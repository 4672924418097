import { useState } from 'react'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { Button } from '../../../common/button/component'
import { Trans } from '../../../common/intl/trans'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { STDialogConfirmConfirmUnblockGetScreen } from './style'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmUnblockGetScreen)

// const BrowserIsFirefox = BrowserUtils.is.firefox()

// ~~~~~~ Component

export const DialogConfirmConfirmUnblockGetScreen = () => {
  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  // ~~~~~~ Handlers

  function onClickOk() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      predefIcon="info"
      modalSize={DialogBreakPoint.XS}
      dialogTitle="dialogs.confirm.unblock-get-screen.Title"
      closeDialog={doClose}
    >
      {/* Content */}

      <Content>
        <STDialogConfirmConfirmUnblockGetScreen>
          <div className="line">
            <Trans id="dialogs.confirm.unblock-get-screen.Content1" />
          </div>

          <div className="line">
            <Trans id="dialogs.confirm.unblock-get-screen.Content2" />
          </div>
        </STDialogConfirmConfirmUnblockGetScreen>
      </Content>

      {/* Actions */}

      <Actions>
        {/* Confirm */}

        <Button
          intlId="common.OK"
          $colorType="primary"
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickOk}
        />
      </Actions>
    </Dialog>
  )
}
