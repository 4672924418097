const prefix = 'mt-live-paint-replace-track-on-stop-draw'

// ~~~~~~ Mutators Types

export const EpicLivePaintReplaceTrackOnStopDrawMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicLivePaintReplaceTrackOnStopDrawMC = {
  ok: () => ({
    type: EpicLivePaintReplaceTrackOnStopDrawMT.OK,
  }),
} as const
