import styled from 'styled-components'
import { Colors } from '../../style/theme/colors'
import { Fonts } from '../../style/theme/fonts'
import { Layout } from '../../style/theme/layout'
import { theme } from '../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'page-start'

// ~~~~~~ Main

export const STStartPage = styled.div`
  height: 100%;
`

// ~~~~~~ Top Right

export const STStartToRight = styled.div``

// ~~~~~~ Content

export const STStartContent = styled.div`
  padding-left: ${Layout.Spacing[3]}px;
  padding-right: ${Layout.Spacing[3]}px;

  width: calc(100% - ${Layout.Spacing[3] * 2}px);
  height: 100%;

  color: ${theme(Colors.WOLF_100_05)};

  display: flex;
  justify-content: center;
  align-items: center;

  .${ClassName}--start-content {
    .${ClassName}--start-content--start-bcast {
      margin-bottom: 40px;

      svg {
        height: 20px;
        path {
          fill: ${theme(Colors.WOLF_100_05)};
        }
      }
    }

    .${ClassName}--start-content--start-info {
      ${Fonts.Scale.Title}
      margin-bottom: 16px;

      max-width: 434px;
    }

    .${ClassName}--start-content--device-not-supported {
      margin-bottom: 16px;
    }

    .${ClassName}--start-content--start-action {
      margin-bottom: 32px;
    }

    .${ClassName}--start-content--start-bottom-info {
      text-align: center;

      .${ClassName}--start-content--start-bottom-info--link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`
