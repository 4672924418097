const prefix = 'at-countdown-bcast-duration'

// ~~~~~~ Action Types

export const CountdownBcastDurationAT = {
  START: `${prefix}-start`,

  STOP: `${prefix}-stop`,
} as const

// ~~~~~~ Action Creators

export const CountdownBcastDurationAC = {
  start: (subscriptionId: string, remainingMins: number) => ({
    type: CountdownBcastDurationAT.START,
    payload: {
      subscriptionId,
      remainingMins,
    },
  }),

  stop: (subscriptionId: string) => {
    return {
      type: CountdownBcastDurationAT.STOP,
      payload: {
        subscriptionId,
      },
    }
  },
} as const
