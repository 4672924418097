const prefix = 'mt-server-pub-main-epic'

// ~~~~~~ Mutators Types

export const EpicApiServerPubMainMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiServerPubMainMC = {
  ok: (
    server: DN.Models.StreamManagerServer.Api.Get.ResParsed,
    stunTurnCredentials: DN.Models.StunTurnCredentials.Api.Get.ResParsed,
  ) => ({
    type: EpicApiServerPubMainMT.OK,
    payload: {
      server,
      stunTurnCredentials,
    },
  }),

  error: (errors: DN.Models.StreamManagerServer.Api.Get.ResError) => ({
    type: EpicApiServerPubMainMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
