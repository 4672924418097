const prefix = 'mt-exit'

// ~~~~~~ Mutators Types

export const ExitMT = {
  RESET_EXIT: `${prefix}-reset-exit`,
} as const

// ~~~~~~ Mutators Creators

export const ExitMC = {
  resetExit: () => ({
    type: ExitMT.RESET_EXIT,
  }),
} as const
