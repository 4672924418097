import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { HocTheme } from '@dn/hocs'

const initState = AppInitState.theme

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Theme
// ####################################################################################################

reducer[HocTheme.Mutators.MT.CHANGE_THEME] = (
  state: State,
  { payload }: ReturnType<typeof HocTheme.Mutators.MC.changeTheme>,
): State => {
  return {
    ...state,
    mode: payload,
  }
}

// ####################################################################################################
// ~~~~~~ Page Size Width/Height
// ####################################################################################################

reducer[HocTheme.Mutators.MT.CH_SIZE] = (
  state: State,
  { payload }: ReturnType<typeof HocTheme.Mutators.MC.chSize>,
): State => {
  return {
    ...state,
    pageWidth: payload.pageWidth,
    pageHeight: payload.pageHeight,
  }
}

// ~~~~~~

export const themeReducer = UtilsStore.dynReducer(initState, reducer, (state) => {
  return state
})
