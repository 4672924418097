import { Trans } from '../../../../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../../../../components/svgs/lazy-svg/component'
import { Zoom } from '../../../../../../../../../constants/zoom'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsZoom } from '../../../../../../../behaviour-logic/content/main/toolbars/main/buttons/zoom/hook'
import { ClassName, STJoinedDesktopContentMainToolbarsMainButtonsZoom } from './style'

// ~~~~~~ Constants

const ZoomInIcon = LazySVG('icons/zoom-in')
const ZoomOutIcon = LazySVG('icons/zoom-out')

// ~~~~~~ Component

export const JoinedDesktopContentMainToolbarsMainButtonsZoom = () => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsZoom(Zoom.Step, Zoom.Max)

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainToolbarsMainButtonsZoom className="with-tooltip">
      <div className={`${ClassName}--content`}>
        {/* Zoom Out */}

        <div className={`${ClassName}--content--zoom-out`} onClick={logic.onClickZoomOut}>
          <ZoomOutIcon size={24} />
        </div>

        {/* Current zoom */}

        <div className={`${ClassName}--content--current-zoom`}>{logic.currentZoom}</div>

        {/* Zoom In */}

        <div className={`${ClassName}--content--zoom-in`} onClick={logic.onClickZoomIn}>
          <ZoomInIcon size={24} />
        </div>
      </div>

      {/* Tooltip */}

      <span
        ref={logic.tooltipRef}
        className="tooltiptext"
        style={{
          top: logic.tooltipTop,
        }}
      >
        <Trans id="pages.joined.main.toolbars.main.tooltips.Zoom" />
      </span>

      {/* -- */}
    </STJoinedDesktopContentMainToolbarsMainButtonsZoom>
  )
}
