import { useRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../../../models/app/model'
import { PaintBCastStreamDrawAC } from '../../../../../../store/actions/paint-bcast-stream/draw/actions'
import { UtilsPaintFramework } from '@dn/utils'

// ~~~~~~ Hook

export const useJoinedBehaviourLogicContentMainCapturePaint = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Store

  const {
    canvas: paintBcastStreamCanvas,

    activePen,
    isErasing,
  } = useSelector((state: StoreState) => state.paintBcastStreamDraw)

  const { video, zoom, originalVideoW, originalVideoH } = useSelector(
    (state: StoreState) => state.paintBcastStreamVideo,
  )

  const { canvas: savedScreenShotCanvas } = useSelector(
    (state: StoreState) => state.paintBcastStreamPic,
  )

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  const canvasesWrapperRef = useRef<HTMLDivElement>(null)

  const paintCanvasRef = useRef<HTMLCanvasElement | null>(null)

  const screenShotCanvasRef = useRef<HTMLCanvasElement | null>(null)

  // ~~~~~~ Computed

  const scale = zoom / 100

  // ~~~~~~ Callbacks

  const setCanvasesWrapperSize = useCallback(() => {
    const self = selfRef.current
    const canvasesWrapper = canvasesWrapperRef.current

    if (!video || !self || !canvasesWrapper) return

    const relW = self.clientWidth
    const relH = self.clientHeight

    const { width, height, top, left } = UtilsPaintFramework.calcRelativeSizeAndTranslate(
      {
        oW: originalVideoW,
        oH: originalVideoH,
        relW,
        relH,
        scale,
      },
      {
        video,
        framework: self,
      },
    )

    canvasesWrapper.style.minWidth = `${width}px`
    canvasesWrapper.style.minHeight = `${height}px`

    canvasesWrapper.style.top = `${top}px`
    canvasesWrapper.style.left = `${left}px`

    dispatch(PaintBCastStreamDrawAC.updateView(width, height))

    //
  }, [dispatch, originalVideoH, originalVideoW, scale, video])

  // ~~~~~~ Effects

  // - Set screenshot

  useEffect(() => {
    const screenShotCanvas = screenShotCanvasRef.current

    if (!screenShotCanvas || !savedScreenShotCanvas) return

    const context2d = screenShotCanvas.getContext('2d')

    if (!context2d) return

    screenShotCanvas.width = savedScreenShotCanvas.width
    screenShotCanvas.height = savedScreenShotCanvas.height

    context2d.drawImage(
      savedScreenShotCanvas,
      0,
      0,
      savedScreenShotCanvas.width,
      savedScreenShotCanvas.height,
    )

    //
  })

  // - Start Painting

  useEffect(() => {
    const self = selfRef.current
    const paintCanvas = paintCanvasRef.current

    // paintBcastStreamCanvas is setted on start

    if (!self || !paintCanvas || !originalVideoW || !originalVideoH) {
      return
    }

    // We are painting when both canvas are the same

    if (paintBcastStreamCanvas === paintCanvas) return

    // If component unmount and mount with some draw
    // we obtain two differents canvas and we need to
    // keep the draw of the stored one

    if (paintBcastStreamCanvas) {
      // Replace new canvas with the stored one in order to keep the draw

      try {
        self.replaceChild(paintBcastStreamCanvas, paintCanvas)

        paintCanvasRef.current = paintBcastStreamCanvas

        return
      } catch (err) {}
    }

    // Stop is launched from hook on close toolbar

    dispatch(PaintBCastStreamDrawAC.start(paintCanvas, self.clientWidth, self.clientHeight))

    //
  }, [dispatch, paintBcastStreamCanvas, originalVideoH, originalVideoW])

  // - On mount or callback change (original video dimensions change) set canvases wrapper w/h

  useEffect(() => {
    setCanvasesWrapperSize()

    //
  }, [setCanvasesWrapperSize])

  // - Observe self resize to update canvases wrapper
  //   this happens on resize the window

  useEffect(() => {
    const self = selfRef.current

    if (!self) return

    const resizeObserve = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        setCanvasesWrapperSize()
      })
    })

    resizeObserve.observe(self)

    return () => {
      resizeObserve.disconnect()
    }
  }, [setCanvasesWrapperSize])

  // ~~~~~~

  return {
    selfRef,
    canvasesWrapperRef,
    paintCanvasRef,
    screenShotCanvasRef,

    activePen,
    isErasing,
  } as const
}
