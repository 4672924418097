import { useEffect, useRef, useState } from 'react'
import { merge, fromEvent } from 'rxjs'
import { Trans } from '../../../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../../../components/svgs/lazy-svg/component'
import { ConstEventsFullScreen } from '@dn/constants'
import { UtilsFullscreen } from '@dn/utils'
import { ClassName, STStartedContentMainToolbarsMainButtonsFullscreen } from './style'

// ~~~~~~ Constants

const FullscreenIsOffIcon = LazySVG('icons/fullscreen')
const FullscreenIsOnIcon = LazySVG('icons/compress')

const { isInFullScreen, fullscreenIsSupported } = UtilsFullscreen

// ~~~~~~ Helpers

const isPipOn = () => !!document.pictureInPictureElement

// ~~~~~~ Types

type Props = {
  onUpdate: () => void
}

// ~~~~~~ Component

export const StartedContentMainToolbarsMainButtonsFullscreen: React.FC<Props> = ({ onUpdate }) => {
  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Refs

  const fullscreenRef = useRef<HTMLElement | null>(null)

  const tooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Specials

  fullscreenRef.current = document.getElementById('app')

  // ~~~~~~ Computed

  const tooltipTop = tooltipRef.current ? -tooltipRef.current.clientHeight - 8 : -34

  // ~~~~~~ Handlers

  function enterFullscreen() {
    if (!fullscreenIsSupported || !fullscreenRef.current || isInFullScreen()) return

    // First exit pip mode if is enabled

    isPipOn()
      ? document
          .exitPictureInPicture()
          .catch(() => undefined)
          .finally(() => {
            onUpdate()

            fullscreenRef.current?.requestFullscreen().finally(() => {
              onUpdate()
              setUpdate(performance.now())
            })
          })
      : fullscreenRef.current.requestFullscreen().finally(() => {
          onUpdate()
          setUpdate(performance.now())
        })
  }

  function exitFullscreen() {
    if (!fullscreenIsSupported || !fullscreenRef.current || !isInFullScreen()) return

    UtilsFullscreen.exitFullScreenP().finally(() => setUpdate(performance.now()))
  }

  function onClick() {
    isInFullScreen() ? exitFullscreen() : enterFullscreen()
  }

  // ~~~~~~ Effects

  // - Force update on mount to calculate tooltip position

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // - Observe leave full screen ESC

  useEffect(() => {
    const fullscreen = fullscreenRef.current

    if (!fullscreen) return

    const sub = merge(
      fromEvent(fullscreen, ConstEventsFullScreen.Change),
      fromEvent(fullscreen, ConstEventsFullScreen.WebkitChange),
      fromEvent(fullscreen, ConstEventsFullScreen.MozChange),
      fromEvent(fullscreen, ConstEventsFullScreen.MSChange),
    ).subscribe({
      next: () => {
        // NOTE: This can be counter-intuitive but we receive the event on end
        // if is in fullscreen, do nothing
        // if is in normal screen, update

        if (UtilsFullscreen.isInFullScreen()) return

        onUpdate()
        setUpdate(performance.now())
      },
    })

    return () => {
      sub.unsubscribe()
    }
  }, [onUpdate])

  // ~~~~~~ Render

  return (
    <STStartedContentMainToolbarsMainButtonsFullscreen className="with-tooltip" onClick={onClick}>
      {/* Icon */}

      {isInFullScreen() ? (
        <FullscreenIsOnIcon className={`${ClassName}--icon-on`} size={24} />
      ) : (
        <FullscreenIsOffIcon className={`${ClassName}--icon-off`} size={24} />
      )}

      {/* Tooltip */}

      <span
        ref={tooltipRef}
        className="tooltiptext"
        style={{
          top: tooltipTop,
        }}
      >
        {isInFullScreen() ? (
          <Trans id="pages.started.main.toolbars.main.tooltips.fullscreen.Exit" />
        ) : (
          <Trans id="pages.started.main.toolbars.main.tooltips.fullscreen.Enter" />
        )}
      </span>
    </STStartedContentMainToolbarsMainButtonsFullscreen>
  )
}
