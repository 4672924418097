import styled from 'styled-components'
import { Transition } from '../../../../style/animations/css-animations'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { Misc } from '../../../../style/theme/misc'
import { getThemeValue, theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-menu-desktop-tablet'

// ~~~~~~ Types

type STProps = {
  $isOverBlack: boolean
}

// ~~~~~~ Component

export const STUserMenuDesktopTablet = styled.div<STProps>`
  padding-right: 0px;

  .${ClassName}--user-menu-btn {
    cursor: pointer;

    width: 24px;
    height: 24px;
    border-radius: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .${ClassName}--user-menu-btn--icon {
      width: 24px;
      height: 24px;

      svg path {
        fill: ${(props) =>
          getThemeValue(props, props.$isOverBlack ? Colors.WOLF_05_05 : Colors.WOLF_100_05)};
      }
    }
  }

  .${ClassName}--dropdown {
    position: relative;

    ${Fonts.Scale.Body}
    ${Fonts.Weight[500]}

    .${ClassName}--dropdown--content {
      z-index: 100;

      margin-top: 22px;
      min-width: 234px;

      position: absolute;
      right: 0;

      .${ClassName}--dropdown--content--header {
        ${Misc.BoxShadow}
        ${Layout.Border.Radius.A}

        padding: ${Layout.Spacing[3]}px;

        background-color: ${theme(Colors.WOLF_00_90)};

        margin-bottom: ${Layout.Spacing[1]}px;

        .${ClassName}--dropdown--content--header--title {
          display: flex;
          align-items: center;

          margin-bottom: ${Layout.Spacing[2]}px;

          .${ClassName}--dropdown--content--header--title--icon {
            display: flex;

            svg {
              width: 16px;
              height: 16px;

              path {
                fill: ${theme(Colors.WOLF_100_05)};
              }
            }
          }
        }

        .${ClassName}--dropdown--content--header--email {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .${ClassName}--dropdown--content--onboarding {
        cursor: pointer;

        ${Misc.BoxShadow}
        ${Layout.Border.Radius.A}

        padding: ${Layout.Spacing[3]}px;

        background-color: ${theme(Colors.WOLF_00_90)};

        margin-bottom: ${Layout.Spacing[1]}px;

        color: ${theme(Colors.EMMET_50_40)};

        display: flex;
        align-items: center;

        img {
          margin-left: 8px;
          width: 20px;
        }
      }

      .${ClassName}--dropdown--content--items {
        ${Misc.BoxShadow}

        ${Layout.Border.Radius.A}

        padding-top: ${Layout.Spacing[2]}px;
        padding-bottom: ${Layout.Spacing[2]}px;

        background-color: ${theme(Colors.WOLF_00_90)};

        .${ClassName}--dropdown--content--items--item {
          cursor: pointer;

          display: flex;
          align-items: center;

          padding: 12px ${Layout.Spacing[3]}px;

          ${Transition.BackgroundColorFaster};

          &:hover {
            background-color: ${theme(Colors.WOLF_05_80)};
          }

          &.active {
            color: ${theme(Colors.EMMET_50_30)};

            .${ClassName}--dropdown--content--items--item--icon {
              svg {
                path:not(.svg-not-change) {
                  fill: ${theme(Colors.EMMET_50_30)};
                  stroke: none;
                }
              }
            }
          }

          .${ClassName}--dropdown--content--items--item--icon {
            margin-right: ${Layout.Spacing[2]}px;

            svg {
              display: flex;

              width: 20px;
              height: 20px;

              path:not(.svg-not-change) {
                fill: ${theme(Colors.WOLF_100_05)};
                stroke: none;
              }
            }
          }
        }
      }
    }
  }
`
