import { AnimatePresence } from 'framer-motion'
import { useSelector } from 'react-redux'
import { Trans } from '../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { ClassName, STStartedContentMirrorEffectLayer } from './styles'

// ~~~~~~ Constants

const InfoIcon = LazySVG('icons/info')

// ~~~~~~ Types

type Props = {
  showLayer: boolean
}

// ~~~~~~ Component

export const StartedContentMirrorEffectLayer: React.FC<Props> = ({ showLayer }) => {
  // ~~~~~~ State

  // - Store

  const getScreenToSwitchUiStatus = useSelector(
    (state: StoreState) => state.sharedMainStream.getScreenToSwitchUiStatus,
  )

  // ~~~~~~ Computed

  const switchingIsWaiting = getScreenToSwitchUiStatus === 'waiting-stream'

  // ~~~~~~ Handlers

  function onClickLink() {
    window.open(Config.Links.MirrorEffect)
  }

  // ~~~~~~ Render

  return (
    <AnimatePresence>
      {showLayer ? (
        <STStartedContentMirrorEffectLayer
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 1.2,
          }}
        >
          {/* Normal mirror effect info */}

          {switchingIsWaiting ? undefined : (
            <div className={`${ClassName}--info`}>
              <div className={`${ClassName}--info--icon`}>
                <InfoIcon size={20} />
              </div>

              <div className={`${ClassName}--info--text`}>
                <span>
                  <Trans id="pages.started.main.mirror-effect.Sentence" />
                </span>
                <span> </span>
                <span className={`${ClassName}--info--text--link`} onClick={onClickLink}>
                  <Trans id="pages.started.main.mirror-effect.Link" />
                </span>
              </div>
            </div>
          )}
        </STStartedContentMirrorEffectLayer>
      ) : undefined}
    </AnimatePresence>
  )
}
