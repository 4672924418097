import styled from 'styled-components'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'

// ~~~~~~ Component

export const STStartedHeaderTopRightEndBroadcast = styled.div`
  cursor: pointer;

  ${Fonts.Scale.Body}

  padding: ${Layout.Spacing[2]}px;

  display: flex;
  align-items: center;
`
