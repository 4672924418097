import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

// ~~~~~~ Types

type Props = {
  onDownload: () => void
  file?: string
  name?: string
}

// ~~~~~~ Component

export const AutoDownload: React.FC<Props> = ({ onDownload, file, name }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const downloadRef = useRef<HTMLAnchorElement | null>(null)

  // ~~~~~~ Effects

  // - Auto download

  useEffect(() => {
    if (!downloadRef.current || !file || !name) return

    downloadRef.current.click()

    onDownload()

    //
  }, [dispatch, file, name, onDownload])

  // ~~~~~~ Render

  return file && name ? (
    <a ref={downloadRef} style={{ display: 'none' }} href={file} download={name}>
      {name}
    </a>
  ) : null
}
