import { HooksEvents } from '@dn/hooks'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../models/app/model'

export const UserHasInteracted = () => {
  // ~~~~~~ State

  const userHasInteracted = useSelector((state: StoreState) => state.root.userHasInteracted)

  // ~~~~~~ Dependent Hooks

  HooksEvents.WindowClick.useHook(/* check */ !userHasInteracted)

  // ~~~~~~ Render

  return null
}
