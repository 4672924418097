export const Emmet = {
  90: '#002B6B',
  80: '#00388C',
  70: '#0048B5',
  60: '#005DE8',
  50: '#0066FF',
  40: '#3385FF',
  30: '#5498FF',
  20: '#8AB9FF',
  10: '#B0D0FF',
  5: '#E6F0FF',
  0: '#FFFFFF',
} as const

// ~~~~~~

const EMMET_00_10: ThemeColor = {
  light: Emmet[0],
  dark: Emmet[10],
}

const EMMET_05_05: ThemeColor = {
  light: Emmet[5],
  dark: Emmet[5],
}

const EMMET_05_40: ThemeColor = {
  light: Emmet[5],
  dark: Emmet[40],
}

const EMMET_05_50: ThemeColor = {
  light: Emmet[5],
  dark: Emmet[50],
}

const EMMET_10_10: ThemeColor = {
  light: Emmet[10],
  dark: Emmet[10],
}

const EMMET_20_40: ThemeColor = {
  light: Emmet[20],
  dark: Emmet[40],
}

const EMMET_30_30: ThemeColor = {
  light: Emmet[30],
  dark: Emmet[30],
}

const EMMET_30_60: ThemeColor = {
  light: Emmet[30],
  dark: Emmet[60],
}

const EMMET_40_20: ThemeColor = {
  light: Emmet[40],
  dark: Emmet[20],
}

const EMMET_40_30: ThemeColor = {
  light: Emmet[40],
  dark: Emmet[30],
}

const EMMET_40_40: ThemeColor = {
  light: Emmet[40],
  dark: Emmet[40],
}

const EMMET_40_50: ThemeColor = {
  light: Emmet[40],
  dark: Emmet[40],
}

const EMMET_40_70: ThemeColor = {
  light: Emmet[40],
  dark: Emmet[70],
}

const EMMET_50_20: ThemeColor = {
  light: Emmet[50],
  dark: Emmet[20],
}

const EMMET_50_30: ThemeColor = {
  light: Emmet[50],
  dark: Emmet[30],
}

const EMMET_50_40: ThemeColor = {
  light: Emmet[50],
  dark: Emmet[40],
}

const EMMET_50_50: ThemeColor = {
  light: Emmet[50],
  dark: Emmet[50],
}

const EMMET_50_80: ThemeColor = {
  light: Emmet[50],
  dark: Emmet[80],
}

const EMMET_60_60: ThemeColor = {
  light: Emmet[60],
  dark: Emmet[60],
}

const EMMET_60_80: ThemeColor = {
  light: Emmet[60],
  dark: Emmet[80],
}

const EMMET_70_90: ThemeColor = {
  light: Emmet[70],
  dark: Emmet[90],
}

// ~~~~~~

export const EmmetColors = {
  EMMET_00_10,
  EMMET_05_05,
  EMMET_05_40,
  EMMET_05_50,
  EMMET_10_10,
  EMMET_20_40,
  EMMET_30_30,
  EMMET_30_60,
  EMMET_40_20,
  EMMET_40_30,
  EMMET_40_40,
  EMMET_40_50,
  EMMET_40_70,
  EMMET_50_20,
  EMMET_50_30,
  EMMET_50_40,
  EMMET_50_50,
  EMMET_50_80,
  EMMET_60_60,
  EMMET_60_80,
  EMMET_70_90,
} as const
