import { useDispatch } from 'react-redux'
import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { UiMobileToolbarFieldsMR } from '../../../../../../../models/bcast/ui-mobile-toolbars/model'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsCapturePaint } from '../../../../../behaviour-logic/content/main/toolbars/main/buttons/capture-paint/hook'
import { ClassName, STJoinedMobileToolbarsMainButtonsCapturePaint } from './style'

// ~~~~~~ Constants

const PaintIcon = LazySVG('icons/tool-pen')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const JoinedMobileToolbarsMainButtonsCapturePaint: React.FC<Props> = ({ video }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsCapturePaint(video)

  // ~~~~~~ Handlers

  function onClick() {
    logic.onClickCapturePaint()

    dispatch(UiMobileToolbarFieldsMR.openStatus.MC.change('painting-closed'))
  }

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsMainButtonsCapturePaint onClick={onClick}>
      {/* Icon */}

      <PaintIcon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsMainButtonsCapturePaint>
  )
}
