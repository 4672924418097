import { Config } from '../../../../config'
import { UtilsLog } from '../../../../utils/logs'
import { DrawState } from '../../@state/draw-partial-service'

// ####################################################################################################
// ~~~~~~ Redo
// ####################################################################################################

export const redo = () => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'public-commands', 'redo')

  if (!DrawState.State.commandManager) return

  return DrawState.State.commandManager.redo()
}
