import { Trans } from '../../../../../../components/common/intl/trans'
import { LoaderBalls } from '../../../../../../components/common/loader-balls/component'
import { ClassName, STTabletDesktopContentMainLoadingVideo } from './style'

// ~~~~~~ Component

export const JoinedTabletContentMainLoadingVideo = () => {
  // ~~~~~~ Render

  return (
    <STTabletDesktopContentMainLoadingVideo>
      <LoaderBalls />

      <div className={`${ClassName}--text`}>
        <Trans id="pages.joined.content.LoadingVideo" />
      </div>
    </STTabletDesktopContentMainLoadingVideo>
  )
}
