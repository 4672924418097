import { AnimationProps } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../../../models/app/model'
import { UiMobileToolbarFieldsMR } from '../../../../../../../models/bcast/ui-mobile-toolbars/model'
import { JoinedMobileToolbarsCapturePaintColorsColor } from './color/component'

import { STMotionJoinedMoblieToolbarsCapturePaintButtonsColors } from './style'

// ~~~~~~ Types

type Props = {
  showAll: boolean

  variantsContainer: AnimationProps['variants']

  isPortrait: boolean

  onClickSelectColor: (colorKey: DN.Models.PaintBcastStream.Draw.ColorKey) => void
  onClickShowColorSelector: () => void
  onClickSelectPen: (pen: DN.Models.PaintBcastStream.Draw.Pen) => void
}

// ~~~~~~ Component

export const JoinedMoblieToolbarsCapturePaintButtonsColors: React.FC<Props> = ({
  showAll,

  variantsContainer,

  isPortrait,

  onClickSelectColor,
  onClickShowColorSelector,
  onClickSelectPen,
}) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const selectedColorKey = useSelector((state: StoreState) => state.paintBcastStreamDraw.colorKey)

  const selectedPen = useSelector((state: StoreState) => state.paintBcastStreamDraw.activePen)

  const isErasing = useSelector((state: StoreState) => state.paintBcastStreamDraw.isErasing)

  // ~~~~~~ Handlers

  function onClickSelectColorH(color: typeof selectedColorKey) {
    onClickSelectColor(color)

    dispatch(UiMobileToolbarFieldsMR.openStatus.MC.change('painting-closed'))
  }

  function onClickCurrentColor() {
    onClickShowColorSelector()

    if (!isErasing) return

    onClickSelectPen(selectedPen)
  }

  // ~~~~~~ Render

  // Collapsed: Current Pen

  if (!showAll) {
    return (
      <STMotionJoinedMoblieToolbarsCapturePaintButtonsColors>
        {/* -- */}

        <JoinedMobileToolbarsCapturePaintColorsColor
          $colorKey={selectedColorKey}
          selected={false}
          onClick={onClickCurrentColor}
        />

        {/* -- */}
      </STMotionJoinedMoblieToolbarsCapturePaintButtonsColors>
    )
  }

  // Pen selector

  return (
    <STMotionJoinedMoblieToolbarsCapturePaintButtonsColors
      variants={variantsContainer}
      initial="hidden"
      animate="show"
      style={{
        display: 'flex',
        flexDirection: isPortrait ? 'column' : 'row',
      }}
    >
      {/* Black */}

      <JoinedMobileToolbarsCapturePaintColorsColor
        $colorKey="BLACK"
        selected={selectedColorKey === 'BLACK'}
        onClick={() => onClickSelectColorH('BLACK')}
      />

      {/* White */}

      <JoinedMobileToolbarsCapturePaintColorsColor
        $colorKey="WHITE"
        selected={selectedColorKey === 'WHITE'}
        onClick={() => onClickSelectColorH('WHITE')}
      />

      {/* Purple */}

      <JoinedMobileToolbarsCapturePaintColorsColor
        $colorKey="PURPLE"
        selected={selectedColorKey === 'PURPLE'}
        onClick={() => onClickSelectColorH('PURPLE')}
      />

      {/* Blue */}

      <JoinedMobileToolbarsCapturePaintColorsColor
        $colorKey="BLUE"
        selected={selectedColorKey === 'BLUE'}
        onClick={() => onClickSelectColorH('BLUE')}
      />

      {/* Green */}

      <JoinedMobileToolbarsCapturePaintColorsColor
        $colorKey="GREEN"
        selected={selectedColorKey === 'GREEN'}
        onClick={() => onClickSelectColorH('GREEN')}
      />

      {/* Yellow */}

      <JoinedMobileToolbarsCapturePaintColorsColor
        $colorKey="YELLOW"
        selected={selectedColorKey === 'YELLOW'}
        onClick={() => onClickSelectColorH('YELLOW')}
      />

      {/* Red */}

      <JoinedMobileToolbarsCapturePaintColorsColor
        $colorKey="RED"
        selected={selectedColorKey === 'RED'}
        onClick={() => onClickSelectColorH('RED')}
      />

      {/* -- */}
    </STMotionJoinedMoblieToolbarsCapturePaintButtonsColors>
  )
}
