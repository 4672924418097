import { AppInitState } from '../../../../models/app/model'
import { DevicesFieldsMR } from '../../../../models/bcast/devices/model'
import { UtilsStore } from '@dn/utils'
import { DevicesMC, DevicesMT } from '../../../actions-mutators/devices/mutators'

const initState = AppInitState.devices

type State = typeof initState

const reducer: any = {
  ...DevicesFieldsMR.selectedCam.Reducer,
  ...DevicesFieldsMR.selectedCamSize.Reducer,
  ...DevicesFieldsMR.selectedMic.Reducer,
  ...DevicesFieldsMR.selectedSpeakers.Reducer,
  ...DevicesFieldsMR.list.Reducer,
}

// ####################################################################################################
// ~~~~~~ Update model
// ####################################################################################################

reducer[DevicesMT.UPDATE_MODEL] = (
  state: State,
  { payload }: ReturnType<typeof DevicesMC.updateModel>,
): State => {
  return {
    ...state,
    ...payload.patch,
    streams: {
      ...state.streams,
      ...payload.patch.streams,
    },
  }
}

// ####################################################################################################
// ~~~~~~ Add stream
// ####################################################################################################

reducer[DevicesMT.ADD_STREAM] = (
  state: State,
  { payload }: ReturnType<typeof DevicesMC.addStream>,
): State => {
  return {
    ...state,
    streams: {
      ...state.streams,

      [payload.stream.id]: {
        stream: payload.stream,
        camDeviceId: payload.camDeviceId,
        micDeviceId: payload.micDeviceId,
      },
    },
  }
}

// ####################################################################################################
// ~~~~~~ Rem stream
// ####################################################################################################

function omit(key: string, obj: { [key: string]: any }) {
  const { [key]: omitted, ...rest } = obj

  return rest
}

reducer[DevicesMT.REM_STREAM] = (
  state: State,
  { payload }: ReturnType<typeof DevicesMC.remStream>,
): State => {
  return {
    ...state,
    streams: omit(payload.stream.id, state.streams),
  }
}

// ####################################################################################################
// ~~~~~~ Clear
// ####################################################################################################

reducer[DevicesMT.CLEAR] = (state: State): State => {
  return initState
}

// ~~~~~~

export const devicesReducer = UtilsStore.dynReducer(initState, reducer, (state, action): State => {
  return state
})
