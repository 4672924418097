import styled from 'styled-components'
import { MediaQuery } from '../../../style/constants/media-queries'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { getThemeValue, theme } from '../../../style/utils/theme'

// ~~~~~~ Props

export type STProps = {
  $forceThemeInTop?: Theme
  $forceThemeInFooter?: Theme
}

// ~~~~~~ Component

export const STLayoutCup = styled.div<STProps>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: ${theme(Colors.WOLF_00_100)};

  .layout-cup-top,
  .layout-cup-content,
  .layout-cup-footer {
    width: 100%;
  }

  .layout-cup-top {
    ${Fonts.Scale.BodyBig}

    display: flex;
    flex-direction: column;
    justify-content: center;

    .layout-cup-top-content {
      width: 100%;

      display: flex;
      flex-direction: column;

      background-color: ${(props) =>
        props.$forceThemeInTop
          ? props.$forceThemeInTop === 'light'
            ? getThemeValue(props, Colors.WOLF_00_00)
            : getThemeValue(props, Colors.WOLF_100_100)
          : 'transparent'};

      .layout-cup-top-banner-close {
        cursor: pointer;

        width: 16px;
        height: 16px;

        svg path {
          fill: ${theme(Colors.WOLF_20_20)};
        }
      }
    }

    .layout-cup-top-main {
      width: 100%;
      display: flex;
      justify-content: center;

      .layout-cup-top-main-content {
        height: 68px;
        min-height: 68px;

        max-width: ${MediaQuery.LG};

        padding: 0px ${Layout.Spacing[9]}px 0px ${Layout.Spacing[9]}px;

        width: calc(100% - ${Layout.Spacing[9] * 2}px); // 100% - padding

        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .layout-cup-content {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .layout-cup-footer {
    ${Fonts.Scale.Body}
    ${Fonts.Weight[400]}

    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: ${Layout.Spacing[4]}px;
    padding-bottom: ${Layout.Spacing[4]}px;

    background-color: ${(props) =>
      props.$forceThemeInFooter
        ? props.$forceThemeInFooter === 'light'
          ? getThemeValue(props, Colors.WOLF_00_00)
          : getThemeValue(props, Colors.WOLF_100_100)
        : 'transparent'};
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.MD}) {
    .layout-cup-top {
      .layout-cup-top-content {
        .layout-cup-top-main {
          .layout-cup-top-main-content {
            padding: 0px ${Layout.Spacing[3]}px 0px ${Layout.Spacing[3]}px;

            width: calc(100% - ${Layout.Spacing[3] * 2}px); // 100% - padding
          }
        }
      }
    }
  }

  @media (max-width: ${MediaQuery.SM}) {
    .layout-cup-content {
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
    }
  }
`
