import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Misc } from '../../../style/theme/misc'
import { theme } from '../../../style/utils/theme'

export const STNewVersionAvailable = styled.div`
  left: 50%;
  transform: translate(-50%, 0);

  position: absolute;
  z-index: 9999;

  .panel {
    ${Misc.BoxShadow}

    padding: 14px;
    border-top: none;

    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;

    background-color: ${theme(Colors.WOLF_00_90)};

    .message {
      padding-bottom: 14px;
    }

    .actions {
      display: grid;
      gap: 14px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
`
