import { EMPTY, merge, Observable, of, zip, catchError, map, mergeMap, takeUntil } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'
import { BroadcastAC, BroadcastAT } from '../../../actions/bcast/actions'
import { StoreState } from '../../../../models/app/model'
import { BroadcastService } from '../../../../services/broadcast'
import { EpicBcastStartPubCamMicMC } from './mutators'
import { UtilsLog } from '../../../../utils/logs'
import { BroadcastServiceConst } from '../../../../services/broadcast/constants/broadcast-partial-service'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import { cancelCamMic$$ } from '../../cancel-now/cam-mic/subject'
import { cancelAll$$ } from '../../../../subjects/cancel-all/subject'
import { Config } from '../../../../config'

type Action = ReturnType<typeof BroadcastAC.startPubCamMic>

export const bcastStartPubCamMicEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(BroadcastAT.START_PUB_CAM_MIC),

    mergeMap(({ payload }) => {
      //

      const finalUserPassToken: DN.Services.Broadcast.UserPassToken = {
        ...payload.userPassToken,
        username: `${Config.App.red5proUsernamePrefix}-${payload.userPassToken.username}`,
      }

      return zip(
        of(payload.pubConfig.broadcastId),

        BroadcastService.genPublisher$(
          payload.pubConfig,
          payload.stream,
          finalUserPassToken,
          payload.iceServers,
        ),
      ).pipe(
        mergeMap(([broadcastId, { red5pub, close$, allEvents$ }]) => {
          const startPub$ = BroadcastService.startPub$(red5pub, broadcastId).pipe(
            map(() => EpicBcastStartPubCamMicMC.ok(red5pub)),

            catchError((err) => {
              UtilsLog.devLog('broadcastStartPubEpic$', '\n- ERROR\n', err)

              let errors: Infos[] | undefined = undefined

              switch (err) {
                case BroadcastServiceConst.ErrorCodes.PublishDisalllowed:
                  errors = [{ id: 'service.broadcast.pub.DisallowedError' }]

                  break

                case BroadcastServiceConst.ErrorCodes.PublishNotFound:
                  break
              }

              const actions = []

              errors?.length && actions.push(EpicBcastStartPubCamMicMC.error(errors))

              return actions.length ? of(...actions) : EMPTY
            }),
          )

          // All events

          const event$ = allEvents$.pipe(
            // Emmit events

            map((evt) =>
              EpicBcastStartPubCamMicMC.event({
                type: evt.type,
                data: (evt as any).data,
              }),
            ),
          )

          // Close event is received when:
          // - WebRTC.DataChannel.{Error|Close}
          // - Device is connected to a network that has been disconnected from internet
          // - User start a new broadcast while is broadcasting in another tab/browser
          // - Free account uses all its minutes

          const finalClose$ = close$.pipe(
            mergeMap((reason) => {
              switch (reason) {
                // Close, fail, ...

                case 'closed':
                  return of(CancelNowAC.cancelCamMic([CancelReasons.ConnectionClosed]))

                case 'connection-failure':
                case 'publish-fail':
                  return of(CancelNowAC.cancelCamMic([CancelReasons.PublishFail]))

                case 'data-channel-error':
                  return of(CancelNowAC.cancelCamMic([CancelReasons.DataChannelError]))
              }
            }),
          )

          return merge(startPub$, finalClose$, event$).pipe(
            takeUntil(cancelCamMic$$),
            takeUntil(cancelAll$$),
          )
        }),

        catchError(() => of(CancelNowAC.cancelCamMic([CancelReasons.ConnectionClosed]))),
      )
    }),
  )
