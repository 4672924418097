import styled from 'styled-components'

import { CSSAnimation } from '../../../style/animations/css-animations'
import { getThemeValue } from '../../../style/utils/theme'

// ~~~~~~ Types

type STProps = {
  $size: number
  $fill: ThemeColor
  $paddingTop?: number
}

// ~~~~~~ Component

export const STLoader = styled.div<STProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: ${({ $paddingTop }) => $paddingTop || 0}px;

  svg {
    max-width: ${({ $size }) => $size}px;
    max-height: ${({ $size }) => $size}px;

    ${CSSAnimation.Animation.spinner};

    path.svg-spinner-a {
      fill: transparent !important;
      stroke: transparent !important;
    }

    path.svg-spinner-b {
      fill: ${(props) => getThemeValue(props, props.$fill)};
      stroke: transparent !important;
    }

    path.svg-spinner-c {
      fill: transparent !important;
      stroke: transparent !important;
    }
  }
`
