import styled from 'styled-components'
import { Colors } from '../../../../../../style/theme/colors'
import { Fonts } from '../../../../../../style/theme/fonts'
import { Layout } from '../../../../../../style/theme/layout'
import { theme } from '../../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-tablet-content-main-loading-video'

// ~~~~~~ Component

export const STTabletDesktopContentMainLoadingVideo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .${ClassName}--text {
    margin-top: ${Layout.Spacing[3]}px;

    ${Fonts.Scale.Title}

    color: ${theme(Colors.WOLF_100_05)};
  }
`
