import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { Trans } from '../../../intl/trans'
import { ClassName, STOnboardingPanelOption } from './style'

// ~~~~~~ Constants

const IconLock = LazySVG('icons/lock')

const IconTodo = LazySVG('icons/circle-empty')

const IconSuccess = LazySVG('icons/circle-success')

const IconStatus = {
  lock: IconLock,
  todo: IconTodo,
  success: IconSuccess,
} as const

// ~~~~~~ Types

type Props = {
  intlId: IntlMsgId
  status: 'lock' | 'todo' | 'success'
}

// ~~~~~~ Component

export const OnboardingPanelOption: React.FC<Props> = ({ intlId, status }) => {
  // ~~~~~~ Compputed

  const Icon = IconStatus[status]

  // ~~~~~~ Render

  return (
    <STOnboardingPanelOption className={`${status} ${status !== 'todo' ? 'thin' : ''}`}>
      {/* Icon */}

      <div className={`${ClassName}--icon ${status}`}>
        <Icon size={16} />
      </div>

      {/* Text */}

      <Trans id={intlId} />

      {/* -- */}
    </STOnboardingPanelOption>
  )
}
