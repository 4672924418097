import { AppInitState } from '../../../../../models/app/model'
import { UiMobileToolbarFieldsMR } from '../../../../../models/bcast/ui-mobile-toolbars/model'
import { UtilsStore } from '@dn/utils'
import { EpicCancelNowAllMT } from '../../../../epics/cancel-now/all/mutators'

const initState = AppInitState.uiMobileToolbar

type State = typeof initState

const reducer: any = {
  ...UiMobileToolbarFieldsMR.openStatus.Reducer,
}

// ####################################################################################################
// ~~~~~~ Cancel all
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~

export const uiMobileToolbarReducer = UtilsStore.dynReducer(initState, reducer)
