import { Config } from '../../../../../config'

// ~~~~~~ Constants

type State = DN.Services.Extension.ToExtension.ChooseSourceStateChanged['payload']['state']

// ~~~~~~

export const sendMessageToExtensionChooseSourceStateChanged = (state: State) => {
  if (
    Config.Extension.Id === 'no-extension-id' ||
    !window.chrome ||
    !window.chrome.runtime ||
    !window.chrome.runtime.sendMessage
  ) {
    return
  }

  const message: DN.Services.Extension.ToExtension.ChooseSourceStateChanged = {
    type: 'choose-source-state-changed',
    payload: {
      state,
    },
  }

  chrome.runtime.sendMessage(Config.Extension.Id, message)
}
