import { Config } from '../../../config'
import { UtilsSHA } from '@dn/utils'
import { ConstHttpHeaders, ConstHttpHeaderContentType } from '@dn/constants'

const DefaultHeaders = {
  [ConstHttpHeaders.ContentType]: ConstHttpHeaderContentType.AppJson,
} as const

const DefaultHeadersGet = {} as const

const genHeaders = (isGet?: boolean, extraHeaders?: AppHeaders) => ({
  headers: {
    ...(isGet ? DefaultHeadersGet : DefaultHeaders),
    ...extraHeaders,
  },
})

const genAuthHeader = (token: string) => ({
  Authorization: `Bearer ${token}`,
})

const genHeadersWithAuth = (token: string, isGet?: boolean, extraHeaders?: AppHeaders) => ({
  ...genHeaders(isGet, {
    ...extraHeaders,
    ...genAuthHeader(token),
  }),
})

const genGetHeaders = () => {
  const [random, randomSig] = UtilsSHA.genHmacSha1Tuple(Config.Api.StunTurn.Key)

  const extraHeaders = {
    ...Config.Api.StunTurn.CredentialsHeaders,
    'x-stunturn-rnd': random,
    'x-stunturn-rnd-sig': randomSig,
  }

  return genHeaders(true, extraHeaders)
}

// ~~~~~~

export const StunTurnHeaderUtils = {
  genHeaders,
  genAuthHeader,
  genHeadersWithAuth,
  genGetHeaders,
}
