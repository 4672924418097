import styled from 'styled-components'
import { theme } from '../../../../../../../style/utils/theme'
import { STJoinedMobileToolbarsMainButtons, CommonStyleSvgColors } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-main-buttons-fullscreen'

// ~~~~~~ Component

export const STJoinedMobileToolbarsMainButtonsFullscreen = styled.div`
  ${STJoinedMobileToolbarsMainButtons}

  svg.${ClassName}--icon-off {
    path {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }
  }

  svg.${ClassName}--icon-on {
    path {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }
`
