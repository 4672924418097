import { AppInitState } from '../../../../../models/app/model'
import { UiSettingsEditFieldsMR } from '../../../../../models/bcast/ui-settings/model'
import { UtilsStore } from '@dn/utils'

// ~~~~~~

const initState = AppInitState.uiSettings

// type State = typeof initState

const reducer: any = {
  ...UiSettingsEditFieldsMR.section.Reducer,
}

// ~~~~~~

export const uiSettingsReducer = UtilsStore.dynReducer(initState, reducer)
