import { UnknownAction } from 'redux'
import { ofType, StateObservable } from 'redux-observable'
import { mergeMap, Observable, of } from 'rxjs'
import { UtilsOS, UtilsLang } from '@dn/utils'
import { HocTheme } from '@dn/hocs'

import { StoreState } from '../../../../../models/app/model'
import { apiBcastUserSettingsShow$ } from '../../../../../services/api-bcast/user-settings/get'
import {
  ApiBcastUserSettingsAC,
  ApiBcastUserSettingsAT,
} from '../../../../actions/api-bcast/user-settings/actions'
import { IntlAC } from '../../../../actions/intl/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastUserSettingsShowMC } from './mutators'
import { Config } from '../../../../../config'
import { LocalStorageLang } from '../../../../../services/storage/local/lang'

// ~~~~~~ Constants

const { DefaultLocale, SupportedLocales } = Config.Intl

const StoredLang = LocalStorageLang.get()

// ~~~~~~ Types

type Action = ReturnType<typeof ApiBcastUserSettingsAC.show>

// ~~~~~~ Epic

export const apiBcastUserSettingsShowEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastUserSettingsAT.SHOW),

    mergeMap(({ payload }) =>
      apiBcastUserSettingsShow$(state$.value.currentUser.token, payload.userId, {
        isOnboardingRunning: state$.value.userSettings.isOnboardingRunning,
      }),
    ),

    mergeMap((res) => {
      if (!ApiUtils.isSuccess(res)) {
        return of(EpicApiBcastUserSettingsShowMC.error(res.response))
      }

      // Update

      const actions: UnknownAction[] = [EpicApiBcastUserSettingsShowMC.ok(res.response)]

      // Lang

      const langAction =
        !res.response.lang || res.response.lang === 'auto'
          ? IntlAC.changeLang(UtilsLang.getAppLang(SupportedLocales, DefaultLocale, StoredLang))
          : IntlAC.changeLang(res.response.lang)

      actions.push(langAction)

      // Theme

      const themeAction =
        !res.response.theme || res.response.theme === 'auto'
          ? HocTheme.Mutators.MC.changeTheme(UtilsOS.getTheme())
          : HocTheme.Mutators.MC.changeTheme(res.response.theme)

      actions.push(themeAction)

      // All action

      return of(...actions)
    }),
  )
