const prefix = 'mt-share-main-stream-get-stream-to-switch-epic'

// ~~~~~~ Mutators Types

export const EpicShareMainStreamGetScreenToSwitchMT = {
  WAITING_STREAM: `${prefix}-waiting-stream`,
  START: `${prefix}-start`,
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~

export const EpicShareMainStreamGetScreenToSwitchMC = {
  waitingStream: () => ({
    type: EpicShareMainStreamGetScreenToSwitchMT.WAITING_STREAM,
  }),

  start: () => ({
    type: EpicShareMainStreamGetScreenToSwitchMT.START,
  }),

  ok: (newStream: MediaStream, newVideoTrack: MediaStreamTrack) => ({
    type: EpicShareMainStreamGetScreenToSwitchMT.OK,
    payload: {
      newStream,
      newVideoTrack,
    },
  }),

  error: (errors: Infos[]) => ({
    error: true,
    type: EpicShareMainStreamGetScreenToSwitchMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
