import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Config } from '../../../../config'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { StorePlanFieldsMR } from '../../../../models/store/plan/model'
import { ApiStoreSubscriptionsAC } from '../../../../store/actions/api-store/subscriptions/actions'
import { GoogleGTM } from '../../../../third-parties/google-gtm/library'
import { Button } from '../../../common/button/component'
import { InfoBox } from '../../../common/infos/box/component'
import { DangerTrans } from '../../../common/intl/danger-trans'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { STExtraInfo } from './style'
import { BcastTrackEvents } from '../../../../services/track-events'
import { useTrackUserContext } from '../../../../hooks/track-events/use-track-user-context'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.LoggedStep2Buying)

// ~~~~~~ Component

export const DialogsSubscriptionsLoggedStep2Buying = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const { priceId, errors, uiStatusSubscribing, uiStatusUpgradePlan } = useSelector(
    (state: StoreState) => state.storePlan,
  )

  // ~~~~~~ Computed

  const isRunning = uiStatusSubscribing === 'running' || uiStatusUpgradePlan === 'running'

  const hasErrors = errors.length

  const showClose = !isRunning && hasErrors

  // ~~~~~~ Handlers

  function onClickRetryOrThanks() {
    if (hasErrors) {
      dispatch(
        ApiStoreSubscriptionsAC.upgradePlan({
          price_id: priceId,
        }),
      )

      return
    }

    dispatch(StorePlanFieldsMR.reset.MC.reset())

    setDoClose(performance.now())

    // Track

    BcastTrackEvents.calls.BuyProcess.Upgrade['4-upgrade-success'](userContext)

    GoogleGTM.send('SuccessUpgraded')
  }

  function onClickClose() {
    dispatch(StorePlanFieldsMR.reset.MC.reset())

    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      closeOnClickOut={false}
      stackedBtns={true}
      predefIcon={!isRunning && !hasErrors ? 'success' : undefined}
      dialogTitle={
        isRunning
          ? 'dialogs.confirm.buying.Title.Doing'
          : hasErrors
            ? 'dialogs.confirm.buying.Title.Errors'
            : 'dialogs.confirm.buying.Title.Done'
      }
      removeContentTitleMarginBottom={hasErrors ? undefined : true}
      modalSize={DialogBreakPoint.XS}
      closeDialog={doClose}
    >
      <Content>
        {hasErrors ? <InfoBox withIcon={true} $boxType="ERROR" infos={errors} /> : undefined}
      </Content>

      <Actions>
        {/* Extra information at bottom */}

        <STExtraInfo>
          <DangerTrans
            id="common.NoticeStore"
            template="... <c>Store</c>"
            values={{
              c: (chunks: string) => (
                <a href={Config.Links.UpgradeBroadcastURL} target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
            }}
          />
        </STExtraInfo>

        {/* Close: Cannot close on running */}

        {showClose ? (
          <Button
            $colorType="secondary"
            intlId="common.Close"
            onClick={onClickClose}
            $active={!isRunning}
            disabled={isRunning}
            $running={false}
          />
        ) : undefined}

        {/* Thanks / Retry */}

        <Button
          $colorType={hasErrors ? 'primary' : 'success'}
          intlId={hasErrors ? 'common.Retry' : 'common.Thanks'}
          onClick={onClickRetryOrThanks}
          disabled={isRunning}
          $active={!isRunning}
          $running={isRunning}
        />
      </Actions>
    </Dialog>
  )
}
