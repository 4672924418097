const prefix = 'at-api-bcast-room'

// ~~~~~~ Action Types

export const ApiBcastRoomAT = {
  FOR_PUB: `${prefix}-for-pub`,
  FOR_SUB: `${prefix}-for-sub`,
  VERSION: `${prefix}-version`,
} as const

// ~~~~~~ Action Creators

export const ApiBcastRoomAC = {
  forPub: (bcastId: string) => ({
    type: ApiBcastRoomAT.FOR_PUB,
    payload: {
      bcastId,
    },
  }),

  forSub: (bcastId: string) => ({
    type: ApiBcastRoomAT.FOR_SUB,
    payload: {
      bcastId,
    },
  }),

  version: (bcastId: string) => ({
    type: ApiBcastRoomAT.VERSION,
    payload: {
      bcastId,
    },
  }),
} as const
