const prefix = 'mt-api-bcast-google-slides-create-presentation-and-add-image-slide-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC = {
  ok: (data: DN.Models.GoogleSlides.Api.Create.ResParsed) => ({
    type: EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.OK,
    payload: data,
  }),

  error: (
    errors:
      | DN.Models.GoogleSlides.Api.Create.ResError
      | DN.Models.GoogleSlides.Api.AddImageSlide.ResError,
    unsync: boolean,
  ) => ({
    type: EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.ERROR,
    error: true,
    payload: {
      unsync,
      errors,
    },
  }),
} as const
