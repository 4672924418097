const prefix = 'at-api-bcast-google-slides'

// ~~~~~~ Action Types

export const ApiBcastGoogleSlidesAT = {
  LIST_PRESENTATIONS: `${prefix}-list-presentations`,
  CREATE_PRESENTATION: `${prefix}-create-presentation`,
  ADD_IMAGE_SLIDE: `${prefix}-add-image-slide`,
  CREATE_PRESENTATION_AND_ADD_IMAGE_SLIDE: `${prefix}-craete-presentation-and-add-image-slide`,
} as const

// ~~~~~~ Action Creators

export type WithGoogleAccessToken =
  | {
      withIt: true
      accessToken: string
    }
  | {
      withIt: false
    }

export const ApiBcastGoogleSlidesAC = {
  listPresentations: (withGoogleAccessToken: WithGoogleAccessToken) => ({
    type: ApiBcastGoogleSlidesAT.LIST_PRESENTATIONS,
    payload: {
      withGoogleAccessToken,
    },
  }),

  createPresentation: (
    withGoogleAccessToken: WithGoogleAccessToken,
    data: DN.Models.GoogleSlides.Api.Create.Req,
  ) => ({
    type: ApiBcastGoogleSlidesAT.CREATE_PRESENTATION,
    payload: {
      withGoogleAccessToken,
      data,
    },
  }),

  addImageSlide: (
    withGoogleAccessToken: WithGoogleAccessToken,
    presentation: DN.Models.GoogleSlides.ModelBack,
    data: DN.Models.GoogleSlides.Api.AddImageSlide.Req,
  ) => ({
    type: ApiBcastGoogleSlidesAT.ADD_IMAGE_SLIDE,
    payload: {
      withGoogleAccessToken,
      presentation,
      data,
    },
  }),

  createAndAddImageSlide: (
    withGoogleAccessToken: WithGoogleAccessToken,
    createData: DN.Models.GoogleSlides.Api.Create.Req,
    imageData: DN.Models.GoogleSlides.Api.AddImageSlide.Req,
  ) => ({
    type: ApiBcastGoogleSlidesAT.CREATE_PRESENTATION_AND_ADD_IMAGE_SLIDE,
    payload: {
      withGoogleAccessToken,
      createData,
      imageData,
    },
  }),
} as const
