import { ofType, StateObservable } from 'redux-observable'
import { mergeMap, Observable } from 'rxjs'
import { PenColor } from '../../../../../constants/pen-color'
import { StoreState } from '../../../../../models/app/model'
import { DrawService } from '../../../../../services/draw'
import {
  PaintBCastStreamDrawAC,
  PaintBCastStreamDrawAT,
} from '../../../../actions/paint-bcast-stream/draw/actions'
import { EpicPaintBcastStreamDrawCommandMC } from '../commands/mutators'
import { EpicPaintBcastStreamDrawStartMC } from './mutators'

type Action = ReturnType<typeof PaintBCastStreamDrawAC.start>

export const paintBCastStreamDrawStartEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(PaintBCastStreamDrawAT.START),

    mergeMap(({ payload }) => {
      const { canvas, width, height } = payload

      const { strokeWidth, hasAlpha, colorKey } = state$.value.paintBcastStreamDraw

      DrawService.Set.strokeWidth(strokeWidth)

      DrawService.Set.hasAlpha(hasAlpha)

      DrawService.Set.color(PenColor[colorKey].color)

      const obs$ = new Observable<
        | ReturnType<typeof EpicPaintBcastStreamDrawStartMC.ok>
        | ReturnType<typeof EpicPaintBcastStreamDrawCommandMC.addPath>
        | ReturnType<typeof EpicPaintBcastStreamDrawCommandMC.remPath>
      >((observer) => {
        //

        const scope = DrawService.init()

        DrawService.setCanvas({ scope, canvas, width, height })

        const cmdAddOrRemPath$ = DrawService.startPainting$(scope)

        // -))

        observer.next(EpicPaintBcastStreamDrawStartMC.ok(canvas, scope))

        cmdAddOrRemPath$.subscribe({
          next: (command) => {
            switch (command.type) {
              case 'addPath':
                // -))
                observer.next(EpicPaintBcastStreamDrawCommandMC.addPath())
                break

              case 'remPath':
                // -))
                observer.next(EpicPaintBcastStreamDrawCommandMC.remPath(command.payload.isDirty))
                break
            }
          },

          complete: () => {
            // -|
            observer.complete()
          },
        })
      })

      return obs$
    }),
  )
