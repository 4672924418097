const prefix = 'mt-api-bcast-room-version-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastRoomVersionMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastRoomVersionMC = {
  ok: (roomKind: 'v1' | 'v2') => ({
    type: EpicApiBcastRoomVersionMT.OK,
    payload: {
      roomKind,
    },
  }),

  error: (errors: Infos[]) => ({
    error: true,
    type: EpicApiBcastRoomVersionMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
