import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-content'

// ~~~~~~ Component

export const STJoinedMobileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100%;

  .${ClassName}--main {
    height: 100%;
    width: 100%;
  }
`
