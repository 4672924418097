import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { HocIntl } from '@dn/hocs'

const initState = AppInitState.intl

type State = typeof initState

const reducer: any = {}

// ################################################################################
// ~~~~~~ Lang
// ################################################################################

reducer[HocIntl.Mutators.MT.CHANGE_LANG] = (
  state: State,
  { payload }: ReturnType<typeof HocIntl.Mutators.MC.chLang<Lang>>,
): State => {
  return {
    lang: payload.lang,

    messages: {
      ...state.messages,
      ...payload.messages,
    },
  }
}

// ~~~~~~

export const intlReducer = UtilsStore.dynReducer(initState, reducer, (state) => state)
