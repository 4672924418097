import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ModalsIds } from '../../constants/modals'
import { BcastTrackEvents } from '../../services/track-events'
import { ModalsMC } from '../../store/actions-mutators/modals/mutators'
import { ApiBcastProvidersAC } from '../../store/actions/api-bcast/providers/actions'
import { GoogleAuth } from '../../third-parties/google-api/library'
import { useTrackStreamContext } from '../track-events/use-track-stream-context'
import { useTrackUserContext } from '../track-events/use-track-user-context'

// ~~~~~~ Hook

export const useBcasterGConnect = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  const streamContext = useTrackStreamContext()

  // ~~~~~~ Handlers

  const connect = useCallback(() => {
    dispatch(
      ModalsMC.open(ModalsIds.GoogleSignIn, () => {
        GoogleAuth.grantAccess({
          onSuccess: (code) => {
            dispatch(ApiBcastProvidersAC.linkGoogle(code))

            // Track

            BcastTrackEvents.calls.Google.Bcaster.connect(userContext, streamContext)
          },
        })
      }),
    )
  }, [dispatch, userContext, streamContext])

  // ~~~~~~

  return connect
}
