import { AppInitState } from '../../../../../models/app/model'
import { FeedbackEndBcastFieldsMR } from '../../../../../models/bcast/feedback/end-bcast/model'
import { UtilsStore } from '@dn/utils'

const initState = AppInitState.feedbackEndBcast

// type State = typeof initState

const reducer: any = {
  ...FeedbackEndBcastFieldsMR.__model__.Reducer,
  ...FeedbackEndBcastFieldsMR.happiness.Reducer,
  ...FeedbackEndBcastFieldsMR.comments.Reducer,
  ...FeedbackEndBcastFieldsMR.getInTouch.Reducer,
}

// ~~~~~~

export const feedbackEndBcastReducer = UtilsStore.dynReducer(initState, reducer, (state) => state)
