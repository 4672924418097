import styled from 'styled-components'
import { theme } from '../../../../../../../../../style/utils/theme'
import {
  CommonStyleSvgColors,
  STJoinedTabletContentMainToolbarsButtonsCommonStyle,
} from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-tablet-content-main-toolbars-main-buttons-pip'

// ~~~~~~ Component

export const STJoinedTabletContentMainToolbarsMainButtonsPIP = styled.div`
  ${STJoinedTabletContentMainToolbarsButtonsCommonStyle}

  svg.${ClassName}--icon-off {
    rect.svg-out {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }

    rect.svg-in {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }

  svg.${ClassName}--icon-on {
    rect.svg-out {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }

    rect.svg-in {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }
`
