import { Config } from '../../../config'
import { ConstHttpVerbs } from '@dn/constants'

// ~~~~~~ [P]

const getPath = (path: string) => `${Config.Api.StunTurn.URI}${path}`

// ####################################################################################################
// ~~~~~~ Stream manager
// ####################################################################################################

// ~~~~~~ Credentials

const Credentials = {
  Get: {
    Method: ConstHttpVerbs.GET,
    Path: '/rest/credentials',
    getUrl: () => getPath(Credentials.Get.Path),

    Timeout: Config.Api.StunTurn.Timeout,
    DefaultStunTurnServers: Config.Api.StunTurn.DefaultServers,
  },
}

// ~~~~~~

export const ApiStunTurnRoutes = {
  Credentials,
} as const
