import { UtilsStore } from '@dn/utils'

const initModel: DN.Models.StreamContext.ModelFront = {
  id: '',
  bcastId: '',
  kind: 'sub',
}

// ~~~~~~ Fields Mutators/Reducers for Create

const prefix = 'mt-strean-context-create-field'

export const StreamContextFieldsMR = {
  __model__: UtilsStore.genModelMR(prefix, initModel),

  id: UtilsStore.genBasicFieldMR(prefix, initModel, 'id', initModel.id),
}

// ~~~~~~

const fromBackToFront = (
  model: DN.Models.StreamContext.ModelBack,
  bcastId: string,
  kind: DN.Models.StreamContext.Kind,
): DN.Models.StreamContext.ModelFront => {
  return {
    ...model,
    bcastId,
    kind,
  }
}

// ~~~~~~

export const StreamContextModel = {
  initModel,
  fromBackToFront,
} as const
