import { takeUntil } from 'rxjs'
import { UtilsWebSocket } from '@dn/utils'
import { CommChannelStop$$ } from '../subject/stop/comm-channel-partial-service'

export const commChannelSendMessage$ = (
  webSocket: WebSocket,
  message: {
    type: string
    payload?: any
  },
) => {
  return UtilsWebSocket.sendMessage$(webSocket, message).pipe(takeUntil(CommChannelStop$$))
}
