const initModel: DN.Models.StreamManagerServer.ModelFront = {
  uiStatus: 'init',
  errors: [],

  broadcastId: '',
  scope: 'live',
  serverAddress: '',
  region: '',
}

// ~~~~~~

const fromBackToFront = (
  model: DN.Models.StreamManagerServer.ModelBack,
): DN.Models.StreamManagerServer.ModelFront => {
  const broadcastId = model.name

  const modelCopy = {
    ...model,
    name: undefined,
  }

  delete modelCopy.name

  return {
    ...modelCopy,

    uiStatus: 'init',
    errors: [],
    broadcastId,
  }
}

// ~~~~~~

export const StreamManagerServerModel = {
  initModel,
  fromBackToFront,
}
