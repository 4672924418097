import { EpicApiServerSubMainMC } from '../../../api-stream-manager/server/sub-main/mutators'

const prefix = 'mt-api-bcast-stream-context-show'

// ~~~~~~ Mutators Types

export const EpicApiBcastStreamContextShowMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

type Data = ReturnType<typeof EpicApiServerSubMainMC.ok>['payload']

export const EpicApiBcastStreamContextShowMC = {
  ok: (streamContextId: string, bcastId: string, serverSubMainData: Data) => ({
    type: EpicApiBcastStreamContextShowMT.OK,
    payload: {
      streamContextId,
      bcastId,
      serverSubMainData,
    },
  }),
} as const
