import React from 'react'
import { useLayoutCupContext } from '../../hooks/use-layout-cup-context'

// ~~~~~~ Component

export const LayoutCupFooter: React.FC<any> = ({ children }) => {
  const { kind } = useLayoutCupContext()

  return <>{kind === 'footer' ? children : null}</>
}
