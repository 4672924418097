import { useAuth0 } from '@auth0/auth0-react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Config } from '../../config'
import { StoreState } from '../../models/app/model'

// ~~~~~~ Constants

const error: IntlMsgId = 'api.current-user.show.ErrorNoSub'

// ~~~~~~ Hook

export const useNoLicenseLogout = () => {
  // ~~~~~~ Hooks

  const { logout } = useAuth0()

  // ~~~~~~ State

  const [logoutCalled, setLogoutCalled] = useState(false)

  const currenUserErr = useSelector((state: StoreState) => state.currentUser.errors)

  // ~~~~~~ Computed

  const errorExist = currenUserErr.some((err) => err.id === error)

  if (!errorExist || logoutCalled) return

  !Config.AllBlocked &&
    logout({
      logoutParams: {
        returnTo: Config.LogoutNoLicenseUrl,
      },
    })

  setLogoutCalled(true)
}
