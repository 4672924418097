import { useSelector } from 'react-redux'
import { StoreState } from '../../../models/app/model'

// ~~~~~~ Hook

export const useCamMicSubSound = () => {
  // ~~~~~~ State

  const { iMutedAudio, iHaveTouchedAudioControl } = useSelector(
    (state: StoreState) => state.streamManagerSubCamMic,
  )

  // ~~~~~~ Computed

  const speakersAreEnabled = iHaveTouchedAudioControl ? !iMutedAudio : false // userHasInteractedBeforeCamStart

  const isVirgin = !iHaveTouchedAudioControl

  // ~~~~~~

  return {
    isVirgin,
    speakersAreEnabled,
    iHaveTouchedAudioControl,
  }
}
