import { ofType, StateObservable } from 'redux-observable'
import { catchError, EMPTY, filter, mergeMap, Observable, of, tap } from 'rxjs'
import { StoreState } from '../../../../models/app/model'
import { CommChannelService } from '../../../../services/comm-channel'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import {
  EpicBcastStartPubCamMicMC,
  EpicBcastStartPubCamMicMT,
} from '../../bcast/start-pub-cam-mic/mutators'
import { EpicCommChannelOnPubAvailableSendCamMicStartEpicMC } from './mutators'
import { BcastTrackEvents } from '../../../../services/track-events'
import { UtilsLog } from '../../../../utils/logs'
import { BroadcastAC } from '../../../actions/bcast/actions'

type Action = ReturnType<typeof EpicBcastStartPubCamMicMC.event>

export const onPubAvailableToSendCommMessageEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicBcastStartPubCamMicMT.EVENT),

    filter(({ payload }) => payload.type === 'Publish.Available'),

    mergeMap(() => {
      const webSocket = state$.value.commChannel.webSocket
      const curCamMicStream = state$.value.sharedCamMicStream.curCamMicStream

      const videoTrack = curCamMicStream?.getVideoTracks()[0]
      const audioTrack = curCamMicStream?.getAudioTracks()[0]

      if (!webSocket) return EMPTY

      return CommChannelService.SendMessage.WithAck.BcasterCamMicStreamStart$(webSocket, {
        camEnabled: videoTrack ? videoTrack.enabled : false,
        micEnabled: audioTrack ? audioTrack.enabled : false,
      }).pipe(
        catchError((err: any) => {
          UtilsLog.devLog(
            'BcasterCamMicStreamStart',
            'Error. WSState:',
            state$.value.commChannel.webSocket?.readyState,
          )

          return of({
            type: 'err',
          } as const)
        }),
      )
    }),

    // Track

    tap(({ type }) => {
      if (type !== 'ack') return

      const userContext = BcastTrackEvents.helpers.genUserContext(
        state$.value.currentUser,
        state$.value.subscription,
      )

      const streamContext = BcastTrackEvents.helpers.genStreamContext(
        state$.value.streamContext.id,
        'pub',
      )

      BcastTrackEvents.calls.Streams.PubCamMic.stream(userContext, streamContext)
    }),

    mergeMap(({ type }) => {
      switch (type) {
        case 'ack':
          return of(EpicCommChannelOnPubAvailableSendCamMicStartEpicMC.ok())

        case 'ack-timeout':
          return of(
            EpicCommChannelOnPubAvailableSendCamMicStartEpicMC.timeout(),
            CancelNowAC.cancelCamMic([CancelReasons.CommChannelAckTimeout]),
          )

        case 'err':
          return of(
            EpicCommChannelOnPubAvailableSendCamMicStartEpicMC.error(),
            BroadcastAC.exit('comm-channel-err'),
            CancelNowAC.cancelAll([CancelReasons.CommChannelErr]),
          )
      }
    }),
  )
