import { StorageKeys } from '../../../constants/storage'
import { UtilsStorageLocal } from '@dn/utils'

// ####################################################################################################
// ~~~~~~ Get debug lang from local storage
// ####################################################################################################

const get: () => boolean = () => {
  const langStored = UtilsStorageLocal.getItem(StorageKeys.DebugLang)

  return langStored === 'true'
}

// ~~~~~~

export const LocalStorageDebugLang = {
  get,
} as const
