import { Config } from '../config'

const { ProjectName } = Config

export const StorageKeys = {
  Lang: `${ProjectName}-lang`,
  SkipNewVersionMessage: `${ProjectName}-skip-new-version-message`,
  Theme: `${ProjectName}-theme`,
  Debug: `${ProjectName}-debug`,
  DebugLang: 'debug-lang',
  DebugExtension: 'debug-extension',
  UserOptions: `${ProjectName}-user-options`,
  UserSession: `${ProjectName}-user-session`,
  StoreDuration: `${ProjectName}-store-duration`,
} as const
