import { UtilsStore } from '@dn/utils'
import { Config } from '../../../config'
import { UtilsValidate } from '../../../utils/validations'

const planInitModel: DN.Models.StorePlan.PlanModel = {
  errors: [],

  uiStatusEmailPass: 'init',
  uiStatusVerificationCode: 'init',
  uiStatusSubscribing: 'init',
  uiStatusCheckout: 'init',
  uiStatusCheckPaymentMethod: 'init',
  uiStatusUpgradePlan: 'init',

  intent: 'new',

  priceId: '',

  kind: 'none',
  timeFrame: 'monthly',

  email: '',
  email_err: [],

  pass: '',
  pass_err: [],

  code: '',
  code_err: [],

  step: 1,
  portal_code: '',

  card: undefined,
}

// ~~~~~ Edit

const prefix = 'mt-plan-field'

// ~~~~~~ Fields Mutators/Reducers

export const StorePlanFieldsMR = {
  reset: UtilsStore.genResetModelMR(prefix, planInitModel),

  priceId: UtilsStore.genFieldMR(
    prefix,
    planInitModel,
    'priceId',
    planInitModel.priceId,
    Config.Intl.InfosForInfer,
  ),
  kind: UtilsStore.genFieldMR(
    prefix,
    planInitModel,
    'kind',
    planInitModel.kind,
    Config.Intl.InfosForInfer,
  ),
  timeFrame: UtilsStore.genFieldMR(
    prefix,
    planInitModel,
    'timeFrame',
    planInitModel.timeFrame,
    Config.Intl.InfosForInfer,
  ),
  email: UtilsStore.genFieldMR(
    prefix,
    planInitModel,
    'email',
    planInitModel.email,
    Config.Intl.InfosForInfer,
  ),
  pass: UtilsStore.genFieldMR(
    prefix,
    planInitModel,
    'pass',
    planInitModel.pass,
    Config.Intl.InfosForInfer,
  ),
  code: UtilsStore.genFieldMR(
    prefix,
    planInitModel,
    'code',
    planInitModel.code,
    Config.Intl.InfosForInfer,
  ),

  errors: UtilsStore.genFieldMR(
    prefix,
    planInitModel,
    'errors',
    planInitModel.errors,
    Config.Intl.InfosForInfer,
  ),
}

// ~~~~~~ Validations

const { Validations } = UtilsValidate

const validations = {
  Email: [{ error: Validations.Email }],

  Pass: [{ error: Validations.MinLength, value: 8 }],

  Code: [
    { error: Validations.MinLength, value: 6 },
    { error: Validations.MaxLength, value: 6 },
  ],
}

// ~~~~~

export const StorePlanModel = {
  planInitModel,
  validations,
}
