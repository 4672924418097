import { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fromEvent } from 'rxjs'
import { ConstEventsWindow } from '@dn/constants'

import { LayoutPanelsSizes } from '../../../../../../../../components/layouts/panels/style'
import { StoreState } from '../../../../../../../../models/app/model'
import { MediaQueryNumbers } from '../../../../../../../../style/constants/media-queries'
import { UtilsLog } from '../../../../../../../../utils/logs'
import { HooksData } from '@dn/hooks'

// ~~~~~~ Constants

const MediaQuerySpecialA = 1144

const Width = {
  MD: {
    pic: 444,
  },

  SpecialA: {
    pic: 540,
  },
}

const transition = {
  transition: {
    staggerChildren: 0.11,
    delayChildren: 0,
  },
}

// - Variants

const RootVariants = {
  pic: {
    width: 716,
    ...transition,
  },
  colors: {
    width: 232,
    ...transition,
  },
  pens: {
    width: 152,
    ...transition,
  },
}

const showX = {
  x: 0,
  y: 0,
  opacity: 1,
} as const

const showY = {
  x: 0,
  y: 0,
  opacity: 1,
} as const

const hideX = {
  x: -20,
  y: 0,
  opacity: 0,
} as const

const hideY = {
  x: 0,
  y: 20,
  opacity: 0,
} as const

// Tools

const VideoBtnVariants = {
  video: showX,
  pic: hideX,
  colors: hideX,
  pens: hideX,
  zooms: hideX,
} as const

const PicBtnVariants = {
  video: hideX,
  pic: showX,
  colors: hideX,
  pens: hideX,
  zooms: hideX,
} as const

// Subtools

const ColorsBtnVariants = {
  video: hideX,
  pic: hideY,
  colors: showY,
  pens: hideY,
  zooms: hideY,
} as const

const PensBtnVariants = {
  video: hideX,
  pic: hideY,
  colors: hideY,
  pens: showY,
  zooms: hideY,
} as const

const ZoomsBtnVariants = {
  video: hideY,
  pic: hideX,
  colors: hideY,
  pens: hideY,
  zooms: showY,
} as const

// - Data

// -- 960

const DataMD = {
  showAllColors: false,
  showAllPens: false,
  isXS: false,
  picWidth: Width.MD.pic,
} as const

// -- 1144

const DataSpecialA = {
  showAllColors: false,
  showAllPens: true,
  isXS: false,
  picWidth: Width.SpecialA.pic,
} as const

// -- Default, bigest

const DataDefault = {
  showAllColors: true,
  showAllPens: true,
  isXS: false,
  picWidth: RootVariants.pic.width,
} as const

// ~~~~~~ Types

type Data = typeof DataMD | typeof DataSpecialA | typeof DataDefault

// ~~~~~~ Hook

export const useJoinedTabletContentMainToolbarsCapturePaintResizeAnimation = () => {
  // ~~~~~~ State

  // - Local

  const [data, setData] = useState<Data>(DataMD)

  const [showZoomTools, setShowZoomTools] = useState(true)

  const [menuIsCollapsed, setMenuIsCollapsed] = useState(false)

  // - Store

  const layoutPanels = useSelector((state: StoreState) => state.uiLayoutPanels)

  // ~~~~~~~ Dependent hooks

  const prevLeftPanelOpenState = HooksData.PrevValue.useHook(layoutPanels.leftPanelOpenState)

  const prevLeftPanelDisplayValue = HooksData.PrevValue.useHook(layoutPanels.leftPanelDisplayValue)

  // ~~~~~~~ Computed

  const newRootVariants: typeof RootVariants = {
    ...RootVariants,

    pic: {
      ...RootVariants.pic,
      width: data.picWidth,
    },
  }

  // ~~~~~~ Callbacks

  const updateState = useCallback(() => {
    let componentWidth = window.innerWidth

    if (
      layoutPanels.leftPanelDisplayValue !== 'hidden' &&
      layoutPanels.leftPanelOpenState === 'open'
    ) {
      const panelSize =
        layoutPanels.leftPanelDisplayValue === 'full'
          ? LayoutPanelsSizes.LeftPanelSize
          : LayoutPanelsSizes.LeftPanelSizeMiddle

      componentWidth = componentWidth - panelSize
    }

    // Add calc based on layoutPanels

    switch (true) {
      case componentWidth <= MediaQueryNumbers.MD:
        if (data === DataMD) return

        UtilsLog.devLog('Joined tablet toolbar-resize MD')

        setData(DataMD)

        break
      case componentWidth <= MediaQuerySpecialA:
        if (data === DataSpecialA) return

        UtilsLog.devLog('Joined tablet toolbar-resize Special A')

        setData(DataSpecialA)

        break

      default:
        UtilsLog.devLog('Joined tablet toolbar-resize Default')

        data !== DataDefault && setData(DataDefault)
    }
  }, [data, layoutPanels.leftPanelDisplayValue, layoutPanels.leftPanelOpenState])

  // ~~~~~~ Effects

  // - On Mount calculate the initial width of the toolbar

  useEffect(() => {
    updateState()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - On resize recalculate the width for the toolbar

  useEffect(() => {
    const sub = fromEvent(window, ConstEventsWindow.Resize).subscribe({
      next: () => {
        updateState()
      },
    })

    return () => {
      sub.unsubscribe()
    }
  }, [updateState])

  // - On change left panel display value, recalculate the width for the toolbar

  useEffect(() => {
    if (layoutPanels.leftPanelDisplayValue === prevLeftPanelDisplayValue) return

    updateState()

    //
  }, [layoutPanels.leftPanelDisplayValue, prevLeftPanelDisplayValue, updateState])

  // - On change left panel open state, recalculate the width for the toolbar

  useEffect(() => {
    if (layoutPanels.leftPanelOpenState === prevLeftPanelOpenState) return

    updateState()

    //
  }, [layoutPanels.leftPanelOpenState, prevLeftPanelOpenState, updateState])

  // ~~~~~~~

  return {
    animations: {
      video: 'video',
      editPicture: 'pic',
      colors: 'colors',
      pens: 'pens',
      zooms: 'zooms',
    },

    rootVariants: newRootVariants,
    videoBtnVariants: VideoBtnVariants,
    picBtnVariants: PicBtnVariants,
    colorsBtnVariants: ColorsBtnVariants,
    pensBtnVariants: PensBtnVariants,
    zoomsBtnVariants: ZoomsBtnVariants,

    showAllColors: data.showAllColors,
    showAllPens: data.showAllPens,
    isXS: data.isXS,

    showZoomTools,
    setShowZoomTools,

    menuIsCollapsed,
    setMenuIsCollapsed,
  }
}
