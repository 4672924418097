import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../models/app/model'
import { StartedLeftPanelMain } from './main/component'
import { StartedLeftPanelSharePaint } from './share-paint/component'
import { Padding, STStartedLeftPanel } from './style'

// ~~~~~~ Component

export const StartedLeftPanel = () => {
  // ~~~~~~ State

  // - Store

  const layoutPanels = useSelector((state: StoreState) => state.uiLayoutPanels)

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Render

  return (
    <STStartedLeftPanel ref={selfRef}>
      {layoutPanels.leftPanelShowSharePaints ? (
        <StartedLeftPanelSharePaint panelRef={selfRef} panelMargin={Padding * 2} />
      ) : (
        <StartedLeftPanelMain panelRef={selfRef} panelMargin={Padding * 2} />
      )}
    </STStartedLeftPanel>
  )
}
