import { StateObservable, ofType } from 'redux-observable'
import { catchError, EMPTY, mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../../../models/app/model'
import { CommChannelService } from '../../../../../../../services/comm-channel'
import { CancelNowAC, CancelReasons } from '../../../../../../actions/cancel-now/actions'
import { CommChannelAC, CommChannelAT } from '../../../../../../actions/comm-channel/actions'
import { EpicBcastCommChannelMessagesToServerBcasterCamMicStopMC } from './mutators'
import { UtilsLog } from '../../../../../../../utils/logs'

type Action = ReturnType<typeof CommChannelAC.sendBcasterCamMicStop>

export const commChannelMessagesToServerBcasterCamMicStopEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(CommChannelAT.SEND_BCASTER_CAM_MIC_STOP),

    mergeMap(() => {
      const websocket = state$.value.commChannel.webSocket

      if (!websocket) return EMPTY

      return CommChannelService.SendMessage.WithAck.BcasterCamMicStreamStop$(websocket).pipe(
        catchError((err: any) => {
          UtilsLog.devLog(
            'BcasterCamMicStreamStop',
            'Error. WSState:',
            state$.value.commChannel.webSocket?.readyState,
          )

          return of({
            type: 'err',
          } as const)
        }),
      )
    }),

    mergeMap((res) => {
      switch (res.type) {
        case 'ack':
          return of(
            EpicBcastCommChannelMessagesToServerBcasterCamMicStopMC.ok(),
            CancelNowAC.cancelCamMic([CancelReasons.UserStopped]),
          )

        case 'ack-timeout':
          return of(
            EpicBcastCommChannelMessagesToServerBcasterCamMicStopMC.timeout(),
            CancelNowAC.cancelCamMic([CancelReasons.CommChannelAckTimeout]),
          )

        case 'err':
          return of(
            EpicBcastCommChannelMessagesToServerBcasterCamMicStopMC.error(),
            CancelNowAC.cancelAll([CancelReasons.CommChannelErr]),
          )
      }
    }),
  )
