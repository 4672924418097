import { AppInitState } from '../../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ApiBcastGoogleClassRoomAT } from '../../../../actions/api-bcast/google-classroom/actions'
import {
  EpicApiBcastGoogleClassRoomListMC,
  EpicApiBcastGoogleClassRoomListMT,
} from '../../../../epics/api-bcast/google-classroom/list/mutators'
import {
  EpicApiBcastProvidersUnlinkGoogleMC,
  EpicApiBcastProvidersUnlinkGoogleMT,
} from '../../../../epics/api-bcast/providers/unlink-google/mutators'

const initState = AppInitState.googleClassRoomMetaList

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Unlink Google - OK
// ####################################################################################################

reducer[EpicApiBcastProvidersUnlinkGoogleMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastProvidersUnlinkGoogleMC.ok>,
): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ List - START
// ####################################################################################################

reducer[ApiBcastGoogleClassRoomAT.LIST] = (state: State): State => {
  return {
    ...state,
    errors: [],
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ List - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomListMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastGoogleClassRoomListMC.ok>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    list: UtilsStore.setList(payload, {
      sortBy: state.sortBy,
      order: state.sortDir,
    }),
  }
}

// ####################################################################################################
// ~~~~~~ List - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomListMT.ERROR] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ~~~~~~

export const googleClassRoomMetaListReducer = UtilsStore.dynReducer(initState, reducer)
