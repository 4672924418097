import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toobars-main'

// ~~~~~~ Component

export const STJoinedMobileToolbarsMain = styled.div`
  background-color: ${theme(Colors.WOLF_00_90)};

  border-radius: 100px;

  .${ClassName}--content {
    padding: ${Layout.Spacing[1]}px 0;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @media (orientation: landscape) {
    .${ClassName}--content {
      padding: 0 ${Layout.Spacing[1]}px;
    }
  }
`
