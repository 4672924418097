import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { Config } from '../../config'
import { ModalsIds } from '../../constants/modals'
import { ModalsMC } from '../../store/actions-mutators/modals/mutators'
import { ApiBcastCurrentUserAC } from '../../store/actions/api-bcast/current-user/actions'
import { UtilsLog } from '../../utils/logs'

// ~~~~~~ Constants

// 7 mins like banks

const MaxTimeInSecondsWaitingCredentials = 7 * 60

// This size is copied from Auth0 lib code

const CredentialPopupSize = {
  width: 400,
  height: 600,
}

// ~~~~~~ Types

type LoginOpts = {
  withThrow?: true
  runOnLogin?: () => void
}

// ~~~~~~ Hook

export const useCustomAuth = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const { getAccessTokenWithPopup, logout, isLoading } = useAuth0()

  // ~~~~~~

  function login(opts?: LoginOpts) {
    // All blocked dialog

    if (Config.AllBlocked) {
      dispatch(ModalsMC.open(ModalsIds.ConfirmAllBlocked))

      return
    }

    // Prepare popup to host the credentials view
    // let us to control it so we can close it on demand

    const ownPopup = prepareAuth0Popup('', CredentialPopupSize)

    // Option 'prompt: login' prompts user for reauthentication

    return getAccessTokenWithPopup(
      // Options
      {
        authorizationParams: {
          prompt: 'login',
        },
      },
      // Config
      {
        timeoutInSeconds: MaxTimeInSecondsWaitingCredentials,
        popup: ownPopup,
      },
    )
      .then((token) => {
        // UtilsLog.devLog('Auth0 token', token)

        if (!token) {
          throw new Error('no-token')
        }

        dispatch(ApiBcastCurrentUserAC.show(token))
      })
      .catch((err: Error) => {
        UtilsLog.devWarn('useCustomAuth', err)

        // Close credentials popup

        ownPopup?.close()

        if (err.message === 'Timeout') {
          dispatch(ModalsMC.open(ModalsIds.ConfirmSignInTimeout))
        }

        if (opts?.withThrow) throw err
      })
  }

  return {
    login,
    logout,
    isLoading: Config.AllBlocked ? false : isLoading,
  } as const
}

// ####################################################################################################
// ~~~~~~ Create a popup for Auth0 credentials
// ####################################################################################################

const prepareAuth0Popup = (url: string, { width = 400, height = 600 } = {}) => {
  const left = window.screenX + (window.innerWidth - width) / 2

  const top = window.screenY + (window.innerHeight - height) / 2

  return window.open(
    url,
    'auth0:authorize:popup',
    `left=${left},top=${top},width=${width},height=${height},resizable,scrollbars=yes,status=1`,
  )
}
