import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { STPaintToolbarButtonsClear, STProps } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/bin')

// ~~~~~~ Types

type Props = STProps & {
  onClick: () => void
}

// ~~~~~~ Component

export const PaintToolbarButtonsClear: React.FC<Props> = ({ $disabled, onClick }) => {
  // ~~~~~~ Render

  return (
    <STPaintToolbarButtonsClear $disabled={$disabled} onClick={onClick}>
      <Icon size={24} />
    </STPaintToolbarButtonsClear>
  )
}
