import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-content-main'

// ~~~~~~ Component

export const STJoinedMobileContentMain = styled.div`
  touch-action: none;

  width: 100%;
  height: 100%;

  background-color: ${theme(Colors.WOLF_05_100)};

  display: flex;
  justify-content: center;

  position: relative;

  overflow: hidden;

  transition:
    width 0.2s,
    height 0.2s;

  /* Content */

  .${ClassName}--content {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Layout.Border.Radius.A}

    overflow: hidden;

    width: 100%;
    height: 100%;

    /* Video Wrapper */

    .${ClassName}--content--video-wrapper {
      position: relative;

      width: 100%;

      /* Required in mobile */
      height: 100%;

      ${Layout.Border.Radius.A}

      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: center;

      /* Video */

      .${ClassName}--content--video-wrapper--video {
        z-index: 4;
        width: 100%;
        height: 100%;
      }
    }
  }
`
