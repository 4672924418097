import { AppInitState } from '../../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ApiStreamManagerServerAT } from '../../../../actions/api-stream-manager/server/actions'
import { CancelNowAT } from '../../../../actions/cancel-now/actions'
import {
  EpicApiServerSubCamMicMC,
  EpicApiServerSubCamMicMT,
} from '../../../../epics/api-stream-manager/server/sub-cam-mic/mutators'

const initState = AppInitState.streamManagerServerSubCamMic

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Get server
// ####################################################################################################

reducer[ApiStreamManagerServerAT.GET_SERVER_SUB_CAM_MIC] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Get server Ok
// ####################################################################################################

reducer[EpicApiServerSubCamMicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiServerSubCamMicMC.ok>,
): State => {
  return {
    ...state,
    ...payload.server,
    uiStatus: 'completed',
  }
}

// ####################################################################################################
// ~~~~~~ Get server Error
// ####################################################################################################

reducer[EpicApiServerSubCamMicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiServerSubCamMicMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel now all - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Cancel now cam-mic - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_CAM_MIC] = (): State => {
  return initState
}

// ~~~~~~

export const streamManagerServerSubCamMicReducer = UtilsStore.dynReducer(initState, reducer)
