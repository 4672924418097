import { Config } from '../../../../../config'
import { UtilsLog } from '../../../../../utils/logs'
import { UtilsString } from '@dn/utils'

// ~~~~~~ Constants

export const RemPathProp = '___ERASE___'

// ~~~~~~ Command

export const remPathCommand = (
  drawingsTarget: DN.Services.Draw.DrawingsTarget,
  { items }: DN.Services.Draw.RemPathData,
) => {
  //

  const eraseId = UtilsString.genRandomStr()

  return {
    name: 'remPath',

    execute() {
      Config.Logs.DrawService &&
        UtilsLog.devLog('\nDrawService', 'commands', 'remPathCommand', 'execute', eraseId, items)

      drawingsTarget.cmdApplied += 1

      items.forEach((item) => {
        const anyItem = item as any

        anyItem[RemPathProp] = eraseId

        anyItem.setVisible(false)
      })
    },

    undo() {
      Config.Logs.DrawService &&
        UtilsLog.devLog('\nDrawService', 'commands', 'remPathCommand', 'undo', eraseId)

      drawingsTarget.cmdApplied -= 1

      const layer = drawingsTarget.scope.project.getItems({})[0]

      if (!layer) return

      const layerItems = layer.getItems({}).slice(1) // First element is Roster

      layerItems.forEach((item) => {
        const anyItem = item as any

        if (anyItem[RemPathProp] !== eraseId) return

        anyItem.setVisible(true)

        anyItem[RemPathProp] = undefined
      })
    },
  }
}
