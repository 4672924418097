const prefix = 'at-api-store-checkout'

// ~~~~~~ Action Types

export const ApiStoreCheckoutAT = {
  CANCEL: `${prefix}-cancel`,

  SEND_EMAIL_PASS: `${prefix}-send-email-pass`,

  VALIDATE_EMAIL_CODE: `${prefix}-validate-email-code`,

  CREATE_SESSION: `${prefix}-create-session`,
} as const

// ~~~~~~ Action Creators

export const ApiStoreCheckoutAC = {
  cancel: () => ({
    type: ApiStoreCheckoutAT.CANCEL,
  }),

  sendEmailPass: (data: DN.Models.StoreCheckout.Api.SendEmailPass.Req) => ({
    type: ApiStoreCheckoutAT.SEND_EMAIL_PASS,
    payload: data,
  }),

  validateEmailCode: (data: DN.Models.StoreCheckout.Api.ValidateEmailCode.Req) => ({
    type: ApiStoreCheckoutAT.VALIDATE_EMAIL_CODE,
    payload: data,
  }),

  createSession: (data: DN.Models.StoreCheckout.Api.CreateSession.Req) => ({
    type: ApiStoreCheckoutAT.CREATE_SESSION,
    payload: {
      data,
    },
  }),
} as const
