import { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { interval, map, takeWhile } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { LivePaintDrawAC } from '../../../../../store/actions/live-paint/draw/actions'
import { UtilsCompare, UtilsPaintFramework } from '@dn/utils'
import { UtilsLog } from '../../../../../utils/logs'
import { ClassName, STStartedContentMainLivePaint } from './style'

let savedPaintCanvas: HTMLCanvasElement | undefined = undefined

export const clearSavedPaintCanvas = (from: string) => {
  UtilsLog.devLog('Clear savedPaintCanvas', 'from', from)

  savedPaintCanvas = undefined
}

// ~~~~~~ Component

export const StartedContentMainLivePaint = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Store

  const {
    canvas: liveDrawCanvas,

    activePen,
    isErasing,
  } = useSelector((state: StoreState) => state.livePaintDraw)

  const { video, originalVideoW, originalVideoH } = useSelector(
    (state: StoreState) => state.livePaintVideo,
  )

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  const canvasesWrapperRef = useRef<HTMLDivElement>(null)

  const paintCanvasRef = useRef<HTMLCanvasElement | null>(null)

  // ~~~~~~ Computed

  const isVideoEmpty =
    !!video && UtilsCompare.allValuesAre(video.getBoundingClientRect().toJSON(), 0)

  // ~~~~~~ Callbacks

  const setCanvasesWrapperSize = useCallback(() => {
    if (!video || isVideoEmpty) return

    const self = selfRef.current
    const canvasesWrapper = canvasesWrapperRef.current

    if (!video || !self || !canvasesWrapper) return

    const relW = self.clientWidth
    const relH = self.clientHeight

    const { width, height, top, left } = UtilsPaintFramework.calcRelativeSizeAndTranslate(
      {
        oW: originalVideoW,
        oH: originalVideoH,
        relW,
        relH,
        scale: 1,
      },
      {
        video,
        framework: self,
      },
    )

    canvasesWrapper.style.width = `${width}px`
    canvasesWrapper.style.height = `${height}px`

    canvasesWrapper.style.top = `${top}px`
    canvasesWrapper.style.left = `${left}px`

    dispatch(LivePaintDrawAC.updateView(width, height))

    //
  }, [dispatch, isVideoEmpty, originalVideoH, originalVideoW, video])

  // ~~~~~~ Effects

  // - Start Painting

  useEffect(() => {
    if (savedPaintCanvas) return

    const framework = selfRef.current
    const paintCanvas = paintCanvasRef.current

    // liveDrawCanvas is setted on start

    if (!framework || !paintCanvas || !originalVideoW || !originalVideoH) {
      return
    }

    // We are painting when both canvas are the same

    if (liveDrawCanvas === paintCanvas) return

    // If component unmount and mount with some draw
    // we obtain two differents canvas and we need to
    // keep the draw of the stored one

    if (liveDrawCanvas) {
      // Replace new canvas with the stored one in order to keep the draw

      framework.replaceChild(liveDrawCanvas, paintCanvas)

      paintCanvasRef.current = liveDrawCanvas

      return
    }

    // Save the canvas to get it back if we navigate to settings

    savedPaintCanvas = paintCanvas

    // Stop is launched from hook on close toolbar

    dispatch(LivePaintDrawAC.start(paintCanvas, framework.clientWidth, framework.clientHeight))

    //
  }, [dispatch, liveDrawCanvas, originalVideoH, originalVideoW])

  // - On mount or callback change (original video dimensions change) set canvases wrapper w/h

  useEffect(() => {
    setCanvasesWrapperSize()

    //
  }, [setCanvasesWrapperSize])

  // - Observe self resize to update canvases wrapper
  //   this happens on resize the window

  useEffect(() => {
    const self = selfRef.current

    if (!self) return

    const resizeObserve = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        setCanvasesWrapperSize()
      })
    })

    resizeObserve.observe(self)

    return () => {
      resizeObserve.disconnect()
    }
  }, [setCanvasesWrapperSize])

  // - On mount check if we have the savedPaintCanvas, to replace the current one
  //   and continue painting

  useEffect(() => {
    const sub = interval(50)
      .pipe(
        takeWhile(() => {
          if (!savedPaintCanvas) return false

          const paintCanvas = paintCanvasRef.current

          if (paintCanvas === savedPaintCanvas) return false

          return true
        }),

        map(() => {
          const canvasesWrapper = canvasesWrapperRef.current
          const paintCanvas = paintCanvasRef.current

          return [canvasesWrapper, paintCanvas]
        }),
      )
      .subscribe({
        next: ([canvasesWrapper, paintCanvas]) => {
          if (!canvasesWrapper || !paintCanvas || !savedPaintCanvas) return

          if (paintCanvas === savedPaintCanvas) return

          canvasesWrapper.replaceChild(savedPaintCanvas, paintCanvas)

          paintCanvasRef.current = savedPaintCanvas
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  // ~~~~~~ Render

  if (!video || isVideoEmpty) return null

  return (
    <STStartedContentMainLivePaint ref={selfRef} $activePen={activePen} $isErasing={isErasing}>
      {/* Canvas wrapper */}
      {/* Canvas w/h equals to video 'frame' not video tag */}
      {/* Video tag uses all the space like div and centers the video frame */}

      <div ref={canvasesWrapperRef} className={`${ClassName}--canvases-wrapper`}>
        {/* Paint canvas */}

        <canvas
          ref={paintCanvasRef}
          className={`${ClassName}--canvases-wrapper--paint`}
          data-paper-hidpi="off"
        />

        {/* -- */}
      </div>
    </STStartedContentMainLivePaint>
  )
}
