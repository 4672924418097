import { StateObservable, ofType } from 'redux-observable'
import { EMPTY, mergeMap, Observable } from 'rxjs'
import { StoreState } from '../../../../../../models/app/model'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../../actions/live-paint/draw/actions'
import { EpicLivePaintReplaceTrackOnStopDrawMC } from '../mutators'

type Action = ReturnType<typeof LivePaintDrawAC.stop>

export const deprecatedLivePaintReplaceTrackOnDrawStopEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.STOP),

    mergeMap(() => {
      const state = state$.value

      const publisher = state.streamManagerPubMain.publisher

      if (!publisher) return EMPTY

      const peerConn = publisher.getPeerConnection()

      const { curScreenVideoTrack } = state.sharedMainStream

      if (!curScreenVideoTrack) return EMPTY

      const { video } = state.livePaintVideo

      if (!video) return EMPTY

      const transceiver = peerConn.getTransceivers()[0]

      const obs$ = new Observable((observer) => {
        transceiver.sender.replaceTrack(curScreenVideoTrack).then(() => {
          const { canvasStreamStop } = state$.value.sharedMainStream

          // --|
          if (!canvasStreamStop) return observer.complete()

          canvasStreamStop()

          // --))-|
          observer.next(EpicLivePaintReplaceTrackOnStopDrawMC.ok())
          observer.complete()
        })
      })

      return obs$
    }),
  )
