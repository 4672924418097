import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

export const STStartedHeaderTopRightViewers = styled.div`
  ${Fonts.Scale.Body}

  padding: ${Layout.Spacing[2]}px;
  padding-right: ${Layout.Spacing[3]}px;

  display: flex;
  align-items: center;

  color: ${theme(Colors.WOLF_100_05)};

  .started-top-right-viewers-icon {
    width: 20px;
    height: 20px;

    padding: 2px;

    margin-right: ${Layout.Spacing[2] - 2}px; // -icon padding

    svg path {
      fill: ${theme(Colors.WOLF_100_05)};
    }
  }
`
