import { StateObservable, ofType } from 'redux-observable'
import { Observable, map, mergeMap } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { apiBcastStreamContextCreate$ } from '../../../../../services/api-bcast/stream-context/create'
import { EpicApiBcastStreamContextCreateMC } from './mutators'
import {
  ApiBcastStreamContextAC,
  ApiBcastStreamContextAT,
} from '../../../../actions/api-bcast/stream-context/actions'

type Action = ReturnType<typeof ApiBcastStreamContextAC.create>

export const apiBcastStreamContextCreateEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastStreamContextAT.CREATE),

    mergeMap(({ payload }) =>
      apiBcastStreamContextCreate$(state$.value.currentUser.token, payload.broadcastId),
    ),

    map((res) => EpicApiBcastStreamContextCreateMC.ok(res.response)),
  )
