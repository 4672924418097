import { css } from 'styled-components'
import { Transition } from '../../../../../../../../style/animations/css-animations'
import { Colors } from '../../../../../../../../style/theme/colors'

// ~~~~~~ Constants

export const CommonStyleSvgColors = {
  color: Colors.WOLF_100_05,
}

export const CommonStyleSize = 56

// ~~~~~~ CSS

export const STJoinedDesktopContentMainToolbarsButtonsCommonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${CommonStyleSize}px;
  height: ${CommonStyleSize}px;

  svg {
    height: 24px;
    width: 24px;

    path {
      ${Transition.FillStrokeFaster}
    }
  }

  &.with-tooltip {
    .tooltiptext {
      width: 200px;
      white-space: break-spaces;
      top: -34px;
      bottom: unset;

      /* Debug */
      /* visibility: visible;
      opacity: 1; */
    }
  }
`
