import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable } from 'rxjs'
import { StoreState } from '../../../../models/app/model'
import { FeedbackAC, FeedbackAT } from '../../../actions/feedback/actions'

type Action = ReturnType<typeof FeedbackAC.sendEndBcast>

export const sendFeedbackEndBcastEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(FeedbackAT.SEND_END_BCAST),

    mergeMap(({ payload }) => {
      // ...

      return EMPTY
    }),
  )
