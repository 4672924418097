import React from 'react'
import { Trans } from '../../intl/trans'
import { STInfos } from './style'

// ~~~~~~ Types

type Props = {
  infos: Infos[]
  className?: string
}

// ~~~~~~ Component

export const InfosList: React.FC<Props> = ({ infos, className }) => {
  if (!infos.length) {
    return null
  }

  return (
    <STInfos className={className}>
      {infos.map((err, idx) => (
        <div key={idx}>
          <Trans id={err.id} values={err.values} />
        </div>
      ))}
    </STInfos>
  )
}
