import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../../../../models/app/model'
import { UiMobileToolbarFieldsMR } from '../../../../../../../models/bcast/ui-mobile-toolbars/model'
import { ClassName, STJoinedMobileToolbarsCapturePaintButtonsEraser } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/eraser')

// ~~~~~~ Types

type Props = {
  onClick: () => void
}

// ~~~~~~ Component

export const JoinedMobileToolbarsCapturePaintButtonsEraser: React.FC<Props> = ({ onClick }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const isErasing = useSelector((state: StoreState) => state.paintBcastStreamDraw.isErasing)

  // ~~~~~~ Handlers

  function onClickH() {
    onClick()

    dispatch(UiMobileToolbarFieldsMR.openStatus.MC.change('painting-closed'))
  }

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsCapturePaintButtonsEraser onClick={onClickH}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon ${isErasing ? 'selected' : ''}`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsCapturePaintButtonsEraser>
  )
}
