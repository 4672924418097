const prefix = 'at-api-bcast-user-settings'

// ~~~~~~ Action Types

export const ApiBcastUserSettingsAT = {
  SHOW: `${prefix}-show`,
  UPDATE: `${prefix}-update`,
  DELETE: `${prefix}-delete`,
} as const

// ~~~~~~ Action Creators

export const ApiBcastUserSettingsAC = {
  show: (userId: string) => ({
    type: ApiBcastUserSettingsAT.SHOW,
    payload: {
      userId,
    },
  }),

  update: (userId: string, patch: Partial<DN.Models.UserSettings.ModelBack>) => ({
    type: ApiBcastUserSettingsAT.UPDATE,
    payload: {
      userId,
      patch,
    },
  }),

  delete: (userId: string) => ({
    type: ApiBcastUserSettingsAT.DELETE,
    payload: {
      userId,
    },
  }),
} as const
