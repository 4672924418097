import { trackCalls } from './events/calls'
import { bcastTrackEventsHelpers } from './helpers/track-events-partial-service'
import { bcastTrackEventsInit } from './init/track-events-partial-service'

// ~~~~~~

export const BcastTrackEvents = {
  init: bcastTrackEventsInit,
  helpers: bcastTrackEventsHelpers,
  calls: trackCalls,
} as const
