import { motion } from 'framer-motion'
import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-capture-paint-buttons-colors'

// ~~~~~~ Component

export const STMotionJoinedMoblieToolbarsCapturePaintButtonsColors = styled(motion.div)`
  align-items: center;
  justify-content: center;
`
