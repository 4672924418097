import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { ClassName, STJoinedMobileToolbarsCapturePaintButtonsUndo } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/undo')

// ~~~~~~ Types

type Props = {
  onClick: () => void
}

// ~~~~~~ Component

export const JoinedMobileToolbarsCapturePaintButtonsUndo: React.FC<Props> = ({ onClick }) => {
  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsCapturePaintButtonsUndo onClick={onClick}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsCapturePaintButtonsUndo>
  )
}
