import { Observable, mergeMap, of } from 'rxjs'
import { ExtensionAC, ExtensionAT } from '../../../actions/extension/actions'
import { ofType } from 'redux-observable'
import { UnknownAction } from 'redux'
import { UtilsDates, UtilsLang, UtilsOS } from '@dn/utils'
import { CowntdownFreePremiumDaysAC } from '../../../actions/countdown/free-premium-days/actions'
import { CountdownMidnightBcastTimeResetAC } from '../../../actions/countdown/midnight-bcast-time-reset/actions'
import { IntlAC } from '../../../actions/intl/actions'
import { HocTheme } from '@dn/hocs'
import { Config } from '../../../../config'
import { EpicExtensionUserDataMC } from './mutators'

// ~~~~~~ Actions

type Action = ReturnType<typeof ExtensionAC.userData>

// ~~~~~~ Epic

export const extensionUserDataEpic$ = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(ExtensionAT.USER_DATA),

    mergeMap(({ payload }) => {
      const { subscription, userSettings } = payload

      // Show

      const actions: UnknownAction[] = [EpicExtensionUserDataMC.ok(payload)]

      if (UtilsDates.isValidStringDate(subscription.free_premium_period_end)) {
        actions.push(CowntdownFreePremiumDaysAC.start(subscription.free_premium_period_end))
      }

      if (subscription.time_limit) {
        actions.push(CountdownMidnightBcastTimeResetAC.start(subscription.id))
      }

      // Lang

      const langAction =
        !userSettings.lang || userSettings.lang === 'auto'
          ? IntlAC.changeLang(
              UtilsLang.getAppLang(Config.Intl.SupportedLocales, Config.Intl.DefaultLocale),
            )
          : IntlAC.changeLang(userSettings.lang)

      actions.push(langAction)

      // Theme

      const themeAction =
        !userSettings.theme || userSettings.theme === 'auto'
          ? HocTheme.Mutators.MC.changeTheme(UtilsOS.getTheme())
          : HocTheme.Mutators.MC.changeTheme(userSettings.theme)

      actions.push(themeAction)

      // All actions

      return of(...actions)
    }),
  )
