import { useSelector } from 'react-redux'
import { LazySVG } from '../../../../../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../../../../../models/app/model'
import { ClassName, STJoinedMobileToolbarsCapturePaintButtonsPensHighlighter } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/tool-highlighter')

// ~~~~~~ Types

type Props = {
  onClick: () => void
}

// ~~~~~~ Component

export const JoinedMobileToolbarsCapturePaintButtonsPensHighlighter: React.FC<Props> = ({
  onClick,
}) => {
  // ~~~~~~ State

  const { activePen, isErasing } = useSelector((state: StoreState) => state.paintBcastStreamDraw)

  // ~~~~~~ Computed

  const selected = !isErasing && activePen === 'highlighter'

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsCapturePaintButtonsPensHighlighter onClick={onClick}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon ${selected ? 'selected' : ''}`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsCapturePaintButtonsPensHighlighter>
  )
}
