const initModel: DN.Models.StoreProducts.ListModelFront = {
  uiStatus: 'init',
  list: [],
  errors: [],
}

const initItemModel: DN.Models.StoreProducts.ModelFront = {
  id: '',
  name: '',
  description: '',
  trial_period_days: null,
  price: 0,

  // Broadcast only
  // edition: BroadcastProductEdition
  interval: 'month',
  order: 0,
  connections: 0,
  quality: '720p',
  fps: 0,
  min_limit: null,
}

const initBcastModel: DN.Models.StoreProducts.BcastModelFront = {
  uiStatus: 'init',
  products: {
    free: initItemModel,
    premium: {
      month: initItemModel,
      year: initItemModel,
    },
  },
  errors: [],
}

// ~~~~~~

export const StoreProductsModel = {
  initModel,
  initBcastModel,
} as const
