export const Routes = {
  Debug: '/debug/:kind', // (on|qa|off)

  Common: '/',
  Main: '/',

  Terms: '/terms',
  Privacy: '/privacy-policy',
  Cookies: '/cookies',

  BuySuccess: '/buy-success/:id',
  BuySuccessForStripe: '/buy-success',
  BuyError: '/buy-error',

  SignIn: '/sign-in',

  Join: '/join',
  Joining: '/joining/:broadcastId', // [0-9]{6}
  Joined: '/:broadcastId', // [0-9]{6}

  Start: '/start',
  Starting: '/starting',
  Started: '/broadcasting',

  NoSubscription: '/no-subscription',

  genJoining: (broadcastId: string) => `/joining/${broadcastId}`,
  genJoined: (broadcastId: string) => `/${broadcastId}`,

  UserSettings: '/user-settings',

  // ~~~~~~~ Extension

  // - Extension: Redirect autostarting bcast

  ExtensionStartBcast: '/extension-start-bcast',

  // - Extension: Redirect showing create account

  ExtensionCreateAccount: '/extension-create-account',

  // - Extension: Update user settings

  ExtensionUserSettings: '/extension-user-settings',

  // ~~~~~~~ Redirects

  // - Redirect with login

  RedirectWithLogin: '/redirect-with-login',
} as const

export type BroadcastIdRouteParams = { broadcastId?: string }

export type BuySubscriptionParams = { monthlyOrYearly?: string }

export const ExtensionRoutes = [
  Routes.ExtensionStartBcast,
  Routes.ExtensionCreateAccount,
  Routes.ExtensionUserSettings,
]

export const RegexRoutes = {
  joining: new RegExp(/^\/joining\/[0-9]{6}$/),
  joined: new RegExp(/^\/[0-9]{6}$/),
}
