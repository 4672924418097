import { AppInitState } from '../../../../../models/app/model'
import { UserSettingsEditFieldsMR } from '../../../../../models/bcast/user-settings/model'
import { UtilsStore } from '@dn/utils'
import { ApiBcastUserSettingsAT } from '../../../../actions/api-bcast/user-settings/actions'
import {
  EpicApiBcastCurrentUserShowMT,
  EpicApiBcastCurrentUserShowMC,
} from '../../../../epics/api-bcast/current-user/show/mutators'
import { EpicApiBcastUserSettingsUpdateMT } from '../../../../epics/api-bcast/user-settings/update/mutators'

const initState = AppInitState.userSettings

type State = typeof initState

const reducer: any = {
  ...UserSettingsEditFieldsMR.__model__.Reducer,
  ...UserSettingsEditFieldsMR.lang.Reducer,
  ...UserSettingsEditFieldsMR.theme.Reducer,
}

// ####################################################################################################
// ~~~~~~ Current user Show - OK
// ####################################################################################################

reducer[EpicApiBcastCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastCurrentUserShowMC.ok>,
): State => {
  return {
    ...initState,
    ...payload.userSettings,
  }
}

// ####################################################################################################
// ~~~~~~ User settings update - START
// ####################################################################################################

reducer[ApiBcastUserSettingsAT.UPDATE] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ User settings update - OK
// ####################################################################################################

reducer[EpicApiBcastUserSettingsUpdateMT.OK] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ User settings update - ERROR
// ####################################################################################################

reducer[EpicApiBcastUserSettingsUpdateMT.ERROR] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ~~~~~~

export const userSettingsEditReducer = UtilsStore.dynReducer(initState, reducer)
