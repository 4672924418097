import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Trans } from '../../../components/common/intl/trans'
import { LazySVG } from '../../../components/svgs/lazy-svg/component'
import { Routes } from '../../../constants/routes/routes'
import { StoreState } from '../../../models/app/model'
import { STUserSettingsTopLeft } from './style'

// ~~~~~~ Constants

const BackIcon = LazySVG('icons/arrow-back')

// ~~~~~~ Component

export const UserSettingsTopLeft = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ State

  // - Store

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  const broadcastId = useSelector(
    (state: StoreState) => state.streamManagerServerPubMain.broadcastId,
  )

  // ~~~~~~ Computed

  const returnPath = broadcastId ? Routes.Started : redirectPath

  // ~~~~~~ Handlers

  function onClick() {
    navigate(returnPath, { replace: true })
  }

  // ~~~~~~ Render

  return (
    <STUserSettingsTopLeft onClick={onClick}>
      <BackIcon size={20} />

      <div style={{ whiteSpace: 'nowrap' }}>
        <Trans id="common.GoBack" />
      </div>
    </STUserSettingsTopLeft>
  )
}
