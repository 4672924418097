import { useDispatch } from 'react-redux'
import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { UiMobileToolbarFieldsMR } from '../../../../../../../models/bcast/ui-mobile-toolbars/model'
import { ClassName, STJoinedMobileToolbarsMainButtonsClose } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/exit')

// ~~~~~~ Component

export const JoinedMobileToolbarsMainButtonsClose = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ Handlers

  function onClick() {
    dispatch(UiMobileToolbarFieldsMR.openStatus.MC.change('closed'))
  }

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsMainButtonsClose onClick={onClick}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsMainButtonsClose>
  )
}
