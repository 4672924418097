import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromEvent, takeUntil } from 'rxjs'
import { ConstEventsElement, ConstMouseButtons } from '@dn/constants'
import { Config } from '../../config'
import { StoreState } from '../../models/app/model'
import { FirstPortal } from '../../portals/debug-portal'
import { LocalStorageDebugLang } from '../../services/storage/local/debug-lang'
import { IntlAC } from '../../store/actions/intl/actions'
import { STDebugLang } from './style'

// ~~~~~~ Constants

const Langs = Config.Intl.SupportedLocales

// ~~~~~~ Component

export const DebugLang = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [show, setShow] = useState(false)

  const [pos, setPos] = useState({ x: 20, y: 80 })

  const curLang = useSelector((state: StoreState) => state.intl.lang)

  const selfRef = useRef<HTMLDivElement>(null)
  const selectRef = useRef<HTMLSelectElement>(null)

  // ~~~~~~ Handlers

  function onSelect(evt: any) {
    dispatch(IntlAC.changeLang(evt.target.value as Lang))
  }

  function startDrag(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const self = selfRef.current

    if (!self || evt.button !== ConstMouseButtons.Main || evt.target === selectRef.current) {
      return
    }

    const init = {
      x: evt.clientX - parseInt(self.style.left),
      y: evt.clientY - parseInt(self.style.top),
    }

    const mouseMove$ = fromEvent<MouseEvent>(document, ConstEventsElement.MouseMove)
    const mouseUp$ = fromEvent<MouseEvent>(document, ConstEventsElement.MouseUp)

    mouseMove$.pipe(takeUntil(mouseUp$)).subscribe({
      next: (evt) => {
        if (!evt.target) {
          return
        }

        setPos({
          x: evt.clientX - init.x,
          y: evt.clientY - init.y,
        })
      },
    })
  }

  // ~~~~~~ Effects

  useEffect(() => {
    const debugLang = LocalStorageDebugLang.get()
    setShow(debugLang)
  }, [])

  // ~~~~~~ Render

  return (
    <FirstPortal>
      {show ? (
        <STDebugLang ref={selfRef} onMouseDown={startDrag} style={{ left: pos.x, top: pos.y }}>
          <select ref={selectRef} defaultValue={curLang} name="select" onChange={onSelect}>
            {Langs.map((lang) => (
              <option key={lang} value={lang}>
                {lang}
              </option>
            ))}
          </select>
        </STDebugLang>
      ) : undefined}
    </FirstPortal>
  )
}
