import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'
import {
  EpicBcastCommChannelMessagesFromServerMC,
  EpicBcastCommChannelMessagesFromServerMT,
} from '../../../epics/comm-channel/messages/from-server/mutators'

const initState = AppInitState.viewers

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Presence resume msg
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesFromServerMT.PRESENCE_RESUME] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastCommChannelMessagesFromServerMC.presenceResume>,
): State => {
  return {
    ...state,
    quantity: payload.count,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel all
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~

export const viewersReducer = UtilsStore.dynReducer(initState, reducer)
