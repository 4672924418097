import styled from 'styled-components'
import { Colors } from '../../style/theme/colors'
import { Layout } from '../../style/theme/layout'
import { theme } from '../../style/utils/theme'

// ~~~~~~ Helpers

const getHeight = (
  isDebugLocal: boolean,
  sended: boolean,
  hasError: boolean,
  hasSendError: boolean,
) => {
  let init = 80

  if (!isDebugLocal) init += 50

  if (sended) init += 40

  if (hasError) init += 50

  if (hasSendError) init += 40

  return init
}

// ~~~~~~ Types

type STProps = {
  $isDebugLocal: boolean
  $sended: boolean
  $hasError: boolean
  $hasSendError: boolean
  $disabled: boolean
}

// ~~~~~~ Component

export const STDebugActions = styled.div<STProps>`
  z-index: 1000000;
  user-select: none;
  font-family: 'Lucida Console', Monaco, monospace;
  font-size: 12px;
  color: ${theme(Colors.WOLF_95_05)};

  position: absolute;
  border: solid 1px #aaa;
  background-color: ${theme(Colors.WOLF_10_90)};
  padding: 14px;

  ${Layout.Border.Radius.A}

  width: 100px;

  height: ${({ $isDebugLocal, $sended, $hasError, $hasSendError }) =>
    getHeight($isDebugLocal, $sended, $hasError, $hasSendError)}px;

  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  .title {
    padding-bottom: 10px;
  }

  .ok {
    color: green;
  }

  .error {
    padding-top: 5px;
    padding-bottom: 5px;

    color: ${theme(Colors.BROOKLYN_40_30)};
  }

  textarea {
    resize: none;
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'auto')};
  }

  button {
    margin-top: 4px;
    cursor: pointer;
  }
`
