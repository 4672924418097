import styled from 'styled-components'
import { Transition } from '../../../../../../../../../style/animations/css-animations'
import { Fonts } from '../../../../../../../../../style/theme/fonts'
import { Layout } from '../../../../../../../../../style/theme/layout'
import { theme } from '../../../../../../../../../style/utils/theme'
import {
  CommonStyleSvgColors,
  STJoinedTabletContentMainToolbarsButtonsCommonStyle,
} from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-tablet-content-main-toolbars-main-buttons-zoom'

// ~~~~~~ Component

export const STJoinedTabletContentMainToolbarsMainButtonsZoom = styled.div`
  ${STJoinedTabletContentMainToolbarsButtonsCommonStyle}

  width: unset;

  .${ClassName}--content {
    display: flex;
    align-items: center;
    justify-content: center;

    .${ClassName}--content--zoom-out {
      margin-right: ${Layout.Spacing[2]}px;
    }

    .${ClassName}--content--current-zoom {
      ${Fonts.MonoFamily}
    }

    .${ClassName}--content--zoom-in {
      margin-left: ${Layout.Spacing[2]}px;
    }

    .${ClassName}--content--zoom-out, .${ClassName}--content--zoom-in {
      cursor: pointer;

      display: flex;

      svg {
        width: 20px;
        height: 20px;

        circle,
        line {
          ${Transition.StrokeFaster}

          fill: none;
          stroke: ${theme(CommonStyleSvgColors.color)};
        }
      }
    }
  }
`
