import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { SideMenuPortal } from '../../../../portals/side-menu'
import { RootMC } from '../../../../store/actions-mutators/root/mutators'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Loader } from '../../loader/component'
import { useMenuLogic } from '../hook'
import { UserMenuMobileSlide } from './slide/component'
import { ClassName, STUserMenuMobile } from './style'

// ~~~~~~ Constants

const MenuIcon = LazySVG('icons/menu')

// ~~~~~~ Component

export const UserMenuMobile = () => {
  // ~~~~~~ Hooks

  const { showMenuLoader, locationIsSettings } = useMenuLogic()

  const dispatch = useDispatch()

  // ~~~~~~ State

  const showSideMenu = useSelector((state: StoreState) => state.root.showSideMenu)

  // ~~~~~~ Handlers

  function onClickMenuButton() {
    const action = showSideMenu ? RootMC.hideSideMenu() : RootMC.showSideMenu()

    dispatch(action)
  }

  // ~~~~~~ Render

  return (
    <STUserMenuMobile data-testid="user-menu" $isOverBlack={locationIsSettings}>
      {/* Menu icon */}

      <div data-testid="toggle-menu-act" onClick={onClickMenuButton}>
        <div className={`${ClassName}--user-menu-btn`}>
          <div className={`${ClassName}--user-menu-btn--icon`}>
            {showMenuLoader ? <Loader size={30} /> : <MenuIcon size={30} />}
          </div>
        </div>
      </div>

      {/* Side Menu */}

      <SideMenuPortal>
        <UserMenuMobileSlide />
      </SideMenuPortal>
    </STUserMenuMobile>
  )
}
