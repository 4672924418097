const prefix = 'mt-paint-bcast-stream-pic'

// ~~~~~~ Mutators Types

export const PaintBcastStreamPicMT = {
  NEW: `${prefix}-new`,
  CLEAR_EDIT: `${prefix}-clear-edit`,
} as const

// ~~~~~~ Mutators Creators

type PaintBcastStreamPic = {
  data: string
  width: number
  height: number
}

export const PaintBcastStreamPicMC = {
  new: (pic: PaintBcastStreamPic) => ({
    type: PaintBcastStreamPicMT.NEW,
    payload: {
      pic,
    },
  }),

  clearEdit: () => ({
    type: PaintBcastStreamPicMT.CLEAR_EDIT,
  }),
} as const
