const prefix = 'at-extension'

// ~~~~~~ Mutators Types

export const ExtensionAT = {
  USER_DATA: `${prefix}-user-data`,
} as const

// ~~~~~~ Mutators Creators

type UserData = DN.Services.Extension.AppInfoPayload

export const ExtensionAC = {
  userData: (data: UserData) => ({
    type: ExtensionAT.USER_DATA,
    payload: data,
  }),
} as const
