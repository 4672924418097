import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { STPaintToolbarButtonsDownload } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/download')

// ~~~~~~ Types

type Props = {
  onClick: () => void
}

// ~~~~~~ Component

export const PaintToolbarButtonsDownload: React.FC<Props> = ({ onClick }) => {
  // ~~~~~~ Render

  return (
    <STPaintToolbarButtonsDownload $disabled={false} onClick={onClick}>
      <Icon size={24} />
    </STPaintToolbarButtonsDownload>
  )
}
