import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { Button } from '../../../common/button/component'
import { Trans } from '../../../common/intl/trans'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmShouldCheckAllGooglePermissionsToLink)

// ~~~~~~ Component

export const DialogConfirmShouldCheckAllGooglePermissionsToLink = () => {
  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const errors = useSelector((state: StoreState) => state.providerGoogle.errors)

  // ~~~~~~ Computed

  // ~~~~~~ Handlers

  function onClickOk() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Effects

  // - Close dialog if no error

  useEffect(() => {
    if (errors.length) return

    setDoClose(performance.now())

    //
  }, [errors.length])

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      predefIcon="error"
      modalSize={DialogBreakPoint.XS}
      dialogTitle="dialogs.confirm.should-check-all-google-permissions.Title"
      closeDialog={doClose}
    >
      {/* Content */}

      <Content>
        {errors.map((error, idx) => (
          <div key={idx} className="line">
            <Trans id={error.id} values={error.values} />
          </div>
        ))}
      </Content>

      {/* Actions */}

      <Actions>
        {/* Confirm */}

        <Button
          intlId="common.OK"
          $colorType="error"
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickOk}
        />
      </Actions>
    </Dialog>
  )
}
