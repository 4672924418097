import styled from 'styled-components'
import { Transition } from '../../../../style/animations/css-animations'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'dialog-end-bcast-feedback-emojis'

// ~~~~~~ Component

export const STDialogEndBcastFeedbackEmojis = styled.div`
  .${ClassName}--title {
    ${Fonts.Scale.Title}
    ${Fonts.Weight[300]}

    color: ${theme(Colors.WOLF_100_05)};

    margin-bottom: ${Layout.Spacing[3]}px;
  }

  .${ClassName}--faces-container {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 112px;

    margin-bottom: ${Layout.Spacing[3]}px;

    .${ClassName}--faces-container--faces {
      width: 256px;

      display: flex;
      justify-content: space-between;

      .${ClassName}--faces-container--faces--face {
        cursor: pointer;

        width: 64px;
        height: 64px;

        svg {
          path.svg-face {
            ${Transition.FillFaster}

            fill: ${theme(Colors.BRONX_50_30)};
            stroke: none;
          }
        }

        &:hover {
          svg {
            path.svg-face {
              fill: ${theme(Colors.BRONX_40_50)};
              stroke: none;
            }
          }
        }
      }
    }
  }

  .${ClassName}--skip {
    cursor: pointer;

    ${Fonts.Scale.Body}
    ${Fonts.Weight[500]}

    color: ${theme(Colors.WOLF_80_20)};
  }
`
