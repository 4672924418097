import { AppInitState } from '../../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ApiStreamManagerServerAT } from '../../../../actions/api-stream-manager/server/actions'
import { CancelNowAT } from '../../../../actions/cancel-now/actions'
import {
  EpicApiServerPubCamMicMC,
  EpicApiServerPubCamMicMT,
} from '../../../../epics/api-stream-manager/server/pub-cam-mic/mutators'

const initState = AppInitState.streamManagerServerPubCamMic

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Get server
// ####################################################################################################

reducer[ApiStreamManagerServerAT.GET_SERVER_PUB_CAM_MIC] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Get server Ok
// ####################################################################################################

reducer[EpicApiServerPubCamMicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiServerPubCamMicMC.ok>,
): State => {
  return {
    ...state,
    ...payload.server,
    uiStatus: 'completed',
  }
}

// ####################################################################################################
// ~~~~~~ Get server Error
// ####################################################################################################

reducer[EpicApiServerPubCamMicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiServerPubCamMicMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ~~~~~~

export const streamManagerServerPubCamMicReducer = UtilsStore.dynReducer(initState, reducer)
