import React from 'react'

import { Trans } from '../intl/trans'
import { STCheckBox } from './style'

// ~~~~~~ Types

type Props = {
  intlId: IntlMsgId
  checked: boolean
  disabled: boolean
  onClickCheck: () => void
}

// ~~~~~~ Component

export const CheckButton: React.FC<Props> = ({ intlId, checked, disabled, onClickCheck }) => {
  // ~~~~~~ Hanlders

  function onClickCheckH() {
    if (disabled) return

    onClickCheck()
  }

  // ~~~~~~ Render

  return (
    <STCheckBox $disabled={disabled}>
      <input type="checkbox" disabled={disabled} checked={checked} readOnly />

      <label onClick={onClickCheckH}>
        <Trans id={intlId} />
      </label>
    </STCheckBox>
  )
}
