import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { getThemeValue, theme } from '../../../style/utils/theme'

// ~~~~~~ Types

type STProps = {
  $kind: 'success' | 'error'
}

// ~~~~~~ Component

export const STToast = styled.div<STProps>`
  ${Fonts.Scale.Body};

  display: flex;

  padding: ${Layout.Spacing[3]}px;
  padding-bottom: ${Layout.Spacing[3] - 4}px;

  background-color: ${(props) =>
    props.$kind === 'success'
      ? getThemeValue(props, Colors.SITO_05_05)
      : getThemeValue(props, Colors.BROOKLYN_05_05)};

  .toast-kind-line {
    margin-right: ${Layout.Spacing[3]}px;

    .toast-icon {
      width: 18px;
      height: 100%;

      svg {
        width: 18px;
        height: 18px;

        path {
          fill: ${(props) =>
            props.$kind === 'success'
              ? getThemeValue(props, Colors.SITO_70_70)
              : getThemeValue(props, Colors.BROOKLYN_60_60)};
        }
      }
    }
  }

  .toast-text-lines {
    .toast-text-line {
      color: ${theme(Colors.WOLF_100_100)};
    }
  }
`
