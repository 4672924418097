import { EMPTY, map, mergeMap, Observable, tap } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'

import { StoreState } from '../../../../../models/app/model'
import { apiBcastSubscriptionsGetTime$ } from '../../../../../services/api-bcast/subscriptions/get-time'
import { ApiUtils } from '../../../utils'
import { EpicApiSubscriptionsGetTimeMC } from './mutators'
import { ShareMainStreamAC, ShareMainStreamAT } from '../../../../actions/share-main-stream/actions'
import { ExtensionService } from '../../../../../services/extension'

type Action =
  | ReturnType<typeof ShareMainStreamAC.getStream>
  | ReturnType<typeof ShareMainStreamAC.getStreamByExtension>

export const apiStoreSubscriptionGetTimeOnGetScreenEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ShareMainStreamAT.GET_STREAM, ShareMainStreamAT.GET_STREAM_BY_EXTENSION),
    mergeMap(() => {
      const { currentUser, subscription } = state$.value

      if (!subscription.time_limit) return EMPTY

      return apiBcastSubscriptionsGetTime$(currentUser.token, subscription.id, subscription.token)
    }),
    tap((res) => {
      if (!ApiUtils.isSuccess(res)) return

      ExtensionService.SendMessage.ToExtension.updateInfo()
    }),
    map((res) =>
      ApiUtils.isSuccess(res)
        ? EpicApiSubscriptionsGetTimeMC.ok(res.response)
        : EpicApiSubscriptionsGetTimeMC.error(res.status, res.response),
    ),
  )
