import { useSelector } from 'react-redux'
import { LazySVG } from '../../../../../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../../../../../models/app/model'
import { ClassName, STJoinedMobileToolbarsCapturePaintButtonsPensPen } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/tool-pen')

// ~~~~~~ Types

type Props = {
  onClick: () => void
}

// ~~~~~~ Component

export const JoinedMobileToolbarsCapturePaintButtonsPensPen: React.FC<Props> = ({ onClick }) => {
  // ~~~~~~ State

  const { activePen, isErasing } = useSelector((state: StoreState) => state.paintBcastStreamDraw)

  // ~~~~~~ Computed

  const selected = !isErasing && activePen === 'pen'

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsCapturePaintButtonsPensPen onClick={onClick}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon ${selected ? 'selected' : ''}`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsCapturePaintButtonsPensPen>
  )
}
