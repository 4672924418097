import { UnknownAction } from 'redux'
import { UtilsObservables } from '@dn/utils'
import { ServiceWebRtc } from '@dn/webrtc'
import { ofType, StateObservable } from 'redux-observable'
import { Observable, of, mergeMap, tap } from 'rxjs'
import { Config } from '../../../../config'
import { StoreState } from '../../../../models/app/model'
import { BroadcastService } from '../../../../services/broadcast'
import { CommChannelService } from '../../../../services/comm-channel'
import { ExtensionService } from '../../../../services/extension'
import { cancelAll$$ } from '../../../../subjects/cancel-all/subject'
import { CancelNowAC, CancelNowAT, CancelReasons } from '../../../actions/cancel-now/actions'
import { CountdownBcastDurationAC } from '../../../actions/countdown/bcast-duration/actions'
import {
  EpicApiServerPubMainMC,
  EpicApiServerPubMainMT,
} from '../../api-stream-manager/server/pub-main/mutators'
import {
  EpicApiServerSubMainMC,
  EpicApiServerSubMainMT,
} from '../../api-stream-manager/server/sub-main/mutators'
import {
  EpicBcastStartPubMainMC,
  EpicBcastStartPubMainMT,
} from '../../bcast/start-pub-main/mutators'
import {
  EpicBcastStartSubMainMC,
  EpicBcastStartSubMainMT,
} from '../../bcast/start-sub-main/mutators'
import {
  EpicShareMainStreamGetStreamMC,
  EpicShareMainStreamGetStreamMT,
} from '../../share-main-stream/get-stream/mutators'
import { EpicCancelNowAllMC } from './mutators'
import { ServiceBcast } from '@dn/bcast'

type Action =
  | ReturnType<typeof CancelNowAC.cancelAll>
  | ReturnType<typeof EpicShareMainStreamGetStreamMC.error>
  | ReturnType<typeof EpicApiServerPubMainMC.error>
  | ReturnType<typeof EpicBcastStartPubMainMC.error>
  | ReturnType<typeof EpicApiServerSubMainMC.error>
  | ReturnType<typeof EpicBcastStartSubMainMC.error>

export const cancelNowAllEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      CancelNowAT.CANCEL_ALL,

      EpicShareMainStreamGetStreamMT.ERROR,

      EpicApiServerPubMainMT.ERROR,
      EpicBcastStartPubMainMT.ERROR,

      EpicApiServerSubMainMT.ERROR,
      EpicBcastStartSubMainMT.ERROR,
    ),

    tap(() => {
      const {
        sharedMainStream,
        sharedCamMicStream,

        streamManagerPubMain,
        streamManagerSubMain,

        streamManagerPubCamMic,
        streamManagerSubCamMic,

        commChannel,

        dnBcast,
      } = state$.value

      const { curScreenStream, curScreenVideoTrack, canvasStreamStop } = sharedMainStream

      canvasStreamStop && canvasStreamStop()

      // Streams

      const streams = [
        curScreenStream,
        sharedCamMicStream.curCamMicStream,
        dnBcast.localScreenStream,
        dnBcast.localCamStream,
      ]

      // Stop all

      cancelAll$$.next()

      CommChannelService.stop(commChannel.webSocket)

      // Inform the extension

      ExtensionService.SendMessage.ToExtension.chooseSourceStateChanged('not-choosing')

      // Tracks outside streams

      const tracks = [curScreenVideoTrack, dnBcast.localMicTrack].filter(
        Boolean,
      ) as MediaStreamTrack[]

      // Stopping streams and tracks
      // the stop service can be pending because the lib, if no internet connection
      // so we do not add the stop in stop$.subscribe({ next: () => ... })

      ServiceWebRtc.Media.Stream.stop$(streams).subscribe(
        UtilsObservables.tapLogObserver('Stop streams'),
      )

      ServiceWebRtc.Media.Track.stop$(tracks).subscribe(
        UtilsObservables.tapLogObserver('Stop tracks'),
      )

      // Disconnect (livekit)

      const room = state$.value.dnBcast.room

      if (ServiceBcast.Guards.isRoom(room)) {
        ServiceBcast.stop(room)
      }

      // Stop publisher or subscriber (red5pro)

      BroadcastService.stop$({
        mainPub: streamManagerPubMain.publisher,
        mainSub: streamManagerSubMain.subscriber,

        camMicPub: streamManagerPubCamMic.publisher,
        camMicSub: streamManagerSubCamMic.subscriber,

        stop: { all: true },
      })
        .pipe(tap(UtilsObservables.tapLogObserver('Stop all')))
        .subscribe()

      // After emit cancel all done, send the updated status to the extension

      !Config.isExtension &&
        setTimeout(() => ExtensionService.SendMessage.ToExtension.broadcastingStateChanged(), 10)
    }),

    mergeMap(({ payload }) => {
      const actionsToEmit: UnknownAction[] = []

      const hasTimeLimit = typeof state$.value.subscription.time_limit === 'number'

      if (hasTimeLimit) {
        const subscriptionId = state$.value.subscription.id

        actionsToEmit.push(CountdownBcastDurationAC.stop(subscriptionId))
      }

      if (Array.isArray(payload)) {
        actionsToEmit.push(EpicCancelNowAllMC.done([...payload]))
      } else {
        const cancelReason = CancelReasons.Unknown
        // 'reason' in action.payload.data
        //   ? (action.payload.data.reason as CancelReasons)
        //   : CancelReasons.Unknown

        actionsToEmit.push(EpicCancelNowAllMC.done([cancelReason]))
      }

      return of(...actionsToEmit)
    }),
  )
