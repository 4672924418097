import { DialogsSubscriptionsPlansForDialogsLayoutContext } from './context/component'
import { STDialogsSubscriptionsPlansForDialogsLayout } from './style'

// ~~~~~~ Props

type Props = {
  children: React.ReactNode
}

// ~~~~~~ Component

export const DialogsSubscriptionsPlansForDialogsLayout: React.FC<Props> = ({ children }) => {
  // ~~~~~~ Render

  return (
    <STDialogsSubscriptionsPlansForDialogsLayout>
      {/* Content */}
      <div className="plans-for-dialogs-layout-content">
        <DialogsSubscriptionsPlansForDialogsLayoutContext.Provider value={{ kind: 'content' }}>
          {children}
        </DialogsSubscriptionsPlansForDialogsLayoutContext.Provider>
      </div>

      {/* Action */}
      <div className="plans-for-dialogs-layout-action">
        <DialogsSubscriptionsPlansForDialogsLayoutContext.Provider value={{ kind: 'action' }}>
          {children}
        </DialogsSubscriptionsPlansForDialogsLayoutContext.Provider>
      </div>
    </STDialogsSubscriptionsPlansForDialogsLayout>
  )
}
