import styled from 'styled-components'
import { theme } from '../../../../../../../style/utils/theme'
import { CommonStyleSvgColors, STJoinedMobileToolbarsCapturePaintButtons } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-capture-paint-buttons-redo'

// ~~~~~~ Component

export const STJoinedMobileToolbarsCapturePaintButtonsRedo = styled.div`
  ${STJoinedMobileToolbarsCapturePaintButtons}

  svg.${ClassName}--icon {
    path {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }
`
