import { useState } from 'react'
import { Config } from '../../../../config'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { Button } from '../../../common/button/component'
import { Trans } from '../../../common/intl/trans'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { STDialogConfirmConfirmGrantSystemPermissions } from './style'
import { BrowserIsSafari, SystemIsOSX } from '../../../../constants/misc'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmGrantSystemPermissions)

// ~~~~~~ Component

export const DialogConfirmConfirmGrantSystemPermissions = () => {
  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  // ~~~~~~ Handlers

  function onClickOk() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      predefIcon="info"
      modalSize={DialogBreakPoint.XS}
      dialogTitle="dialogs.confirm.grant-system-permissions.Title"
      closeDialog={doClose}
    >
      {/* Content */}

      <Content>
        <STDialogConfirmConfirmGrantSystemPermissions>
          <div className="line">
            <Trans id="dialogs.confirm.grant-system-permissions.Content1" />
          </div>

          <div className="line">
            {BrowserIsSafari ? (
              <>
                <Trans id="dialogs.confirm.grant-system-permissions.Content2Safari" />
                <span> </span>(<span className="preferences-command">⌘ ,</span>)
              </>
            ) : (
              <Trans id="dialogs.confirm.grant-system-permissions.Content2" />
            )}

            {SystemIsOSX && Config.Links.GrantRecordPermissionsOSX ? (
              <>
                <Trans id="dialogs.confirm.grant-system-permissions.Content3" />
                <span> </span>
                <a href={Config.Links.GrantRecordPermissionsOSX} target="_blank" rel="noreferrer">
                  <Trans id="dialogs.confirm.grant-system-permissions.Content4" />
                </a>
              </>
            ) : undefined}
          </div>
        </STDialogConfirmConfirmGrantSystemPermissions>
      </Content>

      {/* Actions */}

      <Actions>
        {/* Confirm */}

        <Button
          intlId="common.OK"
          $colorType="primary"
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickOk}
        />
      </Actions>
    </Dialog>
  )
}
