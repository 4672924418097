import { Observable, catchError, map, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'
import { StreamContextModel } from '../../../models/bcast/stream-context/model'

const { Get } = ApiBcastRoutes.StreamContext

// ####################################################################################################
// ~~~~~~ Create stream context
// ####################################################################################################

type Res = DN.Models.StreamContext.Api.Get.Res
type ResParsed = DNApiResParsed<DN.Models.StreamContext.Api.Get.ResParsed>
type ResError = DNApiError<DN.Models.StreamContext.Api.Get.ResError>

export const apiBcastStreamContextGet$ = (bcastId: string, headers?: AppHeaders) => {
  //

  return ajax<Res>({
    ...BcastHeaderUtils.genHeaders(headers),
    method: Get.Method,
    url: Get.getUrl(bcastId),
  }).pipe(
    //

    map((res): ResParsed => {
      return {
        status: res.status,
        response: StreamContextModel.fromBackToFront(res.response, bcastId, 'sub'),
      }
    }),

    catchError((res: ResError): Observable<ResParsed> => {
      return of({
        status: 200,
        response: StreamContextModel.fromBackToFront(
          {
            id: 'no-stream-context',
          },
          bcastId,
          'sub',
        ),
      })
    }),
  )
}
