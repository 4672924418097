import styled from 'styled-components'
import { Transition } from '../../../../style/animations/css-animations'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Colors } from '../../../../style/theme/colors'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Styled Component

export const STBroadcastIdInput = styled.div`
  display: flex;
  margin-bottom: 16px;

  input {
    text-align: center;

    width: ${58 - 16 * 2}px;
    height: ${56 - 16 * 2}px;

    padding: 16px;

    ${Transition.BorderColorFaster}
    border: solid 1px ${theme(Colors.WOLF_50_30)};
    ${Layout.Border.Radius.A}

    color: ${theme(Colors.WOLF_100_05)};
    background-color: transparent;

    margin-right: 8px;

    &:hover {
      border-color: ${theme(Colors.WOLF_40_40)};
    }

    &:focus {
      border-color: ${theme(Colors.WOLF_100_05)};
    }
  }

  @media (max-width: ${MediaQuery.SM}) {
    justify-content: space-between;

    input {
      width: ${50 - 16 * 2}px;
      height: ${50 - 16 * 2}px;

      margin-right: 0px;
    }
  }

  @media (max-width: ${MediaQuery.XS}) {
    justify-content: space-between;

    input {
      padding: 8px;
      width: ${40 - 8 * 2}px;
      height: ${40 - 8 * 2}px;

      margin-right: 0px;
    }
  }
`
