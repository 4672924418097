import { useDispatch, useSelector } from 'react-redux'
import { Trans } from '../../../../../components/common/intl/trans'
import { ModalsIds } from '../../../../../constants/modals'
import { StoreState } from '../../../../../models/app/model'
import { ModalsMC } from '../../../../../store/actions-mutators/modals/mutators'
import { ClassName, STStartedHeaderTopRightTimeLeft } from './style'

// ~~~~~~ Component

export const StartedHeaderTopRightTimeLeft = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const subscription = useSelector((state: StoreState) => state.subscription)

  const { timeRemainig } = useSelector((state: StoreState) => state.bcastCountdown)

  // ~~~~~~ Computed

  const hasTimeLimit = typeof subscription.time_limit === 'number'

  const mins = timeRemainig.minutes
  const finalMins = mins > 0 ? mins.toString().padStart(2, '0') : '00'

  const secs = timeRemainig.seconds
  const finalSecs = secs > 0 ? secs.toString().padStart(2, '0') : '00'

  // ~~~~~~ Handlers

  function onClick() {
    dispatch(ModalsMC.open(ModalsIds.CreateAnAccountFreeOrBuy))
  }

  // ~~~~~~ Render

  if (!hasTimeLimit) return null

  return (
    <STStartedHeaderTopRightTimeLeft onClick={onClick}>
      <span className={`${ClassName}--clock`}>
        {finalMins}:{finalSecs}
      </span>

      <span>&nbsp;</span>

      <span>
        <Trans id="pages.started.header.top-right.TimeLeft" values={{ value: '' }} />
      </span>
    </STStartedHeaderTopRightTimeLeft>
  )
}
