import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { RoutesGTM } from '../../constants/routes/gtm'
import { GoogleGTM } from '../../third-parties/google-gtm/library'

// ~~~~~~ Types

type Data = {
  requireExtraInfo: boolean
  extraInfo?: string
}

// ~~~~~~ Hook

export const useGoogleGTM = ({ requireExtraInfo, extraInfo }: Data) => {
  // ~~~~~~ Hooks

  const location = useLocation()

  // ~~~~~~ State

  const [isMounted, setIsMounted] = useState(false)

  // ~~~~~~ Effects

  // Track page on mount

  useEffect(() => {
    if (requireExtraInfo && !extraInfo) return

    if (isMounted) return

    setIsMounted(true)

    const routeKey = RoutesGTM.DirectRoutes[location.pathname]

    if (!routeKey) return

    GoogleGTM.send(routeKey)

    //
  }, [extraInfo, isMounted, location.pathname, requireExtraInfo])
}
