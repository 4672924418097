const date = new Date().toISOString()

const tmpInit: DN.Models.StoreSubscription.ModelFront = {
  uiStatus: 'init',
  errors: [],

  price_id: '',

  subscription_id: '',
  subscription_status: 'active',

  amount: 0,

  interval: 'month',
  payment_method_id: null,
  card_brand: '',
  card_last4: '',

  created_at: date,
  current_period_end: date,
  cancel_at: date,

  license_key: null,

  product_description: '',
  product_id: 'broadcast-subscription',
  product_name: '',
}

const fromBackToFront = (
  model: DN.Models.StoreSubscription.ModelBack,
): DN.Models.StoreSubscription.ModelFront => {
  return {
    ...model,
    uiStatus: 'init',
    errors: [],
  }
}

// ~~~~~

export const StoreSubscriptionModel = {
  tmpInit,
  fromBackToFront,
}
