import { useSelector } from 'react-redux'
import { CamMicMicSoundMobileIsOffBar } from '../../../../components/common/cam-mic/mic-sound/mobile/is-off-bar/component'
import { CamMicMicSoundMobileButtonSwitcher } from '../../../../components/common/cam-mic/mic-sound/mobile/button-switcher/component'
import { StoreState } from '../../../../models/app/model'
import { ClassName } from '../content/style'
import { Config } from '../../../../config'

export const JoinedMobileSound = () => {
  // ~~~~~~ State

  const curCamMicStream = useSelector(
    (state: StoreState) => state.streamManagerSubCamMic.subscriber,
  )

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  const rootWebrtcKind = useSelector((state: StoreState) => state.root.version)

  // ~~~~~~ Computed

  const isBcastV1 =
    !Config.Features.BcastService2 ||
    (Config.Features.BcastService2 && Config.Features.BcastCheckVersion && rootWebrtcKind === 'v1')

  // ~~~~~~ Render

  if (isBcastV1 && !curCamMicStream) {
    return null
  }

  if (!isBcastV1 && (!dnBcast.remoteCamTrack || !dnBcast.remoteMicTrack)) {
    return null
  }

  return (
    <>
      <CamMicMicSoundMobileIsOffBar className={`${ClassName}--content--sound`} />
      <CamMicMicSoundMobileButtonSwitcher className={`${ClassName}--content--sound`} />
    </>
  )
}
