import { CommChannelStop$$ } from '../subject/stop/comm-channel-partial-service'

export const commChannelStop = (webSocket?: WebSocket) => {
  CommChannelStop$$.next()

  if (!webSocket) return

  if (webSocket.readyState === webSocket.CLOSED || webSocket.readyState === webSocket.CLOSING) {
    return
  }

  webSocket.close()
}
