import { UtilsLog } from '../../../../utils/logs'

let messageBuffer: DN.Services.CommChannel.Messages.FromServer.FromServerWithRelay[] = []

let order = 0

// ~~~~~~

function getBuffer() {
  return [...messageBuffer]
}

// ~~~~~~

function getOrder() {
  return order
}

// ~~~~~~

function getMessages(
  message: DN.Services.CommChannel.Messages.FromServer.FromServerWithRelay,
): any[] {
  // No buffer and the order is right
  if (!messageBuffer.length && message.payload.relayData.order === order + 1) {
    order = order + 1

    return [message]
  }

  // Add the message

  messageBuffer.push(message)

  // Sort the message buffer

  messageBuffer = messageBuffer.sort((a, b) => {
    if (a.payload.relayData.order > b.payload.relayData.order) return 1

    if (a.payload.relayData.order < b.payload.relayData.order) return -1

    UtilsLog.devWarn('Messages with the same order', a, b)

    return 0
  })

  // Check if order is now correct

  const newOrder = messageBuffer.reduce(
    (acc, cur) => (acc + 1 === cur.payload.relayData.order ? acc + 1 : acc),
    order,
  )

  const messagesOrdered = newOrder - order

  if (!messagesOrdered) return []

  const messages = messageBuffer.splice(0, newOrder)

  order = newOrder

  return messages
}

export const RelayMessageBuffer = {
  getMessages,
  getBuffer,
  getOrder,
}
