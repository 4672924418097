import styled from 'styled-components'
import { Transition } from '../../../../../style/animations/css-animations'
import { theme, getThemeValue } from '../../../../../style/utils/theme'
import { PaintToolbarCommonColors, STPaintToolbarCommonStyle } from '../common-style'

// ~~~~~~ Types

type STProps = {
  $selected: boolean
  $disabled: boolean
}

// ~~~~~~ Component

export const STPaintToolbarButtonsMarker = styled.div<STProps>`
  ${STPaintToolbarCommonStyle}

  svg path {
    ${Transition.StrokeFaster}

    fill: none;
    stroke: ${(props) =>
      props.$selected
        ? getThemeValue(props, PaintToolbarCommonColors.selected)
        : getThemeValue(props, PaintToolbarCommonColors.color)};
  }

  @media (hover: hover) {
    &:hover {
      svg path {
        fill: none;
        stroke: ${theme(PaintToolbarCommonColors.hover)};
      }
    }
  }
`
