import { Config } from '../../../config'
import { ConstHttpVerbs } from '@dn/constants'

// ~~~~~~ [P]

const getV1Path = (path: string) => `${Config.Api.Bcast.URI}/license-store-api/v1${path}`

// ####################################################################################################
// ~~~~~~ Normal Api
// ####################################################################################################

// ~~~~~~ Products

const Products = {
  List: {
    Method: ConstHttpVerbs.GET,
    Path: '/products/:productId',
    getUrl: (productId: DN.Models.StoreProducts.ProductId) =>
      getV1Path(Products.List.Path).replace(':productId', productId),
  },
}

// ~~~~~~ Checkout

const Checkout = {
  SendEmailWithPass: {
    Method: ConstHttpVerbs.POST,
    Path: '/checkout/email-with-password',
    getUrl: () => getV1Path(Checkout.SendEmailWithPass.Path),
  },

  ValidateEmailCode: {
    Method: ConstHttpVerbs.POST,
    Path: '/checkout/validate-email-code',
    getUrl: () => getV1Path(Checkout.ValidateEmailCode.Path),
  },

  CreateSession: {
    Method: ConstHttpVerbs.POST,
    Path: '/checkout/create-session',
    getUrl: () => getV1Path(Checkout.CreateSession.Path),
  },

  // Private

  CardIntent: {
    Method: ConstHttpVerbs.GET,
    Path: '/checkout/card-intent',
    getUrl: () => getV1Path(Checkout.CardIntent.Path),
  },
}

// ~~~~~~ Subscriptions

const Subscriptions = {
  CheckPaymentMethod: {
    Method: ConstHttpVerbs.GET,
    Path: '/subscriptions/:subscriptionId/payment-method',
    getUrl: (subscriptionId: string) =>
      getV1Path(Subscriptions.CheckPaymentMethod.Path).replace(':subscriptionId', subscriptionId),
  },

  // Create: Only free plan

  Create: {
    Method: ConstHttpVerbs.POST,
    Path: '/subscriptions',
    getUrl: () => getV1Path(Subscriptions.Create.Path),
  },

  UpgradePlan: {
    Method: ConstHttpVerbs.POST,
    Path: '/subscriptions/:subscriptionId/upgrade-plan',
    getUrl: (subscriptionId: string) =>
      getV1Path(Subscriptions.UpgradePlan.Path.replace(':subscriptionId', subscriptionId)),
  },
}

// ~~~~~~

export const ApiStoreRoutes = {
  Products,
  Subscriptions,
  Checkout,
} as const
