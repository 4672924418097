import { AppInitState } from '../../../../models/app/model'
import { SharedStreamModelFieldsMR } from '../../../../models/bcast/shared-main-stream/model'
import { UtilsStore } from '@dn/utils'
import { ShareMainStreamAC, ShareMainStreamAT } from '../../../actions/share-main-stream/actions'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'
import {
  EpicLivePaintReplaceTrackOnStartDrawMC,
  EpicLivePaintReplaceTrackOnStartDrawMT,
} from '../../../epics/live-paint/replace-track/on-draw-start/mutators'
import { EpicLivePaintReplaceTrackOnStopDrawMT } from '../../../epics/live-paint/replace-track/on-draw-stop/mutators'
import {
  EpicShareMainStreamGetStreamByExtensionToSwitchMC,
  EpicShareMainStreamGetStreamByExtensionToSwitchMT,
} from '../../../epics/share-main-stream/get-stream-by-extension-to-switch/mutators'
import {
  EpicShareMainStreamGetStreamByExtensionMC,
  EpicShareMainStreamGetStreamByExtensionMT,
} from '../../../epics/share-main-stream/get-stream-by-extension/mutators'
import {
  EpicShareMainStreamGetScreenToSwitchMC,
  EpicShareMainStreamGetScreenToSwitchMT,
} from '../../../epics/share-main-stream/get-stream-to-switch/mutators'
import {
  EpicShareMainStreamGetStreamMC,
  EpicShareMainStreamGetStreamMT,
} from '../../../epics/share-main-stream/get-stream/mutators'

// ~~~~~~

const initState = AppInitState.sharedMainStream

type State = typeof initState

const reducer: any = {
  ...SharedStreamModelFieldsMR.curScreenStream.Reducer,
  ...SharedStreamModelFieldsMR.videoIsPlaying.Reducer,
}

// ####################################################################################################
// ~~~~~~ Cancel all
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return {
    ...state,

    curScreenStream: undefined,
    curScreenVideoTrack: undefined,

    canvasVideoTrack: undefined,
    canvasStreamStop: undefined,

    screenStreamForVideo: undefined,
  }
}

// ####################################################################################################
// ~~~~~~ Live paint replace-track ON START DRAW - OK
// ####################################################################################################

reducer[EpicLivePaintReplaceTrackOnStartDrawMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicLivePaintReplaceTrackOnStartDrawMC.ok>,
): State => {
  return {
    ...state,

    canvasVideoTrack: payload.track,
    canvasStreamStop: payload.stop,
  }
}

// ####################################################################################################
// ~~~~~~ Live paint replace-track ON STOP DRAW - OK
// ####################################################################################################

reducer[EpicLivePaintReplaceTrackOnStopDrawMT.OK] = (state: State): State => {
  return {
    ...state,

    canvasVideoTrack: undefined,
    canvasStreamStop: undefined,
  }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
// ~~~~~~ App only (comparing with extension)
//
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// ####################################################################################################
// ~~~~~~ Get screen stream START
// ####################################################################################################

reducer[ShareMainStreamAT.GET_STREAM] = (state: State): State => {
  return {
    ...state,
    getSreenUiStatus: 'running',
    captureKind: 'unknown',
  }
}

// ####################################################################################################
// ~~~~~~ Get screen stream OK
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareMainStreamGetStreamMC.ok>,
): State => {
  const isTab = !!~payload.videoTrack.label.indexOf('web-contents-media-stream')

  return {
    ...state,
    getSreenUiStatus: 'completed',

    captureKind: state.captureKind === 'unknown' && isTab ? 'tab' : state.captureKind,

    curScreenStream: payload.stream,
    curScreenVideoTrack: payload.videoTrack,

    screenStreamForVideo: new MediaStream([payload.videoTrack]),
  }
}

// ####################################################################################################
// ~~~~~~ Get screen stream - ERROR
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamMT.ERROR] = (state: State): State => {
  return {
    ...state,
    getSreenUiStatus: 'completed',
  }
}

// ####################################################################################################
// ~~~~~~ Extension choose source to switch - TRIGGERED
// ####################################################################################################

reducer[ShareMainStreamAT.GET_STREAM_TO_SWITCH] = (state: State): State => {
  return {
    ...state,
    getScreenToSwitchUiStatus: 'select-running',
    captureKind: 'unknown',
  }
}

// ####################################################################################################
// ~~~~~~ Get screen for change - WAITING
// ####################################################################################################

reducer[EpicShareMainStreamGetScreenToSwitchMT.WAITING_STREAM] = (state: State): State => {
  return {
    ...state,
    getScreenToSwitchUiStatus: 'waiting-stream',
  }
}

// ####################################################################################################
// ~~~~~~ Get screen for change - START
// ####################################################################################################

reducer[EpicShareMainStreamGetScreenToSwitchMT.START] = (state: State): State => {
  return {
    ...state,
    getScreenToSwitchUiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Get screen to switch - OK
// ####################################################################################################

reducer[EpicShareMainStreamGetScreenToSwitchMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareMainStreamGetScreenToSwitchMC.ok>,
): State => {
  const isTab = !!~payload.newVideoTrack.label.indexOf('web-contents-media-stream')

  return {
    ...state,
    getScreenToSwitchUiStatus: 'completed',

    captureKind: state.captureKind === 'unknown' && isTab ? 'tab' : state.captureKind,

    curScreenStream: payload.newStream,
    curScreenVideoTrack: payload.newVideoTrack,

    screenStreamForVideo: new MediaStream([payload.newVideoTrack]),
  }
}

// ####################################################################################################
// ~~~~~~ Get screen to switch - ERROR
// ####################################################################################################

reducer[EpicShareMainStreamGetScreenToSwitchMT.ERROR] = (state: State): State => {
  return {
    ...state,
    getScreenToSwitchUiStatus: 'completed',
  }
}

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//
// ~~~~~~ Extension only
//
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// ####################################################################################################
// ~~~~~~ Extension choose source START
// ####################################################################################################

reducer[ShareMainStreamAT.GET_STREAM_BY_EXTENSION] = (
  state: State,
  { payload }: ReturnType<typeof ShareMainStreamAC.getStreamByExtension>,
): State => {
  return {
    ...state,
    getSreenUiStatus: 'running',

    captureKind: payload.kind,
  }
}

// ####################################################################################################
// ~~~~~~ Extension choose source - OK
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamByExtensionMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareMainStreamGetStreamByExtensionMC.ok>,
): State => {
  const isTab = !!~payload.videoTrack.label.indexOf('web-contents-media-stream')

  return {
    ...state,
    getSreenUiStatus: 'completed',

    captureKind: state.captureKind === 'unknown' && isTab ? 'tab' : state.captureKind,

    curScreenStream: payload.stream,
    curScreenVideoTrack: payload.videoTrack,

    screenStreamForVideo: new MediaStream([payload.videoTrack]),
  }
}

// ####################################################################################################
// ~~~~~~ Extension choose source - ERROR
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamByExtensionMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareMainStreamGetStreamByExtensionMC.error>,
): State => {
  return {
    ...state,
    getSreenUiStatus: 'completed',
    captureKind: 'unknown',
  }
}

// ####################################################################################################
// ~~~~~~ Extension choose source to switch - TRIGGERED
// ####################################################################################################

reducer[ShareMainStreamAT.GET_STREAM_BY_EXTENSION_TO_SWITCH] = (
  state: State,
  { payload }: ReturnType<typeof ShareMainStreamAC.getStreamByExtensionToSwitch>,
): State => {
  return {
    ...state,
    getScreenToSwitchUiStatus: 'select-running',
    captureKind: payload.kind,
  }
}

// ####################################################################################################
// ~~~~~~ Extension choose source to switch - WAITING
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamByExtensionToSwitchMT.WAITING_STREAM] = (
  state: State,
): State => {
  return {
    ...state,
    getScreenToSwitchUiStatus: 'waiting-stream',
  }
}

// ####################################################################################################
// ~~~~~~ Extension choose source to switch - START
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamByExtensionToSwitchMT.START] = (state: State): State => {
  return {
    ...state,
    getScreenToSwitchUiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Extension choose source to switch - OK
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamByExtensionToSwitchMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareMainStreamGetStreamByExtensionToSwitchMC.ok>,
): State => {
  const isTab = !!~payload.newVideoTrack.label.indexOf('web-contents-media-stream')

  return {
    ...state,
    getScreenToSwitchUiStatus: 'completed',

    captureKind: state.captureKind === 'unknown' && isTab ? 'tab' : state.captureKind,

    curScreenStream: payload.newStream,
    curScreenVideoTrack: payload.newVideoTrack,

    screenStreamForVideo: new MediaStream([payload.newVideoTrack]),
  }
}

// ####################################################################################################
// ~~~~~~ Extension choose source to switch - ERROR
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamByExtensionToSwitchMT.ERROR] = (state: State): State => {
  return {
    ...state,
    getScreenToSwitchUiStatus: 'completed',
    captureKind: 'unknown',
  }
}

// ~~~~~~

export const sharedMainStreamReducer = UtilsStore.dynReducer(initState, reducer)
