const prefix = 'at-api-debugger'

// ~~~~~~ Action Types

export const ApiDebuggerAT = {
  SEND: `${prefix}-send`,
} as const

// ~~~~~~ Action Creators

export const ApiDebuggerAC = {
  send: (data: string) => ({
    type: ApiDebuggerAT.SEND,
    payload: {
      data,
    },
  }),
} as const
