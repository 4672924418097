/**
 * Its function is to send external actions to redux
 */

import { Dispatch } from 'react'
import { UnknownAction } from 'redux'
import { getStore } from '../../../store/store-config'
import { prefix } from '../actions/extension-partial-service'
import { extensionReduxDispatcher$$ } from '../subject/redux-dispatcher/extension-partial-service'

// ~~~~~~ Constants

// Definitions are in service-types.ts
export type ProcessAction = {
  type: string
  payload: any
  meta: any
}

// ####################################################################################################
// ~~~~~~ process
// ####################################################################################################

export const actionEmitter = (
  source: MessageEventSource,
  { type, payload, meta }: ProcessAction,
) => {
  reduxDispatch({
    type: `${prefix}-#-${type}`,
    payload,
    meta: {
      source,
      ...meta,
    },
  })
}

// ####################################################################################################
// ~~~~~~ [P] reduxDispatch: Send actions to redux
// ####################################################################################################

let dispatch: Dispatch<UnknownAction>

const reduxDispatch = (action: UnknownAction) => {
  extensionReduxDispatcher$$.next(action)
}

// ####################################################################################################
// ~~~~~~ initializeReduxDispatcher: subscribe to reduxDispatcher$
// ####################################################################################################

export const initializeReduxDispatcher = () => {
  extensionReduxDispatcher$$.subscribe({
    next: (action: UnknownAction) => {
      if (!dispatch) {
        dispatch = getStore().dispatch
      }

      dispatch(action)
    },
  })
}
