const prefix = 'mt-comm-channel-on-pub-available-send-cam-mic-start-epic'

// ~~~~~~ Mutators Types

export const EpicCommChannelOnPubAvailableSendCamMicStartEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
  TIMEOUT: `${prefix}-timeout`,
} as const

// ~~~~~~ Mutators Types

export const EpicCommChannelOnPubAvailableSendCamMicStartEpicMC = {
  ok: () => ({
    type: EpicCommChannelOnPubAvailableSendCamMicStartEpicMT.OK,
  }),

  error: () => ({
    type: EpicCommChannelOnPubAvailableSendCamMicStartEpicMT.ERROR,
  }),

  timeout: () => ({
    type: EpicCommChannelOnPubAvailableSendCamMicStartEpicMT.TIMEOUT,
  }),
} as const
