import { PaperScope } from 'paper/dist/paper-core'
import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'

export const init = () => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'init')

  const scope = new PaperScope()

  return scope
}
