import styled from 'styled-components'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Colors } from '../../../../style/theme/colors'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~~ Content

export const STDialogCreateAnAccountFreeOrBuyContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  .plans-subtitle {
    margin-bottom: ${Layout.Spacing[3]}px;
  }

  .all-plans {
    display: flex;
    justify-content: space-between;

    .plan {
      width: 283px;
      height: 406px;

      ${Layout.Border.Radius.B}

      &.free {
        background-color: ${theme(Colors.WOLF_00_100)};
        color: ${theme(Colors.WOLF_100_05)};
      }

      &.premium {
        background-color: ${theme(Colors.WOLF_100_05)};
        color: ${theme(Colors.WOLF_05_100)};
      }
    }
  }

  @media (max-width: ${MediaQuery.MD}) {
    .all-plans {
      flex-direction: column;
      align-items: center;

      .plan {
        height: 100%;
        margin-bottom: ${Layout.Spacing[3]}px;

        .plans-for-dialogs-logo {
          display: none;
        }

        .plans-for-dialogs-title {
          margin-bottom: 0;
        }

        &.free {
          hr {
            margin-bottom: ${Layout.Spacing[5]}px;
          }
        }

        &.premium {
          .line.price {
            margin-bottom: ${Layout.Spacing[5]}px;
          }
        }
      }
    }
  }
`

// ~~~~~~~ Action

export const STDialogCreateAnAccountFreeOrBuyAction = styled.div`
  margin: 8px;
`
