import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Config } from '../../../../config'
import { ModalsIds } from '../../../../constants/modals'
import { StorePlanFieldsMR } from '../../../../models/store/plan/model'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { DialogConfirmation } from '../component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmUserAlreadyExists)

// ~~~~~~ Component

export const DialogConfirmUserAlreadyExists = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  // ~~~~~~ Handlers

  function onClickOk() {
    window.open(Config.Links.BuyBroadcastURL)

    setDoClose(performance.now())
  }

  function onClickClose() {
    setDoClose(performance.now())
  }

  function onEndDissapear() {
    dispatch(StorePlanFieldsMR.reset.MC.reset())
  }

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      Dialog={Dialog}
      predefIcon="info"
      title="dialogs.confirm.user-already-exists.Title"
      contentText="dialogs.confirm.user-already-exists.Content"
      okButtonIntl="common.ManageAccount"
      closeButtonIntl="common.MaybeLater"
      isRunning={false}
      doClose={doClose}
      onClickConfirm={onClickOk}
      onClickClose={onClickClose}
      onEndDisappear={onEndDissapear}
    />
  )
}
