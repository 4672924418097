import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UtilsNumber } from '@dn/utils'

import { Button } from '../../../components/common/button/component'
import { InfoBox } from '../../../components/common/infos/box/component'
import { Trans } from '../../../components/common/intl/trans'
import BroadcastBrand from '../../../components/svgs/logos/broadcast-brand/component'
import { Config } from '../../../config'
import { useValidator } from '../../../hooks/validators/use-validators'
import { StoreState } from '../../../models/app/model'
import {
  StreamManagerSubMain,
  StreamManagerSubMainFieldsMR,
} from '../../../models/stream-manager/sub/main/model'
import { ApiStreamManagerServerAC } from '../../../store/actions/api-stream-manager/server/actions'
import { BroadcastIdInput } from './broadcast-id-input/component'
import { STJoinContent, ClassName } from './style'
import { ApiBcastRoomAC } from '../../../store/actions/api-bcast/room/actions'

// ~~~~~~ Constants

const IDLength = 6

// ~~~~~~ Component

export const JoinContent = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const validateId = useValidator(
    StreamManagerSubMain.validations.Id,
    StreamManagerSubMainFieldsMR.id,
  )

  // ~~~~~~ State

  // - Local

  const [isDirty, setIsDirty] = useState(false)

  // - Store

  const streamManagerSubMain = useSelector((state: StoreState) => state.streamManagerSubMain)

  const streamManagerServerSubMain = useSelector(
    (state: StoreState) => state.streamManagerServerSubMain,
  )

  const getSreenUIStatus = useSelector(
    (state: StoreState) => state.sharedMainStream.getSreenUiStatus,
  )

  const connectionStatus = useSelector((state: StoreState) => state.root.connectionStatus)

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  const root = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Computed

  const dnBcastGetInfoUiStatus = dnBcast.getSubDataUiStatus

  const broadcastId = UtilsNumber.safeStrOfNumberInt(streamManagerSubMain.id)

  const hasClientErrors = !!streamManagerSubMain.id_err.length

  const getScreenIsRunning = getSreenUIStatus === 'running'

  const getServerIsRunning =
    streamManagerServerSubMain.uiStatus === 'running' ||
    dnBcastGetInfoUiStatus === 'running' ||
    root.uiRoomVersion === 'running'

  const isBroadcastInputDisabled = getScreenIsRunning

  const joinIsDisabled =
    broadcastId.length < IDLength ||
    getServerIsRunning ||
    getScreenIsRunning ||
    connectionStatus === 'offline' ||
    hasClientErrors

  // ~~~~~~~ Handlers

  function onClickFindOutMore() {
    window.open(Config.Links.FindOutMore)
  }

  function onClickConnect(evt?: any) {
    evt && evt.preventDefault()

    if (getScreenIsRunning || getServerIsRunning) return

    submit()
  }

  function onChangeId(value: string) {
    const number = UtilsNumber.safeStrOfNumberInt(value)

    const numberStr = `${number}`.slice(0, StreamManagerSubMain.MaxIdLenght)

    isDirty && validateId(numberStr)

    dispatch(StreamManagerSubMainFieldsMR.id.MC.change(value))

    if (numberStr.length !== 6) return

    submit(numberStr)
  }

  function submit(value?: string) {
    setIsDirty(true)

    const finalValue = value || streamManagerSubMain.id

    const number = UtilsNumber.safeStrOfNumberInt(finalValue)

    // Validate

    const errors = [validateId(number).length].filter(Boolean)

    // Cancel submit

    if (errors.length) return

    // Clean dirty

    setIsDirty(false)

    // Submit

    Config.Features.BcastService2
      ? Config.Features.BcastCheckVersion
        ? dispatch(ApiBcastRoomAC.version(number))
        : dispatch(ApiBcastRoomAC.forSub(number))
      : dispatch(ApiStreamManagerServerAC.getServerSubMain(number))
  }

  // ~~~~~~ Render

  return (
    <STJoinContent>
      <div className={`${ClassName}--content`}>
        {/* Broadcast */}

        <div className={`${ClassName}--content--bcast`}>
          <BroadcastBrand />
        </div>

        {/* Info 6-digit */}

        <div className={`${ClassName}--content--info-join`}>
          <Trans id="pages.join.InfoDigits" />
        </div>

        {/* Backend errors */}

        {streamManagerServerSubMain.errors.length ? (
          <div className={`${ClassName}--content--errors`}>
            <InfoBox withIcon={true} $boxType="ERROR" infos={streamManagerServerSubMain.errors} />
          </div>
        ) : undefined}

        {dnBcast.getSubDataErrors.length ? (
          <div className={`${ClassName}--content--errors`}>
            <InfoBox withIcon={true} $boxType="ERROR" infos={dnBcast.getSubDataErrors as Infos[]} />
          </div>
        ) : undefined}

        {root.uiRoomVersionErrors.length ? (
          <div className={`${ClassName}--content--errors`}>
            <InfoBox withIcon={true} $boxType="ERROR" infos={root.uiRoomVersionErrors} />
          </div>
        ) : undefined}

        {/* Broadcast Id Inputs */}

        <BroadcastIdInput
          id={streamManagerSubMain.id}
          onChangeId={onChangeId}
          disabled={isBroadcastInputDisabled}
        />

        {/* Join Button */}

        <Button
          data-testid="view-bcast-act"
          className={`${ClassName}--content--action`}
          $width="100%"
          $colorType="primary"
          intlId="pages.join.actions.View"
          $active={!joinIsDisabled}
          $running={getServerIsRunning}
          disabled={joinIsDisabled}
          onClick={onClickConnect}
        />

        {/* Bottom Info: Find out more */}

        <div className={`${ClassName}--content--info-extra`}>
          <span>
            <Trans id="pages.join.bcast-info.Info" />
          </span>
          <span> </span>
          <span
            data-testid="go-to-find-out-more"
            className={`${ClassName}--content--info-extra--link`}
            onClick={onClickFindOutMore}
          >
            <Trans id="pages.join.bcast-info.Link" />
          </span>
        </div>
      </div>
    </STJoinContent>
  )
}
