import { AppInitState } from '../../../../../models/app/model'
import { UiOnboardingFieldsMR } from '../../../../../models/bcast/ui-onboarding/model'
import { UtilsStore } from '@dn/utils'
import {
  EpicApiBcastCurrentUserShowMC,
  EpicApiBcastCurrentUserShowMT,
} from '../../../../epics/api-bcast/current-user/show/mutators'

const initState = AppInitState.uiOnboarding

type State = typeof initState

const reducer: any = {
  ...UiOnboardingFieldsMR.buttonState.Reducer,
  ...UiOnboardingFieldsMR.panelState.Reducer,
  ...UiOnboardingFieldsMR.tooltipState.Reducer,

  ...UiOnboardingFieldsMR.onboardingChangeSourceBounds.Reducer,
  ...UiOnboardingFieldsMR.onboardingShareBounds.Reducer,
  ...UiOnboardingFieldsMR.onboardingLiveDrawBounds.Reducer,
}

// ####################################################################################################
// ~~~~~~ Current user Show - OK
// ####################################################################################################

reducer[EpicApiBcastCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastCurrentUserShowMC.ok>,
): State => {
  return {
    ...initState,

    buttonState: payload.userSettings.onboardingPercent !== 100 ? 'button-only' : 'hidden',
  }
}

// ~~~~~~

export const uiOnboardingReducer = UtilsStore.dynReducer(initState, reducer, (state) => {
  return state
})
