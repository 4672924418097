import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { getThemeValue } from '../../../../../style/utils/theme'
import { PaintToolbarCommonColors, STPaintToolbarCommonStyle } from '../common-style'

// ~~~~~~ Types

type STProps = {
  $selected: boolean
  $isLegacyButton?: true
  $disabled: boolean
}

// ~~~~~~ Component

export const STPaintToolbarButtonsEraser = styled.div<STProps>`
  ${STPaintToolbarCommonStyle}

  ${({ $isLegacyButton }) => ($isLegacyButton ? 'transform: scaleX(-1);' : '')};

  width: 26px;

  svg {
    path {
      fill: none;
      stroke: ${(props) =>
        props.$selected
          ? getThemeValue(
              props,
              props.$isLegacyButton ? Colors.WOLF_40_40 : PaintToolbarCommonColors.selected,
            )
          : getThemeValue(
              props,
              props.$isLegacyButton ? Colors.WOLF_05_05 : PaintToolbarCommonColors.color,
            )};
    }
  }

  @media (hover: hover) {
    &:hover {
      svg path {
        fill: none;
        stroke: ${(props) =>
          getThemeValue(
            props,
            props.$isLegacyButton ? Colors.WOLF_40_40 : PaintToolbarCommonColors.hover,
          )};
      }
    }
  }
`
