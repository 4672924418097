import React from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'
import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { STPaintToolbarButtonsEraser } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/eraser')

// ~~~~~~ Types

type Props = {
  isLegacyButton?: true
  actionKind: 'paint-bcast-stream' | 'live-paint'
  onClick: () => void
}

// ~~~~~~ Component

export const PaintToolbarButtonsEraser: React.FC<Props> = ({
  isLegacyButton,
  actionKind,
  onClick,
}) => {
  // ~~~~~~ State

  const { isErasing } = useSelector((state: StoreState) => {
    switch (actionKind) {
      case 'paint-bcast-stream':
        return state.paintBcastStreamDraw

      case 'live-paint':
        return state.livePaintDraw
    }
  })

  // ~~~~~~ Render

  return (
    <STPaintToolbarButtonsEraser
      $isLegacyButton={isLegacyButton}
      $selected={isErasing}
      $disabled={false}
      onClick={onClick}
    >
      <Icon size={24} />
    </STPaintToolbarButtonsEraser>
  )
}
