const prefix = 'at-cancel-now'

// ~~~~~~ Action Types

export const CancelNowAT = {
  CANCEL_ALL: `${prefix}-all`,
  CANCEL_CAM_MIC: `${prefix}-cam-mic`,
} as const

// ~~~~~~ Action Creators

// If you add a new reason here
// maybe a track event is required
// and the event needs to be added
// in use-broadcast-ended hook

export enum CancelReasons {
  NoInternetConnection = 'no-internet-connection',
  Inactivity = 'inactivity',

  ScreenSharingCanceled = 'screen-sharing-canceled', // <- User press "cancel" on select screen media
  ScreenSharingEnded = 'screen-sharing-ended', // <- User press browsers' stop sharing button
  ScreenStreamHasNotAMediaTrack = 'screen-stream-has-not-a-media-track',

  CamMicSharingCanceled = 'cam-mic-sharing-canceled',
  CamMicSharingEnded = 'cam-mic-sharing-ended',
  CamMicStreamHasNotAMediaTrack = 'cam-mic-stream-has-not-a-media-track',

  NoScreenStreamFound = 'no-screen-stream-found',
  NoStreamQualityFound = 'no-stream-quality-found',
  UserStopped = 'user-stopped',
  SessionEnded = 'session-ended',
  UserLimitReached = 'user-limit-reached',
  TimeLimitReached = 'time-limit-reached',
  BeforeUnload = 'before-unload',
  ConnectionClosed = 'connection-closed',
  DeviceOffline = 'device-offline',
  InvalidName = 'invalid-name', // <- Broadcast stopped when client is connecting
  Unknown = 'unknown',
  WebsocketFail = 'websocket-fail',
  PublishFail = 'publish-fail',
  DataChannelError = 'data-channel-error',
  VideoTooManyProgress = 'video-too-many-progress',

  CommChannelAckTimeout = 'comm-channel-ack-timeout',
  CommChannelErr = 'comm-channel-err',
}

export const CancelNowAC = {
  cancelAll: (reasons: CancelReasons[]) => ({
    type: CancelNowAT.CANCEL_ALL,
    payload: reasons,
  }),

  cancelCamMic: (reasons: CancelReasons[]) => ({
    type: CancelNowAT.CANCEL_CAM_MIC,
    payload: reasons,
  }),
} as const
