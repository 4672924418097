import { Link } from 'react-router-dom'
import {
  DNContactPage,
  DNCookiePolicy,
  DNInfoMail,
  DNPrivacyAndCookiePolicies,
  DNPrivacyPolicy,
} from '../../../components/common/privacy-terms-links/component'

import { TablesDefinitioins } from '../../../components/common/tables/definitions/component'
import { LayoutText } from '../../../components/layouts/text/component'
import { Routes } from '../../../constants/routes/routes'
import { STConditions } from './style'

// ~~~~~~ Constants

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Definitions: Definition[] = [
  {
    word: 'Content',
    def: 'means any and all text, images, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that appears on, or forms part of, Our Site; and',
  },

  {
    word: 'We/Us/Our',
    def: 'Means DisplayNote Technologies Ltd. Of Concourse 3 Building, Queens Road, Belfast, BT3 9DT.',
  },
]

// ~~~~~~ Types

type Definition = {
  word: string
  def: string
}

// ~~~~~~ Component

export const TermsPage = () => {
  // ~~~~~~ Render

  return (
    <LayoutText>
      <STConditions>
        <h1>Terms and Conditions</h1>

        <h2>BACKGROUND</h2>

        <p>
          These Terms and Conditions, together with any and all other documents referred to herein,
          set out the terms of use under which you may use this website,
          <span> </span>
          <Link to={Routes.Main}>www.broadcast.online</Link>
          <span> </span>
          (“Our Site”). Please read these Terms and Conditions carefully and ensure that you
          understand them. Your agreement to comply with and be bound by these Terms and Conditions
          is deemed to occur upon your first use of Our Site. If you do not agree to comply with and
          be bound by these Terms and Conditions, you must stop using Our Site immediately.
        </p>

        <h2>1. Definitions and Interpretation</h2>

        <p>
          1.1 In these Terms and Conditions, unless the context otherwise requires, the following
          expressions have the following meanings:
        </p>

        <TablesDefinitioins
          definitions={[
            {
              word: 'Content',
              def: 'means any and all text, images, audio, video, scripts, code, software, databases and any other form of information capable of being stored on a computer that appears on, or forms part of, Our Site; and',
            },

            {
              word: 'We/Us/Our',
              def: 'Means DisplayNote Technologies Ltd. Of Concourse 3 Building, Queens Road, Belfast, BT3 9DT.',
            },
          ]}
        />

        <h2>2. Information About Us</h2>

        <p>
          2.1 Our Site, www.broadcast.online, is operated by DisplayNote Technologies of Concourse 3
          Building, Queens Road, Belfast, BT3 9DT. Our VAT number is GB125654319.
        </p>

        <h2>3. Access to Our Site</h2>

        <p>3.1 Access to Our Site is free of charge.</p>

        <p>
          3.2 It is your responsibility to make any and all arrangements necessary in order to
          access Our Site.
        </p>

        <p>
          3.3 Access to Our Site is provided “as is” and on an “as available” basis. We may alter,
          suspend or discontinue Our Site (or any part of it) at any time and without notice. We
          will not be liable to you in any way if Our Site (or any part of it) is unavailable at any
          time and for any period.
        </p>

        <h2>4. Intellectual Property Rights</h2>

        <p>
          4.1 All Content included on Our Site and the copyright and other intellectual property
          rights subsisting in that Content, unless specifically labelled otherwise, belongs to or
          has been licensed by Us. All Content is protected by applicable United Kingdom and
          international intellectual property laws and treaties.
        </p>

        <p>
          4.2 Subject to sub-Clauses 4.3 and 4.6 you may not reproduce, copy, distribute, sell,
          rent, sub-licence, store, or in any other manner re-use Content from Our Site unless given
          express written permission to do so by Us.
        </p>

        <p>4.3 You may:</p>

        <p>
          4.3.1 Access, view and use Our Site in a web browser (including any web browsing
          capability built into other types of software or app);
        </p>

        <p>4.3.2 Download Our Site (or any part of it) for caching;</p>

        <p>4.3.3 Print pages from Our Site;</p>

        <p>4.3.4 Download extracts from pages on Our Site; and</p>

        <p>4.3.5 Save pages from Our Site for later and/or offline viewing.</p>

        <p>
          4.4 Our status as the owner and author of the Content on Our Site (or that of identified
          licensors, as appropriate) must always be acknowledged.
        </p>

        <p>
          4.5 You may not use any Content saved or downloaded from Our Site for commercial purposes
          without first obtaining a licence from Us (or our licensors, as appropriate) to do so.
          This does not prohibit the normal access, viewing and use of Our Site for general
          information purposes whether by business users or consumers.
        </p>

        <p>
          4.6 Nothing in these Terms and Conditions limits or excludes the provisions of Chapter III
          of the Copyrights, Designs and Patents Act 1988 'Acts Permitted in Relation to Copyright
          Works', covering in particular the making of temporary copies; research and private study;
          the making of copies for text and data analysis for non-commercial research; criticism,
          review, quotation and news reporting; caricature, parody or pastiche; and the incidental
          inclusion of copyright material.
        </p>

        <h2>5. Links to Our Site</h2>

        <p>5.1 You may link to Our Site provided that:</p>

        <p>5.1.1 You do so in a fair and legal manner;</p>

        <p>
          5.1.2 You do not do so in a manner that suggests any form of association, endorsement or
          approval on Our part where none exists;
        </p>

        <p>
          5.1.3 You do not use any logos or trade marks displayed on Our Site without Our express
          written permission; and
        </p>

        <p>
          5.1.4 You do not do so in a way that is calculated to damage Our reputation or to take
          unfair advantage of it.
        </p>

        <p>
          5.2 You may not link to Our Site from any other site the main content of which contains
          material that:
        </p>

        <p>5.2.1 is obscene, deliberately offensive, hateful or otherwise inflammatory;</p>

        <p>5.2.2 promotes violence;</p>

        <p>5.2.3 promotes or assists in any form of unlawful activity;</p>

        <p>
          5.2.4 discriminates against, or is in any way defamatory of, any person, group or class of
          persons, race, sex, religion, nationality, disability, sexual orientation, or age;
        </p>

        <p>
          5.2.5 is intended or is otherwise likely to threaten, harass, annoy, alarm, inconvenience,
          upset, or embarrass another person; 5.2.6 is calculated or is otherwise likely to deceive
          another person;
        </p>

        <p>
          5.2.7 is intended or otherwise likely to infringe (or threaten to infringe) another
          person's right to privacy;
        </p>

        <p>
          5.2.8 misleadingly impersonates any person or otherwise misrepresents the identity or
          affiliation of a particular person in a way that is calculated to deceive (obvious
          parodies are not included in this definition provided that they do not fall within any of
          the other provisions of this sub-Clause 5.4);
        </p>

        <p>5.2.9 implies any form of affiliation with Us where none exists;</p>

        <p>
          5.2.10 infringes, or assists in the infringement of, the intellectual property rights
          (including, but not limited to, copyright, trademarks and database rights) of any other
          party; or
        </p>

        <p>
          5.2.11 is made in breach of any legal duty owed to a third party including, but not
          limited to, contractual duties and duties of confidence.
        </p>

        <p>
          5.3 The content restrictions in sub-Clause 5.4 do not apply to content submitted to sites
          by other users provided that the primary purpose of the site accords with the provisions
          of sub-Clause
        </p>

        <p>
          5.4. You are not, for example, prohibited from posting links on general-purpose social
          networking sites merely because another user may post such content. You are, however,
          prohibited from posting links on websites which focus on or encourage the submission of
          such content from users.
        </p>

        <h2>6. Links to Other Sites</h2>

        <p>
          Links to other sites may be included on Our Site. Unless expressly stated, these sites are
          not under Our control. We neither assume nor accept responsibility or liability for the
          content of third party sites. The inclusion of a link to another site on Our Site is for
          information only and does not imply any endorsement of the sites themselves or of those in
          control of them.
        </p>

        <h2>7. Disclaimers</h2>

        <p>
          7.1 Nothing on Our Site constitutes advice on which you should rely. It is provided for
          general information purposes only.
        </p>

        <p>
          7.2 Insofar as is permitted by law, We make no representation, warranty, or guarantee that
          Our Site will meet your requirements, that it will not infringe the rights of third
          parties, that it will be compatible with all software and hardware, or that it will be
          secure.
        </p>

        <p>
          7.3 We make reasonable efforts to ensure that the Content on Our Site is complete,
          accurate, and up-to-date. We do not, however, make any representations, warranties or
          guarantees (whether express or implied) that the Content is complete, accurate, or
          up-to-date.
        </p>

        <h2>8. Our Liability</h2>

        <p>
          8.1 To the fullest extent permissible by law, We accept no liability to any user for any
          loss or damage, whether foreseeable or otherwise, in contract, tort (including
          negligence), for breach of statutory duty, or otherwise, arising out of or in connection
          with the use of (or inability to use) Our Site or the use of or reliance upon any Content
          included on Our Site.
        </p>

        <p>
          8.2 To the fullest extent permissible by law, We exclude all representations, warranties,
          and guarantees (whether express or implied) that may apply to Our Site or any Content
          included on Our Site.
        </p>

        <p>
          8.3 If you are a business user, We accept no liability for loss of profits, sales,
          business or revenue; loss of business opportunity, goodwill or reputation; loss of
          anticipated savings; business interruption; or for any indirect or consequential loss or
          damage.
        </p>

        <p>
          8.4 We exercise all reasonable skill and care to ensure that Our Site is free from viruses
          and other malware. We accept no liability for any loss or damage resulting from a virus or
          other malware, a distributed denial of service attack, or other harmful material or event
          that may adversely affect your hardware, software, data or other material that occurs as a
          result of your use of Our Site (including the downloading of any Content from it) or any
          other site referred to on Our Site.
        </p>

        <p>
          8.5 We neither assume nor accept responsibility or liability arising out of any disruption
          or non-availability of Our Site resulting from external causes including, but not limited
          to, ISP equipment failure, host equipment failure, communications network failure, natural
          events, acts of war, or legal restrictions and censorship.
        </p>

        <p>
          8.6 Nothing in these Terms and Conditions excludes or restricts Our liability for fraud or
          fraudulent misrepresentation, for death or personal injury resulting from negligence, or
          for any other forms of liability which cannot be excluded or restricted by law. For full
          details of consumers' legal rights, including those relating to digital content, please
          contact your local Citizens' Advice Bureau or Trading Standards Office.
        </p>

        <h2>9. Viruses, Malware and Security</h2>

        <p>
          9.1 We exercise all reasonable skill and care to ensure that Our Site is secure and free
          from viruses and other malware.
        </p>

        <p>
          9.2 You are responsible for protecting your hardware, software, data and other material
          from viruses, malware, and other internet security risks.
        </p>

        <p>
          9.3 You must not deliberately introduce viruses or other malware, or any other material
          which is malicious or technologically harmful either to or via Our Site.
        </p>

        <p>
          9.4 You must not attempt to gain unauthorised access to any part of Our Site, the server
          on which Our Site is stored, or any other server, computer, or database connected to Our
          Site.
        </p>

        <p>
          9.5 You must not attack Our Site by means of a denial of service attack, a distributed
          denial of service attack, or by any other means.
        </p>

        <p>
          9.6 By breaching the provisions of sub-Clauses 9.3 to 9.5 you may be committing a criminal
          offence under the Computer Misuse Act 1990. Any and all such breaches will be reported to
          the relevant law enforcement authorities and We will cooperate fully with those
          authorities by disclosing your identity to them. Your right to use Our Site will cease
          immediately in the event of such a breach.
        </p>

        <h2>10. Acceptable Usage Policy</h2>

        <p>10.1 You may only use Our Site in a manner that is lawful. Specifically:</p>

        <p>
          10.1.1 you must ensure that you comply fully with any and all local, national or
          international laws and/or regulations;
        </p>

        <p>
          10.1.2 you must not use Our Site in any way, or for any purpose, that is unlawful or
          fraudulent;
        </p>

        <p>
          10.1.3 you must not use Our Site to knowingly send, upload, or in any other way transmit
          data that contains any form of virus or other malware, or any other code designed to
          adversely affect computer hardware, software, or data of any kind; and
        </p>

        <p>
          10.1.4 you must not use Our Site in any way, or for any purpose, that is intended to harm
          any person or persons in any way.
        </p>

        <p>
          10.2 We reserve the right to suspend or terminate your access to Our Site if you
          materially breach the provisions of this Clause 10 or any of the other provisions of these
          Terms and Conditions. Specifically, We may take one or more of the following actions:
        </p>

        <p>10.2.1 suspend, whether temporarily or permanently, your right to access Our Site;</p>

        <p>10.2.2 issue you with a written warning;</p>

        <p>
          10.2.3 take legal proceedings against you for reimbursement of any and all relevant costs
          on an indemnity basis resulting from your breach;
        </p>

        <p>10.2.4 take further legal action against you as appropriate;</p>

        <p>
          10.2.5 disclose such information to law enforcement authorities as required or as We deem
          reasonably necessary; and/or
        </p>

        <p>10.2.6 any other actions which We deem reasonably appropriate (and lawful).</p>

        <p>
          10.3 We hereby exclude any and all liability arising out of any actions (including, but
          not limited to those set out above) that We may take in response to breaches of these
          Terms and Conditions
        </p>

        <h2>11. Privacy and Cookies</h2>

        <p>
          Use of Our Site is also governed by Our Cookie and Privacy Policies, available from
          <span> </span>
          <DNCookiePolicy /> <span> </span>
          and
          <span> </span>
          <DNPrivacyPolicy />
          <span> </span>.<span> </span>
          These policies are incorporated into these Terms and Conditions by this reference.
        </p>

        <h2>12. Changes to these Terms and Conditions</h2>

        <p>
          12.1 We may alter these Terms and Conditions at any time. Any such changes will become
          binding on you upon your first use of Our Site after the changes have been implemented.
          You are therefore advised to check this page from time to time.
        </p>

        <p>
          12.2 In the event of any conflict between the current version of these Terms and
          Conditions and any previous version(s), the provisions current and in effect shall prevail
          unless it is expressly stated otherwise.
        </p>

        <h2>13. Contacting Us</h2>

        <p>
          To contact Us, please email Us at
          <span> </span>
          <DNInfoMail />
          <span> </span>
          or using any of the methods provided on Our
          <span> </span>
          <DNContactPage />.
        </p>

        <h2>14. Data Protection</h2>

        <p>
          14.1 All personal information that We may use will be collected, processed, and held in
          accordance with the provisions of EU Regulation 2016/679 General Data Protection
          Regulation (“GDPR”) and your rights under the GDPR.
        </p>

        <p>
          14.2 For complete details of Our collection, processing, storage, and retention of
          personal data including, but not limited to, the purpose(s) for which personal data is
          used, the legal basis or bases for using it, details of your rights and how to exercise
          them, and personal data sharing (where applicable), please refer to Our{' '}
          <DNPrivacyAndCookiePolicies />.
        </p>

        <h2>15. Law and Jurisdiction</h2>

        <p>
          15.1 These Terms and Conditions, and the relationship between you and Us (whether
          contractual or otherwise) shall be governed by, and construed in accordance with the law
          of Northern Ireland.
        </p>

        {/* <p>
        15.2 If you are a consumer, you will benefit from any mandatory provisions of the law in
        your country of residence. Nothing in Sub-Clause 16.1 above takes away or reduces your
        rights as a consumer to rely on those provisions.
      </p> */}

        <p>
          15.2 If you are a consumer, any dispute, controversy, proceedings or claim between you and
          Us relating to these Terms and Conditions, or the relationship between you and Us (whether
          contractual or otherwise) shall be subject to the jurisdiction of the courts of England,
          Wales, Scotland, or Northern Ireland, as determined by your residency.
        </p>

        <p>
          15.3 If you are a business, any disputes concerning these Terms and Conditions, the
          relationship between you and Us, or any matters arising therefrom or associated therewith
          (whether contractual or otherwise) shall be subject to the exclusive jurisdiction of the
          courts of Northern Ireland.
        </p>
      </STConditions>
    </LayoutText>
  )
}
