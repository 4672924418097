import { STJoinedCommonHeader } from './style'
import { JoinedCommonHeaderTopLeft } from './top-left/component'
import { JoinedCommonHeaderTopRight } from './top-right/component'

// ~~~~~~ Component

export const JoinedCommonHeader: React.FC = () => {
  // ~~~~~~ Render

  return (
    <STJoinedCommonHeader>
      {/* Left */}

      <div>
        <JoinedCommonHeaderTopLeft />
      </div>

      {/* Right */}

      <div>
        <JoinedCommonHeaderTopRight />
      </div>

      {/* - */}
    </STJoinedCommonHeader>
  )
}
