import { LazySVG } from '../../svgs/lazy-svg/component'
import { Trans } from '../intl/trans'
import { STToast } from './style'

// ~~~~~~ Constants

const SuccessIcon = LazySVG('icons/circle-success')
const ErrorIcon = LazySVG('icons/circle-error')

// ~~~~~~ Types

export type Props = {
  kind: 'success' | 'error'
  intlIds: [
    {
      id: IntlMsgId
      values?: any
    },
  ]
}

// ~~~~~~ Component

export const Toast: React.FC<Props> = ({ kind, intlIds }) => {
  // ~~~~~~ Render

  return (
    <STToast $kind={kind}>
      <div className="toast-kind-line">
        <div className="toast-icon">
          {kind === 'success' ? <SuccessIcon size={16} /> : <ErrorIcon size={16} />}
        </div>
      </div>

      <div className="toast-text-lines">
        {intlIds.map(({ id, values }, idx) => (
          <div key={idx} className="toast-text-line">
            <Trans id={id} values={values} />
          </div>
        ))}
      </div>
    </STToast>
  )
}
