import styled from 'styled-components'
import { STStartedContentMainToolbarsButtonsCommonStyle } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'started-content-main-toolbars-main-buttons-change-source'

// ~~~~~~ Component

export const STStartedContentMainToolbarsMainButtonsChangeSource = styled.div`
  ${STStartedContentMainToolbarsButtonsCommonStyle}

  white-space: nowrap;
  width: 100%;
`
