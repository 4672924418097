import styled from 'styled-components'
import { Colors } from '../../../../../../../style/theme/colors'
import { theme } from '../../../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-tablet-content-main-toolbars-capture-paint'

// ~~~~~~ Component

export const STJoinedTabletContentMainToolbarsCapturePaint = styled.div`
  .${ClassName}--content {
    background-color: ${theme(Colors.WOLF_00_90)};

    border-radius: 35px;

    height: 56px;

    display: flex;
    align-items: center;

    padding-left: 12px;
    padding-right: 12px;
  }
`
