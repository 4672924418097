import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, tap } from 'rxjs'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { apiBcastGoogleClassRoomAnnounce$ } from '../../../../../services/api-bcast/google-class-room/announce'
import { UtilsLog } from '../../../../../utils/logs'

import {
  ApiBcastGoogleClassRoomAC,
  ApiBcastGoogleClassRoomAT,
} from '../../../../actions/api-bcast/google-classroom/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC } from './mutators'

type Action = ReturnType<typeof ApiBcastGoogleClassRoomAC.announceBCastLink>

export const apiBcastGoogleClassRoomAnnounceBcastLinkEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastGoogleClassRoomAT.ANNOUNCE_BCAST_LINK),

    // Api call

    mergeMap(({ payload }) =>
      apiBcastGoogleClassRoomAnnounce$(
        payload.withGoogleAccessToken.withIt
          ? payload.withGoogleAccessToken
          : {
              ...payload.withGoogleAccessToken,
              token: state$.value.currentUser.token,
            },
        payload.course.id,
        {
          text: payload.text,
          materials: [payload.materialLink],
        },
      ).pipe(
        map((res) => {
          return ApiUtils.isSuccess(res)
            ? {
                status: res.status,
                response: payload.course,
              }
            : res
        }),
      ),
    ),

    // Extension

    tap((res) => {
      if (
        !Config.isExtension ||
        ApiUtils.isSuccess(res) ||
        !window.chrome ||
        !window.chrome.identity
      ) {
        return
      }

      window.chrome.identity.clearAllCachedAuthTokens(() =>
        UtilsLog.devLog('clear all cached auth tokens'),
      )
    }),

    // Mutator

    map((res) => {
      return ApiUtils.isSuccess(res)
        ? EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC.ok(res.response)
        : EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC.error(
            res.response,
            /* unsync */ res.status === 412,
          )
    }),
  )
