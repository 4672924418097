import { useSelector } from 'react-redux'
import { StoreState } from '../../models/app/model'
import { BcastTrackEvents } from '../../services/track-events'
import { useMemo } from 'react'

export const useTrackStreamContext = () => {
  // ~~~~~~ State

  const streamContextData = useSelector((state: StoreState) => state.streamContext)

  // ~~~~~~ Computed

  const streamContext = useMemo(() => {
    return BcastTrackEvents.helpers.genStreamContext(streamContextData.id, streamContextData.kind)
  }, [streamContextData.id, streamContextData.kind])

  // ~~~~~~

  return streamContext
}
