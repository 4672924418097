const prefix = 'mt-api-bcast-subscription-show-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastSubscriptionShowMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastSubscriptionShowMC = {
  ok: (data: DN.Models.Subscription.Api.Show.ResParsed) => ({
    type: EpicApiBcastSubscriptionShowMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.Subscription.Api.Show.ResError) => ({
    type: EpicApiBcastSubscriptionShowMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
