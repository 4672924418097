import { AnimationProps, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from '../../intl/trans'
import { StoreState } from '../../../../models/app/model'
import { UtilsTooltip } from '@dn/utils'
import { STPaintToolbarButtonsOnlyColors } from './style'
import { PaintToolbarButtonsColor } from '../buttons/color/component'

// ~~~~~~ Constants

const TooltipColorBottom = 39

// ~~~~~~ Types

type Props = {
  actionKind: 'paint-bcast-stream' | 'live-paint'

  isOnlyColors: boolean

  variants: AnimationProps['variants']
  showAll: boolean

  onClickSelectColor: (newColorKey: DN.Models.PaintBcastStream.Draw.ColorKey) => void
  onClickShowColorSelector: () => void
  onClickSelectPen: (newPen: DN.Models.PaintBcastStream.Draw.Pen) => void
}

// ~~~~~~ Component

export const PaintToolbarButtonsOnlyColors: React.FC<Props> = ({
  actionKind,

  isOnlyColors,

  variants,
  showAll,

  onClickSelectColor,
  onClickShowColorSelector,
  onClickSelectPen,
}) => {
  // ~~~~~~ State

  // - Store

  const selectedColorKey = useSelector((state: StoreState) => {
    switch (actionKind) {
      case 'paint-bcast-stream':
        return state.paintBcastStreamDraw.colorKey

      case 'live-paint':
        return state.livePaintDraw.colorKey
    }
  })

  const selectedPen = useSelector((state: StoreState) => {
    switch (actionKind) {
      case 'paint-bcast-stream':
        return state.paintBcastStreamDraw.activePen

      case 'live-paint':
        return state.livePaintDraw.activePen
    }
  })

  const isErasing = useSelector((state: StoreState) => {
    switch (actionKind) {
      case 'paint-bcast-stream':
        return state.paintBcastStreamDraw.isErasing

      case 'live-paint':
        return state.livePaintDraw.isErasing
    }
  })

  // - Refs

  const selectColorTooltipRef = useRef<HTMLSpanElement>(null)

  const blackTooltipRef = useRef<HTMLSpanElement>(null)

  const whiteTooltipRef = useRef<HTMLSpanElement>(null)

  const purpleTooltipRef = useRef<HTMLSpanElement>(null)

  const blueTooltipRef = useRef<HTMLSpanElement>(null)

  const greenTooltipRef = useRef<HTMLSpanElement>(null)

  const yellowTooltipRef = useRef<HTMLSpanElement>(null)

  const redTooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Handlers

  function onClickCurrentColor() {
    onClickShowColorSelector()

    if (!isErasing) return

    onClickSelectPen(selectedPen)
  }

  // ~~~~~~ Effects

  // - Fix tooltip position

  useEffect(() => {
    // Select

    if (selectColorTooltipRef.current) {
      selectColorTooltipRef.current.style.left =
        UtilsTooltip.getTooltipStyle(selectColorTooltipRef).left
    }

    // Black

    if (blackTooltipRef.current) {
      blackTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(blackTooltipRef).left
    }

    // White

    if (whiteTooltipRef.current) {
      whiteTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(whiteTooltipRef).left
    }

    // Purple

    if (purpleTooltipRef.current) {
      purpleTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(purpleTooltipRef).left
    }

    // Blue

    if (blueTooltipRef.current) {
      blueTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(blueTooltipRef).left
    }

    // Green

    if (greenTooltipRef.current) {
      greenTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(greenTooltipRef).left
    }

    // Yellow

    if (yellowTooltipRef.current) {
      yellowTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(yellowTooltipRef).left
    }

    // Red

    if (redTooltipRef.current) {
      redTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(redTooltipRef).left
    }
  })

  // ~~~~~~ Render

  // Collapsed: Current Color

  if (!showAll) {
    return (
      <STPaintToolbarButtonsOnlyColors>
        <motion.div
          className="color-btn last with-tooltip"
          style={{ position: 'relative' }}
          variants={variants}
        >
          {/* Icon */}

          <PaintToolbarButtonsColor
            $colorKey={selectedColorKey}
            $selected={true}
            $disabled={false}
            onClick={onClickCurrentColor}
          />

          {/* Tooltip */}

          <span
            ref={selectColorTooltipRef}
            className="tooltiptext"
            style={{
              ...UtilsTooltip.getTooltipStyle(selectColorTooltipRef),
            }}
          >
            <Trans id="tooltip.EditPic.SelectColor" />
          </span>
        </motion.div>
      </STPaintToolbarButtonsOnlyColors>
    )
  }

  // Color selector

  return (
    <STPaintToolbarButtonsOnlyColors>
      {/* Black */}

      <motion.div
        className={`${isOnlyColors ? 'first' : ''} color-btn with-tooltip`}
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsColor
          $colorKey="BLACK"
          $selected={selectedColorKey === 'BLACK'}
          $disabled={false}
          onClick={() => onClickSelectColor('BLACK')}
        />

        {/* Tooltip */}

        <span
          ref={blackTooltipRef}
          className="tooltiptext"
          style={{
            ...UtilsTooltip.getTooltipStyle(blackTooltipRef),
            bottom: TooltipColorBottom,
          }}
        >
          <Trans id="tooltip.EditPic.Colors.Black" />
        </span>
      </motion.div>

      {/* White */}

      <motion.div
        className="color-btn with-tooltip"
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsColor
          $colorKey="WHITE"
          $selected={selectedColorKey === 'WHITE'}
          $disabled={false}
          onClick={() => onClickSelectColor('WHITE')}
        />

        {/* Tooltip */}

        <span
          ref={whiteTooltipRef}
          className="tooltiptext"
          style={{
            ...UtilsTooltip.getTooltipStyle(whiteTooltipRef),
            bottom: TooltipColorBottom,
          }}
        >
          <Trans id="tooltip.EditPic.Colors.White" />
        </span>
      </motion.div>

      {/* Purple */}

      <motion.div
        className="color-btn with-tooltip"
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsColor
          $colorKey="PURPLE"
          $selected={selectedColorKey === 'PURPLE'}
          $disabled={false}
          onClick={() => onClickSelectColor('PURPLE')}
        />

        {/* Tooltip */}

        <span
          ref={purpleTooltipRef}
          className="tooltiptext"
          style={{
            ...UtilsTooltip.getTooltipStyle(purpleTooltipRef),
            bottom: TooltipColorBottom,
          }}
        >
          <Trans id="tooltip.EditPic.Colors.Purple" />
        </span>
      </motion.div>

      {/* Blue */}

      <motion.div
        className="color-btn with-tooltip"
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsColor
          $colorKey="BLUE"
          $selected={selectedColorKey === 'BLUE'}
          $disabled={false}
          onClick={() => onClickSelectColor('BLUE')}
        />

        {/* Tooltip */}

        <span
          ref={blueTooltipRef}
          className="tooltiptext"
          style={{
            ...UtilsTooltip.getTooltipStyle(blueTooltipRef),
            bottom: TooltipColorBottom,
          }}
        >
          <Trans id="tooltip.EditPic.Colors.Blue" />
        </span>
      </motion.div>

      {/* Green */}

      <motion.div
        className="color-btn with-tooltip"
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsColor
          $colorKey="GREEN"
          $selected={selectedColorKey === 'GREEN'}
          $disabled={false}
          onClick={() => onClickSelectColor('GREEN')}
        />

        {/* Tooltip */}

        <span
          ref={greenTooltipRef}
          className="tooltiptext"
          style={{
            ...UtilsTooltip.getTooltipStyle(greenTooltipRef),
            bottom: TooltipColorBottom,
          }}
        >
          <Trans id="tooltip.EditPic.Colors.Green" />
        </span>
      </motion.div>

      {/* Yellow */}

      <motion.div
        className="color-btn with-tooltip"
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsColor
          $colorKey="YELLOW"
          $selected={selectedColorKey === 'YELLOW'}
          $disabled={false}
          onClick={() => onClickSelectColor('YELLOW')}
        />

        {/* Tooltip */}

        <span
          ref={yellowTooltipRef}
          className="tooltiptext"
          style={{
            ...UtilsTooltip.getTooltipStyle(yellowTooltipRef),
            bottom: TooltipColorBottom,
          }}
        >
          <Trans id="tooltip.EditPic.Colors.Yellow" />
        </span>
      </motion.div>

      {/* Red */}

      <motion.div
        className="color-btn last with-tooltip"
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsColor
          $colorKey="RED"
          $selected={selectedColorKey === 'RED'}
          $disabled={false}
          onClick={() => onClickSelectColor('RED')}
        />

        {/* Tooltip */}

        <span
          ref={redTooltipRef}
          className="tooltiptext"
          style={{
            ...UtilsTooltip.getTooltipStyle(redTooltipRef),
            bottom: TooltipColorBottom,
          }}
        >
          <Trans id="tooltip.EditPic.Colors.Red" />
        </span>
      </motion.div>
    </STPaintToolbarButtonsOnlyColors>
  )
}
