import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Config } from '../../../../config'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { StorePlanFieldsMR } from '../../../../models/store/plan/model'
import { ModalsMC } from '../../../../store/actions-mutators/modals/mutators'
import { Button } from '../../../common/button/component'
import { DangerTrans } from '../../../common/intl/danger-trans'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { DialogAddCardForm } from './form/component'
import { STExtraInfo } from './style'
import { BcastTrackEvents } from '../../../../services/track-events'
import { useTrackUserContext } from '../../../../hooks/track-events/use-track-user-context'

// ~~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.LoggedStep1AddPaymentMethod)

// ~~~~~~~ Types

type Props = {
  stripe: Promise<Stripe | null>
}

// ~~~~~~~ Component

export const DialogSubscriptionsLogguedStep1AddPaymentMethod: React.FC<Props> = ({ stripe }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const [triggerAddCard, setTriggerAddCard] = useState(0)

  const [isRunning, setIsRunning] = useState(false)

  const { uiStatusUpgradePlan: uiStatusAddCard } = useSelector(
    (state: StoreState) => state.storePlan,
  )

  // ~~~~~~ Computed

  const finalIsRunning = isRunning || uiStatusAddCard === 'running'

  // ~~~~~~ Handlers

  function onClickClose() {
    dispatch(StorePlanFieldsMR.reset.MC.reset())

    setDoClose(performance.now())
  }

  function onIsRunningChange(isRunning: boolean) {
    setIsRunning(isRunning)
  }

  function onApiCall() {
    setDoClose(performance.now())

    dispatch(ModalsMC.open(ModalsIds.LoggedStep2Buying))

    // Track

    BcastTrackEvents.calls.BuyProcess.Upgrade['3-add-card'](userContext)
  }

  function onClickAddCard() {
    setTriggerAddCard(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      modalSize={DialogBreakPoint.S}
      closeOnClickOut={false}
      dialogTitle="dialogs.add-payment-method.Title"
      dialogTitleAlign="left"
      closeDialog={doClose}
    >
      <Content>
        <Elements stripe={stripe}>
          <DialogAddCardForm
            triggerAddCard={triggerAddCard}
            onIsRunningChange={onIsRunningChange}
            onApiCall={onApiCall}
          />
        </Elements>
      </Content>

      <Actions>
        {/* Extra info under buttons */}

        <STExtraInfo>
          <DangerTrans
            id="common.NoticeStore"
            template="... <c>Store</c>"
            values={{
              c: (chunks: string) => (
                <a href={Config.Links.UpgradeBroadcastURL} target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
            }}
          />
        </STExtraInfo>

        {/* Close */}

        <Button
          intlId="common.Cancel"
          $colorType="secondary"
          disabled={false}
          $active={true}
          $running={false}
          onClick={onClickClose}
        />

        {/* Add card */}

        <Button
          intlId="dialogs.add-payment-method.actions.AddCard"
          $colorType="primary"
          $running={finalIsRunning}
          disabled={finalIsRunning}
          $active={!finalIsRunning}
          onClick={onClickAddCard}
        />
      </Actions>
    </Dialog>
  )
}
