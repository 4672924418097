import { StateObservable, ofType } from 'redux-observable'
import { Observable, map, mergeMap } from 'rxjs'

import { StoreState } from '../../../../../models/app/model'
import {
  ApiBcastStreamContextAC,
  ApiBcastStreamContextAT,
} from '../../../../actions/api-bcast/stream-context/actions'
import { apiBcastStreamContextGet$ } from '../../../../../services/api-bcast/stream-context/get'
import { EpicApiBcastStreamContextShowMC } from './mutators'

type Action = ReturnType<typeof ApiBcastStreamContextAC.show>

export const apiBcastStreamContextShowEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastStreamContextAT.SHOW),

    mergeMap(({ payload }) =>
      apiBcastStreamContextGet$(payload.serverSubMainData.server.broadcastId).pipe(
        map((res) =>
          EpicApiBcastStreamContextShowMC.ok(
            res.response.id,
            payload.serverSubMainData.server.broadcastId,
            payload.serverSubMainData,
          ),
        ),
      ),
    ),
  )
