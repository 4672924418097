import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { apiBcastSubscriptionShow$ } from '../../../../../services/api-bcast/subscriptions/get'
import {
  ApiBcastSubscriptionsAC,
  ApiBcastSubscriptionsAT,
} from '../../../../actions/api-bcast/subscription/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastSubscriptionShowMC } from './mutators'

type Action = ReturnType<typeof ApiBcastSubscriptionsAC.show>

export const apiBcastSubscriptionShowEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastSubscriptionsAT.SHOW),
    mergeMap(() => apiBcastSubscriptionShow$(state$.value.currentUser.token)),
    map((res) => {
      // Reload browser if we have error here
      // to prevent free users with premium account

      if (!ApiUtils.isSuccess(res)) {
        window.location.reload()
      }

      return ApiUtils.isSuccess(res)
        ? EpicApiBcastSubscriptionShowMC.ok(res.response)
        : EpicApiBcastSubscriptionShowMC.error(res.response)
    }),
  )
