import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable, tap } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'
import { ApiCheckout$$ } from '../subject/subject'

type Action = ReturnType<typeof ApiStoreCheckoutAC.cancel>

export const apiStoreCheckoutCancelEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.CANCEL),
    tap(() => {
      ApiCheckout$$.next()
    }),
    mergeMap(() => EMPTY),
  )
