import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { ModalsMC } from '../../../../store/actions-mutators/modals/mutators'
import { Button } from '../../../common/button/component'
import { Trans } from '../../../common/intl/trans'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { STDialogSubscriptionsFreeAccountWithoutTime } from './style'

// ~~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.FreeAccountWithoutTime)

// ~~~~~~~ Component

export const DialogSubscriptionsFreeAccountWithoutTime = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  // const { email: currentUserEmail } = useSelector((state: StoreState) => state.currentUser)

  // const subscription = useSelector((state: StoreState) => state.subscription)

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())
  }

  function onClickUpgrade() {
    dispatch(ModalsMC.open(ModalsIds.CreateAnAccountFreeOrBuy))

    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      modalSize={DialogBreakPoint.XS}
      closeOnClickOut={false}
      dialogTitle="dialogs.free-account-without-time.Title"
      closeDialog={doClose}
    >
      <Content>
        <STDialogSubscriptionsFreeAccountWithoutTime>
          <div className="line">
            <Trans id="dialogs.free-account-without-time.Content1" />
          </div>

          <div className="line">
            <Trans id="dialogs.free-account-without-time.Content2" />
          </div>
        </STDialogSubscriptionsFreeAccountWithoutTime>
      </Content>

      <Actions>
        {/* Close */}

        <Button
          intlId="common.Cancel"
          $colorType="secondary"
          disabled={false}
          $active={true}
          $running={false}
          onClick={onClickClose}
        />

        {/* Upgrade */}

        <Button
          intlId="dialogs.free-account-without-time.actions.Upgrade"
          $colorType="primary"
          disabled={false}
          $active={true}
          $running={false}
          onClick={onClickUpgrade}
        />
      </Actions>
    </Dialog>
  )
}
