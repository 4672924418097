import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fromEvent } from 'rxjs'
import { Config } from '../../config'
import { StatsMC } from '../../store/actions-mutators/stats/mutators'

export const useKeycodeToEnablePeerConnStats = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [keyCode, setKeyCode] = useState('')

  // ~~~~~~ Effects

  // Set key code

  useEffect(() => {
    const sub = fromEvent<KeyboardEvent>(window, 'keypress').subscribe({
      next: (evt) => {
        setKeyCode(`${keyCode}${evt.key}`)
      },
    })

    const timeout = window.setTimeout(() => {
      setKeyCode('')
    }, 750)

    return () => {
      clearTimeout(timeout)
      sub.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyCode])

  // Trigger enable
  useEffect(() => {
    if (keyCode !== Config.PCSCode) return

    setKeyCode('')

    dispatch(StatsMC.enable())
  }, [keyCode, dispatch])
}
