// !!!-used-by-extension

import { useSetRealSize } from '../../../hooks/misc/use-set-real-size'
import { LayoutTopContentContext } from './context/component'
import { STProps, STLayoutTopContent } from './style'

// ~~~~~~ Types

type Props = STProps & {
  'data-testid'?: string
  children: React.ReactNode
}

// ~~~~~~ Component

export const LayoutTopContent: React.FC<Props> = ({
  'data-testid': dataTestId = 'layout-top-content',
  $noTopBottomMarginContent: noTopBottomMarginContent,
  children,
}) => {
  // ~~~~~~ Hooks

  const { realHeight, bottomRef } = useSetRealSize()

  // ~~~~~~ Render

  return (
    <STLayoutTopContent
      data-testid={dataTestId}
      $noTopBottomMarginContent={noTopBottomMarginContent}
      style={{ height: realHeight }}
    >
      {/* Top */}

      <div className="layout-top-content-top">
        <div className="layout-top-content-top-content">
          {/* Left */}

          <div className="left">
            <LayoutTopContentContext.Provider value={{ kind: 'top-left' }}>
              {children}
            </LayoutTopContentContext.Provider>
          </div>

          {/* Right */}

          <div className="right">
            <LayoutTopContentContext.Provider value={{ kind: 'top-right' }}>
              {children}
            </LayoutTopContentContext.Provider>
          </div>

          {/* -- */}
        </div>
      </div>

      {/* Content */}

      <div className="layout-top-content-content">
        <LayoutTopContentContext.Provider value={{ kind: 'content' }}>
          {children}
        </LayoutTopContentContext.Provider>
      </div>

      {/* Bottom ref */}

      <div className="bottom-ref" ref={bottomRef} />
    </STLayoutTopContent>
  )
}
