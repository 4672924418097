import {
  fromEvent,
  merge,
  Observable,
  of,
  catchError,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
} from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'
import { ConstWebRtc } from '@dn/constants'
import { ServiceWebRtc } from '@dn/webrtc'
import { StoreState } from '../../../../models/app/model'
import { ShareMainStreamAC, ShareMainStreamAT } from '../../../actions/share-main-stream/actions'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import { EpicShareMainStreamGetStreamMC } from './mutators'
import { ShareMainStream$$ } from '../subject'
import { ExtensionService } from '../../../../services/extension'
import { cancelAll$$ } from '../../../../subjects/cancel-all/subject'
import { Config } from '../../../../config'
import { ServiceBcast } from '@dn/bcast'

type Action = ReturnType<typeof ShareMainStreamAC.getStream>

export const shareMainStreamGetStreamEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ShareMainStreamAT.GET_STREAM),

    mergeMap(() => {
      const subscription = state$.value.subscription

      const extraConstraints: MediaTrackConstraints = Config.Features.BcastService2
        ? {}
        : {
            // frameRate fields are of type ConstrainULong
            // ConstrainULong: The ConstrainULong constraint type is used to specify a constraint for a property whose value is an integer
            frameRate: {
              // An integer specifying an ideal value for the property
              // If possible, this value will be used, but if it's not possible,
              // the user agent will use the closest possible match
              ideal: subscription.fps,

              // An integer specifying a specific, required, value the property must have to be considered acceptable.
              // exact is equivalent to min == max
              // Currently not supported, see:
              // https://chromium.googlesource.com/chromium/src/+/refs/heads/main/third_party/blink/renderer/modules/mediastream/user_media_request.cc#391
              // exact: subscription.fps,

              // An integer specifying the largest permissible value of the property it describes
              // If the value cannot remain equal to or less than this value, matching will fail
              max: subscription.fps,

              // An integer specifying the smallest permissible value of the property it describes
              // If the value cannot remain equal to or greater than this value, matching will fail
              // min: ???
            },
          }

      return ServiceWebRtc.Media.Screen.getStream$(subscription.quality, extraConstraints).pipe(
        takeUntil(cancelAll$$),

        catchError((err: DN.Services.Webrtc.Error) => of(err)),
      )
    }),

    mergeMap((streamOrErr) => {
      if (ServiceWebRtc.Guards.isWebRTCError(streamOrErr)) {
        const errorCodes = streamOrErr.errorCodes

        const errors: Infos[] = []

        // Device permission

        errorCodes.includes('device-permision-info-err') &&
          errors.push({ id: 'get-screen.errors.NoDevicePermissions' })

        // No getDisplayMedia API

        errorCodes.includes('no-get-display-media-api') &&
          errors.push({ id: 'get-screen.errors.NoGetDisplayMedia' })

        // Permission denied (user cancel get screen) - skip (wildcard)

        errorCodes.includes('user-permission-denied') &&
          errors.push({ id: 'wildcard', values: { value: 'user-permission-denied' } })

        // Permission dismissed (system cancel get screen) - skip (wildcard)

        errorCodes.includes('system-permission-dismissed') &&
          errors.push({
            id: 'wildcard',
            values: { value: 'system-permission-dismissed' },
          })

        return of(EpicShareMainStreamGetStreamMC.error(errors))
      }

      const stream = streamOrErr

      const [streamVideoTrack] = stream && stream.getVideoTracks ? stream.getVideoTracks() : []

      if (!streamVideoTrack) {
        return of(CancelNowAC.cancelAll([CancelReasons.ScreenStreamHasNotAMediaTrack]))
      }

      // If stream track ended (maybe user click on 'stop sharing' button)
      // dispatch cancel all, that also sends a disconnect message

      return merge(
        fromEvent(streamVideoTrack, ConstWebRtc.Media.Track.Events.Ended).pipe(
          take(1),

          takeUntil(cancelAll$$),

          takeUntil(ShareMainStream$$.pipe(filter((msg) => msg.type === 'replace-track'))),

          map(() => CancelNowAC.cancelAll([CancelReasons.ScreenSharingEnded])),

          catchError(() => {
            return of(CancelNowAC.cancelAll([CancelReasons.ScreenStreamHasNotAMediaTrack]))
          }),
        ),

        of(EpicShareMainStreamGetStreamMC.ok(stream, streamVideoTrack)),

        of(ServiceBcast.React.Mutators.MC.addScreenStream(stream)),
      )
    }),

    tap((_action) => {
      ExtensionService.SendMessage.ToExtension.chooseSourceStateChanged('not-choosing')
    }),
  )
