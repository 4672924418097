import React from 'react'
import { useViewerMobileContext } from '../../hooks/use-viewer-mobile-context'

// ~~~~~~ Component

export const LayoutViewerMobileContent: React.FC<any> = ({ children }) => {
  const { kind } = useViewerMobileContext()

  return <>{kind === 'content' ? children : null}</>
}
