const prefix = 'mt-live-paint-draw-start-epic'

// ~~~~~~ Mutators Types

export const EpicLivePaintDrawStartMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicLivePaintDrawStartMC = {
  ok: (canvas: HTMLCanvasElement, scope: paper.PaperScope) => ({
    type: EpicLivePaintDrawStartMT.OK,
    payload: {
      canvas,
      scope,
    },
  }),
} as const
