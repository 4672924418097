import { TimeConst } from '../../../constants/time'

const initModel: DN.Models.Subscription.ModelFront = {
  uiStatus: 'init',
  errors: [],

  id: '',
  plan_type: 'free',
  fps: 0,
  quality: '720p',
  connections: 0,
  token: 'web-subscriber',
}

// ~~~~~~

const fromBackToFront = (
  model: DN.Models.Subscription.ModelBack,
): DN.Models.Subscription.ModelFront => {
  const { time_remaining, time_limit, free_premium_period_end } = model

  const finalTimeRemaining = !time_limit
    ? undefined
    : typeof time_remaining !== 'number'
      ? time_limit // This should not happen
      : time_remaining < 0
        ? 0
        : time_remaining

  const freePremiumDaysLeftInMs = free_premium_period_end
    ? new Date(free_premium_period_end).getTime() - Date.now()
    : undefined

  const daysLeft =
    freePremiumDaysLeftInMs && freePremiumDaysLeftInMs > -1
      ? Math.ceil(freePremiumDaysLeftInMs / TimeConst.SecondsToDays / 1000)
      : undefined

  return {
    ...initModel,
    ...model,
    time_remaining: finalTimeRemaining,
    daysLeft,
  }
}

// ~~~~~~

export const SubscriptionModel = {
  initModel,
  fromBackToFront,
}
