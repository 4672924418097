import styled from 'styled-components'
import { PenColor } from '../../../../../constants/pen-color'
import { Colors } from '../../../../../style/theme/colors'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Helpers

const getColor = (colorKey: ColorKey) => PenColor[colorKey].color

// ~~~~~~ Types

type ColorKey = DN.Models.PaintBcastStream.Draw.ColorKey

export type STProps = {
  $colorKey: ColorKey
}

// ~~~~~~ Component

export const STJoinedMobileToolbarsMenuBtn = styled.div<STProps>`
  border-radius: 50%;

  height: 56px;
  width: 56px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${theme(Colors.WOLF_00_90)};

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: none;
      stroke: ${theme(Colors.WOLF_100_05)};
    }
  }

  &.pen {
    svg {
      path {
        stroke: ${(props) => getColor(props.$colorKey)};
      }
    }
  }
`
