import { ofType, StateObservable } from 'redux-observable'
import { map, Observable } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { DrawService } from '../../../../../services/draw'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../actions/live-paint/draw/actions'
import { EpicLivePaintDrawStopMC } from './mutators'

type Action = ReturnType<typeof LivePaintDrawAC.stop>

export const livePaintDrawStopEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.STOP),
    map(() => {
      DrawService.stop()

      return EpicLivePaintDrawStopMC.ok()
    }),
  )
