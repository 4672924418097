import { AnimatePresence, motion } from 'framer-motion'
import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { Trans } from '../../../intl/trans'
import { useMenuLogic } from '../../hook'
import { ClassName, STUserMenuMobileSlide } from './style'

// ~~~~~~ Constants

const IconSize = 24

const Logo = LazySVG('logos/broadcast-square')

const CloseIcon = LazySVG('icons/close')

const UserIcon = LazySVG('icons/user')

const ManageAccountIcon = LazySVG('icons/gear')

const SupportIcon = LazySVG('icons/help')

const SettingsIcon = LazySVG('icons/settings')

const SignOutIcon = LazySVG('icons/sign-out-alt')

// ~~~~~~ Component

export const UserMenuMobileSlide = () => {
  // ~~~~~~ Hooks

  const {
    currentUserEmail,
    onSelection,
    signOutWithoutModal,
    locationIsSettings,
    showSideMenu,
    onHideSideMenu,
  } = useMenuLogic()

  // ~~~~~~ Render

  return (
    <STUserMenuMobileSlide>
      <AnimatePresence>
        {showSideMenu ? (
          <motion.div
            key="side-menu"
            className={`${ClassName}--motion`}
            transition={{
              type: 'tween',
            }}
            initial={{
              x: 420,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{
              x: 420,
              opacity: 0,
            }}
          >
            <div className={`${ClassName}--motion--content`}>
              {/* Header */}

              <div className={`${ClassName}--motion--content--header`}>
                {/* Logo */}

                <div className={`${ClassName}--motion--content--header--logo`}>
                  <Logo size={32} />
                </div>

                {/* Close */}

                <div
                  className={`${ClassName}--motion--content--header--close`}
                  onClick={onHideSideMenu}
                >
                  <CloseIcon size={24} />
                </div>
              </div>

              {/* Header info */}

              <div className={`${ClassName}--motion--content--header-info`}>
                {/* Title */}

                <div className={`${ClassName}--motion--content--header-info--title`}>
                  <div className={`${ClassName}--motion--content--header-info--title--icon`}>
                    <UserIcon size={16} />
                  </div>

                  <div className={`${ClassName}--motion--content--header-info--title--text`}>
                    <span>&nbsp;</span>
                    <span>
                      <Trans id="user-menu.LoggedInAs" />
                    </span>
                  </div>
                </div>

                {/* Email */}

                <div
                  data-testid="email"
                  className={`${ClassName}--motion--content--header-info--email`}
                >
                  {currentUserEmail}
                </div>
              </div>

              {/* Items */}

              <div className={`${ClassName}--motion--content--items`}>
                {/* Manage Account */}

                <div
                  data-testid="go-to-manage-account-act"
                  className={`${ClassName}--motion--content--items--item`}
                  onClick={() => onSelection('manage-account')}
                >
                  <div className={`${ClassName}--motion--content--items--item--icon`}>
                    <ManageAccountIcon size={IconSize} />
                  </div>

                  <Trans id="user-menu.ManageAccount" />
                </div>

                {/* Support */}

                <div
                  data-testid="go-to-support-act"
                  className={`${ClassName}--motion--content--items--item`}
                  onClick={() => onSelection('support')}
                >
                  <div className={`${ClassName}--motion--content--items--item--icon`}>
                    <SupportIcon size={IconSize} />
                  </div>

                  <Trans id="user-menu.Support" />
                </div>

                {/* Settings */}

                <div
                  data-testid="go-to-settings-act"
                  className={`${ClassName}--motion--content--items--item ${
                    locationIsSettings ? 'active' : ''
                  }`}
                  onClick={() => onSelection('settings')}
                >
                  <div className={`${ClassName}--motion--content--items--item--icon`}>
                    <SettingsIcon size={IconSize} />
                  </div>

                  <Trans id="user-menu.Settings" />
                </div>

                {/* Logout */}

                <div
                  data-testid="sign-out-act"
                  className={`${ClassName}--motion--content--items--item`}
                  onClick={() =>
                    onSelection(`sign-out-${signOutWithoutModal ? 'now' : 'stop-bcast'}`)
                  }
                >
                  <div className={`${ClassName}--motion--content--items--item--icon`}>
                    <SignOutIcon size={IconSize} />
                  </div>

                  <Trans id="user-menu.SignOut" />
                </div>
              </div>

              {/* - */}
            </div>
          </motion.div>
        ) : undefined}
      </AnimatePresence>
    </STUserMenuMobileSlide>
  )
}
