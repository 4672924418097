import { css, keyframes } from 'styled-components'

export const KFZoomIn = keyframes`
  from { transform: scale(1, 1) }
  to { transform: scale(1.1, 1.1) }
`

export const KFZoomOut = keyframes`
  from { transform: scale(1.1, 1.1) }
  to { transform: scale(1, 1) }
`

export const KFFadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`

export const KFFadeOut = keyframes`
  from { opacity: 1 }
  to { opacity: 0 }
`

export const KFSpinner = keyframes`
  to { transform: rotate(360deg) }
`

const Time = {
  Slow: '800ms',
  Slower: '1s',
  SemiFast: '500ms',
  Fast: '300ms',
  Faster: '200ms',
} as const

const Type = {
  Linear: 'linear',
  Ease: 'ease',
  EaseIn: 'ease-in',
  EaseOut: 'ease-out',
  EaseInOut: 'ease-in-out',
  Initial: 'initial',
  Inherit: 'inherit',
  SetpStart: 'step-start',
  SetpEnd: 'step-end',
  Steps: (int: number, at: 'start' | 'end') => `steps(${int}, ${at})`,
  CubicBezier: (n1: number, n2: number, n3: number, n4: number) =>
    `cubic-bezier(${n1}, ${n2}, ${n3}, ${n4})`,
} as const

const KFAnimation = {
  FastFadeIn: css`
    animation: ${KFFadeIn} ${Time.Fast};
  `,
  FastFadeOut: css`
    animation: ${KFFadeOut} ${Time.Fast};
  `,
  FasterZoomIn: css`
    animation: ${KFZoomIn} ${Time.Faster};
  `,
  FasterZoomOut: css`
    animation: ${KFZoomOut} ${Time.Faster};
  `,
} as const

const Transform = {
  Scale: (scaleX = 1.07, scaleY?: number) => css`
    transform: ${scaleY ? `scale(${scaleX}, ${scaleY})` : `scale(${scaleX})`};
  `,
}

export const Transition = {
  AllFastEaseOut: css`
    transition: ${Time.Fast} ${Type.EaseOut};
  `,
  Color: css`
    transition: color ${Time.Fast} ${Type.Ease};
  `,
  ColorFaster: css`
    transition: color ${Time.Faster} ${Type.Ease};
  `,

  BorderColor: css`
    transition: border-color ${Time.Fast} ${Type.Ease};
  `,
  BorderColorFaster: css`
    transition: border-color ${Time.Faster} ${Type.Ease};
  `,

  BackgroundColor: css`
    transition: background-color ${Time.Fast} ${Type.Ease};
  `,
  BackgroundColorFaster: css`
    transition: background-color ${Time.Faster} ${Type.Ease};
  `,

  ColorAndBackgroundFaster: css`
    transition:
      color ${Time.Faster} ${Type.Ease},
      background-color ${Time.Faster} ${Type.Ease};
  `,

  ColorBorderAndBackgroundFaster: css`
    transition:
      color ${Time.Faster} ${Type.Ease},
      background-color ${Time.Faster} ${Type.Ease},
      border-color ${Time.Faster} ${Type.Ease};
  `,

  FillFaster: css`
    transition: fill ${Time.Faster} ${Type.Ease};
  `,
  StrokeFaster: css`
    transition: stroke ${Time.Faster} ${Type.Ease};
  `,

  FillStrokeFaster: css`
    transition:
      fill ${Time.Faster} ${Type.Ease},
      stroke ${Time.Faster} ${Type.Ease};
  `,
}

const Animation = {
  spinner: css`
    animation: ${KFSpinner} 0.6s ${Type.Linear} infinite;
  `,
}

export const CSSAnimation = {
  Time,
  Type,
  KFAnimations: KFAnimation,
  Animation,
  Transform,
  Transition,
}
