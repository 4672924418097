import { ofType, StateObservable } from 'redux-observable'
import { map, mergeMap, Observable } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { apiBcastUserSettingsDelete$ } from '../../../../../services/api-bcast/user-settings/delete'
import {
  ApiBcastUserSettingsAC,
  ApiBcastUserSettingsAT,
} from '../../../../actions/api-bcast/user-settings/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastUserSettingsDeleteMC } from './mutators'

// ~~~~~~ Types

type Action = ReturnType<typeof ApiBcastUserSettingsAC.delete>

// ~~~~~~ Epic

export const apiBcastUserSettingsDeleteEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastUserSettingsAT.DELETE),

    mergeMap(({ payload }) =>
      apiBcastUserSettingsDelete$(state$.value.currentUser.token, payload.userId),
    ),

    map((res) => {
      return ApiUtils.isSuccess(res)
        ? EpicApiBcastUserSettingsDeleteMC.ok()
        : EpicApiBcastUserSettingsDeleteMC.error(res.response)
    }),
  )
