const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
)

interface IConfig {
  onRegister: (registration: ServiceWorkerRegistration) => void
  onReadyForUpdate: () => void
}

export function register(config: IConfig) {
  if (!navigator.serviceWorker) return

  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)

    // Prevent register the sw on www subdomain
    if (window.location.hostname.split('.')[0] === 'www') return

    if (publicUrl.origin !== window.location.origin) return

    // This event is commented because
    // app is created after window.onload
    // window.addEventListener('load', () => {

    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      checkValidServiceWorker(swUrl, config)

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      // navigator.serviceWorker.ready.then(() => {
      //   console.log('SW::Ready')
      // })
    } else {
      // Is not localhost. Just register service worker
      registerValidSW(swUrl, config)
    }

    // })
  }
}

function registerValidSW(swUrl: string, config: IConfig) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      config.onRegister(registration)

      // console.log('SW::Waiting', registration.waiting ? 'YES' : 'NO')

      registration.onupdatefound = () => {
        const installingWorker = registration.installing

        if (installingWorker == null) return

        installingWorker.onstatechange = () => {
          // console.log('SW::onstatechange', installingWorker.state)

          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // console.log('SW::installed', 'New version')

              // [!]
              config.onReadyForUpdate()
            } else {
              // console.log('SW::installed', 'Cached all')
            }
          }
        }
      }
    })
    .catch((error) => {
      console.error('SW:Error', 'Error during service worker registration:', error)
    })
}

function checkValidServiceWorker(swUrl: string, config: IConfig) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')

      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready
          .then((registration) => {
            registration
              .unregister()
              .then(() => {
                window.location.reload()
              })
              .catch((err) => {
                console.log('SW:Error', 'unregister call', err)
              })
          })
          .catch((err) => {
            console.log('SW:Error', 'ready', err)
          })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      // console.log('No internet connection found. App is running in offline mode')
    })
}

export function unregister() {
  return 'serviceWorker' in navigator
    ? navigator.serviceWorker.ready
        .then((registration) => registration.unregister())
        .catch((error) => {
          console.error('SW:Error', 'unregister fun', error.message)
        })
    : Promise.reject('No serviceWorker in navigator').catch((error) => {
        console.log('SW:Error', 'unregister fun', error)
      })
}
