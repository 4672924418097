import { STLayoutCup } from './style'
import { LayoutCupContext } from './context/component'
import { STProps } from './style'

// ~~~~~~ Types

type Props = STProps & {
  children: React.ReactNode
  forceThemeInTop?: Theme
  forceThemeInFooter?: Theme
}

// ~~~~~~ Component

export const LayoutCup: React.FC<Props> = ({
  children,
  $forceThemeInTop: forceThemeInTop,
  $forceThemeInFooter: forceThemeInFooter,
}) => {
  // ~~~~~~ Render

  return (
    <STLayoutCup $forceThemeInTop={forceThemeInTop} $forceThemeInFooter={forceThemeInFooter}>
      {/* Top */}

      <div className="layout-cup-top">
        <div className="layout-cup-top-content">
          {/* Banner */}

          <div className="layout-cup-top-main">
            <div className="layout-cup-top-main-content">
              {/* Left */}

              <div className="left">
                <LayoutCupContext.Provider value={{ kind: 'top-left' }}>
                  {children}
                </LayoutCupContext.Provider>
              </div>

              {/* Right */}

              <div className="right">
                <LayoutCupContext.Provider value={{ kind: 'top-right' }}>
                  {children}
                </LayoutCupContext.Provider>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Content */}

      <div className="layout-cup-content">
        <LayoutCupContext.Provider value={{ kind: 'content' }}>
          {children}
        </LayoutCupContext.Provider>
      </div>

      {/* Footer */}

      <div className="layout-cup-footer">
        <LayoutCupContext.Provider value={{ kind: 'footer' }}>{children}</LayoutCupContext.Provider>
      </div>
    </STLayoutCup>
  )
}
