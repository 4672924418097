import { LocalStorageBroadcastOptions } from '../../../services/storage/local/user-options'
import { UtilsStore } from '@dn/utils'

const bcastOptions = LocalStorageBroadcastOptions.get()

const initModel: DN.Models.UserSettings.ModelFront = {
  uiStatus: 'init',
  errors: [],

  theme: 'auto',
  lang: bcastOptions.lang,

  // num_of_bcast should be -1 as init value
  // because we check `prev + 1 === cur`
  // to display the survey
  // @see common/feedback-end-bcast/component.tsx
  num_of_bcast: -1,

  num_of_exit_surveys: 0,

  onboarding_create_a_bcast: false,
  onboarding_change_source: false,
  onboarding_share: false,
  onboarding_live_draw: false,
  onboarding_is_completed: false,

  onboardingPercent: 0,
  isOnboardingRunning: false,

  feedback_end_bcast_count: 0,
}

// ~~~~~~ Edit

const prefix = 'mt-user-settings-edit'

// ~~~~~~ Fields Mutators/Reducers

export const UserSettingsEditFieldsMR = {
  __model__: UtilsStore.genModelMR(prefix, initModel),

  lang: UtilsStore.genBasicFieldMR(prefix, initModel, 'lang', initModel.lang),

  theme: UtilsStore.genBasicFieldMR(prefix, initModel, 'theme', initModel.theme),

  isOnboardingRunning: UtilsStore.genBasicFieldMR(
    prefix,
    initModel,
    'isOnboardingRunning',
    initModel.isOnboardingRunning,
  ),
}

// ~~~~~~

const onboardingStepPercent = 25

const fromBackToFront = (
  model: DN.Models.UserSettings.ModelBack,
  opts: DN.Models.UserSettings.FromBackToFrontOpts,
): DN.Models.UserSettings.ModelFront => {
  let onboardingPercent = 0

  if (model.onboarding_create_a_bcast) {
    onboardingPercent += onboardingStepPercent
  }

  if (model.onboarding_change_source) {
    onboardingPercent += onboardingStepPercent
  }

  if (model.onboarding_share) {
    onboardingPercent += onboardingStepPercent
  }

  if (model.onboarding_live_draw) {
    onboardingPercent += onboardingStepPercent
  }

  return {
    ...initModel,

    ...model,

    onboardingPercent,
    isOnboardingRunning: opts.isOnboardingRunning,
  }
}
// ~~~~~~

export const UserSettingsModel = {
  initModel,
  fromBackToFront,
}
