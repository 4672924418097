import { AppInitState } from '../../../../models/app/model'
import { ImageToShareFieldsMR } from '../../../../models/bcast/image-to-share/model'
import { UtilsStore } from '@dn/utils'
import { EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT } from '../../../epics/api-bcast/google-classroom/announce-bcast-link/mutators'

const initState = AppInitState.imageToShare

type State = typeof initState

const reducer: any = {
  ...ImageToShareFieldsMR.__model__.Reducer,
  ...ImageToShareFieldsMR.file.Reducer,
  ...ImageToShareFieldsMR.name.Reducer,
  ...ImageToShareFieldsMR.reqUpdate.Reducer,
}

// ####################################################################################################
// ~~~~~~ Create Announcement - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT.OK] = (state: State): State => {
  return initState
}

// ~~~~~~

export const imageToShareReducer = UtilsStore.dynReducer(initState, reducer)
