import styled from 'styled-components'
import { Colors } from '../../../../../../style/theme/colors'
import { HexAlpha } from '../../../../../../style/theme/colors/alpha'
import { Layout } from '../../../../../../style/theme/layout'
import { getThemeValue, theme } from '../../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'cam-mic-mic-sound-mobile-is-off-bar'

// ~~~~~~ Component

export const STCamMicMicSoundMobileIsOffBar = styled.div`
  display: flex;
  justify-content: center;

  .${ClassName}--content {
    background-color: ${(props) => `${getThemeValue(props, Colors.WOLF_90_90)}${HexAlpha[90]}`};

    ${Layout.Border.Radius.A}

    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 8px 12px;

    .${ClassName}--icon {
      display: flex;
      align-items: center;

      margin-right: ${Layout.Spacing[2]}px;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: none;
          stroke: ${theme(Colors.WOLF_05_05)};
        }
      }
    }

    .${ClassName}--sound-state {
      color: ${theme(Colors.WOLF_05_05)};

      margin-right: ${Layout.Spacing[2]}px;
    }

    .${ClassName}--action {
      cursor: pointer;

      color: ${theme(Colors.BRONX_50_50)};

      border-bottom: dashed 1px ${theme(Colors.BRONX_50_50)};
    }
  }
`
