import styled from 'styled-components'
import { Transition } from '../../../../../../../../style/animations/css-animations'
import { theme } from '../../../../../../../../style/utils/theme'
import { CommonStyleSvgColors, STJoinedMobileToolbarsCapturePaintButtons } from '../../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-capture-paint-buttons-pens-pen'

// ~~~~~~ Component

export const STJoinedMobileToolbarsCapturePaintButtonsPensPen = styled.div`
  ${STJoinedMobileToolbarsCapturePaintButtons}

  svg.${ClassName}--icon {
    path {
      ${Transition.FillStrokeFaster}

      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }

    &.selected {
      path {
        stroke: ${theme(CommonStyleSvgColors.selected)};
      }
    }
  }
`
