import { BroadcastServiceConst } from './constants/broadcast-partial-service'
import { genPublisher$ } from './gen-publisher/broadcast-partial-service'
import { genSubscription$ } from './gen-subscription/broadcast-partial-service'
import { genUser } from './gen-user/broadcast-gen-user'
import { startPub$ } from './start-pub/broadcast-partial-service'
import { startSub$ } from './start-sub/broadcast-partial-service'
import { stop$ } from './stop/broadcast-partial-service'

export const BroadcastService = {
  stop$,
  genSubscription$,
  genPublisher$,
  startPub$,
  startSub$,
  genUser,
  eventNames: {
    publisher: BroadcastServiceConst.PublisherEventNames,
    subscriber: BroadcastServiceConst.SubscriberEventNames,
  },
} as const
