import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTheme } from 'styled-components'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { Trans } from '../../../common/intl/trans'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { ClassName, STDialogGoogleSignInContent } from './style'

// ~~~~~~ Constants

const ModalId = ModalsIds.GoogleSignIn

const Dialog = genAnimatedDialog(ModalId)

const GoogleWhiteButton = LazySVG('icons/google-white')
const GoogleBlueButton = LazySVG('icons/google-blue')

// ~~~~~~ Component

export const DialogGoogleSignIn: React.FC<{}> = () => {
  // ~~~~~~ Hooks

  const theme = (useTheme() as { mode: Theme }).mode

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const confirmModals = useSelector((state: StoreState) => state.modals.confirm)

  // ~~~~~~ Computed

  const GoogleButton = theme === 'light' ? GoogleWhiteButton : GoogleBlueButton

  // ~~~~~~ Handlers

  function onClickGoogleSignIn() {
    const onConfirm = confirmModals[ModalId]

    onConfirm && onConfirm()

    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      showCloseInStackedDialogWithoutTitle
      modalSize={DialogBreakPoint.XS}
      dialogTitle="wildcard"
      dialogTitleValues={{ value: ' ' }}
      closeDialog={doClose}
    >
      <Content>
        <STDialogGoogleSignInContent>
          {/* Title */}

          <div className={`${ClassName}--title`}>
            <Trans id="dialogs.google.sign-in.Title" />
          </div>

          <div className={`${ClassName}--line`}>
            <Trans id="dialogs.google.sign-in.Content1" />
          </div>

          <div className={`${ClassName}--line`}>
            <Trans id="dialogs.google.sign-in.Content2" />
          </div>
        </STDialogGoogleSignInContent>
      </Content>

      <Actions>
        {/* Sign in with Google */}

        <STDialogGoogleSignInContent>
          <div
            className={`${ClassName}--gaction`}
            style={{ display: 'flex', justifyContent: 'center', cursor: 'pointer' }}
            onClick={onClickGoogleSignIn}
          >
            <GoogleButton className={`${ClassName}--gaction`} size={43} />
          </div>
        </STDialogGoogleSignInContent>
      </Actions>
    </Dialog>
  )
}
