const initModel: DN.Models.Exit.Model = {
  uiStatus: 'init',
  errors: [],
  shouldExit: false,
  reason: 'init',
}

// ~~~~~~

export const ExitModel = {
  initModel,
}
