import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ExitMT } from '../../../actions-mutators/exit/mutators'
import { BroadcastAC, BroadcastAT } from '../../../actions/bcast/actions'
// import { EpicCancelNowAllMT, EpicCancelNowAllMC } from '../../../epics/cancel-now/all/mutators'

const initState = AppInitState.exit

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Exit
// ####################################################################################################

reducer[BroadcastAT.EXIT] = (
  state: State,
  { payload }: ReturnType<typeof BroadcastAC.exit>,
): State => {
  return {
    ...state,
    shouldExit: true,
    reason: payload.reason,
  }
}

// ####################################################################################################
// ~~~~~~ Reset Exit
// ####################################################################################################

reducer[ExitMT.RESET_EXIT] = (state: State): State => {
  return initState
}

// ~~~~~~

export const exitReducer = UtilsStore.dynReducer(initState, reducer)
