import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DialogExitEditingImage } from '../../components/dialogs/exit-editing-image/component'
import { LayoutPanels } from '../../components/layouts/panels/component'
import { LayoutPanelsContent } from '../../components/layouts/panels/parts/content/component'
import { LayoutPanelsHeader } from '../../components/layouts/panels/parts/header/component'
import { LayoutPanelsLpanel } from '../../components/layouts/panels/parts/lpanel/component'
import { DevicePIPEnabled } from '../../constants/misc'
import { ModalsIds } from '../../constants/modals'
import { Routes } from '../../constants/routes/routes'
import { useModelRootUpdateUserkind } from '../../hooks/model/root/use-model-root-update-userkind'
import { StoreState } from '../../models/app/model'
import { ModalsMC } from '../../store/actions-mutators/modals/mutators'
import { UtilsLog } from '../../utils/logs'
import { StartedDialogs } from './content/dialogs/component'
import { StartedContent } from './content/component'
import { StartedHeader } from './header/component'
import { StartedLeftPanel } from './left-panel/component'
import { BcastTrackEvents } from '../../services/track-events'

// ~~~~~~ Component

export const StartedPage = () => {
  // ~~~~~~ Hooks

  useModelRootUpdateUserkind('bcaster')

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ~~~~~~ State

  const publisher = useSelector((state: StoreState) => state.streamManagerPubMain.publisher)

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  const { shouldExit, reason: exitReason } = useSelector((state: StoreState) => state.exit)

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  const { id: curUserId } = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Effects

  // - Page event

  useEffect(() => {
    BcastTrackEvents.calls.Page.view('/started')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - Redirect to main if we don't have a publisher

  useEffect(() => {
    if (shouldExit || publisher || dnBcast.roomIsConnected) return

    UtilsLog.devLog(String.fromCodePoint(128256), '/started', 'should exit redirect to', '/main')

    navigate(curUserId ? redirectPath : Routes.Main, { replace: true })

    //
  }, [
    publisher,
    exitReason,
    shouldExit,
    navigate,
    redirectPath,
    curUserId,
    dnBcast.roomIsConnected,
  ])

  // - Show modal to exit with the reason

  useEffect(() => {
    if (!shouldExit) return

    // Maintain

    // ModalsIds.ConfirmStopBroadcasting
    // ModalsIds.ConfirmBroadcasterDisconectedByFail

    // Close

    dispatch(
      ModalsMC.closeSelection([
        // In (started-dialogs)

        ModalsIds.SafariChangeSource,
        ModalsIds.GoogleSignIn,
        ModalsIds.ConfirmStopCamMicStream,
        ModalsIds.ConfirmYourBrowswerIsTooOld,
        ModalsIds.ConfirmGetCamMicError,
        ModalsIds.ConfirmPredefMsgWillCloseBecauseNoViewers,

        // In this component

        ModalsIds.ExitEditingImage,
      ]),
    )

    try {
      DevicePIPEnabled && document.exitPictureInPicture().catch(() => undefined)
    } catch (err: any) {}

    UtilsLog.devLog('exit reason', exitReason)

    switch (exitReason) {
      case 'inactivity':
        dispatch(ModalsMC.open(ModalsIds.ConfirmPrefedMsgClosedByNoViewers))
        break

      case 'conn-closed':
      case 'publish-fail':
      case 'data-channel-error':
      case 'comm-channel-err':
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByConnFail))
        break

      case 'network-offline':
        dispatch(ModalsMC.open(ModalsIds.ConfirmBroadcasterDisconectedByFail))
        break

      case 'user-manual-exit':
      default:
        UtilsLog.devLog(
          String.fromCodePoint(128256),
          '/started',
          'manual exit redirect to',
          '/main',
        )
        navigate(redirectPath, { replace: true })
    }

    //
  }, [dispatch, exitReason, navigate, redirectPath, shouldExit])

  // ~~~~~~ Render

  return (
    <>
      {/* Dialogs */}

      <StartedDialogs />

      {/* - Dialog: Exit editing image */}

      <DialogExitEditingImage />

      {/* Layout */}

      <LayoutPanels>
        {/* Left Panel */}

        <LayoutPanelsLpanel>
          <StartedLeftPanel />
        </LayoutPanelsLpanel>

        {/* Header */}

        <LayoutPanelsHeader>
          <StartedHeader />
        </LayoutPanelsHeader>

        {/* Content - Shared Video and editing capture */}

        <LayoutPanelsContent>
          <StartedContent />
        </LayoutPanelsContent>

        {/* -- */}
      </LayoutPanels>
    </>
  )
}
