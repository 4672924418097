import { StateObservable, ofType } from 'redux-observable'
import { EMPTY, Observable, mergeMap, of } from 'rxjs'
import { StoreState } from '../../../../models/app/model'
import { EpicApiBcastRoomPubMC, EpicApiBcastRoomPubMT } from '../../api-bcast/room/pub/mutators'
import { ServiceBcast } from '@dn/bcast'
import { Config } from '../../../../config'
import { ServiceWebRtc } from '@dn/webrtc'
import { ConstWebRtc } from '@dn/constants'

type Action = ReturnType<typeof EpicApiBcastRoomPubMC.ok>

// NOTE: as in model dnBcast
// iAmBcaster is true, the lib will publish the screen stream (localScreenStream)

export const chainingfromRoomForPubToConnect$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiBcastRoomPubMT.OK),

    mergeMap(({ payload }) => {
      const { dnBcast, subscription } = state$.value

      if (!dnBcast.localScreenTrack) return EMPTY

      const wsUrl = `${Config.Bcast.Protocol}${payload.server}`

      const screenStreamConstraints = ConstWebRtc.Constraints.Screen[subscription.quality]

      const h264Bandwidth = ServiceWebRtc.Codecs.calcH264Bandwidth({
        h264Profile: 'high',
        width: screenStreamConstraints.video.width,
        height: screenStreamConstraints.video.height,
        frameRate: subscription.fps,
      })

      const action = ServiceBcast.React.Actions.AC.bcasterStart(wsUrl, payload.token, {
        simulcast: dnBcast.localScreenSimulcast || undefined,
        dynacast: dnBcast.localScreenDynacast || undefined,
        videoTrack: dnBcast.localScreenTrack,
        maxFrameRate: subscription.fps,
        h264Bandwidth,
      })

      return of(action)
    }),
  )
