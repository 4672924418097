import styled from 'styled-components'

import PenImage from '../../../../../imgs/png/icons/pen.png'
import MarkerImage from '../../../../../imgs/png/icons/marker.png'
import HighlighterImage from '../../../../../imgs/png/icons/highlighter.png'
import EraserImage from '../../../../../imgs/png/icons/eraser.png'

// ~~~~~~ Constants

export const ClassName = 'started-content-main-live-paint'

// ~~~~~~ Types

type STProps = {
  $activePen: DN.Models.LivePaint.Draw.Pen
  $isErasing: boolean
}

// ~~~~~~ Component

export const STStartedContentMainLivePaint = styled.div<STProps>`
  position: absolute;
  z-index: 10;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .${ClassName}--canvases-wrapper {
    position: relative;

    canvas {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }

    canvas.${ClassName}--canvases-wrapper--paint {
      z-index: 2;

      /* url offset-x offset-y (from left or top)  */
      cursor: ${({ $activePen, $isErasing }) =>
        $isErasing
          ? `url(${EraserImage}) 9 33, auto`
          : $activePen === 'pen'
            ? `url(${PenImage}) 0 22, auto`
            : $activePen === 'marker'
              ? `url(${MarkerImage}) 0 22, auto`
              : $activePen === 'highlighter'
                ? `url(${HighlighterImage}) 0 22, auto`
                : 'default'};
    }
  }
`
