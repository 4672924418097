import { StorageKeys } from '../../../constants/storage'
import { UtilsStorageSession } from '@dn/utils'

// ####################################################################################################
// ~~~~~~ get: Return the value of skip new version from session storage
// ####################################################################################################

const get = () => {
  return UtilsStorageSession.getItem(StorageKeys.SkipNewVersionMessage) === 'true'
}

// ####################################################################################################
// ~~~~~~ set: Save skip new version to session storage
// ####################################################################################################

const set = () => {
  UtilsStorageSession.setItem(StorageKeys.SkipNewVersionMessage, 'true')
}

// ####################################################################################################
// ~~~~~~ clear: Remove skip new version from session storage
// ####################################################################################################

const clear = () => {
  UtilsStorageSession.removeItem(StorageKeys.SkipNewVersionMessage)
}

export const SessionStorageNewVersion = {
  get,
  set,
  clear,
} as const
