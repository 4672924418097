const initModel: DN.Models.Stats.Model = {
  enabled: false,
  stats: [],
}

// ~~~~~~

export const StatsModel = {
  initModel,
}
