import { UtilsValidate as GenericUtilsValidate } from '@dn/utils'

// ~~~~~~ Constants

export const Validations: DN.Utils.Validations.ValidatorsMessages<IntlMsgId> = {
  Required: 'validations.required',
  MaxLength: 'validations.max-length',
  MinLength: 'validations.min-length',
  Min: 'validations.min',
  Max: 'validations.max',
  InvalidChars: 'validations.invalid-chars',
  Number: 'validations.number',
  MaxAmount: 'validations.max-amount',
  Email: 'validations.email',
} as const

// ~~~~~~ Helper

const validate = GenericUtilsValidate.genValidateFn(Validations)

// ~~~~~~

export const UtilsValidate = {
  Validations,
  validate,
}
