import { Button, Menu, MenuItem, Wrapper } from 'react-aria-menubutton'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { UserSettingsEditFieldsMR } from '../../../../models/bcast/user-settings/model'
import {
  Langs,
  LocalStorageBroadcastOptions,
} from '../../../../services/storage/local/user-options'
import { ApiBcastUserSettingsAC } from '../../../../store/actions/api-bcast/user-settings/actions'
import { Colors } from '../../../../style/theme/colors'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Trans } from '../../intl/trans'
import { Loader } from '../../loader/component'
import { UserSettingsTemplate } from '../0-template/component'
import { ClassName, STUserSettingsGeneral } from './style'

// ~~~~~~ Constants

const LanguageIcon = LazySVG('icons/mesh-world')

const ArrowDownIcon = LazySVG('icons/arrow-down')

// ~~~~~~ Component

export const UserSettingsGeneral = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const { lang: userLang, theme: userTheme } = useSelector(
    (state: StoreState) => state.userSettings,
  )

  const userSettingsEdit = useSelector((state: StoreState) => state.userSettingsEdit)

  const currentLang = useSelector((state: StoreState) => state.intl.lang)

  // ~~~~~~ Computed

  const userSettingsIsRunning = userSettingsEdit.uiStatus === 'running'

  const showThemeLoader = userSettingsIsRunning && userTheme !== userSettingsEdit.theme

  const showLangLoader = userSettingsIsRunning && userLang !== userSettingsEdit.lang

  // ~~~~~~ Handlers

  function onChangeTheme(theme: DN.Models.UserSettings.ModelFront['theme']) {
    if (showThemeLoader || theme === userTheme) return

    dispatch(UserSettingsEditFieldsMR.theme.MC.change(theme))

    dispatch(ApiBcastUserSettingsAC.update(currentUser.id, { theme }))
  }

  function onSelectLang(lang: DN.Models.UserSettings.ModelFront['lang']) {
    if (showLangLoader || lang === userLang) return

    dispatch(UserSettingsEditFieldsMR.lang.MC.change(lang))

    LocalStorageBroadcastOptions.update({ lang })

    dispatch(ApiBcastUserSettingsAC.update(currentUser.id, { lang }))
  }

  // ~~~~~~ Render

  return (
    <UserSettingsTemplate title="common-components.user-settings.sections.general.Title">
      <STUserSettingsGeneral>
        {/* Theme */}

        <div className={`${ClassName}--theme`}>
          {/* Title */}

          <div className={`${ClassName}--theme--title`}>
            <div className={`${ClassName}--theme--title--text`}>
              <Trans id="common-components.user-settings.sections.general.theme.Title" />
            </div>

            {showThemeLoader ? (
              <div>
                <Loader size={24} color={Colors.EMMET_50_40} />
              </div>
            ) : undefined}
          </div>

          {/* Selector */}

          <div className={`${ClassName}--theme--selector ${showThemeLoader ? 'running' : ''}`}>
            {/* Theme Ligth */}

            <div
              onClick={() => onChangeTheme('light')}
              className={`${ClassName}--theme--selector--light  ${
                userTheme === 'light' ? 'selected' : ''
              }`}
            >
              <div className={`${ClassName}--theme--selector--img`} />

              <div className={`${ClassName}--theme--selector--name`}>
                <Trans id="common-components.user-settings.sections.general.theme.Light" />
              </div>
            </div>

            {/* Theme Dark */}

            <div
              onClick={() => onChangeTheme('dark')}
              className={`${ClassName}--theme--selector--dark ${
                userTheme === 'dark' ? 'selected' : ''
              }`}
            >
              <div className={`${ClassName}--theme--selector--img`} />

              <div className={`${ClassName}--theme--selector--name`}>
                <Trans id="common-components.user-settings.sections.general.theme.Dark" />
              </div>
            </div>

            {/* Theme Auto */}

            <div
              onClick={() => onChangeTheme('auto')}
              className={`${ClassName}--theme--selector--auto ${
                userTheme === 'auto' ? 'selected' : ''
              }`}
            >
              <div className={`${ClassName}--theme--selector--img`} />

              <div className={`${ClassName}--theme--selector--name`}>
                <Trans id="common-components.user-settings.sections.general.theme.Auto" />
              </div>
            </div>

            {/* - */}
          </div>
        </div>

        {/* Separator */}

        <hr className={`${ClassName}`} />

        {/* Language */}

        <div className={`${ClassName}--lang`}>
          {/* Title */}

          <div className={`${ClassName}--lang--title`}>
            <div className={`${ClassName}--lang--title--text`}>
              <Trans id="common-components.user-settings.sections.general.language.Title" />
            </div>

            {showLangLoader ? (
              <div>
                <Loader size={24} color={Colors.EMMET_50_40} />
              </div>
            ) : undefined}
          </div>

          {/* Selector title (choose lang) */}

          <div className={`${ClassName}--lang--subtitle`}>
            <Trans id="common-components.user-settings.sections.general.language.Choose" />
          </div>

          {/* Selector */}

          <div className={`${ClassName}--lang--selector`}>
            <Wrapper onSelection={onSelectLang}>
              {/* Selected lang */}

              <Button
                className={`${ClassName}--lang--selector--action ${
                  showLangLoader ? 'running' : ''
                }`}
                tag="div"
                disabled={showLangLoader}
              >
                <div className={`${ClassName}--lang--selector--action--label-icon`}>
                  {/* Lang icon */}

                  <div className={`${ClassName}--lang--selector--action--label-icon--icon`}>
                    <LanguageIcon size={20} />
                  </div>

                  {/* Lang label */}

                  <div className={`${ClassName}--lang--selector--action--label-icon--label`}>
                    <Trans
                      id={`common-components.user-settings.sections.general.language.options.${userLang}`}
                    />

                    <span> </span>

                    {userLang === 'auto' ? (
                      <span>
                        <Trans
                          id={`common-components.user-settings.sections.general.language.options.${currentLang}`}
                        />
                      </span>
                    ) : undefined}
                  </div>
                </div>

                {/* Arrow icon */}

                <div className={`${ClassName}--lang--selector--action--icon`}>
                  <ArrowDownIcon size={16} />
                </div>
              </Button>

              {/* Menu with all Languages */}

              <div className={`${ClassName}--lang--selector--dropdown`}>
                <Menu>
                  <div className={`${ClassName}--lang--selector--dropdown--items`}>
                    {Langs.map((lang) => (
                      <MenuItem
                        key={lang}
                        className={`${ClassName}--lang--selector--dropdown--items--item ${
                          lang === userLang ? 'selected' : ''
                        }`}
                        value={lang}
                      >
                        {/* Language Icon */}

                        <div
                          className={`${ClassName}--lang--selector--dropdown--items--item--icon`}
                        >
                          <LanguageIcon size={20} />
                        </div>

                        {/* Language label */}

                        <div
                          className={`${ClassName}--lang--selector--dropdown--items--item--label`}
                        >
                          <Trans
                            id={`common-components.user-settings.sections.general.language.options.${lang}`}
                          />
                        </div>
                      </MenuItem>
                    ))}
                  </div>
                </Menu>
              </div>
            </Wrapper>
          </div>
        </div>
      </STUserSettingsGeneral>
    </UserSettingsTemplate>
  )
}
