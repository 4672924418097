const prefix = 'mt-broadcast-duration'

// ~~~~~~ Mutators Types

export const BroadcastDurationMT = {
  CLEAR: `${prefix}-clear`,
} as const

// ~~~~~~ Mutators Creators

export const BroadcastDurationMC = {
  clear: () => ({
    type: BroadcastDurationMT.CLEAR,
  }),
}
