import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'onboarding-panel-percent'

// ~~~~~~ Component

export const STOnboardingPanelPercent = styled.div`
  ${Fonts.Scale.Label}
  ${Fonts.Weight[700]}

  color: ${theme(Colors.EMMET_50_40)};

  display: flex;
  align-items: center;

  .${ClassName}--value {
    margin-right: ${Layout.Spacing[2]}px;
  }

  .${ClassName}--bar {
    position: relative;

    height: 8px;
    width: 100%;

    background-color: ${theme(Colors.WOLF_10_80)};

    border-radius: 40px;

    .${ClassName}--progress {
      position: absolute;

      height: 8px;

      background-color: ${theme(Colors.EMMET_50_40)};

      border-radius: 40px;
    }
  }
`
