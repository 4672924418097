import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { theme } from '../../../style/utils/theme'
import { Layout } from '../../../style/theme/layout'
import { MediaQuery } from '../../../style/constants/media-queries'
import { Transition } from '../../../style/animations/css-animations'

// ~~~~~~ Constants

export const ClassName = 'layout-panels'

export const LayoutPanelsSizes = {
  HeaderHeight: 72,
  LeftPanelMargin: 12,
  ContentPaddingBottom: 12,
  OpenerSize: 56,
  LeftPanelSize: 396,
  LeftPanelSizeMiddle: 352,
}

const {
  HeaderHeight,
  LeftPanelMargin,
  ContentPaddingBottom,
  OpenerSize,
  LeftPanelSize,
  LeftPanelSizeMiddle,
} = LayoutPanelsSizes

// ~~~~~~ Component

export const STLayoutPanels = styled.div`
  box-sizing: border-box;
  height: 100%;

  max-width: 100vw;
  max-height: ${({ theme }) => `${theme.pageHeight ? `${theme.pageHeight}px` : '100vh'}`};

  overflow: hidden;

  background-color: ${theme(Colors.WOLF_05_100)};

  display: flex;

  /* LPanel */

  /* - Normal */

  .${ClassName}--left-panel {
    min-width: ${LeftPanelSize + LeftPanelMargin * 2}px;

    display: flex;
    flex-direction: column;

    &.hidden {
      display: none;
    }

    .${ClassName}--left-panel--inner {
      overflow: hidden;

      margin: ${LeftPanelMargin}px;

      height: calc(100% - ${LeftPanelMargin * 2}px);

      ${Layout.Border.Radius.A}

      background-color: ${theme(Colors.WOLF_00_90)};
    }
  }

  /* - Minimal (closed) */

  .${ClassName}--left-panel-minimal {
    display: none;

    margin-right: ${LeftPanelMargin}px;
    margin-left: 0;

    height: calc(100% - ${LeftPanelMargin * 2}px);

    width: 2px;

    background-color: ${theme(Colors.WOLF_00_90)};

    &.show {
      display: block;
    }

    &.only-space {
      display: block;
      width: 0;
    }
  }

  /* Header + Content */

  .${ClassName}--middle {
    padding-left: ${Layout.Spacing[4] - LeftPanelMargin}px;
    padding-right: ${Layout.Spacing[4]}px;

    flex: 1;

    display: flex;
    flex-direction: column;

    width: auto;
    overflow: hidden;

    /* Header */

    .${ClassName}--middle--header {
      .${ClassName}--middle--header--inner {
        width: 100%;
        height: ${HeaderHeight}px;

        display: flex;
        align-items: center;
      }
    }

    /* Left panel opener (>) */

    .${ClassName}--middle--opener {
      display: none;

      position: absolute;
      top: ${HeaderHeight}px;
      left: 0;
      z-index: 11;

      &.show {
        display: block;
      }

      .${ClassName}--middle--opener--content {
        cursor: pointer;

        position: absolute;
        left: 0;

        height: ${OpenerSize}px;
        width: ${OpenerSize}px;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: ${theme(Colors.WOLF_00_90)};

        svg {
          width: 24px;
          height: 24px;

          path {
            ${Transition.StrokeFaster}

            stroke: ${theme(Colors.WOLF_100_05)};
          }
        }

        &:hover {
          svg path {
            stroke: ${theme(Colors.WOLF_90_10)};
          }
        }

        &:active {
          svg path {
            stroke: ${theme(Colors.WOLF_100_05)};
          }
        }
      }
    }

    /* Content */

    .${ClassName}--middle--content {
      padding-bottom: ${ContentPaddingBottom}px;
      flex: 1;

      .${ClassName}--middle--content--inner {
        width: 100%;
        height: 100%;

        max-height: ${({ theme }) =>
          theme.pageHeight
            ? `calc(${theme.pageHeight}px - ${HeaderHeight}px)`
            : `calc(100vh - ${HeaderHeight}px)`};
      }
    }
  }

  /* Reduced panel */

  @media (max-width: ${MediaQuery.MD}) {
    /* LPanel */

    .${ClassName}--left-panel {
      min-width: ${LeftPanelSizeMiddle + LeftPanelMargin * 2}px;
    }
  }

  @media (max-width: ${MediaQuery.FLOATING_PANELS}) {
    /* LPanel */

    .${ClassName}--left-panel {
      position: absolute;
      z-index: 100;
      top: 0;
      bottom: 0;
    }

    .${ClassName}--left-panel-minimal {
      background-color: transparent;

      display: block;

      &.only-space {
        width: 2px;
      }
    }
  }
`
