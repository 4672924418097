import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { RootModelFieldsMR } from '../../../models/bcast/root/model'

export const useModelRootUpdateUserkind = (kind: DN.Models.Root.Model['userKind']) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ Effects

  useEffect(() => {
    dispatch(RootModelFieldsMR.userKind.MC.change(kind))

    //
  }, [dispatch, kind])
}
