import { HooksData } from '@dn/hooks'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ModalsIds } from '../../../../../../constants/modals'
import { useValidator } from '../../../../../../hooks/validators/use-validators'
import { StoreState } from '../../../../../../models/app/model'
import { CurrentUserEditFieldsMR } from '../../../../../../models/bcast/current-user/model'
import {
  GoogleSlidesModel,
  GoogleSlidesCreateFieldsMR,
} from '../../../../../../models/bcast/google-presentations/model'
import { UiLayoutPanelsFieldsMR } from '../../../../../../models/bcast/ui-layout-panels/model'
import { ModalsMC } from '../../../../../../store/actions-mutators/modals/mutators'
import { ApiBcastGoogleSlidesAC } from '../../../../../../store/actions/api-bcast/google-slides/actions'
import { GoogleAuth } from '../../../../../../third-parties/google-api/library'
import { useTrackStreamContext } from '../../../../../../hooks/track-events/use-track-stream-context'
import { BcastTrackEvents } from '../../../../../../services/track-events'

// ~~~~~~ Constants

const NoProject: Project = {
  id: '',
  title: '',
}

// ~~~~~~ Types

type Project = {
  id: string
  title: string
}

// ~~~~~~ Hook

export const useJoinedBehaviourLogicLeftPanelSharePaintSlidesLogic = (
  recalculateScrollbarHeight: () => void,
) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const validateTitle = useValidator(
    GoogleSlidesModel.validations.Title,
    GoogleSlidesCreateFieldsMR.title,
  )

  const streamContext = useTrackStreamContext()

  // ~~~~~~ State

  // - Local

  const [shareView, setShareView] = useState<'create' | 'select'>('create')

  const [generatedTitle, setGeneratedTitle] = useState('')

  const [isDirty, setIsDirty] = useState(false)

  const [selectedProject, setSelectedProject] = useState(NoProject)

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const layoutPanels = useSelector((state: StoreState) => state.uiLayoutPanels)

  const slidesMetaList = useSelector((state: StoreState) => state.googleSlidesPresentationMetaList)

  const addImageToSlide = useSelector((state: StoreState) => state.googleSlidesAddImageToSlide)

  const { title, title_err } = useSelector(
    (state: StoreState) => state.googleSlidesCreatePresentation,
  )

  const imageToShare = useSelector((state: StoreState) => state.imageToShare)

  // ~~~~~~ Computed

  const shareIsOpen = layoutPanels.shareImageToSlidesState === 'open'

  const userCanShareImage = !!currentUser.googleAccessToken

  const slidesQuantity = slidesMetaList.list.length

  // const getListIsRunning = slidesMetaList.uiStatus === 'running'

  const shareImageIsRunning = addImageToSlide.uiStatus === 'running'

  const alreadyShared = !!currentUser.lastSelectedGoogleSlide.id

  const showEmptyList = slidesMetaList.list.length === 0 && slidesMetaList.uiStatus !== 'running'

  const lastSelectedGoogleSlideTitle = currentUser.lastSelectedGoogleSlide.title

  const titleChangedByUser = title !== generatedTitle

  const imageToShareName = imageToShare.name

  // ~~~~~~ State dependent Hooks

  const prevUserCanShareImage = HooksData.PrevValue.useHook(userCanShareImage)

  // ~~~~~~ Handlers

  function onChangeSlider() {
    if (!userCanShareImage) {
      dispatch(
        ModalsMC.open(ModalsIds.GoogleSignIn, () => {
          GoogleAuth.login({
            onSuccess: ({ access_token, expires_at }) => {
              // Update info

              dispatch(CurrentUserEditFieldsMR.googleAccessToken.MC.change(access_token))
              dispatch(CurrentUserEditFieldsMR.googleAccessTokenExpiresAt.MC.change(expires_at))

              // Open GSlides panel

              dispatch(UiLayoutPanelsFieldsMR.shareImageToSlidesState.MC.change('open'))

              // Track

              BcastTrackEvents.calls.Google.Viewer.connect(streamContext)
            },
          })
        }),
      )

      return
    }

    // Model

    dispatch(
      UiLayoutPanelsFieldsMR.shareImageToSlidesState.MC.change(shareIsOpen ? 'closed' : 'open'),
    )

    // Changed to close

    if (shareIsOpen) {
      setSelectedProject(NoProject)

      return
    }

    // Changed to open

    dispatch(
      ApiBcastGoogleSlidesAC.listPresentations(
        /* withGoogleAccessToken */ { withIt: true, accessToken: currentUser.googleAccessToken },
      ),
    )

    // Required to recalculate scrollbar height on change to "checked"
    // as the internal size of the card changes.
    // We need the space used by "Available slides"

    setTimeout(() => recalculateScrollbarHeight(), 50)
  }

  function onClickNavigate(value: 'create' | 'select') {
    setShareView(value)

    // Required to calculate scrollbar height

    setTimeout(() => recalculateScrollbarHeight(), 50)
  }

  // - For create slide and share

  function onChangeTitle(value: string) {
    isDirty && validateTitle(value)

    dispatch(GoogleSlidesCreateFieldsMR.title.MC.change(value))
  }

  function onShareImageCreatingSlide() {
    setIsDirty(true)

    // Validate

    const errors = [validateTitle(title).length].filter(Boolean)

    // Cancel submit

    if (errors.length) return

    // Clean dirty

    setIsDirty(false)

    // Submit

    dispatch(
      ApiBcastGoogleSlidesAC.createAndAddImageSlide(
        /* withGoogleAccessToken */ { withIt: true, accessToken: currentUser.googleAccessToken },
        { title },
        { image_content: imageToShare.file },
      ),
    )

    // Track

    BcastTrackEvents.calls.Google.Viewer.shareSlideImage(streamContext)
  }

  // - For select and share

  function onClickItem(project: Project) {
    if (shareImageIsRunning) return

    setSelectedProject(project)
  }

  function onShareImageInSelectedSlide() {
    const project = currentUser.lastSelectedGoogleSlide.id
      ? currentUser.lastSelectedGoogleSlide
      : selectedProject

    if (shareImageIsRunning || !project.id) return

    dispatch(
      ApiBcastGoogleSlidesAC.addImageSlide(
        /* withGoogleAccessToken */ { withIt: true, accessToken: currentUser.googleAccessToken },
        project,
        {
          image_content: imageToShare.file,
        },
      ),
    )

    // Track

    BcastTrackEvents.calls.Google.Viewer.shareSlideImage(streamContext)
  }

  // ~~~~~~ Effects

  // - On connect account, get slides list

  useEffect(() => {
    if (prevUserCanShareImage !== false || userCanShareImage !== true) return

    dispatch(
      ApiBcastGoogleSlidesAC.listPresentations(
        /* WithGoogleAccessToken */ { withIt: true, accessToken: currentUser.googleAccessToken },
      ),
    )

    //
  }, [currentUser.googleAccessToken, dispatch, prevUserCanShareImage, userCanShareImage])

  // - On mount, if account is connected, get slides list

  useEffect(() => {
    if (!userCanShareImage) return

    dispatch(
      ApiBcastGoogleSlidesAC.listPresentations({
        withIt: true,
        accessToken: currentUser.googleAccessToken,
      }),
    )

    // We don't want update value side effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - Set slide title from image to share name

  useEffect(() => {
    if (titleChangedByUser || alreadyShared) return

    dispatch(GoogleSlidesCreateFieldsMR.title.MC.change(imageToShareName))

    setGeneratedTitle(imageToShareName)

    //
  }, [alreadyShared, dispatch, imageToShareName, shareView, titleChangedByUser])

  // ~~~~~~

  return {
    shareView,
    generatedTitle,
    selectedProject,

    lastSelectedGoogleSlideTitle,
    // subscription,
    // layoutPanels,
    slidesMetaList,
    // addImageToSlide,
    title,
    title_err,
    // imageToShare,

    shareIsOpen,
    userCanShareImage,
    slidesQuantity,
    shareImageIsRunning,
    alreadyShared,
    showEmptyList,

    onChangeSlider,
    onClickNavigate,
    onChangeTitle,
    onShareImageCreatingSlide,
    onClickItem,
    onShareImageInSelectedSlide,
  } as const
}
