import { AppInitState } from '../../../../../models/app/model'
import { UiLayoutViewerMobileFieldsMR } from '../../../../../models/bcast/ui-layout-viewer-mobile/model'
import { UtilsStore } from '@dn/utils'
import { EpicCancelNowAllMT } from '../../../../epics/cancel-now/all/mutators'

const initState = AppInitState.uiLayoutViewerMobile

type State = typeof initState

const reducer: any = {
  ...UiLayoutViewerMobileFieldsMR.floatPanelOpenState.Reducer,
}

// ####################################################################################################
// ~~~~~~ Cancel all
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~

export const uiLayoutViewerMobileReducer = UtilsStore.dynReducer(initState, reducer)
