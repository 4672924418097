import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { fromEvent } from 'rxjs'
import { ConstEventsPIP } from '@dn/constants'
import { Trans } from '../../../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../../../components/svgs/lazy-svg/component'
import { DevicePIPEnabled } from '../../../../../../../../constants/misc'
import { StoreState } from '../../../../../../../../models/app/model'
import { UtilsFullscreen } from '@dn/utils'
import { ClassName, STStartedContentMainToolbarsMainButtonsPIP } from './style'
import { BcastTrackEvents } from '../../../../../../../../services/track-events'
import { useTrackStreamContext } from '../../../../../../../../hooks/track-events/use-track-stream-context'
import { useTrackUserContext } from '../../../../../../../../hooks/track-events/use-track-user-context'

// ~~~~~~ Constants

const PipIsOffIcon = LazySVG('icons/pip-inactive')
const PipIsOnIcon = LazySVG('icons/pip-active')

// ~~~~~~ Helpers

const isPipOn = () => !!document.pictureInPictureElement

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
  onUpdate: () => void
}

// ~~~~~~ Component

export const StartedContentMainToolbarsMainButtonsPIP: React.FC<Props> = ({ video, onUpdate }) => {
  // ~~~~~~ Hooks

  const userContext = useTrackUserContext()

  const streamContext = useTrackStreamContext()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const lang = useSelector((state: StoreState) => state.intl.lang)

  // - Refs

  const fullscreenRef = useRef<HTMLElement | null>(null)

  const tooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Specials

  fullscreenRef.current = document.getElementById('app')

  // ~~~~~~ Computed

  const tooltipTop = tooltipRef.current ? -tooltipRef.current.clientHeight - 8 : -34

  // ~~~~~~ Handlers

  function enterPIPMode() {
    if (!DevicePIPEnabled || !video || !video.requestPictureInPicture) return

    // First exit fullscreen

    UtilsFullscreen.exitFullScreenP().finally(() =>
      video.requestPictureInPicture().finally(() => {
        onUpdate()

        setTimeout(() => {
          setUpdate(performance.now())

          // Track

          BcastTrackEvents.calls.Pip.bcaster(userContext, streamContext)
        }, 10)
      }),
    )
  }

  function exitPIPMode() {
    if (!isPipOn()) return

    document
      .exitPictureInPicture()
      .catch(() => undefined)
      .finally(() => {
        onUpdate()

        setTimeout(() => {
          setUpdate(performance.now())
        }, 10)
      })
  }

  function onClick() {
    isPipOn() ? exitPIPMode() : enterPIPMode()
  }

  // ~~~~~~ Effects

  // - Force update on mount to calculate tooltip position

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // - Observe leave pip event (close button in external pip window)

  useEffect(() => {
    if (!video) return

    const sub = fromEvent(video, ConstEventsPIP.LeavePip).subscribe({
      next: () => {
        onUpdate()
        setUpdate(performance.now())
      },
    })

    return () => {
      sub.unsubscribe()
    }

    //
  }, [onUpdate, video])

  // ~~~~~~ Render

  return (
    <STStartedContentMainToolbarsMainButtonsPIP
      className={`with-tooltip ${lang === 'es' || isPipOn() ? 'small' : ''}`}
      onClick={onClick}
    >
      {/* Icon */}

      {isPipOn() ? (
        <PipIsOnIcon className={`${ClassName}--icon-on`} size={24} />
      ) : (
        <PipIsOffIcon className={`${ClassName}--icon-off`} size={24} />
      )}

      {/* Tooltip */}

      <span
        ref={tooltipRef}
        className="tooltiptext"
        style={{
          top: tooltipTop,
        }}
      >
        {isPipOn() ? (
          <Trans id="pages.started.main.toolbars.main.tooltips.pip.Exit" />
        ) : (
          <Trans id="pages.started.main.toolbars.main.tooltips.pip.Enter" />
        )}
      </span>
    </STStartedContentMainToolbarsMainButtonsPIP>
  )
}
