const prefix = 'mt-bcast-comm-channel-messages-to-server-baster-mic-disable'

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesToServerBcasterMicDisableMT = {
  OK: `${prefix}-ok`,
  TIMEOUT: `${prefix}-timeout`,
} as const

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesToServerBcasterMicDisableMC = {
  ok: () => ({
    type: EpicBcastCommChannelMessagesToServerBcasterMicDisableMT.OK,
  }),

  timeout: () => ({
    type: EpicBcastCommChannelMessagesToServerBcasterMicDisableMT.TIMEOUT,
  }),
} as const
