import { Config } from '../config'

// ####################################################################################################
// ~~~~~~ Logs
// ####################################################################################################

const devLog = (...args: any[]) => Config.Features.Debug && console.log('\n', ...args, '\n\n')

const remLog = (...args: any[]) => devLog('[RemLog]', ...args)

const devLogT = (...args: any[]): void => {
  if (!Config.Features.Debug) return

  console.groupCollapsed(...args)
  console.log(...args)
  console.trace()
  console.groupEnd()
}

const devInfo = (...args: any[]) => Config.Features.Debug && console.info(...args)

const devDebug = (...args: any[]) => Config.Features.Debug && console.debug(...args)

const devWarn = (...args: any[]) => Config.Features.Debug && console.warn(...args)

const devError = (...args: any[]) => Config.Features.Debug && console.error(...args)

export const UtilsLog = {
  devLog,
  remLog,
  devLogT,

  devInfo,
  devDebug,
  devWarn,
  devError,
} as const
