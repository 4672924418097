import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { BcastTrackEvents } from '../../services/track-events'
import { ApiBcastProvidersAC } from '../../store/actions/api-bcast/providers/actions'
import { useTrackStreamContext } from '../track-events/use-track-stream-context'
import { useTrackUserContext } from '../track-events/use-track-user-context'

// ~~~~~~ Hook

export const useBcasterGDisconnect = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  const streamContext = useTrackStreamContext()

  // ~~~~~~ Handlers

  const disconnect = useCallback(() => {
    dispatch(ApiBcastProvidersAC.unlinkGoogle())

    // Track

    BcastTrackEvents.calls.Google.Bcaster.disconnect(userContext, streamContext)

    //
  }, [dispatch, userContext, streamContext])

  // ~~~~~~

  return disconnect
}
