import styled from 'styled-components'
import { theme } from '../../../../../../../style/utils/theme'
import { CommonStyleSvgColors, STJoinedMobileToolbarsCapturePaintButtons } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-capture-paint-buttons-eraser'

// ~~~~~~ Component

export const STJoinedMobileToolbarsCapturePaintButtonsEraser = styled.div`
  ${STJoinedMobileToolbarsCapturePaintButtons}

  svg.${ClassName}--icon {
    path {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }

    &.selected {
      path {
        stroke: ${theme(CommonStyleSvgColors.selected)};
      }
    }
  }
`
