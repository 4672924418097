import { merge, Observable, of, zip, catchError, filter, map, mergeMap } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'
import { BroadcastAC, BroadcastAT } from '../../../actions/bcast/actions'
import { StoreState } from '../../../../models/app/model'
import { BroadcastService } from '../../../../services/broadcast'
import { EpicBcastStartPubMainMC } from './mutators'
import { UtilsLog } from '../../../../utils/logs'
import { BroadcastServiceConst } from '../../../../services/broadcast/constants/broadcast-partial-service'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import { ApiBcastSubscriptionsAC } from '../../../actions/api-bcast/subscription/actions'
import { CountdownBcastDurationAC } from '../../../actions/countdown/bcast-duration/actions'
import { Config } from '../../../../config'

type Action = ReturnType<typeof BroadcastAC.startPubMain>

export const bcastStartPubMainEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(BroadcastAT.START_PUB_MAIN),

    mergeMap(({ payload }) => {
      //

      const finalUserPassToken: DN.Services.Broadcast.UserPassToken = {
        ...payload.userPassToken,
        username: `${Config.App.red5proUsernamePrefix}-${payload.userPassToken.username}`,
      }

      return zip(
        of(payload.pubConfig.broadcastId),

        BroadcastService.genPublisher$(
          payload.pubConfig,
          payload.stream,
          finalUserPassToken,
          payload.iceServers,
        ),
      ).pipe(
        mergeMap(([broadcastId, { red5pub, close$, allEvents$ }]) => {
          const startPub$ = BroadcastService.startPub$(red5pub, broadcastId).pipe(
            map(() => EpicBcastStartPubMainMC.ok(red5pub)),

            catchError((err) => {
              UtilsLog.devLog('broadcastStartPubEpic$', '\n- ERROR\n', err)

              let errors: Infos[] | undefined = undefined

              let getTime = false

              switch (err) {
                case BroadcastServiceConst.ErrorCodes.PublishDisalllowed:
                  //

                  errors = [{ id: 'service.broadcast.pub.DisallowedError' }]

                  getTime = true

                  break

                case BroadcastServiceConst.ErrorCodes.PublishNotFound:
                  //

                  getTime = true

                  break
              }

              const actions = []

              errors?.length && actions.push(EpicBcastStartPubMainMC.error(errors))

              // The fail "DISALLOWED..." can be related with a bad synchronization between front and back.
              // It can be generated if a user open two tabs, stream in one, and then go to the other
              getTime && actions.push(ApiBcastSubscriptionsAC.getTime(state$.value.subscription.id))

              return of(...actions)
            }),
          )

          // All events

          const event$ = allEvents$.pipe(
            map((evt) =>
              EpicBcastStartPubMainMC.event({ type: evt.type, data: (evt as any).data }),
            ),
          )

          // Start Bcast countdown

          const { time_limit, time_remaining, id } = state$.value.subscription

          const hasTimeLimit = typeof time_limit === 'number'

          const timeRemaining = time_remaining || 0

          const bcastCountdown$ = allEvents$.pipe(
            filter((events) => events.type === 'Publish.Available' && hasTimeLimit),

            map(() => CountdownBcastDurationAC.start(id, timeRemaining)),
          )

          // Close event is received when:
          // - WebRTC.DataChannel.{Error|Close}
          // - Device is connected to a network that has been disconnected from internet
          // - User start a new broadcast while is broadcasting in another tab/browser
          // - Free account uses all its minutes

          const finalClose$ = close$.pipe(
            mergeMap((error) => {
              switch (error) {
                case 'closed':
                  return of(
                    BroadcastAC.exit('conn-closed'),
                    CancelNowAC.cancelAll([CancelReasons.ConnectionClosed]),
                  )

                case 'connection-failure':
                case 'publish-fail':
                  return of(
                    BroadcastAC.exit('publish-fail'),
                    CancelNowAC.cancelAll([CancelReasons.PublishFail]),
                  )

                case 'data-channel-error':
                  return of(
                    BroadcastAC.exit('data-channel-error'),
                    CancelNowAC.cancelAll([CancelReasons.DataChannelError]),
                  )
              }
            }),
          )

          return merge(startPub$, finalClose$, event$, bcastCountdown$)
        }),

        catchError(() => {
          return of(
            BroadcastAC.exit('conn-fail'),
            CancelNowAC.cancelAll([CancelReasons.ConnectionClosed]),
          )
        }),
      )
    }),
  )
