import { HooksBounds } from '@dn/hooks'
import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { ApiBcastUserSettingsAC } from '../../../../../store/actions/api-bcast/user-settings/actions'
import { MediaQueryNumbers } from '../../../../../style/constants/media-queries'
import { LayoutPanelsSizes } from '../../../../layouts/panels/style'
import { Button } from '../../../button/component'
import { Trans } from '../../../intl/trans'
import { CommonClassName } from '../common-style'
import { STOnboardingTooltipsShare } from './style'
import { BcastTrackEvents } from '../../../../../services/track-events'
import { useTrackUserContext } from '../../../../../hooks/track-events/use-track-user-context'

// ~~~~~~ Constants

// ~~~~~~ Component

export const OnboardingTooltipsShare = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const sizeData = HooksBounds.CheckPortrait.useHook()

  const userContext = useTrackUserContext()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const currentUserId = useSelector((state: StoreState) => state.currentUser.id)

  const settingsUiStatus = useSelector((state: StoreState) => state.userSettingsEdit.uiStatus)

  const bcastId = useSelector((state: StoreState) => state.streamManagerServerPubMain.broadcastId)

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Computed

  const self = selfRef.current

  const panelSize =
    (sizeData.width <= MediaQueryNumbers.MD
      ? LayoutPanelsSizes.LeftPanelSizeMiddle
      : LayoutPanelsSizes.LeftPanelSize) +
    LayoutPanelsSizes.LeftPanelMargin +
    16

  const settingsIsRunning = settingsUiStatus === 'running'

  // ~~~~~~ Handlers

  function onClickSkip() {
    dispatch(
      ApiBcastUserSettingsAC.update(currentUserId, {
        onboarding_share: true,
      }),
    )

    // Track

    BcastTrackEvents.calls.Onboarding['3-onboarding-share'](userContext)
  }

  // ~~~~~~ Effects

  // - Set positon

  useEffect(() => {
    if (!self) {
      return setUpdate(performance.now())
    }

    self.style.left = `${panelSize}px`

    //
  }, [panelSize, self])

  // ~~~~~~ Render

  return (
    <STOnboardingTooltipsShare
      ref={selfRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        top: 100,
        left: panelSize,
      }}
    >
      <div className={`${CommonClassName}--content`}>
        {/* Title */}

        <div className={`${CommonClassName}--content--title`}>
          <Trans id="common-components.onboarding.tooltips.share.Title" />
        </div>

        {/* Info */}

        {/* - Louver 1 */}

        <div className={`${CommonClassName}--content--text`}>
          <span>
            <Trans id="common-components.onboarding.tooltips.share.louver1.a" />
          </span>

          <span> </span>

          <a
            className={`${CommonClassName}--content--link`}
            href={`${Config.Origin}/join`}
            target="_blank"
            rel="noreferrer"
          >
            broadcast.online
          </a>

          <span> </span>

          <span>
            <Trans id="common-components.onboarding.tooltips.share.louver1.b" />
          </span>

          <span> </span>

          <span>
            <b>{bcastId}</b>
          </span>
        </div>

        {/* - Louver 2 */}

        <div className={`${CommonClassName}--content--text`}>
          <Trans id="common-components.onboarding.tooltips.share.Louver2" />
        </div>

        {/* - Louver 3 */}

        <div className={`${CommonClassName}--content--text`}>
          <Trans id="common-components.onboarding.tooltips.share.Louver3" />
        </div>

        {/* Skip */}

        <div className={`${CommonClassName}--content--skip`}>
          <Button
            intlId="common-components.onboarding.Skip"
            $colorType="onboarding"
            $size="xs"
            $fontWeight="500"
            $active={!settingsIsRunning}
            $running={settingsIsRunning}
            disabled={settingsIsRunning}
            onClick={onClickSkip}
          />
        </div>

        {/* Triangle */}

        <div className={`${CommonClassName}--left-triangle`} />

        {/* -- */}
      </div>
    </STOnboardingTooltipsShare>
  )
}
