const prefix = 'mt-share-main-stream-get-stream-epic'

// ~~~~~~ Mutators Types

export const EpicShareMainStreamGetStreamMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~ Mutators Creators

export const EpicShareMainStreamGetStreamMC = {
  ok: (stream: MediaStream, videoTrack: MediaStreamTrack) => ({
    type: EpicShareMainStreamGetStreamMT.OK,
    payload: {
      stream,
      videoTrack,
    },
  }),

  error: (errors: Infos[]) => ({
    error: true,
    type: EpicShareMainStreamGetStreamMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
