import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { StatsMC, StatsMT } from '../../../actions-mutators/stats/mutators'
import { CancelNowAT } from '../../../actions/cancel-now/actions'

const initState = AppInitState.stats

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Toggle
// ####################################################################################################

reducer[StatsMT.TOGGLE] = (state: State): State => {
  return {
    ...state,
    enabled: !state.enabled,
  }
}

// ####################################################################################################
// ~~~~~~ Enable
// ####################################################################################################

reducer[StatsMT.ENABLE] = (state: State): State => {
  return {
    ...state,
    enabled: true,
  }
}

// ####################################################################################################
// ~~~~~~ Add stats
// ####################################################################################################

reducer[StatsMT.ADD_STATS] = (
  state: State,
  { payload }: ReturnType<typeof StatsMC.addStats>,
): State => {
  return {
    ...state,
    stats: [...state.stats, payload].slice(-300),
  }
}

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ~~~~~~

export const statsReducer = UtilsStore.dynReducer(initState, reducer)
