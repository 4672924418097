import React from 'react'
import { DialogsSubscriptionsPlansForDialogsLayoutContext } from '../context/component'

// ~~~~~~ Hook

export const useDialogsSubscriptionsPlansForDialogsLayoutContext = () => {
  const context = React.useContext(DialogsSubscriptionsPlansForDialogsLayoutContext)

  if (!context) {
    throw new Error(
      `Layout plans-for-dialogs components cannot be rendered outside the DialogsPlansForDialogsLayout component`,
    )
  }

  return context
}
