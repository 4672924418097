import { Routes } from '../../../constants/routes/routes'
import { UtilsStore } from '@dn/utils'
import { Config } from '../../../config'

const initModel: DN.Models.Root.Model = {
  token: '',

  showSideMenu: false,
  connectionStatus: 'online',

  userHasInteracted: 0, // should end in "At"
  viewerCamHasStarted: 0, // should end in "At"

  prevPath: Routes.Main,

  redirectPath: Routes.Join,

  userKind: 'viewer',

  uiRoomVersion: 'init',
  uiRoomVersionErrors: [],
  bcastId: '',
  version: 'unknown',
}

const editPrefix = 'mt-root-edit'

export const RootModelFieldsMR = {
  prevPath: UtilsStore.genFieldMR(
    editPrefix,
    initModel,
    'prevPath',
    initModel.prevPath,
    Config.Intl.InfosForInfer,
  ),

  redirectPath: UtilsStore.genFieldMR(
    editPrefix,
    initModel,
    'redirectPath',
    initModel.redirectPath,
    Config.Intl.InfosForInfer,
  ),

  userKind: UtilsStore.genFieldMR(
    editPrefix,
    initModel,
    'userKind',
    initModel.userKind,
    Config.Intl.InfosForInfer,
  ),
}

// ~~~~~~

export const RootModel = {
  initModel,
}
