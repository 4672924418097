import styled from 'styled-components'
import { theme } from '../../../../../../../style/utils/theme'
import { CommonStyleSvgColors, STJoinedMobileToolbarsMainButtons } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-main-buttons-capture-paint'

// ~~~~~~ Component

export const STJoinedMobileToolbarsMainButtonsCapturePaint = styled.div`
  ${STJoinedMobileToolbarsMainButtons}

  svg.${ClassName}--icon {
    path {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }
  }
`
