import { of, Observable, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiStoreRoutes } from '../constants/routes'
import { StoreHeaderUtils } from '../utils/headers'

const { CreateSession } = ApiStoreRoutes.Checkout

// ####################################################################################################
// ~~~~~~ Create session
// ####################################################################################################

type Req = DN.Models.StoreCheckout.Api.CreateSession.Req
type Res = DN.Models.StoreCheckout.Api.CreateSession.Res
type ResParsed = DNApiResParsed<DN.Models.StoreCheckout.Api.CreateSession.ResParsed>
type ResError = DNApiError<DN.Models.StoreCheckout.Api.CreateSession.ResError>

export const apiStoreCheckoutCreateSession$ = (token: string, data: Req, headers?: AppHeaders) => {
  const finalHeaders = token
    ? StoreHeaderUtils.genHeadersWithAuth(token, headers)
    : StoreHeaderUtils.genHeaders(headers)

  return ajax<Res>({
    ...finalHeaders,
    method: CreateSession.Method,
    url: CreateSession.getUrl(),
    body: data,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        res.status === 412
          ? of({
              status: res.status,
              response: [{ id: 'api.checkout.create-session.Error412' }],
            })
          : of({
              status: res.status,
              response: [{ id: 'api.checkout.create-session.Error' }],
            }),
    ),
  )
}
