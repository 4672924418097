import { guardIsMessage } from '../../is-message/comm-channel-partial-service'

export const guardIsMessageFromServerWithRelay = (
  message: unknown,
): message is DN.Services.CommChannel.Messages.FromServer.FromServerWithRelay => {
  if (!guardIsMessage(message)) return false

  if (!('payload' in message) || typeof message.payload !== 'object') return false

  if (!('relayData' in message.payload) || typeof message.payload.relayData !== 'object') {
    return false
  }

  if (
    !('order' in message.payload.relayData) ||
    typeof message.payload.relayData.order === 'number'
  ) {
    return false
  }

  return true
}
