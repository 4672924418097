const prefix = 'mt-modals'

// ~~~~~~ Mutators Types

export const ModalsMT = {
  OPEN_MODAL: `${prefix}-open-modal`,
  CLOSE_MODAL: `${prefix}-close-modal`,
  CLOSE_MODAL_SELECTION: `${prefix}-close-modal-selection`,
} as const

// ~~~~~~ Mutators Creators

export const ModalsMC = {
  open: (
    modalId: string,
    fun?: DN.Models.Modals.ModalFun,
    values?: DN.Models.Modals.ModalValues,
  ) => ({
    type: ModalsMT.OPEN_MODAL,
    payload: {
      modalId,
      fun,
      values,
    },
  }),

  close: (modalId: string, action?: string) => ({
    type: ModalsMT.CLOSE_MODAL,
    payload: {
      modalId,
      action,
    },
  }),

  closeSelection: (modalIds: string[]) => ({
    type: ModalsMT.CLOSE_MODAL_SELECTION,
    payload: {
      modalIds,
    },
  }),
} as const
