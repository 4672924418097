import { AppInitState } from '../../../../../models/app/model'
import { PaintBcastStreamDrawModelFieldsMR } from '../../../../../models/bcast/paint-bcast-stream/model'
import { UtilsStore } from '@dn/utils'
import { CancelNowAT } from '../../../../actions/cancel-now/actions'
import {
  EpicPaintBcastStreamDrawCommandMC,
  EpicPaintBcastStreamDrawCommandMT,
} from '../../../../epics/paint-bcast-stream/draw/commands/mutators'
import {
  EpicPaintBcastStreamDrawStartMC,
  EpicPaintBcastStreamDrawStartMT,
} from '../../../../epics/paint-bcast-stream/draw/start/mutators'
import { EpicPaintBcastStreamDrawStopMT } from '../../../../epics/paint-bcast-stream/draw/stop/mutators'
import {
  EpicPaintBcastStreamDrawUpdatePropsMC,
  EpicPaintBcastStreamDrawUpdatePropsMT,
} from '../../../../epics/paint-bcast-stream/draw/update-props/mutators'

const initState = AppInitState.paintBcastStreamDraw

type State = typeof initState

const reducer: any = {
  ...PaintBcastStreamDrawModelFieldsMR.isDownloaded.Reducer,
}

// ####################################################################################################
// ~~~~~~ Start Ok
// ####################################################################################################

reducer[EpicPaintBcastStreamDrawStartMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicPaintBcastStreamDrawStartMC.ok>,
): State => {
  return {
    ...state,
    scope: payload.scope,
    canvas: payload.canvas,
  }
}

// ####################################################################################################
// ~~~~~~ Update props
// ####################################################################################################

reducer[EpicPaintBcastStreamDrawUpdatePropsMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicPaintBcastStreamDrawUpdatePropsMC.ok>,
): State => {
  return {
    ...state,
    ...payload,
  }
}

// ####################################################################################################
// ~~~~~~ Command Add Path
// ####################################################################################################

reducer[EpicPaintBcastStreamDrawCommandMT.ADD_PATH] = (state: State): State => {
  return {
    ...state,
    undos: state.undos + 1,
    redos: 0,
    lastCmd: 'addPath',
    isDirty: true,
    isDownloaded: false,
  }
}

// ####################################################################################################
// ~~~~~~ Command Rem Path
// ####################################################################################################

reducer[EpicPaintBcastStreamDrawCommandMT.REM_PATH] = (
  state: State,
  { payload }: ReturnType<typeof EpicPaintBcastStreamDrawCommandMC.remPath>,
): State => {
  return {
    ...state,
    undos: state.undos + 1,
    redos: 0,
    lastCmd: 'remPath',
    isDirty: payload.isDirty,
  }
}

// ####################################################################################################
// ~~~~~~ Commmand Clear
// ####################################################################################################

reducer[EpicPaintBcastStreamDrawCommandMT.CLEAR] = (state: State): State => {
  return {
    ...state,
    undos: state.undos + 1,
    redos: 0,
    lastCmd: 'clear',
    isDirty: false,
  }
}

// ####################################################################################################
// ~~~~~~ Command Undo
// ####################################################################################################

reducer[EpicPaintBcastStreamDrawCommandMT.UNDO] = (
  state: State,
  { payload }: ReturnType<typeof EpicPaintBcastStreamDrawCommandMC.undo>,
): State => {
  const newUndos = state.undos - 1

  const finalUndos = newUndos < 0 ? 0 : newUndos

  return {
    ...state,
    undos: finalUndos,
    redos: newUndos < 0 ? state.redos : state.redos + 1,
    lastCmd: payload.command,
    isDirty: payload.isDirty,
  }
}

// ####################################################################################################
// ~~~~~~ Command Redo
// ####################################################################################################

reducer[EpicPaintBcastStreamDrawCommandMT.REDO] = (
  state: State,
  { payload }: ReturnType<typeof EpicPaintBcastStreamDrawCommandMC.redo>,
): State => {
  const newRedos = state.redos - 1

  return {
    ...state,
    undos: state.undos + 1,
    redos: newRedos < 0 ? 0 : newRedos,
    lastCmd: payload.command,
    isDirty: payload.isDirty,
  }
}

// ####################################################################################################
// ~~~~~~ Stop Ok
// ####################################################################################################

reducer[EpicPaintBcastStreamDrawStopMT.OK] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ~~~~~~

export const paintBcastStreamDrawReducer = UtilsStore.dynReducer(initState, reducer)
