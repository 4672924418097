import React from 'react'
import { LayoutViewerMobileContext } from '../context/component'

// ~~~~~~ Hook

export const useViewerMobileContext = () => {
  const context = React.useContext(LayoutViewerMobileContext)

  if (!context) {
    throw new Error(
      `Layout ViewerMobile components cannot be rendered outside the LayoutCup component`,
    )
  }

  return context
}
