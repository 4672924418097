const prefix = 'at-countdown-free-premium-days'

// ~~~~~~ Action Types

export const CountdownFreePremiumDaysAT = {
  START: `${prefix}-start`,
} as const

// ~~~~~~ Action Creators

export const CowntdownFreePremiumDaysAC = {
  start: (endOfFreePremiumPeriod: string) => ({
    type: CountdownFreePremiumDaysAT.START,
    payload: {
      endOfFreePremiumPeriod,
    },
  }),
} as const
