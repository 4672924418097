import React from 'react'
import { useLayoutPanelsContext } from '../../hooks/use-layout-panels-context'

// ~~~~~~ Component

export const LayoutPanelsLpanel: React.FC<any> = ({ children }) => {
  const { kind } = useLayoutPanelsContext()

  return <>{kind === 'lpanel' ? children : null}</>
}
