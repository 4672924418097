import { UtilsStore } from '@dn/utils'

// ~~~~~~ Constants

const ErrorType: Infos = { id: 'wildcard' }

const initModel: DN.Models.Devices.Model = {
  selectedCam: undefined,

  selectedCamSize: {
    width: 0,
    height: 0,
  },

  selectedMic: undefined,

  selectedSpeakers: undefined,

  list: {
    mics: [],
    speakers: [],
    cams: [],
  },

  lastStream: '',

  streams: {},
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-devices-fields'

export const DevicesFieldsMR = {
  selectedCam: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'selectedCam',
    initModel.selectedCam,
    ErrorType,
  ),

  selectedCamSize: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'selectedCamSize',
    initModel.selectedCamSize,
    ErrorType,
  ),

  selectedMic: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'selectedMic',
    initModel.selectedMic,
    ErrorType,
  ),

  selectedSpeakers: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'selectedSpeakers',
    initModel.selectedSpeakers,
    ErrorType,
  ),

  list: UtilsStore.genFieldMR(prefix, initModel, 'list', initModel.list, ErrorType),
}

// ~~~~~~

export const DevicesModel = {
  initModel,
}
