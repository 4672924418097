import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromEvent, debounceTime } from 'rxjs'
import { ConstEventsWindow } from '@dn/constants'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { StorePlanFieldsMR } from '../../../../models/store/plan/model'
import { ModalsMC } from '../../../../store/actions-mutators/modals/mutators'
import { ApiStoreSubscriptionsAC } from '../../../../store/actions/api-store/subscriptions/actions'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Trans } from '../../../common/intl/trans'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { DialogsSubscriptionsPlansForDialogsPremium } from './premium/component'
import { DialogsSubscriptionsPlansForDialogsFree } from './free/component'
import { STDialogCreateAnAccountFreeOrBuyContent } from './style'

// ~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.CreateAnAccountFreeOrBuy)

const MediaQueryMD = parseInt(MediaQuery.MD)

// ~~~~~ Component

export const DialogCreateAnAccountFreeOrBuy = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClosee, setDoClose] = useState(0)

  const [dialogSize, setDialogSize] = useState(
    window.innerWidth <= MediaQueryMD ? DialogBreakPoint.S : DialogBreakPoint.SPECIAL_1,
  )

  const { uiStatusCheckPaymentMethod, uiStatusUpgradePlan, errors, card, priceId } = useSelector(
    (state: StoreState) => state.storePlan,
  )

  // ~~~~~~ Handlers

  function onSelectPlan() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Effects

  // - Show modal based on Check Paymen method

  useEffect(() => {
    if (uiStatusCheckPaymentMethod !== 'completed' || uiStatusUpgradePlan === 'running') return

    // No default card (tipical use path)

    if (errors.find((err) => err.id === 'api.subscriptions.check-payment-method.Error404')) {
      dispatch(StorePlanFieldsMR.errors.MC.clear())

      setDoClose(performance.now())

      dispatch(ModalsMC.open(ModalsIds.LoggedStep1AddPaymentMethod))

      return
    }

    // Default card

    if (card === undefined) return

    setDoClose(performance.now())

    dispatch(ModalsMC.open(ModalsIds.LoggedStep2Buying))

    if (errors.length) return

    dispatch(
      ApiStoreSubscriptionsAC.upgradePlan({
        price_id: priceId,
      }),
    )
  }, [dispatch, card, errors, uiStatusCheckPaymentMethod, priceId, uiStatusUpgradePlan])

  // - Check window size to update the dialog size

  useEffect(() => {
    const sub = fromEvent(window, ConstEventsWindow.Resize)
      .pipe(debounceTime(100))
      .subscribe({
        next: () => {
          requestAnimationFrame(() => {
            const newDialogSize =
              window.innerWidth <= MediaQueryMD ? DialogBreakPoint.S : DialogBreakPoint.SPECIAL_1

            dialogSize !== newDialogSize && setDialogSize(newDialogSize)
          })
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [dialogSize])

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={false}
      modalSize={dialogSize}
      closeOnClickOut={false}
      dialogTitle="dialogs.plans.Title"
      closeDialog={doClosee}
    >
      {/* Content */}
      <Content>
        <STDialogCreateAnAccountFreeOrBuyContent>
          {/* Subtitle */}
          <div className="plans-subtitle">
            <Trans id="dialogs.plans.Subtitle" />
          </div>

          <div className="all-plans">
            {/* Free plan */}

            <div className="plan free">
              <DialogsSubscriptionsPlansForDialogsFree onSelectPlan={onSelectPlan} />
            </div>

            {/* Premium plan */}

            <div className="plan premium">
              <DialogsSubscriptionsPlansForDialogsPremium onSelectPlan={onSelectPlan} />
            </div>
          </div>
        </STDialogCreateAnAccountFreeOrBuyContent>
      </Content>
    </Dialog>
  )
}
