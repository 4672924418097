import { AnimatePresence } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { fromEvent, take } from 'rxjs'
import { ConstEventsVideo } from '@dn/constants'
import { StoreState } from '../../../../../models/app/model'
import { UtilsImage } from '@dn/utils'
import { STMotionImgStartedContentMainPicOverVideo } from './styles'

// ~~~~~~ Types

type Props = {
  videoRef: React.RefObject<HTMLVideoElement>
  containerRef: React.RefObject<HTMLDivElement>
}

const defaultImg = '' // 'https://via.placeholder.com/300.png'

// ~~~~~~ Component

export const StartedContentMainPicOverVideo: React.FC<Props> = ({ videoRef, containerRef }) => {
  // ~~~~~~ State

  const [imgUrl, setImgUrl] = useState(defaultImg)

  const [size, setSize] = useState({
    width: 0,
    height: 0,
  })

  const { curScreenStream, getScreenToSwitchUiStatus, curScreenVideoTrack } = useSelector(
    (state: StoreState) => state.sharedMainStream,
  )

  const sceenStreamIdRef = useRef(curScreenStream?.id)

  // ~~~~~~ Effects

  // - Update video source on change stream

  useEffect(() => {
    const video = videoRef.current

    if (!video || !curScreenStream || sceenStreamIdRef.current === curScreenStream.id) {
      return
    }

    sceenStreamIdRef.current = curScreenStream.id

    const sub = fromEvent(video, ConstEventsVideo.Playing)
      .pipe(take(1))
      .subscribe({
        next: () => {
          setImgUrl(defaultImg)
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [sceenStreamIdRef, curScreenStream, videoRef])

  // - Pic-to-video between the switch

  useEffect(() => {
    const video = videoRef.current
    const videoContainer = containerRef.current

    if (!video || !videoContainer || imgUrl || getScreenToSwitchUiStatus !== 'running') {
      return
    }

    const imageData = UtilsImage.takePictureFromVideo(video, curScreenVideoTrack)

    if (!imageData) return

    setSize({
      width: videoContainer.clientWidth,
      height: videoContainer.clientHeight,
    })

    setImgUrl(imageData.data)

    //
  }, [curScreenVideoTrack, containerRef, getScreenToSwitchUiStatus, imgUrl, videoRef])

  // ~~~~~~ Render

  return (
    <>
      {/* As all elements have absolute position,
      we need this element to maintain the original bounds */}

      {imgUrl ? (
        <div
          style={{
            minWidth: size.width,
            minHeight: size.height,
          }}
        />
      ) : undefined}

      {/* Image with blur and scale animation */}

      <AnimatePresence>
        {imgUrl && (
          <STMotionImgStartedContentMainPicOverVideo
            key="switch-pic"
            transition={{ ease: 'linear' }}
            initial="hidden"
            animate="visible"
            variants={{
              hidden: {
                filter: 'blur(0)',
              },
              visible: {
                filter: 'blur(3px)',
                transition: {
                  duration: 1,
                },
              },
            }}
            exit={{ opacity: 0, scale: 0.1 }}
            src={imgUrl}
            alt="switching-video"
          />
        )}
      </AnimatePresence>
    </>
  )
}
