import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { STPaintToolbarButtonsExit } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/exit')

// ~~~~~~ Types

type Props = {
  onClick: () => void
}

// ~~~~~~ Component

export const PaintToolbarButtonsExit: React.FC<Props> = ({ onClick }) => {
  // ~~~~~~ Render

  return (
    <STPaintToolbarButtonsExit onClick={onClick}>
      <Icon size={24} />
    </STPaintToolbarButtonsExit>
  )
}
