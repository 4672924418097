import { Observable, of, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

const { Subscriptions } = ApiBcastRoutes

// ####################################################################################################
// ~~~~~~ Get time
// ####################################################################################################

type Res = DN.Models.Subscription.Api.GetTime.Res
type ResParsed = DNApiResParsed<DN.Models.Subscription.Api.GetTime.ResParsed>
type ResError = DNApiError<DN.Models.Subscription.Api.GetTime.ResError>

export const apiBcastSubscriptionsGetTime$ = (
  token: string,
  subscriptionId: string,
  subscriptionToken: string,
) => {
  const headers: AppHeaders = {
    'x-sub-token': subscriptionToken,
  }

  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersWithAuth(token, headers),
    method: Subscriptions.Time.Method,
    url: Subscriptions.Time.getUrl(subscriptionId),
  }).pipe(
    map((res): ResParsed => {
      const { remaining } = res.response

      const finalRemaining = remaining < 0 ? 0 : remaining

      return {
        status: res.status,
        response: {
          ...res.response,
          remaining: finalRemaining,
        },
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.subscriptions.time.Error' }],
      })
    }),
  )
}
