import { UnknownAction } from 'redux'
import { ofType, StateObservable } from 'redux-observable'
import { Observable, of, mergeMap, tap } from 'rxjs'
import { Config } from '../../../../../config'
import { ModalsIds } from '../../../../../constants/modals'
import { StoreState } from '../../../../../models/app/model'
import { ProviderModel } from '../../../../../models/bcast/providers/model'
import { apiBcastProvidersGoogleLink$ } from '../../../../../services/api-bcast/providers/google/link'
import { ExtensionService } from '../../../../../services/extension'
import { ModalsMC } from '../../../../actions-mutators/modals/mutators'
import {
  ApiBcastProvidersAC,
  ApiBcastProvidersAT,
} from '../../../../actions/api-bcast/providers/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastProvidersLinkGoogleMC } from './mutators'

type Action = ReturnType<typeof ApiBcastProvidersAC.linkGoogle>

export const apiBcastProvidersLinkGoogleEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastProvidersAT.LINK_GOOGLE),

    // Api call

    mergeMap(({ payload }) =>
      apiBcastProvidersGoogleLink$(
        state$.value.currentUser.token,
        state$.value.currentUser.id,
        payload.code,
      ),
    ),

    // Extension

    tap((res) => {
      if (!ApiUtils.isSuccess(res)) return

      !Config.isExtension && ExtensionService.SendMessage.ToExtension.updateInfo()
    }),

    // Mutator

    mergeMap((res) => {
      // Errors

      if (!ApiUtils.isSuccess(res)) {
        const errorActions: UnknownAction[] = [
          EpicApiBcastProvidersLinkGoogleMC.error(res.response),
        ]

        // Maybe show dialog if error comes from 'inssufient-permissions'

        if (
          res.response.some(
            (error) =>
              error.id ===
              ProviderModel.customErrors.authorizationCodeInsufficientPermissionsIntlId,
          )
        ) {
          errorActions.push(ModalsMC.open(ModalsIds.ConfirmShouldCheckAllGooglePermissionsToLink))
        }

        return of(...errorActions)
      }

      // Ok

      return of(EpicApiBcastProvidersLinkGoogleMC.ok(res.response))
    }),
  )
