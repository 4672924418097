const prefix = 'mt-bcast-start-pub-main-epic'

// ~~~~~~ Mutators Types

export const EpicBcastStartPubMainMT = {
  OK: `${prefix}-ok`,
  EVENT: `${prefix}-event`,
  ERROR: `${prefix}-error`,

  BANDWITH_STATE_INSUFFICIENT: `${prefix}-bandwidth-state-insufficient`,
  BANDWITH_STATE_RECOVERING: `${prefix}-bandwidth-state-recovering`,
  BANDWITH_STATE_SUFFICIENT: `${prefix}-bandwidth-state-sufficient`,
} as const

// ~~~~~~ Mutators Types

export const EpicBcastStartPubMainMC = {
  ok: (publisher: R5P.Publisher) => ({
    type: EpicBcastStartPubMainMT.OK,
    payload: {
      publisher,
    },
  }),

  event: ({ type, data }: { type: string; data?: any }) => ({
    type: EpicBcastStartPubMainMT.EVENT,
    payload: {
      type: type as DN.Models.StreamManagerPub.LastEvent,
      data,
    },
  }),

  error: (errors?: Infos[]) => ({
    type: EpicBcastStartPubMainMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),

  bandwidthStateInsufficient: () => ({
    type: EpicBcastStartPubMainMT.BANDWITH_STATE_INSUFFICIENT,
  }),

  bandwidthStateRecovering: () => ({
    type: EpicBcastStartPubMainMT.BANDWITH_STATE_RECOVERING,
  }),

  bandwidthStateSufficient: () => ({
    type: EpicBcastStartPubMainMT.BANDWITH_STATE_SUFFICIENT,
  }),
} as const
