import React from 'react'
import { useDialogsSubscriptionsPlansForDialogsLayoutContext } from '../../hooks/use-dialogs-subscriptions-plans-for-dialogs-layout-context'

// ~~~~~~ Component

export const DialogsSubscriptionsPlansForDialogsLayoutPartsAction: React.FC<any> = ({
  children,
}) => {
  const { kind } = useDialogsSubscriptionsPlansForDialogsLayoutContext()

  return <>{kind === 'action' ? children : null}</>
}
