import { CancelReasons } from '../../../actions/cancel-now/actions'

const prefix = 'mt-epic-cancel-now-all'

// ~~~~~~ Mutators Types

export const EpicCancelNowAllMT = {
  DONE: `${prefix}-done`,
}

// ~~~~~~ Mutators Creators

export const EpicCancelNowAllMC = {
  done: (reasons: CancelReasons[]) => ({
    type: EpicCancelNowAllMT.DONE,
    payload: reasons,
  }),
}
