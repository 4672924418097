import { AutoDownload } from '../../../../../../../components/common/auto-download/component'
import { PaintToolbar } from '../../../../../../../components/common/paint-toolbar/component'
import { useJoinedBehaviourLogicContentMainToolbarsCapturePaintLogic } from '../../../../../behaviour-logic/content/main/toolbars/capture-paint/logic-hook'
import { useJoinedDesktopContentMainToolbarsCapturePaintResizeAnimation } from './hooks/resize-animation'
import { ClassName, STJoinedDesktopContentMainToolbarsCapturePaint } from './style'

// ~~~~~~ Component

export const JoinedDesktopContentMainToolbarsCapturePaint = () => {
  // ~~~~~~ Hooks

  const resizeAnimation = useJoinedDesktopContentMainToolbarsCapturePaintResizeAnimation()

  const logic = useJoinedBehaviourLogicContentMainToolbarsCapturePaintLogic()

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainToolbarsCapturePaint>
      {/* Auto download image */}

      {!logic.draw.isDownloaded && logic.draw.shouldDownload && logic.draw.fileAndName ? (
        <AutoDownload
          onDownload={logic.draw.onDownload}
          file={logic.draw.fileAndName.file}
          name={logic.draw.fileAndName.name}
        />
      ) : undefined}

      {/* Content: paint controls */}

      <div className={`${ClassName}--content`}>
        <PaintToolbar
          showSharePaintSelector={false}
          actionKind="paint-bcast-stream"
          showExitButton={true}
          show={true}
          //
          rootVariants={resizeAnimation.rootVariants}
          btnVariants={resizeAnimation.picBtnVariants}
          colorsBtnVariants={resizeAnimation.colorsBtnVariants}
          pensBtnVariants={resizeAnimation.pensBtnVariants}
          showAllColors={resizeAnimation.showAllColors}
          showAllPens={resizeAnimation.showAllPens}
          //
          fromAnimation={
            logic.draw.showColorSelector
              ? resizeAnimation.animations.editPicture
              : logic.draw.showPenSelector
                ? resizeAnimation.animations.editPicture
                : resizeAnimation.animations.video
          }
          toAnimation={
            logic.draw.showColorSelector
              ? resizeAnimation.animations.colors
              : logic.draw.showPenSelector
                ? resizeAnimation.animations.pens
                : resizeAnimation.animations.editPicture
          }
          showOnlyColors={logic.draw.showColorSelector}
          showOnlyPens={logic.draw.showPenSelector}
          //
          onClickSelectColor={logic.draw.onClickSelectColor}
          onClickShowColorSelector={logic.draw.onClickShowColorSelector}
          onClickSelectPen={logic.draw.onClickSelectPen}
          onClickShowPenSelector={logic.draw.onClickShowPenSelector}
          onClickEraser={logic.draw.onClickEraser}
          onClickUndo={logic.draw.onClickUndo}
          onClickRedo={logic.draw.onClickRedo}
          onClickClear={logic.draw.onClickClear}
          onClickSave={logic.draw.onClickSave}
          //
          onClickSharePaint={logic.onClickSharePaint}
          onClickExit={logic.onClickExitEditingPic}
        />
      </div>
    </STJoinedDesktopContentMainToolbarsCapturePaint>
  )
}
