import styled from 'styled-components'
import { theme } from '../../../../../../../../../style/utils/theme'
import {
  CommonStyleSvgColors,
  STJoinedTabletContentMainToolbarsButtonsCommonStyle,
} from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-tablet-content-main-toolbars-main-buttons-fullscreen'

// ~~~~~~ Component

export const STJoinedTabletContentMainToolbarsMainButtonsFullscreen = styled.div`
  ${STJoinedTabletContentMainToolbarsButtonsCommonStyle}

  svg.${ClassName}--icon-off {
    path {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }
  }

  svg.${ClassName}--icon-on {
    path {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }
`
