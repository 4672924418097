import { LazySVG } from '../../../../../../../../components/svgs/lazy-svg/component'
import { ClassName, STJoinedMobileToolbarsCapturePaintColorsColor, STProps } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/color')

// ~~~~~~ Types

type Props = STProps & {
  selected: boolean

  onClick: () => void
}

// ~~~~~~ Component

export const JoinedMobileToolbarsCapturePaintColorsColor: React.FC<Props> = ({
  // Style props
  $colorKey: colorKey,

  selected,

  onClick,
}) => {
  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsCapturePaintColorsColor $colorKey={colorKey} onClick={onClick}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon ${selected ? 'selected' : ''}`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsCapturePaintColorsColor>
  )
}
