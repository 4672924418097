import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DialogBreakPoint } from '../../../constants/breakpoints'
import { ModalsIds } from '../../../constants/modals'
import { StoreState } from '../../../models/app/model'
import { Button } from '../../common/button/component'
import { Actions } from '../compound-dialog/actions/component'
import { Content } from '../compound-dialog/content/component'
import { genAnimatedDialog } from '../compound-dialog/dialog/component'
import { Trans } from '../../common/intl/trans'

import { ShareMainStreamAC } from '../../../store/actions/share-main-stream/actions'
import { STDialogSafariChangeSource } from './style'

// ~~~~~~ Constants

const ModalId = ModalsIds.SafariChangeSource

const Dialog = genAnimatedDialog(ModalId)

// ~~~~~~ Component

export const DialogSafariChangeSource = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const { publisher } = useSelector((state: StoreState) => state.streamManagerPubMain)

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())
  }

  function onClickChangeSource() {
    dispatch(ShareMainStreamAC.getStreamToSwitch())

    setDoClose(performance.now())
  }

  // ~~~~~~ Effects

  // - Close dialog if no publisher

  useEffect(() => {
    if (publisher) return

    setDoClose(performance.now())

    //
  }, [publisher])

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      modalSize={DialogBreakPoint.XS}
      dialogTitle="dialogs.safari-change-source.Title"
      closeDialog={doClose}
    >
      {/* Content */}

      <Content>
        <STDialogSafariChangeSource>
          <div className="line">
            <Trans id="dialogs.safari-change-source.Content1" />
          </div>

          <div className="line">
            <Trans id="dialogs.safari-change-source.Content2" />
          </div>
        </STDialogSafariChangeSource>
      </Content>

      {/* Actions */}

      <Actions>
        {/* Close */}

        <Button
          data-testid="close"
          intlId="common.Cancel"
          $colorType="secondary"
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickClose}
        />

        {/* Chage source */}

        <Button
          data-testid="save"
          intlId="dialogs.safari-change-source.ChangeSource"
          $colorType="primary"
          disabled={false}
          $running={false}
          $active={true}
          onClick={(evt) => {
            evt && evt.stopPropagation()

            onClickChangeSource()
          }}
        />
      </Actions>
    </Dialog>
  )
}
