import { AnimationProps, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { Trans } from '../intl/trans'
import { UtilsTooltip } from '@dn/utils'
import { PaintToolbarButtonsOnlyColors } from './only-colors/component'
import { PaintToolbarButtonsOnlyPens } from './only-pens/component'
import { ClassName, STPaintToolbar } from './style'
import { PaintToolbarButtonsClear } from './buttons/clear/component'
import { PaintToolbarButtonsDownload } from './buttons/download/component'
import { PaintToolbarButtonsExit } from './buttons/exit/component'
import { PaintToolbarButtonsRedo } from './buttons/redo/component'
import { PaintToolbarButtonsUndo } from './buttons/undo/component'
import { PaintToolbarButtonsSharePaint } from './buttons/share-paint/component'
import { PaintToolbarButtonsEraser } from './buttons/eraser/component'

// ~~~~~~ Types

type Props = {
  showExitButton: boolean

  showSharePaintSelector: boolean

  actionKind: 'paint-bcast-stream' | 'live-paint'

  show: boolean

  rootVariants: AnimationProps['variants']
  btnVariants: AnimationProps['variants']
  colorsBtnVariants: AnimationProps['variants']
  pensBtnVariants: AnimationProps['variants']

  fromAnimation: string
  toAnimation: string

  showAllColors: boolean
  showOnlyColors: boolean

  showAllPens: boolean
  showOnlyPens: boolean

  onClickSelectColor: (newColorKey: DN.Models.PaintBcastStream.Draw.ColorKey) => void
  onClickShowColorSelector: () => void

  onClickSelectPen: (newPen: DN.Models.PaintBcastStream.Draw.Pen) => void
  onClickShowPenSelector: () => void

  onClickEraser: () => void
  onClickUndo: () => void
  onClickRedo: () => void
  onClickClear: () => void
  onClickSharePaint: () => void
  onClickSave: () => void
  onClickExit: () => void
}

// ~~~~~~ Component

export const PaintToolbar: React.FC<Props> = ({
  showExitButton,
  actionKind,

  showSharePaintSelector,
  show,

  rootVariants,
  btnVariants,
  colorsBtnVariants,
  pensBtnVariants,

  fromAnimation,
  toAnimation,

  showAllColors,
  showOnlyColors,

  showAllPens,
  showOnlyPens,

  onClickSelectColor,
  onClickShowColorSelector,

  onClickSelectPen,
  onClickShowPenSelector,

  onClickEraser,
  onClickUndo,
  onClickRedo,
  onClickClear,
  onClickSharePaint,
  onClickSave,
  onClickExit,
}) => {
  // ~~~~~~ State

  // const { undos, redos } = useSelector((state: StoreState) => state.draw)

  // - Refs

  const eraserTooltipRef = useRef<HTMLSpanElement>(null)

  const undoTooltipRef = useRef<HTMLSpanElement>(null)

  const redoTooltipRef = useRef<HTMLSpanElement>(null)

  const binTooltipRef = useRef<HTMLSpanElement>(null)

  const downloadTooltipRef = useRef<HTMLSpanElement>(null)

  const exitTooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Handlers

  function onClickSharePaintH(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    evt.stopPropagation()

    onClickSharePaint()
  }

  // ~~~~~~ Effects

  // - Fix tooltip position

  useEffect(() => {
    // Undo

    if (undoTooltipRef.current) {
      undoTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(undoTooltipRef).left
    }

    // Redo

    if (redoTooltipRef.current) {
      redoTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(redoTooltipRef).left
    }

    // Bin

    if (binTooltipRef.current) {
      binTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(binTooltipRef).left
    }

    // Download

    if (downloadTooltipRef.current) {
      downloadTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(downloadTooltipRef).left
    }

    // Exit

    if (exitTooltipRef.current) {
      exitTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(exitTooltipRef).left
    }
  })

  // ~~~~~~ Render

  if (!show) return null

  // - Render only colors

  if (showOnlyColors) {
    return (
      <STPaintToolbar>
        <motion.div
          className={`${ClassName}--only-colors`}
          initial={fromAnimation}
          animate={toAnimation}
          variants={rootVariants}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div className={`${ClassName}--animated-btn`}>
            <PaintToolbarButtonsOnlyColors
              actionKind={actionKind}
              variants={colorsBtnVariants}
              showAll={true}
              isOnlyColors={true}
              onClickSelectColor={onClickSelectColor}
              onClickShowColorSelector={onClickShowColorSelector}
              onClickSelectPen={onClickSelectPen}
            />
          </div>
        </motion.div>
      </STPaintToolbar>
    )
  }

  // - Render only Pens

  if (showOnlyPens) {
    return (
      <STPaintToolbar>
        <motion.div
          className={`${ClassName}--only-pens`}
          initial={fromAnimation}
          animate={toAnimation}
          variants={rootVariants}
          style={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <div className={`${ClassName}--animated-btn`}>
            <PaintToolbarButtonsOnlyPens
              actionKind={actionKind}
              variants={pensBtnVariants}
              showAll={true}
              onClickSelectPen={onClickSelectPen}
              onClickShowPenSelector={onClickShowPenSelector}
            />
          </div>
        </motion.div>
      </STPaintToolbar>
    )
  }

  // - Render tools

  return (
    <STPaintToolbar>
      <motion.div
        className={`${ClassName}--all-tools`}
        initial={fromAnimation}
        animate={toAnimation}
        variants={rootVariants}
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {/* Pens */}

        <motion.div className={`${ClassName}--animated-btn`} variants={btnVariants}>
          <PaintToolbarButtonsOnlyPens
            actionKind={actionKind}
            variants={{}}
            showAll={showAllPens}
            onClickSelectPen={onClickSelectPen}
            onClickShowPenSelector={onClickShowPenSelector}
          />
        </motion.div>

        {/* Eraser */}

        <motion.div
          style={{ position: 'relative' }}
          className={`${ClassName}--animated-btn with-tooltip`}
          variants={btnVariants}
        >
          {/* Icon */}

          <PaintToolbarButtonsEraser actionKind={actionKind} onClick={onClickEraser} />

          {/* Tooltip */}

          <span
            ref={eraserTooltipRef}
            className="tooltiptext"
            style={UtilsTooltip.getTooltipStyle(eraserTooltipRef)}
          >
            <Trans id="tooltip.EditPic.Eraser" />
          </span>
        </motion.div>

        {/* Colors */}

        <motion.div className={`${ClassName}--animated-btn`} variants={btnVariants}>
          <PaintToolbarButtonsOnlyColors
            actionKind={actionKind}
            isOnlyColors={false}
            variants={{}}
            showAll={showAllColors}
            onClickSelectColor={onClickSelectColor}
            onClickShowColorSelector={onClickShowColorSelector}
            onClickSelectPen={onClickSelectPen}
          />
        </motion.div>

        {/* Undo */}

        <motion.div
          className={`${ClassName}--animated-btn with-tooltip`}
          style={{ position: 'relative' }}
          variants={btnVariants}
        >
          {/* Icon */}

          <PaintToolbarButtonsUndo onClick={onClickUndo} $disabled={false} />

          {/* Tooltip */}

          <span
            ref={undoTooltipRef}
            className="tooltiptext"
            style={UtilsTooltip.getTooltipStyle(undoTooltipRef)}
          >
            <Trans id="tooltip.EditPic.Undo" />
          </span>
        </motion.div>

        {/* Redo */}

        <motion.div
          className={`${ClassName}--animated-btn with-tooltip`}
          style={{ position: 'relative' }}
          variants={btnVariants}
        >
          {/* Icon */}

          <PaintToolbarButtonsRedo onClick={onClickRedo} $disabled={false} />

          {/* Tooltip */}

          <span
            ref={redoTooltipRef}
            className="tooltiptext"
            style={UtilsTooltip.getTooltipStyle(redoTooltipRef)}
          >
            <Trans id="tooltip.EditPic.Redo" />
          </span>
        </motion.div>

        {/* Bin (Clear drawings) */}

        <motion.div
          className={`${ClassName}--animated-btn with-tooltip`}
          style={{ position: 'relative' }}
          variants={btnVariants}
        >
          {/* Icon */}

          <PaintToolbarButtonsClear onClick={onClickClear} $disabled={false} />

          {/* Tooltip */}

          <span
            ref={binTooltipRef}
            className="tooltiptext"
            style={UtilsTooltip.getTooltipStyle(binTooltipRef)}
          >
            <Trans id="tooltip.EditPic.Bin" />
          </span>
        </motion.div>

        {/* Share Paint */}

        <motion.div
          className={`${ClassName}--animated-btn with-tooltip`}
          style={{ position: 'relative' }}
          variants={btnVariants}
        >
          {/* Icon */}

          <PaintToolbarButtonsSharePaint
            onClick={(evt: any) => onClickSharePaintH(evt)}
            $disabled={false}
          />

          {/* Tooltip */}

          {showSharePaintSelector ? undefined : (
            <span
              ref={binTooltipRef}
              className="tooltiptext"
              style={UtilsTooltip.getTooltipStyle(binTooltipRef)}
            >
              <Trans id="tooltip.EditPic.Share" />
            </span>
          )}
        </motion.div>

        {/* Download */}

        <motion.div
          className={`${ClassName}--animated-btn with-tooltip`}
          style={{ position: 'relative' }}
          variants={btnVariants}
        >
          {/* Icon */}

          <PaintToolbarButtonsDownload onClick={onClickSave} />

          {/* Tooltip */}

          <span
            ref={downloadTooltipRef}
            className="tooltiptext"
            style={UtilsTooltip.getTooltipStyle(downloadTooltipRef)}
          >
            <Trans id="tooltip.EditPic.Download" />
          </span>
        </motion.div>

        {/* Exit */}

        {showExitButton ? (
          <motion.div
            className={`${ClassName}--animated-btn exit with-tooltip`}
            style={{ position: 'relative' }}
            variants={btnVariants}
          >
            {/* Icon */}

            <PaintToolbarButtonsExit onClick={onClickExit} />

            {/* Tooltip */}

            <span
              ref={exitTooltipRef}
              className="tooltiptext"
              style={UtilsTooltip.getTooltipStyle(exitTooltipRef)}
            >
              <Trans id="tooltip.EditPic.Exit" />
            </span>
          </motion.div>
        ) : undefined}
      </motion.div>
    </STPaintToolbar>
  )
}
