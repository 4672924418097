import { merge, Observable, of, zip, catchError, filter, map, mergeMap, takeUntil } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'

import { StoreState } from '../../../../models/app/model'
import { BroadcastAC, BroadcastAT } from '../../../actions/bcast/actions'

import { BroadcastService } from '../../../../services/broadcast'
import { EpicBcastStartSubCamMicMC } from './mutators'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import { cancelCamMic$$ } from '../../cancel-now/cam-mic/subject'
import { Config } from '../../../../config'

type Action = ReturnType<typeof BroadcastAC.startSubCamMic>

const connectionFail$ = of(CancelNowAC.cancelCamMic([CancelReasons.ConnectionClosed]))

export const bcastStartSubCamMicEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(BroadcastAT.START_SUB_CAM_MIC),

    mergeMap(({ payload }) => {
      const broadcastId = payload.subConfig.broadcastId

      const username = state$.value.streamManagerSubMain.username

      const finalUsername = `${Config.App.red5proUsernamePrefix}-${username}`

      const subscriptionId = `${finalUsername}-cam`

      return zip(
        of(broadcastId),

        BroadcastService.genSubscription$(
          finalUsername,
          subscriptionId,
          payload.subConfig,
          payload.iceServers,
        ),
      ).pipe(
        mergeMap(([broadcastId, { red5Sub, events$, allEvents$ }]) => {
          const startSub$ = BroadcastService.startSub$(red5Sub, broadcastId).pipe(
            mergeMap(([red5Sub, closedByConnFail$]) => {
              //

              const specialClose$ = closedByConnFail$.pipe(
                mergeMap(() => of(CancelNowAC.cancelCamMic([CancelReasons.ConnectionClosed]))),
              )

              return merge(
                of(EpicBcastStartSubCamMicMC.ok(red5Sub, broadcastId, username)),
                specialClose$,
              )
            }),

            catchError(() => connectionFail$),
          )

          // All events

          const event$ = allEvents$.pipe(
            map((evt) =>
              EpicBcastStartSubCamMicMC.event({
                type: evt.type,
                data: (evt as any).data,
              }),
            ),
          )

          // Close

          const close$ = events$.pipe(
            filter((evt) => evt === 'closed'),
            filter(() => !state$.value.streamManagerSubCamMic.paused),

            map(() => CancelNowAC.cancelCamMic([CancelReasons.SessionEnded])),
          )

          // Fails

          const fail$ = events$.pipe(
            filter((evt) => evt !== 'closed'),
            mergeMap((evt) => {
              switch (evt) {
                case 'user-limit':
                  return of(
                    EpicBcastStartSubCamMicMC.error([
                      { id: 'service.broadcast.sub.DisallowedError' },
                    ]),
                    CancelNowAC.cancelCamMic([CancelReasons.UserLimitReached]),
                  )

                case 'invalid-name':
                  return of(CancelNowAC.cancelCamMic([CancelReasons.InvalidName]))

                // case 'websocket-fail':
                //   return of(
                //     CancelNowAC.cancel([CancelReasons.WebsocketFail]),
                //   )

                default:
                  return connectionFail$
              }
            }),
          )

          return merge(startSub$, close$, fail$, event$).pipe(takeUntil(cancelCamMic$$))
        }),

        catchError(() => connectionFail$),
      )
    }),
  )
