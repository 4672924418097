import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Config } from '../../../../config'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { SharedCamMicModelFieldsMR } from '../../../../models/bcast/shared-cam-mic/model'
import { Layout } from '../../../../style/theme/layout'
import { Button } from '../../../common/button/component'
import { Trans } from '../../../common/intl/trans'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { STDialogGetCamMicErrorContent } from './style'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmGetCamMicError)

// ~~~~~~ Component

export const DialogGetCamMicError = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const errors = useSelector((state: StoreState) => state.sharedCamMicStream.errors)

  // ~~~~~~ Handlers

  function onClickOk() {
    dispatch(SharedCamMicModelFieldsMR.errors.MC.change([]))

    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      predefIcon="info"
      modalSize={DialogBreakPoint.XS}
      dialogTitle="wildcard"
      dialogTitleValues={{ value: ' ' }}
      closeDialog={doClose}
    >
      {/* Content */}

      <Content>
        <STDialogGetCamMicErrorContent>
          {errors.map((error) => {
            return error.id === 'get-cam-mic.errors.NoDevicePermissions' ? (
              <div key={error.id}>
                <Trans id={error.id} />

                <div style={{ height: Layout.Spacing[2] }} />

                <span
                  className="get-cam-mic-error-link"
                  onClick={() => window.open(Config.Links.HowEnableDevicesBrowserPermissions)}
                >
                  <Trans id="dialogs.confirm.get-cam-mic-error.permissions-request.Info" />
                </span>
              </div>
            ) : (
              <Trans key={error.id} id={error.id} />
            )
          })}
        </STDialogGetCamMicErrorContent>
      </Content>

      {/* Actions */}

      <Actions>
        {/* Confirm */}

        <Button
          intlId="common.OK"
          $colorType="primary"
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickOk}
        />
      </Actions>
    </Dialog>
  )
}
