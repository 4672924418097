import { useState } from 'react'
import { useSelector } from 'react-redux'
import { DialogBreakPoint } from '../../../constants/breakpoints'
import { ModalsIds } from '../../../constants/modals'
import { StoreState } from '../../../models/app/model'
import { genAnimatedDialog } from '../compound-dialog/dialog/component'
import { DialogEndBcastFeedbackEmojis } from './emojis/component'
import { DialogEndBcastFeedbackForm } from './form/component'

// ~~~~~~ Constants

const ModalId = ModalsIds.EndBcastFeedback

const Dialog = genAnimatedDialog(ModalId)

// ~~~~~~ Component

export const DialogEndBcastFeedback = () => {
  // ~~~~~~ State

  // - Local

  const [doClose, setDoClose] = useState(0)

  // - Store

  const happiness = useSelector((state: StoreState) => state.feedbackEndBcast.happiness)

  // ~~~~~~ Handlers

  function onClose() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      modalSize={DialogBreakPoint.S}
      dialogTitle="wildcard"
      dialogTitleValues={{ value: '' }}
      closeDialog={doClose}
    >
      {happiness ? (
        <DialogEndBcastFeedbackForm onClose={onClose} />
      ) : (
        <DialogEndBcastFeedbackEmojis onClose={onClose} />
      )}
    </Dialog>
  )
}
