import React from 'react'
import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsCaptureShare } from '../../../../../behaviour-logic/content/main/toolbars/main/buttons/capture-share/hook'
import { ClassName, STJoinedMobileToolbarsMainButtonsSharePaint } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/share-paint')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

export const JoinedMobileToolbarsMainButtonsSharePaint: React.FC<Props> = ({ video }) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsCaptureShare(video)

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsMainButtonsSharePaint onClick={logic.onClickCaptureShare}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsMainButtonsSharePaint>
  )
}
