import styled from 'styled-components'

import { Theme } from '../../../../style/theme'
import { Colors } from '../../../../style/theme/colors'
import { theme } from '../../../../style/utils/theme'

export const STInfos = styled.div`
  padding-top: 2px;

  ${Theme.Fonts.Scale.Label};
  color: ${theme(Colors.BROOKLYN_40_30)};

  text-align: left;
`
