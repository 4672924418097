import {
  EMPTY,
  fromEvent,
  merge,
  Observable,
  of,
  catchError,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
} from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'
import { ConstWebRtc } from '@dn/constants'
import { GetCamAndMicStreamOpts } from '@dn/webrtc/dist/types/devices/partial-service'
import { UtilsSize } from '@dn/utils'
import { ServiceWebRtc } from '@dn/webrtc'
import { StoreState } from '../../../../models/app/model'
import { cancelAll$$ } from '../../../../subjects/cancel-all/subject'
import {
  ShareCamMicStreamAC,
  ShareCamMicStreamAT,
} from '../../../actions/share-cam-mic-stream/actions'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import { EpicShareCamMicStreamGetStreamMC, GetStreamData } from './mutators'
import { ShareCamMicStream$$ } from '../subject'
import { cancelCamMic$$ } from '../../cancel-now/cam-mic/subject'
import { Config } from '../../../../config'
import { UtilsLog } from '../../../../utils/logs'

type Action = ReturnType<typeof ShareCamMicStreamAC.getStream>

export const shareCamMicStreamGetStreamEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ShareCamMicStreamAT.GET_STREAM),

    mergeMap(({ payload }) => {
      const Options: GetCamAndMicStreamOpts = {
        // Audio

        getAudio: !!payload.data.getMic,
        audioDeviceId: payload.data.mic ? payload.data.mic.deviceId : undefined,

        // Video

        getVideo: !!payload.data.getCam,

        videoDeviceId: payload.data.cam ? payload.data.cam.deviceId : undefined,

        videoFrameRate: payload.data.getCam
          ? {
              ideal: Config.CamMic.fps,
              max: Config.CamMic.fps,
            }
          : undefined,

        videoWidth: payload.data.cam ? payload.data.camSize.width || undefined : undefined,
        videoHeight: payload.data.cam ? payload.data.camSize.height || undefined : undefined,
      }

      return ServiceWebRtc.Devices.getCamAndMicStream$(Options).pipe(
        takeUntil(cancelCamMic$$),

        mergeMap((stream) => {
          if (ServiceWebRtc.Guards.isWebRTCError(stream)) return of(stream)

          const [videoTrack] = stream.getVideoTracks()

          if (!videoTrack) return of(stream)

          const settings = videoTrack.getSettings()

          const { width, height } = UtilsSize.calcHeightWidthUsingMinForSmaller({
            min: Config.CamMic.minSize,
            width: settings.width || 0,
            height: settings.height || 0,
          })

          const obs$ = new Observable<MediaStream>((observer) => {
            if (!videoTrack.applyConstraints) {
              observer.next(stream)
              observer.complete()

              return
            }

            videoTrack
              .applyConstraints({
                width,
                height,
              })
              .then(() => {
                observer.next(stream)
                observer.complete()
              })
              .catch(() => {
                observer.next(stream)
                observer.complete()
              })
          })

          return obs$
        }),

        catchError((err: DN.Services.Webrtc.Error) => of(err)),
      )
    }),

    mergeMap((streamOrErr) => {
      if (ServiceWebRtc.Guards.isWebRTCError(streamOrErr)) {
        const errorCodes = streamOrErr.errorCodes

        const errors: Infos[] = []

        // No getUserMedia api

        errorCodes.includes('no-get-user-media') &&
          errors.push({ id: 'get-cam-mic.errors.NoGetUserMedia' })

        // No device found

        errorCodes.includes('no-device-found') &&
          errors.push({ id: 'get-cam-mic.errors.NoDeviceFound' })

        // Device permission

        errorCodes.includes('user-permission-denied') &&
          errors.push({ id: 'get-cam-mic.errors.NoDevicePermissions' })

        // Device is bushy

        errorCodes.includes('device-is-busy') &&
          errors.push({ id: 'get-cam-mic.errors.DeviceIsBusy' })

        // Unknown

        errorCodes.includes('unknown-error') && errors.push({ id: 'get-cam-mic.errors.Unknown' })

        return of(EpicShareCamMicStreamGetStreamMC.error(errors))
      }

      // User can press stop cam-mic before it is in the store

      cancelCamMic$$.pipe(take(1)).subscribe({
        next: () => {
          streamOrErr.getTracks().forEach((track) => {
            track.stop()
            streamOrErr.removeTrack(track)
          })
        },
      })

      const { selectedCam, selectedMic } = state$.value.devices

      const stream = streamOrErr

      const videoTracks = stream && stream.getVideoTracks ? stream.getVideoTracks() : undefined

      const audioTracks = stream && stream.getAudioTracks ? stream.getAudioTracks() : undefined

      const audio = audioTracks && audioTracks[0]
      const video = videoTracks && videoTracks[0]

      if (!audio && !video) {
        UtilsLog.devWarn('shareCamMicStreamGetStreamEpic', 'Not media track')

        return of(CancelNowAC.cancelCamMic([CancelReasons.ScreenStreamHasNotAMediaTrack]))
      }

      const streamData: GetStreamData = {
        videoTrack: selectedCam ? (videoTracks ? videoTracks[0] : undefined) : undefined,
        audioTrack: selectedMic ? (audioTracks ? audioTracks[0] : undefined) : undefined,
      }

      // If stream track ended (maybe user click on 'stop sharing' button)
      // dispatch cancel all, that also sends a disconnect message
      return merge(
        streamData.videoTrack
          ? observeMediaStreamTrack(streamData.videoTrack)
          : streamData.audioTrack
            ? observeMediaStreamTrack(streamData.audioTrack)
            : EMPTY,

        of(EpicShareCamMicStreamGetStreamMC.ok(stream, streamData)),
      )
    }),
  )

// ~~~~~~ Helpers

function observeMediaStreamTrack(mediaStreamTrack: MediaStreamTrack) {
  return fromEvent(mediaStreamTrack, ConstWebRtc.Media.Track.Events.Ended).pipe(
    take(1),

    takeUntil(cancelAll$$),
    takeUntil(cancelCamMic$$),
    takeUntil(ShareCamMicStream$$.pipe(filter((msg) => msg.type === 'replace-track'))),

    map(() => {
      return CancelNowAC.cancelCamMic([CancelReasons.CamMicSharingEnded])
    }),

    catchError(() => {
      return of(CancelNowAC.cancelCamMic([CancelReasons.CamMicStreamHasNotAMediaTrack]))
    }),
  )
}
