import { ofType, StateObservable } from 'redux-observable'
import { Observable, of, mergeMap } from 'rxjs'
import { loadStripe } from '@stripe/stripe-js'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreCheckoutCreateSession$ } from '../../../../../services/api-store/checkout/create-session'

import { Config } from '../../../../../config'
import { UtilsLog } from '../../../../../utils/logs'
import { ApiUtils } from '../../../utils'
import { ApiCheckoutCreateSessionEpicMC } from './mutators'
import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'

type Action = ReturnType<typeof ApiStoreCheckoutAC.createSession>

export const apiStoreCheckoutCreateSessionEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.CREATE_SESSION),
    mergeMap(({ payload }) =>
      apiStoreCheckoutCreateSession$(state$.value.currentUser.token, payload.data),
    ),
    mergeMap((res) => {
      // Error
      if (!ApiUtils.isSuccess(res)) {
        return of(ApiCheckoutCreateSessionEpicMC.error(res.response))
      }

      // Try to load stripe and redirect to checkout portal
      const obs$ = new Observable((observer) => {
        loadStripe(Config.ThirdParty.Stripe.PublishableKey)
          .then((stripe) => {
            if (!stripe) {
              UtilsLog.devLog('RedirectToCheckout', 'ERROR', 'Stripe not present')

              observer.next(
                ApiCheckoutCreateSessionEpicMC.error([
                  { id: 'dialogs.verification-code.errors.LoadStripe' },
                ]),
              )

              observer.complete()
              return
            }

            stripe
              .redirectToCheckout({
                sessionId: res.response.session_id,
              })
              .then((result) => {
                UtilsLog.devLog('RedirectToCheckout', 'SUCCESS', result)

                observer.next(ApiCheckoutCreateSessionEpicMC.ok())
                observer.complete()
              })
              .catch((err: any) => {
                UtilsLog.devLog('RedirectToCheckout', 'ERROR', err)

                observer.next(
                  ApiCheckoutCreateSessionEpicMC.error([
                    { id: 'wildcard', values: { value: 'RedirectToCheckout error' } },
                  ]),
                )

                observer.complete()
              })
          })
          .catch((err) => {
            UtilsLog.devLog('RedirectToCheckout', 'ERROR', err)

            observer.next(
              ApiCheckoutCreateSessionEpicMC.error([
                { id: 'wildcard', values: { value: 'Cannot load stripe' } },
              ]),
            )
          })
      })

      return obs$
    }),
  )
