import { ofType, StateObservable } from 'redux-observable'
import { filter, map, Observable, tap } from 'rxjs'
import { CountdownService } from '@dn/service-countdown'
import { StoreState } from '../../../../../models/app/model'
import { ApiBcastSubscriptionsAC } from '../../../../actions/api-bcast/subscription/actions'
import {
  CountdownBcastDurationAC,
  CountdownBcastDurationAT,
} from '../../../../actions/countdown/bcast-duration/actions'

// ~~~~~~ Actions

type Action = ReturnType<typeof CountdownBcastDurationAC.stop>

// ~~~~~~ Epic

export const countdownBcastDurationStopEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(CountdownBcastDurationAT.STOP),

    tap(() => CountdownService.Stop.minutes()),

    filter(() => {
      return typeof state$.value.subscription.time_limit === 'number'
    }),

    map(({ payload }) => ApiBcastSubscriptionsAC.getTime(payload.subscriptionId)),
  )
