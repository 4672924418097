import { StorageKeys } from '../../../constants/storage'
import { UtilsStorageLocal } from '@dn/utils'
import { Config } from '../../../config'

// ~~~~~~ Types

type Options = {
  autoShowBroadcastingOptions: boolean
  showBanner: boolean

  lang: DN.Models.UserSettings.ModelBack['lang']
}

// ~~~~~~ Constants

export const Langs: DN.Models.UserSettings.ModelBack['lang'][] = [
  'auto',
  ...Config.Intl.SupportedLocales,
]

const DefaultOptions: Options = {
  autoShowBroadcastingOptions: true,
  showBanner: true,

  lang: 'auto',
}

// ####################################################################################################
// ~~~~~~ Get
// ####################################################################################################

const get: () => Options = () => {
  const optionsStored = UtilsStorageLocal.getItem(StorageKeys.UserOptions)

  if (!optionsStored) return DefaultOptions

  let options = DefaultOptions

  try {
    const parsed = JSON.parse(optionsStored)

    if (typeof parsed !== 'object') {
      clear()
      return DefaultOptions
    }

    options.autoShowBroadcastingOptions = true

    options.showBanner =
      typeof parsed.showBanner === 'boolean' ? parsed.showBanner : DefaultOptions.showBanner

    options.lang = Langs.includes(parsed.lang) ? parsed.lang : 'auto'

    //
  } catch (err) {}

  return options
}

// ####################################################################################################
// ~~~~~~ Set
// ####################################################################################################

const set = (options: Options) => {
  const stringifyed = JSON.stringify(options)

  UtilsStorageLocal.setItem(StorageKeys.UserOptions, stringifyed)
}

const update = (options: Partial<Options>) => {
  const curOptions = get()

  const newOptions = {
    ...curOptions,
    ...options,
  }

  set(newOptions)
}

const clear = () => {
  UtilsStorageLocal.removeItem(StorageKeys.UserOptions)
}

// ~~~~~~

export const LocalStorageBroadcastOptions = {
  get,
  set,
  update,
  clear,
} as const
