import { catchError, Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

const { Delete } = ApiBcastRoutes.UserSettings

// ####################################################################################################
// ~~~~~~ User settings delete
// ####################################################################################################

type Res = DN.Models.UserSettings.Api.Show.Res
// type ResParsed = DNApiResParsed<DN.Models.UserSettings.Api.Show.ResParsed>
type ResError = DNApiError<DN.Models.UserSettings.Api.Show.ResError>

export const apiBcastUserSettingsDelete$ = (
  token: string,
  userId: string,
  headers?: AppHeaders,
) => {
  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersWithAuth(token, headers),
    method: Delete.Method,
    url: Delete.getUrl(userId),
  }).pipe(
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.user-settings.delete.Error' }],
      })
    }),
  )
}
