import { ofType } from 'redux-observable'
import { mergeMap, Observable, of, switchMap, delay, zip } from 'rxjs'
import { CountdownService } from '@dn/service-countdown'
import { Config } from '../../../../../config'
import { UtilsLog } from '../../../../../utils/logs'
import { SubscriptionMC } from '../../../../actions-mutators/subscription/mutators'
import { ApiBcastSubscriptionsAC } from '../../../../actions/api-bcast/subscription/actions'
import { CancelNowAC, CancelReasons } from '../../../../actions/cancel-now/actions'
import {
  CowntdownFreePremiumDaysAC,
  CountdownFreePremiumDaysAT,
} from '../../../../actions/countdown/free-premium-days/actions'

// ~~~~~~ Constants

const GracePeriodBeforeStop = 10000

// ~~~~~~ Actions

type Action = ReturnType<typeof CowntdownFreePremiumDaysAC.start>

// ~~~~~~ Epic

export const countdownFreePremiumDaysEpic$ = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(CountdownFreePremiumDaysAT.START),

    switchMap(({ payload }) =>
      CountdownService.Start.days$(new Date(payload.endOfFreePremiumPeriod)),
    ),

    mergeMap((days) => {
      Config.Logs.FreePremiumDays &&
        UtilsLog.devLog('Epic', 'freePremiumDaysCountdown', 'next: Update days', days)

      if (days === 0) {
        return zip(
          of(SubscriptionMC.setDaysLeft(0)),
          of(CancelNowAC.cancelAll([CancelReasons.UserStopped])),
          of(ApiBcastSubscriptionsAC.show()),
        ).pipe(
          delay(GracePeriodBeforeStop),
          mergeMap((actions) => of(...actions)),
        )
      }

      return of(SubscriptionMC.setDaysLeft(Math.ceil(days)))
    }),
  )
