import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import {
  ApiStreamManagerServerAC,
  ApiStreamManagerServerAT,
} from '../../../actions/api-stream-manager/server/actions'
import { ShareMainStreamAC, ShareMainStreamAT } from '../../../actions/share-main-stream/actions'
import {
  EpicBcastStartPubMainMC,
  EpicBcastStartPubMainMT,
} from '../../../epics/bcast/start-pub-main/mutators'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'
import {
  EpicApiBcastRoomPubMT,
  EpicApiBcastRoomPubMC,
} from '../../../epics/api-bcast/room/pub/mutators'

const initState = AppInitState.streamManagerPubMain

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Get screem, set Quality
// ####################################################################################################

reducer[ShareMainStreamAT.GET_STREAM] = (
  state: State,
  action: ReturnType<typeof ShareMainStreamAC.getStream>,
): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Get pub server, set broadcastId
// ####################################################################################################

reducer[ApiStreamManagerServerAT.GET_SERVER_PUB_MAIN] = (
  state: State,
  { payload }: ReturnType<typeof ApiStreamManagerServerAC.getServerPubMain>,
): State => {
  return {
    ...state,
    uiStatus: 'running',
    id: payload.broadcastId,
  }
}

// ####################################################################################################
// ~~~~~~ Room for pub (api-call) - ERROR
// ####################################################################################################

reducer[EpicApiBcastRoomPubMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastRoomPubMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ Pub main - OK
// ####################################################################################################

reducer[EpicBcastStartPubMainMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartPubMainMC.ok>,
): State => {
  return {
    ...state,
    uiStatus: 'completed',
    publisher: payload.publisher,
  }
}

// ####################################################################################################
// ~~~~~~ Pub main - ERROR
// ####################################################################################################

reducer[EpicBcastStartPubMainMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartPubMainMC.error>,
): State => {
  if (!payload.errors) return state

  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Last Event
// ####################################################################################################

reducer[EpicBcastStartPubMainMT.EVENT] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartPubMainMC.event>,
): State => {
  return {
    ...state,
    lastEvent: payload.type,
    isReady: payload.type === 'Publish.Available' ? true : state.isReady,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel now all - DONE
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~

export const streamManagerPubMainReducer = UtilsStore.dynReducer(initState, reducer)
