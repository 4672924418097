const Colors = {
  Black: '000000',
  White: 'ffffff',
  Purple: 'A665D3',
  Blue: '4B91E8',
  Green: '66BF86',
  Yellow: 'F5D358',
  Red: 'E25F59',
} as const

const getHexColor = (colorKey: keyof typeof Colors) => `#${Colors[colorKey]}`

export const PenColor = {
  BLACK: {
    name: 'BLACK',
    color: getHexColor('Black'),
    number: Colors.Black,
  },
  WHITE: {
    name: 'WHITE',
    color: getHexColor('White'),
    number: Colors.White,
  },
  PURPLE: {
    name: 'PURPLE',
    color: getHexColor('Purple'),
    number: Colors.Purple,
  },
  BLUE: {
    name: 'BLUE',
    color: getHexColor('Blue'),
    number: Colors.Blue,
  },
  GREEN: {
    name: 'GREEN',
    color: getHexColor('Green'),
    number: Colors.Green,
  },
  YELLOW: {
    name: 'YELLOW',
    color: getHexColor('Yellow'),
    number: Colors.Yellow,
  },
  RED: {
    name: 'RED',
    color: getHexColor('Red'),
    number: Colors.Red,
  },
} as const

export type PenColorKey = keyof typeof PenColor

// ~~~~~~~

export const ColorToKey = {
  [getHexColor('Black')]: 'BLACK',
  [getHexColor('White')]: 'WHITE',
  [getHexColor('Purple')]: 'PURPLE',
  [getHexColor('Blue')]: 'BLUE',
  [getHexColor('Green')]: 'GREEN',
  [getHexColor('Yellow')]: 'YELLOW',
  [getHexColor('Red')]: 'RED',
} as const

export type ColorToKeyKey = keyof typeof ColorToKey
