import styled from 'styled-components'
import { Transition } from '../../../style/animations/css-animations'
import { Colors } from '../../../style/theme/colors'
import { theme, applyTheme } from '../../../style/utils/theme'

// ~~~~~~ Constants

const ColorBase = Colors.WOLF_80_20
const ColorActive = Colors.WOLF_30_50

// ~~~~~~ Props

type STProps = {
  $disabled: boolean
}

// ~~~~~~ Styled component

export const STCheckBox = styled.div<STProps>`
  display: flex;
  user-select: none;

  [type='checkbox']:checked,
  [type='checkbox']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type='checkbox']:checked + label,
  [type='checkbox']:not(:checked) + label {
    position: relative;
    padding-left: 25px;
    display: inline-block;
  }

  [type='checkbox']:checked + label:before,
  [type='checkbox']:not(:checked) + label:before {
    ${Transition.BorderColorFaster};

    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    width: 12px;
    height: 12px;
    border: 1px solid ${theme(ColorBase)};
    border-radius: 2px;
  }

  [type='checkbox']:checked + label:after,
  [type='checkbox']:not(:checked) + label:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 16px;
    height: 16px;
    transition: all 0.2s ease;
  }

  [type='checkbox']:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  [type='checkbox']:checked + label:after {
    opacity: 1;
    top: 5px;
    left: 2px;
    width: 10px;
    height: 10px;
    transform: scale(1);
    background-color: ${(props) => applyTheme(props.$disabled ? ColorBase : ColorActive, props)};
  }

  [type='checkbox'] + label:hover:before {
    border-color: ${(props) => applyTheme(props.$disabled ? ColorBase : ColorActive, props)};
  }

  label {
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

    ${Transition.ColorFaster};

    ${(props) => (props.$disabled ? `color ${applyTheme(ColorBase, props)};` : '')}
  }

  &:hover {
    label {
      color: ${(props) => applyTheme(props.$disabled ? ColorBase : ColorActive, props)};
    }
  }
`
