import { UserMenu } from '../../../../components/common/user-menu/component'
import { StartedTopRightEndBroadcast } from './end-broadcast/component'
import { STStartedHeaderTopRight } from './style'
import { StartedHeaderTopRightTimeLeft } from './time-left/component'
import { StartedHeaderTopRightViewers } from './viewers/component'

// ~~~~~~ Component

export const StartedHeaderTopRight = () => {
  // ~~~~~~ Render

  return (
    <STStartedHeaderTopRight>
      {/* Free time left */}

      <StartedHeaderTopRightTimeLeft />

      {/* End bcast button */}

      <StartedTopRightEndBroadcast />

      {/* Viewers quantity */}

      <StartedHeaderTopRightViewers />

      {/* User menu */}

      <UserMenu />

      {/* -- */}
    </STStartedHeaderTopRight>
  )
}
