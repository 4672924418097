import styled from 'styled-components'

// ~~~~~~ Component

export const STPaintToolbarButtonsOnlyColors = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  .first {
    margin-left: 16px;
  }

  .color-btn {
    margin-right: 8px;

    &.last {
      margin-right: 0%;
    }
  }
`
