import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { ApiBcastUserSettingsAC } from '../../../../actions/api-bcast/user-settings/actions'
import { ApiStreamManagerServerAC } from '../../../../actions/api-stream-manager/server/actions'
import { CancelNowAC, CancelReasons } from '../../../../actions/cancel-now/actions'
import { EpicBcastCommChannelInitMC, EpicBcastCommChannelInitMT } from '../../init/mutators'
import { EpicBcastCommChannelMessagesFromServerMC } from './mutators'
import { BcastTrackEvents } from '../../../../../services/track-events'

type Action = ReturnType<typeof EpicBcastCommChannelInitMC.wsMessage>

export const bcastCommChannelMessagesFromServerEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicBcastCommChannelInitMT.WS_MESSAGE),

    mergeMap(({ payload: msg }) => {
      let actions = []

      const {
        isOnboardingRunning,
        onboarding_create_a_bcast,
        onboarding_change_source,
        onboarding_share,
      } = state$.value.userSettings

      const currentUserId = state$.value.currentUser.id

      switch (msg.type) {
        case 'bcast-current-state':
          actions.push(
            EpicBcastCommChannelMessagesFromServerMC.bcastCurrentState({
              camIsMuted: !msg.payload.bcasterCamTrackEnabled,
              micIsMuted: !msg.payload.bcasterMicTrackEnabled,
              sharingCamMic: msg.payload.bcasterCamMicStreamSharing,
            }),
          )

          // Start connection to cam-mic stream

          if (msg.payload.bcasterCamMicStreamSharing) {
            actions.push(ApiStreamManagerServerAC.getServerSubCamMic())
          }

          break

        case 'bcaster-cam-mic-stream-start-relay':
          // Ignore relay if we're getting the server to connect or we have the server.
          // This can happen if we receive the bcast-current-state with sharingCamMic as true,
          // so we start connecting and few secconds later, we receive bcaster-cam-mic-stream-start-relay

          if (
            state$.value.streamManagerServerSubCamMic.uiStatus === 'running' ||
            state$.value.streamManagerServerSubCamMic.broadcastId
          ) {
            return EMPTY
          }

          return of(
            EpicBcastCommChannelMessagesFromServerMC.bcastCurrentState({
              camIsMuted: !msg.payload.bcasterCamTrackEnabled,
              micIsMuted: !msg.payload.bcasterMicTrackEnabled,
              sharingCamMic: true,
            }),

            ApiStreamManagerServerAC.getServerSubCamMic(),
          )

        case 'bcaster-cam-mic-stream-stop-relay':
          return of(
            EpicBcastCommChannelMessagesFromServerMC.bcasterCamMicStop(),
            CancelNowAC.cancelCamMic([CancelReasons.SessionEnded]),
          )

        case 'bcaster-cam-track-disable-relay':
          return of(EpicBcastCommChannelMessagesFromServerMC.bcasterDisabledCam())

        case 'bcaster-cam-track-enable-relay':
          return of(EpicBcastCommChannelMessagesFromServerMC.bcasterEnabledCam())

        case 'bcaster-mic-track-disable-relay':
          return of(EpicBcastCommChannelMessagesFromServerMC.bcasterDisabledMic())

        case 'bcaster-mic-track-enable-relay':
          return of(EpicBcastCommChannelMessagesFromServerMC.bcasterEnabledMic())

        case 'presence-resume':
          // Onboarding share

          if (
            isOnboardingRunning &&
            onboarding_create_a_bcast &&
            onboarding_change_source &&
            !onboarding_share &&
            msg.payload.count > 0
          ) {
            actions.push(
              ApiBcastUserSettingsAC.update(currentUserId, {
                onboarding_share: true,
              }),
            )

            // Track

            const userContext = BcastTrackEvents.helpers.genUserContext(
              state$.value.currentUser,
              state$.value.subscription,
            )

            BcastTrackEvents.calls.Onboarding['3-onboarding-share'](userContext)
          }

          actions.push(EpicBcastCommChannelMessagesFromServerMC.presenceResume(msg.payload))

          break

        // Predef Messages

        case 'predef-msg':
          switch (msg.payload.id) {
            case 'confirm-close-no-viewers':
              return of(
                EpicBcastCommChannelMessagesFromServerMC.predefMsgConfirmCloseNoViewers(
                  msg.payload,
                ),
              )

            // Close Now
            case 'close-now-no-viewers':
              return of(EpicBcastCommChannelMessagesFromServerMC.predefMsgCloseNowNoViewers())

            default:
              return EMPTY
          }

        default:
          return EMPTY
      }

      const finalActions = actions.length ? actions : [EMPTY]

      return of(...finalActions)
    }),
  )
