import { UtilsBrowser, UtilsOS } from '@dn/utils'

// System

export const SystemIsOSX = UtilsOS.is.mac()

export const SystemIsIOs = UtilsOS.is.ios()

export const SystemVersionBase = parseInt(UtilsOS.getOsInfo().osVersion)

// Browser

export const BrowserIsSafari = UtilsBrowser.is.safari()

export const BrowserIsFirefox = UtilsBrowser.is.firefox()

export const BrowserVersionBase = parseInt(UtilsBrowser.version || '0')

// PIP

export const DevicePIPEnabled =
  BrowserIsSafari && BrowserVersionBase > 16
    ? false
    : SystemIsIOs
      ? SystemVersionBase < 17
        ? !(window.navigator as any).standalone
        : false
      : document.pictureInPictureEnabled
