import { Config } from '../../../../../config'
import { UtilsLog } from '../../../../../utils/logs'

// ~~~~~~ Command

export const addPathCommand = (
  drawingsTarget: DN.Services.Draw.DrawingsTarget,
  pathData: DN.Services.Draw.AddPathData,
) => {
  //

  let cmdPath: paper.Path | undefined = undefined

  return {
    name: 'addPath',

    execute() {
      Config.Logs.DrawService &&
        UtilsLog.devLog('\nDrawService', 'commands', 'addPathCommand', 'execute')

      drawingsTarget.cmdApplied += 1

      // Existent path

      if (cmdPath) {
        const path = cmdPath as any

        path.setVisible(true)

        return
      }

      // New Path

      cmdPath = new drawingsTarget.scope.Path(pathData)
    },

    undo() {
      Config.Logs.DrawService &&
        UtilsLog.devLog('\nDrawService', 'commands', 'addPathCommand', 'undo')

      drawingsTarget.cmdApplied -= 1

      if (!cmdPath) return

      const path = cmdPath as any

      path.setVisible(false)
    },
  }
}
