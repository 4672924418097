import { useSelector } from 'react-redux'
import { CamMicSub } from '../../../components/common/cam-mic/sub/component'
import { DeprecatedCamMicSub } from '../../../components/common/cam-mic/sub/deprecated/component'
import { LayoutViewerMobile } from '../../../components/layouts/viewer-mobile/component'
import { LayoutViewerMobileContent } from '../../../components/layouts/viewer-mobile/parts/content/component'
import { LayoutViewerMobileFloatPanel } from '../../../components/layouts/viewer-mobile/parts/float-panel/component'
import { LayoutViewerMobileHeader } from '../../../components/layouts/viewer-mobile/parts/header/component'
import { LayoutViewerMobileSound } from '../../../components/layouts/viewer-mobile/parts/sound/component'
import { LayoutViewerMobileToolbar } from '../../../components/layouts/viewer-mobile/parts/toolbar/component'
import { LayoutViewerMobileWebcam } from '../../../components/layouts/viewer-mobile/parts/webcam/component'
import { Config } from '../../../config'
import { JoinedMobileContent } from './content/component'
import { JoinedMobileFloatPanelCaptureShare } from './float-panel/capture-share/component'
import { JoinedMobileHeader } from './header/component'
import { JoinedMobileSound } from './sound/component'
import { JoinedMobileToolbars } from './toolbars/component'
import { StoreState } from '../../../models/app/model'

// ~~~~~~ Component

export const JoinedMobile = () => {
  // ~~~~~~ State

  const rootWebrtcKind = useSelector((state: StoreState) => state.root.version)

  // ~~~~~~ Computed

  const isBcastV1 =
    !Config.Features.BcastService2 ||
    (Config.Features.BcastService2 && Config.Features.BcastCheckVersion && rootWebrtcKind === 'v1')

  // ~~~~~~ Render

  return (
    <LayoutViewerMobile>
      {/* Float panel */}

      <LayoutViewerMobileFloatPanel>
        <JoinedMobileFloatPanelCaptureShare />
      </LayoutViewerMobileFloatPanel>

      {/* Header */}

      <LayoutViewerMobileHeader>
        <JoinedMobileHeader />
      </LayoutViewerMobileHeader>

      {/* Sound */}

      <LayoutViewerMobileSound>
        <JoinedMobileSound />
      </LayoutViewerMobileSound>

      {/* Toolbar */}

      <LayoutViewerMobileToolbar>
        <JoinedMobileToolbars />
      </LayoutViewerMobileToolbar>

      {/* Content */}

      <LayoutViewerMobileContent>
        <JoinedMobileContent />
      </LayoutViewerMobileContent>

      {/* Webcam */}

      <LayoutViewerMobileWebcam>
        {isBcastV1 ? <DeprecatedCamMicSub /> : <CamMicSub />}
      </LayoutViewerMobileWebcam>

      {/* -- */}
    </LayoutViewerMobile>
  )
}
