import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { theme } from '../../../../style/utils/theme'

export const STDialogGetCamMicErrorContent = styled.div`
  .get-cam-mic-error-link {
    cursor: pointer;
    color: ${theme(Colors.EMMET_30_30)};
  }
`
