import { AppInitState } from '../../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ApiBcastGoogleClassRoomAT } from '../../../../actions/api-bcast/google-classroom/actions'
import { EpicApiBcastGoogleClassRoomAnnounceCaptureMT } from '../../../../epics/api-bcast/google-classroom/announce-capture/mutators'

const initState = AppInitState.googleClassRoomAnnouncementCapture

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Create Announcement - START
// ####################################################################################################

reducer[ApiBcastGoogleClassRoomAT.ANNOUNCE_CAPTURE] = (state: State): State => {
  return {
    ...state,
    errors: [],
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Create Announcement - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomAnnounceCaptureMT.OK] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ Create Announcement - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomAnnounceCaptureMT.ERROR] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ~~~~~~

export const googleClassRoomAnnouncementCaptureReducer = UtilsStore.dynReducer(initState, reducer)
