import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'onboarding-panel-option'

// ~~~~~~ Component

export const STOnboardingPanelOption = styled.div`
  ${Fonts.Scale.BodyBig}
  ${Fonts.Weight[500]}

  color: ${theme(Colors.WOLF_100_05)};

  display: flex;
  align-items: center;

  margin-bottom: ${Layout.Spacing[3]}px;

  &.thin {
    ${Fonts.Weight[300]}
  }

  /* Lock */
  color: ${theme(Colors.WOLF_50_50)};

  &.todo {
    color: ${theme(Colors.WOLF_100_05)};
  }

  &.success {
    color: ${theme(Colors.WOLF_80_20)};
  }

  .${ClassName}--icon {
    margin-right: ${Layout.Spacing[2]}px;

    svg {
      width: 16px;
      height: 16px;

      display: flex;
      align-items: center;

      path {
        /* Lock */
        fill: ${theme(Colors.WOLF_50_50)};
        stroke: none;
      }
    }

    &.todo {
      svg path {
        fill: ${theme(Colors.WOLF_100_05)};
      }
    }

    &.success {
      svg path {
        fill: ${theme(Colors.SITO_50_40)};
      }
    }
  }
`
