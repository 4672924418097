import { CancelReasons } from '../../../actions/cancel-now/actions'

const prefix = 'mt-epic-cancel-now-cam-mic'

// ~~~~~~ Mutators Types

export const EpicCancelNowCamMicMT = {
  DONE: `${prefix}-done`,
}

// ~~~~~~ Mutators Creators

export const EpicCancelNowCamMicMC = {
  done: (reasons: CancelReasons[]) => ({
    type: EpicCancelNowCamMicMT.DONE,
    payload: reasons,
  }),
}
