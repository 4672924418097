import { filter, tap, take } from 'rxjs'
import { v4 } from 'uuid'
import { UtilsObservables } from '@dn/utils'
import { guardIsAck } from '../../../../guards/is-ack/comm-channel-partial-service'
import { CommChannelRes$$ } from '../../../../subject/response/comm-channel-partial-service'
import { genMergeMapAckTimeout } from '../../../gen-ack-timeout/comm-channel-partial-service'
import { commChannelSendMessage$ } from '../../../comm-channel-partial-service'

export const sendMessageWithAckBcasterCamTrackEnable$ = (webSocket: WebSocket) => {
  const ackId = v4()

  const message: DN.Services.CommChannel.Messages.ToServer.WithAck.CamTrack.Enable.Req = {
    type: 'bcaster-cam-track-enable',

    payload: {
      ackData: {
        id: ackId,
      },
    },
  }

  return commChannelSendMessage$(webSocket, message).pipe(
    genMergeMapAckTimeout(
      CommChannelRes$$.pipe(
        filter((message) => guardIsAck(message) && message.payload.ackData.id === ackId),
        tap(UtilsObservables.tapLogObserver('ACK: bcaster-cam-track-enable')),
        take(1),
      ),
    ),
  )
}
