import { UtilsStore } from '@dn/utils'
import { AppInitState } from '../../../../models/app/model'
import { StreamManagerSubCamMicFieldsMR } from '../../../../models/stream-manager/sub/cam-mic/model'
import { ExitMT } from '../../../actions-mutators/exit/mutators'
import {
  EpicBcastCommChannelMessagesFromServerMC,
  EpicBcastCommChannelMessagesFromServerMT,
} from '../../../epics/comm-channel/messages/from-server/mutators'
import {
  EpicBcastStartSubCamMicMC,
  EpicBcastStartSubCamMicMT,
} from '../../../epics/bcast/start-sub-cam-mic/mutators'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'
import { EpicCancelNowCamMicMT } from '../../../epics/cancel-now/cam-mic/mutators'

const initState = AppInitState.streamManagerSubCamMic

type State = typeof initState

const reducer: any = {
  // ...StreamManagerSubCamMicFieldsMR.iMutedAudio.Reducer,
  ...StreamManagerSubCamMicFieldsMR.bcasterMutedAudio.Reducer,
  ...StreamManagerSubCamMicFieldsMR.bcasterMutedVideo.Reducer,
}

// ####################################################################################################
// ~~~~~~ Control muted state
// ####################################################################################################

reducer[StreamManagerSubCamMicFieldsMR.iMutedAudio.MT.CHANGE] = (
  state: State,
  { payload }: ReturnType<typeof StreamManagerSubCamMicFieldsMR.iMutedAudio.MC.change>,
): State => {
  return {
    ...state,

    iMutedAudio: payload,
    iHaveTouchedAudioControl: true,
  }
}

// ####################################################################################################
// ~~~~~~ Subscription Event: Ok
// ####################################################################################################

reducer[EpicBcastStartSubCamMicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartSubCamMicMC.ok>,
): State => {
  return {
    ...state,
    subscriber: payload.sub,
    username: payload.username,
    id: payload.broadcastId,
  }
}

// ####################################################################################################
// ~~~~~~ Subscription Event: error
// ####################################################################################################

reducer[EpicBcastStartSubCamMicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartSubCamMicMC.error>,
): State => {
  if (!payload.errors) return state

  return {
    ...state,
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Last Event
// ####################################################################################################

reducer[EpicBcastStartSubCamMicMT.EVENT] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartSubCamMicMC.event>,
): State => {
  return {
    ...state,
    lastEvent: payload.type,
  }
}

// ####################################################################################################
// ~~~~~~ Reset exit, remove errors
// ####################################################################################################

reducer[ExitMT.RESET_EXIT] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Cancel now all - DONE
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Cancel now cam-mic - DONE
// ####################################################################################################

reducer[EpicCancelNowCamMicMT.DONE] = (state: State): State => {
  return initState
}

// ---------------------------------------------------------------------------------------------------
// ------  MESSAGES
// ---------------------------------------------------------------------------------------------------

// ####################################################################################################
// ~~~~~~ WS Message: Bcast current state
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesFromServerMT.BCAST_CURRENT_STATE] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastCommChannelMessagesFromServerMC.bcastCurrentState>,
): State => {
  return {
    ...state,
    bcasterMutedCam: payload.camIsMuted,
    bcasterMutedMic: payload.micIsMuted,
  }
}

// ####################################################################################################
// ~~~~~~ WS Message: Bcaster enabled cam
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesFromServerMT.BCASTER_ENABLED_CAM] = (state: State): State => {
  return {
    ...state,
    bcasterMutedCam: false,
  }
}

// ####################################################################################################
// ~~~~~~ WS Message: Bcaster disabled cam
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesFromServerMT.BCASTER_DISABLED_CAM] = (state: State): State => {
  return {
    ...state,
    bcasterMutedCam: true,
  }
}

// ####################################################################################################
// ~~~~~~ WS Message: Bcaster enabled mic
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesFromServerMT.BCASTER_ENABLED_MIC] = (state: State): State => {
  return {
    ...state,
    bcasterMutedMic: false,
  }
}

// ####################################################################################################
// ~~~~~~ WS Message: Bcaster disabled mic
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesFromServerMT.BCASTER_DISABLED_MIC] = (state: State): State => {
  return {
    ...state,
    bcasterMutedMic: true,
  }
}

// ~~~~~~

export const streamManagerSubCamMicReducer = UtilsStore.dynReducer(initState, reducer)
