import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { ClassName, STJoinedMobileToolbarsCapturePaintButtonsBin } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/bin')

// ~~~~~~ Types

type Props = {
  onClick: () => void
}

// ~~~~~~ Component

export const JoinedMobileToolbarsCapturePaintButtonsBin: React.FC<Props> = ({ onClick }) => {
  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsCapturePaintButtonsBin onClick={onClick}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsCapturePaintButtonsBin>
  )
}
