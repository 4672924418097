const prefix = 'mt-stats'

// ~~~~~~ Mutators Types

export const StatsMT = {
  TOGGLE: `${prefix}-toggle`,
  ENABLE: `${prefix}-enable`,
  ADD_STATS: `${prefix}-add-stats`,
} as const

// ~~~~~~ Mutators Creators

export const StatsMC = {
  toggle: () => ({
    type: StatsMT.TOGGLE,
  }),

  enable: () => ({
    type: StatsMT.ENABLE,
  }),

  addStats: (info: DN.Utils.PeerConnStats.Info) => ({
    type: StatsMT.ADD_STATS,
    payload: info,
  }),
} as const
