const prefix = 'mt-api-bcast-google-slides-add-image-slide-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastGoogleSlidesAddImageSlideMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastGoogleSlidesAddImageSlideMC = {
  ok: (data: DN.Models.GoogleSlides.ModelBack) => ({
    type: EpicApiBcastGoogleSlidesAddImageSlideMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.GoogleSlides.Api.AddImageSlide.ResError, unsync: boolean) => ({
    type: EpicApiBcastGoogleSlidesAddImageSlideMT.ERROR,
    error: true,
    payload: {
      unsync,
      errors,
    },
  }),
} as const
