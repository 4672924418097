import { UtilsStore } from '@dn/utils'

const initModel: DN.Models.FeedbackEndBcast.Model = {
  uiStatus: 'init',
  errors: [],

  happiness: '',
  comments: '',
  get_in_touch: false,
}

// ~~~~~~ Edit

const prefix = 'mt-feedback-end-bcast'

// ~~~~~~ Fields Mutators/Reducers

export const FeedbackEndBcastFieldsMR = {
  __model__: UtilsStore.genModelMR(prefix, initModel),

  happiness: UtilsStore.genBasicFieldMR(prefix, initModel, 'happiness', initModel.happiness),

  comments: UtilsStore.genBasicFieldMR(prefix, initModel, 'comments', initModel.comments),

  getInTouch: UtilsStore.genBasicFieldMR(prefix, initModel, 'get_in_touch', initModel.get_in_touch),
}

// ~~~~~~

export const FeedbackEndBcastModel = {
  initModel,
}
