import { catchError, map, Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiStoreRoutes } from '../constants/routes'
import { StoreHeaderUtils } from '../utils/headers'

const { CheckPaymentMethod } = ApiStoreRoutes.Subscriptions

// ####################################################################################################
// ~~~~~~ Check Payment Method
// ####################################################################################################

// type Req = DN.Models.Subscription.Api.CheckPaymentMethod.Req
type Res = DN.Models.StoreSubscription.Api.CheckPaymentMethod.Res
type ResParsed = DNApiResParsed<DN.Models.StoreSubscription.Api.CheckPaymentMethod.ResParsed>
type ResError = DNApiError<DN.Models.StoreSubscription.Api.CheckPaymentMethod.ResError>

export const apiStoreSubscriptionsCheckPaymentMethod$ = (
  token: string,
  subscriptionId: string,
  headers?: AppHeaders,
) => {
  return ajax<Res>({
    ...StoreHeaderUtils.genHeadersWithAuth(token, headers),
    method: CheckPaymentMethod.Method,
    url: CheckPaymentMethod.getUrl(subscriptionId),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      switch (res.status) {
        case 404:
          return of({
            status: res.status,
            response: [{ id: 'api.subscriptions.check-payment-method.Error404' }],
          })
        default:
          return of({
            status: res.status,
            response: [{ id: 'api.subscriptions.check-payment-method.Error' }],
          })
      }
    }),
  )
}
