import { EMPTY, Observable, mergeMap, tap } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'

import { StoreState } from '../../../../models/app/model'
import {
  EpicApiBcastCurrentUserShowMC,
  EpicApiBcastCurrentUserShowMT,
} from '../../api-bcast/current-user/show/mutators'
import {
  EpicShareMainStreamGetStreamMC,
  EpicShareMainStreamGetStreamMT,
} from '../../share-main-stream/get-stream/mutators'
import {
  EpicApiProductsListMC,
  EpicApiProductsListMT,
} from '../../api-store/products/list/mutators'
import {
  EpicApiBcastProvidersLinkGoogleMC,
  EpicApiBcastProvidersLinkGoogleMT,
} from '../../api-bcast/providers/link-google/mutators'
import {
  EpicApiBcastProvidersUnlinkGoogleMC,
  EpicApiBcastProvidersUnlinkGoogleMT,
} from '../../api-bcast/providers/unlink-google/mutators'
import {
  EpicApiBcastGoogleClassRoomListMC,
  EpicApiBcastGoogleClassRoomListMT,
} from '../../api-bcast/google-classroom/list/mutators'
import {
  EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC,
  EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT,
} from '../../api-bcast/google-classroom/announce-bcast-link/mutators'
import {
  EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC,
  EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT,
} from '../../api-bcast/google-slides/create-presentation-and-add-image-slide/mutators'
import {
  EpicApiBcastGoogleSlidesAddImageSlideMC,
  EpicApiBcastGoogleSlidesAddImageSlideMT,
} from '../../api-bcast/google-slides/add-image-slide/mutators'
import {
  EpicApiBcastGoogleSlidesCreatePresentationMC,
  EpicApiBcastGoogleSlidesCreatePresentationMT,
} from '../../api-bcast/google-slides/create-presentation/mutators'
import {
  EpicApiBcastGoogleClassRoomAnnounceCaptureMC,
  EpicApiBcastGoogleClassRoomAnnounceCaptureMT,
} from '../../api-bcast/google-classroom/announce-capture/mutators'
import { ToastUtil } from '../../../../components/common/toast/util'
import { ProviderModel } from '../../../../models/bcast/providers/model'
import {
  EpicApiBcastUserSettingsShowMC,
  EpicApiBcastUserSettingsShowMT,
} from '../../api-bcast/user-settings/show/mutators'
import {
  EpicApiBcastUserSettingsUpdateMC,
  EpicApiBcastUserSettingsUpdateMT,
} from '../../api-bcast/user-settings/update/mutators'
import {
  EpicApiBcastUserSettingsDeleteMC,
  EpicApiBcastUserSettingsDeleteMT,
} from '../../api-bcast/user-settings/delete/mutators'

type Action =
  | ReturnType<typeof EpicApiBcastCurrentUserShowMC.error>
  | ReturnType<typeof EpicApiBcastUserSettingsShowMC.error>
  | ReturnType<typeof EpicApiBcastUserSettingsUpdateMC.error>
  | ReturnType<typeof EpicApiBcastUserSettingsDeleteMC.error>
  | ReturnType<typeof EpicShareMainStreamGetStreamMC.error>
  | ReturnType<typeof EpicApiProductsListMC.error>
  | ReturnType<typeof EpicApiBcastProvidersLinkGoogleMC.error>
  | ReturnType<typeof EpicApiBcastProvidersUnlinkGoogleMC.error>
  | ReturnType<typeof EpicApiBcastGoogleClassRoomListMC.error>
  | ReturnType<typeof EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC.error>
  | ReturnType<typeof EpicApiBcastGoogleClassRoomAnnounceCaptureMC.error>
  | ReturnType<typeof EpicApiBcastGoogleSlidesCreatePresentationMC.error>
  | ReturnType<typeof EpicApiBcastGoogleSlidesAddImageSlideMC.error>
  | ReturnType<typeof EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC.error>

export const toastErrorsEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      EpicApiBcastCurrentUserShowMT.ERROR,

      // User Settings

      EpicApiBcastUserSettingsShowMT.ERROR,
      EpicApiBcastUserSettingsUpdateMT.ERROR,
      EpicApiBcastUserSettingsDeleteMT.ERROR,

      // Others

      EpicShareMainStreamGetStreamMT.ERROR,
      EpicApiProductsListMT.ERROR,
      EpicApiBcastProvidersLinkGoogleMT.ERROR,
      EpicApiBcastProvidersUnlinkGoogleMT.ERROR,

      // Classroom

      EpicApiBcastGoogleClassRoomListMT.ERROR,
      EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT.ERROR,
      EpicApiBcastGoogleClassRoomAnnounceCaptureMT.ERROR,

      // Slides

      EpicApiBcastGoogleSlidesCreatePresentationMT.ERROR,
      EpicApiBcastGoogleSlidesAddImageSlideMT.ERROR,
      EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.ERROR,
    ),

    tap(({ type, payload }) => {
      //

      // Link Google inssufficient permissions should show a dialog

      if (
        payload.errors.some(
          (error) =>
            error.id === ProviderModel.customErrors.authorizationCodeInsufficientPermissionsIntlId,
        )
      ) {
        return
      }

      // Toast errors

      payload.errors.forEach((error) => {
        if (error.id === 'wildcard') return

        let values = { value: '' }

        if (error.values && typeof error.values === 'object') {
          values = {
            ...values,
            ...error.values,
          }
        }

        ToastUtil.basic({
          kind: 'error',
          intlIds: [{ id: error.id, values }],
          duration: 7000,
        })
      })
    }),

    mergeMap(() => EMPTY),
  )
