const prefix = 'mt-bcast-comm-channel-messages-to-server-baster-cam-mic-stop'

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesToServerBcasterCamMicStopMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
  TIMEOUT: `${prefix}-timeout`,
} as const

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesToServerBcasterCamMicStopMC = {
  ok: () => ({
    type: EpicBcastCommChannelMessagesToServerBcasterCamMicStopMT.OK,
  }),

  error: () => ({
    type: EpicBcastCommChannelMessagesToServerBcasterCamMicStopMT.ERROR,
  }),

  timeout: () => ({
    type: EpicBcastCommChannelMessagesToServerBcasterCamMicStopMT.TIMEOUT,
  }),
} as const
