import { Observable, map, mergeMap } from 'rxjs'
import { StateObservable, ofType } from 'redux-observable'
import { StoreState } from '../../../../../models/app/model'
import { ApiBcastRoomAC, ApiBcastRoomAT } from '../../../../actions/api-bcast/room/actions'
import { EpicApiBcastRoomSubMC } from './mutators'
import { apiBcastRoomForSub$ } from '../../../../../services/api-bcast/room/for-sub'
import { ApiUtils } from '../../../utils'
import { UtilsBcast } from '../../../../../utils/bcast'
// import { Config } from '../../../../../config'

// const bcastIdPrefix = Config.Api.Bcast.IdPrefix

type Action = ReturnType<typeof ApiBcastRoomAC.forSub>

export const apiBcastRoomForSubEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastRoomAT.FOR_SUB),

    mergeMap(({ payload }) => {
      // const fullBcastId = `${bcastIdPrefix ? `${bcastIdPrefix}-` : ''}${payload.bcastId}`

      const bcastId = UtilsBcast.getPublicBcastId(payload.bcastId)

      if (bcastId !== payload.bcastId) {
        console.warn('sub', bcastId, 'vs', payload.bcastId)
      }

      return apiBcastRoomForSub$(bcastId, 3)
    }),

    map((res) =>
      ApiUtils.isSuccess(res)
        ? EpicApiBcastRoomSubMC.ok(res.response)
        : EpicApiBcastRoomSubMC.error(res.response),
    ),
  )
