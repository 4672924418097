import styled from 'styled-components'
import { Transition } from '../../../../../../../style/animations/css-animations'
import { Colors } from '../../../../../../../style/theme/colors'
import { Layout } from '../../../../../../../style/theme/layout'
import { theme } from '../../../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-tablet-content-main-toolbars-main'

const ButtonsMargin = Layout.Spacing[2]

export const BtnBgColors = {
  color: Colors.WOLF_00_90,
  hover: Colors.WOLF_10_80,
  pressed: Colors.WOLF_00_90,
}

// ~~~~~~ Component

export const STJoinedTabletContentMainToolbarsMain = styled.div`
  display: flex;
  align-items: center;

  height: 56px;
  width: 100%;

  .${ClassName}--icon-btn {
    position: relative;
    z-index: 10;

    cursor: pointer;

    ${Transition.BackgroundColorFaster}
    background-color: ${theme(BtnBgColors.color)};

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: ${ButtonsMargin}px;
    margin-right: ${ButtonsMargin}px;

    width: 56px;
    height: 56px;

    border-radius: 50%;
  }

  .${ClassName}--zoom {
    position: relative;
    z-index: 10;

    ${Transition.BackgroundColorFaster}
    background-color: ${theme(BtnBgColors.color)};

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: ${ButtonsMargin}px;
    margin-right: ${ButtonsMargin}px;

    height: 56px;

    padding-left: 16px;
    padding-right: 16px;
    border-radius: 100px;
  }
`
