import styled from 'styled-components'
import { Colors } from '../../../../../../style/theme/colors'
import { Fonts } from '../../../../../../style/theme/fonts'
import { Layout } from '../../../../../../style/theme/layout'
import { theme } from '../../../../../../style/utils/theme'

export const STJoinedCommonHeaderTopRightBroadcastId = styled.div`
  ${Fonts.Scale.BodyBig}
  ${Fonts.Weight[700]}

  color: ${theme(Colors.WOLF_100_05)};

  padding: ${Layout.Spacing[2]}px;
`
