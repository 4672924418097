const initModel: DN.Models.StunTurnCredentials.ModelBack = {
  username: '',
  password: '',
  ttl: 0,
  uris: [],
}

export const StunTurnModel = {
  initModel,
} as const
