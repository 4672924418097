import styled from 'styled-components'

// ~~~~~~ Component

export const STStatsGraph = styled.div`
  padding-top: 20px;
  width: 620px;
`

export const STCrosshair = styled.div`
  background-color: #fff;
  border: solid 1px #a3b3c2;
  padding: 4px;
  border-radius: 5px;
`
