const prefix = 'mt-auth'

// ~~~~~~ Mutators Types

export const AuthMT = {
  GET_TOKEN_START: `${prefix}-get-token`,
  GET_TOKEN_ERROR: `${prefix}-get-token-error`,
  SET_SESSION_CHECKED: `${prefix}-set-session-checked`,
} as const

// ~~~~~~ Mutators Creators

export const AuthMC = {
  getTokenStart: () => ({
    type: AuthMT.GET_TOKEN_START,
  }),

  getTokenError: () => ({
    type: AuthMT.GET_TOKEN_ERROR,
  }),

  setSessionChecked: () => ({
    type: AuthMT.SET_SESSION_CHECKED,
  }),
} as const
