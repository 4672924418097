const prefix = 'mt-paint-bcast-stream-draw-stop-epic'

// ~~~~~~ Mutators Types

export const EpicPaintBcastStreamDrawStopMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicPaintBcastStreamDrawStopMC = {
  ok: () => ({
    type: EpicPaintBcastStreamDrawStopMT.OK,
  }),
} as const
