import { Color } from 'paper/dist/paper-core'
import { genDrawingCommandManager } from '../command-manager/draw-partial-service'
import { DrawState } from '../@state/draw-partial-service'
import { HexAlpha, SmoothType } from '../constants/draw-partial-service'
import { addToolListeners$ } from '../add-tool-listeners/draw-partial-service'
import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'
import { Observable, takeUntil } from 'rxjs'
import { DrawStop$$ } from '../subject/draw-partial-service'
import { ClearProp } from '../command-manager/commands/clear/draw-partial-service'
import { RemPathProp } from '../command-manager/commands/rem-path/draw-partial-service'
import { getSomeItemIsVisible } from '../get-some-item-is-visible/draw-partial-service'

// ~~~~~~ Types

type OutEvent =
  | {
      type: 'addPath'
    }
  | {
      type: 'remPath'
      payload: {
        isDirty: boolean
      }
    }

// ####################################################################################################
// ~~~~~~ Start Painting
// ####################################################################################################

export const startPainting$ = (scope: paper.PaperScope) => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'startPainting')

  // Command manager to execute actions in our scope (paint, clear, ...)

  const drawingsCM = genDrawingCommandManager(scope)

  DrawState.setCommandManager(drawingsCM)

  // Paint based in mouse events

  const drawing$ = addToolListeners$(scope.view)

  // Elements preserver between drawing (press + move + stop)

  let curPath: paper.Path | undefined

  let remItems: paper.Item[] = []

  // Observe + emit after drawing

  const obs$ = new Observable<OutEvent>((observer) => {
    drawing$.subscribe({
      next: ({ eventType, point }) => {
        Config.Logs.DrawService && UtilsLog.devLog('drawing$ sub', eventType)

        const strokeColor = DrawState.State.IsErasing
          ? new Color(Config.Features.DebugEraser ? '#7FFF00' : 'transparent')
          : new Color(`${DrawState.State.Color}${DrawState.State.HasAlpha ? HexAlpha[50] : ''}`)

        const strokeWidth = DrawState.State.IsErasing ? 1 : DrawState.State.StrokeWidth

        if (!curPath) {
          const path = new scope.Path({
            segments: [],
            strokeColor,
            strokeWidth,
            project: scope.project,
          })

          curPath = path
        }

        curPath.smooth({ type: SmoothType.CONTINUOUS })

        curPath.add(point)

        // Maybe erase (to run command rem)

        const layer = scope.project.getItems({})[0]

        const layerItems = layer.getItems({}).slice(1)

        if (DrawState.State.IsErasing) {
          layerItems.forEach((item) => {
            if (!curPath) return

            if (item === curPath) return

            const anyItem = item as any

            if (!item.visible || anyItem[ClearProp] || anyItem[RemPathProp]) return

            const intersec = !!curPath.getIntersections(item as paper.PathItem).length

            if (!intersec) return

            item.visible = false

            remItems.push(item)
          })
        }

        // On finish draw (erase is transparent draw)

        if (eventType !== 'up' && eventType !== 'leave') return

        // Simplify path (extra smooth because use less points)

        curPath.simplify(5)

        // Run command (add or rem path) + Emit

        switch (true) {
          // Add path

          case !DrawState.State.IsErasing:
            const addPathData: DN.Services.Draw.AddPathData = {
              segments: curPath.segments,
              strokeColor,
              strokeWidth,
              strokeCap: 'round',
              strokeJoin: 'round',
            }

            drawingsCM.doCommand('addPath', addPathData)

            // -))
            observer.next({ type: 'addPath' })

            break

          // Rem path

          case DrawState.State.IsErasing && !!remItems.length:
            const remPathData: DN.Services.Draw.RemPathData = {
              items: remItems,
            }

            drawingsCM.doCommand('remPath', remPathData)

            remItems = []

            // Timeout required to have the true information
            // about items visibility because curPath is already
            // present
            setTimeout(() => {
              const someItemIsVisible = getSomeItemIsVisible(scope)

              // -))
              observer.next({
                type: 'remPath',
                payload: {
                  isDirty: someItemIsVisible,
                },
              })
            }, 0)

            break
        }

        // Remove path (final path is created by command, a kind of clone)

        curPath.remove()

        curPath = undefined
      },
    })
  })

  return obs$.pipe(takeUntil(DrawStop$$))
}
