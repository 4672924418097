import styled from 'styled-components'
import { theme } from '../../../../../../../../../style/utils/theme'
import {
  STJoinedTabletContentMainToolbarsButtonsCommonStyle,
  CommonStyleSvgColors,
} from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-tablet-content-main-toolbars-main-buttons-capture-paint'

// ~~~~~~ Component

export const STJoinedTabletContentMainToolbarsMainButtonsCapturePaint = styled.div`
  ${STJoinedTabletContentMainToolbarsButtonsCommonStyle}

  svg.${ClassName}--icon {
    path {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }
  }
`
