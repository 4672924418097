import { useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { JoinedMobileToolbarsCapturePaint } from './capture-paint/component'
import { JoinedMobileToolbarsMain } from './main/component'
import { JoinedMobileToolbarsMenuBtn } from './menu-btn/component'
import { STJoinedMobileToolbars } from './style'

// ~~~~~~ Component

export const JoinedMobileToolbars = () => {
  // ~~~~~~ State

  // - Store

  const openStatus = useSelector((state: StoreState) => state.uiMobileToolbar.openStatus)

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbars>
      {/* Menu Button */}

      {openStatus === 'closed' ? <JoinedMobileToolbarsMenuBtn /> : undefined}

      {/* Main toolbar */}

      {openStatus === 'main' ? <JoinedMobileToolbarsMain /> : undefined}

      {/* Paint toolbar */}

      {/* - Closed */}

      {openStatus === 'painting-closed' ? <JoinedMobileToolbarsMenuBtn /> : undefined}

      {/* - Open */}

      {openStatus === 'painting-open' ? <JoinedMobileToolbarsCapturePaint /> : undefined}

      {/* -- */}
    </STJoinedMobileToolbars>
  )
}
