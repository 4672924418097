import { of, Observable, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiStoreRoutes } from '../constants/routes'
import { StoreHeaderUtils } from '../utils/headers'

const { ValidateEmailCode } = ApiStoreRoutes.Checkout

// ####################################################################################################
// ~~~~~~ Validate email code
// ####################################################################################################

type Req = DN.Models.StoreCheckout.Api.ValidateEmailCode.Req
type Res = DN.Models.StoreCheckout.Api.ValidateEmailCode.Res
type ResParsed = DNApiResParsed<DN.Models.StoreCheckout.Api.ValidateEmailCode.ResParsed>
type ResError = DNApiError<DN.Models.StoreCheckout.Api.ValidateEmailCode.ResError>

export const apiStoreCheckoutValidateEmailCode$ = (data: Req, headers?: AppHeaders) => {
  return ajax<Res>({
    ...StoreHeaderUtils.genHeaders(headers),
    method: ValidateEmailCode.Method,
    url: ValidateEmailCode.getUrl(),
    body: data,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      switch (res.status) {
        case 404:
          return of({
            status: res.status,
            response: [{ id: 'api.checkout.validate-email-code.Error404' }],
          })
        default:
          return of({
            status: res.status,
            response: [{ id: 'api.checkout.validate-email-code.Error' }],
          })
      }
    }),
  )
}
