import styled from 'styled-components'
import { Transition } from '../../../../../../style/animations/css-animations'
import { Colors } from '../../../../../../style/theme/colors'
import { Layout } from '../../../../../../style/theme/layout'
import { theme } from '../../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'started-content-main-toolbars-main'

const ButtonsMargin = Layout.Spacing[2]

export const BtnBgColors = {
  color: Colors.WOLF_00_90,
  hover: Colors.WOLF_10_80,
  pressed: Colors.WOLF_00_90,
}

// ~~~~~~ Component

export const STStartedContentMainToolbarsMain = styled.div`
  display: flex;
  align-items: center;

  .${ClassName}--text-btn {
    position: relative;
    z-index: 10;

    cursor: pointer;

    ${Transition.BackgroundColorFaster}
    background-color: ${theme(BtnBgColors.color)};

    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: 16px;
    padding-right: 16px;

    margin-left: ${ButtonsMargin}px;
    margin-right: ${ButtonsMargin}px;

    height: 56px;

    border-radius: 35px;

    &:hover {
      background-color: ${theme(BtnBgColors.hover)};
    }

    &:active {
      background-color: ${theme(BtnBgColors.pressed)};
    }
  }

  .${ClassName}--icon-btn {
    position: relative;
    z-index: 10;

    cursor: pointer;

    ${Transition.BackgroundColorFaster}
    background-color: ${theme(BtnBgColors.color)};

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: ${ButtonsMargin}px;
    margin-right: ${ButtonsMargin}px;

    width: 56px;
    height: 56px;

    border-radius: 50%;

    &.running {
      cursor: progress;
    }

    &:hover:not(.running) {
      background-color: ${theme(BtnBgColors.hover)};
    }

    &:active {
      background-color: ${theme(BtnBgColors.pressed)};
    }
  }
`
