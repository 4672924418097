const prefix = 'mt-bcast-comm-channel-messages-to-server-baster-cam-disable'

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesToServerBcasterCamDisableMT = {
  OK: `${prefix}-ok`,
  TIMEOUT: `${prefix}-timeout`,
} as const

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesToServerBcasterCamDisableMC = {
  ok: () => ({
    type: EpicBcastCommChannelMessagesToServerBcasterCamDisableMT.OK,
  }),

  timeout: () => ({
    type: EpicBcastCommChannelMessagesToServerBcasterCamDisableMT.TIMEOUT,
  }),
} as const
