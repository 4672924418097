import { UtilsStore } from '@dn/utils'
import { UtilsValidate } from '../../../utils/validations'

// ~~~~~~ Constants

const ErrorType: Infos = { id: 'wildcard' }

const initMetaListModel: DN.Models.GoogleSlides.MetaListModelFront = {
  uiStatus: 'init',
  errors: [],
  list: [],
  searchTerm: '',
  page: 0,
  perPage: 10,
  sortBy: 'title',
  sortDir: 'ASC',
}

const initAddImageToSlideModel: DN.Models.GoogleSlides.AddImageToSlide = {
  uiStatus: 'init',
  errors: [],
}

const initCreateModel: DN.Models.GoogleSlides.CreateModelFront = {
  uiStatus: 'init',
  errors: [],
  id: '',

  title: '',
  title_err: [],
}

// ~~~~~~ Fields Mutators/Reducers Add Image To Slide

const prefixAddImageToSlide = 'mt-google-slides-add-image-to-slide'

export const GoogleSlidesAddImageToSlideFieldsMR = {
  errors: UtilsStore.genFieldMR(
    prefixAddImageToSlide,
    initAddImageToSlideModel,
    'errors',
    initCreateModel.errors,
    ErrorType,
  ),
}

// ~~~~~~ Fields Mutators/Reducers for Create

const prefixCreate = 'mt-google-slides-create-field'

export const GoogleSlidesCreateFieldsMR = {
  title: UtilsStore.genFieldMR(
    prefixCreate,
    initCreateModel,
    'title',
    initCreateModel.title,
    ErrorType,
  ),
}

// ~~~~~~ Validations

const { Validations } = UtilsValidate

const validations = {
  Title: [
    { error: Validations.Required },
    { error: Validations.InvalidChars },
    { error: Validations.MinLength, value: 1 },
    { error: Validations.MaxLength, value: 100 },
  ],
}

// ~~~~~~

export const GoogleSlidesModel = {
  initMetaListModel,
  initAddImageToSlideModel,
  initCreateModel,
  validations,
}
