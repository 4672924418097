import styled from 'styled-components'
import { Transition } from '../../../../style/animations/css-animations'
import { Colors } from '../../../../style/theme/colors'
import { HexAlpha } from '../../../../style/theme/colors/alpha'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

const fadeInTime = '260ms'
const fadeOutTime = '300ms'

const width = '96px'
const height = width

const borderRadius = 0

// ~~~~~~ Component

export const STSharedCamMic = styled.div`
  position: relative;

  .shared-cam-mic-loading {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: ${borderRadius};

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${theme(Colors.WOLF_100_100)}${HexAlpha[60]};
  }

  &.available {
    .cam-mic-pub {
      /* animation: blink 2s; */
    }
  }

  &:hover {
    .cam-mic-pub {
      .cam-mic-pub-hover {
        visibility: visible;
        opacity: 1;
        transition: all ${fadeInTime} ease-in;
      }
    }
  }

  .cam-mic-pub {
    overflow: hidden;
    isolation: isolate;

    width: ${width};
    height: ${height};

    border-radius: ${borderRadius};

    position: relative;

    .cam-mic-pub-hover {
      visibility: hidden;
      opacity: 0;
      transition: all ${fadeOutTime} ease-out;

      position: absolute;
      top: 0;
      left: 0;

      z-index: 3;

      width: 100%;
      height: 100%;

      border-radius: ${borderRadius};

      background-color: transparent;

      &.with-background {
        background-color: ${theme(Colors.WOLF_100_100)};
      }

      .cam-mic-pub-controls {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .cam-mic-pub-controls-panel {
          width: 70px;

          background-color: ${theme(Colors.WOLF_90_90)}${HexAlpha[80]};

          border-radius: 35px;
          display: flex;
          justify-content: space-between;

          padding: 8px;

          .cam-mic-pub-controls-audio,
          .cam-mic-pub-controls-video {
            cursor: pointer;

            background-color: transparent;

            width: 30px;
            height: 30px;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            ${Transition.ColorBorderAndBackgroundFaster}

            &.not-allowed {
              cursor: not-allowed;
            }

            &:hover:not(.not-allowed) {
              background-color: ${theme(Colors.WOLF_100_100)};
            }

            &:active:not(.not-allowed) {
              background-color: ${theme(Colors.WOLF_95_95)};
            }

            &.running {
              background-color: ${theme(Colors.WOLF_95_95)};

              &:hover {
                background-color: ${theme(Colors.WOLF_95_95)};
              }

              svg path {
                stroke: ${theme(Colors.WOLF_50_50)};
              }
            }

            svg {
              width: 20px;

              path {
                stroke: ${theme(Colors.WOLF_05_05)};
              }
            }
          }
        }
      }
    }

    .cam-muted {
      position: absolute;
      z-index: 2;

      width: calc(100% - 12px);
      height: calc(100% - 12px);
      top: 0;
      left: 0;

      border-radius: ${borderRadius};
      border: solid 6px ${theme(Colors.WOLF_90_90)};

      background-color: ${theme(Colors.WOLF_95_95)};

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 40px;
        height: 40px;

        path {
          stroke: ${theme(Colors.WOLF_05_05)};
        }
      }
    }

    video.cam-mic-pub-video {
      position: absolute;
      z-index: 1;

      object-fit: cover;
      border-radius: ${borderRadius};

      background-color: ${theme(Colors.WOLF_95_95)};

      width: ${width};
      height: ${height};

      transform: rotateY(180deg);
    }
  }
`
