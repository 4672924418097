import styled from 'styled-components'
import { Layout } from '../../../../../style/theme/layout'

export const STDialogsSubscriptionsPlansForDialogsLayout = styled.div`
  padding: ${Layout.Spacing[3]}px;
  padding-top: ${Layout.Spacing[5]}px;

  height: calc(100% - ${Layout.Spacing[3] + Layout.Spacing[5]}px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .plans-for-dialogs-layout-content {
    flex: 1;
  }

  .plans-for-dialogs-layout-action {
  }
`
