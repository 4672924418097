import { of, Observable, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiStoreRoutes } from '../constants/routes'
import { StoreHeaderUtils } from '../utils/headers'

const { SendEmailWithPass } = ApiStoreRoutes.Checkout

// ####################################################################################################
// ~~~~~~ Send Email With Pass
// ####################################################################################################

type Req = DN.Models.StoreCheckout.Api.SendEmailPass.Req
type Res = DN.Models.StoreCheckout.Api.SendEmailPass.Res
type ResParsed = DNApiResParsed<DN.Models.StoreCheckout.Api.SendEmailPass.ResParsed>
type ResError = DNApiError<DN.Models.StoreCheckout.Api.SendEmailPass.ResError>

export const apiStoreCheckoutSendEmailPass$ = (data: Req, headers?: AppHeaders) => {
  return ajax<Res>({
    ...StoreHeaderUtils.genHeaders(headers),
    method: SendEmailWithPass.Method,
    url: SendEmailWithPass.getUrl(),
    body: data,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      switch (res.status) {
        case 412:
          return of({
            status: res.status,
            response: [{ id: 'api.checkout.send-email-pass.Error412' }],
          })
        default:
          return of({
            status: res.status,
            response: [{ id: 'api.checkout.send-email-pass.Error' }],
          })
      }
    }),
  )
}
