import { of, Observable, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'

import { ApiStoreRoutes } from '../constants/routes'
import { StoreHeaderUtils } from '../utils/headers'

// NOTE: This call is used in components before stripe calls

const { CardIntent } = ApiStoreRoutes.Checkout

// ####################################################################################################
// ~~~~~~ Card Intent
// ####################################################################################################

type Res = { intent: { client_secret: string } }
type ResParsed = DNApiResParsed<Res>
type ResError = DNApiError<Infos[]>

export const apiStoreCheckoutCardIntent$ = (token: string, headers?: AppHeaders) => {
  return ajax<Res>({
    ...StoreHeaderUtils.genHeadersWithAuth(token, headers),
    method: CardIntent.Method,
    url: CardIntent.getUrl(),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        of({
          status: res.status,
          response: [{ id: 'api.checkout.card-intent.Error' }],
        }),
    ),
  )
}
