import { Config } from '../../../../config'
import { UtilsLog } from '../../../../utils/logs'
import { DrawState } from '../../@state/draw-partial-service'

// ####################################################################################################
// ~~~~~~ Undo
// ####################################################################################################

export const undo = () => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'public-commands', 'undo')

  if (!DrawState.State.commandManager) return

  return DrawState.State.commandManager.undo()
}
