import styled from 'styled-components'
import { theme } from '../../../../../../../../style/utils/theme'
import {
  CommonStyleSvgColors,
  STStartedContentMainToolbarsButtonsCommonStyle,
} from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'started-content-main-toolbars-main-buttons-fullscreen'

// ~~~~~~ Component

export const STStartedContentMainToolbarsMainButtonsFullscreen = styled.div`
  ${STStartedContentMainToolbarsButtonsCommonStyle}

  svg.${ClassName}--icon-off {
    path {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }
  }

  svg.${ClassName}--icon-on {
    path {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }

  &.with-tooltip {
    .tooltiptext {
      width: unset;
      white-space: nowrap;
    }
  }
`
