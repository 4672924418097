import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-capture-paint'

// ~~~~~~ Component

export const STJoinedMobileToolbarsCapturePaint = styled.div`
  background-color: ${theme(Colors.WOLF_00_90)};

  border-radius: 100px;

  .${ClassName}--content {
    .${ClassName}--content--inner {
      padding: ${Layout.Spacing[1]}px 0;

      width: unset;
      height: calc(100% - ${Layout.Spacing[1] * 2}px);

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  @media (orientation: landscape) {
    .${ClassName}--content {
      .${ClassName}--content--inner {
        padding: 0 ${Layout.Spacing[1]}px;

        width: calc(100% - ${Layout.Spacing[1] * 2}px);
        height: unset;
      }
    }
  }
`
