import { STJoinedMobileHeader } from './style'
import { JoinedMobileHeaderTopLeft } from './top-left/component'
import { JoinedMobileHeaderTopRight } from './top-right/component'

// ~~~~~~ Types

type Props = {
  isMobile?: true
}

// ~~~~~~ Component

export const JoinedMobileHeader: React.FC<Props> = ({ isMobile }) => {
  // ~~~~~~ Render

  return (
    <STJoinedMobileHeader>
      {/* Left */}

      <div>
        <JoinedMobileHeaderTopLeft />
      </div>

      {/* Right */}

      <div>
        <JoinedMobileHeaderTopRight />
      </div>

      {/* - */}
    </STJoinedMobileHeader>
  )
}
