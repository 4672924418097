import { useEffect } from 'react'
import { LocalStorageStoreDuration } from '../../../services/storage/local/store-duration'
import { BcastTrackEvents } from '../../../services/track-events'

// ~~~~~~ Component

export const SendStoredDuration = () => {
  // ~~~~~~ Effects

  // - Load from local storage, pub and sub duration events
  //   and send to analytics

  useEffect(() => {
    const data = LocalStorageStoreDuration.get()

    if (!data) return

    data.forEach((evt) => {
      evt.kind === 'pub'
        ? evt.userContext &&
          BcastTrackEvents.calls.Streams.PubMain.duration(
            { mins: evt.mins },
            evt.userContext,
            evt.streamContext,
          )
        : BcastTrackEvents.calls.Streams.SubMain.duration({ mins: evt.mins }, evt.streamContext)
    })

    LocalStorageStoreDuration.clear()

    //
  }, [])

  // ~~~~~~ Render

  return null
}
