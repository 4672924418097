import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { map, filter, interval } from 'rxjs'
import { LazySVG } from '../../../../../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../../../../../models/app/model'
import { UiLayoutPanelsFieldsMR } from '../../../../../../../../models/bcast/ui-layout-panels/model'
import { LivePaintEditFieldsMR } from '../../../../../../../../models/bcast/live-paint/model'
import { ClassName, STStartedContentMainToolbarsMainButtonsStartLivePaint } from './style'
import { Trans } from '../../../../../../../../components/common/intl/trans'
import { UiOnboardingFieldsMR } from '../../../../../../../../models/bcast/ui-onboarding/model'
import { UtilsCompare } from '@dn/utils'
import { ApiBcastUserSettingsAC } from '../../../../../../../../store/actions/api-bcast/user-settings/actions'
import { useTrackUserContext } from '../../../../../../../../hooks/track-events/use-track-user-context'
import { BcastTrackEvents } from '../../../../../../../../services/track-events'
import { useTrackStreamContext } from '../../../../../../../../hooks/track-events/use-track-stream-context'

// ~~~~~~ Constants

const PaintIcon = LazySVG('icons/tool-pen')

// ~~~~~~ Helpers

const isPipOn = () => !!document.pictureInPictureElement

// ~~~~~~ Component

export const StartedContentMainToolbarsMainButtonsStartLivePaint = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  const streamContext = useTrackStreamContext()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const { id: currentUserId } = useSelector((state: StoreState) => state.currentUser)

  const uiBounds = useSelector((state: StoreState) => state.uiOnboarding.onboardingLiveDrawBounds)

  const userSettings = useSelector((state: StoreState) => state.userSettings)

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  const tooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Computed

  const self = selfRef.current

  const tooltipTop = tooltipRef.current ? -tooltipRef.current.clientHeight - 8 : -34

  const shouldCalculateBounds =
    userSettings.isOnboardingRunning &&
    !userSettings.onboarding_is_completed &&
    userSettings.onboarding_create_a_bcast &&
    userSettings.onboarding_change_source &&
    userSettings.onboarding_share &&
    !userSettings.onboarding_live_draw

  // ~~~~~~ Handlers

  function onClickLivePaint() {
    if (isPipOn()) {
      document.exitPictureInPicture().catch(() => undefined)
    }

    if (shouldCalculateBounds) {
      dispatch(
        ApiBcastUserSettingsAC.update(currentUserId, {
          onboarding_live_draw: true,
        }),
      )

      // Track

      BcastTrackEvents.calls.Onboarding['4-onboarding-live-draw'](userContext)
    }

    dispatch(UiLayoutPanelsFieldsMR.savedPaintingLeftPanelOpenState.MC.change('closed'))

    dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('closed'))

    dispatch(LivePaintEditFieldsMR.enabled.MC.change(true))

    // Track

    BcastTrackEvents.calls.PaintStart.bcaster(userContext, streamContext)
  }

  // ~~~~~~ Effects

  // - Force update on mount to calculate tooltip position

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // - Observe self bounds to update uiBounds

  useEffect(() => {
    if (!shouldCalculateBounds || !self) return

    const sub = interval(50)
      .pipe(
        map(() => self.getBoundingClientRect().toJSON()),

        filter((bounds) => !UtilsCompare.allValuesAre(bounds, 0)),

        filter((bounds) => !UtilsCompare.containsSameValuesAtFirstLevel(bounds, uiBounds)),
      )
      .subscribe({
        next: (bounds) => {
          dispatch(UiOnboardingFieldsMR.onboardingLiveDrawBounds.MC.change(bounds))
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [dispatch, self, shouldCalculateBounds, uiBounds])

  // ~~~~~~ Render

  return (
    <STStartedContentMainToolbarsMainButtonsStartLivePaint
      ref={selfRef}
      className="with-tooltip"
      onClick={onClickLivePaint}
    >
      {/* Icon */}

      <PaintIcon className={`${ClassName}--icon`} size={24} />

      {/* Tooltip */}

      {!shouldCalculateBounds ? (
        <span
          ref={tooltipRef}
          className="tooltiptext"
          style={{
            top: tooltipTop,
          }}
        >
          <Trans id="pages.started.main.toolbars.main.tooltips.LivePaint" />
        </span>
      ) : undefined}

      {/* -- */}
    </STStartedContentMainToolbarsMainButtonsStartLivePaint>
  )
}
