import { JoinedCommonHeaderTopRightBroadcastId } from './broadcast-id/component'
import { JoinedCommonHeaderTopRightLeave } from './leave/component'
import { STJoinedCommonHeaderTopRight } from './style'

// ~~~~~~ Component

export const JoinedCommonHeaderTopRight = () => {
  // ~~~~~~ Render

  return (
    <STJoinedCommonHeaderTopRight>
      {/* Broadcast ID: Common */}

      <JoinedCommonHeaderTopRightBroadcastId />

      {/* Leave */}

      <JoinedCommonHeaderTopRightLeave />

      {/* -- */}
    </STJoinedCommonHeaderTopRight>
  )
}
