const prefix = 'mt-api-checkout-create-session-epic'

// ~~~~~~ Mutators Types

export const ApiCheckoutCreateSessionEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiCheckoutCreateSessionEpicMC = {
  ok: () => ({
    type: ApiCheckoutCreateSessionEpicMT.OK,
  }),

  error: (errors: DN.Models.StoreCheckout.Api.CreateSession.ResError) => ({
    type: ApiCheckoutCreateSessionEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
