import { HooksBounds } from '@dn/hooks'
import { AnimatePresence, motion } from 'framer-motion'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../models/app/model'
import { UiLayoutViewerMobileFieldsMR } from '../../../models/bcast/ui-layout-viewer-mobile/model'
import { LayoutViewerMobileContext } from './context/component'
import { ClassName, STLayoutViewerMobile } from './style'

// ~~~~~~ Types

type Props = {
  children: React.ReactNode
}

// ~~~~~~ Component

export const LayoutViewerMobile: React.FC<Props> = ({ children }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const sizeData = HooksBounds.CheckPortrait.useHook()

  // ~~~~~~ State

  // - Store

  const floatPanelOpenState = useSelector(
    (state: StoreState) => state.uiLayoutViewerMobile.floatPanelOpenState,
  )

  const subCamMicSubscriber = useSelector(
    (state: StoreState) => state.streamManagerSubCamMic.subscriber,
  )

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Computed

  const showFloatPanel = floatPanelOpenState === 'open'

  const selfWidth = selfRef.current?.clientWidth || 0

  const selfHeight = selfRef.current?.clientHeight || 0

  // x

  const initialAndExitFloatX = sizeData.isPortrait ? -selfWidth * (3 / 4) : 0

  const finalFloatX = sizeData.isPortrait ? 0 : 0

  // y

  const initialAndExitFloatY = sizeData.isPortrait ? 0 : selfHeight * (3 / 4)

  const finalFloatY = sizeData.isPortrait ? 0 : 0

  // ~~~~~~ Handlers

  function onExitFloatPanelComplete() {
    dispatch(UiLayoutViewerMobileFieldsMR.floatPanelOpenState.MC.change('closed'))
  }

  // ~~~~~~ Render

  return (
    <STLayoutViewerMobile ref={selfRef}>
      {/* Float Panel */}

      <div
        className={`${ClassName}--float-panel ${floatPanelOpenState === 'closed' ? 'hidden' : ''}`}
      >
        {/* - */}

        <LayoutViewerMobileContext.Provider value={{ kind: 'float-panel' }}>
          <AnimatePresence onExitComplete={onExitFloatPanelComplete}>
            {showFloatPanel ? (
              <motion.div
                className={`${ClassName}--float-panel--inner`}
                //
                initial={{ opacity: 0, x: initialAndExitFloatX, y: initialAndExitFloatY }}
                //
                animate={{ opacity: 1, x: finalFloatX, y: finalFloatY }}
                //
                exit={{ opacity: 0, x: initialAndExitFloatX, y: initialAndExitFloatY }}
                //
                transition={{ duration: 0.25 }}
              >
                {children}
              </motion.div>
            ) : undefined}
          </AnimatePresence>
        </LayoutViewerMobileContext.Provider>

        {/* - */}
      </div>

      {/* Sound */}

      <div
        className={`${ClassName}--sound ${subCamMicSubscriber ? '' : 'hidden'} ${
          !sizeData.isPortrait ? 'no-header' : ''
        }`}
        style={{ width: sizeData.width }}
      >
        {/* - */}

        <LayoutViewerMobileContext.Provider value={{ kind: 'sound' }}>
          <div className={`${ClassName}--sound--inner`}>{children}</div>
        </LayoutViewerMobileContext.Provider>

        {/* - */}
      </div>

      {/* Toolbar */}

      <div className={`${ClassName}--toolbar`}>
        {/* - */}

        <LayoutViewerMobileContext.Provider value={{ kind: 'toolbar' }}>
          <div className={`${ClassName}--toolbar--inner`}>{children}</div>
        </LayoutViewerMobileContext.Provider>

        {/* - */}
      </div>

      {/* Middle: Header Content */}

      <div className={`${ClassName}--middle`}>
        {/* Header */}

        <div className={`${ClassName}--middle--header ${!sizeData.isPortrait ? 'hidden' : ''}`}>
          {/* - */}

          <LayoutViewerMobileContext.Provider value={{ kind: 'header' }}>
            <div className={`${ClassName}--middle--header--inner`}>{children}</div>
          </LayoutViewerMobileContext.Provider>

          {/* - */}
        </div>

        {/* Content */}

        <div className={`${ClassName}--middle--content`}>
          {/* - */}

          <LayoutViewerMobileContext.Provider value={{ kind: 'content' }}>
            <div
              className={`${ClassName}--middle--content--inner ${
                !sizeData.isPortrait ? 'no-header' : ''
              }`}
            >
              {children}
            </div>
          </LayoutViewerMobileContext.Provider>

          {/* - */}
        </div>

        {/* -- */}
      </div>

      {/* Webcam */}

      <div className={`${ClassName}--webcam ${subCamMicSubscriber ? '' : 'hidden'}`}>
        {/* - */}

        <LayoutViewerMobileContext.Provider value={{ kind: 'webcam' }}>
          <div className={`${ClassName}--webcam--inner`}>{children}</div>
        </LayoutViewerMobileContext.Provider>

        {/* - */}
      </div>

      {/* -- */}
    </STLayoutViewerMobile>
  )
}
