import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-settings-provider-google'

// ~~~~~~ Component

export const STUserSettingsProvidersGoogle = styled.div`
  display: flex;

  .${ClassName}--icon {
    margin-top: 6px;
    margin-right: ${Layout.Spacing[3]}px;

    svg {
      height: 32px;
      width: 32px;
    }
  }

  .${ClassName}--content {
    .${ClassName}--content--title {
      ${Fonts.Scale.BodyBig}
      ${Fonts.Weight[700]}
    }

    .${ClassName}--content--subtitle {
      ${Fonts.Scale.Body}
      ${Fonts.Weight[500]}

      color: ${theme(Colors.WOLF_80_20)};

      margin-bottom: 10px;
    }

    .${ClassName}--actions {
      cursor: pointer;

      display: flex;

      .${ClassName}--actions--link {
        ${Fonts.Weight[700]}
        color: ${theme(Colors.EMMET_50_40)};
      }

      .${ClassName}--actions--unlink {
        ${Fonts.Weight[500]}
        color: ${theme(Colors.BROOKLYN_50_40)};
      }
    }
  }
`
