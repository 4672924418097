import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { ClassName, STJoineddMobileToolbarsCapturePaintButtonsClose } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/exit')

// ~~~~~~ Types

type Props = {
  onClick: () => void
}

// ~~~~~~ Component

export const JoineddMobileToolbarsCapturePaintButtonsClose: React.FC<Props> = ({ onClick }) => {
  // ~~~~~~ Render

  return (
    <STJoineddMobileToolbarsCapturePaintButtonsClose onClick={onClick}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoineddMobileToolbarsCapturePaintButtonsClose>
  )
}
