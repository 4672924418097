import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable, of } from 'rxjs'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { ModalsMC } from '../../../actions-mutators/modals/mutators'
import {
  EpicShareMainStreamGetStreamByExtensionToSwitchMC,
  EpicShareMainStreamGetStreamByExtensionToSwitchMT,
} from '../../share-main-stream/get-stream-by-extension-to-switch/mutators'
import {
  EpicShareMainStreamGetStreamByExtensionMC,
  EpicShareMainStreamGetStreamByExtensionMT,
} from '../../share-main-stream/get-stream-by-extension/mutators'
import {
  EpicShareMainStreamGetScreenToSwitchMC,
  EpicShareMainStreamGetScreenToSwitchMT,
} from '../../share-main-stream/get-stream-to-switch/mutators'
import {
  EpicShareMainStreamGetStreamMC,
  EpicShareMainStreamGetStreamMT,
} from '../../share-main-stream/get-stream/mutators'
import { BrowserIsFirefox, BrowserIsSafari, SystemIsIOs } from '../../../../constants/misc'

// ~~~~~~

type Action =
  | ReturnType<typeof EpicShareMainStreamGetStreamByExtensionToSwitchMC.error>
  | ReturnType<typeof EpicShareMainStreamGetStreamByExtensionMC.error>
  | ReturnType<typeof EpicShareMainStreamGetScreenToSwitchMC.error>
  | ReturnType<typeof EpicShareMainStreamGetStreamMC.error>

// ~~~~~~

export const errorsToModalsGrantPermissionsToGetStreamsEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      EpicShareMainStreamGetStreamByExtensionToSwitchMT.ERROR,
      EpicShareMainStreamGetStreamByExtensionMT.ERROR,
      EpicShareMainStreamGetScreenToSwitchMT.ERROR,
      EpicShareMainStreamGetStreamMT.ERROR,
    ),

    mergeMap(({ payload }) => {
      // Safari: We can not distinguish the error
      if (BrowserIsSafari) {
        if (SystemIsIOs) return EMPTY

        return of(ModalsMC.open(ModalsIds.ConfirmGrantSystemPermissions))
      }

      const systemPermissionDismissed = payload.errors.some((error) => {
        if (!error.values) return false

        const systemPermissionDismissed: DN.Services.Webrtc.ErrorCode =
          'system-permission-dismissed'

        return error.values.value === systemPermissionDismissed
      })

      if (!systemPermissionDismissed) {
        if (BrowserIsFirefox) {
          return of(ModalsMC.open(ModalsIds.ConfirmUnblockGetScreen))
        }

        return EMPTY
      }

      return of(ModalsMC.open(ModalsIds.ConfirmGrantSystemPermissions))
    }),
  )
