import { Variants } from 'framer-motion'
import { JoinedMobileToolbarsCapturePaintButtonsBin } from '../buttons/bin/component'
import { JoineddMobileToolbarsCapturePaintButtonsClose } from '../buttons/close/component'
import { JoinedMoblieToolbarsCapturePaintButtonsColors } from '../buttons/colors/component'
import { JoinedMobileToolbarsCapturePaintButtonsDownload } from '../buttons/download/component'
import { JoinedMobileToolbarsCapturePaintButtonsEraser } from '../buttons/eraser/component'
import { JoinedMoblieToolbarsCapturePaintButtonsPens } from '../buttons/pens/component'
import { JoinedMobileToolbarsCapturePaintButtonsRedo } from '../buttons/redo/component'
import { JoinedMobileToolbarsCapturePaintButtonsSharePaint } from '../buttons/share-paint/component'
import { JoinedMobileToolbarsCapturePaintButtonsUndo } from '../buttons/undo/component'

// ~~~~~~ Props

type Props = {
  className: string

  isPortrait: boolean

  pensVariantsContainer: Variants | undefined
  colorsVariantsContainer: Variants | undefined

  onClickSelectPen: (pen: DN.Models.PaintBcastStream.Draw.Pen) => void
  onClickShowPenSelector: () => void

  onClickEraser: () => void

  onClickSelectColor: (colorKey: DN.Models.PaintBcastStream.Draw.ColorKey) => void
  onClickShowColorSelector: () => void

  onClickUndo: () => void
  onClickRedo: () => void
  onClickClear: () => void
  onClickSharePaint: () => void
  onClickSave: () => void
  onClickExitEditingPic: () => void
}

// ~~~~~~ Component

export const JoinedMobileToolbarsCapturePaintContent: React.FC<Props> = ({
  className,

  isPortrait,

  pensVariantsContainer,
  colorsVariantsContainer,

  onClickSelectPen,
  onClickShowPenSelector,

  onClickEraser,

  onClickSelectColor,
  onClickShowColorSelector,

  onClickUndo,
  onClickRedo,
  onClickClear,
  onClickSharePaint,
  onClickSave,
  onClickExitEditingPic,
}) => {
  // ~~~~~~ Render

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: isPortrait ? 'column' : 'row',
      }}
    >
      {/* Pens */}

      <JoinedMoblieToolbarsCapturePaintButtonsPens
        showAll={false}
        variantsContainer={pensVariantsContainer}
        isPortrait={isPortrait}
        onClickSelectPen={onClickSelectPen}
        onClickShowPenSelector={onClickShowPenSelector}
      />

      {/* Eraser */}

      <JoinedMobileToolbarsCapturePaintButtonsEraser onClick={onClickEraser} />

      {/* Colors */}

      <JoinedMoblieToolbarsCapturePaintButtonsColors
        showAll={false}
        variantsContainer={colorsVariantsContainer}
        isPortrait={isPortrait}
        onClickSelectColor={onClickSelectColor}
        onClickShowColorSelector={onClickShowColorSelector}
        onClickSelectPen={onClickSelectPen}
      />

      {/* Undo */}

      <JoinedMobileToolbarsCapturePaintButtonsUndo onClick={onClickUndo} />

      {/* Redo */}

      <JoinedMobileToolbarsCapturePaintButtonsRedo onClick={onClickRedo} />

      {/* Bin (Clear drawings) */}

      <JoinedMobileToolbarsCapturePaintButtonsBin onClick={onClickClear} />

      {/* Share paint */}

      <JoinedMobileToolbarsCapturePaintButtonsSharePaint onClick={onClickSharePaint} />

      {/* Download */}

      <JoinedMobileToolbarsCapturePaintButtonsDownload onClick={onClickSave} />

      {/* Close */}

      <JoineddMobileToolbarsCapturePaintButtonsClose onClick={onClickExitEditingPic} />

      {/* -- */}
    </div>
  )
}
