const initModel: DN.Models.BcastDuration.Model = {
  kind: 'none',
  start: null,
  end: null,
  endReason: '',
}

// ~~~~~~

export const BcastDurationModel = {
  initModel,
}
