import { StorageKeys } from '../../../constants/storage'
import { UtilsEncDec, UtilsStorageLocal } from '@dn/utils'

// ~~~~~~ Types

type Data = {
  kind: 'sub' | 'pub'
  userContext?: DN.Services.TrackEvents.Contexts.BcastUser.v1_0_0.Context
  streamContext: DN.Services.TrackEvents.Contexts.BcastStream.v1_0_0.Context
  mins: number
}

// ####################################################################################################
// ~~~~~~ Get
// ####################################################################################################

const get: () => Data[] | undefined = () => {
  let data = UtilsStorageLocal.getItem(StorageKeys.StoreDuration)

  if (!data) return undefined

  data = UtilsEncDec.base64UrlDecode(data)

  let parsed: Data[] | undefined = undefined

  try {
    parsed = JSON.parse(data) as Data[]
  } catch (err) {}

  return parsed
}

// ####################################################################################################
// ~~~~~~ Set
// ####################################################################################################

const set = (data: Data) => {
  const stored = (get() || []).filter((storedData) => storedData.kind === data.kind)

  const stringifyed = JSON.stringify([data, ...stored])

  const encoded = UtilsEncDec.base64UrlEncode(stringifyed)

  UtilsStorageLocal.setItem(StorageKeys.StoreDuration, encoded)
}

// ####################################################################################################
// ~~~~~~ Clear
// ####################################################################################################

const clear = () => {
  UtilsStorageLocal.removeItem(StorageKeys.StoreDuration)
}

// ~~~~~~

export const LocalStorageStoreDuration = {
  get,
  set,
  clear,
} as const
