import {
  EMPTY,
  fromEvent,
  Observable,
  of,
  catchError,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
} from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'
import { Track } from 'livekit-client'
import { ConstWebRtc } from '@dn/constants'
import { ServiceWebRtc } from '@dn/webrtc'
import { ServiceBcast } from '@dn/bcast'
import { StoreState } from '../../../../models/app/model'
import { ExtensionService } from '../../../../services/extension'
import { cancelAll$$ } from '../../../../subjects/cancel-all/subject'
import { ApiBcastUserSettingsAC } from '../../../actions/api-bcast/user-settings/actions'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import { ShareMainStreamAC, ShareMainStreamAT } from '../../../actions/share-main-stream/actions'
import { ShareMainStream$$ } from '../subject'
import { EpicShareMainStreamGetScreenToSwitchMC } from './mutators'
import { BcastTrackEvents } from '../../../../services/track-events'
import { Config } from '../../../../config'
import { UtilsLog } from '../../../../utils/logs'

type Action = ReturnType<typeof ShareMainStreamAC.getStreamToSwitch>

export const shareMainStreamGetStreamToSwitchEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ShareMainStreamAT.GET_STREAM_TO_SWITCH),

    filter(
      () =>
        (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v2'),
    ),

    tap(() => {
      ExtensionService.SendMessage.ToExtension.setMeAsActive()
      ExtensionService.SendMessage.ToExtension.chooseSourceStateChanged('choosing')
    }),

    mergeMap(() => {
      const subscription = state$.value.subscription

      const extraConstraints: MediaTrackConstraints = {}

      // Quality

      return ServiceWebRtc.Media.Screen.getStream$(subscription.quality, extraConstraints).pipe(
        takeUntil(cancelAll$$),
        catchError(() => of(EpicShareMainStreamGetScreenToSwitchMC.error([]))),
      )
    }),

    mergeMap((errorOrStream) => {
      if ('error' in errorOrStream) return of(errorOrStream)

      const room = state$.value.dnBcast.room

      if (!ServiceBcast.Guards.isRoom(room)) return EMPTY

      const { canvasStreamStop } = state$.value.sharedMainStream

      const { localScreenTrack } = state$.value.dnBcast

      const { isOnboardingRunning, onboarding_create_a_bcast, onboarding_change_source } =
        state$.value.userSettings

      const currentUserId = state$.value.currentUser.id

      const newStream = errorOrStream

      const [newVideoTrack] = newStream.getVideoTracks()

      const screenLocalTrackPub = room.localParticipant.getTrackPublication(
        Track.Source.ScreenShare,
      )

      if (!screenLocalTrackPub) return EMPTY

      const localVideoTrack = screenLocalTrackPub.videoTrack

      if (!localVideoTrack) return EMPTY

      const obs$ = new Observable((observer) => {
        // -))
        observer.next(EpicShareMainStreamGetScreenToSwitchMC.start())

        // 250 ms of delay. Prevent popping when image appears to replace the video
        setTimeout(() => {
          // Bitrate experiments

          localVideoTrack
            .replaceTrack(newVideoTrack)
            .then(() => {
              // Update bitrate

              const videoSettings = newVideoTrack.getSettings()

              const { width, height } = videoSettings

              const h264Bandwidth = ServiceWebRtc.Codecs.calcH264Bandwidth({
                h264Profile: 'baseline',
                width: width || 0,
                height: height || 0,
                frameRate: state$.value.subscription.fps,
              })

              ServiceBcast.updateBitrate({ room, bitrate: h264Bandwidth, isBandwidth: true })

              // Onboarding change source

              if (isOnboardingRunning && onboarding_create_a_bcast && !onboarding_change_source) {
                observer.next(
                  ApiBcastUserSettingsAC.update(currentUserId, {
                    onboarding_change_source: true,
                  }),
                )

                // Track

                const userContext = BcastTrackEvents.helpers.genUserContext(
                  state$.value.currentUser,
                  state$.value.subscription,
                )

                BcastTrackEvents.calls.Onboarding['2-onboarding-change-source'](userContext)
              }

              // $-)) Stop listening old track (get-screen and get-screen-for-change)
              ShareMainStream$$.next({ type: 'replace-track' })

              // Free old tracks
              canvasStreamStop && canvasStreamStop()
              localScreenTrack?.stop()

              // If stream track ended (maybe user click on 'stop sharing' button)
              // dispatch cancel all, that also sends a disconnect message

              fromEvent(newVideoTrack, ConstWebRtc.Media.Track.Events.Ended)
                .pipe(
                  take(1),

                  takeUntil(cancelAll$$),
                  takeUntil(ShareMainStream$$.pipe(filter((msg) => msg.type === 'replace-track'))),

                  map(() => {
                    return CancelNowAC.cancelAll([CancelReasons.ScreenSharingEnded])
                  }),

                  catchError(() => {
                    return of(CancelNowAC.cancelAll([CancelReasons.ScreenStreamHasNotAMediaTrack]))
                  }),
                )
                .subscribe({
                  next: (cancelNowAction) => {
                    // -))-|
                    observer.next(cancelNowAction)
                    observer.complete()
                  },
                  complete: () => observer.complete(),
                })

              // -)) Emit new stream and new videoTrack
              observer.next(EpicShareMainStreamGetScreenToSwitchMC.ok(newStream, newVideoTrack))
            })
            .catch(() => {
              UtilsLog.devLog('GetScreenForChange$', 'replace-track-failed')
              // -X
              observer.error()
            })

          //
        }, 250)
      })

      return obs$
    }),

    tap(() => {
      ExtensionService.SendMessage.ToExtension.chooseSourceStateChanged('not-choosing')
    }),
  )
