import { ofType, StateObservable } from 'redux-observable'
import { Observable, zip, map, mergeMap, takeUntil } from 'rxjs'

import { StoreState } from '../../../../../models/app/model'
import { apiStreamManagerServerGet$ } from '../../../../../services/api-stream-manager/server/get'
import { cancelAll$$ } from '../../../../../subjects/cancel-all/subject'
import {
  ApiStreamManagerServerAC,
  ApiStreamManagerServerAT,
} from '../../../../actions/api-stream-manager/server/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiServerPubMainMC } from './mutators'
import { cachedApiStunTurnGetCredentials$ } from '../../../../../services/api-stun-turn/credentials/get/cached'

type Action = ReturnType<typeof ApiStreamManagerServerAC.getServerPubMain>

export const apiStreamManagerServerPubMainGetEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStreamManagerServerAT.GET_SERVER_PUB_MAIN),

    mergeMap(({ payload }) =>
      // On "cancel all" we abort the response, server/pub reducer uses
      // the cancel action from cancel-now to change its running state
      zip(
        apiStreamManagerServerGet$(payload.broadcastId, 'broadcast', 0),
        cachedApiStunTurnGetCredentials$,
      ).pipe(takeUntil(cancelAll$$)),
    ),

    map(([resServer, resCredentials]) =>
      ApiUtils.isSuccess(resServer)
        ? EpicApiServerPubMainMC.ok(resServer.response, resCredentials.response)
        : EpicApiServerPubMainMC.error(resServer.response),
    ),
  )
