import { Size } from 'paper/dist/paper-core'
import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'

// ~~~~~~ Types

type Data = {
  width: number
  height: number
}

// ~~~~~~

export const updateView = (scope: paper.PaperScope, { width, height }: Data) => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'updateView', { width, height })

  if (isNaN(width) || isNaN(height)) return

  const { width: curWidth, height: curHeight } = scope.view.viewSize

  if (curWidth === width && curHeight === height) return

  scope.view.viewSize = new Size(width, height)

  scope.project.activeLayer.fitBounds(scope.view.bounds)
}
