import { map, mergeMap, Observable } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'

import { StoreState } from '../../../../../models/app/model'
import { apiBcastSubscriptionsGetTime$ } from '../../../../../services/api-bcast/subscriptions/get-time'
import { ApiUtils } from '../../../utils'
import { EpicApiSubscriptionsGetTimeMC } from './mutators'
import {
  ApiBcastSubscriptionsAC,
  ApiBcastSubscriptionsAT,
} from '../../../../actions/api-bcast/subscription/actions'

type Action = ReturnType<typeof ApiBcastSubscriptionsAC.getTime>

export const apiStoreSubscriptionGetTimeEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastSubscriptionsAT.GET_TIME),
    mergeMap(({ payload }) =>
      apiBcastSubscriptionsGetTime$(
        state$.value.currentUser.token,
        payload.subscriptionId,
        state$.value.subscription.token,
      ),
    ),
    map((res) =>
      ApiUtils.isSuccess(res)
        ? EpicApiSubscriptionsGetTimeMC.ok(res.response)
        : EpicApiSubscriptionsGetTimeMC.error(res.status, res.response),
    ),
  )
