import { ClassName, STLayoutPanels } from './style'
import { LayoutPanelsContext } from './context/component'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../models/app/model'
import { useDispatch } from 'react-redux'
import { UiLayoutPanelsFieldsMR } from '../../../models/bcast/ui-layout-panels/model'
import { LazySVG } from '../../svgs/lazy-svg/component'

// ~~~~~~ Constants

const OpenLPanelIcon = LazySVG('icons/chevron-right')

// ~~~~~~ Types

type Props = {
  children: React.ReactNode
  disableManualOpenPanel?: true
  onClickOpenPanel?: () => void
}

// ~~~~~~ Component

export const LayoutPanels: React.FC<Props> = ({
  children,
  disableManualOpenPanel,
  onClickOpenPanel,
}) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const leftPanelOpenState = useSelector(
    (state: StoreState) => state.uiLayoutPanels.leftPanelOpenState,
  )

  // ~~~~~~ Handlers

  function onClickOpenPanelH() {
    onClickOpenPanel && onClickOpenPanel()

    dispatch(UiLayoutPanelsFieldsMR.savedNotPaintingLeftPanelOpenState.MC.change('open'))

    dispatch(UiLayoutPanelsFieldsMR.savedPaintingLeftPanelOpenState.MC.change('open'))

    dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('open'))
  }

  // ~~~~~~ Render

  return (
    <STLayoutPanels>
      {/* Left panel */}

      <div
        className={`${ClassName}--left-panel ${leftPanelOpenState === 'closed' ? 'hidden' : ''}`}
      >
        {/* - */}

        <LayoutPanelsContext.Provider value={{ kind: 'lpanel' }}>
          <div className={`${ClassName}--left-panel--inner`}>{children}</div>
        </LayoutPanelsContext.Provider>

        {/* - */}
      </div>

      {/* Left panel minimal (2px at left) */}

      <div
        className={`${ClassName}--left-panel-minimal ${
          leftPanelOpenState === 'closed' ? (disableManualOpenPanel ? 'only-space' : 'show') : ''
        }`}
      />

      {/* Middle: Header | Left panel opener (>) | Content */}

      <div className={`${ClassName}--middle`}>
        {/* Header */}

        <div className={`${ClassName}--middle--header`}>
          {/* - */}

          <LayoutPanelsContext.Provider value={{ kind: 'header' }}>
            <div className={`${ClassName}--middle--header--inner`}>{children}</div>
          </LayoutPanelsContext.Provider>

          {/* - */}
        </div>

        {/* Left panel opener (>) */}

        <div
          className={`${ClassName}--middle--opener ${
            leftPanelOpenState === 'closed' && !disableManualOpenPanel ? 'show' : ''
          }`}
        >
          <div className={`${ClassName}--middle--opener--content`} onClick={onClickOpenPanelH}>
            <OpenLPanelIcon size={16} />
          </div>
        </div>

        {/* Content */}

        <div className={`${ClassName}--middle--content`}>
          {/* - */}

          <LayoutPanelsContext.Provider value={{ kind: 'content' }}>
            <div className={`${ClassName}--middle--content--inner`}>{children}</div>
          </LayoutPanelsContext.Provider>

          {/* - */}
        </div>
      </div>
    </STLayoutPanels>
  )
}
