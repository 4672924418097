import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Contants

export const ClassName = 'user-menu-mobile-slide'

// ~~~~~~ Component

export const STUserMenuMobileSlide = styled.div`
  .${ClassName}--motion {
    width: 100%;
    height: 100%;

    position: absolute;
    z-index: 100;

    .${ClassName}--motion--content {
      ${Fonts.Scale.Body}
      ${Fonts.Weight[500]}

      background-color: ${theme(Colors.WOLF_00_90)};

      padding: 24px;

      width: calc(100% - ${24 * 2}px);
      height: calc(100% - ${24 * 2}px);

      .${ClassName}--motion--content--header {
        display: flex;
        justify-content: space-between;

        margin-bottom: 40px;

        .${ClassName}--motion--content--header--logo {
          svg {
            width: 32px;
            height: 32px;
          }
        }

        .${ClassName}--motion--content--header--close {
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: ${theme(Colors.WOLF_100_05)};
              stroke: none;
            }
          }
        }
      }

      .${ClassName}--motion--content--header-info {
        margin-bottom: 32px;

        .${ClassName}--motion--content--header-info--title {
          display: flex;
          align-items: center;

          .${ClassName}--motion--content--header-info--title--icon {
            display: flex;

            svg {
              width: 16px;
              height: 16px;

              path {
                fill: ${theme(Colors.WOLF_100_05)};
                stroke: none;
              }
            }
          }
        }

        .${ClassName}--motion--content--header-info--email {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .${ClassName}--motion--content--items {
        ${Layout.Border.Radius.B}

        padding-top: ${Layout.Spacing[2]}px;
        padding-bottom: ${Layout.Spacing[2]}px;

        background-color: ${theme(Colors.WOLF_00_90)};

        .${ClassName}--motion--content--items--item {
          cursor: pointer;

          display: flex;
          align-items: center;

          padding: 16px;

          &:hover {
            background-color: ${theme(Colors.WOLF_05_80)};
          }

          &.active {
            color: ${theme(Colors.EMMET_50_30)};

            .${ClassName}--motion--content--items--item--icon {
              svg {
                path:not(.svg-not-change) {
                  fill: ${theme(Colors.EMMET_50_30)};
                  stroke: none;
                }
              }
            }
          }

          .${ClassName}--motion--content--items--item--icon {
            margin-right: ${Layout.Spacing[2]}px;

            svg {
              display: flex;

              width: 20px;
              height: 20px;

              path:not(.svg-not-change) {
                fill: ${theme(Colors.WOLF_100_05)};
                stroke: none;
              }
            }
          }
        }
      }
    }
  }
`
