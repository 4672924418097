const prefix = 'mt-live-paint-replace-track-on-start-draw'

// ~~~~~~ Mutators Types

export const EpicLivePaintReplaceTrackOnStartDrawMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicLivePaintReplaceTrackOnStartDrawMC = {
  ok: (track: MediaStreamTrack, stop: () => void) => ({
    type: EpicLivePaintReplaceTrackOnStartDrawMT.OK,
    payload: {
      track,
      stop,
    },
  }),
} as const
