import { Config } from '../../../config'
import { ConstHttpVerbs } from '@dn/constants'

// ~~~~~~ [P]

const getPath = (path: string) => `https://${Config.Api.StreamManager.URI}${path}`

// ####################################################################################################
// ~~~~~~ Stream manager
// ####################################################################################################

// ~~~~~~ Server

const Server = {
  Get: {
    Method: ConstHttpVerbs.GET,
    Path: '/streammanager/api/4.0/event/live/:broadcastId?action=:action',
    getUrl: (broadcastId: string, action: DN.Models.StreamManagerServer.Action) =>
      getPath(Server.Get.Path).replace(':broadcastId', broadcastId).replace(':action', action),
  },
}

// ~~~~~~

export const ApiStreamManagerRoutes = {
  Server,
}
