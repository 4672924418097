import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../../models/app/model'
import { DrawService } from '../../../../../../services/draw'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../../actions/live-paint/draw/actions'

import { EpicLivePaintDrawCommandMC } from '../mutators'

type Action = ReturnType<typeof LivePaintDrawAC.cmdClear>

export const livePaintDrawCommandsClearEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.CMD_CLEAR),

    mergeMap(() => {
      const { lastCmd } = state$.value.livePaintDraw

      if (lastCmd === 'clear') return EMPTY

      DrawService.Commands.clear()

      return of(EpicLivePaintDrawCommandMC.clear())
    }),
  )
