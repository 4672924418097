import { timer, Observable, merge, takeUntil, take, tap, throwError, mergeMap, share } from 'rxjs'
import { Config } from '../../../../../config'
import { UtilsLog } from '../../../../../utils/logs'
import { UtilsObservables } from '@dn/utils'

type Message = DN.Services.Extension.ToExtension.AskForExtension

type BackgroundResponse = DN.Services.Extension.FromExtension.AskForExtension

type CheckResponse = { installed: true }

type TimoutResponse = { timeout: true }

type Response = CheckResponse | TimoutResponse

export const sendMessageToExtensionCheckExtension$ = () => {
  //

  const timeout$ = timer(500).pipe(
    mergeMap(() => {
      const response: TimoutResponse = { timeout: true }

      return throwError(() => response)
    }),
    share(),
  )

  const obs$ = new Observable<Response>((observer) => {
    if (
      Config.Extension.Id === 'no-extension-id' ||
      !window.chrome ||
      !window.chrome.runtime ||
      !window.chrome.runtime.sendMessage
    ) {
      observer.error({ installed: false, extensionId: Config.Extension.Id })
      return
    }

    const message: Message = {
      type: 'is-extension-installed?',
    }

    const callback = (res?: BackgroundResponse) => {
      if (!res) {
        UtilsLog.devLog('extension error:', chrome.runtime.lastError)

        observer.error({ installed: false })
        return
      }

      observer.next({ installed: true })
      observer.complete()
    }

    window.chrome.runtime.sendMessage(Config.Extension.Id, message, callback)
  })

  const response$: Observable<Response> = merge(obs$.pipe(takeUntil(timeout$)), timeout$).pipe(
    take(1),
    tap(UtilsObservables.tapLogObserver('CheckExtension')),
  )

  return response$
}
