const prefix = 'mt-api-bcast-providers-link-google-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastProvidersLinkGoogleMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastProvidersLinkGoogleMC = {
  ok: (data: DN.Models.Provider.Api.LinkGoogle.ResParsed) => ({
    type: EpicApiBcastProvidersLinkGoogleMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.Provider.Api.LinkGoogle.ResParsedError) => ({
    type: EpicApiBcastProvidersLinkGoogleMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
