import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { Trans } from '../../../intl/trans'
import { ClassName, STOnboardingPanelDone } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/tick')

// ~~~~~~ Component

export const OnboardingPanelDone = () => {
  // ~~~~~~ Render

  return (
    <STOnboardingPanelDone>
      {/* Icon */}

      <div className={`${ClassName}--icon`}>
        <Icon size={16} />
      </div>

      {/* Text */}

      <Trans id="common-components.onboarding.main-panel.options.Done" />

      {/* -- */}
    </STOnboardingPanelDone>
  )
}
