import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { HorizontalGridLines, XAxis, XYPlot, YAxis, LineSeries, Crosshair } from 'react-vis'

import { StoreState } from '../../../models/app/model'
import { Bronx } from '../../../style/theme/colors/bronx'
import { Wolf } from '../../../style/theme/colors/wolf'
import { STCrosshair, STStatsGraph } from './style'

import 'react-vis/dist/style.css'

// ~~~~~~ Constants

const yDomain = [0, 60]

const FPSTickValues = [20, 40, 60, 80, 100]

const axisStyle = {
  text: { fill: '#a3b3c2', stroke: 'transparent' },
  ticks: { stroke: '#a3b3c2', strokeWidth: '3px' },
  line: { stroke: '#a3b3c2', strokeWidth: '2px' },
}

// ~~~~~~ Types

type Point = {
  x: number
  y: number
}

type ExtraPoint = Point & { stat: DN.Utils.PeerConnStats.Info }

// ~~~~~~ Component

export const StatsGraph: React.FC<{}> = () => {
  // ~~~~~~ State

  const [crosshairData, setCrosshairData] = useState<any>([])

  const stats = useSelector((state: StoreState) => state.stats)

  // ~~~~~~ Computed

  const data: ExtraPoint[] = stats.stats.map((stat) => ({
    x: stat.inboundInfo.time,
    y: stat.inboundInfo.fps,
    stat,
  }))

  const dataStart: Point = data[0] || { x: 0, y: 0 }
  const dataEnd: Point = data.slice(-1)[0] || { x: 0, y: 0 }

  const xDomain = [dataStart.x, dataEnd.x] as const

  // ~~~~~~ Handlers

  function onNearestX(data: any) {
    setCrosshairData([data])
  }

  // ~~~~~~ Render

  return (
    <STStatsGraph>
      <XYPlot
        width={620}
        height={235}
        yDomain={yDomain}
        xDomain={xDomain}
        onMouseLeave={() => crosshairData.length && setCrosshairData([])}
      >
        {/* Horizontal separators */}

        <HorizontalGridLines style={{ stroke: Wolf[5], strokeWidth: '1px' }} />

        {/* Y */}

        <YAxis
          tickValues={FPSTickValues}
          style={{ ...axisStyle, fontSize: 12 }}
          tickSizeInner={0}
        />

        {/* X */}

        <XAxis style={{ ...axisStyle, fontSize: 12 }} tickSizeInner={0} />

        {/* Data */}

        <LineSeries
          data={data}
          curve="curveCatmullRom"
          style={{ stroke: Bronx[50], fill: 'transparent' }}
          animation="true"
          onNearestX={onNearestX}
        />

        {/* Crosshair */}

        {crosshairData.length ? (
          <Crosshair values={crosshairData}>
            <STCrosshair>
              {typeof crosshairData[0] === 'object' && crosshairData[0].stat ? (
                <div>
                  <div>{crosshairData[0].stat.inboundInfo.fps} FPS</div>
                  <div>{crosshairData[0].stat.inboundInfo.bitrate} kbps</div>
                </div>
              ) : (
                <div>No data</div>
              )}
            </STCrosshair>
          </Crosshair>
        ) : undefined}
      </XYPlot>
    </STStatsGraph>
  )
}
