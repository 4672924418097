const prefix = 'at-api-bcast-subscriptions'

// ~~~~~~ Action Types

export const ApiBcastSubscriptionsAT = {
  GET_TIME: `${prefix}-get-time`,

  SHOW: `${prefix}-show`,
} as const

// ~~~~~~ Action Creators

export const ApiBcastSubscriptionsAC = {
  getTime: (subscriptionId: string) => ({
    type: ApiBcastSubscriptionsAT.GET_TIME,
    payload: {
      subscriptionId,
    },
  }),

  show: () => ({
    type: ApiBcastSubscriptionsAT.SHOW,
  }),
} as const
