import { AppInitState } from '../../../../models/app/model'
import { CurrentUserEditFieldsMR } from '../../../../models/bcast/current-user/model'
import { UtilsStore } from '@dn/utils'
import { AuthMT } from '../../../actions-mutators/auth/mutators'
import {
  ApiBcastCurrentUserAC,
  ApiBcastCurrentUserAT,
} from '../../../actions/api-bcast/current-user/actions'
import {
  EpicApiBcastCurrentUserShowMC,
  EpicApiBcastCurrentUserShowMT,
} from '../../../epics/api-bcast/current-user/show/mutators'
import {
  EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC,
  EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT,
} from '../../../epics/api-bcast/google-classroom/announce-bcast-link/mutators'
import {
  EpicApiBcastGoogleClassRoomAnnounceCaptureMC,
  EpicApiBcastGoogleClassRoomAnnounceCaptureMT,
} from '../../../epics/api-bcast/google-classroom/announce-capture/mutators'
import {
  EpicApiBcastGoogleSlidesAddImageSlideMC,
  EpicApiBcastGoogleSlidesAddImageSlideMT,
} from '../../../epics/api-bcast/google-slides/add-image-slide/mutators'
import {
  EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC,
  EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT,
} from '../../../epics/api-bcast/google-slides/create-presentation-and-add-image-slide/mutators'
import {
  EpicApiBcastGoogleSlidesCreatePresentationMC,
  EpicApiBcastGoogleSlidesCreatePresentationMT,
} from '../../../epics/api-bcast/google-slides/create-presentation/mutators'
import { EpicApiBcastProvidersUnlinkGoogleMT } from '../../../epics/api-bcast/providers/unlink-google/mutators'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'
import { EpicApiBcastGoogleClassRoomListMT } from '../../../epics/api-bcast/google-classroom/list/mutators'
import { EpicApiBcastGoogleSlidesListPresentationsMT } from '../../../epics/api-bcast/google-slides/list-presentations/mutators'
import {
  EpicExtensionUserDataMC,
  EpicExtensionUserDataMT,
} from '../../../epics/extension/user-data/mutators'

const initState = AppInitState.currentUser

type State = typeof initState

const reducer: any = {
  ...CurrentUserEditFieldsMR.lastSelectedGoogleClassRoom.Reducer,
  ...CurrentUserEditFieldsMR.lastSelectedGoogleClassRoom.Reducer,
  ...CurrentUserEditFieldsMR.googleAccessToken.Reducer,
  ...CurrentUserEditFieldsMR.googleAccessTokenExpiresAt.Reducer,
}

// ####################################################################################################
// ~~~~~~ Extension user data
// ####################################################################################################

reducer[EpicExtensionUserDataMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicExtensionUserDataMC.ok>,
): State => {
  return {
    ...state,
    ...payload.currentUser,

    uiStatus: 'completed',
    isSessionChecked: true,

    lastSelectedGoogleClassRoom: initState.lastSelectedGoogleClassRoom,
    lastSelectedGoogleSlide: initState.lastSelectedGoogleSlide,
    googleAccessToken: initState.googleAccessToken,
    googleAccessTokenExpiresAt: initState.googleAccessTokenExpiresAt,
  }
}

// ####################################################################################################
// ~~~~~~ Get token - START
// ####################################################################################################

reducer[AuthMT.GET_TOKEN_START] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Get token - OK
// ####################################################################################################

// Not exists because if ok we trigger current user Show

// ####################################################################################################
// ~~~~~~ Get token - ERROR
// ####################################################################################################

reducer[AuthMT.GET_TOKEN_ERROR] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
    isSessionChecked: true,
  }
}

// ####################################################################################################
// ~~~~~~ Set session checked
// ####################################################################################################

reducer[AuthMT.SET_SESSION_CHECKED] = (state: State): State => {
  return {
    ...state,
    isSessionChecked: true,
  }
}

// ####################################################################################################
// ~~~~~~ Show - START (call to show)
// ####################################################################################################

reducer[ApiBcastCurrentUserAT.SHOW] = (
  state: State,
  { payload }: ReturnType<typeof ApiBcastCurrentUserAC.show>,
): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Show - OK
// ####################################################################################################

reducer[EpicApiBcastCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastCurrentUserShowMC.ok>,
): State => {
  return {
    ...state,
    ...payload.user,

    uiStatus: 'completed',
    isSessionChecked: true,

    lastSelectedGoogleClassRoom: initState.lastSelectedGoogleClassRoom,
    lastSelectedGoogleSlide: initState.lastSelectedGoogleSlide,
    googleAccessToken: initState.googleAccessToken,
    googleAccessTokenExpiresAt: initState.googleAccessTokenExpiresAt,
  }
}

// ####################################################################################################
// ~~~~~~ Show - ERROR
// ####################################################################################################

reducer[EpicApiBcastCurrentUserShowMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastCurrentUserShowMC.error>,
): State => {
  return {
    ...state,
    errors: payload.errors,
    uiStatus: 'init',
    isSessionChecked: true,
    // NOTE: Do not add errors, or you can experiment a continuous redirection
    // Errors are showed in a toast
  }
}

// ####################################################################################################
// ~~~~~~ Google Create Announcement (bcast link) - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC.ok>,
): State => {
  return {
    ...state,
    lastSelectedGoogleClassRoom: payload.course,
  }
}

// ####################################################################################################
// ~~~~~~ Google Create Announcement (capture) - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomAnnounceCaptureMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastGoogleClassRoomAnnounceCaptureMC.ok>,
): State => {
  return {
    ...state,
    lastSelectedGoogleClassRoom: payload.course,
  }
}

// ####################################################################################################
// ~~~~~~ Google Slide Create Presentation - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastGoogleSlidesCreatePresentationMC.ok>,
): State => {
  return {
    ...state,

    lastSelectedGoogleSlide: {
      id: payload.id,
      title: payload.title,
    },
  }
}

// ####################################################################################################
// ~~~~~~ Google Slide Add image to slide - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesAddImageSlideMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastGoogleSlidesAddImageSlideMC.ok>,
): State => {
  return {
    ...state,

    lastSelectedGoogleSlide: {
      id: payload.id,
      title: payload.title,
    },
  }
}

// ####################################################################################################
// ~~~~~~ Google Slide Create and Add image to slide - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC.ok>,
): State => {
  return {
    ...state,

    lastSelectedGoogleSlide: {
      id: payload.id,
      title: payload.title,
    },
  }
}

// ####################################################################################################
// ~~~~~~ Unlink Google - OK
// ####################################################################################################

reducer[EpicApiBcastProvidersUnlinkGoogleMT.OK] = (state: State): State => {
  return {
    ...state,
    lastSelectedGoogleClassRoom: initState.lastSelectedGoogleClassRoom,
    lastSelectedGoogleSlide: initState.lastSelectedGoogleSlide,
  }
}

// ####################################################################################################
// ~~~~~~ Google Classroom announce link - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT.ERROR] = (state: State): State => {
  return {
    ...state,
    googleAccessToken: '',
  }
}

// ####################################################################################################
// ~~~~~~ Google Classroom announce capture - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomAnnounceCaptureMT.ERROR] = (state: State): State => {
  return {
    ...state,
    googleAccessToken: '',
  }
}

// ####################################################################################################
// ~~~~~~ Google Classroom list - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomListMT.ERROR] = (state: State): State => {
  return {
    ...state,
    googleAccessToken: '',
  }
}

// ####################################################################################################
// ~~~~~~ Google Slides add image slide - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesAddImageSlideMT.ERROR] = (state: State): State => {
  return {
    ...state,
    googleAccessToken: '',
  }
}

// ####################################################################################################
// ~~~~~~ Google Slides create presentation - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationMT.ERROR] = (state: State): State => {
  return {
    ...state,
    googleAccessToken: '',
  }
}

// ####################################################################################################
// ~~~~~~ Google Slides create presentation and add image - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.ERROR] = (
  state: State,
): State => {
  return {
    ...state,
    googleAccessToken: '',
  }
}

// ####################################################################################################
// ~~~~~~ Google Slides list presentations - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesListPresentationsMT.ERROR] = (state: State): State => {
  return {
    ...state,
    googleAccessToken: '',
  }
}

// ####################################################################################################
// ~~~~~~ Cancel now all - DONE
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return {
    ...state,
    lastSelectedGoogleClassRoom: initState.lastSelectedGoogleClassRoom,
    lastSelectedGoogleSlide: initState.lastSelectedGoogleSlide,
  }
}

// ~~~~~~

export const currentUserReducer = UtilsStore.dynReducer(initState, reducer)
