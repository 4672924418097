export const SmoothType = {
  CONTINUOUS: 'continuous',
  ASYMEMETRIC: 'asymmetric',
  CATMULL_ROM: 'catmull-rom',
  GEOMETRIC: 'geometric',
} as const

// https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
export const HexAlpha = {
  100: 'FF',
  90: 'E6',
  80: 'CC',
  70: 'B3',
  60: '99',
  50: '80',
  40: '66',
  30: '4D',
  20: '33',
  10: '1A',
  0: '00',
} as const
