import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Transition } from '../../../../style/animations/css-animations'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'onboarding-button'

// ~~~~~~ Component

export const STMotionOnboardingButton = styled(motion.div)`
  cursor: pointer;

  &.no-pointer {
    cursor: default;
  }

  position: absolute;
  z-index: 50;

  bottom: 24px;
  right: 24px;

  height: 46px;

  padding: 2px 16px 2px 8px;

  border-radius: 40px;

  color: ${theme(Colors.WOLF_00_10)};

  background-color: ${theme(Colors.EMMET_50_80)};

  ${Transition.BackgroundColorFaster}

  display: flex;
  align-items: center;

  &:hover:not(&.no-pointer) {
    background-color: ${theme(Colors.EMMET_40_70)};

    .${ClassName}--button {
      background-color: ${theme(Colors.EMMET_30_60)};
    }
  }

  .${ClassName}--button {
    ${Fonts.Weight[900]}

    font-size: 22px;

    width: 34px;
    height: 34px;

    border-radius: 50%;

    ${Transition.BackgroundColorFaster}

    background-color: ${theme(Colors.EMMET_40_70)};

    margin-right: ${Layout.Spacing[2]}px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`
