import { applyMiddleware, combineReducers, compose, legacy_createStore, Store } from 'redux'
import { composeWithDevTools, EnhancerOptions } from '@redux-devtools/extension'
import { createEpicMiddleware } from 'redux-observable'

import { Config } from '../config'
import reducers from './reducers'
import epics from './epics'
import { UtilsLog } from '../utils/logs'
import { UtilsCycle, UtilsStore } from '@dn/utils'

const anyCompose: any = compose

const epicMiddleware = createEpicMiddleware()

const middleware = applyMiddleware(epicMiddleware)

const identity = <T>(data: T): T => data

let store: Store

export const getStore = (opts?: { test?: true }): any => {
  if (store) return store

  const devToolsComposeOpts: EnhancerOptions = {
    maxAge: 500,

    serialize: false,

    actionSanitizer: (action) => UtilsCycle.decycle(action),

    stateSanitizer: (state) => UtilsCycle.decycle(state),
  }

  const mustAddDevTools = Config.Features.Debug

  const combinedReducers = combineReducers(reducers)

  const preloadedState = {}

  store = mustAddDevTools
    ? legacy_createStore(
        combinedReducers,
        preloadedState,
        anyCompose(
          composeWithDevTools(devToolsComposeOpts)(middleware),
          opts?.test ? UtilsStore.reduxSpy.storeSpy : identity,
        ),
      )
    : legacy_createStore(
        combinedReducers,
        preloadedState,
        anyCompose(middleware, opts?.test ? UtilsStore.reduxSpy.storeSpy : identity),
      )

  epicMiddleware.run(epics)

  if (mustAddDevTools && global.window) {
    UtilsLog.devInfo(
      String.fromCodePoint(0x001f41e),
      'Using debug store',
      String.fromCodePoint(0x001f41e),
    )

    // Initial state
    ;(window as any).state = store.getState()

    // Keep state updated

    store.subscribe(() => {
      ;(window as any).state = store.getState()
    })
  }

  return store
}
