import { ServiceTrackEvents } from '@dn/track-events'
import { trackEvents } from '../constants'

// ~~~~~~ Types

type Context = DN.Services.TrackEvents.Context

type Contexts = Context[]

type UserContext = DN.Services.TrackEvents.Contexts.BcastUser.v1_0_0.Context

type StreamContext = DN.Services.TrackEvents.Contexts.BcastStream.v1_0_0.Context

type Duration = Partial<{
  hours: number
  mins: number
  secs: number
}>

// ~~~~~~ Calls

export const trackCalls = {
  Page: {
    view: (page: string, contexts?: Contexts) => {
      ServiceTrackEvents.Events.v1_0_0.sendPage(page, contexts)
    },
  },

  Onboarding: {
    '1-onboarding-create-a-bcast': (userContext: UserContext, contexts: Contexts = []) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(
        trackEvents.Onboarding['2-onboarding-create-a-bcast'],
        [userContext, ...contexts],
      )
    },

    '2-onboarding-change-source': (userContext: UserContext, contexts: Contexts = []) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(
        trackEvents.Onboarding['1-onboarding-change-source'],
        [userContext, ...contexts],
      )
    },

    '3-onboarding-share': (userContext: UserContext, contexts: Contexts = []) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Onboarding['4-onboarding-share'], [
        userContext,
        ...contexts,
      ])
    },

    '4-onboarding-live-draw': (userContext: UserContext, contexts: Contexts = []) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(
        trackEvents.Onboarding['3-onboarding-live-draw'],
        [userContext, ...contexts],
      )
    },
  },

  BuyProcess: {
    Free: {
      '1-select-plan-free': (contexts?: Contexts) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Free['1-select-plan-free'],
          contexts,
        )
      },

      '2-create-account': (contexts?: Contexts) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Free['2-create-account'],
          contexts,
        )
      },

      '3-verify-email': (contexts?: Contexts) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Free['3-verify-email'],
          contexts,
        )
      },

      '4-create-success': (contexts?: Contexts) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Free['4-create-success'],
          contexts,
        )
      },
    },

    Premium: {
      '1-select-plan-premium': (contexts?: Contexts) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Premium['1-select-plan-premium'],
          contexts,
        )
      },

      '2-create-account': (contexts?: Contexts) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Premium['2-create-account'],
          contexts,
        )
      },

      '3-verify-email': (contexts?: Contexts) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Premium['3-verify-email'],
          contexts,
        )
      },

      '4-create-success': (contexts?: Contexts) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Premium['4-create-success'],
          contexts,
        )
      },
    },

    Upgrade: {
      '1-select-plan-upgrade': (userContext: UserContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Upgrade['1-select-plan-upgrade'],
          [userContext, ...contexts],
        )
      },

      '2-confirm-plan': (userContext: UserContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Upgrade['2-confirm-plan'],
          [userContext, ...contexts],
        )
      },

      '3-add-card': (userContext: UserContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Upgrade['3-add-card'],
          [userContext, ...contexts],
        )
      },

      '4-upgrade-success': (userContext: UserContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendBuyProcess(
          trackEvents.BuyProcess.Upgrade['4-upgrade-success'],
          [userContext, ...contexts],
        )
      },
    },
  },

  JoinKind: {
    input: (contexts?: Contexts) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.JoinKind.Input, contexts)
    },

    bcastLink: (contexts?: Contexts) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.JoinKind.BcastLink, contexts)
    },

    croomLink: (contexts?: Contexts) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.JoinKind.CroomLink, contexts)
    },
  },

  Streams: {
    PubMain: {
      stream: (userContext: UserContext, streamContext: StreamContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Streams.PubMain.Stream, [
          userContext,
          streamContext,
          ...contexts,
        ])
      },

      duration: (
        duration: Duration,
        userContext: UserContext,
        streamContext: StreamContext,
        contexts: Contexts = [],
      ) => {
        ServiceTrackEvents.Events.v1_0_0.sendDuration(
          trackEvents.Streams.PubMain.Duration,
          duration,
          [userContext, streamContext, ...contexts],
        )
      },
    },

    PubCamMic: {
      stream: (userContext: UserContext, streamContext: StreamContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Streams.PubCamMic.Stream, [
          userContext,
          streamContext,
          ...contexts,
        ])
      },
    },

    SubMain: {
      stream: (streamContext: StreamContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Streams.SubMain.Stream, [
          streamContext,
          ...contexts,
        ])
      },

      duration: (duration: Duration, streamContext: StreamContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendDuration(
          trackEvents.Streams.SubMain.Duration,
          duration,
          [streamContext, ...contexts],
        )
      },
    },
  },

  PaintStart: {
    bcaster: (userContext: UserContext, streamContext: StreamContext, contexts: Contexts = []) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.PaintStart.Bcaster, [
        userContext,
        streamContext,
        ...contexts,
      ])
    },

    viewer: (streamContext: StreamContext, contexts: Contexts = []) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.PaintStart.Viewer, [
        streamContext,
        ...contexts,
      ])
    },
  },

  Google: {
    Bcaster: {
      connect: (
        userContext: UserContext,
        streamContext: StreamContext,
        contexts: Contexts = [],
      ) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Google.Bcaster.Connect, [
          userContext,
          streamContext,
          ...contexts,
        ])
      },

      disconnect: (
        userContext: UserContext,
        streamContext: StreamContext,
        contexts: Contexts = [],
      ) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Google.Bcaster.Disconnect, [
          userContext,
          streamContext,
          ...contexts,
        ])
      },

      shareSlideImage: (
        userContext: UserContext,
        streamContext: StreamContext,
        contexts: Contexts = [],
      ) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Google.Bcaster.ShareSlideImage, [
          userContext,
          streamContext,
          ...contexts,
        ])
      },

      shareCroomImage: (
        userContext: UserContext,
        streamContext: StreamContext,
        contexts: Contexts = [],
      ) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Google.Bcaster.ShareCroomImage, [
          userContext,
          streamContext,
          ...contexts,
        ])
      },

      shareCroomLink: (
        userContext: UserContext,
        streamContext: StreamContext,
        contexts: Contexts = [],
      ) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Google.Bcaster.ShareCroomLink, [
          userContext,
          streamContext,
          ...contexts,
        ])
      },
    },

    Viewer: {
      connect: (streamContext: StreamContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Google.Viewer.Connect, [
          streamContext,
          ...contexts,
        ])
      },

      shareSlideImage: (streamContext: StreamContext, contexts: Contexts = []) => {
        ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Google.Viewer.ShareSlideImage, [
          streamContext,
          ...contexts,
        ])
      },
    },
  },

  Pip: {
    bcaster: (userContext: UserContext, streamContext: StreamContext, contexts: Contexts = []) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Pip.Bcaster, [
        userContext,
        streamContext,
        ...contexts,
      ])
    },

    viewer: (streamContext: StreamContext, contexts: Contexts = []) => {
      ServiceTrackEvents.Events.v1_0_0.sendAction(trackEvents.Pip.Viewer, [
        streamContext,
        ...contexts,
      ])
    },
  },
} as const
