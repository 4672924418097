import { Observable, filter, map } from 'rxjs'
import {
  EpicShareMainStreamGetStreamMC,
  EpicShareMainStreamGetStreamMT,
} from '../../share-main-stream/get-stream/mutators'
import { StateObservable, ofType } from 'redux-observable'
import { StoreState } from '../../../../models/app/model'
import { ApiBcastRoomAC } from '../../../actions/api-bcast/room/actions'
import { Config } from '../../../../config'
import { UtilsString } from '@dn/utils'
import { ConstBcast } from '../../../../constants/bcast'

type Action = ReturnType<typeof EpicShareMainStreamGetStreamMC.ok>

export const chainingFromGetStreamToRoomForPub$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicShareMainStreamGetStreamMT.OK),

    filter(() => Config.Features.BcastService2),

    map(() => {
      // Gen broadcast ID

      const bcastId = UtilsString.genRandomStr(ConstBcast.IdLength, {
        symbols: false,
        numbers: true,
        letters: false,
      })

      return ApiBcastRoomAC.forPub(bcastId)
    }),
  )
