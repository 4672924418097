import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'
import { UtilsString } from '@dn/utils'

const RandomLength = 4

export const genUser = (name: string, kind: 'sub' | 'pub', extra?: string) => {
  Config.Logs.BroadcastService && UtilsLog.devLog('BCastService', 'genUser', '\n- name\n', name)

  const hexStr = UtilsString.genRandomStr(RandomLength, {
    symbols: false,
    numbers: true,
    letters: true,
    onlyHexLetters: false,
  })

  return `${name}-${kind}-${hexStr}${extra ? `-${extra}` : ''}`
}
