import { useSelector } from 'react-redux'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../../models/app/model'
import { ClassName, STStartedContentMainVideoBadge } from './style'

// ~~~~~~ Constants

const PenIcon = LazySVG('icons/tool-pen')

// ~~~~~~ Props

type Props = {
  className?: string
}

// ~~~~~~ Component

export const StartedContentMainVideoBadge: React.FC<Props> = ({ className }) => {
  // ~~~~~~ State

  const livePaintEnabled = useSelector((state: StoreState) => state.livePaint.enabled)

  // ~~~~~~ Render

  return (
    <STStartedContentMainVideoBadge className={className}>
      {livePaintEnabled ? (
        <div>
          <PenIcon className={`${ClassName}--icon`} size={12} />{' '}
        </div>
      ) : undefined}

      <div>LIVE</div>
    </STStartedContentMainVideoBadge>
  )
}
