import { StateObservable, ofType } from 'redux-observable'
import { catchError, EMPTY, map, mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../../../../models/app/model'
import { CommChannelService } from '../../../../../../../../services/comm-channel'
import { CommChannelAC, CommChannelAT } from '../../../../../../../actions/comm-channel/actions'
import { EpicBcastCommChannelMessagesToServerBcasterCamDisableMC } from './mutators'
import { UtilsLog } from '../../../../../../../../utils/logs'
import { CancelNowAC, CancelReasons } from '../../../../../../../actions/cancel-now/actions'

type Action = ReturnType<typeof CommChannelAC.sendBcasterCamDisable>

export const commChannelMessagesToServerBcasterCamDisableEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(CommChannelAT.SEND_BCASTER_CAM_DISABLE),

    mergeMap(() => {
      const websocket = state$.value.commChannel.webSocket

      if (!websocket) return EMPTY

      return CommChannelService.SendMessage.WithAck.BcasterCamTrack.Disable$(websocket).pipe(
        catchError((err: any) => {
          UtilsLog.devLog(
            'BcasterCamTrack.Disable',
            'Error. WSState:',
            state$.value.commChannel.webSocket?.readyState,
          )

          return of({
            type: 'err',
          } as const)
        }),
      )
    }),

    map(({ type }) => {
      switch (type) {
        case 'ack':
          return EpicBcastCommChannelMessagesToServerBcasterCamDisableMC.ok()

        case 'ack-timeout':
          return EpicBcastCommChannelMessagesToServerBcasterCamDisableMC.timeout()

        case 'err':
          return CancelNowAC.cancelAll([CancelReasons.CommChannelErr])
      }
    }),
  )
