import { Link } from 'react-router-dom'
import { Config } from '../../../config'
import { Routes } from '../../../constants/routes/routes'
import { DangerTrans } from '../intl/danger-trans'
import { STFooter } from './style'

// ~~~~~~ Types

type Props = {
  forceTheme?: Theme
}

// ~~~~~~ Component

export const Footer: React.FC<Props> = ({ forceTheme }) => {
  // ~~~~~~ Render

  return (
    <STFooter $forceTheme={forceTheme}>
      <DangerTrans
        template="<c>Cookies</> ... <d>Privacy Policy</d> and ... <e>General Use Policy</e> | <f>Support</f>"
        id="common-components.footer.Info"
        values={{
          // Cookies

          c: (chunks: string) => <Link to={Routes.Cookies}>{chunks}</Link>,

          // Privacy

          d: (chunks: string) => <Link to={Routes.Privacy}>{chunks}</Link>,

          // Terms

          e: (chunks: string) => <Link to={Routes.Terms}>{chunks}</Link>,

          // Support

          f: (chunks: string) => (
            <a href={Config.Links.SupportInfo} className="support" rel="noreferrer" target="_blank">
              {chunks}
            </a>
          ),
        }}
      />
    </STFooter>
  )
}
