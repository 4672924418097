import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { BroadcastDurationMT } from '../../../actions-mutators/broadcast-duration/mutators'
import { EpicBcastStartPubMainMT } from '../../../epics/bcast/start-pub-main/mutators'
import { EpicBcastStartSubMainMT } from '../../../epics/bcast/start-sub-main/mutators'
import { EpicCancelNowAllMC, EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'

const initState = AppInitState.bcastDuration

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Start pub
// ####################################################################################################

reducer[EpicBcastStartPubMainMT.OK] = (state: State): State => {
  return {
    kind: 'pub',
    start: new Date(),
    end: null,
    endReason: '',
  }
}

// ####################################################################################################
// ~~~~~~ Start sub
// ####################################################################################################

reducer[EpicBcastStartSubMainMT.OK] = (state: State): State => {
  return {
    kind: 'sub',
    start: new Date(),
    end: null,
    endReason: '',
  }
}

// ####################################################################################################
// ~~~~~~ Restart
// ####################################################################################################

reducer[BroadcastDurationMT.CLEAR] = () => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Cancel now all - DONE
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (
  state: State,
  { payload }: ReturnType<typeof EpicCancelNowAllMC.done>,
): State => {
  return {
    ...state,
    end: new Date(),
    endReason: payload[0],
  }
}

// ~~~~~~

export const bcastDurationReducer = UtilsStore.dynReducer(initState, reducer)
