import { JoinedMobileFloatPanelCaptureShareSlides } from './slides/component'
import { ClassName, STJoinedMobileFloatPanelCaptureShare } from './style'

// ~~~~~~ Component

export const JoinedMobileFloatPanelCaptureShare = () => {
  // ~~~~~~ Render

  return (
    <STJoinedMobileFloatPanelCaptureShare>
      <div className={`${ClassName}--content`}>
        {/* Slides */}

        <div className={`${ClassName}--content--slides-card`}>
          <JoinedMobileFloatPanelCaptureShareSlides />
        </div>

        {/* -- */}
      </div>
    </STJoinedMobileFloatPanelCaptureShare>
  )
}
