import { HooksBounds } from '@dn/hooks'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../../models/app/model'
import { Layout } from '../../../../../../style/theme/layout'

// ~~~~~~ Constants

const showX = {
  x: 0,
  y: 0,
  opacity: 1,
} as const

const hideX = {
  x: -20,
  y: 0,
  opacity: 0,
} as const

const showY = {
  x: 0,
  y: 0,
  opacity: 1,
} as const

const hideY = {
  x: 0,
  y: 20,
  opacity: 0,
} as const

const SmallerSideSize = 56

const IconSize = SmallerSideSize

const MiddleIcon = IconSize / 2

const ToolbarMargins = 48

const Padding = Layout.Spacing[1] * 2

//  - Main

const IconsNumber = 9

const AllToolsBiggerSideSize = IconsNumber * IconSize + Padding

// - Pens

const PensIconsNumber = 3

const OnlyPensSize = PensIconsNumber * IconSize + Padding

// - Colors

const ColorsIconsNumber = 7

const OnlyColorsSize = ColorsIconsNumber * IconSize + Padding

// ~~~~~~ Hook

export const useJoinedMobileToolbarsCapturePaintHooksResizeAnimations = (
  showOnlyPens: boolean,
  showOnlyColors: boolean,
) => {
  // ~~~~~~ Hooks

  const sizeData = HooksBounds.CheckPortrait.useHook()

  // ~~~~~~ State

  const { pageWidth, pageHeight } = useSelector((state: StoreState) => state.theme)

  // ~~~~~~ Computed

  const maxWidth = pageWidth || 0

  const maxHeight = pageHeight || 0

  // - All tools

  const biggerSideSizePortrait =
    AllToolsBiggerSideSize > maxHeight
      ? maxHeight - ToolbarMargins - MiddleIcon
      : AllToolsBiggerSideSize

  const biggerSideSizeLandscape =
    AllToolsBiggerSideSize > maxWidth
      ? maxWidth - ToolbarMargins - MiddleIcon
      : AllToolsBiggerSideSize

  const hasScroll = sizeData.isPortrait
    ? AllToolsBiggerSideSize > maxHeight
    : AllToolsBiggerSideSize > maxWidth

  const biggerSideSize = sizeData.isPortrait ? biggerSideSizePortrait : biggerSideSizeLandscape

  const hidden = sizeData.isPortrait ? hideY : hideX

  const show = sizeData.isPortrait ? showY : showX

  const variantsItem = {
    hidden,
    show,
  }

  const finalSize = showOnlyPens ? OnlyPensSize : showOnlyColors ? OnlyColorsSize : biggerSideSize

  const [allToolsWidth, allToolsHeight] = sizeData.isPortrait
    ? [SmallerSideSize, finalSize]
    : [finalSize, SmallerSideSize]

  const fromOriginW = showOnlyPens || showOnlyColors ? allToolsWidth : 0

  const fromOriginH = showOnlyPens || showOnlyColors ? allToolsHeight : 0

  const variantsContainer = {
    hidden: {
      width: sizeData.isPortrait ? allToolsWidth : fromOriginW,
      height: sizeData.isPortrait ? fromOriginH : allToolsHeight,
    },

    show: {
      ...show,

      width: allToolsWidth,
      height: allToolsHeight,

      transition: {
        staggerChildren: 0.11,
        delayChildren: 0,
      },
    },
  }

  // - Pens

  const [pensWidth, pensHeight] = sizeData.isPortrait
    ? [SmallerSideSize, OnlyPensSize]
    : [OnlyPensSize, SmallerSideSize]

  const pensVariantsContainer = {
    hidden: {
      width: sizeData.isPortrait ? pensWidth : finalSize,
      height: sizeData.isPortrait ? finalSize : pensHeight,
    },

    show: {
      ...show,

      width: pensWidth,
      height: pensHeight,

      transition: {
        staggerChildren: 0.11,
        delayChildren: 0,
      },
    },
  }

  // - Colors

  const [colorsWidth, colorsHeight] = sizeData.isPortrait
    ? [SmallerSideSize, OnlyColorsSize]
    : [OnlyColorsSize, SmallerSideSize]

  const colorsVariantsContainer = {
    hidden: {
      width: sizeData.isPortrait ? colorsWidth : finalSize,
      height: sizeData.isPortrait ? finalSize : colorsHeight,
    },

    show: {
      ...show,

      width: colorsWidth,
      height: colorsHeight,

      transition: {
        staggerChildren: 0.11,
        delayChildren: 0,
      },
    },
  }

  // ~~~~~~

  return {
    hasScroll,
    sizeData,

    variantsContainer,
    pensVariantsContainer,
    colorsVariantsContainer,

    variantsItem,
  }
}
