import { motion } from 'framer-motion'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { DevicePIPEnabled } from '../../../../../constants/misc'
import { StoreState } from '../../../../../models/app/model'
import { UtilsFullscreen } from '@dn/utils'
import { JoinedMobileToolbarsMainButtonsCapturePaint } from './buttons/capture-paint/component'
import { JoinedMobileToolbarsMainButtonsSharePaint } from './buttons/share-paint/component'
import { JoinedMobileToolbarsMainButtonsClose } from './buttons/close/component'
import { JoinedMobileToolbarsMainButtonsFullscreen } from './buttons/fullscreen/component'
import { JoinedMobileToolbarsMainButtonsPip } from './buttons/pip/component'
import { JoinedMobileToolbarsMainButtonsZoomIn } from './buttons/zoom-in/component'
import { JoinedMobileToolbarsMainButtonsZoomOut } from './buttons/zoom-out/component'
import { useJoinedMobileToolbarsMainHooksResizeAnimations } from './hooks/resize-animations'
import { ClassName, STJoinedMobileToolbarsMain } from './style'
import { JoinedMobileToolbarsMainButtonsDownload } from './buttons/download/component'

// ~~~~~~ Component

export const JoinedMobileToolbarsMain = () => {
  // ~~~~~~ Hooks

  const resizeAnimations = useJoinedMobileToolbarsMainHooksResizeAnimations()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(performance.now())

  // - Store

  const video = useSelector((state: StoreState) => state.paintBcastStreamVideo.video)

  // ~~~~~~ Callbacks

  // We need this in order to sync pip and fullscreen icons
  // we can do it better using the store instead

  const onUpdate = useCallback(() => {
    setUpdate(performance.now())
  }, [])

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsMain>
      <motion.div
        className={`${ClassName}--content`}
        variants={resizeAnimations.variantsContainer}
        initial="hidden"
        animate="show"
        style={{
          display: 'flex',
          flexDirection: resizeAnimations.sizeData.isPortrait ? 'column' : 'row',
        }}
      >
        {/* Share paint */}

        <motion.div variants={resizeAnimations.variantsItem}>
          <JoinedMobileToolbarsMainButtonsSharePaint video={video} />
        </motion.div>

        {/* Download */}

        <motion.div variants={resizeAnimations.variantsItem}>
          <JoinedMobileToolbarsMainButtonsDownload video={video} />
        </motion.div>

        {/* Paint */}

        <motion.div variants={resizeAnimations.variantsItem}>
          <JoinedMobileToolbarsMainButtonsCapturePaint video={video} />
        </motion.div>

        {/* Zoom in */}

        <motion.div variants={resizeAnimations.variantsItem}>
          <JoinedMobileToolbarsMainButtonsZoomIn />
        </motion.div>

        {/* Zoom out */}

        <motion.div variants={resizeAnimations.variantsItem}>
          <JoinedMobileToolbarsMainButtonsZoomOut />
        </motion.div>

        {/* PIP */}

        {DevicePIPEnabled ? (
          <motion.div variants={resizeAnimations.variantsItem}>
            <JoinedMobileToolbarsMainButtonsPip video={video} onUpdate={onUpdate} />
          </motion.div>
        ) : undefined}

        {/* Fullscreen */}

        {UtilsFullscreen.fullscreenIsSupported ? (
          <motion.div variants={resizeAnimations.variantsItem}>
            <JoinedMobileToolbarsMainButtonsFullscreen onUpdate={onUpdate} />
          </motion.div>
        ) : undefined}

        {/* Close */}

        <motion.div variants={resizeAnimations.variantsItem}>
          <JoinedMobileToolbarsMainButtonsClose />
        </motion.div>

        {/* -- */}
      </motion.div>
    </STJoinedMobileToolbarsMain>
  )
}
