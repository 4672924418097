export const Wolf = {
  100: '#1C1E21',
  95: '#202427',
  90: '#2A2E31',
  80: '#464B53',
  70: '#5D656F',
  60: '#767F8A',
  50: '#8D979F',
  40: '#A8ADB4',
  30: '#BEC4C7',
  20: '#D9DBDD',
  10: '#E5E7E9',
  5: '#F0F2F4',
  0: '#ffffff',
} as const

// ~~~~~~

const WOLF_00_00: ThemeColor = {
  light: Wolf[0],
  dark: Wolf[0],
}

const WOLF_00_05: ThemeColor = {
  light: Wolf[0],
  dark: Wolf[5],
}

const WOLF_00_10: ThemeColor = {
  light: Wolf[0],
  dark: Wolf[10],
}

const WOLF_00_90: ThemeColor = {
  light: Wolf[0],
  dark: Wolf[90],
}

const WOLF_00_100: ThemeColor = {
  light: Wolf[0],
  dark: Wolf[100],
}

const WOLF_00_80: ThemeColor = {
  light: Wolf[0],
  dark: Wolf[80],
}

const WOLF_00_95: ThemeColor = {
  light: Wolf[0],
  dark: Wolf[95],
}

const WOLF_05_05: ThemeColor = {
  light: Wolf[5],
  dark: Wolf[5],
}

const WOLF_05_80: ThemeColor = {
  light: Wolf[5],
  dark: Wolf[80],
}

const WOLF_05_100: ThemeColor = {
  light: Wolf[5],
  dark: Wolf[100],
}

const WOLF_05_90: ThemeColor = {
  light: Wolf[5],
  dark: Wolf[90],
}

const WOLF_05_95: ThemeColor = {
  light: Wolf[5],
  dark: Wolf[95],
}

const WOLF_10_10: ThemeColor = {
  light: Wolf[10],
  dark: Wolf[10],
}

const WOLF_10_100: ThemeColor = {
  light: Wolf[10],
  dark: Wolf[100],
}

const WOLF_10_70: ThemeColor = {
  light: Wolf[10],
  dark: Wolf[70],
}

const WOLF_10_80: ThemeColor = {
  light: Wolf[10],
  dark: Wolf[80],
}

const WOLF_10_90: ThemeColor = {
  light: Wolf[10],
  dark: Wolf[90],
}

const WOLF_10_95: ThemeColor = {
  light: Wolf[10],
  dark: Wolf[95],
}

const WOLF_100_00: ThemeColor = {
  light: Wolf[100],
  dark: Wolf[0],
}

const WOLF_100_05: ThemeColor = {
  light: Wolf[100],
  dark: Wolf[5],
}

const WOLF_100_100: ThemeColor = {
  light: Wolf[100],
  dark: Wolf[100],
}

const WOLF_100_80: ThemeColor = {
  light: Wolf[100],
  dark: Wolf[80],
}

const WOLF_100_90: ThemeColor = {
  light: Wolf[100],
  dark: Wolf[90],
}

const WOLF_20_100: ThemeColor = {
  light: Wolf[20],
  dark: Wolf[100],
}

const WOLF_20_20: ThemeColor = {
  light: Wolf[20],
  dark: Wolf[20],
}

const WOLF_20_60: ThemeColor = {
  light: Wolf[20],
  dark: Wolf[60],
}

const WOLF_20_70: ThemeColor = {
  light: Wolf[20],
  dark: Wolf[70],
}

const WOLF_20_80: ThemeColor = {
  light: Wolf[20],
  dark: Wolf[80],
}

const WOLF_20_90: ThemeColor = {
  light: Wolf[20],
  dark: Wolf[90],
}

const WOLF_20_95: ThemeColor = {
  light: Wolf[20],
  dark: Wolf[95],
}

const WOLF_30_30: ThemeColor = {
  light: Wolf[30],
  dark: Wolf[30],
}

const WOLF_30_50: ThemeColor = {
  light: Wolf[30],
  dark: Wolf[50],
}

const WOLF_30_90: ThemeColor = {
  light: Wolf[30],
  dark: Wolf[90],
}

const WOLF_40_40: ThemeColor = {
  light: Wolf[40],
  dark: Wolf[40],
}

const WOLF_40_50: ThemeColor = {
  light: Wolf[40],
  dark: Wolf[50],
}

const WOLF_40_90: ThemeColor = {
  light: Wolf[40],
  dark: Wolf[90],
}

const WOLF_50_00: ThemeColor = {
  light: Wolf[50],
  dark: Wolf[0],
}

const WOLF_50_30: ThemeColor = {
  light: Wolf[50],
  dark: Wolf[30],
}

const WOLF_50_40: ThemeColor = {
  light: Wolf[50],
  dark: Wolf[40],
}

const WOLF_50_50: ThemeColor = {
  light: Wolf[50],
  dark: Wolf[50],
}

const WOLF_60_60: ThemeColor = {
  light: Wolf[60],
  dark: Wolf[60],
}

const WOLF_70_20: ThemeColor = {
  light: Wolf[70],
  dark: Wolf[20],
}

const WOLF_70_30: ThemeColor = {
  light: Wolf[70],
  dark: Wolf[30],
}

const WOLF_70_70: ThemeColor = {
  light: Wolf[70],
  dark: Wolf[70],
}

const WOLF_80_20: ThemeColor = {
  light: Wolf[80],
  dark: Wolf[20],
}

const WOLF_80_40: ThemeColor = {
  light: Wolf[80],
  dark: Wolf[40],
}

const WOLF_80_80: ThemeColor = {
  light: Wolf[80],
  dark: Wolf[80],
}

const WOLF_90_05: ThemeColor = {
  light: Wolf[90],
  dark: Wolf[5],
}

const WOLF_90_10: ThemeColor = {
  light: Wolf[90],
  dark: Wolf[10],
}

const WOLF_90_20: ThemeColor = {
  light: Wolf[90],
  dark: Wolf[20],
}

const WOLF_90_90: ThemeColor = {
  light: Wolf[90],
  dark: Wolf[90],
}

const WOLF_90_100: ThemeColor = {
  light: Wolf[90],
  dark: Wolf[100],
}

const WOLF_95_05: ThemeColor = {
  light: Wolf[95],
  dark: Wolf[5],
}

const WOLF_95_20: ThemeColor = {
  light: Wolf[95],
  dark: Wolf[20],
}

const WOLF_95_95: ThemeColor = {
  light: Wolf[95],
  dark: Wolf[95],
}

// ~~~~~~

export const WolfColors = {
  WOLF_00_00,
  WOLF_00_05,
  WOLF_00_10,
  WOLF_00_90,
  WOLF_00_100,
  WOLF_00_80,
  WOLF_00_95,
  WOLF_05_05,
  WOLF_05_80,
  WOLF_05_100,
  WOLF_05_90,
  WOLF_05_95,
  WOLF_10_10,
  WOLF_10_100,
  WOLF_10_70,
  WOLF_10_80,
  WOLF_10_90,
  WOLF_10_95,
  WOLF_100_00,
  WOLF_100_05,
  WOLF_100_100,
  WOLF_100_80,
  WOLF_100_90,
  WOLF_20_100,
  WOLF_20_20,
  WOLF_20_60,
  WOLF_20_70,
  WOLF_20_80,
  WOLF_20_90,
  WOLF_20_95,
  WOLF_30_30,
  WOLF_30_50,
  WOLF_30_90,
  WOLF_40_40,
  WOLF_40_50,
  WOLF_40_90,
  WOLF_50_00,
  WOLF_50_30,
  WOLF_50_40,
  WOLF_50_50,
  WOLF_60_60,
  WOLF_70_20,
  WOLF_70_30,
  WOLF_70_70,
  WOLF_80_20,
  WOLF_80_40,
  WOLF_80_80,
  WOLF_90_05,
  WOLF_90_10,
  WOLF_90_100,
  WOLF_90_20,
  WOLF_90_90,
  WOLF_95_05,
  WOLF_95_20,
  WOLF_95_95,
} as const
