import { Config } from '../../../config'

const DefaultHeaders = {
  'Content-Type': 'application/json',
}

const genHeaders = (headers?: AppHeaders) => ({
  headers: {
    ...DefaultHeaders,
    ...Config.Api.Store.Headers,
    ...headers,
  },
})

const genAuthHeader = (token: string) => ({
  Authorization: `Bearer ${token}`,
})

const genHeadersWithAuth = (token: string, headers?: AppHeaders) => ({
  ...genHeaders({
    ...headers,
    ...genAuthHeader(token),
  }),
})

// ~~~~~~

export const StoreHeaderUtils = {
  genHeaders,
  genAuthHeader,
  genHeadersWithAuth,
}
