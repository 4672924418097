const prefix = 'mt-api-bcast-room-for-pub-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastRoomPubMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastRoomPubMC = {
  ok: (data: {
    id: string
    uuid: string
    server: string // ip
    token: string
  }) => ({
    type: EpicApiBcastRoomPubMT.OK,
    payload: data,
  }),

  error: (errors: Infos[]) => ({
    type: EpicApiBcastRoomPubMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
