import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

export const STDialogsSubscriptionsCreateAnAccount = styled.div`
  .line {
    text-align: left;

    padding-bottom: ${Layout.Spacing[2]}px;

    &.last {
      padding-bottom: 0;
    }

    svg path {
      fill: ${theme(Colors.WOLF_100_05)};
    }
  }

  form {
    padding-top: 8px;
  }
`
