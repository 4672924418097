import { css } from 'styled-components'
import { Transition } from '../../../../style/animations/css-animations'
import { Colors } from '../../../../style/theme/colors'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const PaintToolbarCommonColors = {
  color: Colors.WOLF_100_05,
  disabled: Colors.WOLF_100_05,
  selected: Colors.EMMET_40_40,
  hover: Colors.EMMET_50_30,
}

const { color, disabled: disabledColor, hover: hoverColor } = PaintToolbarCommonColors

// ~~~~~~ Props

type STProps = {
  $disabled?: boolean
}

// ~~~~~~ Component

export const STPaintToolbarCommonStyle = css<STProps>`
  width: 20px;
  height: 20px;

  padding: 2px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;

    path {
      ${Transition.FillStrokeFaster}

      fill: ${({ $disabled }) => ($disabled ? theme(disabledColor) : theme(color))};
    }
  }

  @media (hover: hover) {
    &:hover {
      svg path {
        fill: ${({ $disabled }) => ($disabled ? theme(disabledColor) : theme(hoverColor))};
      }
    }
  }
`
