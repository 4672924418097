import { Config } from '../../../../../config'

// ~~~~~~ Types

type Message = DN.Services.Extension.ToExtension.SetMeAsActive

// ~~~~~~

export const sendMessageToExtensionSetMeAsActive = () => {
  if (
    Config.Extension.Id === 'no-extension-id' ||
    !window.chrome ||
    !window.chrome.runtime ||
    !window.chrome.runtime.sendMessage
  ) {
    return false
  }

  const message: Message = {
    type: 'set-me-as-active',
  }

  setTimeout(() => chrome.runtime.sendMessage(Config.Extension.Id, message), 2000)
}
