import React from 'react'
import { useDialogContext } from '../hooks/use-dialog-context'

// ~~~~~~ Component

export const Actions: React.FC<any> = ({ children }) => {
  const { kind } = useDialogContext()

  return kind === 'actions' ? <div className="dialog-actions">{children}</div> : null
}
