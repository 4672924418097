const prefix = 'mt-api-bcast-providers-unlink-google-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastProvidersUnlinkGoogleMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastProvidersUnlinkGoogleMC = {
  ok: (data: DN.Models.Provider.Api.Unlink.ResParsed) => ({
    type: EpicApiBcastProvidersUnlinkGoogleMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.Provider.Api.Unlink.ResError) => ({
    type: EpicApiBcastProvidersUnlinkGoogleMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
