import styled from 'styled-components'
import { theme } from '../../../../../../../style/utils/theme'
import { CommonStyleSvgColors, STJoinedMobileToolbarsCapturePaintButtons } from '../common-style'

// ~~~~~~ Render

export const ClassName = 'joined-mobile-toolbars-capture-paint-buttons-share-paint'

// ~~~~~~ Component

export const STJoinedMobileToolbarsCapturePaintButtonsSharePaint = styled.div`
  ${STJoinedMobileToolbarsCapturePaintButtons}

  svg.${ClassName}--icon {
    path {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }
`
