import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Helpers

const getBorderColor = (hasErrors: boolean) => {
  return hasErrors ? Colors.BROOKLYN_40_30 : Colors.WOLF_50_30
}

// ~~~~~~ Types

export type STProps = {
  $focussed: boolean
  $hasErrors: boolean
}

// ~~~~~~ Constants

export const ClassName = 'dialog-add-card-form'

// ~~~~~~ Component

export const STDialogAddCardForm = styled.div<STProps>`
  display: flex;
  flex-direction: column;

  /* min-height: 72px; */

  .${ClassName}--card {
    ${Layout.Border.Radius.A}

    margin-top: 8px;
    width: calc(100% - 26px);

    padding: 12px;

    border: ${`solid ${Layout.Border.Size.Number.B}px`};

    border-color: ${({ $hasErrors }) => theme(getBorderColor($hasErrors))};
  }

  .${ClassName}--stripe-error {
    margin-top: 8px;
    width: calc(100% - 38px);
  }
`
