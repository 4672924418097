import { useDispatch } from 'react-redux'
import { Button } from '../../../../../../components/common/button/component'
import { BroadcastAC } from '../../../../../../store/actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../../../../store/actions/cancel-now/actions'
import { STJoinedMobileHeaderTopRightLeave } from './style'

// ~~~~~~ Component

export const JoinedMobileHeaderTopRightLeave = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ Handlers

  function onClick() {
    dispatch(BroadcastAC.exit('user-manual-exit'))

    dispatch(CancelNowAC.cancelAll([CancelReasons.UserStopped]))
  }

  // ~~~~~~ Render

  return (
    <STJoinedMobileHeaderTopRightLeave>
      <Button
        $colorType="error"
        intlId="pages.joined.top.MobileEnd"
        $size="s"
        disabled={false}
        $active={true}
        $running={false}
        onClick={onClick}
      />
    </STJoinedMobileHeaderTopRightLeave>
  )
}
