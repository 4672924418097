import { UtilsStorageLocal } from '@dn/utils'

// ####################################################################################################
// ~~~~~~ Get extension id to use override the configured one
// ####################################################################################################

const get: () => string = () => {
  const extensionId = UtilsStorageLocal.getItem('debug-extension')

  return typeof extensionId === 'string' ? extensionId : ''
}

// ~~~~~~

export const LocalStorageDebugExtension = {
  get,
} as const
