import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { DrawService } from '../../../../../services/draw'
import {
  PaintBCastStreamDrawAC,
  PaintBCastStreamDrawAT,
} from '../../../../actions/paint-bcast-stream/draw/actions'

type Action = ReturnType<typeof PaintBCastStreamDrawAC.updateView>

export const paintBCastStreamDrawUpdateViewEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(PaintBCastStreamDrawAT.UPDATE_VIEW),

    mergeMap(({ payload }) => {
      const scope = state$.value.paintBcastStreamDraw.scope

      if (!scope) return EMPTY

      DrawService.updateView(scope, {
        width: payload.width,
        height: payload.height,
      })

      return EMPTY
    }),
  )
