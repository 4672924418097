import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { getThemeValue } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-menu-mobile'

// ~~~~~~ Types

type STProps = {
  $isOverBlack: boolean
}

// ~~~~~~ Component

export const STUserMenuMobile = styled.div<STProps>`
  padding-right: 0px;

  .${ClassName}--user-menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    .${ClassName}--user-menu-btn--icon {
      width: 30px;
      height: 30px;

      svg path {
        fill: ${(props) =>
          getThemeValue(props, props.$isOverBlack ? Colors.WOLF_05_05 : Colors.WOLF_100_05)};
      }
    }
  }
`
