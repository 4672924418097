import { useSelector } from 'react-redux'
import { StoreState } from '../../models/app/model'
import { useMemo } from 'react'
import { BcastTrackEvents } from '../../services/track-events'

// ~~~~~~ Hook

export const useTrackUserContext = () => {
  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const subscription = useSelector((state: StoreState) => state.subscription)

  // ~~~~~~ Computed

  const bcastUserContext = useMemo(() => {
    return BcastTrackEvents.helpers.genUserContext(currentUser, subscription)
  }, [currentUser, subscription])

  // ~~~~~~

  return bcastUserContext
}
