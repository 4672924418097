import { EMPTY, fromEvent, of, filter, mergeMap, share, tap } from 'rxjs'

import { Config } from '../../../../config'
import { UtilsLog } from '../../../../utils/logs'
import { BroadcastServiceConst } from '../../constants/broadcast-partial-service'
import { genTakeUntilStop$ } from '../../subject/stop/take-until/broadcast-partial-service'

const { SubscriberEventNames, OurSubscriberEvents } = BroadcastServiceConst

const { SubscribeTimeUpdate } = SubscriberEventNames

export const startListeningSubscription$ = (subscriber: R5P.Subscription, id: string) => {
  Config.Logs.BroadcastService &&
    UtilsLog.devLog(
      'BCastService',
      'startListeningSubscription$',
      '\n- id\n',
      id,
      '\n- subscriber\n',
      subscriber,
    )

  const closeObs$ = fromEvent<R5P.SubscriptionEvent>(
    subscriber,
    SubscriberEventNames.Wildcard,
  ).pipe(
    filter((evt) => evt.type !== SubscribeTimeUpdate),

    tap((evt) => Config.Logs.BroadcastService && UtilsLog.devLog('New sub event', id, evt)),

    mergeMap((evt) => {
      switch (true) {
        case evt.type === SubscriberEventNames.SubscribeConnectionClosed:
        case evt.type === SubscriberEventNames.SubscribeStop:
          return of(OurSubscriberEvents.Closed)

        case evt.type === SubscriberEventNames.SubscribeFail:
          if (!evt.data) return of(OurSubscriberEvents.Fail)

          return typeof evt.data === 'string' &&
            !!~evt.data.search('Session playback disallowed for')
            ? of(OurSubscriberEvents.UserLimit)
            : of(OurSubscriberEvents.Fail)

        case evt.type === SubscriberEventNames.SubscribeInvalidName:
          return of(OurSubscriberEvents.InvalidName)

        default:
          // Config.Logs.BroadcastEvents && devLog('Unhandled event (ignored)', evt.type)
          return EMPTY
      }
    }),

    genTakeUntilStop$(id),

    share(),
  )

  const obsAll$ = fromEvent<R5P.SubscriptionEvent>(subscriber, SubscriberEventNames.Wildcard).pipe(
    genTakeUntilStop$(id),

    share(),
  )

  return [closeObs$, obsAll$] as const
}
