const prefix = 'at-api-store-subscriptions'

// ~~~~~~ Action Types

export const ApiStoreSubscriptionsAT = {
  CHECK_PAYMENT_METHOD: `${prefix}-check-payment-method`,
  CREATE: `${prefix}-create`,
  UPGRADE_PLAN: `${prefix}-upgrade-plan`,
} as const

// ~~~~~~ Action Creators

export const ApiStoreSubscriptionsAC = {
  checkPaymentMethod: () => ({
    type: ApiStoreSubscriptionsAT.CHECK_PAYMENT_METHOD,
  }),

  // Create: Only free plan

  create: (data: DN.Models.StoreSubscription.Api.Create.Req) => ({
    type: ApiStoreSubscriptionsAT.CREATE,
    payload: data,
  }),

  upgradePlan: (data: DN.Models.StoreSubscription.Api.UpgradePlan.Req) => ({
    type: ApiStoreSubscriptionsAT.UPGRADE_PLAN,
    payload: data,
  }),
} as const
