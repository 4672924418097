import styled from 'styled-components'

import { Transition } from '../../../style/animations/css-animations'

import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'

// ~~~~~~ Constants

const ShowWidth = 32

// ~~~~~~ Helpers

const getFocussedBorderColor = (hasErrors: boolean) => {
  return hasErrors ? Colors.BROOKLYN_40_30 : Colors.WOLF_100_05
}

// ~~~~~~ Types

export type STProps = {
  $hideBorder?: true
  $hasErrors: boolean
  $height?: number
  $hasIcon?: boolean
  $disabled: boolean
  $iconWidth?: number
  $isPassword: boolean
  $textAlign?: 'center' | 'left' | 'right'
}

// ~~~~~~ Component

export const STIntlInput = styled.div<STProps>`
  width: 100%;

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'default')};

  position: relative;

  .label {
    padding-bottom: 12px;
    user-select: none;

    color: ${theme(Colors.WOLF_100_05)};

    ${Fonts.Scale.Body}
    ${Fonts.Weight[700]}
  }

  .sublabel {
    padding-bottom: 12px;
    user-select: none;

    color: ${theme(Colors.WOLF_100_05)};

    ${Fonts.Scale.Helper}
    ${Fonts.Weight[300]}
  }

  input {
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'text')};

    ${Layout.Border.Radius.A}

    height: ${(props) => (props.$height ? `${props.$height - 35}px` : '20px')};

    /* Text */

    ${Fonts.Scale.BodyBig}
    ${Fonts.Weight[500]}

    text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};

    width: calc(
      100% -
        (
          20px +
            ${({ $hasIcon, $isPassword }) => ($hasIcon ? 36 : 16) + ($isPassword ? ShowWidth : 0)}px
        )
    );

    padding-top: 16px;
    padding-right: ${({ $isPassword }) => ($isPassword ? 16 + ShowWidth : 16)}px;

    padding-bottom: 16px;
    padding-left: ${({ $hasIcon }) => ($hasIcon ? 36 : 16)}px;

    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

    /* Colors */

    background-color: transparent;

    color: ${theme(Colors.WOLF_100_05)};

    /* Border */

    border: ${({ $hideBorder }) =>
      $hideBorder ? 'none' : `solid ${Layout.Border.Size.Number.A}px`};

    border-color: ${theme(Colors.WOLF_50_30)};

    ${Transition.BorderColorFaster}

    &:hover {
      border-color: ${theme(Colors.WOLF_40_40)};
    }

    &:focus {
      border-color: ${({ $hasErrors }) => theme(getFocussedBorderColor($hasErrors))};
    }

    &::placeholder {
      color: ${theme(Colors.WOLF_50_30)};
    }
  }

  .show-pass {
    cursor: pointer;
    position: absolute;

    right: 16px;
    top: 16px;

    svg {
      padding: 2px;
      width: 24px;
    }
  }

  .intl-icon-container {
    position: absolute;
    height: ${({ $height }) => ($height ? $height : 56)}px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 8px;
    margin-right: 8px;

    .intl-icon {
      width: 24px;
      height: 24px;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        padding: 2px;
        width: ${({ $iconWidth }) => $iconWidth ?? 20}px;
      }
    }
  }

  .intl-box {
    margin-top: 16px;
  }

  .intl-input-error {
    margin-top: ${Layout.Spacing[2]}px;

    display: flex;

    .intl-input-error-icon {
      width: 16px;
      height: 16px;

      margin-top: 3px;
      margin-right: ${Layout.Spacing[1]}px;

      svg path {
        fill: ${theme(Colors.BROOKLYN_40_30)};
      }
    }

    .intl-input-error-list {
    }
  }
`
