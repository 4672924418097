import { RTCSubscriber } from '@dn/patched-red5pro-sdk'

import { Observable, takeUntil } from 'rxjs'

import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'
import { startListeningSubscription$ } from '../start-listening/subscription/broadcast-partial-service'
import { BroadcastStop$$ } from '../subject/stop/broadcast-partial-service'

// ~~~~~ Types

type Event$ = ReturnType<typeof startListeningSubscription$>

type Emit = {
  red5Sub: R5P.Subscription
  events$: Event$[0]
  allEvents$: Event$[1]
}

// ~~~~~

export const genSubscription$ = (
  username: string,
  subscriptionId: string,
  config: DN.Services.Broadcast.SubConfig,
  iceServers: RTCIceServer[],
) => {
  Config.Logs.BroadcastService &&
    UtilsLog.devLog(
      '\nBCastService',
      'genSubscriber$',
      '\n- username\n',
      username,
      '\n- config\n',
      config,
      '\n- iceServers\n',
      iceServers,
    )

  const subscriberInitConfig: R5P.SubscriberInitConfig = {
    protocol: 'wss',
    port: 443,
    app: 'streammanager',

    host: config.host,

    connectionParams: {
      host: config.serverAddress,
      app: 'live',
      username: username,
      password: 'password',
      token: 'web-subscriber',
    },

    streamName: config.broadcastId,
    subscriptionId,

    mediaElementId: config.mediaElementId || 'red5pro-subscriber',

    rtcConfiguration: {
      iceServers: iceServers,
      iceCandidatePoolSize: 2,
      bundlePolicy: 'max-bundle',
    },

    videoEncoding: 'NONE',
    audioEncoding: 'NONE',
  }

  Config.Logs.BroadcastEvents &&
    UtilsLog.devLog(
      'BCastService',
      'genSubscriber$',
      '\n- subscriberInitConfig\n',
      subscriberInitConfig,
    )

  const obs$ = new Observable<Emit>((observer) => {
    const subscriber = new RTCSubscriber()

    try {
      subscriber
        .init(subscriberInitConfig)

        .then((red5Sub) => {
          const [events$, allEvents$] = startListeningSubscription$(red5Sub, config.broadcastId)

          observer.next({ red5Sub, events$, allEvents$ })
          observer.complete()
        })

        .catch((err) => observer.error(err))
    } catch (err) {
      observer.error(err)
    }
  })

  return obs$.pipe(takeUntil(BroadcastStop$$))
}
