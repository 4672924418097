import { UtilsStore } from '@dn/utils'
import { Config } from '../../../config'

const initModel: DN.Models.SharedCamMic.ModelFront = {
  errors: [],

  getCamMicUiStatus: 'init',
  getCamMicToSwitchUiStatus: 'init',

  curCamMicStream: undefined,

  camMicUiStatus: 'init',
  camUiStatus: 'init',
  micUiStatus: 'init',
}

// ~~~~~~

const prefix = 'mt-edit-shared-cam-mic'

export const SharedCamMicModelFieldsMR = {
  errors: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'errors',
    initModel.errors,
    Config.Intl.InfosForInfer,
  ),
}

// ~~~~~~

export const SharedCamMicModel = {
  initModel,
} as const
