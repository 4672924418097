import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePeerConnStats } from '../../../hooks/stats/use-peer-conn-stats'
import { StoreState } from '../../../models/app/model'
import { StatsMC } from '../../../store/actions-mutators/stats/mutators'
import { StatsGraph } from '../stats-graph/component'
import { Button } from '../button/component'
import { STPeerStats } from './style'

export const PeerStats = () => {
  // ~~~~~~ Hooks

  usePeerConnStats()

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [showGraph, setShowGrap] = useState(false)

  const stats = useSelector((state: StoreState) => state.stats)

  // ~~~~~~ Computed

  const lastStats = stats.stats.slice(-1)

  // ~~~~~~ Handlers

  function onClickCloseStats() {
    dispatch(StatsMC.toggle())
  }

  // ~~~~~~ Render

  return (
    <STPeerStats className="stats">
      {lastStats.map((stat, idx) => (
        <div className="main" key={idx}>
          {/* Current data: 1024x768 | H264 | 26 FPS | 2800kbps */}
          <div>
            <span>
              {stat.trackInfo.frameWidth}x{stat.trackInfo.frameHeight}
            </span>
            <span> | {stat.codecInfo.codec}</span>
            <span> | {stat.inboundInfo.fps} FPS</span>
            <span> | {stat.inboundInfo.bitrate}kbps</span>
          </div>

          <div className="actions">
            {/* Toggle graph */}
            <div style={{ marginLeft: 14 }}>
              <Button
                $colorType="primary"
                intlId="common.ToggleGraph"
                $running={false}
                $active={true}
                disabled={false}
                onClick={() => setShowGrap(!showGraph)}
              />
            </div>

            {/* Close stats */}
            <div style={{ marginLeft: 14 }}>
              <Button
                $colorType="secondary"
                intlId="common.Close"
                $running={false}
                $active={true}
                disabled={false}
                onClick={onClickCloseStats}
              />
            </div>
          </div>
        </div>
      ))}

      {showGraph ? <StatsGraph /> : undefined}
    </STPeerStats>
  )
}
