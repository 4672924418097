import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'
import { ApiBcastUserSettingsAC } from '../../../../../store/actions/api-bcast/user-settings/actions'
import { UtilsCompare } from '@dn/utils'
import { Button } from '../../../button/component'
import { Trans } from '../../../intl/trans'
import { CommonClassName } from '../common-style'
import { STOnboardingTooltipsLiveDraw } from './style'
import { BcastTrackEvents } from '../../../../../services/track-events'
import { useTrackUserContext } from '../../../../../hooks/track-events/use-track-user-context'

// ~~~~~~ Component

export const OnboardingTooltipsLiveDraw = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const currentUserId = useSelector((state: StoreState) => state.currentUser.id)

  const settingsUiStatus = useSelector((state: StoreState) => state.userSettingsEdit.uiStatus)

  const uiBounds = useSelector((state: StoreState) => state.uiOnboarding.onboardingLiveDrawBounds)

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Computed

  const self = selfRef.current

  const settingsIsRunning = settingsUiStatus === 'running'

  // ~~~~~~ Handlers

  function onClickSkip() {
    if (settingsUiStatus === 'running') return

    dispatch(
      ApiBcastUserSettingsAC.update(currentUserId, {
        onboarding_live_draw: true,
      }),
    )

    // Track

    BcastTrackEvents.calls.Onboarding['4-onboarding-live-draw'](userContext)
  }

  // ~~~~~~ Effects

  // - Set position

  useEffect(() => {
    if (!self) {
      setUpdate(performance.now())
      return
    }

    if (UtilsCompare.allValuesAre(uiBounds, 0)) return

    self.style.top = `${uiBounds.top - self.clientHeight - 20}px`

    self.style.left = `${uiBounds.left + uiBounds.width / 2 - self.clientWidth / 2}px`

    //
  }, [self, uiBounds])

  // ~~~~~~ Render

  return (
    <STOnboardingTooltipsLiveDraw
      ref={selfRef}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className={`${CommonClassName}--content`}>
        {/* Title */}

        <div className={`${CommonClassName}--content--title`}>
          <Trans id="common-components.onboarding.tooltips.live-draw.Title" />
        </div>

        {/* Info */}

        {/* - Louver 1 */}

        <div className={`${CommonClassName}--content--text`}>
          <Trans id="common-components.onboarding.tooltips.live-draw.Louver1" />
        </div>

        {/* - Louver 2 */}

        <div className={`${CommonClassName}--content--text`}>
          <Trans id="common-components.onboarding.tooltips.live-draw.Louver2" />
        </div>

        {/* Skip */}

        <div className={`${CommonClassName}--content--skip`}>
          <Button
            intlId="common-components.onboarding.Skip"
            $colorType="onboarding"
            $size="xs"
            $fontWeight="500"
            $active={!settingsIsRunning}
            $running={settingsIsRunning}
            disabled={settingsIsRunning}
            onClick={onClickSkip}
          />
        </div>

        {/* Triangle */}

        <div className={`${CommonClassName}--triangle`} />

        {/* -- */}
      </div>
    </STOnboardingTooltipsLiveDraw>
  )
}
