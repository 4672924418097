import styled from 'styled-components'
import { STPaintToolbarCommonStyle } from '../common-style'

// ~~~~~~ Types

type STProps = {
  $disabled?: boolean
}

// ~~~~~~ Component

export const STPaintToolbarButtonsExit = styled.div<STProps>`
  ${STPaintToolbarCommonStyle}
`
