const prefix = 'mt-api-checkout-validate-email-code-epic'

// ~~~~~~ Mutators Types

export const ApiCheckoutValidateEmailCodeEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiCheckoutValidateEmailCodeEpicMC = {
  ok: (data: DN.Models.StoreCheckout.Api.ValidateEmailCode.ResParsed) => ({
    type: ApiCheckoutValidateEmailCodeEpicMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.StoreCheckout.Api.ValidateEmailCode.ResError) => ({
    type: ApiCheckoutValidateEmailCodeEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
