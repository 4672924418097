import { motion } from 'framer-motion'
import Scrollbars from 'react-custom-scrollbars'
import { AutoDownload } from '../../../../../components/common/auto-download/component'
import { useJoinedBehaviourLogicContentMainToolbarsCapturePaintLogic } from '../../../behaviour-logic/content/main/toolbars/capture-paint/logic-hook'
import { JoinedMoblieToolbarsCapturePaintButtonsColors } from './buttons/colors/component'
import { JoinedMoblieToolbarsCapturePaintButtonsPens } from './buttons/pens/component'
import { JoinedMobileToolbarsCapturePaintContent } from './content/component'
import { useJoinedMobileToolbarsCapturePaintHooksResizeAnimations } from './hooks/resize-animations'
import { ClassName, STJoinedMobileToolbarsCapturePaint } from './style'

// ~~~~~~ Component

export const JoinedMobileToolbarsCapturePaint = () => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsCapturePaintLogic()

  const resizeAnimations = useJoinedMobileToolbarsCapturePaintHooksResizeAnimations(
    logic.draw.showPenSelector,
    logic.draw.showColorSelector,
  )

  // ~~~~~~ Render

  // - Only pens

  if (logic.draw.showPenSelector) {
    return (
      <STJoinedMobileToolbarsCapturePaint>
        <motion.div
          className={`${ClassName}--content`}
          variants={resizeAnimations.variantsContainer}
          initial="hidden"
          animate="show" // show-pens
          style={{
            display: 'flex',
            flexDirection: resizeAnimations.sizeData.isPortrait ? 'column' : 'row',
          }}
        >
          {/* -- */}

          <JoinedMoblieToolbarsCapturePaintButtonsPens
            showAll={true}
            variantsContainer={resizeAnimations.pensVariantsContainer}
            isPortrait={resizeAnimations.sizeData.isPortrait}
            onClickSelectPen={logic.draw.onClickSelectPen}
            onClickShowPenSelector={logic.draw.onClickShowPenSelector}
          />

          {/* -- */}
        </motion.div>
      </STJoinedMobileToolbarsCapturePaint>
    )
  }

  // - Only colors

  if (logic.draw.showColorSelector) {
    return (
      <STJoinedMobileToolbarsCapturePaint>
        <motion.div
          className={`${ClassName}--content`}
          variants={resizeAnimations.variantsContainer}
          initial="hidden"
          animate="show" // show-pens
          style={{
            display: 'flex',
            flexDirection: resizeAnimations.sizeData.isPortrait ? 'column' : 'row',
          }}
        >
          {/* -- */}

          <JoinedMoblieToolbarsCapturePaintButtonsColors
            showAll={true}
            variantsContainer={resizeAnimations.colorsVariantsContainer}
            isPortrait={resizeAnimations.sizeData.isPortrait}
            onClickSelectColor={logic.draw.onClickSelectColor}
            onClickShowColorSelector={logic.draw.onClickShowColorSelector}
            onClickSelectPen={logic.draw.onClickSelectPen}
          />

          {/* -- */}
        </motion.div>
      </STJoinedMobileToolbarsCapturePaint>
    )
  }

  // - All tools

  return (
    <STJoinedMobileToolbarsCapturePaint>
      {/* Auto download image */}

      {!logic.draw.isDownloaded && logic.draw.shouldDownload && logic.draw.fileAndName ? (
        <AutoDownload
          onDownload={logic.draw.onDownload}
          file={logic.draw.fileAndName.file}
          name={logic.draw.fileAndName.name}
        />
      ) : undefined}

      {/* Content: paint controls */}

      <motion.div
        className={`${ClassName}--content`}
        variants={resizeAnimations.variantsContainer}
        initial="hidden"
        animate="show"
      >
        {resizeAnimations.hasScroll ? (
          <Scrollbars
            height={resizeAnimations.variantsContainer.show.height}
            width={resizeAnimations.variantsContainer.show.width}
            universal
            autoHide={false}
            // 4px prevent last icon to have pixels out of the toolbar
            style={{
              height: resizeAnimations.sizeData.isPortrait
                ? 'calc(100% - 4px)'
                : resizeAnimations.variantsContainer.show.height,

              width: !resizeAnimations.sizeData.isPortrait
                ? 'calc(100% - 4px)'
                : resizeAnimations.variantsContainer.show.width,
            }}
          >
            <JoinedMobileToolbarsCapturePaintContent
              className={`${ClassName}--content--inner`}
              //
              isPortrait={resizeAnimations.sizeData.isPortrait}
              //
              pensVariantsContainer={resizeAnimations.pensVariantsContainer}
              colorsVariantsContainer={resizeAnimations.colorsVariantsContainer}
              //
              onClickSelectPen={logic.draw.onClickSelectPen}
              onClickShowPenSelector={logic.draw.onClickShowPenSelector}
              //
              onClickEraser={logic.draw.onClickEraser}
              //
              onClickSelectColor={logic.draw.onClickSelectColor}
              onClickShowColorSelector={logic.draw.onClickShowColorSelector}
              //
              onClickUndo={logic.draw.onClickUndo}
              onClickRedo={logic.draw.onClickRedo}
              onClickClear={logic.draw.onClickClear}
              onClickSharePaint={logic.onClickSharePaint}
              onClickSave={logic.draw.onClickSave}
              onClickExitEditingPic={logic.onClickExitEditingPic}
            />
          </Scrollbars>
        ) : (
          <JoinedMobileToolbarsCapturePaintContent
            className={`${ClassName}--content--inner`}
            //
            isPortrait={resizeAnimations.sizeData.isPortrait}
            //
            pensVariantsContainer={resizeAnimations.pensVariantsContainer}
            colorsVariantsContainer={resizeAnimations.colorsVariantsContainer}
            //
            onClickSelectPen={logic.draw.onClickSelectPen}
            onClickShowPenSelector={logic.draw.onClickShowPenSelector}
            //
            onClickEraser={logic.draw.onClickEraser}
            //
            onClickSelectColor={logic.draw.onClickSelectColor}
            onClickShowColorSelector={logic.draw.onClickShowColorSelector}
            //
            onClickUndo={logic.draw.onClickUndo}
            onClickRedo={logic.draw.onClickRedo}
            onClickClear={logic.draw.onClickClear}
            onClickSharePaint={logic.onClickSharePaint}
            onClickSave={logic.draw.onClickSave}
            onClickExitEditingPic={logic.onClickExitEditingPic}
          />
        )}
      </motion.div>
    </STJoinedMobileToolbarsCapturePaint>
  )
}
