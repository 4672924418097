export const trackEvents = {
  Onboarding: {
    '1-onboarding-change-source': 'onboarding-change-source',
    '2-onboarding-create-a-bcast': 'onboarding-create-a-bcast',
    '3-onboarding-live-draw': 'onboarding-live-draw',
    '4-onboarding-share': 'onboarding-share',
  },

  BuyProcess: {
    Free: {
      '1-select-plan-free': 'plan-free-select',
      '2-create-account': 'plan-free-create-account',
      '3-verify-email': 'plan-free-verify-email',
      '4-create-success': 'plan-free-success',
    },

    Premium: {
      '1-select-plan-premium': 'plan-premium-select',
      '2-create-account': 'plan-premium-create-account',
      '3-verify-email': 'plan-premium-verify-email',
      '4-create-success': 'plan-premium-success',
    },

    Upgrade: {
      '1-select-plan-upgrade': 'upgrade-plan-select',
      '2-confirm-plan': 'upgrade-plan-confirm',
      '3-add-card': 'upgrade-plan-add-card',
      '4-upgrade-success': 'upgrade-plan-success',
    },
  },

  JoinKind: {
    Input: 'join-by-input',
    BcastLink: 'join-by-bcast-link',
    CroomLink: 'join-by-croom-link',
  },

  Streams: {
    PubMain: {
      Stream: 'pub-main-stream',
      Duration: 'pub-main-duration',
    },

    PubCamMic: {
      Stream: 'pub-cam-mic-stream',
    },

    SubMain: {
      Stream: 'sub-main-stream',
      Duration: 'sub-main-duration',
    },
  },

  PaintStart: {
    Bcaster: 'paint-start-bcaster',
    Viewer: 'paint-start-viewer',
  },

  Google: {
    Bcaster: {
      Connect: 'google-bcaster-connect',
      Disconnect: 'google-bcaster-disconnect',
      ShareSlideImage: 'google-bcaster-share-slide-img',
      ShareCroomImage: 'google-bcaster-share-croom-img',
      ShareCroomLink: 'google-bcaster-share-croom-link',
    },

    Viewer: {
      Connect: 'google-viewer-connect',
      ShareSlideImage: 'google-viewer-share-slide-img',
    },
  },

  Pip: {
    Bcaster: 'bcaster-pip-start',
    Viewer: 'viewer-pip-start',
  },
} as const
