import { Observable, of, catchError, map, retry } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { StreamManagerServerModel } from '../../../models/stream-manager/server/model'
import { ApiStreamManagerRoutes } from '../constants/routes'

const { Get } = ApiStreamManagerRoutes.Server

// ####################################################################################################
// ~~~~~~ Get server for publish a stream or subscribe to a stream
// ####################################################################################################

type Res = DN.Models.StreamManagerServer.Api.Get.Res
type ResParsed = DNApiResParsed<DN.Models.StreamManagerServer.Api.Get.ResParsed>
type ResError = DNApiError<DN.Models.StreamManagerServer.Api.Get.ResError>

export const apiStreamManagerServerGet$ = (
  broadcastId: string,
  action: DN.Models.StreamManagerServer.Action,
  retryTimes: number,
) => {
  return ajax<Res>({
    method: Get.Method,
    url: Get.getUrl(broadcastId, action),
  }).pipe(
    retry({ count: retryTimes, delay: 1000 }),

    map((res): ResParsed => {
      return {
        status: res.status,
        response: StreamManagerServerModel.fromBackToFront(res.response),
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.server.get.Error', values: { value: res.status } }],
      })
    }),
  )
}
