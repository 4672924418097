import styled from 'styled-components'
import { theme } from '../../../../../../../../../style/utils/theme'
import {
  STJoinedDesktopContentMainToolbarsButtonsCommonStyle,
  CommonStyleSvgColors,
} from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-desktop-content-main-toolbars-main-buttons-capture-share'

// ~~~~~~ Component

export const STJoinedDesktopContentMainToolbarsMainButtonsCaptureShare = styled.div`
  ${STJoinedDesktopContentMainToolbarsButtonsCommonStyle}

  svg.${ClassName}--icon {
    path {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }
`
