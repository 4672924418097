import TagManager, { DataLayerArgs } from 'react-gtm-module'
import { Config } from '../../config'
import { RoutesGTM } from '../../constants/routes/gtm'
import { UtilsLog } from '../../utils/logs'

const send = (gtmRoute: keyof typeof RoutesGTM.Routes) => {
  const { Title, Path } = RoutesGTM.Routes[gtmRoute]

  const info: DataLayerArgs = {
    dataLayer: {
      event: 'displaynote-event',
      page_path: Path,
      page_title: Title,
    },
  }

  Config.Logs.GoogleGTM && UtilsLog.devLog('GTM ~>', info)

  TagManager.dataLayer(info)
}

// ~~~~~

export const GoogleGTM = {
  send,
}
