import styled from 'styled-components'
import { Colors } from '../../style/theme/colors'
import { Fonts } from '../../style/theme/fonts'
import { theme } from '../../style/utils/theme'

export const STRootPage = styled.div`
  @keyframes fade {
    0%,
    100% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
  }

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${theme(Colors.WOLF_00_100)};

  .root-content {
    animation: fade 3s linear infinite;

    .root-logo {
      display: flex;
      justify-content: center;

      margin-bottom: 16px;

      svg {
        width: 64px;
      }
    }

    .root-info {
      ${Fonts.Scale.Title}
      ${Fonts.Weight[500]}

      color: ${theme(Colors.WOLF_100_05)};
    }
  }
`
