import { Config } from '../../../../../config'

// ~~~~~~ Constants

const message: DN.Services.Extension.ToExtension.UpdateInfo = {
  type: 'update-info',
}

// ~~~~~~

export const sendMessageToExtensionUpdateInfo = () => {
  if (
    Config.Extension.Id === 'no-extension-id' ||
    !window.chrome ||
    !window.chrome.runtime ||
    !window.chrome.runtime.sendMessage
  ) {
    return
  }

  chrome.runtime.sendMessage(Config.Extension.Id, message)
}
