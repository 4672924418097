import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from '../../../../../components/common/intl/trans'
import { ToastUtil } from '../../../../../components/common/toast/util'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { UiLayoutPanelsFieldsMR } from '../../../../../models/bcast/ui-layout-panels/model'
import { ClassName, STStartedLeftPanelMainBcastOnlineInfo } from './style'

// ~~~~~~ Constants

const CloseIcon = LazySVG('icons/close')

const InfoIcon = LazySVG('icons/info')

// ~~~~~~ Component

export const StartedLeftPanelMainBcastOnlineInfo = React.forwardRef<HTMLDivElement>(
  (_props, selfRef) => {
    // ~~~~~~ Hooks

    const dispatch = useDispatch()

    // ~~~~~~ State

    // - Local

    const broadcastId = useSelector((state: StoreState) =>
      Config.Features.BcastService2 ? state.dnBcast.publicBcastId : state.streamManagerPubMain.id,
    )

    // ~~~~~~ Handlers

    function onClickCloseIcon() {
      dispatch(UiLayoutPanelsFieldsMR.savedNotPaintingLeftPanelOpenState.MC.change('closed'))

      dispatch(UiLayoutPanelsFieldsMR.savedPaintingLeftPanelOpenState.MC.change('closed'))

      dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('closed'))
    }

    function onClickCopyId() {
      navigator &&
        navigator.clipboard
          .writeText(broadcastId)
          .then(() => {
            ToastUtil.basic({
              kind: 'success',
              intlIds: [{ id: 'pages.started.left-panel.main.copy.BroadcastId.Success' }],
            })
          })
          .catch(() => {
            ToastUtil.basic({
              kind: 'error',
              duration: 7000,
              intlIds: [{ id: 'pages.started.left-panel.main.copy.BroadcastId.Error' }],
            })
          })
    }

    function onClickCopyLink() {
      navigator &&
        navigator.clipboard
          .writeText(`${Config.Origin}/${broadcastId}`)
          .then(() => {
            ToastUtil.basic({
              kind: 'success',
              intlIds: [{ id: 'pages.started.left-panel.main.copy.Link.Success' }],
            })
          })
          .catch(() => {
            ToastUtil.basic({
              kind: 'error',
              duration: 7000,
              intlIds: [{ id: 'pages.started.left-panel.main.copy.Link.Error' }],
            })
          })
    }

    // ~~~~~~ Render

    return (
      <STStartedLeftPanelMainBcastOnlineInfo ref={selfRef}>
        {/* Close panel */}

        <div className={`${ClassName}--close-btn`}>
          <div onClick={onClickCloseIcon}>
            <CloseIcon size={40} />
          </div>
        </div>

        {/* Bcast online info */}

        <div className={`${ClassName}--line-bcast-title`}>
          <Trans id="pages.started.left-panel.main.BcastOnlineInfo" />
        </div>

        <div className={`${ClassName}--line-bcast-content`}>broadcast.online</div>

        {/* ID info */}

        <div className={`${ClassName}--line-id-title`}>
          <Trans id="pages.started.left-panel.main.IdInfo" />
        </div>

        <div className={`${ClassName}--line-id-content`} onClick={onClickCopyId}>
          {broadcastId}
        </div>

        {/* Copy */}

        <div className={`${ClassName}--line-copy`}>
          {/* Icon */}

          <div className={`${ClassName}--line-copy-icon`}>
            <InfoIcon size={14} />
          </div>

          {/* Text */}

          <span>
            <span className={`${ClassName}--line-copy-text`}>
              <Trans id="pages.started.left-panel.main.copy.Sentence1" />
            </span>

            <span> </span>

            <span className={`${ClassName}--line-copy-copy`} onClick={onClickCopyLink}>
              <Trans id="pages.started.left-panel.main.copy.Sentence2" />
            </span>
          </span>
        </div>
      </STStartedLeftPanelMainBcastOnlineInfo>
    )
  },
)
