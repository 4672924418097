export const BreackPoint = {
  XS: 320,
  S: 554,
  M: 768,
  L: 913,
  XL: 1200,
} as const

export const StackedBreackPoint = {
  XS: 320,
  S: 424,
  M: 768,
  L: 913,
  XL: 1200,
  SPECIAL_1: 614,
}

export enum DialogBreakPoint {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  SPECIAL_1 = 'SPECIAL-1',
}

export const BreakPointMap = {
  [DialogBreakPoint.XS]: BreackPoint.XS,
  [DialogBreakPoint.S]: BreackPoint.S,
  [DialogBreakPoint.M]: BreackPoint.M,
  [DialogBreakPoint.L]: BreackPoint.L,
  [DialogBreakPoint.XL]: BreackPoint.XL,
  [DialogBreakPoint.SPECIAL_1]: StackedBreackPoint.SPECIAL_1,
} as const

export const StackedBreakPointMap = {
  [DialogBreakPoint.XS]: StackedBreackPoint.XS,
  [DialogBreakPoint.S]: StackedBreackPoint.S,
  [DialogBreakPoint.M]: StackedBreackPoint.M,
  [DialogBreakPoint.L]: StackedBreackPoint.L,
  [DialogBreakPoint.XL]: StackedBreackPoint.XL,
  [DialogBreakPoint.SPECIAL_1]: StackedBreackPoint.SPECIAL_1,
} as const
