import styled from 'styled-components'
import { Transition } from '../../../style/animations/css-animations'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { getThemeValue, theme } from '../../../style/utils/theme'

// ~~~~~~ Constants

const LoaderDistance = 4

// ~~~~~~ Types

export type BtnColorType =
  // Base
  | 'primary'
  | 'secondary'
  | 'tertiary-ghost'

  // State
  | 'success'
  | 'warning'
  | 'error'

  // Only bcast
  | 'buy-cta'
  | 'buy-plan'
  | 'onboarding'

type ColorState = 'base' | 'hover' | 'active'

export type Size = 'xs' | 's' | 'm' | 'l' | 'xl'

// ~~~~~~ Helpers

const getPaddingBySize = ({ $size, $running }: { $size?: Size; $running: boolean }) => {
  const v = $running ? LoaderDistance : 0

  switch ($size) {
    case 'xs':
      return `${3}px ${7}px`

    case 's':
      return `${6 - v}px ${8 - v}px`

    // Default
    case 'm':
      return `${14 - v}px ${16 - v}px`

    case 'l':
      return `${14 - v}px ${18 - v}px`

    case 'xl':
      return `${18 - v}px ${23 - v}px`
  }
}

const getFontSize = ({ $size, $running }: { $size?: Size; $running: boolean }) => {
  if ($running) return ''

  switch ($size) {
    case 'xs':
      return Fonts.Scale.Label

    case 's':
      return Fonts.Scale.Body

    // Default
    case 'm':
      return Fonts.Scale.BodyBig

    case 'l':
      return Fonts.Scale.BodyBig

    case 'xl':
      return Fonts.Scale.BodyBig
  }
}

export const getIconSize = ({ $size }: { $size?: Size }) => {
  switch ($size) {
    case 's':
      return 16

    case 'm':
      return 20

    case 'l':
      return 20

    case 'xl':
      return 20

    default:
      return 18
  }
}

const paddingSize = (props: STProps) => `padding: ${getPaddingBySize(props)}`

// ~~~~~~ Color helpers

// - Base

// -- Primary

const getPrimaryColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        bg: Colors.BRONX_40_30,
        font: Colors.WOLF_100_100,
        border: Colors.BRONX_40_30,
      }

    case 'hover':
      return {
        bg: Colors.BRONX_50_40,
        font: Colors.WOLF_100_100,
        border: Colors.BRONX_50_40,
      }

    case 'active':
      return {
        bg: Colors.BRONX_60_50,
        font: Colors.WOLF_100_100,
        border: Colors.BRONX_60_50,
      }
  }
}

// -- Secondary

const getSecondaryColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        bg: Colors.WOLF_10_80,
        font: Colors.WOLF_100_05,
        border: Colors.WOLF_10_80,
      }

    case 'hover':
      return {
        bg: Colors.WOLF_20_70,
        font: Colors.WOLF_100_05,
        border: Colors.WOLF_20_70,
      }

    case 'active':
      return {
        bg: Colors.WOLF_10_80,
        font: Colors.WOLF_100_05,
        border: Colors.WOLF_10_80,
      }
  }
}

// -- Tertiary Ghost

const getTertiaryGhostColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        bg: { light: 'transparent', dark: 'transparent' },
        font: Colors.WOLF_100_05,
        border: Colors.WOLF_10_80,
      }

    case 'hover':
      return {
        bg: { light: 'transparent', dark: 'transparent' },
        font: Colors.WOLF_100_05,
        border: Colors.WOLF_20_70,
      }

    case 'active':
      return {
        bg: { light: 'transparent', dark: 'transparent' },
        font: Colors.WOLF_100_05,
        border: Colors.WOLF_10_80,
      }
  }
}

// - State

// -- Success

const getSuccessColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        bg: Colors.SITO_40_30,
        font: Colors.WOLF_100_100,
        border: Colors.SITO_40_30,
      }

    case 'hover':
      return {
        bg: Colors.SITO_50_40,
        font: Colors.WOLF_100_100,
        border: Colors.SITO_50_40,
      }

    case 'active':
      return {
        bg: Colors.SITO_60_50,
        font: Colors.WOLF_100_100,
        border: Colors.SITO_60_50,
      }
  }
}

// -- Warning

const getWarningColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        bg: Colors.LISA_40_30,
        font: Colors.WOLF_100_100,
        border: Colors.LISA_40_30,
      }

    case 'hover':
      return {
        bg: Colors.LISA_50_40,
        font: Colors.WOLF_100_100,
        border: Colors.LISA_50_40,
      }

    case 'active':
      return {
        bg: Colors.LISA_60_50,
        font: Colors.WOLF_100_100,
        border: Colors.LISA_60_50,
      }
  }
}

// -- Error

const getErrorColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        bg: Colors.BROOKLYN_50_30,
        font: Colors.WOLF_00_100,
        border: Colors.BROOKLYN_50_30,
      }

    case 'hover':
      return {
        bg: Colors.BROOKLYN_40_40,
        font: Colors.WOLF_00_100,
        border: Colors.BROOKLYN_40_40,
      }

    case 'active':
      return {
        bg: Colors.BROOKLYN_50_30,
        font: Colors.WOLF_00_100,
        border: Colors.BROOKLYN_50_30,
      }
  }
}

// - Only BCast

// -- Buy CTA

const getBuyCTA = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        bg: Colors.WOLF_95_05,
        font: Colors.WOLF_00_100,
        border: Colors.WOLF_95_05,
      }

    case 'hover':
      return {
        bg: Colors.WOLF_90_10,
        font: Colors.WOLF_00_100,
        border: Colors.WOLF_90_10,
      }

    case 'active':
      return {
        bg: Colors.WOLF_95_05,
        font: Colors.WOLF_00_100,
        border: Colors.WOLF_95_05,
      }
  }
}

// -- Buy Plan

const getBuyPlan = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        bg: Colors.BRONX_30_40,
        font: Colors.WOLF_100_100,
        border: Colors.BRONX_30_40,
      }

    case 'hover':
      return {
        bg: Colors.BRONX_40_50,
        font: Colors.WOLF_100_100,
        border: Colors.BRONX_40_50,
      }

    case 'active':
      return {
        bg: Colors.BRONX_50_60,
        font: Colors.WOLF_100_100,
        border: Colors.BRONX_50_60,
      }
  }
}

// -- Onboarding

const getOnboardingColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        bg: Colors.EMMET_70_90,
        font: Colors.EMMET_00_10,
        border: Colors.EMMET_70_90,
      }

    case 'hover':
      return {
        bg: { light: 'transparent', dark: 'transparent' },
        font: Colors.EMMET_00_10,
        border: Colors.EMMET_70_90,
      }

    case 'active':
      return {
        bg: Colors.EMMET_70_90,
        font: Colors.EMMET_00_10,
        border: Colors.EMMET_70_90,
      }
  }
}

// ~~~~~~

export const getColor = (colorType: BtnColorType, colorState: ColorState) => {
  switch (colorType) {
    // Base

    case 'primary':
      return getPrimaryColor(colorState)

    case 'secondary':
      return getSecondaryColor(colorState)

    case 'tertiary-ghost':
      return getTertiaryGhostColor(colorState)

    // State

    case 'success':
      return getSuccessColor(colorState)

    case 'warning':
      return getWarningColor(colorState)

    case 'error':
      return getErrorColor(colorState)

    // Only bcast

    case 'buy-cta':
      return getBuyCTA(colorState)

    case 'buy-plan':
      return getBuyPlan(colorState)

    case 'onboarding':
      return getOnboardingColor(colorState)
  }
}

const backgroundColor = (colorState: ColorState) => (props: STProps) =>
  `background-color: ${getThemeValue(
    props,
    getColor(props.$colorType, props.$running || !props.$active ? 'base' : colorState).bg,
  )}`

const color = (colorState: ColorState) => (props: STProps) =>
  getThemeValue(props, getColor(props.$colorType, props.$active ? colorState : 'base').font)

const border = (width: number, colorState: ColorState) => (props: STProps) =>
  `border: solid ${width}px ${getThemeValue(
    props,
    getColor(props.$colorType, props.$active ? colorState : 'base').border,
  )}`

// ~~~~~~ Types

export type STProps = {
  $colorType: BtnColorType
  $active: boolean
  $running: boolean
  $padding?: string

  $size?: Size
  $width?: number | string
  $height?: number | string
  $align?: 'left' | 'center' | 'right'
  $forceNoMarginLeft?: boolean
  $fontWeight?: keyof typeof Fonts.Weight
  $fontColor?: ThemeColor
  $borderRadius?: keyof typeof Layout.Border.Radius
}

// ~~~~~~ Component

export const STButton = styled.div<STProps>`
  ${(props) => (props.$forceNoMarginLeft ? 'margin-left: 0 !important' : '')};

  ${(props) =>
    props.$width
      ? `width: ${typeof props.$width === 'string' ? props.$width : `${props.$width}px`}`
      : ''};

  ${(props) =>
    props.$height
      ? `height: ${typeof props.$height === 'string' ? props.$height : `${props.$height}px`}`
      : ''};

  display: inline-block;
  box-sizing: border-box;

  user-select: none;

  ${({ $borderRadius }) =>
    $borderRadius ? Layout.Border.Radius[$borderRadius] : Layout.Border.Radius.A};

  text-align: ${({ $align }) => ($align ? $align : 'center')};

  ${({ $fontWeight }) => ($fontWeight ? Fonts.Weight[$fontWeight] : Fonts.Weight[700])};

  ${getFontSize}

  cursor: ${({ $active, $running }) => (!$active || $running ? 'not-allowed' : 'pointer')};

  ${(props) => (props.$padding ? `padding: ${props.$padding}` : paddingSize(props))};

  /* Disabled */

  opacity: ${({ $active }) => ($active ? 1 : 0.5)};

  ${Transition.ColorBorderAndBackgroundFaster};

  color: ${(props) => (props.$fontColor ? theme(props.$fontColor)(props) : color('base')(props))};

  ${backgroundColor('base')};
  ${border(1, 'base')};

  .button-icon svg {
    width: 20px;
    height: 20px;

    path {
      ${Transition.StrokeFaster};
      fill: ${color('base')};
    }
  }

  .icon-text {
    display: flex;
    align-items: center;

    ${getFontSize}

    .button-icon {
      width: ${getIconSize}px;
      margin-right: ${Layout.Spacing[1]}px;
      display: flex;

      padding: 2px;
    }

    .text {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100%;
    }
  }

  &:active {
    color: ${color('active')};
    ${backgroundColor('active')};
    ${border(1, 'active')};

    .icon svg {
      width: 20px;
      height: 20px;

      path {
        stroke: ${color('active')};
      }
    }
  }

  /* Ignore :hover css style on touch devices  */

  @media (hover: hover) {
    &:hover:not(:active) {
      color: ${color('hover')};
      ${backgroundColor('hover')};
      ${border(1, 'hover')};

      .icon svg {
        width: 20px;
        height: 20px;

        path {
          stroke: ${color('hover')};
        }
      }
    }
  }
`
