import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, takeUntil } from 'rxjs'

import { StoreState } from '../../../../../models/app/model'
import { apiStreamManagerServerGet$ } from '../../../../../services/api-stream-manager/server/get'
import { cancelAll$$ } from '../../../../../subjects/cancel-all/subject'
import {
  ApiStreamManagerServerAC,
  ApiStreamManagerServerAT,
} from '../../../../actions/api-stream-manager/server/actions'
import { cancelCamMic$$ } from '../../../cancel-now/cam-mic/subject'
import { ApiUtils } from '../../../utils'
import { EpicApiServerPubCamMicMC } from './mutators'

type Action = ReturnType<typeof ApiStreamManagerServerAC.getServerPubCamMic>

export const apiStreamManagerServerPubCamGetEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStreamManagerServerAT.GET_SERVER_PUB_CAM_MIC),

    // Api Call

    mergeMap(() => {
      const broadcastPubCamMicId = `${state$.value.streamManagerPubMain.id}-cam`

      // On "cancel all" we abort the response, server/pub reducer uses
      // the cancel action from cancel-now to change its running state

      return apiStreamManagerServerGet$(broadcastPubCamMicId, 'broadcast', 0).pipe(
        takeUntil(cancelAll$$),
        takeUntil(cancelCamMic$$),
      )
    }),

    // Mutator

    map((resServer) =>
      ApiUtils.isSuccess(resServer)
        ? EpicApiServerPubCamMicMC.ok(resServer.response)
        : EpicApiServerPubCamMicMC.error(resServer.response),
    ),
  )
