import { LayoutPanels } from '../../../components/layouts/panels/component'
import { LayoutPanelsContent } from '../../../components/layouts/panels/parts/content/component'
import { LayoutPanelsHeader } from '../../../components/layouts/panels/parts/header/component'
import { LayoutPanelsLpanel } from '../../../components/layouts/panels/parts/lpanel/component'
import { JoinedDesktopContent } from './content/component'
import { JoinedCommonHeader } from '../common/header/component'
import { JoinedCommonLeftPanel } from '../common/left-panel/component'

// ~~~~~~ Component

export const JoinedDesktop = () => {
  // ~~~~~~ Render

  return (
    <LayoutPanels disableManualOpenPanel>
      {/* Left Panel: Common */}

      <LayoutPanelsLpanel>
        <JoinedCommonLeftPanel />
      </LayoutPanelsLpanel>

      {/* Header: Common */}

      <LayoutPanelsHeader>
        <JoinedCommonHeader />
      </LayoutPanelsHeader>

      {/* Content */}

      <LayoutPanelsContent>
        <JoinedDesktopContent />
      </LayoutPanelsContent>

      {/* -- */}
    </LayoutPanels>
  )
}
