import { Observable, of, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { CurrentUserModel } from '../../../models/bcast/current-user/model'
import { SubscriptionModel } from '../../../models/bcast/subscription/model'
import { UserSettingsModel } from '../../../models/bcast/user-settings/model'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

const { Show } = ApiBcastRoutes.CurrentUser

// ####################################################################################################
// ~~~~~~ Current User Show
// ####################################################################################################

type Res = DN.Models.CurrentUser.Api.Show.Res
type ResParsed = DNApiResParsed<DN.Models.CurrentUser.Api.Show.ResParsed>
type ResError = DNApiError<DN.Models.CurrentUser.Api.Show.ResError>

export const apiBcastCurrentUserShow$ = (token: string, headers?: AppHeaders) => {
  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersWithAuth(token, headers),
    method: Show.Method,
    url: Show.getUrl(),
  }).pipe(
    map((res): ResParsed => {
      const finalResponse: DN.Models.CurrentUser.Api.Show.ResParsed = {
        x_token: res.response.x_token || '',

        user: CurrentUserModel.fromBackToFront(res.response.user, res.response.token),

        subscription: SubscriptionModel.fromBackToFront(res.response.subscription),

        providers: res.response.providers,

        userSettings: UserSettingsModel.fromBackToFront(res.response.user.user_settings, {
          isOnboardingRunning: false,
        }),
      }

      return {
        status: res.status,
        response: finalResponse,
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      let err: Infos[]

      switch (res.status) {
        case 404:
          err = [{ id: 'api.current-user.show.ErrorNoSub' }]
          break

        default:
          err = [{ id: 'api.current-user.show.Error' }]
      }

      return of({
        status: res.status,
        response: err,
      })
    }),
  )
}
