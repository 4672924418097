import styled from 'styled-components'
import { MediaQuery } from '../../../../style/constants/media-queries'

export const STSignInPageTopRight = styled.div`
  display: flex;

  align-items: center;

  .sign-in-top-right-to-join {
    .sign-in-top-right-to-join-desktop {
      display: block;
    }

    .sign-in-top-right-to-join-mobile {
      display: none;
    }
  }

  /* Mobile */

  @media screen and (max-width: ${MediaQuery.SM}) {
    .sign-in-top-right-to-join {
      .sign-in-top-right-to-join-desktop {
        display: none;
      }

      .sign-in-top-right-to-join-mobile {
        display: block;
      }
    }
  }
`
