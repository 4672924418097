import { AppInitState } from '../../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ApiBcastGoogleClassRoomAT } from '../../../../actions/api-bcast/google-classroom/actions'
import { EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT } from '../../../../epics/api-bcast/google-classroom/announce-bcast-link/mutators'

const initState = AppInitState.googleClassRoomAnnouncementBcastLink

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Create Announcement - START
// ####################################################################################################

reducer[ApiBcastGoogleClassRoomAT.ANNOUNCE_BCAST_LINK] = (state: State): State => {
  return {
    ...state,
    errors: [],
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Create Announcement - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT.OK] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ Create Announcement - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT.ERROR] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ~~~~~~

export const googleClassRoomAnnouncementBcastLinkReducer = UtilsStore.dynReducer(initState, reducer)
