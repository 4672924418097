import { AppInitState } from '../../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { EpicBcastStartSubCamMicMT } from '../../../../epics/bcast/start-sub-cam-mic/mutators'
import { EpicBcastStartSubMainMT } from '../../../../epics/bcast/start-sub-main/mutators'
import { EpicCancelNowCamMicMT } from '../../../../epics/cancel-now/cam-mic/mutators'

const initState = AppInitState.networkCongestionSub

type State = typeof initState

const reducer: any = {}

// ---------------------------------------------------------------------------------------------------
// ------ Screen events
// ---------------------------------------------------------------------------------------------------

// ####################################################################################################
// ~~~~~~ Congestion
// ####################################################################################################

reducer[EpicBcastStartSubMainMT.BANDWITH_STATE_INSUFFICIENT] = (state: State): State => {
  return {
    ...state,
    screenStreamCongestion: 'congestion',

    networkIsOk: false,
  }
}

// ####################################################################################################
// ~~~~~~ Recovery
// ####################################################################################################

reducer[EpicBcastStartSubMainMT.BANDWITH_STATE_RECOVERING] = (state: State): State => {
  return {
    ...state,
    screenStreamCongestion: 'recovery',

    networkIsOk: state.camMicStreamCongestion !== 'congestion',
  }
}

// ---------------------------------------------------------------------------------------------------
// ------ Cam-Mic events
// ---------------------------------------------------------------------------------------------------

// ####################################################################################################
// ~~~~~~ Congestion
// ####################################################################################################

reducer[EpicBcastStartSubCamMicMT.BANDWITH_STATE_INSUFFICIENT] = (state: State): State => {
  return {
    ...state,
    camMicStreamCongestion: 'congestion',

    networkIsOk: false,
  }
}

// ####################################################################################################
// ~~~~~~ Recovery
// ####################################################################################################

reducer[EpicBcastStartSubCamMicMT.BANDWITH_STATE_RECOVERING] = (state: State): State => {
  return {
    ...state,
    camMicStreamCongestion: 'recovery',

    networkIsOk: state.screenStreamCongestion !== 'congestion',
  }
}

// ---------------------------------------------------------------------------------------------------
// ------ Cancels
// ---------------------------------------------------------------------------------------------------

// ####################################################################################################
// ~~~~~~ Cancel cam-mic
// ####################################################################################################

reducer[EpicCancelNowCamMicMT.DONE] = (state: State): State => {
  return {
    ...state,
    camMicStreamCongestion: initState.camMicStreamCongestion,

    networkIsOk: state.screenStreamCongestion !== 'congestion',
  }
}

// ####################################################################################################
// ~~~~~~ Cancel All
// ####################################################################################################

reducer[EpicCancelNowCamMicMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~~

export const NetworkCongestionSubReducer = UtilsStore.dynReducer(initState, reducer)
