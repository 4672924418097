import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, takeUntil } from 'rxjs'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreCheckoutSendEmailPass$ } from '../../../../../services/api-store/checkout/send-email-pass'
import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'

import { ApiUtils } from '../../../utils'
import { ApiCheckout$$ } from '../subject/subject'
import { ApiCheckoutSendEmailPassEpicMC } from './mutators'

type Action = ReturnType<typeof ApiStoreCheckoutAC.sendEmailPass>

export const apiStoreCheckoutSendEmailEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.SEND_EMAIL_PASS),

    mergeMap(({ payload }) =>
      apiStoreCheckoutSendEmailPass$(payload).pipe(takeUntil(ApiCheckout$$)),
    ),

    map((res) =>
      ApiUtils.isSuccess(res)
        ? ApiCheckoutSendEmailPassEpicMC.ok()
        : ApiCheckoutSendEmailPassEpicMC.error(res.response),
    ),
  )
