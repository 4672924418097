const initGoogleModel: DN.Models.Provider.GoogleModelFront = {
  uiStatus: 'init',
  errors: [],
  classroom_announcements: false,
  classroom_list: false,
  slides: false,
  drive: false,
}

const authorizationCodeInsufficientPermissionsIntlId: IntlMsgId =
  'api.providers.link-google.ErrorInsufficientPermissions'

const customErrors = {
  authorizationCodeInsufficientPermissionsIntlId,
}

// ~~~~~~

export const ProviderModel = {
  initGoogleModel,
  customErrors,
}
