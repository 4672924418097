import styled from 'styled-components'
import { Transition } from '../../../../../style/animations/css-animations'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'started-left-panel-main-classroom'

export const Padding = 12

export const ItemHeight = 48

// ~~~~~~ Component

export const STStartedLeftPanelMainClassroom = styled.div`
  border: solid 1px ${theme(Colors.WOLF_10_80)};

  ${Layout.Border.Radius.A}

  padding: ${Padding}px;

  .${ClassName}--title-slider {
    ${Fonts.Weight[700]}
    ${Fonts.Scale.BodyBig}

    color: ${theme(Colors.WOLF_100_05)};

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /* Closed */

  .${ClassName}--closed-info {
    /* padding:
    Should be top because the component is not alwawys renderer.
    If we have padding-bottom in previous component, a not wanted space will be here
    when the component is not renderer
    */
    padding-top: ${Layout.Spacing[2]}px;

    ${Fonts.Weight[500]}
    ${Fonts.Scale.Body}
  }

  /* Already shared */

  .${ClassName}--already-shared {
    /* padding:
    Should be top because the component is not alwawys renderer.
    If we have padding-bottom in previous component, a not wanted space will be here
    when the component is not renderer
    */
    padding-top: ${Layout.Spacing[4]}px;

    .${ClassName}--already-shared--info {
      ${Fonts.Scale.Body}

      color: ${theme(Colors.WOLF_100_05)};
    }

    .${ClassName}--already-shared--selected {
      display: flex;
      align-items: center;

      height: ${ItemHeight}px;

      padding-bottom: ${Layout.Spacing[4]}px;

      .${ClassName}--already-shared--selected--icon {
        display: flex;

        svg {
          width: 16px;
          height: 16px;

          margin-right: ${Layout.Spacing[2]}px;

          path {
            ${Transition.StrokeFaster};

            fill: none;
            stroke: ${theme(Colors.EMMET_50_40)};
          }
        }
      }

      .${ClassName}--already-shared--selected--name {
        color: ${theme(Colors.EMMET_50_40)};
      }
    }

    .${ClassName}--already-shared--action {
    }
  }

  /* List */

  .${ClassName}--classroom-data--quantity {
    ${Fonts.Weight[500]}
    ${Fonts.Scale.Body}

    color: ${theme(Colors.WOLF_100_05)};

    display: flex;
    align-items: center;

    padding-top: 16px;
    padding-bottom: 16px;

    .${ClassName}--classroom-data--quantity--data {
      margin-left: ${Layout.Spacing[1]}px;
      padding: 4px;
      border-radius: 50%;

      min-width: 20px;
      text-align: center;

      background-color: ${theme(Colors.WOLF_10_80)};
    }
  }

  .${ClassName}--classroom-data--list {
    padding-bottom: 24px;

    /* Empty */

    .${ClassName}--classroom-data--list--empty {
      display: flex;
      flex-direction: column;
      align-items: center;

      .${ClassName}--classroom-data--list--empty--icon {
        padding: 24px;

        svg {
          width: 76px;
          height: 76px;

          path {
            fill: none;
            stroke: ${theme(Colors.WOLF_100_05)};
          }
        }
      }

      .${ClassName}--classroom-data--list--empty--text {
        ${Fonts.Scale.Body}

        text-align: center;

        color: ${theme(Colors.WOLF_80_20)};
      }
    }

    /* NOT Empty */

    .${ClassName}--classroom-data--list--item {
      cursor: pointer;

      ${Fonts.Weight[500]}
      ${Fonts.Scale.BodyBig}

      height: ${ItemHeight}px;

      ${Transition.ColorFaster}

      color: ${theme(Colors.WOLF_100_05)};

      border-bottom: solid 1px ${theme(Colors.WOLF_10_80)};

      display: flex;
      align-items: center;

      &.last {
        border-bottom: solid 1px transparent;
      }

      &:hover {
        color: ${theme(Colors.WOLF_80_20)};

        .${ClassName}--classroom-data--list--item--icon {
          svg path {
            fill: none;
            stroke: ${theme(Colors.WOLF_80_20)};
          }
        }
      }

      &:active {
        color: ${theme(Colors.WOLF_100_05)};

        .${ClassName}--classroom-data--list--item--icon {
          svg path {
            fill: none;
            stroke: ${theme(Colors.WOLF_100_05)};
          }
        }
      }

      &.selected {
        color: ${theme(Colors.EMMET_50_30)};

        .${ClassName}--classroom-data--list--item--icon {
          svg path {
            fill: none;
            stroke: ${theme(Colors.EMMET_50_30)};
          }
        }
      }

      .${ClassName}--classroom-data--list--item--icon {
        display: flex;

        svg {
          width: 16px;
          height: 16px;

          margin-right: ${Layout.Spacing[2]}px;

          path {
            ${Transition.StrokeFaster}

            fill: none;
            stroke: ${theme(Colors.WOLF_100_05)};
          }
        }
      }
    }
  }
`
