const prefix = 'at-countdown-midnight-bcast-time-reset'

// ~~~~~~ Action Types

export const CountdownMidnightBcastTimeResetAT = {
  START: `${prefix}-start`,
} as const

// ~~~~~~ Action Creators

export const CountdownMidnightBcastTimeResetAC = {
  start: (subscriptionId: string) => ({
    type: CountdownMidnightBcastTimeResetAT.START,
    payload: { subscriptionId },
  }),
} as const
