import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, tap } from 'rxjs'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { apiBcastGoogleSlidesList$ } from '../../../../../services/api-bcast/google-slides/list-presentation'
import { UtilsLog } from '../../../../../utils/logs'
import {
  ApiBcastGoogleSlidesAC,
  ApiBcastGoogleSlidesAT,
} from '../../../../actions/api-bcast/google-slides/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastGoogleSlidesListPresentationsMC } from './mutators'

type Action = ReturnType<typeof ApiBcastGoogleSlidesAC.listPresentations>

export const apiBcastGoogleSlidesListPresentationsEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastGoogleSlidesAT.LIST_PRESENTATIONS),
    mergeMap(({ payload }) =>
      apiBcastGoogleSlidesList$(
        payload.withGoogleAccessToken.withIt
          ? payload.withGoogleAccessToken
          : {
              ...payload.withGoogleAccessToken,
              token: state$.value.currentUser.token,
            },
      ),
    ),
    tap((res) => {
      if (
        !Config.isExtension ||
        ApiUtils.isSuccess(res) ||
        !window.chrome ||
        !window.chrome.identity
      ) {
        return
      }

      window.chrome.identity.clearAllCachedAuthTokens(() =>
        UtilsLog.devLog('clear all cached auth tokens'),
      )
    }),
    map((res) => {
      return ApiUtils.isSuccess(res)
        ? EpicApiBcastGoogleSlidesListPresentationsMC.ok(res.response)
        : EpicApiBcastGoogleSlidesListPresentationsMC.error(
            res.response,
            /* unsync */ res.status === 412,
          )
    }),
  )
