import { StateObservable, ofType } from 'redux-observable'
import { catchError, EMPTY, mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../../../../models/app/model'
import { CommChannelService } from '../../../../../../../../services/comm-channel'
import { CommChannelAC, CommChannelAT } from '../../../../../../../actions/comm-channel/actions'
import { EpicBcastCommChannelMessagesToServerBcasterMicEnableMC } from './mutators'
import { UtilsLog } from '../../../../../../../../utils/logs'
import { CancelNowAC, CancelReasons } from '../../../../../../../actions/cancel-now/actions'
import { BroadcastAC } from '../../../../../../../actions/bcast/actions'

type Action = ReturnType<typeof CommChannelAC.sendBcasterMicEnable>

export const commChannelMessagesToServerBcasterMicEnableEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(CommChannelAT.SEND_BCASTER_MIC_ENABLE),

    mergeMap(() => {
      const websocket = state$.value.commChannel.webSocket

      if (!websocket) return EMPTY

      return CommChannelService.SendMessage.WithAck.BcasterMicTrack.Enable$(websocket).pipe(
        catchError((err: any) => {
          UtilsLog.devLog(
            'BcasterMicTrack.Enable',
            'Error. WSState:',
            state$.value.commChannel.webSocket?.readyState,
          )

          return of({
            type: 'err',
          } as const)
        }),
      )
    }),

    mergeMap(({ type }) => {
      switch (type) {
        case 'ack':
          return of(EpicBcastCommChannelMessagesToServerBcasterMicEnableMC.ok())

        case 'ack-timeout':
          return of(EpicBcastCommChannelMessagesToServerBcasterMicEnableMC.timeout())

        case 'err':
          return of(
            BroadcastAC.exit('comm-channel-err'),
            CancelNowAC.cancelAll([CancelReasons.CommChannelErr]),
          )
      }
    }),
  )
