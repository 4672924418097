import { AppInitState } from '../../../../models/app/model'
import { PaintBcastStreamEditFieldsMR } from '../../../../models/bcast/paint-bcast-stream/model'
import { UtilsStore } from '@dn/utils'
import { CancelNowAT } from '../../../actions/cancel-now/actions'

const initState = AppInitState.paintBcastStream

type State = typeof initState

const reducer: any = {
  ...PaintBcastStreamEditFieldsMR.enabled.Reducer,
}

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ~~~~~~

export const paintBcastStreamReducer = UtilsStore.dynReducer(initState, reducer)
