import { ofType, StateObservable } from 'redux-observable'
import { map, Observable } from 'rxjs'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { ModalsMC } from '../../../actions-mutators/modals/mutators'
import {
  EpicBcastCommChannelMessagesFromServerMC,
  EpicBcastCommChannelMessagesFromServerMT,
} from '../messages/from-server/mutators'

type Action = ReturnType<
  typeof EpicBcastCommChannelMessagesFromServerMC.predefMsgConfirmCloseNoViewers
>

export const onPredefMsgConfirmCloseNoViewers$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicBcastCommChannelMessagesFromServerMT.PREFED_MSG_CONFIRM_CLOSE_NO_VIEWERS),

    map(({ payload }) =>
      ModalsMC.open(ModalsIds.ConfirmPredefMsgWillCloseBecauseNoViewers, /*opts*/ undefined, {
        value: payload.values.mins,
      }),
    ),
  )
