import { InfoBox } from '../component'
// ~~~~~~ Constants

const hasGetDisplayMedia = !!navigator?.mediaDevices?.getDisplayMedia

// ~~~~~~ Types

type Props = {
  className: string
}

// ~~~~~~ Component

export const InfoBoxDeviceNotSupported: React.FC<Props> = ({ className }) => {
  if (hasGetDisplayMedia) return null

  return (
    <div className={className}>
      <InfoBox
        infos={[
          {
            id: 'common-components.info-box.device-not-supported.Content',
          },
        ]}
        $boxType="INFO"
        withIcon
      />
    </div>
  )
}
