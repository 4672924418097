const prefix = 'mt-subscriptions-check-payment-method'

export const EpicApiSubscriptionsCheckPaymentMethodMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

export const EpicApiSubscriptionsCheckPaymentMethodMC = {
  ok: (data: DN.Models.StoreSubscription.Api.CheckPaymentMethod.ResParsed) => ({
    type: EpicApiSubscriptionsCheckPaymentMethodMT.OK,
    payload: data,
  }),

  error: (status: number, errors: DN.Models.StoreSubscription.Api.CheckPaymentMethod.ResError) => ({
    type: EpicApiSubscriptionsCheckPaymentMethodMT.ERROR,
    error: true,
    payload: {
      status,
      errors,
    },
  }),
} as const
