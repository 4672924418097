import { UtilsMobile } from '@dn/utils'
import { UserMenuDesktopTablet } from './desktop-tablet/component'
import { UserMenuMobile } from './mobile/component'

// ~~~~~~ Constants

const isMobile = UtilsMobile.calcIsMobile()

// ~~~~~~ Component

export const UserMenu = () => {
  // ~~~~~~ Render

  return isMobile ? <UserMenuMobile /> : <UserMenuDesktopTablet />
}
