import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Transition } from '../../../../../style/animations/css-animations'

import { Colors } from '../../../../../style/theme/colors'
import { HexAlpha } from '../../../../../style/theme/colors/alpha'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'started-main-mirror-effect-layer'

// ~~~~~~ Component

export const STStartedContentMirrorEffectLayer = styled(motion.div)`
  position: absolute;
  z-index: 5;

  /* Expand */

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  ${Layout.Border.Radius.A}

  padding: ${Layout.Spacing[4]}px;
  padding-top: ${Layout.Spacing[3]}px;

  background: ${theme(Colors.WOLF_95_95)}${HexAlpha[90]};

  display: flex;

  /* Info */

  .${ClassName}--info {
    display: flex;
    padding-right: ${Layout.Spacing[4]}px;

    max-width: 600px;

    /* Icon */

    .${ClassName}--info--icon {
      margin-right: ${Layout.Spacing[2]}px;

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: ${theme(Colors.WOLF_05_05)};
        }
      }
    }

    /* Text */

    .${ClassName}--info--text {
      ${Fonts.Scale.Body}
      ${Fonts.Weight[300]}

      color: ${theme(Colors.WOLF_05_05)};
      padding-right: 20px;

      /* Link more info */

      .${ClassName}--info--text--link {
        cursor: pointer;

        ${Fonts.Weight[500]}

        text-decoration: underline;

        letter-spacing: 0.5px;

        ${Transition.ColorFaster}

        color: ${theme(Colors.WOLF_05_05)};

        &:hover {
          color: ${theme(Colors.WOLF_10_10)};
        }
      }
    }
  }

  /* Warning */

  .${ClassName}--warning {
    display: flex;
    justify-content: center;
    align-items: center;

    color: ${theme(Colors.WOLF_05_05)};
  }
`
