import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'

export const STDialogExitEditingImage = styled.div`
  ${Fonts.Scale.Body}
  text-align: center;

  color: ${theme(Colors.WOLF_80_20)};
`
