import { EMPTY, Observable, of, mergeMap } from 'rxjs'
import { StateObservable, ofType } from 'redux-observable'
import { ServiceWebRtc } from '@dn/webrtc'
import { Config } from '../../../../config'
import { StoreState } from '../../../../models/app/model'
import { BroadcastAC } from '../../../actions/bcast/actions'
import {
  EpicApiServerPubCamMicMC,
  EpicApiServerPubCamMicMT,
} from '../../api-stream-manager/server/pub-cam-mic/mutators'

type Action = ReturnType<typeof EpicApiServerPubCamMicMC.ok>

export const chainingPubCamMicFromGetServerCamMicPubToStartPubEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiServerPubCamMicMT.OK),

    mergeMap(({ payload }) => {
      const {
        sharedCamMicStream,
        subscription,
        stunTurnCredentials: sturnTurnCredentials,
      } = state$.value

      const { curCamMicStream } = sharedCamMicStream

      if (!curCamMicStream) return EMPTY

      const videoTrack = curCamMicStream.getVideoTracks()[0]
      const audioTrack = curCamMicStream.getAudioTracks()[0]

      if (!videoTrack && !audioTrack) return EMPTY

      let videoBitrate = 0

      if (videoTrack) {
        const videoSettings = videoTrack.getSettings()

        const { width, height } = videoSettings

        if (typeof width !== 'number' || typeof height !== 'number') return EMPTY

        videoBitrate = ServiceWebRtc.Codecs.calcH264Bandwidth({
          h264Profile: 'high',
          width,
          height,
          frameRate: Config.CamMic.fps,
        })
      }

      const { serverAddress, broadcastId } = payload.server

      return of(
        BroadcastAC.startPubCamMic(
          {
            serverAddress,
            broadcastId,
            host: Config.Api.StreamManager.URI,
            videoBitrate,
            audioBitrate: Config.CamMic.micBandwidth,
            mediaElementId: 'cam-mic-stream-pub',
          },
          curCamMicStream,
          {
            username: 'username',
            password: 'password',
            token: subscription.token,
          },
          [
            {
              urls: sturnTurnCredentials.uris,
              username: sturnTurnCredentials.username,
              credential: sturnTurnCredentials.password,
            },
          ],
        ),
      )
    }),
  )
