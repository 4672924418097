import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { PeerStats } from '../../components/common/peer-stats/component'
import { DevicePIPEnabled } from '../../constants/misc'
import { ModalsIds } from '../../constants/modals'
import { BroadcastIdRouteParams, Routes } from '../../constants/routes/routes'
import { useCheckIsBroadcastIdOrRedirect } from '../../hooks/check-params/use-check-param-broadcast-id'
import { useModelRootUpdateUserkind } from '../../hooks/model/root/use-model-root-update-userkind'
import { useKeycodeToEnablePeerConnStats } from '../../hooks/stats/use-keycode-to-enable'
import { StoreState } from '../../models/app/model'
import { ModalsMC } from '../../store/actions-mutators/modals/mutators'
import { UtilsFullscreen, UtilsMobile } from '@dn/utils'
import { UtilsLog } from '../../utils/logs'
import { JoinedDesktop } from './desktop/component'
import { JoinedDialogs } from './dialogs/component'
import { JoinedMobile } from './mobile/component'
import { JoinedTablet } from './tablet/component'
import { BcastTrackEvents } from '../../services/track-events'

// ~~~~~~ Constants

const isTablet = UtilsMobile.calcIsTablet()

const isMobile = UtilsMobile.calcIsMobile()

// ~~~~~~ Component

export const JoinedPage = () => {
  // ~~~~~~ Hooks

  useModelRootUpdateUserkind('viewer')

  useKeycodeToEnablePeerConnStats()

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { broadcastId } = useParams<BroadcastIdRouteParams>()

  useCheckIsBroadcastIdOrRedirect({
    broadcastId,
    redirectIfUndef: false,
    redirectTo: Routes.Main,
  })

  // ~~~~~~ State

  const subscriber = useSelector((state: StoreState) => state.streamManagerSubMain.subscriber)

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  const {
    shouldExit,
    reason: exitReason,
    // uiStatus: exitUIStatus,
  } = useSelector((state: StoreState) => state.exit)

  const connectionStatus = useSelector((state: StoreState) => state.root.connectionStatus)

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Effects

  // - Page event

  useEffect(() => {
    // Join with link enter to this page and are redirected to joining
    // so checking subscriber to not send the event page in this situation
    if (!subscriber && !dnBcast.roomIsConnected) return

    BcastTrackEvents.calls.Page.view('/joined')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - Redirect to joining if we don't have a subscriber to start connecting

  useEffect(() => {
    if (shouldExit || subscriber || dnBcast.roomIsConnected) return

    // Join by link https://doma.in/123456

    if (!broadcastId) {
      UtilsLog.devLog(String.fromCodePoint(128256), '/joined', 'no bcastId redirect to', '/main')

      navigate(redirectPath, { replace: true })

      return
    }

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      'joined',
      'no subscriber redirect to joining with id',
      broadcastId,
    )

    const joiningPath = Routes.genJoining(broadcastId)

    navigate(`${joiningPath}${window.location.search}`, { replace: true })

    //
  }, [
    subscriber,
    broadcastId,
    shouldExit,
    exitReason,
    navigate,
    redirectPath,
    dnBcast.roomIsConnected,
  ])

  // - Show modal to exit with the reason

  useEffect(() => {
    if (!shouldExit) return

    dispatch(ModalsMC.closeSelection([ModalsIds.ExitEditingImage, ModalsIds.GoogleSignIn]))

    try {
      DevicePIPEnabled && document.exitPictureInPicture().catch(() => undefined)
    } catch (err: any) {}

    try {
      isMobile && UtilsFullscreen.exitFullScreen()
    } catch (err: any) {}

    switch (exitReason) {
      case 'user-limit':
        dispatch(ModalsMC.open(ModalsIds.ConfirmUserLimitReached))
        break

      case 'network-offline':
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByOffline))
        break

      case 'data-channel-error':
      case 'conn-fail':
      case 'comm-channel-err':
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByConnFail))
        break

      case 'websoket-fail':
        dispatch(ModalsMC.open(ModalsIds.ConfirmViewerDisconnectedByWebsocketFail))
        break

      case 'invalid-name':
      case 'session-end':
        dispatch(ModalsMC.open(ModalsIds.ViewHasEnded))
        break

      case 'user-manual-exit':
      default:
        UtilsLog.devLog(
          String.fromCodePoint(128256),
          '/joined',
          'manual exit redirect to',
          redirectPath,
        )

        navigate(redirectPath, { replace: true })
    }
  }, [connectionStatus, dispatch, exitReason, navigate, redirectPath, shouldExit])

  // ~~~~~~ Render

  if (!subscriber && !dnBcast.roomIsConnected && exitReason === 'init') return null

  switch (true) {
    case isMobile:
      return (
        <>
          <JoinedDialogs />
          <JoinedMobile />
        </>
      )

    case isTablet:
      return (
        <>
          <JoinedDialogs />
          <JoinedTablet />
        </>
      )

    default:
      return (
        <>
          <PeerStats />
          <JoinedDialogs />
          <JoinedDesktop />
        </>
      )
  }
}
