import styled from 'styled-components'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Colors } from '../../../../style/theme/colors'
import { HexAlpha } from '../../../../style/theme/colors/alpha'
import { Misc } from '../../../../style/theme/misc'
import { getThemeValue, theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

const fadeInTime = '260ms'
const fadeOutTime = '300ms'

const width = '96px'
const height = width

const micMutedW = `36px`
const micMutedH = micMutedW

const mobileW = `70px`
const mobileH = mobileW

const mobileMicMutedW = `24px`
const mobileMicMutedH = mobileMicMutedW

const borderRadius = '0'

// ~~~~~~ Component

export const STCamMicSub = styled.div`
  position: relative;

  width: ${width};
  height: ${height};

  &:hover {
    .cam-mic-sub {
      .cam-mic-sub-hover {
        visibility: visible;
        opacity: 1;
        transition: all ${fadeInTime} ease-in;
      }
    }
  }

  .cam-mic-sub {
    position: relative;

    width: ${width};
    height: ${height};

    .cam-mic-sub-hover {
      visibility: hidden;
      opacity: 0;
      transition: all ${fadeOutTime} ease-out;

      position: absolute;
      z-index: 4;

      width: 100%;
      height: 100%;

      background-color: transparent;

      display: flex;
      align-items: center;
      justify-content: center;

      .cam-mic-sub-controls {
        ${Misc.BoxShadow}

        width: 44px;
        height: 44px;

        background-color: ${theme(Colors.WOLF_100_100)}${HexAlpha[60]};

        display: flex;
        align-items: center;
        justify-content: center;

        .cam-mic-sub-controls-speaker {
          cursor: pointer;

          display: flex;

          svg {
            width: 20px;
            height: 20px;

            path {
              stroke: ${theme(Colors.WOLF_05_05)};
            }
          }
        }
      }
    }

    .cam-muted {
      position: absolute;
      z-index: 3;

      width: calc(100% - 12px);
      height: calc(100% - 12px);
      top: 0;
      left: 0;

      border: solid 6px ${theme(Colors.WOLF_90_90)};
      border-radius: ${borderRadius};

      background-color: ${theme(Colors.WOLF_95_95)};

      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 40px;
        height: 40px;

        path {
          stroke: ${theme(Colors.WOLF_05_05)};
        }
      }
    }

    video.cam-mic-sub-video {
      position: absolute;
      z-index: 1;

      object-fit: cover;
      border-radius: ${borderRadius};

      background-color: ${theme(Colors.WOLF_95_95)};

      width: ${width};
      height: ${height};

      transform: rotateY(180deg);
    }
  }

  .mic-muted {
    position: absolute;
    z-index: 4;

    width: ${micMutedW};
    height: ${micMutedH};

    bottom: 0px;
    left: calc(${width} - ${micMutedW});

    border-radius: ${borderRadius ? borderRadius : '7px'};

    background-color: ${(props) =>
      borderRadius === '0'
        ? `${getThemeValue(props, Colors.WOLF_95_95)}${HexAlpha[80]}`
        : getThemeValue(props, Colors.WOLF_95_95)};

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;

      path {
        stroke: ${theme(Colors.WOLF_05_05)};
      }
    }
  }

  @media (max-width: ${MediaQuery.SM}) {
    width: ${mobileW};
    height: ${mobileH};

    .cam-mic-sub {
      width: ${mobileW};
      height: ${mobileH};

      .cam-muted {
        border-width: 3px;

        width: calc(100% - 6px);
        height: calc(100% - 6px);

        svg {
          width: 20px;
          height: 20px;
        }
      }

      video.cam-mic-sub-video {
        width: ${mobileW};
        height: ${mobileH};
      }
    }

    .mic-muted {
      width: ${mobileMicMutedW};
      height: ${mobileMicMutedH};

      left: calc(${mobileW} - ${mobileMicMutedW});
      bottom: 0px;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
`
