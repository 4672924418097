const prefix = 'mt-root'

// ~~~~~~ Mutators Types

export const RootMT = {
  SHOW_SIDE_MENU: `${prefix}-show-side-menu`,
  HIDE_SIDE_MENU: `${prefix}-hide-side-menu`,
} as const

// ~~~~~~ Mutators Creators

export const RootMC = {
  showSideMenu: () => ({
    type: RootMT.SHOW_SIDE_MENU,
  }),

  hideSideMenu: () => ({
    type: RootMT.HIDE_SIDE_MENU,
  }),
} as const
