import { UnknownAction } from 'redux'
import { ofType, StateObservable } from 'redux-observable'
import { Observable, of, mergeMap } from 'rxjs'
import { UtilsDates, UtilsOS, UtilsLang } from '@dn/utils'
import { HocTheme } from '@dn/hocs'

import { StoreState } from '../../../../../models/app/model'
import { apiBcastCurrentUserShow$ } from '../../../../../services/api-bcast/current-user/get'
import {
  ApiBcastCurrentUserAC,
  ApiBcastCurrentUserAT,
} from '../../../../actions/api-bcast/current-user/actions'
import { CowntdownFreePremiumDaysAC } from '../../../../actions/countdown/free-premium-days/actions'
import { CountdownMidnightBcastTimeResetAC } from '../../../../actions/countdown/midnight-bcast-time-reset/actions'
import { IntlAC } from '../../../../actions/intl/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastCurrentUserShowMC } from './mutators'
import { Config } from '../../../../../config'
import { LocalStorageLang } from '../../../../../services/storage/local/lang'

// ~~~~~~ Constants

const { DefaultLocale, SupportedLocales } = Config.Intl

const StoredLang = LocalStorageLang.get()

// ~~~~~~ Types

type Action = ReturnType<typeof ApiBcastCurrentUserAC.show>

// ~~~~~~ Epic

export const apiBcastCurrentUserShowEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastCurrentUserAT.SHOW),

    mergeMap(({ payload }) => apiBcastCurrentUserShow$(payload.token)),

    mergeMap((res) => {
      if (!ApiUtils.isSuccess(res)) {
        return of(EpicApiBcastCurrentUserShowMC.error(res.response))
      }

      const { subscription } = res.response

      // Show

      const actions: UnknownAction[] = [EpicApiBcastCurrentUserShowMC.ok(res.response)]

      if (UtilsDates.isValidStringDate(subscription.free_premium_period_end)) {
        actions.push(CowntdownFreePremiumDaysAC.start(subscription.free_premium_period_end))
      }

      if (subscription.time_limit) {
        actions.push(CountdownMidnightBcastTimeResetAC.start(subscription.id))
      }

      // Lang

      const langAction =
        !res.response.userSettings.lang || res.response.userSettings.lang === 'auto'
          ? IntlAC.changeLang(UtilsLang.getAppLang(SupportedLocales, DefaultLocale, StoredLang))
          : IntlAC.changeLang(res.response.userSettings.lang)

      actions.push(langAction)

      // Theme

      const themeAction =
        !res.response.userSettings.theme || res.response.userSettings.theme === 'auto'
          ? HocTheme.Mutators.MC.changeTheme(UtilsOS.getTheme())
          : HocTheme.Mutators.MC.changeTheme(res.response.userSettings.theme)

      actions.push(themeAction)

      // All actions

      return of(...actions)
    }),
  )
