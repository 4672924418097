import { ofType } from 'redux-observable'
import { delay, mergeMap, Observable, of, startWith, switchMap } from 'rxjs'
import { CountdownService } from '@dn/service-countdown'
import { BcastCountdownMR } from '../../../../../models/bcast/bcast-countdown/model'
import { CancelNowAC, CancelReasons } from '../../../../actions/cancel-now/actions'
import {
  CountdownBcastDurationAC,
  CountdownBcastDurationAT,
} from '../../../../actions/countdown/bcast-duration/actions'

// ~~~~~~ Constants

// 5 seconds as grace period the same we are using to ask to the server
// in auto check based on UTC Time

const GracePeriodBeforeStop = 5000

// ~~~~~~ Actions

type Action = ReturnType<typeof CountdownBcastDurationAC.start>

// ~~~~~~ Epic

export const countdownBcastDurationStartEpic$ = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(CountdownBcastDurationAT.START),

    switchMap(({ payload }) => CountdownService.Start.minutes$(payload.remainingMins)),

    mergeMap((remainingTime) => {
      //
      if (isTimeFinished(remainingTime.minutes, remainingTime.seconds)) {
        return of(CancelNowAC.cancelAll([CancelReasons.TimeLimitReached])).pipe(
          delay(GracePeriodBeforeStop),

          startWith(BcastCountdownMR.timeRemaining.MC.change(remainingTime)),
        )
      }

      return of(BcastCountdownMR.timeRemaining.MC.change(remainingTime))
    }),
  )

// ####################################################################################################
// ~~~~~~ Time finish checker
// ####################################################################################################

const isTimeFinished = (mins: number, seconds: number) => mins === 0 && seconds === 0
