import { Config } from '../../../config'
import { UtilsCommand } from '@dn/utils'
import { UtilsLog } from '../../../utils/logs'
import { addPathCommand } from './commands/add-path/draw-partial-service'
import { clearCommand } from './commands/clear/draw-partial-service'
import { remPathCommand } from './commands/rem-path/draw-partial-service'

// ####################################################################################################
// ~~~~~~ Generate drawings target
// ####################################################################################################

const genDrawingsTarget = (scope: paper.PaperScope) => {
  const target: DN.Services.Draw.DrawingsTarget = {
    scope,
    cmdApplied: 0,
  }

  return target
}

// ####################################################################################################
// ~~~~~~ Commands
// ####################################################################################################

const commands = {
  addPath: addPathCommand,
  remPath: remPathCommand,
  clear: clearCommand,
}

// ####################################################################################################
// ~~~~~~ Generate drawing command manager
// ####################################################################################################

export const genDrawingCommandManager = (scope: paper.PaperScope) => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'genDrawingCommandManager')

  const drawingsTarget = genDrawingsTarget(scope)

  const drawingCommandManager = UtilsCommand.genCommandManager(drawingsTarget, commands)

  return drawingCommandManager
}
