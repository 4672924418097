import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromEvent, debounceTime } from 'rxjs'
import { ConstEventsWindow } from '@dn/constants'
import { StoreState } from '../../../../models/app/model'
import { UiLayoutPanelsFieldsMR } from '../../../../models/bcast/ui-layout-panels/model'
import { MediaQueryNumbers } from '../../../../style/constants/media-queries'

// ~~~~~~ Component

export const LayoutPanelsResizeObserver = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const leftPanelDisplayValue = useSelector(
    (state: StoreState) => state.uiLayoutPanels.leftPanelDisplayValue,
  )

  // ~~~~~~ Effects

  // - Observe resize to determine the size of the left panel

  useEffect(() => {
    const sub = fromEvent(window, ConstEventsWindow.Resize)
      .pipe(debounceTime(200))
      .subscribe({
        next: () => {
          const value: DN.Models.UiLayoutPanels.Model['leftPanelDisplayValue'] =
            window.innerWidth <= MediaQueryNumbers.FLOATING_PANELS
              ? 'hidden'
              : window.innerWidth <= MediaQueryNumbers.MD
                ? 'middle'
                : 'full'

          if (value === leftPanelDisplayValue) return

          dispatch(UiLayoutPanelsFieldsMR.leftPanelDisplayValue.MC.change(value))
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [dispatch, leftPanelDisplayValue])

  // ~~~~~~ Render

  return null
}
