import { AppInitState } from '../../../../../models/app/model'
import { GoogleSlidesAddImageToSlideFieldsMR } from '../../../../../models/bcast/google-presentations/model'
import { UtilsStore } from '@dn/utils'
import { ApiBcastGoogleSlidesAT } from '../../../../actions/api-bcast/google-slides/actions'
import {
  EpicApiBcastGoogleSlidesAddImageSlideMC,
  EpicApiBcastGoogleSlidesAddImageSlideMT,
} from '../../../../epics/api-bcast/google-slides/add-image-slide/mutators'
import { EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT } from '../../../../epics/api-bcast/google-slides/create-presentation-and-add-image-slide/mutators'

import {
  EpicApiBcastProvidersUnlinkGoogleMC,
  EpicApiBcastProvidersUnlinkGoogleMT,
} from '../../../../epics/api-bcast/providers/unlink-google/mutators'

const initState = AppInitState.googleSlidesAddImageToSlide

type State = typeof initState

const reducer: any = {
  ...GoogleSlidesAddImageToSlideFieldsMR.errors.Reducer,
}

// ####################################################################################################
// ~~~~~~ Unlink Google - OK
// ####################################################################################################

reducer[EpicApiBcastProvidersUnlinkGoogleMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastProvidersUnlinkGoogleMC.ok>,
): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Google Slide Add image to slide - START
// ####################################################################################################

reducer[ApiBcastGoogleSlidesAT.ADD_IMAGE_SLIDE] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Google Slide Add image to slide - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesAddImageSlideMT.OK] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Google Slide Add image to slide - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesAddImageSlideMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastGoogleSlidesAddImageSlideMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Google Slide Create and Add image to slide - START
// ####################################################################################################

reducer[ApiBcastGoogleSlidesAT.CREATE_PRESENTATION_AND_ADD_IMAGE_SLIDE] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Google Slide Create and Add image to slide - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.OK] = (
  state: State,
): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Google Slide Create and Add image to slide - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.ERROR] = (
  state: State,
): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ~~~~~~

export const googleSlidesAddImageToSlideReducer = UtilsStore.dynReducer(initState, reducer)
