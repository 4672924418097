import { ProductIds } from '../../../../constants/product-ids'
import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ApiStoreProductsAT, ApiStoreProductsAC } from '../../../actions/api-store/products/actions'
import {
  EpicApiProductsListMT,
  EpicApiProductsListMC,
} from '../../../epics/api-store/products/list/mutators'

const initState = AppInitState.storeProducts

type State = typeof initState

const reducer: any = {}

const ProductId: DN.Models.StoreProducts.ProductId = ProductIds.BroadcastSub

// ####################################################################################################
// ~~~~~~ Action to call api list prodcuts
// ####################################################################################################

reducer[ApiStoreProductsAT.LIST_PRODUCTS] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreProductsAC.listProducts>,
): State => {
  if (payload.productId !== ProductId) return state

  return {
    ...initState,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Api List prodcuts ok
// ####################################################################################################

reducer[EpicApiProductsListMT.OK_BROADCAST_SUB] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiProductsListMC.okBroadcastProducts>,
): State => {
  return {
    ...initState,
    uiStatus: 'completed',
    products: payload.products,
  }
}

// ####################################################################################################
// ~~~~~~ Api list prodcuts error
// ####################################################################################################

reducer[EpicApiProductsListMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiProductsListMC.error>,
): State => {
  if (payload.productId !== ProductId) return state

  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ~~~~~~

export const storeProductsReducer = UtilsStore.dynReducer(initState, reducer, (state) => state)
