import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { BroadcastAC } from '../../../../store/actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../../store/actions/cancel-now/actions'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { DialogConfirmation } from '../component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmStopBroadcasting)

// ~~~~~~ Component

export const DialogConfirmStopBroadcasting = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const [confirmed, setConfirmed] = useState(false)

  const modalsConfirm = useSelector((state: StoreState) => state.modals.confirm)

  // ~~~~~~ Handlers

  function onClickClose() {
    setConfirmed(false)

    setDoClose(performance.now())
  }

  function onClickConfirm() {
    setConfirmed(true)

    setDoClose(performance.now())
  }

  function onEndDissapear() {
    if (!confirmed) {
      setConfirmed(false)

      return
    }

    const onConfirm = modalsConfirm[ModalsIds.ConfirmStopBroadcasting]

    dispatch(BroadcastAC.exit('user-manual-exit'))

    dispatch(CancelNowAC.cancelAll([CancelReasons.UserStopped]))

    onConfirm && onConfirm()
  }

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      Dialog={Dialog}
      title="dialogs.confirm.stop-emit.Title"
      contentText="dialogs.confirm.stop-emit.Content"
      isRunning={false}
      doClose={doClose}
      onEndDisappear={onEndDissapear}
      onClickClose={onClickClose}
      onClickConfirm={onClickConfirm}
      okButtonColorType="error"
    />
  )
}
