import { filter, takeUntil } from 'rxjs'
import { BroadcastStop$$ } from '../broadcast-partial-service'
import { SubjectStopGuards } from '../guards'

export const genTakeUntilStop$ = <T>(id: string) =>
  takeUntil<T>(
    BroadcastStop$$.pipe(
      filter((stop) => {
        if (SubjectStopGuards.isStopAll(stop)) return true

        if (SubjectStopGuards.isStopId(stop) && stop.id === id) return true

        return false
      }),
    ),
  )
