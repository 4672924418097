import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { STJoinedCommonHeaderTopLeft } from './style'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-brand')

// ~~~~~~ Component

export const JoinedCommonHeaderTopLeft = () => {
  // ~~~~~~ Render

  return (
    <STJoinedCommonHeaderTopLeft>
      <Logo size={34} />
    </STJoinedCommonHeaderTopLeft>
  )
}
