import { UtilsStore } from '@dn/utils'

const initModel: DN.Models.UiExtensionMenus.Model = {
  showChangeSource: false,
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-ui-extension-menus'

export const UiExtensionMenusFieldsMR = {
  showChangeSource: UtilsStore.genBasicFieldMR(
    prefix,
    initModel,
    'showChangeSource',
    initModel.showChangeSource,
  ),
}

// ~~~~~~

export const UiExtensionMenusModel = {
  initModel,
} as const
