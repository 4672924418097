import { STTables } from '../style'

// ~~~~~~ Types

export type TableDefinition = {
  word: string
  def: string
}

type Props = {
  definitions: TableDefinition[]
}

// ~~~~~~ Component

export const TablesDefinitioins: React.FC<Props> = ({ definitions }) => {
  // ~~~~~~ Render

  return (
    <STTables>
      {definitions.map((definition, idx) => (
        <tbody key={idx}>
          <tr>
            <td className="word">"{definition.word}"</td>
            <td>{definition.def}</td>
          </tr>
        </tbody>
      ))}
    </STTables>
  )
}
