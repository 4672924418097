import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-float-panel-capture-share'

const Padding = 12

// ~~~~~~ Component

export const STJoinedMobileFloatPanelCaptureShare = styled.div`
  padding: ${Padding}px;

  width: 100%;
  height: 100%;

  .${ClassName}--content {
    width: calc(100% - ${Padding * 2}px);
    height: calc(100% - ${Padding * 2}px);

    display: flex;
    flex-direction: column;

    /* Google slides */

    .${ClassName}--content--slides-card {
      flex: 1;

      border: solid 1px ${theme(Colors.WOLF_10_80)};

      ${Layout.Border.Radius.A};

      padding: ${Padding}px;
    }
  }

  @media (orientation: landscape) {
    transform: rotate(-90deg);
    transform-origin: left top;

    /* Inverse values */

    height: ${({ theme }) => theme.pageWidth}px;
    width: ${({ theme }) => theme.pageHeight}px;

    /* Fix position */

    position: absolute;
    top: ${({ theme }) => theme.pageHeight}px;
  }
`
