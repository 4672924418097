import { of, Observable, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiStoreRoutes } from '../constants/routes'
import { StoreHeaderUtils } from '../utils/headers'

const { UpgradePlan } = ApiStoreRoutes.Subscriptions

// ####################################################################################################
// ~~~~~~ Upgrade Plan
// ####################################################################################################

type Req = DN.Models.StoreSubscription.Api.UpgradePlan.Req
type Res = DN.Models.StoreSubscription.Api.UpgradePlan.Res
type ResParsed = DNApiResParsed<DN.Models.StoreSubscription.Api.UpgradePlan.ResParsed>
type ResError = DNApiError<DN.Models.StoreSubscription.Api.UpgradePlan.ResError>

export const apiStoreSubscriptionsUpgradePlan$ = (
  token: string,
  subscriptionId: string,
  data: Req,
  headers?: AppHeaders,
) => {
  return ajax<Res>({
    ...StoreHeaderUtils.genHeadersWithAuth(token, headers),
    method: UpgradePlan.Method,
    url: UpgradePlan.getUrl(subscriptionId),
    body: data,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        of({
          status: res.status,
          response: [{ id: 'api.subscriptions.upgrade-plan.Error' }],
        }),
    ),
  )
}
