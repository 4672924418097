import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, filter, mergeMap, Observable, of } from 'rxjs'
import { Track } from 'livekit-client'
import { UtilsCanvasToStream } from '@dn/utils'
import { ServiceBcast } from '@dn/bcast'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../actions/live-paint/draw/actions'
import { EpicLivePaintReplaceTrackOnStartDrawMC } from './mutators'

type Action = ReturnType<typeof LivePaintDrawAC.start>

export const livePaintReplaceTrackOnDrawStartEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.START),

    filter(
      () =>
        (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v2'),
    ),

    mergeMap(({ payload }) => {
      const state = state$.value
      const canvas = payload.canvas

      if (!state.livePaintVideo.video) return EMPTY

      return of(
        UtilsCanvasToStream.startWithVideoPlaying$(
          state.livePaintVideo.video,
          state.subscription.fps,
          canvas,
        ),
      )
    }),

    mergeMap(({ track, stop }) => {
      if (Config.isExtension) return EMPTY

      const room = state$.value.dnBcast.room

      if (!ServiceBcast.Guards.isRoom(room)) return EMPTY

      const screenLocalTrackPub = room.localParticipant.getTrackPublication(
        Track.Source.ScreenShare,
      )

      if (!screenLocalTrackPub) return EMPTY

      const localVideoTrack = screenLocalTrackPub.videoTrack

      if (!localVideoTrack) return EMPTY

      const obs$ = new Observable((observer) => {
        localVideoTrack.replaceTrack(track).then(() => {
          // --))-|
          observer.next(EpicLivePaintReplaceTrackOnStartDrawMC.ok(track, stop))
          observer.complete()
        })
      })

      return obs$
    }),
  )
