import React, { useEffect, useState } from 'react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useTheme } from 'styled-components'

import { Colors } from '../../../../../style/theme/colors'
import { InfosList } from '../../../../common/infos/list/component'
import { ClassName, STDialogAddCardForm } from './style'
import { useDispatch, useSelector } from 'react-redux'
import { UtilsLog } from '../../../../../utils/logs'

import { apiStoreCheckoutCardIntent$ } from '../../../../../services/api-store/checkout/card-intent'
import { ApiUtils } from '../../../../../store/epics/utils'
import { StoreState } from '../../../../../models/app/model'
import { ApiStoreSubscriptionsAC } from '../../../../../store/actions/api-store/subscriptions/actions'
import { InfoBox } from '../../../../common/infos/box/component'

// ~~~~~~ Types

type Props = {
  triggerAddCard: number
  onIsRunningChange: (isRunning: boolean) => void
  onApiCall: () => void
}

let timeout: any = 0

// ~~~~~~ Component

export const DialogAddCardForm: React.FC<Props> = ({
  triggerAddCard,
  onIsRunningChange,
  onApiCall,
}) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const theme = (useTheme() as { mode: Theme }).mode

  const stripe = useStripe()

  const elements = useElements()

  // ~~~~~~ State

  const [addCard, setAddCard] = useState(triggerAddCard)

  const [focussed, setFocussed] = useState(false)

  const [stripeErrors, setStripeErrors] = useState<Infos[]>([])

  const { token } = useSelector((state: StoreState) => state.currentUser)

  const { errors, priceId } = useSelector((state: StoreState) => state.storePlan)

  // ~~~~~~ Computed

  const finalErrors = [...errors, ...stripeErrors]

  const isStripeLoaded = !!stripe

  // ~~~~~~ Handlers

  function onChange() {
    if (errors.length) setStripeErrors([])
  }

  function onFocus() {
    setFocussed(true)
  }

  function onBlur() {
    setFocussed(false)
  }

  // ~~~~~ Effects

  // - Stripe add card

  useEffect(() => {
    if (triggerAddCard <= addCard) return

    setAddCard(triggerAddCard)

    setStripeErrors([])

    function stripeAddCard() {
      if (!stripe || !elements) {
        UtilsLog.devLog('no stripe or no elements', !!stripe, !!elements)
        return
      }

      const cardElement = elements.getElement(CardElement)

      if (!cardElement) return

      onIsRunningChange(true)

      apiStoreCheckoutCardIntent$(token).subscribe({
        next: (res) => {
          // Our api call error
          if (!ApiUtils.isSuccess(res)) {
            onIsRunningChange(false)

            setStripeErrors(res.response)

            return
          }

          stripe
            .confirmCardSetup(res.response.intent.client_secret, {
              payment_method: {
                card: cardElement,
              },
            })
            .then((result) => {
              // No catch, always returns error or setupIntent
              const { error, setupIntent } = result

              if (error) {
                UtilsLog.devLog('Card Errors', error)

                onIsRunningChange(false)

                setStripeErrors([{ id: 'wildcard', values: { value: error.message } }])

                return
              }

              if (!setupIntent) {
                onIsRunningChange(false)
                return
              }

              // Upgrade plan

              timeout = setTimeout(() => {
                dispatch(
                  ApiStoreSubscriptionsAC.upgradePlan({
                    price_id: priceId,
                  }),
                )

                onIsRunningChange(false)

                cardElement.clear()

                onApiCall()
              }, 5000)
            })
        },
        error: () => {
          onIsRunningChange(false)
        },
      })
    }

    // Run
    stripeAddCard()

    //
  }, [
    addCard,

    dispatch,
    elements,
    onApiCall,
    onIsRunningChange,
    priceId,
    stripe,
    token,
    triggerAddCard,
  ])

  // ~~~~~~ Effects

  // - Focus on load

  useEffect(() => {
    if (!elements) return

    const timeout = setTimeout(() => {
      const cardElement = elements.getElement(CardElement)

      if (!cardElement) return

      cardElement.focus()
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [elements])

  useEffect(() => {
    return () => {
      clearTimeout(timeout)
    }
  }, [])

  // ~~~~~~ Render

  if (!isStripeLoaded) {
    return (
      <STDialogAddCardForm $focussed={focussed} $hasErrors={!!finalErrors.length}>
        {/* Stripe not loaded message */}

        <InfoBox
          className={`${ClassName}--stripe-error`}
          withIcon={true}
          $boxType="ERROR"
          infos={[{ id: 'dialogs.add-payment-method.errors.LoadStripe' }]}
        />
      </STDialogAddCardForm>
    )
  }

  return (
    <STDialogAddCardForm $focussed={focussed} $hasErrors={!!finalErrors.length}>
      {/* Stripe Card */}

      <CardElement
        className={`${ClassName}--card`}
        options={{
          style: {
            base: {
              fontFamily: 'Gilroy, Arial, sans-serif',
              fontSize: '14px',
              iconColor: Colors.WOLF_40_50[theme],
              color: Colors.WOLF_100_05[theme],
              '::placeholder': {
                color: Colors.WOLF_40_50[theme],
              },
            },
            invalid: {
              iconColor: Colors.BROOKLYN_40_30[theme],
              color: Colors.BROOKLYN_40_30[theme],
            },
          },
        }}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />

      {/* Add card errors */}

      <InfosList infos={finalErrors} />
    </STDialogAddCardForm>
  )
}
