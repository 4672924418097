import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Loader } from '../../../components/common/loader/component'
import { ModalsIds } from '../../../constants/modals'
import { Routes } from '../../../constants/routes/routes'
import { ModalsMC } from '../../../store/actions-mutators/modals/mutators'
import { GoogleGTM } from '../../../third-parties/google-gtm/library'
import { STRedirectionsBuySuccessPage } from './style'
import { BcastTrackEvents } from '../../../services/track-events'

// ~~~~~~ Component

export const RedirectionsBuySuccessPage: React.FC<{}> = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ~~~~~~ Effects

  // - Page event

  useEffect(() => {
    BcastTrackEvents.calls.Page.view('/buy-success')

    // Track

    BcastTrackEvents.calls.BuyProcess.Premium['4-create-success']()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - After pay the subscription in stripe, the user is redirected here
  // we send the events and show the modal "Thanks for buy"

  useEffect(() => {
    navigate(Routes.SignIn, { replace: true })

    dispatch(ModalsMC.open(ModalsIds.ConfirmThanksForBuy))

    GoogleGTM.send('SuccessPaid')

    //
  }, [dispatch, navigate])

  // ~~~~~~ Render

  return (
    <STRedirectionsBuySuccessPage>
      <Loader size={100} />
    </STRedirectionsBuySuccessPage>
  )
}
