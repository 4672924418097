import React from 'react'
import { useDialogContext } from '../hooks/use-dialog-context'

// ~~~~~~ Component

export const Content: React.FC<any> = ({ children }) => {
  const { kind } = useDialogContext()

  return <div style={{ minHeight: 1, width: '100%' }}>{kind === 'content' ? children : null}</div>
}
