import { filter, map, Observable } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'

import { StoreState } from '../../../../../models/app/model'
import { BroadcastAC } from '../../../../actions/bcast/actions'
import { Config } from '../../../../../config'
import {
  EpicApiBcastStreamContextShowMC,
  EpicApiBcastStreamContextShowMT,
} from '../../../api-bcast/stream-context/show/mutators'

type Action = ReturnType<typeof EpicApiBcastStreamContextShowMC.ok>

export const deprecatedChainingSubMainFromGetStreamContextToStartSubEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiBcastStreamContextShowMT.OK),

    filter(
      () =>
        !Config.Features.BcastService2 ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v1'),
    ),

    map(({ payload }) => {
      const { server, stunTurnCredentials } = payload.serverSubMainData

      return BroadcastAC.startSubMain(
        {
          host: Config.Api.StreamManager.URI,
          serverAddress: server.serverAddress,
          broadcastId: server.broadcastId,
          mediaElementId: 'main-stream-sub',
        },
        [
          {
            urls: stunTurnCredentials.uris,
            username: stunTurnCredentials.username,
            credential: stunTurnCredentials.password,
          },
        ],
      )
    }),
  )
