import { useDispatch } from 'react-redux'
import { AutoDownload } from '../../../../../../components/common/auto-download/component'
import { PaintToolbar } from '../../../../../../components/common/paint-toolbar/component'
import { ExitEditingImageDialogActions } from '../../../../../../components/dialogs/exit-editing-image/component'
import { ModalsIds } from '../../../../../../constants/modals'
import { UiLayoutPanelsFieldsMR } from '../../../../../../models/bcast/ui-layout-panels/model'
import { ModalsMC } from '../../../../../../store/actions-mutators/modals/mutators'
import { useStartedContentMainToolbarsLivePaintDraw } from './hooks/draw-logic'
import { useStartedContentMainToolbarsLivePaintResizeAnimation } from './hooks/resize-animation'
import { ClassName, STStartedContentMainButtonsLivePaint } from './style'

// ~~~~~~ Types

type Props = {
  showExitButton?: boolean
}

// ~~~~~~ Component

export const StartedContentMainButtonsLivePaint: React.FC<Props> = ({ showExitButton = true }) => {
  // ~~~~~~ Hooks

  const drawLogic = useStartedContentMainToolbarsLivePaintDraw()

  const resizeAnimation = useStartedContentMainToolbarsLivePaintResizeAnimation(showExitButton)

  const dispatch = useDispatch()

  // ~~~~~~ Handlers

  function onClickSharePaint() {
    drawLogic.genImageToShare()

    dispatch(UiLayoutPanelsFieldsMR.leftPanelShowSharePaints.MC.change(true))

    dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('open'))
  }

  // - Show save before close dialog

  function onClickExitEditingPic() {
    if (!drawLogic.isDirty || drawLogic.isDownloaded) {
      drawLogic.onClickExitEditingPic()

      return
    }

    dispatch(
      ModalsMC.open(ModalsIds.ExitEditingImage, (closeAction) => {
        switch (closeAction) {
          case ExitEditingImageDialogActions.NoDownload:
            drawLogic.onClickExitEditingPic()
            break

          case ExitEditingImageDialogActions.Download:
            drawLogic.onClickSave()
            break
        }
      }),
    )
  }

  // ~~~~~~ Render

  return (
    <STStartedContentMainButtonsLivePaint>
      {/* Auto download image */}

      {!drawLogic.isDownloaded && drawLogic.shouldDownload && drawLogic.fileAndName ? (
        <AutoDownload
          onDownload={drawLogic.onDownload}
          file={drawLogic.fileAndName.file}
          name={drawLogic.fileAndName.name}
        />
      ) : undefined}

      {/* Content: paint controls */}

      <div className={`${ClassName}--content`}>
        <PaintToolbar
          showSharePaintSelector={false}
          actionKind="live-paint"
          showExitButton={showExitButton}
          show={true}
          //
          rootVariants={resizeAnimation.rootVariants}
          btnVariants={resizeAnimation.picBtnVariants}
          colorsBtnVariants={resizeAnimation.colorsBtnVariants}
          pensBtnVariants={resizeAnimation.pensBtnVariants}
          showAllColors={resizeAnimation.showAllColors}
          showAllPens={resizeAnimation.showAllPens}
          //
          fromAnimation={
            drawLogic.showColorSelector
              ? resizeAnimation.animations.editPicture
              : drawLogic.showPenSelector
                ? resizeAnimation.animations.editPicture
                : resizeAnimation.animations.video
          }
          toAnimation={
            drawLogic.showColorSelector
              ? resizeAnimation.animations.colors
              : drawLogic.showPenSelector
                ? resizeAnimation.animations.pens
                : resizeAnimation.animations.editPicture
          }
          showOnlyColors={drawLogic.showColorSelector}
          showOnlyPens={drawLogic.showPenSelector}
          //
          onClickSelectColor={drawLogic.onClickSelectColor}
          onClickShowColorSelector={drawLogic.onClickShowColorSelector}
          onClickSelectPen={drawLogic.onClickSelectPen}
          onClickShowPenSelector={drawLogic.onClickShowPenSelector}
          onClickEraser={drawLogic.onClickEraser}
          onClickUndo={drawLogic.onClickUndo}
          onClickRedo={drawLogic.onClickRedo}
          onClickClear={drawLogic.onClickClear}
          onClickSave={drawLogic.onClickSave}
          //
          onClickSharePaint={onClickSharePaint}
          onClickExit={onClickExitEditingPic}
        />
      </div>
    </STStartedContentMainButtonsLivePaint>
  )
}
