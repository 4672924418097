import { useState } from 'react'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { Button } from '../../../common/button/component'
import { Trans } from '../../../common/intl/trans'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { STDialogConfirmYourBrowswerIsTooOld } from './style'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmYourBrowswerIsTooOld)

// ~~~~~~ Component
export const DialogConfirmYourBrowswerIsTooOld = () => {
  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  // ~~~~~~ Handlers

  function onClickOk() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      predefIcon="info"
      modalSize={DialogBreakPoint.XS}
      dialogTitle="dialogs.confirm.update-your-browser.Title"
      closeDialog={doClose}
    >
      {/* Content */}

      <Content>
        <STDialogConfirmYourBrowswerIsTooOld>
          <div className="line">
            <Trans id="dialogs.confirm.update-your-browser.Content1" />
            <span> </span>

            <a href="https://www.google.com/chrome" target="_blank" rel="noreferrer">
              <Trans id="dialogs.confirm.update-your-browser.Content2" />
            </a>
          </div>
        </STDialogConfirmYourBrowswerIsTooOld>
      </Content>

      {/* Actions */}

      <Actions>
        {/* Confirm */}

        <Button
          intlId="common.OK"
          $colorType="primary"
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickOk}
        />
      </Actions>
    </Dialog>
  )
}
