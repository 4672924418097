import { AppInitState } from '../../../../../models/app/model'
import { UiLayoutPanelsFieldsMR } from '../../../../../models/bcast/ui-layout-panels/model'
import { UtilsStore } from '@dn/utils'
import {
  EpicApiBcastCurrentUserShowMT,
  EpicApiBcastCurrentUserShowMC,
} from '../../../../epics/api-bcast/current-user/show/mutators'
import { EpicApiBcastProvidersLinkGoogleMT } from '../../../../epics/api-bcast/providers/link-google/mutators'
import {
  EpicApiBcastProvidersUnlinkGoogleMC,
  EpicApiBcastProvidersUnlinkGoogleMT,
} from '../../../../epics/api-bcast/providers/unlink-google/mutators'
import { EpicCancelNowAllMT } from '../../../../epics/cancel-now/all/mutators'
import { EpicResyncGoogleMC, EpicResyncGoogleMT } from '../../../../epics/resync/google/mutators'

const initState = AppInitState.uiLayoutPanels

type State = typeof initState

const reducer: any = {
  ...UiLayoutPanelsFieldsMR.leftPanelOpenState.Reducer,
  ...UiLayoutPanelsFieldsMR.savedNotPaintingLeftPanelOpenState.Reducer,
  ...UiLayoutPanelsFieldsMR.savedPaintingLeftPanelOpenState.Reducer,

  ...UiLayoutPanelsFieldsMR.leftPanelDisplayValue.Reducer,
  ...UiLayoutPanelsFieldsMR.leftPanelShowSharePaints.Reducer,

  ...UiLayoutPanelsFieldsMR.shareBcasLinkToClassroomState.Reducer,

  // Change reducer is override with custom reducer
  ...UiLayoutPanelsFieldsMR.shareImageToClassroomState.Reducer,

  // Change reducer is override with custom reducer
  ...UiLayoutPanelsFieldsMR.shareImageToSlidesState.Reducer,

  ...UiLayoutPanelsFieldsMR.onLinkWithGoogleShouldOpen.Reducer,
}

// ####################################################################################################
// ~~~~~~ Link Google - OK
// ####################################################################################################

reducer[EpicApiBcastProvidersLinkGoogleMT.OK] = (state: State): State => {
  return {
    ...state,

    shareImageToSlidesState: 'open',
    shareImageToClassroomState: 'closed',
  }
}

// ####################################################################################################
// ~~~~~~ Share image to classroom state, "open" should act as a switch
// ####################################################################################################

reducer[UiLayoutPanelsFieldsMR.shareImageToClassroomState.MT.CHANGE] = (
  state: State,
  { payload }: ReturnType<typeof UiLayoutPanelsFieldsMR.shareImageToClassroomState.MC.change>,
): State => {
  if (payload === 'closed') {
    return {
      ...state,
      shareImageToClassroomState: 'closed',
    }
  }

  return {
    ...state,
    shareImageToClassroomState: 'open',
    shareImageToSlidesState: 'closed',
  }
}

// ####################################################################################################
// ~~~~~~ Share image to slides state, "open" should act as a switch
// ####################################################################################################

reducer[UiLayoutPanelsFieldsMR.shareImageToSlidesState.MT.CHANGE] = (
  state: State,
  { payload }: ReturnType<typeof UiLayoutPanelsFieldsMR.shareImageToSlidesState.MC.change>,
): State => {
  if (payload === 'closed') {
    return {
      ...state,
      shareImageToSlidesState: 'closed',
    }
  }

  return {
    ...state,
    shareImageToSlidesState: 'open',
    shareImageToClassroomState: 'closed',
  }
}

// ####################################################################################################
// ~~~~~~ On load providers (Show current user - OK), set shareImage for slides or classroom as "open"
//        based on onLinkWithGoogleShouldOpen
// ####################################################################################################

reducer[EpicApiBcastCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastCurrentUserShowMC.ok>,
): State => {
  const isLinked =
    payload.providers.google.classroom_list &&
    payload.providers.google.drive &&
    payload.providers.google.classroom_announcements &&
    payload.providers.google.slides

  if (!isLinked) return state

  const slidesOpen = state.onLinkWithGoogleShouldOpen === 'slides'

  return {
    ...state,

    shareBcasLinkToClassroomState: 'open',
    shareImageToClassroomState: slidesOpen ? 'closed' : 'open',
    shareImageToSlidesState: slidesOpen ? 'open' : 'closed',
  }
}

// ####################################################################################################
// ~~~~~~ On "Link with Google - OK", set shareImage for slides or classroom as "open" based on
//        onLinkWithGoogleShouldOpen
// ####################################################################################################

reducer[EpicApiBcastProvidersLinkGoogleMT.OK] = (state: State): State => {
  const slidesOpen = state.onLinkWithGoogleShouldOpen === 'slides'

  return {
    ...state,

    shareBcasLinkToClassroomState: 'open',
    shareImageToClassroomState: slidesOpen ? 'closed' : 'open',
    shareImageToSlidesState: slidesOpen ? 'open' : 'closed',
  }
}

// ####################################################################################################
// ~~~~~~ Unlink Google - OK
// ####################################################################################################

reducer[EpicApiBcastProvidersUnlinkGoogleMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastProvidersUnlinkGoogleMC.ok>,
): State => {
  return {
    ...state,

    shareBcasLinkToClassroomState: 'closed',
    shareImageToClassroomState: 'closed',
    shareImageToSlidesState: 'closed',
  }
}

// ####################################################################################################
// ~~~~~~ On resync google
// ####################################################################################################

reducer[EpicResyncGoogleMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicResyncGoogleMC.ok>,
): State => {
  const stateShouldBeClosed = Object.values(payload.google).some((value) => value === false)

  return {
    ...state,

    shareBcasLinkToClassroomState: stateShouldBeClosed
      ? 'closed'
      : state.shareBcasLinkToClassroomState,

    shareImageToClassroomState: stateShouldBeClosed ? 'closed' : state.shareImageToClassroomState,

    shareImageToSlidesState: stateShouldBeClosed ? 'closed' : state.shareImageToSlidesState,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel all
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~

export const uiLayoutPanelsReducer = UtilsStore.dynReducer(initState, reducer)
