const prefix = 'mt-api-subscriptions-create'

// ~~~~~~ Mutators Types

export const ApiSubscriptionsCreateEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiSubscriptionsCreateEpicMC = {
  ok: (subscription: DN.Models.StoreSubscription.Api.Create.ResParsed) => ({
    type: ApiSubscriptionsCreateEpicMT.OK,
    payload: {
      subscription,
    },
  }),

  error: (errors: DN.Models.StoreSubscription.Api.Create.ResError) => ({
    type: ApiSubscriptionsCreateEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
