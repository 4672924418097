const prefix = 'at-comm-channel'

// ~~~~~ Action types

export const CommChannelAT = {
  SEND_BCASTER_CAM_MIC_STOP: `${prefix}-send-bcaster-cam-mic-stop`,

  SEND_BCASTER_CAM_DISABLE: `${prefix}-send-bcaster-cam-disable`,
  SEND_BCASTER_CAM_ENABLE: `${prefix}-send-bcaster-cam-enable`,

  SEND_BCASTER_MIC_DISABLE: `${prefix}-send-bcaster-mic-disable`,
  SEND_BCASTER_MIC_ENABLE: `${prefix}-send-bcaster-mic-enable`,
} as const

// ~~~~~~ Action Creators

export const CommChannelAC = {
  sendBcasterCamMicStop: () => ({
    type: CommChannelAT.SEND_BCASTER_CAM_MIC_STOP,
  }),

  sendBcasterCamDisable: () => ({
    type: CommChannelAT.SEND_BCASTER_CAM_DISABLE,
  }),

  sendBcasterCamEnable: () => ({
    type: CommChannelAT.SEND_BCASTER_CAM_ENABLE,
  }),

  sendBcasterMicDisable: () => ({
    type: CommChannelAT.SEND_BCASTER_MIC_DISABLE,
  }),

  sendBcasterMicEnable: () => ({
    type: CommChannelAT.SEND_BCASTER_MIC_ENABLE,
  }),
} as const
