import React from 'react'
import { useTrans } from '../../../hooks/use-trans'
import { InfosList } from '../infos/list/component'
import { Trans } from '../intl/trans'
import { STIntlArea, STProps } from './style'

// ~~~~~~ Types

type Props = Omit<STProps, '$hasErrors'> & {
  label?: IntlMsgId
  placeholder?: IntlMsgId
  value: string | number
  errors?: Infos[]
  onChange: (value: string) => void
}

// ~~~~~~ Component

export const IntlArea: React.FC<Props> = ({
  label,
  placeholder,
  value,
  errors,

  // Shared

  $disabled,

  // Style

  $resize,
  $minHeight,

  onChange,

  ...restProps
}) => {
  // ~~~~~~ Hooks

  const placeholderText = useTrans(placeholder || 'wildcard')

  const finalPlaceholderText = placeholder ? placeholderText : ''

  // ~~~~~~ Render

  return (
    <STIntlArea
      data-test-id="intl-area"
      $disabled={$disabled}
      $minHeight={$minHeight}
      $resize={$resize}
      $hasErrors={!!errors?.length}
      {...restProps}
    >
      {label ? (
        <div className="label">
          <Trans id={label} />
        </div>
      ) : undefined}

      <textarea
        data-test-id="input"
        placeholder={finalPlaceholderText}
        value={value}
        disabled={$disabled}
        onChange={(evt) => onChange(evt.target.value)}
      />

      {errors && <InfosList data-test="errors" infos={errors} />}
    </STIntlArea>
  )
}
