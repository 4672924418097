import { EMPTY, fromEvent, mergeMap, of, share, tap } from 'rxjs'

import { Config } from '../../../../config'
import { UtilsLog } from '../../../../utils/logs'
import { BroadcastServiceConst } from '../../constants/broadcast-partial-service'
import { genTakeUntilStop$ } from '../../subject/stop/take-until/broadcast-partial-service'

const { PublisherEventNames, OurPublisherEvents } = BroadcastServiceConst

export const startListeningPublication$ = (publisher: R5P.Publisher, id: string) => {
  Config.Logs.BroadcastService &&
    UtilsLog.devLog(
      'BCastService',
      'startListeningPublication',
      '\n- id\n',
      id,
      '\n- publiser\n',
      publisher,
    )

  const closeObs$ = fromEvent<R5P.PublisherEvent>(publisher, PublisherEventNames.Wildcard).pipe(
    tap((evt) => Config.Logs.BroadcastEvents && UtilsLog.devLog('New pub event', evt)),

    mergeMap((evt) => {
      switch (true) {
        // Close, fails, ...

        case evt.type === PublisherEventNames.PublisherConnectionClosed:
          return of(OurPublisherEvents.Closed)

        case evt.type === PublisherEventNames.PublishFail:
          return of(OurPublisherEvents.PublishFail)

        case evt.type === PublisherEventNames.WebRTCDataChannelError:
          return of(OurPublisherEvents.WebRTCDataChannelError)

        case evt.type === PublisherEventNames.ConnectFailure:
          return of(OurPublisherEvents.ConnectionFailure)

        default:
          // Config.Logs.BroadcastEvents && devLog('Unhandled event (ignored)', evt.type)
          return EMPTY
      }
    }),

    genTakeUntilStop$(id),

    share(),
  )

  const obsAll$ = fromEvent<R5P.PublisherEvent>(publisher, PublisherEventNames.Wildcard).pipe(
    genTakeUntilStop$(id),

    share(),
  )

  return [closeObs$, obsAll$] as const
}
