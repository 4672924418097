import { ConstEventsVideo } from '@dn/constants'
import { HooksData } from '@dn/hooks'
import { useState, useRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromEvent, take } from 'rxjs'
import { StoreState } from '../../../../models/app/model'
import { LivePaintVideoMR } from '../../../../models/bcast/live-paint/model'
import { SharedStreamModelFieldsMR } from '../../../../models/bcast/shared-main-stream/model'
import { clearSavedPaintCanvas } from './live-paint/component'

// ~~~~~~ Hook

export const useStartedContentMaintLogic = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  const [videoHasSrcObj, setVideoHasSrcObj] = useState(false)

  // - Store

  const { screenStreamForVideo, videoIsPlaying } = useSelector(
    (state: StoreState) => state.sharedMainStream,
  )

  const curCamMicStream = useSelector(
    (state: StoreState) => state.sharedCamMicStream.curCamMicStream,
  )

  const localCamStream = useSelector((state: StoreState) => state.dnBcast.localCamStream)

  const livePaintEnabled = useSelector((state: StoreState) => state.livePaint.enabled)

  const { originalVideoH, originalVideoW } = useSelector(
    (state: StoreState) => state.livePaintVideo,
  )

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  const videoWrapperRef = useRef<HTMLDivElement>(null)

  const videoRef = useRef<HTMLVideoElement>(null)

  const prevScreenStreamForVideoIdRef = useRef(screenStreamForVideo?.id)

  // ~~~~~~ Dependent hooks

  const prevLivePaintEnabled = HooksData.PrevValue.useHook(livePaintEnabled)

  // ~~~~~~ Computed

  const video = videoRef.current

  const showMirrorEffectLayer = videoIsPlaying && !livePaintEnabled

  // ~~~~~~ Callbacks

  // - Check if original video dimensions have changed and update if required

  const updateOriginalVideoDimensions = useCallback(() => {
    if (!video) return

    const { videoWidth, videoHeight } = video

    if (videoWidth === originalVideoW && videoHeight === originalVideoH) {
      return
    }

    dispatch(LivePaintVideoMR.originalVideoW.MC.change(videoWidth))
    dispatch(LivePaintVideoMR.originalVideoH.MC.change(videoHeight))

    //
  }, [dispatch, originalVideoH, originalVideoW, video])

  // ~~~~~~ Effects

  // - On start video play, set video, its dimension and the playing status as trure

  useEffect(() => {
    if (!video) return

    const sub = fromEvent(video, ConstEventsVideo.Playing)
      .pipe(take(1))
      .subscribe({
        next: () => {
          dispatch(LivePaintVideoMR.video.MC.change(video))

          updateOriginalVideoDimensions()

          dispatch(SharedStreamModelFieldsMR.videoIsPlaying.MC.change(true))
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [dispatch, updateOriginalVideoDimensions, video])

  // - Add video source on stream ready

  useEffect(() => {
    if (!video && screenStreamForVideo) {
      setUpdate(performance.now())

      return
    }

    if (!video || !screenStreamForVideo || videoHasSrcObj) return

    video.srcObject = screenStreamForVideo

    setVideoHasSrcObj(true)

    // In Firefox (since 91.0), track.getSettings() is undefined, undefined.
    // Because that we have the fromEvent(video, playing)

    const track = screenStreamForVideo.getVideoTracks()[0]

    const { width, height } = track.getSettings()

    dispatch(LivePaintVideoMR.originalVideoW.MC.change(width || 0))
    dispatch(LivePaintVideoMR.originalVideoH.MC.change(height || 0))

    //
  }, [screenStreamForVideo, dispatch, video, videoHasSrcObj])

  // - On resize video (like changing video source), check original video dimensions

  useEffect(() => {
    if (!video) return

    const subResize = fromEvent(video, ConstEventsVideo.Resize).subscribe({
      next: () => {
        updateOriginalVideoDimensions()
      },
    })

    return () => {
      subResize.unsubscribe()
    }
  }, [updateOriginalVideoDimensions, video])

  // - Update video source on change stream

  useEffect(() => {
    const video = videoRef.current

    if (
      !video ||
      !screenStreamForVideo ||
      prevScreenStreamForVideoIdRef.current === screenStreamForVideo.id
    ) {
      return
    }

    prevScreenStreamForVideoIdRef.current = screenStreamForVideo.id

    // Update original video dimensions

    const sub = fromEvent(video, ConstEventsVideo.Playing)
      .pipe(take(1))
      .subscribe({
        next: () => {
          dispatch(LivePaintVideoMR.originalVideoW.MC.change(video.videoWidth))
          dispatch(LivePaintVideoMR.originalVideoH.MC.change(video.videoHeight))
        },
      })

    video.srcObject = screenStreamForVideo

    return () => {
      sub.unsubscribe()
    }
  }, [dispatch, prevScreenStreamForVideoIdRef, screenStreamForVideo])

  // - Clear saved canvas on stop painting

  useEffect(() => {
    if (
      prevLivePaintEnabled === undefined ||
      prevLivePaintEnabled === livePaintEnabled ||
      !prevLivePaintEnabled ||
      livePaintEnabled
    ) {
      return
    }

    clearSavedPaintCanvas('started main')

    // ...
  }, [prevLivePaintEnabled, livePaintEnabled])

  // ~~~~~~

  return {
    selfRef,
    videoWrapperRef,
    videoRef,

    videoIsPlaying,
    curCamMicStream,
    localCamStream,

    showMirrorEffectLayer,
    livePaintEnabled,
  } as const
}
