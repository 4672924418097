import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, tap } from 'rxjs'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { apiBcastProvidersGoogleUnlink$ } from '../../../../../services/api-bcast/providers/google/unlink'
import { ExtensionService } from '../../../../../services/extension'
import {
  ApiBcastProvidersAC,
  ApiBcastProvidersAT,
} from '../../../../actions/api-bcast/providers/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastProvidersUnlinkGoogleMC } from './mutators'

type Action = ReturnType<typeof ApiBcastProvidersAC.unlinkGoogle>

export const apiBcastProvidersUnlinkGoogleEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastProvidersAT.UNLINK_GOOGLE),

    // Api call

    mergeMap(() =>
      apiBcastProvidersGoogleUnlink$(state$.value.currentUser.token, state$.value.currentUser.id),
    ),

    // Extension

    tap((res) => {
      if (!ApiUtils.isSuccess(res)) return

      !Config.isExtension && ExtensionService.SendMessage.ToExtension.updateInfo()
    }),

    // Mutator

    map((res) => {
      return ApiUtils.isSuccess(res)
        ? EpicApiBcastProvidersUnlinkGoogleMC.ok(res.response)
        : EpicApiBcastProvidersUnlinkGoogleMC.error(res.response)
    }),
  )
