import { UtilsStore } from '@dn/utils'

const initModel: DN.Models.SharedStream.ModelFront = {
  getSreenUiStatus: 'init',
  getScreenToSwitchUiStatus: 'init',

  captureKind: 'unknown',

  videoIsPlaying: false,

  curScreenStream: undefined,
  curScreenVideoTrack: undefined,

  canvasVideoTrack: undefined,
  canvasStreamStop: undefined,

  screenStreamForVideo: undefined,
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-shared-stream-model'

export const SharedStreamModelFieldsMR = {
  videoIsPlaying: UtilsStore.genBasicFieldMR(
    prefix,
    initModel,
    'videoIsPlaying',
    initModel.videoIsPlaying,
  ),

  // Used in extension

  curScreenStream: UtilsStore.genBasicFieldMR(
    prefix,
    initModel,
    'curScreenStream',
    initModel.curScreenStream,
  ),
}

// ~~~~~~

export const SharedStreamModel = {
  initModel,
}
