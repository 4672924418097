import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { Routes } from '../../../../constants/routes/routes'
import { useValidator } from '../../../../hooks/validators/use-validators'
import { StoreState } from '../../../../models/app/model'
import { StorePlanFieldsMR, StorePlanModel } from '../../../../models/store/plan/model'
import { ModalsMC } from '../../../../store/actions-mutators/modals/mutators'
import { ApiStoreCheckoutAC } from '../../../../store/actions/api-store/checkout/actions'
import { ApiStoreSubscriptionsAC } from '../../../../store/actions/api-store/subscriptions/actions'
import { GoogleGTM } from '../../../../third-parties/google-gtm/library'
import { Button } from '../../../common/button/component'
import { InfoBox } from '../../../common/infos/box/component'
import { IntlInput } from '../../../common/intl-input/component'
import { DangerTrans } from '../../../common/intl/danger-trans'
import { Trans } from '../../../common/intl/trans'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { ClassName, STDialogsSubscriptionsVerificationCode } from './style'
import { BcastTrackEvents } from '../../../../services/track-events'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.Step2VerificationCode)

const CodeIcon = LazySVG('icons/keyboard')

// ~~~~~~ Component

export const DialogsSubscriptionsStep2VerificationCode = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const validateCode = useValidator(StorePlanModel.validations.Code, StorePlanFieldsMR.code)

  const location = useLocation()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const [isDirty, setIsDirty] = useState(false)

  const {
    uiStatusVerificationCode,
    uiStatusSubscribing,
    uiStatusCheckout,
    kind,
    email,
    pass,
    portal_code,
    code,
    code_err,
    priceId,
    errors,
  } = useSelector((state: StoreState) => state.storePlan)

  const currentUserEmail = useSelector((state: StoreState) => state.currentUser.email)

  const subscription = useSelector((state: StoreState) => state.subscription)

  // ~~~~~~ Computed

  const isRunning =
    uiStatusVerificationCode === 'running' ||
    uiStatusSubscribing === 'running' ||
    uiStatusCheckout === 'running'

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())

    dispatch(StorePlanFieldsMR.reset.MC.reset())

    dispatch(ApiStoreCheckoutAC.cancel())
  }

  function onChangeCode(value: string) {
    isDirty && validateCode(value)

    dispatch(StorePlanFieldsMR.code.MC.change(value))
  }

  function onClickVerifyCode() {
    setIsDirty(true)

    const errors = [validateCode(code).length]

    if (errors.find(Boolean)) return

    setIsDirty(false)

    dispatch(
      ApiStoreCheckoutAC.validateEmailCode({
        email,
        code,
      }),
    )

    // Track

    kind === 'free'
      ? BcastTrackEvents.calls.BuyProcess.Free['3-verify-email']()
      : BcastTrackEvents.calls.BuyProcess.Premium['3-verify-email']()
  }

  // ~~~~~~ Effects

  // - Check portal code to try to subscribe

  useEffect(() => {
    if (kind === 'none' || !portal_code || uiStatusSubscribing !== 'init' || errors.length) return

    // No credit card required

    if (kind === 'free') {
      // Track in epic after success

      dispatch(
        ApiStoreSubscriptionsAC.create({
          email: email,
          price_of_license_of_product_id: priceId,
          code: portal_code,
        }),
      )

      return
    }

    // Get checkout seesion: Stripe portal

    dispatch(
      ApiStoreCheckoutAC.createSession({
        email,
        price_of_license_of_product_id: priceId,
        quantity: 1,
        success_path: Routes.BuySuccessForStripe,
        cancel_path: location.pathname,
        code: portal_code,
      }),
    )

    //
  }, [
    dispatch,
    email,
    errors.length,
    kind,
    location.pathname,
    pass,
    portal_code,
    priceId,
    uiStatusSubscribing,
  ])

  // - Check subscribe status to show info

  useEffect(() => {
    if (doClose || uiStatusSubscribing !== 'completed') return

    setDoClose(performance.now())

    dispatch(StorePlanFieldsMR.reset.MC.reset())

    dispatch(ModalsMC.open(ModalsIds.ConfirmThanksForBuy))

    GoogleGTM.send('SuccessFree')

    //
  }, [currentUserEmail, dispatch, doClose, subscription, uiStatusSubscribing])

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      modalSize={DialogBreakPoint.S}
      closeOnClickOut={false}
      dialogTitle="dialogs.verification-code.Title"
      dialogTitleAlign="left"
      dialogSubtitle="common.StepOf"
      dialogSubtitleValues={{ step: 2, total: kind === 'free' ? 2 : 3 }}
      closeDialog={doClose}
    >
      <Content>
        <STDialogsSubscriptionsVerificationCode>
          <div className={`${ClassName}--line`}>
            <Trans id="dialogs.verification-code.Subtitle" values={{ value: email }} />
          </div>

          <div className={`${ClassName}--line`}>
            <DangerTrans
              id="dialogs.verification-code.ExtraInfo"
              template="Didn't get the email? Check your <c>junk folder</c>"
              values={{
                c: (chunks: string) => <b>{chunks}</b>,
              }}
            />
          </div>

          {errors && errors.length ? (
            <div className={`${ClassName}--info-box`}>
              <InfoBox withIcon={true} $boxType="ERROR" infos={errors} />
            </div>
          ) : undefined}

          <div>
            {/* Code */}

            <div className={`${ClassName}--line`}>
              <IntlInput
                Icon={CodeIcon}
                placeholder="dialogs.verification-code.form.code.Placeholder"
                $disabled={isRunning}
                value={code}
                errors={code_err}
                onChange={onChangeCode}
                showErrorsInBox
              />
            </div>
          </div>
        </STDialogsSubscriptionsVerificationCode>
      </Content>

      <Actions>
        {/* Close */}

        <Button
          intlId="common.Cancel"
          $colorType="secondary"
          disabled={false}
          $active={true}
          $running={false}
          onClick={onClickClose}
        />

        {/* Check */}

        <Button
          intlId="dialogs.verification-code.actions.VerifyCode"
          $colorType="primary"
          disabled={isRunning}
          $active={!isRunning}
          $running={isRunning}
          onClick={onClickVerifyCode}
        />
      </Actions>
    </Dialog>
  )
}
