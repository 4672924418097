const prefix = 'at-feedback'

// ~~~~~~ Action Types

export const FeedbackAT = {
  SEND_END_BCAST: `${prefix}-send`,
} as const

// ~~~~~~ Action Creators

export const FeedbackAC = {
  sendEndBcast: (widget: string, data: DN.Models.FeedbackEndBcast.Api.Send.Req) => ({
    type: FeedbackAT.SEND_END_BCAST,
    payload: {
      widget,
      data,
    },
  }),
} as const
