import { UtilsStore } from '@dn/utils'

const initModel: DN.Models.UiPip.Model = {
  openState: 'closed',
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-ui-pip-model'

export const UiPipFieldsMR = {
  isActive: UtilsStore.genBasicFieldMR(prefix, initModel, 'openState', initModel.openState),
}

// ~~~~~~

export const UiPipModel = {
  initModel,
} as const
