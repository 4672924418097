import { catchError, map, Observable, of, tap } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

const { Announce } = ApiBcastRoutes.GoogleClassRoom

// ####################################################################################################
// ~~~~~~ Announce material in Google Class Room
// ####################################################################################################

type WithGoogleAccessToken =
  | {
      withIt: true
      accessToken: string
    }
  | {
      withIt: false
      token: string
    }

type Res = DN.Models.GoogleClassRoom.Api.Announce.Res
type ResParsed = DNApiResParsed<DN.Models.GoogleClassRoom.Api.Announce.ResParsed>
type ResError = DNApiError<DN.Models.GoogleClassRoom.Api.Announce.ResError>

export const apiBcastGoogleClassRoomAnnounce$ = (
  withGoogleAccessToken: WithGoogleAccessToken,
  courseId: string,
  data: DN.Models.GoogleClassRoom.Api.Announce.Req,
  headers?: AppHeaders,
) => {
  const finalHeaders = withGoogleAccessToken.withIt
    ? BcastHeaderUtils.genHeadersWithGoogleAccessToken(withGoogleAccessToken.accessToken, headers)
    : BcastHeaderUtils.genHeadersWithAuth(withGoogleAccessToken.token, headers)

  const body = data

  return ajax<Res>({
    ...finalHeaders,
    method: Announce.Method,
    url: Announce.getUrl(courseId),
    body,
  }).pipe(
    tap({ next: () => {}, error: () => {} }),

    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.google-class-room.announce.Error' }],
      })
    }),
  )
}
