const prefix = 'mt-api-checkout-send-email-epic'

// ~~~~~~ Mutators Types

export const ApiCheckoutSendEmailPassEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiCheckoutSendEmailPassEpicMC = {
  ok: () => ({
    type: ApiCheckoutSendEmailPassEpicMT.OK,
  }),

  error: (errors: DN.Models.StoreCheckout.Api.SendEmailPass.ResError) => ({
    type: ApiCheckoutSendEmailPassEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
