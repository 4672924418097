const prefix = 'mt-api-bcast-google-slides-list-presentation-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastGoogleSlidesListPresentationsMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastGoogleSlidesListPresentationsMC = {
  ok: (data: DN.Models.GoogleSlides.Api.List.ResParsed) => ({
    type: EpicApiBcastGoogleSlidesListPresentationsMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.GoogleSlides.Api.List.ResError, unsync: boolean) => ({
    type: EpicApiBcastGoogleSlidesListPresentationsMT.ERROR,
    error: true,
    payload: {
      unsync,
      errors,
    },
  }),
} as const
