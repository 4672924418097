import styled from 'styled-components'
import { Colors } from '../../../../../../style/theme/colors'
import { HexAlpha } from '../../../../../../style/theme/colors/alpha'
import { Layout } from '../../../../../../style/theme/layout'
import { getThemeValue, theme } from '../../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'cam-mic-mic-sound-mobile-button-switcher'

// ~~~~~~ Component

export const STCamMicMicSoundMobileButtonSwitcher = styled.div`
  position: absolute;
  top: 0;
  right: ${Layout.Spacing[4]}px;

  .${ClassName}--content {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${(props) => `${getThemeValue(props, Colors.WOLF_90_90)}${HexAlpha[90]}`};

    ${Layout.Border.Radius.A}

    height: 40px;
    width: 40px;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: none;
        stroke: ${theme(Colors.WOLF_05_05)};
      }
    }
  }
`
