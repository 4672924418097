import { guardFromServerIsBcastCurrentState } from './from-server/is-bcast-current-state/comm-channel-partial-service'
import { guardIsMessageFromServerWithRelay } from './from-server/is-from-server-with-relay/comm-channel-partial-service'

import { guardIsAck } from './is-ack/comm-channel-partial-service'
import { guardIsMessage } from './is-message/comm-channel-partial-service'

// ~~~~~~

export const Guards = {
  isMessage: guardIsMessage,
  isAck: guardIsAck,

  FromServer: {
    isRelay: guardIsMessageFromServerWithRelay,

    isBcastCurrentState: guardFromServerIsBcastCurrentState,
  },
} as const
