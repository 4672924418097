import { clear } from './clear/draw-partial-service'
import { redo } from './redo/draw-partial-service'
import { undo } from './undo/draw-partial-service'

// ~~~~~~

export const DrawPublicCommands = {
  clear,

  undo,
  redo,
} as const
