import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../../models/app/model'
import { UiMobileToolbarFieldsMR } from '../../../../../models/bcast/ui-mobile-toolbars/model'
import { STJoinedMobileToolbarsMenuBtn } from './style'

// ~~~~~~ Constants

const MenuIcon = LazySVG('icons/grid')

const PenIcon = LazySVG('icons/tool-pen')

const MarkerIcon = LazySVG('icons/tool-marker')

const HighlighterIcon = LazySVG('icons/tool-highlighter')

const PaintIcon = {
  pen: PenIcon,
  highlighter: HighlighterIcon,
  marker: MarkerIcon,
} as const

const EraserIcon = LazySVG('icons/eraser')

// ~~~~~~ Component

export const JoinedMobileToolbarsMenuBtn = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Store

  const paintBcastStreamEnabled = useSelector((state: StoreState) => state.paintBcastStream.enabled)

  const { activePen, colorKey, isErasing } = useSelector(
    (state: StoreState) => state.paintBcastStreamDraw,
  )

  const toolbarOpenStatus = useSelector((state: StoreState) => state.uiMobileToolbar.openStatus)

  // ~~~~~~ Computed

  const CurPaintIcon = PaintIcon[activePen]

  // ~~~~~~ Handlers

  function onClick() {
    dispatch(
      UiMobileToolbarFieldsMR.openStatus.MC.change(
        paintBcastStreamEnabled
          ? toolbarOpenStatus === 'painting-open'
            ? 'painting-closed'
            : 'painting-open'
          : 'main',
      ),
    )
  }

  // ~~~~~~ Render

  // - Normal

  if (!paintBcastStreamEnabled) {
    return (
      <STJoinedMobileToolbarsMenuBtn $colorKey={colorKey} onClick={onClick}>
        <MenuIcon size={24} />
      </STJoinedMobileToolbarsMenuBtn>
    )
  }

  // - Erasing

  if (isErasing) {
    return (
      <STJoinedMobileToolbarsMenuBtn $colorKey={colorKey} onClick={onClick}>
        <EraserIcon size={24} />
      </STJoinedMobileToolbarsMenuBtn>
    )
  }

  // - Painting

  return (
    <STJoinedMobileToolbarsMenuBtn className="pen" $colorKey={colorKey} onClick={onClick}>
      <CurPaintIcon className={colorKey} size={24} />
    </STJoinedMobileToolbarsMenuBtn>
  )
}
