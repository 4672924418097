import { LazySVG } from '../../../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsCaptureShare } from '../../../../../../../behaviour-logic/content/main/toolbars/main/buttons/capture-share/hook'
import { ClassName, STJoinedTabletContentMainToolbarsMainButtonsCaptureShare } from './style'

// ~~~~~~ Constants

const ShareIcon = LazySVG('icons/edit-pic')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const JoinedTabletContentMainToolbarsMainButtonsCaptureShare: React.FC<Props> = ({
  video,
}) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsCaptureShare(video)

  // ~~~~~~ Render

  return (
    <STJoinedTabletContentMainToolbarsMainButtonsCaptureShare onClick={logic.onClickCaptureShare}>
      {/* Icon */}

      <ShareIcon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedTabletContentMainToolbarsMainButtonsCaptureShare>
  )
}
