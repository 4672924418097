import { Observable } from 'rxjs'
import { Config } from '../../../../../config'

// ~~~~~~ Types

type Message = DN.Services.Extension.ToExtension.ChooseStreamSource

type Response = { streamId: string }

// ~~~~~~

export const sendMessageToExtensionChooseSource$ = (kind: Message['payload']['kind']) => {
  //

  const obs$ = new Observable<Response>((observer) => {
    if (
      Config.Extension.Id === 'no-extension-id' ||
      !window.chrome ||
      !window.chrome.runtime ||
      !window.chrome.runtime.sendMessage
    ) {
      observer.error()
      return
    }

    const message: Message = {
      type: 'choose-stream-source',
      payload: {
        kind,
      },
    }

    const callback = (
      res?:
        | DN.Services.Extension.FromExtension.ChooseStreamCancelled
        | DN.Services.Extension.FromExtension.StreamId,
    ) => {
      if (!res) {
        observer.error({ installed: false })
        return
      }

      switch (res.type) {
        case 'choose-stream-cancelled':
          observer.error({ canceled: true })
          break

        case 'stream-id':
          observer.next({ streamId: res.payload.streamId })
          break
      }

      observer.complete()
    }

    chrome.runtime.sendMessage(Config.Extension.Id, message, callback)
  })

  return obs$
}
