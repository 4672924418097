import { Observable, catchError, map, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'
import { StreamContextModel } from '../../../models/bcast/stream-context/model'
import { Config } from '../../../config'

const { Create } = ApiBcastRoutes.StreamContext

// ####################################################################################################
// ~~~~~~ Create stream context
// ####################################################################################################

type Res = DN.Models.StreamContext.Api.Create.Res
type ResParsed = DNApiResParsed<DN.Models.StreamContext.Api.Create.ResParsed>
type ResError = DNApiError<DN.Models.StreamContext.Api.Create.ResError>

const Prefix = Config.Api.Bcast.IdPrefix ? `${Config.Api.Bcast.IdPrefix}-` : ''

export const apiBcastStreamContextCreate$ = (
  token: string,
  bcastId: string,

  headers?: AppHeaders,
) => {
  const body: DN.Models.StreamContext.Api.Create.Req = {
    bcast_id: `${Prefix}${bcastId}`,
  }

  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersWithAuth(token, headers),
    method: Create.Method,
    url: Create.getUrl(),
    body,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: StreamContextModel.fromBackToFront(res.response, bcastId, 'pub'),
      }
    }),

    catchError((res: ResError): Observable<ResParsed> => {
      return of({
        status: 201,
        response: StreamContextModel.fromBackToFront({ id: 'no-stream-id' }, bcastId, 'pub'),
      })
    }),
  )
}
