import { filter, fromEvent, map, merge, mergeMap, of, take, takeUntil } from 'rxjs'
import { ConstEventsElement } from '@dn/constants'
import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'
import { DrawStop$$ } from '../subject/draw-partial-service'

// ####################################################################################################
// ~~~~~~ Add Paper Tool listeners
// ####################################################################################################

// NOTICE: MouseEvents includes TouchEvents

export const addToolListeners$ = (view: paper.View) => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'addToolListeners')

  const mouseDown$ = fromEvent<paper.MouseEvent & { event: MouseEvent }>(
    view,
    ConstEventsElement.MouseDown,
  ).pipe(
    filter((evt) => evt.event.button === 0 || evt.event.type === ConstEventsElement.TouchStart),
    map((evt) => ({ eventType: 'down' as const, point: evt.point })),
  )

  const mouseUp$ = fromEvent<paper.MouseEvent>(view, ConstEventsElement.MouseUp).pipe(
    map((evt) => ({ eventType: 'up' as const, point: evt.point })),
  )

  const mouseDrag$ = fromEvent<paper.MouseEvent>(view, ConstEventsElement.MouseDrag).pipe(
    map((evt) => ({ eventType: 'drag' as const, point: evt.point })),
  )

  const mouseLeave$ = fromEvent<paper.MouseEvent>(view, ConstEventsElement.MouseLeave).pipe(
    map((evt) => ({ eventType: 'leave' as const, point: evt.point })),
  )

  const end$ = merge(mouseUp$, mouseLeave$)

  const drawing$ = mouseDown$.pipe(
    takeUntil(DrawStop$$),
    mergeMap((evt) => merge(of(evt), mouseDrag$.pipe(takeUntil(end$)), end$.pipe(take(1)))),
  )

  return drawing$
}
