import { AppInitState } from '../../../../../models/app/model'
import { UiPipFieldsMR } from '../../../../../models/bcast/ui-pip/model'
import { UtilsStore } from '@dn/utils'

const initState = AppInitState.uiPip

// type State = typeof initState

const reducer: any = {
  ...UiPipFieldsMR.isActive.Reducer,
}

// ~~~~~~

export const uiPipReducer = UtilsStore.dynReducer(initState, reducer, (state) => {
  return state
})
