import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'

export const STTables = styled.table`
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;

    ${Fonts.Scale.Body}
    ${Fonts.Weight[500]}
  }

  th {
    background-color: ${theme(Colors.EMMET_05_05)};
    border-color: ${theme(Colors.EMMET_10_10)};
  }

  td {
    border-color: ${theme(Colors.WOLF_10_10)};

    &.word {
      background-color: ${theme(Colors.EMMET_05_05)};
      border-color: ${theme(Colors.EMMET_10_10)};
    }
  }
`
