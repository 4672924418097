import { motion } from 'framer-motion'
import styled from 'styled-components'

export const STMotionImgStartedContentMainPicOverVideo = styled(motion.img)`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  /* object-fit: contain; */
`
