import { AppInitState } from '../../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ApiBcastGoogleSlidesAT } from '../../../../actions/api-bcast/google-slides/actions'
import {
  EpicApiBcastGoogleSlidesListPresentationsMT,
  EpicApiBcastGoogleSlidesListPresentationsMC,
} from '../../../../epics/api-bcast/google-slides/list-presentations/mutators'

import {
  EpicApiBcastProvidersUnlinkGoogleMC,
  EpicApiBcastProvidersUnlinkGoogleMT,
} from '../../../../epics/api-bcast/providers/unlink-google/mutators'

const initState = AppInitState.googleSlidesPresentationMetaList

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Unlink Google - OK
// ####################################################################################################

reducer[EpicApiBcastProvidersUnlinkGoogleMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastProvidersUnlinkGoogleMC.ok>,
): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ List - START
// ####################################################################################################

reducer[ApiBcastGoogleSlidesAT.LIST_PRESENTATIONS] = (state: State): State => {
  return {
    ...state,
    errors: [],
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ List - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesListPresentationsMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastGoogleSlidesListPresentationsMC.ok>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    list: UtilsStore.setList(payload, {
      sortBy: state.sortBy,
      order: state.sortDir,
    }),
  }
}

// ####################################################################################################
// ~~~~~~ List - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesListPresentationsMT.ERROR] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ~~~~~~

export const googleSlidesPresentationMetaListReducer = UtilsStore.dynReducer(initState, reducer)
