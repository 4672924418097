import { sendMessageToContentBcastStopped } from './to-content/bcast-stopped/extension-partial-service'
import { sendMessageToExtensionBroadcastingStateChanged } from './to-extension/broadcasting-state-changed/extension-partial-service'
import { sendMessageToExtensionChooseSource$ } from './to-extension/choose-source/extension-partial-service'
import { sendMessageToExtensionSetMeAsActive } from './to-extension/set-me-as-active/extension-partial-service'
import { sendMessageToExtensionLoggedStateChanged } from './to-extension/logged-state-changed/extension-partial-service'
import { sendMessageToExtensionAppInfo$ } from './to-extension/app-info/extension-partial-service'
import { sendMessageToExtensionUpdateInfo } from './to-extension/upate-info/extension-partial-service'
import { sendMessageToExtensionChooseSourceStateChanged } from './to-extension/choose-source-state-changed/extension-partial-service'
import { sendMessageToExtensionCheckExtension$ } from './to-extension/check-extension/extension-partial-service'

export const SendMessage = {
  ToContent: {
    bcastStopped: sendMessageToContentBcastStopped,
  },
  ToExtension: {
    checkExtension$: sendMessageToExtensionCheckExtension$,
    broadcastingStateChanged: sendMessageToExtensionBroadcastingStateChanged,
    loggedStateChanged: sendMessageToExtensionLoggedStateChanged,
    chooseSourceStateChanged: sendMessageToExtensionChooseSourceStateChanged,
    chooseSource$: sendMessageToExtensionChooseSource$,
    setMeAsActive: sendMessageToExtensionSetMeAsActive,
    appInfo$: sendMessageToExtensionAppInfo$,
    updateInfo: sendMessageToExtensionUpdateInfo,
  },
} as const
