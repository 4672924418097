import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'dialog-google-sign-in'

// ~~~~~~ Component

export const STDialogGoogleSignInContent = styled.div`
  .${ClassName}--title {
    ${Fonts.Weight[700]}
    ${Fonts.Scale.Subtitle}

    text-align: left;

    margin-bottom: ${Layout.Spacing[3]}px;
  }

  .${ClassName}--line {
    ${Fonts.Scale.Body}
    text-align: left;

    color: ${theme(Colors.WOLF_80_20)};

    margin-bottom: ${Layout.Spacing[3]}px;
  }

  svg.${ClassName}--gaction {
    cursor: pointer;
    height: 43px;
  }
`
