import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsPIP } from '../../../../../behaviour-logic/content/main/toolbars/main/buttons/pip/hook'
import { ClassName, STJoinedMobileToolbarsMainButtonsPip } from './style'

// ~~~~~~ Constants

const PipIsOffIcon = LazySVG('icons/pip-inactive')
const PipIsOnIcon = LazySVG('icons/pip-active')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
  onUpdate: () => void
}

// ~~~~~~ Component

export const JoinedMobileToolbarsMainButtonsPip: React.FC<Props> = ({ video, onUpdate }) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsPIP(video, onUpdate)

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsMainButtonsPip onClick={logic.onClick}>
      {/* Icon */}

      {logic.isPipOn() ? (
        <PipIsOnIcon className={`${ClassName}--icon-on`} size={24} />
      ) : (
        <PipIsOffIcon className={`${ClassName}--icon-off`} size={24} />
      )}

      {/* -- */}
    </STJoinedMobileToolbarsMainButtonsPip>
  )
}
