const prefix = 'at-api-bcast-google-classroom'

// ~~~~~~ Action Types

export const ApiBcastGoogleClassRoomAT = {
  LIST: `${prefix}-list`,
  ANNOUNCE_BCAST_LINK: `${prefix}-announce-bcast-link`,
  ANNOUNCE_CAPTURE: `${prefix}-announce-capture`,
} as const

// ~~~~~~ Action Creators

export type WithGoogleAccessToken =
  | {
      withIt: true
      accessToken: string
    }
  | { withIt: false }

export const ApiBcastGoogleClassRoomAC = {
  list: (withGoogleAccessToken: WithGoogleAccessToken) => ({
    type: ApiBcastGoogleClassRoomAT.LIST,
    payload: {
      withGoogleAccessToken,
    },
  }),

  announceBCastLink: (
    withGoogleAccessToken: WithGoogleAccessToken,
    course: {
      id: string
      name: string
    },
    text: string,
    materialLink: DN.Models.GoogleClassRoom.MaterialLink,
  ) => ({
    type: ApiBcastGoogleClassRoomAT.ANNOUNCE_BCAST_LINK,
    payload: {
      withGoogleAccessToken,
      course,
      text,
      materialLink,
    },
  }),

  announceCapture: (
    withGoogleAccessToken: WithGoogleAccessToken,
    course: {
      id: string
      name: string
    },
    text: string,
    materialImage: DN.Models.GoogleClassRoom.MaterialImage,
  ) => ({
    type: ApiBcastGoogleClassRoomAT.ANNOUNCE_CAPTURE,
    payload: {
      withGoogleAccessToken,
      course,
      text,
      materialImage,
    },
  }),
} as const
