import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import {
  EpicApiServerPubMainMC,
  EpicApiServerPubMainMT,
} from '../../../epics/api-stream-manager/server/pub-main/mutators'
import {
  EpicApiServerSubMainMT,
  EpicApiServerSubMainMC,
} from '../../../epics/api-stream-manager/server/sub-main/mutators'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'

const initState = AppInitState.stunTurnCredentials

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Get server pub main - OK
// ####################################################################################################

reducer[EpicApiServerPubMainMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiServerPubMainMC.ok>,
): State => {
  return {
    ...initState,
    ...payload.stunTurnCredentials,
  }
}

// ####################################################################################################
// ~~~~~~ Get server sub main - OK
// ####################################################################################################

reducer[EpicApiServerSubMainMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiServerSubMainMC.ok>,
): State => {
  return {
    ...initState,
    ...payload.stunTurnCredentials,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel now all - DONE
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~

export const stunTurnCredentialsReducer = UtilsStore.dynReducer(initState, reducer)
