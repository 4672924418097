import { motion } from 'framer-motion'
import { ClassName, STOnboardingPanelPercent } from './style'

// ~~~~~~ Types

type Props = {
  value: number
}

// ~~~~~~ Component

export const OnboardingPanelPercent: React.FC<Props> = ({ value }) => {
  // ~~~~~~ Render

  return (
    <STOnboardingPanelPercent>
      {/* Value */}

      <div className={`${ClassName}--value`}>{value}%</div>

      {/* Bar */}

      <div className={`${ClassName}--bar`}>
        <motion.div className={`${ClassName}--progress`} animate={{ width: `${value}%` }} />
      </div>
    </STOnboardingPanelPercent>
  )
}
