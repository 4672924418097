const prefix = 'mt-api-bcast-google-class-room-list-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastGoogleClassRoomListMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastGoogleClassRoomListMC = {
  ok: (data: DN.Models.GoogleClassRoom.Api.List.ResParsed) => ({
    type: EpicApiBcastGoogleClassRoomListMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.GoogleClassRoom.Api.List.ResError, unsync: boolean) => ({
    type: EpicApiBcastGoogleClassRoomListMT.ERROR,
    error: true,
    payload: {
      unsync,
      errors,
    },
  }),
} as const
