import { HocIntl, HocTheme } from '@dn/hocs'
import { ServiceBcast } from '@dn/bcast'
import { CurrentUserModel } from '../bcast/current-user/model'
import { PaintBcastStreamModel } from '../bcast/paint-bcast-stream/model'
import { StorePlanModel } from '../store/plan/model'
import { StoreProductsModel } from '../store/products/model'
import { StreamManagerServerModel } from '../stream-manager/server/model'
import { StreamManagerSubMain } from '../stream-manager/sub/main/model'
import { SubscriptionModel } from '../bcast/subscription/model'
import { StreamManagerPubModel } from '../stream-manager/pub/model'
import { RootModel } from '../bcast/root/model'
import { DebuggerModel } from '../bcast/debugger/model'
import { ModalsModel } from '../bcast/modals/model'
import { ProviderModel } from '../bcast/providers/model'
import { SharedStreamModel } from '../bcast/shared-main-stream/model'
import { ExitModel } from '../bcast/exit/model'
import { StatsModel } from '../bcast/stats/model'
import { TimeCheckModel } from '../bcast/time-check/model'
import { BcastDurationModel } from '../bcast/bcast-duration/model'
import { GoogleClassRoomModel } from '../bcast/google-class-room/model'
import { ImageToShareModel } from '../bcast/image-to-share/model'
import { GoogleSlidesModel } from '../bcast/google-presentations/model'
import { ScreenShotModel } from '../bcast/screenshot/model'
import { SettingsModel } from '../bcast/ui-settings/model'
import { CommChannelModel } from '../bcast/comm-channel/model'
import { LivePaintModel } from '../bcast/live-paint/model'
import { DevicesModel } from '../bcast/devices/model'
import { SharedCamMicModel } from '../bcast/shared-cam-mic/model'
import { StunTurnModel } from '../stun-turn/credentials/model'
import { StreamManagerSubCamMic } from '../stream-manager/sub/cam-mic/model'
import { NetworkCongestion } from '../bcast/network-congestion/model'
import { ViewersModel } from '../bcast/viewers/model'
import { LayoutPanelsModel } from '../bcast/ui-layout-panels/model'
import { BcastCountdownModel } from '../bcast/bcast-countdown/model'
import { UserSettingsModel } from '../bcast/user-settings/model'
import { LayoutViewerMobileModel } from '../bcast/ui-layout-viewer-mobile/model'
import { UiMobileToolbar } from '../bcast/ui-mobile-toolbars/model'
import { UiPipFullscreenModel } from '../bcast/ui-pip-fullscreen/model'
import { UiOnboardingModel } from '../bcast/ui-onboarding/model'
import { UiExtensionMenusModel } from '../bcast/ui-extension-menus/model'
import { FeedbackEndBcastModel } from '../bcast/feedback/end-bcast/model'
import { UiPipModel } from '../bcast/ui-pip/model'
import { StreamContextModel } from '../bcast/stream-context/model'
import { StoreDurationModel } from '../bcast/store-duration/model'

// ~~~~~~ Model

export const AppInitState: DN.Models.App.Model = {
  // Libs

  dnBcast: ServiceBcast.React.Model.initModel,

  // ####################################################################################################
  // ~~~~~~ BCast
  // ####################################################################################################

  // Root

  root: RootModel.initModel,

  // User Interface

  uiSettings: SettingsModel.initModel,
  uiLayoutPanels: LayoutPanelsModel.initModel,
  uiLayoutViewerMobile: LayoutViewerMobileModel.initModel,
  uiMobileToolbar: UiMobileToolbar.initModel,
  uiPipFullscreen: UiPipFullscreenModel.initModel,
  uiPip: UiPipModel.initModel,
  uiOnboarding: UiOnboardingModel.initModel,
  uiExtensionMenus: UiExtensionMenusModel.initModel,

  // Debugger

  debugger: DebuggerModel.initModel,

  // Intl

  intl: HocIntl.Model.initModel,

  // Modals

  modals: ModalsModel.initModel,

  // Theme

  theme: HocTheme.Model.initModel,

  // Devices

  devices: DevicesModel.initModel,

  // Current User

  currentUser: CurrentUserModel.initModel,

  userSettings: UserSettingsModel.initModel,
  userSettingsEdit: UserSettingsModel.initModel,

  subscription: SubscriptionModel.initModel,

  // Google

  providerGoogle: ProviderModel.initGoogleModel,

  // - Classroom

  googleClassRoomMetaList: GoogleClassRoomModel.initMetaListModel,

  googleClassRoomAnnouncementBcastLink: GoogleClassRoomModel.initAnnouncementModel,

  googleClassRoomAnnouncementCapture: GoogleClassRoomModel.initAnnouncementModel,

  // - Slides

  googleSlidesPresentationMetaList: GoogleSlidesModel.initMetaListModel,

  googleSlidesCreatePresentation: GoogleSlidesModel.initCreateModel,

  googleSlidesAddImageToSlide: GoogleSlidesModel.initAddImageToSlideModel,

  // Shared stream

  sharedMainStream: SharedStreamModel.initModel,

  // Shared cam and mic

  sharedCamMicStream: SharedCamMicModel.initModel,

  // Exit

  exit: ExitModel.initModel,

  // Stats

  stats: StatsModel.initModel,

  // Time check

  timeCheck: TimeCheckModel.initModel,

  // Broadcast duration

  bcastDuration: BcastDurationModel.initModel,

  // Broadcast countdown

  bcastCountdown: BcastCountdownModel.initModel,

  // Paint Bcast Stream

  paintBcastStream: PaintBcastStreamModel.initModel,
  paintBcastStreamPic: PaintBcastStreamModel.initPicModel,
  paintBcastStreamDraw: PaintBcastStreamModel.initDrawModel,
  paintBcastStreamVideo: PaintBcastStreamModel.initVideoModel,

  // Live paint

  livePaint: LivePaintModel.initModel,
  livePaintDraw: LivePaintModel.initDrawModel,
  livePaintVideo: LivePaintModel.initVideoModel,

  // ScreenShot

  screenShot: ScreenShotModel.initModel,

  // Image to share

  imageToShare: ImageToShareModel.initModel,

  // Communication channel

  commChannel: CommChannelModel.initModel,

  // Stun/Turn Credentials

  stunTurnCredentials: StunTurnModel.initModel,

  // Network Congestion

  networkCongestionPub: NetworkCongestion.initPubModel,
  networkCongestionSub: NetworkCongestion.initSubModel,

  // Viewers info

  viewers: ViewersModel.initModel,

  // Feedback

  feedbackEndBcast: FeedbackEndBcastModel.initModel,

  // Stream context

  streamContext: StreamContextModel.initModel,

  // Store duration

  storeDuration: StoreDurationModel.initModel,

  // ####################################################################################################
  // ~~~~~~ Stream Manager
  // ####################################################################################################

  // Server

  streamManagerServerSubMain: StreamManagerServerModel.initModel,
  streamManagerServerSubCamMic: StreamManagerServerModel.initModel,

  streamManagerServerPubMain: StreamManagerServerModel.initModel,
  streamManagerServerPubCamMic: StreamManagerServerModel.initModel,

  // Red5Pro Subscription/Publication (viewer/broadcasting)

  streamManagerPubMain: StreamManagerPubModel.initModel,
  streamManagerPubCamMic: StreamManagerPubModel.initModel,

  streamManagerSubMain: StreamManagerSubMain.initModel,
  streamManagerSubCamMic: StreamManagerSubCamMic.initModel,

  // ####################################################################################################
  // ~~~~~~ Store
  // ####################################################################################################

  storePlan: StorePlanModel.planInitModel,
  storeProducts: StoreProductsModel.initBcastModel,
}

// ~~~~~~ Export types

export type StoreState = typeof AppInitState

export type StoreStateProps = keyof StoreState
