import { Observable, zip } from 'rxjs'
import { Config } from '../../../config'

import { UtilsLog } from '../../../utils/logs'
import { BroadcastStop, BroadcastStop$$ } from '../subject/stop/broadcast-partial-service'

type StopData = {
  mainPub?: R5P.Publisher
  mainSub?: R5P.Subscription
  camMicPub?: R5P.Publisher
  camMicSub?: R5P.Subscription
  stop: BroadcastStop
}

export const stop$ = ({ mainPub, camMicPub, mainSub, camMicSub, stop }: StopData) => {
  Config.Logs.BroadcastService &&
    UtilsLog.devLog(
      '\nBCastService',
      'stop$',
      '\n- main publisher\n',
      mainPub,
      '\n- cam mic publisher\n',
      camMicPub,
      '\n- main subscription\n',
      mainSub,
      '\n- cam mic subscription\n',
      camMicSub,
    )

  // Stop listening events

  BroadcastStop$$.next(stop)

  // Unsubscribe from "main publisher"

  const obsMainPub$ = new Observable((observer) => {
    if (!mainPub) {
      observer.next('main publisher unpublish NOTHING')
      observer.complete()
      return
    }

    mainPub
      .unpublish()
      .then(() => {
        observer.next('pub main unpublish OK')
        observer.complete()
      })
      .catch(() => {
        observer.next('pub main unpublish ERROR')
        observer.complete()
      })
  })

  // Unsubscribe from "cam mic publisher"

  const obsCamMicPub$ = new Observable((observer) => {
    if (!camMicPub) {
      observer.next('pub cam-mic unpublish NOTHING')
      observer.complete()
      return
    }

    camMicPub
      .unpublish()
      .then(() => {
        observer.next('pub cam-mic unpublish OK')
        observer.complete()
      })
      .catch(() => {
        observer.next('pub cam-mic publisher unpublish ERROR')
        observer.complete()
      })
  })

  // Unsubscribe from "main subscription"

  const obsMainSub$ = new Observable((observer) => {
    if (!mainSub) {
      observer.next('sub main unsubscribe NOTHING')
      observer.complete()
      return
    }

    mainSub
      .unsubscribe()
      .then(() => {
        observer.next('main subscription unsubscribe OK')
        observer.complete()
      })
      .catch(() => {
        observer.next('main subscription unsubscribe ERROR')
        observer.complete()
      })
  })

  // Unsubscribe from "cam mic subscription"

  const obsCamMicSub$ = new Observable((observer) => {
    if (!camMicSub) {
      observer.next('sub cam-mic unsubscribe NOTHING')
      observer.complete()
      return
    }

    camMicSub
      .unsubscribe()
      .then(() => {
        observer.next('sub cam-mic unsubscribe OK')
        observer.complete()
      })
      .catch(() => {
        observer.next('sub cam-mic unsubscribe ERROR')
        observer.complete()
      })
  })

  return zip(obsMainPub$, obsMainSub$, obsCamMicPub$, obsCamMicSub$)
}
