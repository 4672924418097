import { catchError, map, Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

const { CreatePresentation } = ApiBcastRoutes.GoogleSlides

// ####################################################################################################
// ~~~~~~ Create Google slides
// ####################################################################################################

type WithGoogleAccessToken =
  | {
      withIt: true
      accessToken: string
    }
  | {
      withIt: false
      token: string
    }

type Res = DN.Models.GoogleSlides.Api.Create.Res
type ResParsed = DNApiResParsed<DN.Models.GoogleSlides.Api.Create.ResParsed>
type ResError = DNApiError<DN.Models.GoogleSlides.Api.Create.ResError>

export const apiBcastGoogleSlidesCreatePresentation$ = (
  withGoogleAccessToken: WithGoogleAccessToken,
  data: DN.Models.GoogleSlides.Api.Create.Req,
  headers?: AppHeaders,
) => {
  const finalHeaders = withGoogleAccessToken.withIt
    ? BcastHeaderUtils.genHeadersWithGoogleAccessToken(withGoogleAccessToken.accessToken, headers)
    : BcastHeaderUtils.genHeadersWithAuth(withGoogleAccessToken.token, headers)

  return ajax<Res>({
    ...finalHeaders,
    method: CreatePresentation.Method,
    url: CreatePresentation.getUrl(),
    body: data,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response.presentation,
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.google-slides.create.Error' }],
      })
    }),
  )
}
