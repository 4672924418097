import { UserSettingsTemplate } from '../0-template/component'
import { UserSettingsProvidersGoogle } from './google/component'

// ~~~~~~ Component

export const UserSettingsProviders = () => {
  // ~~~~~~ Render

  return (
    // @ts-ignore
    <UserSettingsTemplate title="common-components.user-settings.sections.providers.Title">
      <UserSettingsProvidersGoogle />
    </UserSettingsTemplate>
  )
}
