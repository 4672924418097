import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { UtilsLog } from '../../utils/logs'

// ~~~~~~ Constants

const regExp = /^[0-9]{6}$/

// ~~~~~~ Types

type Data = {
  broadcastId: string | undefined
  redirectIfUndef: boolean
  redirectTo: string
}

// ~~~~~~ Hook

export const useCheckIsBroadcastIdOrRedirect = ({
  broadcastId,
  redirectIfUndef,
  redirectTo,
}: Data) => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ Computed

  const isValidBroadcastId = regExp.test(broadcastId || '')

  const redirect = broadcastId ? !isValidBroadcastId : redirectIfUndef

  // ~~~~~~ Effects

  // - Redirect

  useEffect(() => {
    if (!redirect) return

    UtilsLog.devLog(
      String.fromCodePoint(128256),
      'useCheckIsBroadcastIdOrRedirect',
      'redirect to',
      redirectTo,
    )

    navigate(redirectTo, { replace: true })

    //
  }, [navigate, redirect, redirectTo])

  // ~~~~~~

  return isValidBroadcastId
}
