// type ProductIds = {
//   [key: string]: DN.Models.Product.ProductId
// }

export const ProductIds = {
  Launcher: 'launcher',
  Montage: 'montage',
  BroadcastSub: 'broadcast-subscription',
  // BroadcastLicense: 'broadcast-license',
} as const

export const StoreProductIds = {
  launcher: 'launcher',
  montage: 'montage',
  'broadcast-subscription': 'broadcastSub',
} as const
