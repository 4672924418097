import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCheckAuth } from '../../../hooks/auth/use-check-auth'
import { StoreState } from '../../../models/app/model'
import { UiSettingsEditFieldsMR } from '../../../models/bcast/ui-settings/model'
import { UserSettingsEditFieldsMR } from '../../../models/bcast/user-settings/model'
import { UtilsMobile } from '@dn/utils'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { Trans } from '../intl/trans'
import { UserSettingsAccount } from './1-account/component'
import { UserSettingsProviders } from './2-providers/component'
import { UserSettingsGeneral } from './3-general/component'
import { ClassName, STUserSettings } from './style'

// ~~~~~~ Constants

const isMobile = UtilsMobile.calcIsMobile()

const IconAccount = LazySVG('icons/user')
const IconProviders = LazySVG('icons/grid')
const IconGeneral = LazySVG('icons/layout')

const SecctionIcon: {
  [key in DN.Models.UiSettings.Section]: {
    Icon: JSX.Element
    paint: 'stroke' | 'fill'
  }
} = {
  account: {
    Icon: <IconAccount size={20} />,
    paint: 'fill',
  },

  providers: {
    Icon: <IconProviders size={20} />,
    paint: 'stroke',
  },

  general: {
    Icon: <IconGeneral size={20} />,
    paint: 'stroke',
  },
}

const Sections: DN.Models.UiSettings.Section[] = ['account', 'providers', 'general']

const SectionTitle: {
  [key in DN.Models.UiSettings.Section]: IntlMsgId
} = {
  account: 'common-components.user-settings.sections.account.Title',
  providers: 'common-components.user-settings.sections.providers.Title',
  general: 'common-components.user-settings.sections.general.Title',
}

const sectionsForAllUsers: DN.Models.UiSettings.Section[] = ['general']

// ~~~~~~ Component

export const UserSettings = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  useCheckAuth()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const userSettings = useSelector((state: StoreState) => state.userSettings)

  const uiSettings = useSelector((state: StoreState) => state.uiSettings)

  // ~~~~~~ Computed

  const sectionSelected = uiSettings.sectionSelected

  const finalSections = Sections.filter((section) => {
    return currentUser.id ? true : sectionsForAllUsers.includes(section)
  })

  // ~~~~~~ Handlers

  function onClickSection(section: DN.Models.UiSettings.Section) {
    if (sectionSelected === section) return

    if (section === 'general') {
      dispatch(UserSettingsEditFieldsMR.__model__.MC.setModel(userSettings))
    }

    dispatch(UiSettingsEditFieldsMR.section.MC.change(section))
  }

  // ~~~~~~ Effects

  // Prevent 'providers' section to be selected without user

  useEffect(() => {
    if (currentUser.id && Sections.includes(sectionSelected)) return

    if (sectionsForAllUsers.includes(sectionSelected)) return

    dispatch(UiSettingsEditFieldsMR.section.MC.change('general'))

    //
  }, [currentUser.id, dispatch, sectionSelected])

  // ~~~~~~ Render

  if (!currentUser.email) return null

  return (
    <STUserSettings data-testid="user-settings">
      {/* Heder */}

      {!isMobile ? (
        <div className={`${ClassName}--header`}>
          <div className={`${ClassName}--header--title`}>
            <Trans id="common-components.user-settings.Title" />
          </div>
        </div>
      ) : undefined}

      {/* Panel */}

      <div className={`${ClassName}--panel`}>
        {/* Left panel: Sections */}

        {!isMobile ? (
          <div data-testid="actions" className={`${ClassName}--pannel--left`}>
            {finalSections.map((section) => (
              <div
                data-testid={`show-${section}-act`}
                key={section}
                className={`${ClassName}--pannel--left--section ${
                  sectionSelected === section ? 'active' : ''
                }`}
                onClick={() => onClickSection(section)}
              >
                <div
                  className={`${ClassName}--pannel--left--section--icon ${SecctionIcon[section].paint}`}
                >
                  {SecctionIcon[section].Icon}
                </div>

                <Trans id={SectionTitle[section]} />
              </div>
            ))}
          </div>
        ) : undefined}

        {/* Right panel: Detail */}

        <div className={`${ClassName}--panel--right`}>
          {/* Account */}

          {sectionSelected === 'account' ? <UserSettingsAccount /> : undefined}

          {/* Providers */}

          {sectionSelected === 'providers' ? <UserSettingsProviders /> : undefined}

          {/* General */}

          {sectionSelected === 'general' ? <UserSettingsGeneral /> : undefined}
        </div>
      </div>
    </STUserSettings>
  )
}
