export const Bronx = {
  90: '#64511e',
  80: '#836a27',
  70: '#aa8832',
  60: '#d9af41',
  50: '#efc047',
  40: '#f2cd6c',
  30: '#f4d584',
  20: '#f8e2aa',
  10: '#faebc6',
  5: '#fdf9ed',
} as const

// ~~~~~~

const BRONX_05_40: ThemeColor = {
  light: Bronx[5],
  dark: Bronx[40],
}

const BRONX_20_50: ThemeColor = {
  light: Bronx[20],
  dark: Bronx[50],
}

const BRONX_30_40: ThemeColor = {
  light: Bronx[30],
  dark: Bronx[40],
}

const BRONX_40_30: ThemeColor = {
  light: Bronx[40],
  dark: Bronx[30],
}

const BRONX_40_40: ThemeColor = {
  light: Bronx[40],
  dark: Bronx[40],
}

const BRONX_40_50: ThemeColor = {
  light: Bronx[40],
  dark: Bronx[50],
}

const BRONX_50_20: ThemeColor = {
  light: Bronx[50],
  dark: Bronx[20],
}

const BRONX_50_30: ThemeColor = {
  light: Bronx[50],
  dark: Bronx[30],
}

const BRONX_50_40: ThemeColor = {
  light: Bronx[50],
  dark: Bronx[40],
}

const BRONX_50_50: ThemeColor = {
  light: Bronx[50],
  dark: Bronx[50],
}

const BRONX_50_60: ThemeColor = {
  light: Bronx[50],
  dark: Bronx[60],
}

const BRONX_60_40: ThemeColor = {
  light: Bronx[60],
  dark: Bronx[40],
}

const BRONX_60_50: ThemeColor = {
  light: Bronx[60],
  dark: Bronx[50],
}

const BRONX_60_60: ThemeColor = {
  light: Bronx[60],
  dark: Bronx[50],
}

const BRONX_70_50: ThemeColor = {
  light: Bronx[70],
  dark: Bronx[50],
}

// ~~~~~~

export const BronxColors = {
  BRONX_05_40,
  BRONX_20_50,
  BRONX_30_40,
  BRONX_40_30,
  BRONX_40_40,
  BRONX_40_50,
  BRONX_50_20,
  BRONX_50_30,
  BRONX_50_40,
  BRONX_50_50,
  BRONX_50_60,
  BRONX_60_40,
  BRONX_60_50,
  BRONX_60_60,
  BRONX_70_50,
} as const
