import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ModalsIds } from '../../../../../constants/modals'
import { StoreState } from '../../../../../models/app/model'
import { genAnimatedDialog } from '../../../compound-dialog/dialog/component'
import { DialogConfirmation } from '../../component'

// ~~~~~~ Component

const ModalId = ModalsIds.ConfirmPrefedMsgClosedByNoViewers

const Dialog = genAnimatedDialog(ModalId)

// ~~~~~~ Component

export const DialogPredefMsgConfirmClosedByNoViewers = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Handlers

  function onClickOk() {
    setDoClose(performance.now())
  }

  function onEndDissapear() {
    navigate(redirectPath, { replace: true })
  }

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      Dialog={Dialog}
      predefIcon="info"
      title="dialogs.confirm.predef-msg.closed-by-no-viewers.Title"
      contentText="dialogs.confirm.predef-msg.closed-by-no-viewers.Content"
      okButtonIntl="common.OK"
      isRunning={false}
      doClose={doClose}
      onEndDisappear={onEndDissapear}
      onClickClose={() => undefined}
      onClickConfirm={onClickOk}
      hideCloseButton={true}
    />
  )
}
