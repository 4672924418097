import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../models/app/model'
import { ApiStoreProductsAC } from '../../store/actions/api-store/products/actions'

export const useApiGetProducts = (pricesIsOpen: boolean) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const products = useSelector((state: StoreState) => state.storeProducts)

  // ~~~~~~ Effects

  // - Retrive list products if dialog prices is open

  useEffect(() => {
    if (!pricesIsOpen || products.uiStatus !== 'init') return

    dispatch(ApiStoreProductsAC.listProducts('broadcast-subscription'))
    //
  }, [dispatch, pricesIsOpen, products])
}
