import { Link } from 'react-router-dom'
import { Cookies } from '../../../components/common/cookies/component'

import {
  DNPrivacyMail,
  MailChimpPrivacyAndTerms,
  HubSpotPrivacyAndTerms,
  ZohoPrivacyAndTerms,
  MailChimpPrivacy,
  MailChimpTerms,
  HubSpotPrivacy,
  HubSpotTerms,
  ZohoPrivacy,
  ZohoTerms,
} from '../../../components/common/privacy-terms-links/component'

import { TableCookieKindB } from '../../../components/common/tables/cookies/kind-b/component'

import {
  TableDefinition,
  TablesDefinitioins,
} from '../../../components/common/tables/definitions/component'

import { LayoutText } from '../../../components/layouts/text/component'
import { Routes } from '../../../constants/routes/routes'
import { STPrivacyPolicy } from './style'

// ~~~~~~ Constants

const Definitions: TableDefinition[] = [
  {
    word: 'Account',
    def: 'means an account required to access and/or use certain areas and features of Our Services;',
  },

  {
    word: 'Cookies',
    def: 'means a small text file placed on your computer or device by Our Services when you visit certain parts of Our Services and/or when you use certain features of Our Services. Details of the Cookies used by Our Services are set out in section 13, below;',
  },

  {
    word: 'Our Services',
    def: 'means collectively the software products, online services and websites owned and operated by the company.',
  },

  {
    word: 'Personal Data',
    def: 'means any and all data that relates to an identifiable person who can be directly or indirectly identified from that data. In this case, it means personal data that you give to Us via Our Services. This definition shall, where applicable, incorporate the definitions provided in the EU Regulation 2016/679 – the General Data Protection Regulation ("GDPR"); and',
  },

  {
    word: 'We/Us/Our',
    def: 'Means DisplayNote Technologies Limited, a limited company registered in Northern Ireland under company number NI610261, whose registered address is The Concourse 3 Building, Queens Road, Belfast, Northern Ireland, BT3 9DT, and whose main trading address is The Concourse 3 Building, Queens Road, Belfast, Northern Ireland, BT3 9DT.',
  },
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CookiesMandatory: TableCookieKindB[] = []

// ~~~~~~ Component

export const PrivacyPolicyPage = () => {
  // ~~~~~~ Render

  return (
    <LayoutText>
      <STPrivacyPolicy>
        <h1>Privacy Policy</h1>

        <h2>BACKGROUND</h2>

        <p>
          DisplayNote Technologies Limited understands that your privacy is important to you and
          that you care about how your personal data is used and shared online. We respect and value
          the privacy of everyone who visits this website
          <span> </span>
          <a target="_blank" href="https://www.displaynote.com" rel="noreferrer">
            www.displaynote.com
          </a>
          <span> </span>
          or
          <span> </span>
          <Link to={Routes.Main}>broadcast.online</Link>
          <span> </span>
          and uses our products or service and will only collect and use personal data in ways that
          are described here, and in a manner that is consistent with Our obligations and your
          rights under the law.
        </p>

        <p>
          Please read this Privacy Policy carefully and ensure that you understand it. Your
          acceptance of Our Privacy Policy is deemed to occur upon your first use of Our Services
          and. If you do not accept and agree with this Privacy Policy, you must stop using Our
          Services immediately.
        </p>

        {/* 1 */}

        <h2>1. Definitions and Interpretation</h2>

        <p>In this Policy, the following terms shall have the following meanings:</p>

        <TablesDefinitioins definitions={Definitions} />

        {/* 2 */}

        <h2>2. Information About Us</h2>

        <p>
          2.1 Our Services is owned and operated by DisplayNote Technologies Limited, a limited
          company registered in Northern Ireland under company number NI610261, whose registered
          address is The Concourse 3 Building, Queens Road, Belfast, Northern Ireland, BT3 9DT and
          whose main trading address is The Concourse 3 Building, Queens Road, Belfast, Northern
          Ireland, BT3 9DT.
        </p>

        <p>2.2 Our VAT number is GB125654319.</p>

        {/* 3 */}

        <h2>3. What Does This Policy Cover?</h2>

        <p>
          This Privacy Policy applies only to your use of Our Services. Our Services may contain
          links to other websites. Please note that We have no control over how your data is
          collected, stored, or used by other websites and We advise you to check the privacy
          policies of any such websites before providing any data to them.
        </p>

        {/* 4 */}

        <h2>4. Your Rights</h2>

        <p>
          4.1 As a data subject, you have the following rights under the GDPR, which this Policy and
          Our use of personal data have been designed to uphold:
        </p>

        <p>4.1.1 The right to be informed about Our collection and use of personal data;</p>

        <p>4.1.2 The right of access to the personal data We hold about you (see section 12);</p>

        <p>
          4.1.3 The right to rectification if any personal data We hold about you is inaccurate or
          incomplete (please contact Us using the details in section 14);
        </p>

        <p>
          4.1.4 The right to be forgotten - i.e. the right to ask Us to delete any personal data We
          hold about you (We only hold your personal data for a limited time, as explained in
          section 6 but if you would like Us to delete it sooner, please contact Us using the
          details in section 14);
        </p>

        <p>4.1.5 The right to restrict (i.e. prevent) the processing of your personal data;</p>

        <p>
          4.1.6 The right to data portability (obtaining a copy of your personal data to reuse with
          another service or organisation);
        </p>

        <p>4.1.7 The right to object to Us using your personal data for particular purposes; and</p>

        <p>4.1.8 Rights with respect to automated decision making and profiling.</p>

        <p>
          4.2 If you have any cause for complaint about Our use of your personal data, please
          contact Us using the details provided in section 14 and We will do Our best to solve the
          problem for you. If We are unable to help, you also have the right to lodge a complaint
          with the UK's supervisory authority, the Information Commissioner's Office.
        </p>

        <p>
          4.3 For further information about your rights, please contact the Information
          Commissioner's Office or your local Citizens Advice Bureau.
        </p>

        {/* 5 */}

        <h2>5. What Data Do We Collect?</h2>

        <p>
          Depending upon your use of Our Services, We may collect some or all of the following
          personal data (please also see section 13 on Our use of Cookies and similar technologies):
        </p>

        <p>5.1 name;</p>

        <p>5.2 date of birth;</p>

        <p>5.3 business/company name</p>

        <p>5.4 job title;</p>

        <p>5.5 contact information such as email addresses and telephone numbers;</p>

        <p>5.6 demographic information such as post code, preferences, and interests;</p>

        <p>5.7 financial information such as credit / debit card numbers;</p>

        <p>5.8 IP address;</p>

        <p>5.9 web browser type and version;</p>

        <p>5.10 operating system;</p>

        <p>
          5.11 a list of URLs starting with a referring site, your activity on Our Services, and the
          site you exit to;
        </p>

        {/* 6 */}

        <h2>6. How Do We Use Your Data?</h2>

        <p>
          6.1 All personal data is processed and stored securely, for no longer than is necessary in
          light of the reason(s) for which it was first collected. We will comply with Our
          obligations and safeguard your rights under the GDPR at all times. For more details on
          security see section 7, below.
        </p>

        <p>
          6.2 Our use of your personal data will always have a lawful basis, either because it is
          necessary for Our performance of a contract with you, because you have consented to Our
          use of your personal data (e.g. by subscribing to emails), or because it is in Our
          legitimate interests. Specifically, We may use your data for the following purposes:
        </p>

        <p>6.2.1 Providing and managing your Account;</p>

        <p>6.2.2 Providing and managing your access to Our Services;</p>

        <p>6.2.3 Personalising and tailoring your experience on Our Services;</p>

        <p>
          6.2.4 Supplying Our products and services to you (please note that We require your
          personal data in order to enter into a contract with you);
        </p>

        <p>6.2.5 Personalising and tailoring Our products and services for you;</p>

        <p>6.2.6 Replying to emails from you;</p>

        <p>
          6.2.7 Supplying you with emails that you have opted into (you may unsubscribe or opt-out
          at any time by clicking on the unsubscribed link within all emails or contacting
          <span> </span>
          <DNPrivacyMail />
        </p>

        <p>6.2.8 Market research;</p>

        <p>
          6.2.9 Analysing your use of Our Services to enable Us to continually improve Our Services
          and your user experience;
        </p>

        <p>
          6.3 With your permission and/or where permitted by law, We may also use your data for
          marketing purposes which may include contacting you by email, telephone or post with
          information, news and offers on Our products and services. We will not, however, send you
          any unsolicited marketing or spam and will take all reasonable steps to ensure that We
          fully protect your rights and comply with Our obligations under the GDPR and the Privacy
          and Electronic Communications (EC Directive) Regulations 2003.
        </p>

        <p>
          6.4 Third parties (including Mailchimp and HubSpot) whose content appears on Our Services
          may use third party Cookies, as detailed below in section 13. Please refer to section 13
          for more information on controlling Cookies. Please note that We do not control the
          activities of such third parties, nor the data they collect and use and advise you to
          check the privacy policies of any such third parties.
        </p>

        <p>
          6.5 You have the right to withdraw your consent to Us using your personal data at any
          time, and to request that We delete it.
        </p>

        <p>
          6.6 We do not keep your personal data for any longer than is necessary in light of the
          reason(s) for which it was first collected. Data will therefore be retained for the
          following periods (or its retention will be determined on the following bases):
        </p>

        <p>
          6.6.1 Personal data that you have submitted and opted into within a website form or
          marketing mailing list be retained within our marketing mailing lists held in our
          marketing automation platforms, Mailchimp and HubSpot. By opting into a marketing list,
          you acknowledge that the information you provide will be transferred to Mailchimp for
          processing in accordance with their
          <span> </span>
          <MailChimpPrivacyAndTerms />.
        </p>

        <p>
          6.6.2 By downloading whitepapers and eBooks from our resources page on Our Services and
          opting into a marketing list, you acknowledge that the information you provide will be
          transferred to HubSpot for processing in accordance with their
          <span> </span>
          <HubSpotPrivacyAndTerms />.
        </p>

        <p>
          6.6.3 If you submit a form request to be contacted by our sales team or to book a demo,
          your personal data will be held within Zoho.com our CRM systems. You acknowledge that the
          information you provide will be transferred to Zoho.com for processing in accordance with
          their
          <span> </span>
          <ZohoPrivacyAndTerms />.
        </p>

        <p>
          6.6.4 Your data will be held for a minimum of 5 years or more and will be retained unless
          you choose to unsubscribe from our lists. You can unsubscribe at any time by clicking on
          the unsubscribe button in all emails from us or sending an email to
          <span> </span>
          <DNPrivacyMail />
        </p>

        {/* 7 */}

        <h2>7. How and Where Do We Store Your Data?</h2>

        <p>
          7.1 We only keep your personal data for as long as We need to in order to use it as
          described above in section 6, and/or for as long as We have your permission to keep it.
        </p>

        <p>
          7.2 Some or all of your data may be stored outside of the European Economic Area ("the
          EEA") (The EEA consists of all EU member states, plus Norway, Iceland, and Liechtenstein).
          You are deemed to accept and agree to this by using Our Services and submitting
          information to Us. If We do store data outside the EEA, We will take all reasonable steps
          to ensure that your data is treated as safely and securely as it would be within the UK
          and under the GDPR.
        </p>

        <p>
          7.3 Data security is very important to Us, and to protect your data We have taken suitable
          measures to safeguard and secure data collected through Our Services.
        </p>

        {/* 8 */}

        <h2>8. Do We Share Your Data?</h2>

        <p>
          8.1 We may sometimes contract with third parties to supply services to you on Our behalf.
          These may include payment processing, delivery of goods, search engine facilities,
          advertising, and marketing. In some cases, the third parties may require access to some or
          all of your data. Where any of your data is required for such a purpose, We will take all
          reasonable steps to ensure that your data will be handled safely, securely, and in
          accordance with your rights, Our obligations, and the obligations of the third party under
          the law.
        </p>

        <p>
          8.2 We may compile statistics about the use of Our Services including data on traffic,
          usage patterns, user numbers, sales, and other information. All such data will be
          anonymised and will not include any personally identifying data, or any anonymised data
          that can be combined with other data and used to identify you. We may from time to time
          share such data with third parties such as prospective investors, affiliates, partners,
          and advertisers. Data will only be shared and used within the bounds of the law.
        </p>

        <p>
          8.3 The third-party data processors used by Us and listed below are located outside of the
          European Economic Area ("the EEA") (The EEA consists of all EU member states, plus Norway,
          Iceland, and Liechtenstein). Where We transfer any personal data outside the EEA, We will
          take all reasonable steps to ensure that your data is treated as safely and securely as it
          would be within the UK and under the GDPR including:
        </p>

        <p>
          8.3.1 Mailchimp - is used as our marketing automation platform. By opting into a marketing
          list, you acknowledge that the information you provide will be transferred to Mailchimp
          for processing in accordance with their
          <span> </span>
          <MailChimpPrivacy />
          <span> </span>
          and
          <span> </span>
          <MailChimpTerms />.
        </p>

        <p>
          8.3.2 HubSpot - is used as our second marketing automation platform. By downloading
          whitepapers and eBooks from our resources page on Our Services and opting into a marketing
          list, you acknowledge that the information you provide will be transferred to HubSpot for
          processing in accordance with their
          <span> </span>
          <HubSpotPrivacy />
          <span> </span>
          and
          <span> </span>
          <HubSpotTerms />.
        </p>

        <p>
          8.3.3 Zoho.com - Zoho CRM is used for our customer relationship management system. If you
          submit a form request to be contacted by our sales team or to book a demo, your personal
          data will be held within Zoho.com our CRM systems. You acknowledge that the information
          you provide will be transferred to Zoho.com for processing in accordance with their
          <span> </span>
          <ZohoPrivacy />
          <span> </span>
          and
          <span> </span>
          <ZohoTerms />.
        </p>

        {/* 8 */}

        <h2>8.3.4 Google</h2>

        <p>
          We use your Google account to connect to Google Classroom and Google Drive. We do not
          store your personal data, but use your Google Classroom and Google Drive information to:
        </p>

        <ol className="indent-1 p-like">
          <li>Populate a list of available classrooms</li>
          <li>Make an announcement to your class containing a link to a Broadcast session</li>
          <li>Make an announcement to your class containing an image</li>
          <li>Store in Google Drive the image needed for announcement</li>
        </ol>

        <p>
          8.4 In certain circumstances, We may be legally required to share certain data held by Us,
          which may include your personal data, for example, where We are involved in legal
          proceedings, where We are complying with legal requirements, a court order, or a
          governmental authority.
        </p>

        {/* 9 */}

        <h2>9. What Happens If Our Business Changes Hands?</h2>

        <p>
          9.1 We may, from time to time, expand or reduce Our business and this may involve the sale
          and/or the transfer of control of all or part of Our business. Any personal data that you
          have provided will, where it is relevant to any part of Our business that is being
          transferred, be transferred along with that part and the new owner or newly controlling
          party will, under the terms of this Privacy Policy, be permitted to use that data only for
          the same purposes for which it was originally collected by Us.
        </p>

        <p>
          9.2 In the event that any of your data is to be transferred in such a manner, you will be
          contacted in advance and informed of the changes. When contacted you will be given the
          choice to have your data deleted or withheld from the new owner or controller.
        </p>

        <h2>10. How Can You Control Your Data?</h2>

        <p>
          10.1 In addition to your rights under the GDPR, set out in section 4, when you submit
          personal data via Our Services, you may be given options to restrict Our use of your data.
          In particular, We aim to give you strong controls on Our use of your data for direct
          marketing purposes (including the ability to opt-out of receiving emails from Us which you
          may do by unsubscribing using the links provided in Our emails and at the point of
          providing your details to
          <span> </span>
          <DNPrivacyMail />
          <span> </span>
          and by managing your Account.
        </p>

        <p>
          10.2 You may also wish to sign up to one or more of the preference services operating in
          the UK: The Telephone Preference Service ("the TPS"), the Corporate Telephone Preference
          Service ("the CTPS"), and the Mailing Preference Service ("the MPS"). These may help to
          prevent you receiving unsolicited marketing. Please note, however, that these services
          will not prevent you from receiving marketing communications that you have consented to
          receiving.
        </p>

        <h2>11. Your Right to Withhold Information.</h2>

        <p>
          11.1 You may access Our Services without providing any data at all. However, to use all
          features and functions available on Our Services you may be required to submit or allow
          for the collection of certain data.
        </p>

        <p>11.2 You may restrict Our use of Cookies. For more information, see section 13.</p>

        <h2>12. How Can You Access Your Data?</h2>

        <p>
          You have the right to ask for a copy of any of your personal data held by Us (where such
          data is held). Under the GDPR, no fee is payable, and We will provide any and all
          information in response to your request free of charge. Please contact Us for more details
          at privacy@displaynote.com, or using the contact details below in section 14.
        </p>

        <Cookies sectionNumber={13} />

        <h2>14. Contacting Us</h2>

        <p>
          If you have any questions about Our Services or this Privacy Policy, please contact Us by
          email at <span> </span> <DNPrivacyMail /> <span> </span>, by telephone on 02890 730 480,
          or by post at The Concourse 3 Building, Queens Road, Belfast, Northern Ireland, BT3 9DT.
          Please ensure that your query is clear, particularly if it is a request for information
          about the data We hold about you (as under section 12, above).
        </p>

        <h2>15. Changes to Our Privacy Policy</h2>

        <p>
          We may change this Privacy Policy from time to time (for example, if the law changes). Any
          changes will be immediately posted on Our Services and you will be deemed to have accepted
          the terms of the Privacy Policy on your first use of Our Services following the
          alterations. We recommend that you check this page regularly to keep up-to-date.
        </p>
      </STPrivacyPolicy>
    </LayoutText>
  )
}
