import { AnimationProps, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Trans } from '../../intl/trans'
import { StoreState } from '../../../../models/app/model'
import { UtilsTooltip } from '@dn/utils'
import { STPaintToolbarButtonsOnlyPens } from './style'
import { PaintToolbarButtonsHiglighther } from '../buttons/higlighther/component'
import { PaintToolbarButtonsMarker } from '../buttons/marker/component'
import { PaintToolbarButtonsPen } from '../buttons/pen/component'

// ~~~~~~ Types

type Props = {
  actionKind: 'paint-bcast-stream' | 'live-paint'

  variants: AnimationProps['variants']
  showAll: boolean

  onClickSelectPen: (newPen: DN.Models.PaintBcastStream.Draw.Pen) => void
  onClickShowPenSelector: () => void
}

// ~~~~~~ Component

export const PaintToolbarButtonsOnlyPens: React.FC<Props> = ({
  actionKind,

  variants,
  showAll,

  onClickSelectPen,
  onClickShowPenSelector,
}) => {
  // ~~~~~~ State

  // - Store

  const selectedPen = useSelector((state: StoreState) => {
    switch (actionKind) {
      case 'paint-bcast-stream':
        return state.paintBcastStreamDraw.activePen

      case 'live-paint':
        return state.livePaintDraw.activePen
    }
  })

  const isErasing = useSelector((state: StoreState) => {
    switch (actionKind) {
      case 'paint-bcast-stream':
        return state.paintBcastStreamDraw.isErasing

      case 'live-paint':
        return state.livePaintDraw.isErasing
    }
  })

  // - Refs

  const selectPenTooltipRef = useRef<HTMLSpanElement>(null)

  const penTooltipRef = useRef<HTMLSpanElement>(null)

  const markerTooltipRef = useRef<HTMLSpanElement>(null)

  const higlighterTooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Handlers

  function onClickCurrentPen() {
    isErasing ? onClickSelectPen(selectedPen) : onClickShowPenSelector()
  }

  // ~~~~~~ Effects

  // - Fix tooltip position

  useEffect(() => {
    // Select

    if (selectPenTooltipRef.current) {
      selectPenTooltipRef.current.style.left =
        UtilsTooltip.getTooltipStyle(selectPenTooltipRef).left
    }

    // Pen

    if (penTooltipRef.current) {
      penTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(penTooltipRef).left
    }

    // Marker

    if (markerTooltipRef.current) {
      markerTooltipRef.current.style.left = UtilsTooltip.getTooltipStyle(markerTooltipRef).left
    }

    // Higlighter

    if (higlighterTooltipRef.current) {
      higlighterTooltipRef.current.style.left =
        UtilsTooltip.getTooltipStyle(higlighterTooltipRef).left
    }
  })

  // ~~~~~~ Render

  // Collapsed: Current Pen

  if (!showAll) {
    return (
      <STPaintToolbarButtonsOnlyPens>
        <motion.div
          className="first pen-btn last with-tooltip"
          style={{ position: 'relative' }}
          variants={variants}
        >
          {/* Icon */}

          {selectedPen === 'pen' ? (
            <PaintToolbarButtonsPen actionKind={actionKind} onClick={onClickCurrentPen} />
          ) : selectedPen === 'marker' ? (
            <PaintToolbarButtonsMarker actionKind={actionKind} onClick={onClickCurrentPen} />
          ) : selectedPen === 'highlighter' ? (
            <PaintToolbarButtonsHiglighther actionKind={actionKind} onClick={onClickCurrentPen} />
          ) : undefined}

          {/* Tooltip */}

          <span
            ref={selectPenTooltipRef}
            className="tooltiptext"
            style={UtilsTooltip.getTooltipStyle(selectPenTooltipRef)}
          >
            <Trans id="tooltip.EditPic.SelectPen" />
          </span>
        </motion.div>
      </STPaintToolbarButtonsOnlyPens>
    )
  }

  // Pen selector

  return (
    <STPaintToolbarButtonsOnlyPens>
      {/* Pen */}

      <motion.div
        className="first pen-btn with-tooltip"
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsPen actionKind={actionKind} onClick={() => onClickSelectPen('pen')} />

        {/* Tooltip */}

        <span
          ref={penTooltipRef}
          className="tooltiptext"
          style={UtilsTooltip.getTooltipStyle(penTooltipRef)}
        >
          <Trans id="tooltip.EditPic.Pen" />
        </span>
      </motion.div>

      {/* Marker */}

      <motion.div
        className="pen-btn with-tooltip"
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsMarker
          actionKind={actionKind}
          onClick={() => onClickSelectPen('marker')}
        />

        {/* Tooltip */}

        <span
          ref={markerTooltipRef}
          className="tooltiptext"
          style={UtilsTooltip.getTooltipStyle(markerTooltipRef)}
        >
          <Trans id="tooltip.EditPic.Marker" />
        </span>
      </motion.div>

      {/* Highlighter */}

      <motion.div
        className="pen-btn last with-tooltip"
        style={{ position: 'relative' }}
        variants={variants}
      >
        {/* Icon */}

        <PaintToolbarButtonsHiglighther
          actionKind={actionKind}
          onClick={() => onClickSelectPen('highlighter')}
        />

        {/* Tooltip */}

        <span
          ref={higlighterTooltipRef}
          className="tooltiptext"
          style={UtilsTooltip.getTooltipStyle(higlighterTooltipRef)}
        >
          <Trans id="tooltip.EditPic.Highlighter" />
        </span>
      </motion.div>
    </STPaintToolbarButtonsOnlyPens>
  )
}
