import styled from 'styled-components'
import { MediaQuery } from '../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'paint-toolbar'

// ~~~~~~ Component

export const STPaintToolbar = styled.div`
  .${ClassName}--animated-btn {
    margin-right: 24px;
  }

  /* Portrait mobile: SM */

  @media screen and (orientation: portrait) and (max-width: ${MediaQuery.SM}) {
    width: 100%;

    .${ClassName}--all-tools {
      /* Override the width generated by the animation */

      width: 100% !important;

      justify-content: space-evenly;

      .${ClassName}--animated-btn {
        margin-right: 0;

        &.separator:not(.exit) {
          display: none;
        }
      }
    }

    .${ClassName}--only-colors, .${ClassName}--only-pens {
      margin-left: 0;

      width: 100% !important;

      justify-content: center;
    }
  }
`
