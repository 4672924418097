import styled from 'styled-components'
import { theme } from '../../../../style/utils/theme'
import { Colors } from '../../../../style/theme/colors'
import { Layout } from '../../../../style/theme/layout'

import ThemeLightImg from '../../../../imgs/png/theme/theme-light.png'
import ThemeDarkImg from '../../../../imgs/png/theme/theme-dark.png'
import ThemeAutoImg from '../../../../imgs/png/theme/theme-system.png'

import { Fonts } from '../../../../style/theme/fonts'
import { Misc } from '../../../../style/theme/misc'
import { MediaQuery } from '../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'user-settings-general'

// ~~~~~~ Component

export const STUserSettingsGeneral = styled.div`
  padding-right: 24px;

  .${ClassName}--theme {
    .${ClassName}--theme--title {
      display: flex;

      margin-bottom: ${Layout.Spacing[4]}px;

      .${ClassName}--theme--title--text {
        ${Fonts.Scale.Subtitle}
        ${Fonts.Weight[700]}

        color: ${theme(Colors.WOLF_100_05)};

        margin-right: ${Layout.Spacing[2]}px;
      }
    }

    .${ClassName}--theme--selector {
      display: flex;

      margin-bottom: ${Layout.Spacing[5]}px;

      &.running {
        .${ClassName}--theme--selector--light,
          .${ClassName}--theme--selector--dark,
          .${ClassName}--theme--selector--auto {
          /* - */

          .${ClassName}--theme--selector--img {
            cursor: not-allowed;
          }
        }
      }

      .${ClassName}--theme--selector--light,
        .${ClassName}--theme--selector--dark,
        .${ClassName}--theme--selector--auto {
        /* - */

        margin-right: ${Layout.Spacing[4]}px;
        width: 100%;

        &.selected {
          .${ClassName}--theme--selector--img {
            border-color: ${theme(Colors.EMMET_50_40)};
          }

          .${ClassName}--theme--selector--name {
            color: ${theme(Colors.EMMET_50_40)};
          }
        }

        .${ClassName}--theme--selector--img {
          cursor: pointer;

          margin-bottom: ${Layout.Spacing[1]}px;

          width: 148px;
          height: 111px;

          background-repeat: no-repeat;
          background-position: bottom;

          ${Layout.Border.Radius.B}
        }

        .${ClassName}--theme--selector--name {
          ${Fonts.Scale.Body}
          ${Fonts.Weight[700]}

          color: ${theme(Colors.WOLF_100_05)};
        }
      }

      .${ClassName}--theme--selector--light .${ClassName}--theme--selector--img {
        background-image: url(${ThemeLightImg});
        background-color: ${theme(Colors.WOLF_05_05)};
        border: solid 2px ${theme(Colors.WOLF_05_05)};
      }

      .${ClassName}--theme--selector--dark .${ClassName}--theme--selector--img {
        background-image: url(${ThemeDarkImg});
        background-color: ${theme(Colors.WOLF_100_100)};
        border: solid 2px ${theme(Colors.WOLF_05_05)};
      }

      .${ClassName}--theme--selector--auto .${ClassName}--theme--selector--img {
        background-image: url(${ThemeAutoImg});
        background-color: ${theme(Colors.WOLF_05_05)};
        border: solid 2px ${theme(Colors.WOLF_05_05)};
      }
    }
  }

  hr.${ClassName} {
    width: 100%;
    border: none;
    border-top: solid 1px ${theme(Colors.WOLF_10_80)};

    margin-bottom: ${Layout.Spacing[5]}px;
  }

  .${ClassName}--lang {
    .${ClassName}--lang--title {
      display: flex;

      margin-bottom: ${Layout.Spacing[4]}px;

      .${ClassName}--lang--title--text {
        ${Fonts.Scale.Subtitle}
        ${Fonts.Weight[700]}

        color: ${theme(Colors.WOLF_100_05)};

        margin-right: ${Layout.Spacing[2]}px;
      }
    }

    .${ClassName}--lang--subtitle {
      ${Fonts.Scale.Body}
      ${Fonts.Weight[500]}

      color: ${theme(Colors.WOLF_100_05)};

      margin-bottom: ${Layout.Spacing[2]}px;
    }

    .${ClassName}--lang--selector {
      .${ClassName}--lang--selector--action {
        cursor: pointer;

        border: solid 1px ${theme(Colors.WOLF_10_80)};

        padding: 12px 16px;

        ${Layout.Border.Radius.A}

        display: flex;
        align-items: center;
        justify-content: space-between;

        &.running {
          cursor: not-allowed;
        }

        .${ClassName}--lang--selector--action--label-icon {
          display: flex;
          align-items: center;

          .${ClassName}--lang--selector--action--label-icon--icon {
            display: flex;
            margin-right: 16px;

            svg {
              width: 20px;
              height: 20px;

              path {
                fill: ${theme(Colors.WOLF_100_05)};
              }
            }
          }

          .${ClassName}--lang--selector--action--label-icon--label {
            ${Fonts.Scale.Body}
            ${Fonts.Weight[400]}
          }
        }

        .${ClassName}--lang--selector--action--icon {
          display: flex;

          svg {
            width: 20px;
            height: 20px;

            path {
              fill: ${theme(Colors.WOLF_100_05)};
            }
          }
        }
      }
    }
  }

  .${ClassName}--lang--selector--dropdown {
    position: relative;

    .${ClassName}--lang--selector--dropdown--items {
      position: absolute;

      z-index: 2;

      ${Layout.Border.Radius.A}
      ${Misc.BoxShadow}

      margin-top: -46px;

      width: 100%;

      min-width: 232px;
      padding-bottom: 2px;

      background-color: ${theme(Colors.WOLF_00_100)};

      border: solid 1px ${theme(Colors.WOLF_10_80)};

      .${ClassName}--lang--selector--dropdown--items--item {
        cursor: pointer;

        padding: 12px 16px;

        display: flex;
        align-items: center;

        .${ClassName}--lang--selector--dropdown--items--item--icon {
          display: flex;
          margin-right: 16px;

          svg {
            width: 20px;
            height: 20px;

            path {
              fill: ${theme(Colors.WOLF_100_05)};
            }
          }
        }

        .${ClassName}--lang--selector--dropdown--items--item--label {
          ${Fonts.Scale.Body}
          ${Fonts.Weight[400]}
        }

        &.selected {
          color: ${theme(Colors.EMMET_50_40)};

          .${ClassName}--lang--selector--dropdown--items--item--icon {
            display: flex;
            margin-right: 16px;

            svg {
              width: 20px;
              height: 20px;

              path {
                fill: ${theme(Colors.EMMET_50_40)};
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--theme {
      .${ClassName}--theme--selector {
        .${ClassName}--theme--selector--light,
          .${ClassName}--theme--selector--dark,
          .${ClassName}--theme--selector--auto {
          .${ClassName}--theme--selector--img {
            width: 116px;
            height: 87px;
          }
        }
      }
    }
  }

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--theme {
      .${ClassName}--theme--selector {
        .${ClassName}--theme--selector--light,
          .${ClassName}--theme--selector--dark,
          .${ClassName}--theme--selector--auto {
          .${ClassName}--theme--selector--img {
          }
        }
      }
    }
  }
`
