import styled from 'styled-components'

import { MediaQuery } from '../../../../style/constants/media-queries'
import { Colors } from '../../../../style/theme/colors'
import { HexAlpha } from '../../../../style/theme/colors/alpha'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { getThemeValue } from '../../../../style/utils/theme'

// ~~~~~~ Types

type BoxType = 'ERROR' | 'WARNING' | 'INFO'

export type STProps = {
  $boxType: BoxType
  $alignCenter?: true
  $usingNewLines?: true
  $padding?: string
  $borderRadius?: number
  $iconColor?: ThemeColor
  $iconAlignSelf?: string
  $iconMarginTop?: string
  $fontScale?: keyof typeof Fonts.Scale
}

// ~~~~~~ Helpers

const getColor = (type: BoxType) => {
  switch (type) {
    case 'ERROR':
      return {
        color: {
          value: Colors.WOLF_95_05,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[100],
          },
        },
        bg: {
          value: Colors.BROOKLYN_05_50,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[15],
          },
        },
        border: {
          value: Colors.BROOKLYN_40_30,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[100],
          },
        },
        icon: {
          value: Colors.BROOKLYN_40_30,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[100],
          },
        },
      }

    case 'WARNING':
      return {
        color: {
          value: Colors.WOLF_100_05,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[100],
          },
        },
        bg: {
          value: Colors.LISA_05_50,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[15],
          },
        },
        border: {
          value: Colors.LISA_40_30,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[100],
          },
        },
        icon: {
          value: Colors.LISA_40_30,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[100],
          },
        },
      }

    case 'INFO':
      return {
        color: {
          value: Colors.WOLF_100_05,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[100],
          },
        },
        bg: {
          value: Colors.EMMET_05_50,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[15],
          },
        },
        border: {
          value: Colors.EMMET_40_30,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[100],
          },
        },
        icon: {
          value: Colors.EMMET_40_30,
          alpha: {
            light: HexAlpha[100],
            dark: HexAlpha[100],
          },
        },
      }
  }
}

const getThemeColor = (
  props: { $boxType: BoxType; theme?: { mode?: 'light' | 'dark' } },
  key: keyof ReturnType<typeof getColor>,
) => {
  const colorData = getColor(props.$boxType)[key]

  const alpha = colorData.alpha[props.theme?.mode || 'light']

  return `${getThemeValue(props, colorData.value)}${alpha}`
}

// ~~~~~~ Component

export const STInfoBox = styled.div<STProps>`
  ${({ $fontScale }) => ($fontScale ? Fonts.Scale[$fontScale] : Fonts.Scale.Body)}

  padding: ${({ $padding }) => $padding || `${Layout.Spacing[3]}px`};

  border: ${(props) => `solid 1px ${getThemeColor(props, 'border')}`};

  color: ${(props) => getThemeColor(props, 'color')};

  background-color: ${(props) => getThemeColor(props, 'bg')};

  text-align: ${({ $alignCenter }) => ($alignCenter ? 'center' : 'left')};

  display: flex;

  justify-content: ${({ $alignCenter }) => ($alignCenter ? 'center' : 'left')};
  align-items: center;

  border-radius: ${({ $borderRadius }) => ($borderRadius ? $borderRadius : 0)}px;

  .underlined {
    border-bottom: solid ${Layout.Border.Size.Number.A}px;
  }

  .has-action {
    cursor: pointer;
  }

  .info-box-icon-container {
    position: relative;
    margin-right: ${Layout.Spacing[2]}px;

    align-self: ${({ $iconAlignSelf }) => $iconAlignSelf || 'auto'};

    margin-top: ${({ $iconMarginTop }) => $iconMarginTop || 0};

    .info-box-icon {
      width: 16px;
      height: 16px;

      svg path {
        fill: ${(props) =>
          props.$iconColor ? getThemeValue(props, props.$iconColor) : getThemeColor(props, 'icon')};

        stroke: none;
      }
    }
  }

  @media (max-width: ${MediaQuery.SM}) {
    ${Fonts.Scale.Label}
    padding: ${Layout.Spacing[3]}px;
  }
`
