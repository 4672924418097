const prefix = 'mt-api-bcast-google-slides-create-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastGoogleSlidesCreatePresentationMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastGoogleSlidesCreatePresentationMC = {
  ok: (data: DN.Models.GoogleSlides.Api.Create.ResParsed) => ({
    type: EpicApiBcastGoogleSlidesCreatePresentationMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.GoogleSlides.Api.Create.ResError, unsync: boolean) => ({
    type: EpicApiBcastGoogleSlidesCreatePresentationMT.ERROR,
    error: true,
    payload: {
      unsync,
      errors,
    },
  }),
} as const
