const prefix = 'mt-bcast-comm-channel-messages-to-server-baster-mic-enable'

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesToServerBcasterMicEnableMT = {
  OK: `${prefix}-ok`,
  TIMEOUT: `${prefix}-timeout`,
} as const

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelMessagesToServerBcasterMicEnableMC = {
  ok: () => ({
    type: EpicBcastCommChannelMessagesToServerBcasterMicEnableMT.OK,
  }),

  timeout: () => ({
    type: EpicBcastCommChannelMessagesToServerBcasterMicEnableMT.TIMEOUT,
  }),
} as const
