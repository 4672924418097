import { Config } from '../../../../config'
import { UtilsLog } from '../../../../utils/logs'
import { DrawState } from '../../@state/draw-partial-service'

// ####################################################################################################
// ~~~~~~ Clear
// ####################################################################################################

export const clear = () => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'public-commands', 'clear')

  if (!DrawState.State.commandManager) return

  return DrawState.State.commandManager.doCommand('clear', undefined)
}
