import { AppInitState } from '../../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { EpicBcastStartPubCamMicMT } from '../../../../epics/bcast/start-pub-cam-mic/mutators'
import { EpicBcastStartPubMainMT } from '../../../../epics/bcast/start-pub-main/mutators'
import { EpicCancelNowCamMicMT } from '../../../../epics/cancel-now/cam-mic/mutators'

const initState = AppInitState.networkCongestionPub

type State = typeof initState

const reducer: any = {}

// ---------------------------------------------------------------------------------------------------
// ------ Screen events
// ---------------------------------------------------------------------------------------------------

// ####################################################################################################
// ~~~~~~ Insufficient
// ####################################################################################################

reducer[EpicBcastStartPubMainMT.BANDWITH_STATE_INSUFFICIENT] = (state: State): State => {
  return {
    ...state,
    screenStreamCongestion: 'insuficient',

    networkIsOk: false,
  }
}

// ####################################################################################################
// ~~~~~~ Recovering
// ####################################################################################################

reducer[EpicBcastStartPubMainMT.BANDWITH_STATE_RECOVERING] = (state: State): State => {
  return {
    ...state,
    screenStreamCongestion: 'recovering',

    networkIsOk: state.camMicStreamCongestion !== 'insuficient',
  }
}

// ####################################################################################################
// ~~~~~~ Sufficient
// ####################################################################################################

reducer[EpicBcastStartPubMainMT.BANDWITH_STATE_SUFFICIENT] = (state: State): State => {
  return {
    ...state,
    screenStreamCongestion: 'sufficient',

    networkIsOk: state.camMicStreamCongestion !== 'insuficient',
  }
}

// ---------------------------------------------------------------------------------------------------
// ------ Cam-Mic events
// ---------------------------------------------------------------------------------------------------

// ####################################################################################################
// ~~~~~~ Insufficient
// ####################################################################################################

reducer[EpicBcastStartPubCamMicMT.BANDWITH_STATE_INSUFFICIENT] = (state: State): State => {
  return {
    ...state,
    camMicStreamCongestion: 'insuficient',

    networkIsOk: false,
  }
}

// ####################################################################################################
// ~~~~~~ Recovering
// ####################################################################################################

reducer[EpicBcastStartPubCamMicMT.BANDWITH_STATE_RECOVERING] = (state: State): State => {
  return {
    ...state,
    camMicStreamCongestion: 'recovering',

    networkIsOk: state.screenStreamCongestion !== 'insuficient',
  }
}

// ####################################################################################################
// ~~~~~~ Sufficient
// ####################################################################################################

reducer[EpicBcastStartPubCamMicMT.BANDWITH_STATE_SUFFICIENT] = (state: State): State => {
  return {
    ...state,
    camMicStreamCongestion: 'sufficient',

    networkIsOk: state.screenStreamCongestion !== 'insuficient',
  }
}

// ---------------------------------------------------------------------------------------------------
// ------ Cancels
// ---------------------------------------------------------------------------------------------------

// ####################################################################################################
// ~~~~~~ Cancel cam-mic
// ####################################################################################################

reducer[EpicCancelNowCamMicMT.DONE] = (state: State): State => {
  return {
    ...state,
    camMicStreamCongestion: initState.camMicStreamCongestion,

    networkIsOk: state.screenStreamCongestion !== 'insuficient',
  }
}

// ####################################################################################################
// ~~~~~~ Cancel All
// ####################################################################################################

reducer[EpicCancelNowCamMicMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~~

export const NetworkCongestionPubReducer = UtilsStore.dynReducer(initState, reducer)
