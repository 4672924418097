import { catchError, map, Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { UserSettingsModel } from '../../../models/bcast/user-settings/model'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

const { Show } = ApiBcastRoutes.UserSettings

// ####################################################################################################
// ~~~~~~ User settings show
// ####################################################################################################

type Res = DN.Models.UserSettings.Api.Show.Res
type ResParsed = DNApiResParsed<DN.Models.UserSettings.Api.Show.ResParsed>
type ResError = DNApiError<DN.Models.UserSettings.Api.Show.ResError>

export const apiBcastUserSettingsShow$ = (
  token: string,
  userId: string,
  fromBackToFrontSettingsOpts: DN.Models.UserSettings.FromBackToFrontOpts,
  headers?: AppHeaders,
) => {
  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersWithAuth(token, headers),
    method: Show.Method,
    url: Show.getUrl(userId),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: UserSettingsModel.fromBackToFront(
          res.response.user_settings,
          fromBackToFrontSettingsOpts,
        ),
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.user-settings.show.Error' }],
      })
    }),
  )
}
