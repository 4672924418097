import React from 'react'
import { useViewerMobileContext } from '../../hooks/use-viewer-mobile-context'

// ~~~~~~ Component

export const LayoutViewerMobileToolbar: React.FC<any> = ({ children }) => {
  const { kind } = useViewerMobileContext()

  return <>{kind === 'toolbar' ? children : null}</>
}
