import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'started-content-main-video-badge'

// ~~~~~~ Component

export const STStartedContentMainVideoBadge = styled.div`
  ${Fonts.Scale.Body}
  ${Fonts.Weight[700]}

  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 6; // 5 is mirror-effect-layer

  padding: ${Layout.Spacing[1]}px ${Layout.Spacing[2]}px;

  color: ${theme(Colors.BROOKLYN_50_40)};

  background-color: ${theme(Colors.WOLF_00_95)};

  display: flex;
  align-items: center;

  svg.${ClassName}--icon {
    margin-right: ${Layout.Spacing[1]}px;

    width: 12px;
    height: 12px;

    path {
      fill: none;
      stroke: ${theme(Colors.BROOKLYN_50_40)};
    }
  }
`
