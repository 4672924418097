import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { filter, interval, map } from 'rxjs'
import { Trans } from '../../../../../../../../components/common/intl/trans'
import { ModalsIds } from '../../../../../../../../constants/modals'
import { StoreState } from '../../../../../../../../models/app/model'
import { UiExtensionMenusFieldsMR } from '../../../../../../../../models/bcast/ui-extension-menus/model'
import { UiOnboardingFieldsMR } from '../../../../../../../../models/bcast/ui-onboarding/model'
import { ExtensionService } from '../../../../../../../../services/extension'
import { ModalsMC } from '../../../../../../../../store/actions-mutators/modals/mutators'
import { ShareMainStreamAC } from '../../../../../../../../store/actions/share-main-stream/actions'
import { UtilsCompare } from '@dn/utils'
import { StartedContentMainToolbarsButtonsChangeSourceMenu } from './menu-extension-source-selection-animated/component'
import { STStartedContentMainToolbarsMainButtonsChangeSource } from './style'
import { BrowserIsSafari, BrowserVersionBase } from '../../../../../../../../constants/misc'

// ~~~~~~ Component

export const StartedContentMainToolbarsMainButtonsChangeSource = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const showChangeSource = useSelector(
    (state: StoreState) => state.uiExtensionMenus.showChangeSource,
  )

  const uiBounds = useSelector(
    (state: StoreState) => state.uiOnboarding.onboardingChangeSourceBounds,
  )

  const userSettings = useSelector((state: StoreState) => state.userSettings)

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Computed

  const self = selfRef.current

  const shouldCalculateBounds =
    userSettings.isOnboardingRunning &&
    !userSettings.onboarding_is_completed &&
    userSettings.onboarding_create_a_bcast &&
    !userSettings.onboarding_change_source

  // ~~~~~~ Handlers

  function setShowMenu(showMenu: boolean) {
    dispatch(UiExtensionMenusFieldsMR.showChangeSource.MC.change(showMenu))
  }

  function onClickButton(evt: React.MouseEvent<any> | React.TouchEvent<any>) {
    evt.stopPropagation()

    // Since Safari 17, users can select the screen to share
    // or an app to share

    if (BrowserIsSafari && BrowserVersionBase < 17) {
      dispatch(ModalsMC.open(ModalsIds.SafariChangeSource))
      return
    }

    // Show menu or get stream

    ExtensionService.SendMessage.ToExtension.checkExtension$().subscribe({
      next: () => {
        setShowMenu(!showChangeSource)
      },
      error: () => {
        dispatch(ShareMainStreamAC.getStreamToSwitch())
      },
    })
  }

  function onClickMenuItem(kind: CaptureStreamKind) {
    setShowMenu(false)

    // Call extension to get source of kind or getStream (if removed/disabled after show the menu)

    ExtensionService.SendMessage.ToExtension.checkExtension$().subscribe({
      next: () => {
        dispatch(ShareMainStreamAC.getStreamByExtensionToSwitch(kind))
      },
      error: () => {
        dispatch(ShareMainStreamAC.getStreamToSwitch())
      },
    })
  }

  // - Observe self bounds to update uiBounds

  useEffect(() => {
    if (!shouldCalculateBounds) return

    if (!self) {
      setUpdate(performance.now())
      return
    }

    const sub = interval(50)
      .pipe(
        map(() => self.getBoundingClientRect().toJSON()),

        filter((bounds) => !UtilsCompare.allValuesAre(bounds, 0)),

        filter((bounds) => !UtilsCompare.containsSameValuesAtFirstLevel(bounds, uiBounds)),
      )
      .subscribe({
        next: (bounds) => {
          dispatch(UiOnboardingFieldsMR.onboardingChangeSourceBounds.MC.change(bounds))
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [dispatch, self, shouldCalculateBounds, uiBounds])

  // ~~~~~~ Render

  return (
    <>
      {/* Menu (only if extension is installed) */}

      <StartedContentMainToolbarsButtonsChangeSourceMenu
        show={showChangeSource}
        changeSourceBtn={selfRef.current}
        onClickOut={() => setShowMenu(false)}
        onClickMenuItem={onClickMenuItem}
      />

      {/* Button */}

      <STStartedContentMainToolbarsMainButtonsChangeSource ref={selfRef} onClick={onClickButton}>
        <Trans id="pages.started.main.toolbars.main.ChangeSource" />
      </STStartedContentMainToolbarsMainButtonsChangeSource>
    </>
  )
}
