import { STTables } from '../../style'

export type TableCookieKindB = {
  name: string
  provider: string
  purpose: string
}

type Props = {
  cookies: TableCookieKindB[]
}

export const TablesCookiesKindB: React.FC<Props> = ({ cookies }) => {
  return (
    <STTables>
      <thead>
        <tr>
          <th>Name of Cookie</th>
          <th>Provider</th>
          <th>Purpose</th>
        </tr>
      </thead>

      {cookies.map(({ name, provider, purpose }, idx) => (
        <tbody key={idx}>
          <tr>
            <td>{name}</td>
            <td>{provider}</td>
            <td>{purpose}</td>
          </tr>
        </tbody>
      ))}
    </STTables>
  )
}
