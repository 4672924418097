import styled from 'styled-components'

import { Theme } from '../../../../style/theme'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { getThemeValue, theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

const { Misc } = Theme

// ~~~~~~ Props

type STProps = {
  $stackedBtns: boolean
  $iconColor?: ThemeColor
  $titleAlign?: 'left' | 'center' | 'right'
  $removeContentTitleMarginBottom?: true
  $disableHorizontalScroll?: true
}

// ~~~~~~ Component

export const STDialog = styled.div<STProps>`
  position: relative;

  background-color: ${theme(Colors.WOLF_00_100)};
  ${Layout.Border.Radius.B}

  ${Misc.BoxShadow}

  .dialog-header {
    .title {
      user-select: none;
      cursor: default;

      display: flex;
      justify-content: space-between;

      .text {
        padding: ${Layout.Spacing[3]}px ${Layout.Spacing[3]}px ${Layout.Spacing[2]}px
          ${Layout.Spacing[3]}px;

        overflow: hidden;
        text-overflow: ellipsis;
      }

      ${Fonts.Scale.Title}
      ${Fonts.Weight[700]}

      .btns-holder {
        cursor: pointer;
        min-height: 44px;
        top: 0;
        right: 0;

        &.abs {
          position: absolute;
        }

        .btn-close,
        .btn-reload {
          height: 100%;
          padding-left: ${Layout.Spacing[3]}px;

          svg {
            margin-top: ${Layout.Spacing[4]}px;
            margin-right: ${Layout.Spacing[3]}px;

            width: 24px;
            height: 24px;

            path {
              transition: fill 200ms ease;
              fill: ${theme(Colors.WOLF_50_40)};
            }
          }

          &:hover {
            svg path {
              fill: ${theme(Colors.WOLF_40_90)};
            }
          }

          &:active {
            svg path {
              fill: ${theme(Colors.WOLF_50_40)};
            }
          }

          .esc {
            ${Fonts.Scale.Helper}

            line-height: 0;

            color: ${theme(Colors.WOLF_40_90)};
            user-select: none;
            width: 100%;
          }
        }
      }
    }
  }

  .dialog-scroller > div {
    overflow-x: hidden
      ${({ $disableHorizontalScroll }) => ($disableHorizontalScroll ? '!important' : '')};
  }

  .inside-scroll {
    cursor: default;

    ${({ $stackedBtns }) => ($stackedBtns ? Fonts.Scale.Body : '')};
    ${({ $stackedBtns }) => ($stackedBtns ? 'text-align: center' : '')};

    padding-left: ${Layout.Spacing[3]}px;
    padding-right: ${Layout.Spacing[3]}px;

    color: ${theme(Colors.WOLF_80_20)};
  }

  .dialog-content-actions {
    padding-bottom: ${({ $stackedBtns }) =>
      $stackedBtns ? Layout.Spacing[2] : Layout.Spacing[3]}px;

    .dialog-content {
      padding-top: 0;
      margin-bottom: ${({ $stackedBtns }) => ($stackedBtns ? Layout.Spacing[2] : 0)}px;

      display: flex;
      flex-direction: column;

      .dialog-content-title {
        cursor: default;
        /* position: relative; */

        color: ${theme(Colors.WOLF_100_05)};

        ${Fonts.Scale.Subtitle};
        ${Fonts.Weight[700]};

        padding-top: ${Layout.Spacing[3]}px;
        padding-left: ${Layout.Spacing[3]}px;
        padding-right: ${Layout.Spacing[3]}px;

        text-align: ${({ $titleAlign }) => ($titleAlign ? $titleAlign : 'center')};

        margin-bottom: ${({ $removeContentTitleMarginBottom }) =>
          $removeContentTitleMarginBottom ? 0 : Layout.Spacing[2]}px;

        &.with-stacked-btn-close {
          margin-bottom: 0;
          min-height: 34px;
        }

        .dialog-stacked-btn-close,
        .dialog-stacked-btn-back {
          cursor: pointer;

          position: absolute;
          z-index: 1;

          top: 0;
          right: 0;

          width: 16px;
          height: 16px;

          padding: 23px;

          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 16px;
            height: 16px;

            path {
              transition: fill 200ms ease;
              fill: ${theme(Colors.WOLF_50_40)};
            }
          }

          &:hover {
            svg path {
              fill: ${theme(Colors.WOLF_40_90)};
            }
          }
        }

        .dialog-stacked-btn-back {
          left: 0;
          padding-bottom: 8px;
        }

        .subtext {
          ${Fonts.Scale.Body};
          ${Fonts.Weight[500]};

          color: ${theme(Colors.WOLF_80_20)};

          text-align: left;

          ${Fonts.Scale.Label};

          margin-bottom: ${Layout.Spacing[2]}px;
        }

        .dialog-icon {
          width: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          margin-bottom: ${Layout.Spacing[3]}px;

          svg {
            width: 40px;
            height: 40px;

            path {
              fill: ${(props) =>
                props.$iconColor ? getThemeValue(props, props.$iconColor) : 'none'};
            }
          }
        }
      }
    }

    .dialog-actions {
      margin-top: ${({ $stackedBtns }) => ($stackedBtns ? 8 : 16)}px;
      padding-top: ${({ $stackedBtns }) => ($stackedBtns ? 0 : 8)}px;

      padding-left: ${({ $stackedBtns }) => ($stackedBtns ? 16 : 24)}px;
      padding-right: ${({ $stackedBtns }) => ($stackedBtns ? 16 : 24)}px;

      display: flex;
      flex-direction: ${({ $stackedBtns }) => ($stackedBtns ? 'column-reverse' : 'row')};

      align-items: center;
      justify-content: flex-end;

      & > div {
        width: ${({ $stackedBtns }) => ($stackedBtns ? '100%' : 'initial')};
        margin-bottom: ${({ $stackedBtns }) => ($stackedBtns ? '8px' : 0)};
      }

      & > *:not(.skip-margin-left) {
        margin-left: ${({ $stackedBtns }) => ($stackedBtns ? 0 : 20)}px;
      }
    }
  }
`
