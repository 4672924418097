import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'

export const STPeerStats = styled.div`
  position: absolute;
  z-index: 3;

  padding: 5px;

  color: ${theme(Colors.WOLF_100_05)};
  background-color: ${theme(Colors.WOLF_00_100)};

  ${Fonts.Scale.Body}

  top: 0;
  left: 0;

  .main {
    width: 600px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 12px;
    padding-right: 8px;

    .actions {
      display: flex;
    }
  }
`
