import React from 'react'
import { useViewerMobileContext } from '../../hooks/use-viewer-mobile-context'

// ~~~~~~ Component

export const LayoutViewerMobileSound: React.FC<any> = ({ children }) => {
  const { kind } = useViewerMobileContext()

  return <>{kind === 'sound' ? children : null}</>
}
