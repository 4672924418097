import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ModalsIds } from '../../../../../constants/modals'
import { StoreState } from '../../../../../models/app/model'
import { genAnimatedDialog } from '../../../compound-dialog/dialog/component'
import { DialogConfirmation } from '../../component'

// ~~~~~~ Component

const ModalId = ModalsIds.ConfirmPredefMsgWillCloseBecauseNoViewers

const Dialog = genAnimatedDialog(ModalId)

// ~~~~~~ Component

export const DialogPredefMsgConfirmCloseNoViewers = () => {
  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const values = useSelector((state: StoreState) => state.modals.values[ModalId])

  // ~~~~~~ Handlers

  function onClickOk() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      Dialog={Dialog}
      predefIcon="info"
      title="dialogs.confirm.predef-msg.close-no-viewers.Title"
      contentText="dialogs.confirm.predef-msg.close-no-viewers.Content"
      contentTextValues={values}
      okButtonIntl="common.OK"
      isRunning={false}
      doClose={doClose}
      onEndDisappear={() => undefined}
      onClickClose={() => undefined}
      onClickConfirm={onClickOk}
      hideCloseButton={true}
    />
  )
}
