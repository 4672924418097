import { filter, map, Observable } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'
import { StoreState } from '../../../../../models/app/model'
import {
  EpicApiServerSubMainMC,
  EpicApiServerSubMainMT,
} from '../../../api-stream-manager/server/sub-main/mutators'
import { ApiBcastStreamContextAC } from '../../../../actions/api-bcast/stream-context/actions'
import { Config } from '../../../../../config'

type Action = ReturnType<typeof EpicApiServerSubMainMC.ok>

export const deprecatedChainingSubMainFromGetServerSubMainToGetStreamContextEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiServerSubMainMT.OK),

    filter(
      () =>
        !Config.Features.BcastService2 ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v1'),
    ),

    map(({ payload }) => ApiBcastStreamContextAC.show(payload)),
  )
