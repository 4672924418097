const prefix = 'mt-api-bcast-google-class-room-announce-capture-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastGoogleClassRoomAnnounceCaptureMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastGoogleClassRoomAnnounceCaptureMC = {
  ok: (course: { id: string; name: string }) => ({
    type: EpicApiBcastGoogleClassRoomAnnounceCaptureMT.OK,
    payload: {
      course,
    },
  }),

  error: (errors: DN.Models.GoogleClassRoom.Api.Announce.ResError, unsync: boolean) => ({
    type: EpicApiBcastGoogleClassRoomAnnounceCaptureMT.ERROR,
    error: true,
    payload: {
      unsync,
      errors,
    },
  }),
} as const
