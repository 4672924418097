const prefix = 'mt-resync-google-epic'

// ~~~~~~ Mutators Types

export const EpicResyncGoogleMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicResyncGoogleMC = {
  ok: (data: DN.Models.Provider.Api.GetGoogle.ResParsed) => ({
    type: EpicResyncGoogleMT.OK,
    payload: data,
  }),
} as const
