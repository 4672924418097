import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { DrawService } from '../../../../../services/draw'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../actions/live-paint/draw/actions'

type Action = ReturnType<typeof LivePaintDrawAC.updateView>

export const livePaintDrawUpdateViewEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.UPDATE_VIEW),
    mergeMap(({ payload }) => {
      const scope = state$.value.livePaintDraw.scope

      if (!scope) return EMPTY

      DrawService.updateView(scope, {
        width: payload.width,
        height: payload.height,
      })

      return EMPTY
    }),
  )
