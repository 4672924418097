import { ofType, StateObservable } from 'redux-observable'
import { map, mergeMap, Observable, of, take, interval, filter, switchMap, takeUntil } from 'rxjs'
import { Config } from '../../../../config'
import { StoreState } from '../../../../models/app/model'
import { CommChannelService } from '../../../../services/comm-channel'
import { BroadcastAC, BroadcastAT } from '../../../actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import { EpicBcastCommChannelInitMC } from './mutators'
import { cancelAll$$ } from '../../../../subjects/cancel-all/subject'

type Action = ReturnType<typeof BroadcastAC.commChannelInit>

export const bcastCommChannelInitEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(BroadcastAT.COMM_CHANNEL_INIT),

    mergeMap(({ payload }) => {
      let finalChannelData = payload.channelData

      if (payload.channelData.kind === 'viewer') {
        finalChannelData = {
          ...payload.channelData,
          username: `${Config.App.red5proUsernamePrefix}-${payload.channelData.username}`,
        }
      }

      return CommChannelService.init$(Config.Api.Bcast.WS.URI, finalChannelData).pipe(
        takeUntil(cancelAll$$),

        map((data) => {
          return data instanceof WebSocket
            ? ({
                type: 'websocket',
                payload: {
                  webSocket: data,
                },
              } as const)
            : data
        }),
      )
    }),

    mergeMap((data) => {
      if (data instanceof WebSocket) {
        return of(EpicBcastCommChannelInitMC.ok(data))
      }

      if (data.type === 'websocket') {
        const action = EpicBcastCommChannelInitMC.ok(data.payload.webSocket)

        return of(action)
      }

      switch (data.type) {
        case 'ws-open':
          return of(EpicBcastCommChannelInitMC.wsOpen())

        case 'ws-close':
          return of(
            EpicBcastCommChannelInitMC.wsClose(),
            BroadcastAC.exit('conn-closed'),
            CancelNowAC.cancelAll([CancelReasons.ConnectionClosed]),
          )

        case 'ws-error':
          return interval(500).pipe(
            filter(() => {
              return (
                state$.value.streamManagerPubMain.isReady ||
                state$.value.streamManagerSubMain.isReady
              )
            }),
            take(1),
            switchMap(() =>
              of(
                EpicBcastCommChannelInitMC.wsError(),
                BroadcastAC.exit('comm-channel-err'),
                CancelNowAC.cancelAll([CancelReasons.CommChannelErr]),
              ),
            ),
          )

        case 'ws-ping-timeout':
          return of(
            EpicBcastCommChannelInitMC.wsPingTimeout(),
            BroadcastAC.exit('conn-closed'),
            CancelNowAC.cancelAll([CancelReasons.ConnectionClosed]),
          )

        case 'ws-message':
          return of(EpicBcastCommChannelInitMC.wsMessage(data.payload.data))
      }
    }),
  )
