import { Config } from '../../../../../config'
import { UtilsLog } from '../../../../../utils/logs'
import { UtilsString } from '@dn/utils'

// ~~~~~~ Constants

export const ClearProp = '___CLEAR___'

// ~~~~~~ Command

export const clearCommand = (drawingsTarget: DN.Services.Draw.DrawingsTarget) => {
  //

  const clearId = UtilsString.genRandomStr()

  return {
    name: 'clear',

    execute() {
      Config.Logs.DrawService &&
        UtilsLog.devLog('\nDrawService', 'commands', 'clearCommand', 'execute', clearId)

      drawingsTarget.cmdApplied += 1

      const layer = drawingsTarget.scope.project.getItems({})[0]

      if (!layer) return

      const layerItems = layer.getItems({}).slice(1) // First element is Roster

      layerItems.forEach((item) => {
        const anyItem = item as any

        if (!item.visible || anyItem[ClearProp]) return

        anyItem.setVisible(false)

        anyItem[ClearProp] = clearId
      })
    },

    undo() {
      Config.Logs.DrawService &&
        UtilsLog.devLog('\nDrawService', 'commands', 'clearCommand', 'undo', clearId)

      drawingsTarget.cmdApplied -= 1

      const layer = drawingsTarget.scope.project.getItems({})[0]

      if (!layer) return

      const layerItems = layer.getItems({}).slice(1) // First element is Roster

      layerItems.forEach((item) => {
        const anyItem = item as any

        if (anyItem[ClearProp] !== clearId) return

        anyItem.setVisible(true)

        anyItem[ClearProp] = undefined
      })
    },
  }
}
