import { useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../../../../../../models/app/model'
import { ImageToShareFieldsMR } from '../../../../../../../../../models/bcast/image-to-share/model'
import { PaintBcastStreamPicModelFieldsMR } from '../../../../../../../../../models/bcast/paint-bcast-stream/model'
import { UiLayoutPanelsFieldsMR } from '../../../../../../../../../models/bcast/ui-layout-panels/model'
import { UiLayoutViewerMobileFieldsMR } from '../../../../../../../../../models/bcast/ui-layout-viewer-mobile/model'
import { UtilsDates } from '@dn/utils'

// ~~~~~~ Hook

export const useJoinedBehaviourLogicContentMainToolbarsMainButtonsCaptureShare = (
  video: HTMLVideoElement | null,
) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  const { googleAccessToken } = useSelector((state: StoreState) => state.currentUser)

  // - Store

  const { originalVideoW, originalVideoH } = useSelector(
    (state: StoreState) => state.paintBcastStreamVideo,
  )

  const reqUpdate = useSelector((state: StoreState) => state.imageToShare.reqUpdate)

  const intlLang = useSelector((state: StoreState) => state.intl.lang)

  // - Refs

  const tooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Computed

  const tooltipTop = tooltipRef.current ? -tooltipRef.current.clientHeight - 8 : -34

  // ~~~~~~ Callbacks

  const takeScreenshot = useCallback(() => {
    if (!video) return

    const canvas = document.createElement('canvas')

    canvas.width = originalVideoW
    canvas.height = originalVideoH

    const context2d = canvas.getContext('2d')

    if (!context2d) return

    context2d.imageSmoothingEnabled = false

    context2d.drawImage(video, 0, 0, originalVideoW, originalVideoH)

    dispatch(PaintBcastStreamPicModelFieldsMR.canvas.MC.change(canvas))

    return canvas

    //
  }, [dispatch, originalVideoH, originalVideoW, video])

  const onClickCaptureShare = useCallback(() => {
    // Take screenshot

    const screenShotCanvas = takeScreenshot()

    if (!screenShotCanvas) return

    const file = screenShotCanvas.toDataURL('image/png')

    const name = `Broadcast ${UtilsDates.getTitleTimestamp(intlLang)}`

    dispatch(ImageToShareFieldsMR.__model__.MC.setModel({ file, name, reqUpdate: false }))

    // GSlides slider open state

    if (!googleAccessToken) {
      dispatch(UiLayoutPanelsFieldsMR.shareImageToSlidesState.MC.change('closed'))
    }

    // Desktop/Tablet

    // - Open share paint panel

    dispatch(UiLayoutPanelsFieldsMR.leftPanelShowSharePaints.MC.change(true))

    dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('open'))

    // Mobile

    // - Open float panel

    dispatch(UiLayoutViewerMobileFieldsMR.floatPanelOpenState.MC.change('open'))

    //
  }, [dispatch, googleAccessToken, intlLang, takeScreenshot])

  // ~~~~~~ Effects

  // - Force update on mount to calculate tooltip position

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // - Refresh image on demand

  useEffect(() => {
    if (!reqUpdate) return

    onClickCaptureShare()

    //
  }, [onClickCaptureShare, reqUpdate])

  // ~~~~~~

  return {
    tooltipRef,
    tooltipTop,

    onClickCaptureShare,
  } as const
}
