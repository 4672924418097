import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, tap } from 'rxjs'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { apiBcastGoogleSlidesCreatePresentation$ } from '../../../../../services/api-bcast/google-slides/create-presentation'
import { UtilsLog } from '../../../../../utils/logs'
import {
  ApiBcastGoogleSlidesAC,
  ApiBcastGoogleSlidesAT,
} from '../../../../actions/api-bcast/google-slides/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastGoogleSlidesCreatePresentationMC } from './mutators'

type Action = ReturnType<typeof ApiBcastGoogleSlidesAC.createPresentation>

export const apiBcastGoogleSlidesCreatePresentationEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastGoogleSlidesAT.CREATE_PRESENTATION),

    // Api call

    mergeMap(({ payload }) =>
      apiBcastGoogleSlidesCreatePresentation$(
        payload.withGoogleAccessToken.withIt
          ? payload.withGoogleAccessToken
          : {
              ...payload.withGoogleAccessToken,
              token: state$.value.currentUser.token,
            },
        payload.data,
      ),
    ),

    // Extension

    tap((res) => {
      if (
        !Config.isExtension ||
        ApiUtils.isSuccess(res) ||
        !window.chrome ||
        !window.chrome.identity
      ) {
        return
      }

      window.chrome.identity.clearAllCachedAuthTokens(() =>
        UtilsLog.devLog('clear all cached auth tokens'),
      )
    }),

    // Mutator

    map((res) => {
      return ApiUtils.isSuccess(res)
        ? EpicApiBcastGoogleSlidesCreatePresentationMC.ok(res.response)
        : EpicApiBcastGoogleSlidesCreatePresentationMC.error(
            res.response,
            /* unsync */ res.status === 412,
          )
    }),
  )
