import { useSelector } from 'react-redux'
import { DialogConfirmBroadcasterDisconnectedByFail } from '../../../../components/dialogs/confirmation/broadcaster-disconnected-by-fail/component'
import { DialogGetCamMicError } from '../../../../components/dialogs/confirmation/get-cam-mic-error/component'
import { DialogPredefMsgConfirmCloseNoViewers } from '../../../../components/dialogs/confirmation/predef-msg/will-close-because-no-viewers/component'
import { DialogPredefMsgConfirmClosedByNoViewers } from '../../../../components/dialogs/confirmation/predef-msg/close-now-no-viewers/component'
import { DialogConfirmStopBroadcasting } from '../../../../components/dialogs/confirmation/stop-broadcasting/component'
import { DialogConfirmStopCamMicStream } from '../../../../components/dialogs/confirmation/stop-cam-mic-stream/component'
import { DialogConfirmYourBrowswerIsTooOld } from '../../../../components/dialogs/confirmation/your-brwoser-is-too-old/component'
import { DialogGoogleSignIn } from '../../../../components/dialogs/google/sign-in/component'
import { DialogSafariChangeSource } from '../../../../components/dialogs/safari-change-source/component'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { DialogLinkGoogleInBcast } from '../../../../components/dialogs/link-google-in-bcast/component'

// ~~~~~~ Component

export const StartedDialogs = () => {
  // ~~~~~~ State

  const modals = useSelector((state: StoreState) => state.modals.list)

  // ~~~~~~ Computed

  let showConfirmStopBroadcasting: true | undefined = undefined

  let showConfirmBroadcasterDisconectedByFail: true | undefined = undefined

  let showSafariChangeSource: true | undefined = undefined

  let showGoogleSignIn: true | undefined = undefined

  let showConfirmStopCamMicStream: true | undefined = undefined

  let showYourBrowswerIsTooOld: true | undefined = undefined

  let showConfirmGetCamMicError: true | undefined = undefined

  let showConfirmPredefMsgWillCloseBecauseNoViewers: true | undefined = undefined

  let showConfirmPrefedMsgClosedByNoViewers: true | undefined = undefined

  let showLinkGoogleInBroadcast: true | undefined = undefined

  modals.forEach((modal) => {
    switch (modal) {
      case ModalsIds.LinkGoogleInBcast:
        showLinkGoogleInBroadcast = true
        break

      case ModalsIds.ConfirmStopBroadcasting:
        showConfirmStopBroadcasting = true
        break

      case ModalsIds.ConfirmBroadcasterDisconectedByFail:
        showConfirmBroadcasterDisconectedByFail = true
        break

      case ModalsIds.SafariChangeSource:
        showSafariChangeSource = true
        break

      case ModalsIds.GoogleSignIn:
        showGoogleSignIn = true
        break

      case ModalsIds.ConfirmStopCamMicStream:
        showConfirmStopCamMicStream = true
        break

      case ModalsIds.ConfirmYourBrowswerIsTooOld:
        showYourBrowswerIsTooOld = true
        break

      case ModalsIds.ConfirmGetCamMicError:
        showConfirmGetCamMicError = true
        break

      case ModalsIds.ConfirmPredefMsgWillCloseBecauseNoViewers:
        showConfirmPredefMsgWillCloseBecauseNoViewers = true
        break

      case ModalsIds.ConfirmPrefedMsgClosedByNoViewers:
        showConfirmPrefedMsgClosedByNoViewers = true
        break
    }
  })

  // ~~~~~~ Render

  return (
    <>
      {/* - Dialog: Stop broadcasting confirmation */}

      {showConfirmStopBroadcasting && <DialogConfirmStopBroadcasting />}

      {/* - Dialog: Connection fail (webrtc datachannel error) */}

      {showConfirmBroadcasterDisconectedByFail && <DialogConfirmBroadcasterDisconnectedByFail />}

      {/* Dialog: Safari change source */}

      {showSafariChangeSource && <DialogSafariChangeSource />}

      {/* Dialog: Google sign-in */}

      {showGoogleSignIn && <DialogGoogleSignIn />}

      {/* Dialog: Confirm stop sharing cam-mic */}

      {showConfirmStopCamMicStream && <DialogConfirmStopCamMicStream />}

      {/* Dialog: Update your brwoser */}

      {showYourBrowswerIsTooOld && <DialogConfirmYourBrowswerIsTooOld />}

      {/* Dialog: Error get cam mic */}

      {showConfirmGetCamMicError && <DialogGetCamMicError />}

      {/* Dialog: Confirm will be closed, no viewers */}

      {showConfirmPredefMsgWillCloseBecauseNoViewers && <DialogPredefMsgConfirmCloseNoViewers />}

      {/* Dialog: Confirm closed, inactivity (no viewers) */}
      {showConfirmPrefedMsgClosedByNoViewers && <DialogPredefMsgConfirmClosedByNoViewers />}

      {/* Dialog: Link google in bcast */}
      {showLinkGoogleInBroadcast && <DialogLinkGoogleInBcast />}
    </>
  )
}
