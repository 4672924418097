import { Observable, of, catchError, map, retry } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

const { ForSub } = ApiBcastRoutes.BcastRoom

// ####################################################################################################
// ~~~~~~ Bcast Room for SUB
// ####################################################################################################

type Res = {
  id: string
  uuid: string
  server: string // ip
  token: string
}

type ResParsed = DNApiResParsed<Res>

type ResError = DNApiError<Infos[]>

export const apiBcastRoomForSub$ = (broadcastId: string, retryTimes: number) => {
  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersForRoom(),
    method: ForSub.Method,
    url: ForSub.getUrl(broadcastId),
  }).pipe(
    retry({ count: retryTimes, delay: 1000 }),

    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.server.get.Error', values: { value: res.status } }],
      })
    }),
  )
}
