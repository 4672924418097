import styled from 'styled-components'
import { LayoutPanelsSizes } from '../../../components/layouts/panels/style'
import { MediaQuery } from '../../../style/constants/media-queries'

// ~~~~~~ Constants

export const Padding = 12

// ~~~~~~ Component

export const STStartedLeftPanel = styled.div`
  padding: ${Padding}px;

  max-width: ${LayoutPanelsSizes.LeftPanelSize - Padding * 2}px;

  max-height: calc(100% - ${Padding * 2}px);

  width: 100%;
  height: 100%;

  @media (max-width: ${MediaQuery.MD}) {
    max-width: ${LayoutPanelsSizes.LeftPanelSizeMiddle - Padding * 2}px;
  }
`
