import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { ExitMC } from '../../../../store/actions-mutators/exit/mutators'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { DialogConfirmation } from '../component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmUserLimitReached)

// ~~~~~~ Component

export const DialogConfirmUserLimitReached = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Handlers

  function onWillBeClosed() {
    navigate(redirectPath, { replace: true })

    dispatch(ExitMC.resetExit())
  }

  function onClickConfirm() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      Dialog={Dialog}
      predefIcon="info"
      title="dialogs.confirm.user-limit-reached.Title"
      contentText="service.broadcast.sub.DisallowedError"
      isRunning={false}
      doClose={doClose}
      hideCloseButton
      okButtonIntl="common.OK"
      onEndDisappear={() => undefined}
      onClickClose={() => undefined}
      onClickConfirm={onClickConfirm}
      onWillBeClosed={onWillBeClosed}
    />
  )
}
