import { UtilsStore } from '@dn/utils'
import { Config } from '../../../../config'
import { UtilsValidate } from '../../../../utils/validations'

const MaxIdLenght = 6

const initModel: DN.Models.StreamManagerSub.ModelMain = {
  errors: [],

  id: '------',
  id_err: [],

  username: '',

  paused: false,

  subscriber: undefined,

  lastEvent: 'Empty',

  isReady: false,
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-stream-amanager-sub-main-edit-field'

export const StreamManagerSubMainFieldsMR = {
  id: UtilsStore.genFieldMR(prefix, initModel, 'id', initModel.id, Config.Intl.InfosForInfer),
}

// ~~~~~~ Validations

const { Validations } = UtilsValidate

const validations = {
  Id: [
    { error: Validations.Required },
    { error: Validations.InvalidChars },
    { error: Validations.MinLength, value: MaxIdLenght },
    { error: Validations.MaxLength, value: MaxIdLenght },
  ],
}

// ~~~~~~

export const StreamManagerSubMain = {
  MaxIdLenght,
  initModel,
  validations,
}
