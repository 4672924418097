import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { theme } from '../../../../style/utils/theme'

export const STDialogsConfirmationThanksForBuyExtra = styled.div`
  margin-top: 8px;
  text-align: center;

  ${Fonts.Scale.Label}
  color: ${theme(Colors.WOLF_80_20)};

  a {
    cursor: pointer;

    ${Fonts.Scale.Body}
    ${Fonts.Weight[700]}

    color: ${theme(Colors.WOLF_80_20)};

    text-decoration: none;
  }
`
