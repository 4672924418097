import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from '../../../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../../../components/svgs/lazy-svg/component'
import { ModalsIds } from '../../../../../../../../constants/modals'
import { StoreState } from '../../../../../../../../models/app/model'
import { ModalsMC } from '../../../../../../../../store/actions-mutators/modals/mutators'
import { ShareCamMicStreamAC } from '../../../../../../../../store/actions/share-cam-mic-stream/actions'
import { UtilsSound } from '@dn/utils'
import { ClassName, STStartedContentMainToolbarsMainButtonsCamMic } from './style'
import { Config } from '../../../../../../../../config'
import { ServiceBcast } from '@dn/bcast'
import { ServiceWebRtc } from '@dn/webrtc'

// ~~~~~~ Constants

const CamIcon = LazySVG('icons/cam')

const CamFeedbackIcon = LazySVG('icons/camera-feedback')

// ~~~~~~ Component

export const StartedContentMainToolbarsMainButtonsCamMic = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const { isReady } = useSelector((state: StoreState) => state.streamManagerPubCamMic)

  const { errors, getCamMicUiStatus, curCamMicStream, camMicUiStatus } = useSelector(
    (state: StoreState) => state.sharedCamMicStream,
  )

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  // - Refs

  const tooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Computed

  const curDnBcastWebCam = !!dnBcast.localCamTrack && !!dnBcast.localMicTrack

  const camMicIsRunning = Config.Features.BcastService2
    ? dnBcast.webcamUiState === 'running'
    : getCamMicUiStatus === 'running' || camMicUiStatus === 'running'

  const showCamStart = !camMicIsRunning && !curCamMicStream && !curDnBcastWebCam

  const tooltipTop = tooltipRef.current ? -tooltipRef.current.clientHeight - 8 : -34

  // ~~~~~~ Handlers

  function onClickStartCamMicStream() {
    if (!UtilsSound.checkAudioApi()) {
      dispatch(ModalsMC.open(ModalsIds.ConfirmYourBrowswerIsTooOld))
      return
    }

    Config.Features.BcastService2
      ? dispatch(
          ServiceBcast.React.Actions.AC.webcamStart({
            camFps: Config.CamMic.fps,

            camResolution: Config.CamMic.minSize,

            camBitrate: ServiceWebRtc.Codecs.calcH264Bandwidth({
              h264Profile: 'baseline',
              width: Config.CamMic.minSize,
              height: Config.CamMic.minSize,
              frameRate: Config.CamMic.fps,
            }),

            bitrateIsBandwidth: true,
          }),
        )
      : dispatch(
          ShareCamMicStreamAC.getStream({
            getCam: true,
            cam: undefined,
            camSize: { width: 0, height: 0 },

            getMic: true,
            mic: undefined,
          }),
        )
  }

  function onClickStopCamMicStream() {
    Config.Features.BcastService2
      ? dispatch(ServiceBcast.React.Actions.AC.webcamStop())
      : dispatch(ModalsMC.open(ModalsIds.ConfirmStopCamMicStream))
  }

  // ~~~~~~ Effects

  // - Force update on mount to calculate tooltip position

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // - Show get cam-mic error dialog

  useEffect(() => {
    if (!errors.length) return

    dispatch(ModalsMC.open(ModalsIds.ConfirmGetCamMicError))

    //
  }, [dispatch, errors.length])

  // TODO(Cuenca): Errors dnBcast WIP

  // ~~~~~~ Render

  return (
    <STStartedContentMainToolbarsMainButtonsCamMic
      className="with-tooltip"
      onClick={
        curCamMicStream || curDnBcastWebCam || camMicIsRunning
          ? onClickStopCamMicStream
          : onClickStartCamMicStream
      }
    >
      {/* Icon */}

      {showCamStart ? (
        <CamIcon className={`${ClassName}--cam-start`} size={24} />
      ) : (
        <CamFeedbackIcon
          className={`${ClassName}--cam-feedback ${(!isReady || camMicIsRunning) && !curDnBcastWebCam ? 'running' : ''}`}
          size={24}
        />
      )}

      {/* Tooltip */}

      <span
        ref={tooltipRef}
        className="tooltiptext"
        style={{
          top: tooltipTop,
        }}
      >
        {showCamStart ? (
          <Trans id="pages.started.main.toolbars.main.tooltips.webcam.Start" />
        ) : (
          <Trans id="pages.started.main.toolbars.main.tooltips.webcam.Stop" />
        )}
      </span>
    </STStartedContentMainToolbarsMainButtonsCamMic>
  )
}
