import styled from 'styled-components'
import { theme } from '../../../../../../../style/utils/theme'
import { CommonStyleSvgColors, STJoinedMobileToolbarsMainButtons } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-main-buttons-pip'

// ~~~~~~ Component

export const STJoinedMobileToolbarsMainButtonsPip = styled.div`
  ${STJoinedMobileToolbarsMainButtons}

  svg.${ClassName}--icon-off {
    rect.svg-out {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }

    rect.svg-in {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }

  svg.${ClassName}--icon-on {
    rect.svg-out {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }

    rect.svg-in {
      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }
  }
`
