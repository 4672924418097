import { ofType, StateObservable } from 'redux-observable'
import { mergeMap, Observable } from 'rxjs'
import { PenColor } from '../../../../../constants/pen-color'
import { StoreState } from '../../../../../models/app/model'
import { DrawService } from '../../../../../services/draw'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../actions/live-paint/draw/actions'
import { EpicLivePaintDrawCommandMC } from '../commands/mutators'
import { EpicLivePaintDrawStartMC } from './mutators'

type Action = ReturnType<typeof LivePaintDrawAC.start>

export const livePaintDrawStartEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.START),

    mergeMap(({ payload }) => {
      const { canvas, width, height } = payload

      const state = state$.value

      const { strokeWidth, hasAlpha, colorKey } = state.livePaintDraw

      DrawService.Set.strokeWidth(strokeWidth)

      DrawService.Set.hasAlpha(hasAlpha)

      DrawService.Set.color(PenColor[colorKey].color)

      const obs$ = new Observable<
        | ReturnType<typeof EpicLivePaintDrawStartMC.ok>
        | ReturnType<typeof EpicLivePaintDrawCommandMC.addPath>
        | ReturnType<typeof EpicLivePaintDrawCommandMC.remPath>
      >((observer) => {
        //

        const scope = DrawService.init()

        DrawService.setCanvas({ scope, canvas, width, height })

        const cmdAddOrRemPath$ = DrawService.startPainting$(scope)

        // -))

        observer.next(EpicLivePaintDrawStartMC.ok(canvas, scope))

        cmdAddOrRemPath$.subscribe({
          next: (command) => {
            switch (command.type) {
              case 'addPath':
                // -))
                observer.next(EpicLivePaintDrawCommandMC.addPath())
                break

              case 'remPath':
                // -))
                observer.next(EpicLivePaintDrawCommandMC.remPath(command.payload.isDirty))
                break
            }
          },
          complete: () => {
            // -|

            observer.complete()
          },
        })
      })

      return obs$
    }),
  )
