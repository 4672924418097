import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { LoaderBalls } from '../../../components/common/loader-balls/component'
import { ModalsIds } from '../../../constants/modals'
import { StoreState } from '../../../models/app/model'
import { ModalsMC } from '../../../store/actions-mutators/modals/mutators'
import { ApiStoreProductsAC } from '../../../store/actions/api-store/products/actions'

// ~~~~~~ Component

export const ExtensionCreateAccountPage = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ~~~~~~ State

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Effects

  // - Get products on render

  useEffect(() => {
    dispatch(ApiStoreProductsAC.listProducts('broadcast-subscription'))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - Open the process to create an account

  useEffect(() => {
    navigate(redirectPath, { replace: true })

    dispatch(ModalsMC.open(ModalsIds.CreateAnAccountFreeOrBuy))

    //
  }, [dispatch, navigate, redirectPath])

  // ~~~~~~ Render

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoaderBalls />
    </div>
  )
}
