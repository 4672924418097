import { map, Observable } from 'rxjs'

import { ofType, StateObservable } from 'redux-observable'

import { StoreState } from '../../../../models/app/model'
import { BroadcastAC } from '../../../actions/bcast/actions'
import { Config } from '../../../../config'
import {
  EpicApiServerSubCamMicMC,
  EpicApiServerSubCamMicMT,
} from '../../api-stream-manager/server/sub-cam-mic/mutators'

type Action = ReturnType<typeof EpicApiServerSubCamMicMC.ok>

export const chainingSubCamMicFromGetServerSubCamMicToStartSubEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiServerSubCamMicMT.OK),
    map(({ payload }) => {
      const { server } = payload

      const { stunTurnCredentials } = state$.value

      return BroadcastAC.startSubCamMic(
        {
          host: Config.Api.StreamManager.URI,
          serverAddress: server.serverAddress,
          broadcastId: server.broadcastId,
          mediaElementId: 'cam-mic-stream-sub',
        },
        [
          {
            urls: stunTurnCredentials.uris,
            username: stunTurnCredentials.username,
            credential: stunTurnCredentials.password,
          },
        ],
      )
    }),
  )
