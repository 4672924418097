import styled from 'styled-components'
import { Layout } from '../../../style/theme/layout'

export const STExtensionUserSettingsPage = styled.div`
  width: 100%;
  height: 100hv;

  .settings-content {
    padding: ${Layout.Spacing[7]}px;
    width: calc(100% - ${Layout.Spacing[7] * 2}px);
    height: calc(100vh - ${Layout.Spacing[7] * 2}px);
  }
`
