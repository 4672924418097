import { ofType } from 'redux-observable'
import { delay, map, Observable, switchMap } from 'rxjs'
import { CountdownService } from '@dn/service-countdown'
import { ApiBcastSubscriptionsAC } from '../../../../actions/api-bcast/subscription/actions'
import {
  CountdownMidnightBcastTimeResetAC,
  CountdownMidnightBcastTimeResetAT,
} from '../../../../actions/countdown/midnight-bcast-time-reset/actions'

// ~~~~~~ Constants

const GracePeriodBeforeEmit = 5000

// ~~~~~~ Actions

type Action = ReturnType<typeof CountdownMidnightBcastTimeResetAC.start>

// ~~~~~~ Epic

export const countdownMidnightBcastTimeResetEpic$ = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(CountdownMidnightBcastTimeResetAT.START),

    switchMap(({ payload }) =>
      CountdownService.Start.utcMidnight$().pipe(map(() => payload.subscriptionId)),
    ),

    delay(GracePeriodBeforeEmit),

    map((subscriptionId) => {
      return ApiBcastSubscriptionsAC.getTime(subscriptionId)
    }),
  )
