import { UtilsStore } from '@dn/utils'
import { Config } from '../../../config'

const initModel: DN.Models.UiLayoutViewerMobile.Model = {
  floatPanelOpenState: 'closed',
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-ui-layout-viewer-mobile'

export const UiLayoutViewerMobileFieldsMR = {
  floatPanelOpenState: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'floatPanelOpenState',
    initModel.floatPanelOpenState,
    Config.Intl.InfosForInfer,
  ),
} as const

// ~~~~~~

export const LayoutViewerMobileModel = {
  initModel,
} as const
