import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { Config } from '../../../config'

// ~~~~~~ Inint Sentry

export const sentryInit = () => {
  if (!Config.ThirdParty.Sentry.DSN) return

  Sentry.init({
    dsn: Config.ThirdParty.Sentry.DSN,

    environment: Config.App.Environment,

    release: Config.App.Revision,

    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#traces-sample-rate
    // approximately 10% of your transactions will get recorded and sent
    tracesSampleRate: 0.1,

    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#normalize-depth
    normalizeDepth: 3,

    ignoreErrors: [/(reading 'sever')/, /chrome-extension/, /gtag/],
  })
}
