import { ConstBcast } from '../constants/bcast'

const getPublicBcastId = (bcastId: string) => {
  return `${parseInt(bcastId)}`.padStart(ConstBcast.IdLength, '0')
}

// ~~~~~~

export const UtilsBcast = {
  getPublicBcastId,
} as const
