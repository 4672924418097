import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../models/app/model'
import { StatsMC } from '../../store/actions-mutators/stats/mutators'
import { UtilsPeerConnStats } from '@dn/utils'

// ~~~~~~ Constants

const StatsName = 'sub'

// ~~~~~~ Hook

export const usePeerConnStats = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const sub = useSelector((state: StoreState) => state.streamManagerSubMain.subscriber)

  const statsEnabled = useSelector((state: StoreState) => state.stats.enabled)

  // ~~~~~~ Effects

  useEffect(() => {
    if (!sub || !statsEnabled) {
      UtilsPeerConnStats.stop(StatsName)
      return
    }

    const statsSub = UtilsPeerConnStats.start$(StatsName, sub.getPeerConnection()).subscribe({
      next: (data) => dispatch(StatsMC.addStats(data)),
    })

    return () => {
      statsSub.unsubscribe()
    }
  }, [dispatch, sub, statsEnabled])
}
