import { AppInitState } from '../../../../models/app/model'
import { BcastCountdownMR } from '../../../../models/bcast/bcast-countdown/model'
import { UtilsStore } from '@dn/utils'
import {
  EpicApiBcastCurrentUserShowMT,
  EpicApiBcastCurrentUserShowMC,
} from '../../../epics/api-bcast/current-user/show/mutators'
import {
  EpicApiBcastSubscriptionShowMT,
  EpicApiBcastSubscriptionShowMC,
} from '../../../epics/api-bcast/subscription/show/mutators'
import {
  EpicApiSubscriptionsGetTimeMT,
  EpicApiSubscriptionsGetTimeMC,
} from '../../../epics/api-store/subscription/get-time/mutators'
import { ApiSubscriptionsUpgradePlanEpicMT } from '../../../epics/api-store/subscription/upgrade-plan/mutators'
import {
  EpicExtensionUserDataMC,
  EpicExtensionUserDataMT,
} from '../../../epics/extension/user-data/mutators'

const initState = AppInitState.bcastCountdown

// ~~~~~~ Types

type State = typeof initState

// ~~~~~~ Generated fields

const reducer: any = {
  ...BcastCountdownMR.timeRemaining.Reducer,
}

// ####################################################################################################
// ~~~~~~ Extension user data
// ####################################################################################################

reducer[EpicExtensionUserDataMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicExtensionUserDataMC.ok>,
): State => {
  const { time_remaining } = payload.subscription

  return {
    timeRemainig: {
      minutes: time_remaining || 0,

      seconds: 0,
    },
  }
}

// ####################################################################################################
// ~~~~~~ Current user Show - OK
// ####################################################################################################

reducer[EpicApiBcastCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastCurrentUserShowMC.ok>,
): State => {
  const { time_remaining } = payload.subscription

  return {
    timeRemainig: {
      minutes: time_remaining,

      seconds: 0,
    },
  }
}

// ####################################################################################################
// ~~~~~~ Store Subscription upgrade - OK
// ####################################################################################################

reducer[ApiSubscriptionsUpgradePlanEpicMT.OK] = (): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Get Bcast Subscription - OK
// ####################################################################################################

reducer[EpicApiBcastSubscriptionShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastSubscriptionShowMC.ok>,
): State => {
  const { time_remaining } = payload.subscription

  return {
    timeRemainig: {
      minutes: time_remaining,

      seconds: 0,
    },
  }
}

// ####################################################################################################
// ~~~~~~ Get Time - OK
// ####################################################################################################

reducer[EpicApiSubscriptionsGetTimeMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiSubscriptionsGetTimeMC.ok>,
): State => {
  return {
    timeRemainig: {
      minutes: payload.remaining,

      seconds: 0,
    },
  }
}

// ####################################################################################################
// ~~~~~~ Get Time - ERROR 412 (user has not time_limit, maybe updated plan, remove)
// ####################################################################################################

reducer[EpicApiSubscriptionsGetTimeMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiSubscriptionsGetTimeMC.error>,
): State => {
  if (payload.status !== 412) return state

  return initState
}

// ~~~~~~

export const bcastCountdownReducer = UtilsStore.dynReducer(initState, reducer)
