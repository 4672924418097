import { Trans } from '../../../../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsCapturePaint } from '../../../../../../../behaviour-logic/content/main/toolbars/main/buttons/capture-paint/hook'
import { ClassName, STJoinedDesktopContentMainToolbarsMainButtonsCapturePaint } from './style'

// ~~~~~~ Constants

const PaintIcon = LazySVG('icons/tool-pen')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const JoinedDesktopContentMainToolbarsMainButtonsCapturePaint: React.FC<Props> = ({
  video,
}) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsCapturePaint(video)

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainToolbarsMainButtonsCapturePaint
      className="with-tooltip"
      onClick={logic.onClickCapturePaint}
    >
      {/* Icon */}

      <PaintIcon className={`${ClassName}--icon`} size={24} />

      {/* Tooltip */}

      <span
        ref={logic.tooltipRef}
        className="tooltiptext"
        style={{
          top: logic.tooltipTop,
        }}
      >
        <Trans id="pages.joined.main.toolbars.main.tooltips.CapturePaint" />
      </span>

      {/* -- */}
    </STJoinedDesktopContentMainToolbarsMainButtonsCapturePaint>
  )
}
