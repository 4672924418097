import { JoinedCommonHeaderTopRightBroadcastId } from '../../../common/header/top-right/broadcast-id/component'
import { JoinedMobileHeaderTopRightLeave } from './leave/component'
import { STJoinedCommonHeaderTopRight } from './style'

// ~~~~~~ Component

export const JoinedMobileHeaderTopRight = () => {
  // ~~~~~~ Render

  return (
    <STJoinedCommonHeaderTopRight>
      {/* Broadcast ID: Common */}

      <JoinedCommonHeaderTopRightBroadcastId />

      {/* Leave */}

      <JoinedMobileHeaderTopRightLeave />

      {/* -- */}
    </STJoinedCommonHeaderTopRight>
  )
}
