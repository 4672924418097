import { ModalsMC, ModalsMT } from '../../../actions-mutators/modals/mutators'
import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'
import { ModalsIds } from '../../../../constants/modals'

const initState = AppInitState.modals

type State = typeof initState

const reducer: any = {}

// ################################################################################
// ~~~~~~ Action to open modal
// ################################################################################

reducer[ModalsMT.OPEN_MODAL] = (
  state: State,
  { payload }: ReturnType<typeof ModalsMC.open>,
): State => {
  const cleanList = state.list.filter((modalId) => modalId !== payload.modalId)

  const newConfirm = payload.fun
    ? {
        ...state.confirm,
        [payload.modalId]: payload.fun,
      }
    : state.confirm

  const newList = [...cleanList, payload.modalId]

  const newValues = payload.values
    ? {
        ...state.values,
        [payload.modalId]: payload.values,
      }
    : state.values

  return {
    list: newList,
    confirm: newConfirm,
    values: newValues,
  }
}

// ################################################################################
// ~~~~~~ Action to close modal
// ################################################################################

reducer[ModalsMT.CLOSE_MODAL] = (
  state: State,
  { payload }: ReturnType<typeof ModalsMC.close>,
): State => {
  if (payload.modalId === ModalsIds.ViewHasEnded) return initState

  const newList = state.list.filter((modal) => modal !== payload.modalId)

  const newConfirm = {
    ...state.confirm,
    [payload.modalId]: undefined,
  }

  const newValues = {
    ...state.values,
    [payload.modalId]: undefined,
  }

  return {
    list: newList,
    confirm: newConfirm,
    values: newValues,
  }
}

// ####################################################################################################
// ~~~~~~ Close a list of modals
// ####################################################################################################

reducer[ModalsMT.CLOSE_MODAL_SELECTION] = (
  state: State,
  { payload }: ReturnType<typeof ModalsMC.closeSelection>,
): State => {
  const newList = state.list.filter((modal) => !payload.modalIds.includes(modal))

  const newConfirm = Object.keys(state.confirm).reduce((acc, cur) => {
    if (!state.confirm[cur]) return acc

    if (payload.modalIds.includes(cur)) return acc

    return {
      ...acc,
      [cur]: state.confirm[cur],
    }
  }, {})

  const newValues = Object.keys(state.values).reduce((acc, cur) => {
    if (!state.values[cur]) return acc

    if (payload.modalIds.includes(cur)) return acc

    return {
      ...acc,
      [cur]: state.values[cur],
    }
  }, {})

  return {
    list: newList,
    confirm: newConfirm,
    values: newValues,
  }
}

// ####################################################################################################
// ~~~~~~ All cancelled
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  const confirmStopEmitting = ModalsIds.ConfirmStopBroadcasting

  const newList = state.list.filter((modal) => modal !== confirmStopEmitting)

  const newConfirm = {
    ...state.confirm,
    [confirmStopEmitting]: undefined,
  }

  const newValues = {
    ...state.values,
    [confirmStopEmitting]: undefined,
  }

  return {
    list: newList,
    confirm: newConfirm,
    values: newValues,
  }
}

// ~~~~~~

export const modalReducer = UtilsStore.dynReducer(initState, reducer)
