const initPubModel: DN.Models.NetworkCongestion.PubModel = {
  screenStreamCongestion: 'sufficient',
  camMicStreamCongestion: 'sufficient',
  networkIsOk: true,
} as const

const initSubModel: DN.Models.NetworkCongestion.SubModel = {
  screenStreamCongestion: 'recovery',
  camMicStreamCongestion: 'recovery',
  networkIsOk: true,
} as const

// ~~~~~~

export const NetworkCongestion = {
  initPubModel,
  initSubModel,
} as const
