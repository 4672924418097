import { UtilsApiCallCache } from '@dn/utils'
import { Observable } from 'rxjs'
import { apiStunTurnGetCredentials$ } from './call'

// ~~~~~~ Types

type State = ReturnType<typeof apiStunTurnGetCredentials$> extends Observable<infer T> ? T : never

// ~~~~~~ Constants

const GracePeriod = 1000

// ~~~~~~

const checkCache = (value: State) => {
  return Date.now() + GracePeriod < value.response.expiration
}

const initValue: State = {
  status: 0,
  response: {
    username: '',
    password: '',
    ttl: 0,
    expiration: 0,
    uris: [],
  },
}

// ~~~~~~

export const cachedApiStunTurnGetCredentials$ = UtilsApiCallCache.genApiCallCache(
  apiStunTurnGetCredentials$,
  initValue,
  checkCache,
)
