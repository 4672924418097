import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constant

export const ClassName = 'started-content-main'

// ~~~~~~ Component

export const STStartedContentMain = styled.div`
  touch-action: none;

  width: 100%;
  height: 100%;

  background-color: ${theme(Colors.WOLF_05_100)};

  display: flex;
  justify-content: center;

  position: relative;

  overflow: hidden;

  /* Content */

  .${ClassName}--content {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Layout.Border.Radius.A}

    overflow: hidden;

    width: 100%;

    /* Loading */

    .${ClassName}--content--loading {
      position: absolute;
      z-index: 1;

      left: 50%;
      top: 44%;
      transform: translate(-50%, -50%);
    }

    /* Video Wrapper */

    .${ClassName}--content--video-wrapper {
      position: relative;

      width: 100%;

      ${Layout.Border.Radius.A}

      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: center;

      /* Cam Mic */

      .${ClassName}--content--video-wrapper--cam-mic {
        position: absolute;

        // LivePaint z-index is 10
        z-index: 11;

        left: ${Layout.Spacing[2]}px;

        // Component padding
        bottom: ${Layout.Spacing[2]}px;

        &.hidden {
          display: none;
        }
      }

      /* Video */

      .${ClassName}--content--video-wrapper--video {
        z-index: 4;
        width: 100%;
        height: 100%;
      }
    }

    /* Tools */

    .${ClassName}--content--tools {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
`
