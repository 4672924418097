import styled from 'styled-components'

import PenImage from '../../../../../../imgs/png/icons/pen.png'
import MarkerImage from '../../../../../../imgs/png/icons/marker.png'
import HighlighterImage from '../../../../../../imgs/png/icons/highlighter.png'
import EraserImage from '../../../../../../imgs/png/icons/eraser.png'
import { Colors } from '../../../../../../style/theme/colors'
import { theme } from '../../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-tablet-content-main-capture-paint'

// ~~~~~~ Types

type STProps = {
  $activePen: DN.Models.PaintBcastStream.Draw.Pen
  $isErasing: boolean
}

// ~~~~~~ Component

export const STJoinedTabletContentMainCapturePaint = styled.div<STProps>`
  /* border: solid 1px blue; */

  position: absolute;
  z-index: 10;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${theme(Colors.WOLF_05_100)};

  .${ClassName}--canvases-wrapper {
    /* border: solid 1px green; */

    position: relative;

    canvas {
      /* opacity: 0.5; */

      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
    }

    canvas.${ClassName}--canvases-wrapper--paint {
      /* border: solid 1px red; */

      z-index: 2;

      /* url offset-x offset-y (from left or top)  */
      cursor: ${({ $activePen, $isErasing }) =>
        $isErasing
          ? `url(${EraserImage}) 9 33, auto`
          : $activePen === 'pen'
            ? `url(${PenImage}) 0 22, auto`
            : $activePen === 'marker'
              ? `url(${MarkerImage}) 0 22, auto`
              : $activePen === 'highlighter'
                ? `url(${HighlighterImage}) 0 22, auto`
                : 'default'};
    }

    canvas.${ClassName}--canvases-wrapper--screenshot {
      /* border: solid 1px lime; */

      z-index: 1;
    }
  }
`
