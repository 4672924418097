// !!!-used-by-extension

import { useState } from 'react'

import { DialogBreakPoint } from '../../../constants/breakpoints'
import { ModalsIds } from '../../../constants/modals'
import { Button } from '../../common/button/component'
import { Actions } from '../compound-dialog/actions/component'
import { Content } from '../compound-dialog/content/component'
import { genAnimatedDialog } from '../compound-dialog/dialog/component'
import { Trans } from '../../common/intl/trans'

import { STDialogLinkGoogleInBcast } from './style'
import { Config } from '../../../config'
import { Routes } from '../../../constants/routes/routes'

// ~~~~~~ Constants

const ModalId = ModalsIds.LinkGoogleInBcast

const Dialog = genAnimatedDialog(ModalId)

// ~~~~~~ Component

export const DialogLinkGoogleInBcast: React.FC<{}> = () => {
  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())
  }

  function onClickGoToBcastSettings() {
    window.open(`${Config.BcastUrl}${Routes.ExtensionUserSettings}`)

    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      modalSize={DialogBreakPoint.XS}
      dialogTitle="dialogs.link-google-in-bcast.Title"
      closeDialog={doClose}
    >
      {/* Content */}

      <Content>
        <STDialogLinkGoogleInBcast>
          <div className="line">
            <Trans id="dialogs.link-google-in-bcast.Content" />
          </div>
        </STDialogLinkGoogleInBcast>
      </Content>

      {/* Actions */}

      <Actions>
        {/* Close */}

        <Button
          intlId="common.Cancel"
          $colorType="secondary"
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickClose}
        />

        {/* Chage source */}

        <Button
          intlId="dialogs.link-google-in-bcast.actions.Connect"
          $colorType="primary"
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickGoToBcastSettings}
        />
      </Actions>
    </Dialog>
  )
}
