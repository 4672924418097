import { Config } from '../../../../../config'

// ~~~~~~

export const sendMessageToContentBcastStopped = (source: MessageEventSource, msgId: string) => {
  const message: DN.Services.Extension.ToContent.BroadcastStopped = {
    type: 'bcast-stopped',
    meta: {
      from: 'bcast',
      msgId,
      extensionId: Config.Extension.Id,
    },
  }

  source.postMessage(message)
}
