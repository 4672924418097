import styled from 'styled-components'
import { STPaintToolbarCommonStyle } from '../common-style'

// ~~~~~~ Types

export type STProps = {
  $disabled: boolean
}

// ~~~~~~ Component

export const STPaintToolbarButtonsSharePaint = styled.div<STProps>`
  ${STPaintToolbarCommonStyle}

  padding: 0;

  height: 24px;
  width: 24px;
`
