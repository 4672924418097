import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalsIds } from '../../../../../../../constants/modals'
import { PenColorKey } from '../../../../../../../constants/pen-color'
import { StrokeWidth } from '../../../../../../../constants/stroke-width'
import { StoreState } from '../../../../../../../models/app/model'
import { ImageToShareFieldsMR } from '../../../../../../../models/bcast/image-to-share/model'
import {
  PaintBcastStreamDrawModelFieldsMR,
  PaintBcastStreamEditFieldsMR,
} from '../../../../../../../models/bcast/paint-bcast-stream/model'
import { UiLayoutPanelsFieldsMR } from '../../../../../../../models/bcast/ui-layout-panels/model'
import { UiMobileToolbarFieldsMR } from '../../../../../../../models/bcast/ui-mobile-toolbars/model'
import { ModalsMC } from '../../../../../../../store/actions-mutators/modals/mutators'
import { PaintBCastStreamDrawAC } from '../../../../../../../store/actions/paint-bcast-stream/draw/actions'
import { UtilsDates } from '@dn/utils'

// ~~~~~~ Types

type ActiveTool = DN.Models.PaintBcastStream.Draw.Pen

// ~~~~~~ Hook

export const useJoinedBehaviourLogicContentMainToolbarsCapturePaintDraw = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [requireReRender, setRequireReRender] = useState(true)

  const [showColorSelector, setShowColorSelector] = useState(false)

  const [showPenSelector, setShowPenSelector] = useState(false)

  const [downloading, setDownloading] = useState(false)

  const [fileAndName, setFileAndName] = useState<{ file: string; name: string } | undefined>(
    undefined,
  )

  const [shouldDownload, setShouldDownload] = useState(false)

  // - Store

  const { zoom, originalVideoW, originalVideoH } = useSelector(
    (state: StoreState) => state.paintBcastStreamVideo,
  )

  const { canvas, activePen, colorKey, undos, redos, isDirty, isDownloaded } = useSelector(
    (state: StoreState) => state.paintBcastStreamDraw,
  )

  const { canvas: savedScreenShotCanvas } = useSelector(
    (state: StoreState) => state.paintBcastStreamPic,
  )

  const exitEditingImageIsOpen = useSelector((state: StoreState) =>
    state.modals.list.includes(ModalsIds.ExitEditingImage),
  )

  const layoutPanels = useSelector((state: StoreState) => state.uiLayoutPanels)

  const imageToShareReqUpdate = useSelector((state: StoreState) => state.imageToShare.reqUpdate)

  const intlLang = useSelector((state: StoreState) => state.intl.lang)

  // ~~~~~~ Callbacks

  const genImageToShare = useCallback(() => {
    if (!savedScreenShotCanvas || !canvas) {
      endDownload()

      return
    }

    const mergedCanvas = document.createElement('canvas')

    const scale = zoom / 100

    mergedCanvas.width = originalVideoW * scale
    mergedCanvas.height = originalVideoH * scale

    const context2d = mergedCanvas.getContext('2d')

    if (!context2d) return

    context2d.drawImage(savedScreenShotCanvas, 0, 0, mergedCanvas.width, mergedCanvas.height)

    context2d.drawImage(canvas, 0, 0, mergedCanvas.width, mergedCanvas.height)

    const file = mergedCanvas.toDataURL('image/png')

    const name = `Broadcast ${UtilsDates.getTitleTimestamp(intlLang)}`

    dispatch(ImageToShareFieldsMR.__model__.MC.setModel({ file, name, reqUpdate: false }))

    //
  }, [canvas, dispatch, intlLang, originalVideoH, originalVideoW, savedScreenShotCanvas, zoom])

  // ~~~~~~ Handlers

  // - Change: Show new tools

  function onClickShowColorSelector() {
    setShowColorSelector(!showColorSelector)
  }

  function onClickShowPenSelector() {
    setShowPenSelector(!showPenSelector)
  }

  // - Paint tools

  // -- Select concrete pen from paint tools or pens tools

  // --- Pen

  function onClickDrawWithPen() {
    dispatch(
      PaintBCastStreamDrawAC.updateProps({
        activePen: 'pen',
        strokeWidth: StrokeWidth.Pen,
        hasAlpha: false,
        isErasing: false,
      }),
    )
  }

  // --- Marker

  function onClickDrawWithMarker() {
    dispatch(
      PaintBCastStreamDrawAC.updateProps({
        activePen: 'marker',
        strokeWidth: StrokeWidth.Marker,
        hasAlpha: false,
        isErasing: false,
      }),
    )
  }

  // --- Hinghtlighter

  function onClickDrawWithHighlighter() {
    dispatch(
      PaintBCastStreamDrawAC.updateProps({
        activePen: 'highlighter',
        strokeWidth: StrokeWidth.Highlighter,
        hasAlpha: true,
        isErasing: false,
      }),
    )
  }

  // --- Switch pen/hightlighter/marker helper

  function onClickSelectPen(pen: ActiveTool) {
    setShowPenSelector(false)

    switch (pen) {
      case 'pen':
        return onClickDrawWithPen()

      case 'marker':
        return onClickDrawWithMarker()

      case 'highlighter':
        return onClickDrawWithHighlighter()
    }
  }

  // -- Eraser

  function onClickEraser() {
    dispatch(
      PaintBCastStreamDrawAC.updateProps({
        isErasing: true,
      }),
    )
  }

  // -- Select concrete color from paint tools or colors tools

  function onClickSelectColor(newColorKey: PenColorKey) {
    setShowColorSelector(false)

    dispatch(
      PaintBCastStreamDrawAC.updateProps({
        colorKey: newColorKey,
      }),
    )
  }

  // -- Undo

  function onClickUndo() {
    dispatch(PaintBCastStreamDrawAC.cmdUndo())
  }

  // -- Redo

  function onClickRedo() {
    dispatch(PaintBCastStreamDrawAC.cmdRedo())
  }

  // -- Save: Download the image

  function endDownload() {
    setShouldDownload(false)
    setDownloading(false)
    setFileAndName(undefined)
  }

  function onClickSave() {
    if (downloading) return

    setShouldDownload(true)
    setDownloading(true)

    if (!savedScreenShotCanvas || !canvas) {
      endDownload()

      return
    }

    const mergedCanvas = document.createElement('canvas')

    const scale = zoom / 100

    mergedCanvas.width = originalVideoW * scale
    mergedCanvas.height = originalVideoH * scale

    const mergedCanvasContext2d = mergedCanvas.getContext('2d')

    if (!mergedCanvasContext2d) {
      endDownload()

      return
    }

    mergedCanvasContext2d.drawImage(
      savedScreenShotCanvas,
      0,
      0,
      mergedCanvas.width,
      mergedCanvas.height,
    )

    mergedCanvasContext2d.drawImage(canvas, 0, 0, mergedCanvas.width, mergedCanvas.height)

    const file = mergedCanvas.toDataURL('image/png')

    const name = `Broadcast-${UtilsDates.getFileTimestamp(intlLang)}`

    setFileAndName({ file, name })

    dispatch(PaintBcastStreamDrawModelFieldsMR.isDownloaded.MC.change(false))
  }

  function onImageSaved() {
    // Close on save only if action come from the dialog ExitEditingImage

    if (!exitEditingImageIsOpen) return

    // Close save dialog

    dispatch(ModalsMC.close(ModalsIds.ExitEditingImage))

    // Exit

    onClickExitEditingPic()
  }

  function onDownload() {
    endDownload()

    dispatch(PaintBcastStreamDrawModelFieldsMR.isDownloaded.MC.change(true))

    onImageSaved()
  }

  // -- Clear

  function onClickClear() {
    dispatch(PaintBCastStreamDrawAC.cmdClear())
  }

  // -- Exit image editing

  function onClickExitEditingPic() {
    dispatch(PaintBCastStreamDrawAC.stop())

    dispatch(PaintBcastStreamEditFieldsMR.enabled.MC.change(false))

    setRequireReRender(true)

    setShowColorSelector(false)

    setShowPenSelector(false)

    // Desktop

    dispatch(
      UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change(
        layoutPanels.savedNotPaintingLeftPanelOpenState,
      ),
    )

    dispatch(UiLayoutPanelsFieldsMR.leftPanelShowSharePaints.MC.change(false))

    // Mobile

    dispatch(UiMobileToolbarFieldsMR.openStatus.MC.change('closed'))

    // Clear image

    dispatch(ImageToShareFieldsMR.__model__.MC.completeClear())
  }

  // ~~~~~~ Effects

  // - Refresh image on demand

  useEffect(() => {
    if (!imageToShareReqUpdate) return

    genImageToShare()

    //
  }, [genImageToShare, imageToShareReqUpdate])

  // ~~~~~~

  return {
    requireReRender,
    activePen,
    fileAndName,
    colorKey,
    undos,
    redos,

    showColorSelector,
    showPenSelector,

    isDirty,
    shouldDownload,
    isDownloaded,

    onClickShowColorSelector,
    onClickSelectColor,

    onClickShowPenSelector,
    onClickSelectPen,

    onClickEraser,

    onClickUndo,
    onClickRedo,

    onClickSave,
    onDownload,

    onClickClear,

    onClickExitEditingPic,

    genImageToShare,
  } as const
}
