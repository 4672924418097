import { useSelector } from 'react-redux'
import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../../../../models/app/model'
import { UtilsDates, UtilsImage } from '@dn/utils'
import { ClassName, STJoinedMobileToolbarsMainButtonsDownload } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/download')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const JoinedMobileToolbarsMainButtonsDownload: React.FC<Props> = ({ video }) => {
  // ~~~~~~ State

  // - Store

  const { originalVideoW, originalVideoH } = useSelector(
    (state: StoreState) => state.paintBcastStreamVideo,
  )

  const intlLang = useSelector((state: StoreState) => state.intl.lang)

  // ~~~~~~ Handlers

  function takeScreenshot() {
    if (!video) return

    const canvas = document.createElement('canvas')

    canvas.width = originalVideoW
    canvas.height = originalVideoH

    const context2d = canvas.getContext('2d')

    if (!context2d) return

    context2d.imageSmoothingEnabled = false

    context2d.drawImage(video, 0, 0, originalVideoW, originalVideoH)

    return canvas
  }

  function onClick() {
    const screenShotCanvas = takeScreenshot()

    if (!screenShotCanvas) return

    const file = screenShotCanvas.toDataURL('image/png')

    const name = `Broadcast ${UtilsDates.getTitleTimestamp(intlLang)}`

    UtilsImage.download({
      file,
      name,
    })
  }

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsMainButtonsDownload onClick={onClick}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsMainButtonsDownload>
  )
}
