import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ApiBcastSubscriptionsAT } from '../../../actions/api-bcast/subscription/actions'
import { EpicApiSubscriptionsGetTimeMT } from '../../../epics/api-store/subscription/get-time/mutators'

const initState = AppInitState.timeCheck

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Get Time START
// ####################################################################################################

reducer[ApiBcastSubscriptionsAT.GET_TIME] = (): State => {
  return {
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Get Time OK
// ####################################################################################################

reducer[EpicApiSubscriptionsGetTimeMT.OK] = (): State => {
  return {
    uiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ Get Time ERROR
// ####################################################################################################

reducer[EpicApiSubscriptionsGetTimeMT.ERROR] = (): State => {
  return {
    uiStatus: 'init',
  }
}

// ~~~~~~

export const timeCheckReducer = UtilsStore.dynReducer(initState, reducer)
