import { css } from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { Misc } from '../../../../style/theme/misc'
import { theme } from '../../../../style/utils/theme'

// ~~~~~ Types

export type STProps = {
  $forceDarkTheme?: true
}

// ~~~~~ Component

export const STMenuCommonStyle = css<STProps>`
  padding: ${Layout.Spacing[3]}px;

  ${Layout.Border.Radius.B}

  background-color: ${({ $forceDarkTheme }) =>
    $forceDarkTheme ? Colors.WOLF_00_90.dark : theme(Colors.WOLF_00_90)};

  color: ${({ $forceDarkTheme }) =>
    $forceDarkTheme ? Colors.WOLF_100_05.dark : theme(Colors.WOLF_100_05)};

  ${Misc.BoxShadow}

  width: 100%;
  white-space: nowrap;

  .popup-menu-title {
    ${Fonts.Scale.BodyBig}
    ${Fonts.Weight[700]}

    margin-bottom: ${Layout.Spacing[3]}px;

    display: flex;
  }

  .popup-menu-line {
    cursor: pointer;

    padding: ${Layout.Spacing[2]}px;

    ${Layout.Border.Radius.A}

    display: flex;
    align-items: center;

    margin-bottom: ${Layout.Spacing[2]}px;

    &.last {
      margin-bottom: 0;
    }

    &.running {
      cursor: not-allowed;
    }

    &:hover:not(.running) {
      background-color: ${({ $forceDarkTheme }) =>
        $forceDarkTheme ? Colors.WOLF_05_80.dark : theme(Colors.WOLF_05_80)};
    }

    .popup-menu-icon {
      display: flex;
      margin-right: ${Layout.Spacing[2]}px;

      svg {
        height: 24px;
      }
    }

    .popup-menu-text {
      ${Fonts.Scale.Body}
      ${Fonts.Weight[500]}
    }

    &.with-loader {
      display: flex;
      justify-content: space-between;
    }
  }
`
