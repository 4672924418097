import { Config } from '../../../config'

const DefaultHeaders = {
  'Content-Type': 'application/json',
}

const genHeaders = (headers?: AppHeaders): { headers: { [key: string]: string } } => ({
  headers: {
    ...DefaultHeaders,
    ...Config.Api.Bcast.Headers,
    ...headers,
  },
})

const genAuthHeader = (token: string) => ({
  Authorization: `Bearer ${token}`,
})

const genHeadersWithAuth = (token: string, headers?: AppHeaders) => ({
  ...genHeaders({
    ...headers,
    ...genAuthHeader(token),
  }),
})

const genGoogleAccessToken = (token: string) => ({
  'x-google-access-token': token,
})

const genHeadersWithGoogleAccessToken = (accessToken: string, headers?: AppHeaders) => ({
  ...genHeaders({
    ...headers,
    ...genGoogleAccessToken(accessToken),
  }),
})

const genRoomHeaders = () => ({
  // 'x-app-version': undefined,
  // 'x-app-version-code': undefined,
  'x-app-flavour': 'vanilla',
  'x-app-environment': Config.Bcast.Environment,
  'x-app-id': 'bcast',
  // 'x-app-rnd': undefined,
  // 'x-app-rnd-sign': undefined,
})

const genHeadersForRoom = (headers?: AppHeaders) => ({
  ...genHeaders({
    ...headers,
    ...genRoomHeaders(),
  }),
})

const genHeadersWithAuthForRoom = (token: string, headers?: AppHeaders) => ({
  ...genHeaders({
    ...headers,
    ...genAuthHeader(token),
    ...genRoomHeaders(),
  }),
})

// ~~~~~~

export const BcastHeaderUtils = {
  genHeaders,
  genAuthHeader,
  genGoogleAccessToken,
  genHeadersWithAuth,
  genHeadersWithGoogleAccessToken,

  genHeadersForRoom,
  genHeadersWithAuthForRoom,
}
