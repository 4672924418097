import styled from 'styled-components'
import { theme } from '../../../../../../../../style/utils/theme'
import {
  CommonStyleSvgColors,
  STStartedContentMainToolbarsButtonsCommonStyle,
} from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'started-content-main-toolbars-buttons-start-live-paint'

// ~~~~~~ Component

export const STStartedContentMainToolbarsMainButtonsStartLivePaint = styled.div`
  ${STStartedContentMainToolbarsButtonsCommonStyle}

  svg.${ClassName}--icon {
    path {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }
  }
`
