import React from 'react'
import { LayoutCupContext } from '../context/component'

// ~~~~~~ Hook

export const useLayoutCupContext = () => {
  const context = React.useContext(LayoutCupContext)

  if (!context) {
    throw new Error(`Layout cup components cannot be rendered outside the LayoutCup component`)
  }

  return context
}
