import { AppInitState } from '../../../../../models/app/model'
import { PaintBcastStreamVideoMR } from '../../../../../models/bcast/paint-bcast-stream/model'
import { UtilsStore } from '@dn/utils'
import { CancelNowAT } from '../../../../actions/cancel-now/actions'

const initState = AppInitState.paintBcastStreamVideo

type State = typeof initState

const reducer: any = {
  ...PaintBcastStreamVideoMR.video.Reducer,

  ...PaintBcastStreamVideoMR.originalVideoW.Reducer,
  ...PaintBcastStreamVideoMR.originalVideoH.Reducer,

  ...PaintBcastStreamVideoMR.zoom.Reducer,
}

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ~~~~~~

export const paintBcastStreamVideoReducer = UtilsStore.dynReducer(initState, reducer)
