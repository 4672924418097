const prefix = 'mt-api-bcast-user-settings-show-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastUserSettingsShowMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastUserSettingsShowMC = {
  ok: (data: DN.Models.UserSettings.Api.Show.ResParsed) => ({
    type: EpicApiBcastUserSettingsShowMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.UserSettings.Api.Show.ResError) => ({
    type: EpicApiBcastUserSettingsShowMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
