const prefix = 'mt-live-paint-draw-command-epic'

// ~~~~~~

export const EpicLivePaintDrawCommandMT = {
  ADD_PATH: `${prefix}-add-path`,
  REM_PATH: `${prefix}-rem-path`,
  CLEAR: `${prefix}-clear`,
  UNDO: `${prefix}-undo`,
  REDO: `${prefix}-redo`,
} as const

// ~~~~~~

export const EpicLivePaintDrawCommandMC = {
  addPath: () => ({
    type: EpicLivePaintDrawCommandMT.ADD_PATH,
  }),

  remPath: (isDirty: boolean) => ({
    type: EpicLivePaintDrawCommandMT.REM_PATH,
    payload: {
      isDirty,
    },
  }),

  clear: () => ({
    type: EpicLivePaintDrawCommandMT.CLEAR,
  }),

  undo: (cmd: string, isDirty: boolean) => ({
    type: EpicLivePaintDrawCommandMT.UNDO,
    payload: {
      command: cmd as DN.Models.PaintBcastStream.Draw.Command,
      isDirty,
    },
  }),

  redo: (cmd: string, isDirty: boolean) => ({
    type: EpicLivePaintDrawCommandMT.REDO,
    payload: {
      command: cmd as DN.Models.PaintBcastStream.Draw.Command,
      isDirty,
    },
  }),
} as const
