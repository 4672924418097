import { catchError, map, Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ProviderModel } from '../../../../models/bcast/providers/model'
import { ApiBcastRoutes } from '../../constants/routes'
import { BcastHeaderUtils } from '../../utils/headers'

const { LinkGoogle } = ApiBcastRoutes.Providers

// ####################################################################################################
// ~~~~~~ Link Google account
// ####################################################################################################

type Res = DN.Models.Provider.Api.LinkGoogle.Res
type ResParsed = DNApiResParsed<DN.Models.Provider.Api.LinkGoogle.ResParsed>
type ResError = DNApiError<DN.Models.Provider.Api.LinkGoogle.ResError>
type ResParsedError = DNApiError<DN.Models.Provider.Api.LinkGoogle.ResParsedError>

export const apiBcastProvidersGoogleLink$ = (
  token: string,
  userId: string,
  code: string,
  headers?: AppHeaders,
) => {
  const body: DN.Models.Provider.Api.LinkGoogle.Req = {
    authorization_code: code,
  }

  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersWithAuth(token, headers),
    method: LinkGoogle.Method,
    url: LinkGoogle.getUrl(userId),
    body,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),

    catchError((res: ResError): Observable<ResParsedError> => {
      const defaultError: ResParsedError = {
        status: res.status,
        response: [{ id: 'api.providers.link-google.Error' }],
      }

      let error = defaultError

      const authorizationCodeInsufficientPermissions: DN.Models.Provider.Api.LinkGoogle.authorizationCodeInsufficientPermissions =
        'insufficient-permissions'

      switch (true) {
        case res.status === 400:
          if (
            typeof res.response === 'object' &&
            res.response.authorization_code_err?.includes(authorizationCodeInsufficientPermissions)
          ) {
            error = {
              ...error,
              response: [
                { id: ProviderModel.customErrors.authorizationCodeInsufficientPermissionsIntlId },
              ],
            }
          }

          break
      }

      return of(error)
    }),
  )
}
