import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../../models/app/model'
import { DrawService } from '../../../../../../services/draw'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../../actions/live-paint/draw/actions'
import { EpicLivePaintDrawCommandMC } from '../mutators'

type Action = ReturnType<typeof LivePaintDrawAC.cmdUndo>

export const livePaintDrawCommandsUndoEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.CMD_UNDO),

    mergeMap(() => {
      const scope = state$.value.livePaintDraw.scope

      if (!scope) return EMPTY

      const cmdName = DrawService.Commands.undo()

      const isDirty = DrawService.getSomeItemIsVisible(scope)

      return of(EpicLivePaintDrawCommandMC.undo(cmdName || 'undo', isDirty))
    }),
  )
