import { useDispatch } from 'react-redux'

import { DialogBreakPoint } from '../../../constants/breakpoints'
import { ModalsIds } from '../../../constants/modals'
import { ModalsMC } from '../../../store/actions-mutators/modals/mutators'
import { Button } from '../../common/button/component'
import { Actions } from '../compound-dialog/actions/component'
import { Content } from '../compound-dialog/content/component'
import { genAnimatedDialog } from '../compound-dialog/dialog/component'
import { Trans } from '../../common/intl/trans'
import { STDialogYouCanBuyBroadcast } from './style'
import { useState } from 'react'

// ~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.YouCanBuyBroadcast)

// ~~~~~ Component

export const DialogYouCanBuyBroadcast = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())
  }

  function onClickBuy() {
    dispatch(ModalsMC.open(ModalsIds.CreateAnAccountFreeOrBuy))

    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      predefIcon="info"
      modalSize={DialogBreakPoint.XS}
      dialogTitle="dialogs.you-can-buy-broadcast.Title"
      closeDialog={doClose}
    >
      {/* Content */}

      <Content>
        <STDialogYouCanBuyBroadcast>
          <Trans id="dialogs.you-can-buy-broadcast.Content" />

          <span className="open-dialog" onClick={onClickBuy}>
            <Trans id="dialogs.you-can-buy-broadcast.Here" />
          </span>
        </STDialogYouCanBuyBroadcast>
      </Content>

      {/* Actions */}

      <Actions>
        <Button
          data-testid="close"
          intlId="common.Close"
          $colorType="primary"
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickClose}
        />
      </Actions>
    </Dialog>
  )
}
