const prefix = 'at-api-bcast-providers'

// ~~~~~~ Action Types

export const ApiBcastProvidersAT = {
  LINK_GOOGLE: `${prefix}-link-google`,
  UNLINK_GOOGLE: `${prefix}-unlink-google`,
} as const

// ~~~~~~ Action Creators

export const ApiBcastProvidersAC = {
  linkGoogle: (code: string, isXauth?: true) => ({
    type: ApiBcastProvidersAT.LINK_GOOGLE,
    payload: {
      code,
      isXauth,
    },
  }),

  unlinkGoogle: () => ({
    type: ApiBcastProvidersAT.UNLINK_GOOGLE,
  }),
} as const
