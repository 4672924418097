import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { getThemeValue } from '../../../style/utils/theme'

// ~~~~~~ Types

export type BtnColorType = 'primary' | 'buy-plan' | 'secondary'

export type STProps = {
  $translateActiveBall?: number
  $small?: true
  $colorType?: BtnColorType
}

// ~~~~~~ Helpers

const getPrimaryColor = () => {
  return {
    backgroundUnchecked: Colors.WOLF_20_70,
    ballUnchecked: Colors.WOLF_00_100,

    backgroundChecked: Colors.BRONX_40_30,
    ballChecked: Colors.WOLF_00_100,
  }
}

const getSecondaryColor = () => {
  return {
    backgroundUnchecked: Colors.WOLF_20_70,
    ballUnchecked: Colors.WOLF_00_100,

    backgroundChecked: Colors.WOLF_20_70,
    ballChecked: Colors.WOLF_100_05,
  }
}

const getBuyPlan = () => {
  return {
    backgroundUnchecked: Colors.WOLF_70_20,
    ballUnchecked: Colors.WOLF_100_00,

    backgroundChecked: Colors.BRONX_30_40,
    ballChecked: Colors.WOLF_100_00,
  }
}

const getColor = (colorType?: BtnColorType) => {
  switch (colorType) {
    // Base

    case 'primary':
      return getPrimaryColor()

    case 'secondary':
      return getSecondaryColor()

    case 'buy-plan':
      return getBuyPlan()

    default:
      return getPrimaryColor()
  }
}

const backgroundCheckedColor = (props: STProps) =>
  getThemeValue(props, getColor(props.$colorType).backgroundChecked)

const backgroundUncheckedColor = (props: STProps) =>
  getThemeValue(props, getColor(props.$colorType).backgroundUnchecked)

const ballColorChecked = (props: STProps) =>
  getThemeValue(props, getColor(props.$colorType).ballChecked)

const ballColorUnchecked = (props: STProps) =>
  getThemeValue(props, getColor(props.$colorType).ballUnchecked)

// ~~~~~~ Component

export const STSliderCheck = styled.div<STProps>`
  width: ${({ $small }) => ($small ? 28 : 40)}px;
  height: ${({ $small }) => ($small ? 16 : 20)}px;

  /* Border not checked */
  background-color: ${backgroundUncheckedColor};

  /* Border checked */
  &[data-checked='true'] {
    background-color: ${backgroundCheckedColor};
  }

  border-radius: 40px;
  overflow: hidden;

  .slider-check-switch {
    cursor: pointer;

    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 40px;

    margin: 2px;

    display: flex;
    justify-content: flex-start;

    /* Not checked */

    /* - Background */
    background-color: ${backgroundUncheckedColor};

    .slider-check-checkbox {
      width: ${({ $small }) => ($small ? 12 : 16)}px;
      height: 100%;

      margin: 0px;

      border-radius: 50%;

      /* - Ball */
      background-color: ${ballColorUnchecked};
    }

    /* Checked */

    &[data-checked='true'] {
      justify-content: flex-end;

      /* - Background */
      background-color: ${backgroundCheckedColor};

      .slider-check-checkbox {
        /* - Ball */
        background-color: ${ballColorChecked};
      }
    }
  }
`
