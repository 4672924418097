import { Trans } from '../../../../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsCaptureShare } from '../../../../../../../behaviour-logic/content/main/toolbars/main/buttons/capture-share/hook'
import { ClassName, STJoinedDesktopContentMainToolbarsMainButtonsCaptureShare } from './style'

// ~~~~~~ Constants

const ShareIcon = LazySVG('icons/edit-pic')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const JoinedDesktopContentMainToolbarsMainButtonsCaptureShare: React.FC<Props> = ({
  video,
}) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsCaptureShare(video)

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainToolbarsMainButtonsCaptureShare
      className="with-tooltip"
      onClick={logic.onClickCaptureShare}
    >
      {/* Icon */}

      <ShareIcon className={`${ClassName}--icon`} size={24} />

      {/* Tooltip */}

      <span
        ref={logic.tooltipRef}
        className="tooltiptext"
        style={{
          top: logic.tooltipTop,
        }}
      >
        <Trans id="pages.joined.main.toolbars.main.tooltips.CaptureShare" />
      </span>

      {/* -- */}
    </STJoinedDesktopContentMainToolbarsMainButtonsCaptureShare>
  )
}
