import { UtilsStore } from '@dn/utils'

// ~~~~~~ Constants

const ErrorType: Infos = { id: 'wildcard' }

const initModel: DN.Models.UiSettings.Model = {
  sectionSelected: 'account',
}

// ~~~~~~ Edit

const prefix = 'mt-ui-settings-edit'

// ~~~~~~ Fields Mutators/Reducers

export const UiSettingsEditFieldsMR = {
  section: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'sectionSelected',
    initModel.sectionSelected,
    ErrorType,
  ),
}

// ~~~~~~

export const SettingsModel = {
  initModel,
}
