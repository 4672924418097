import { ReactComponent as LoaderSVG } from '../../../imgs/svgs/icons/spinner.svg'
import { Colors } from '../../../style/theme/colors'

import { STLoader } from './style'

// ~~~~~~ Types

interface Props {
  size: number
  color?: ThemeColor
  paddingTop?: number
}

// ~~~~~~ Components

export const Loader: React.FC<Props> = ({ size, color, paddingTop }) => {
  return (
    <STLoader $size={size} $fill={color || Colors.WOLF_100_05} $paddingTop={paddingTop}>
      <LoaderSVG height={size} width={size} />
    </STLoader>
  )
}
