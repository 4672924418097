import { Config } from '../../../config'
import { PenColor } from '../../../constants/pen-color'
import { UtilsLog } from '../../../utils/logs'
import { genDrawingCommandManager } from '../command-manager/draw-partial-service'

// ~~~~~~ Types

type CommandManager = ReturnType<typeof genDrawingCommandManager>

type StateCM = {
  commandManager: CommandManager | undefined
}

type State = DN.Services.Draw.State & StateCM

// ~~~~~~ State

const InitState: State = {
  Color: PenColor.BLUE.color,
  HasAlpha: false,
  StrokeWidth: 3,
  IsErasing: false,
  commandManager: undefined,
} as const

// ~~~~~~ Mutators

// ####################################################################################################
// ~~~~~~ Set color
// ####################################################################################################

const setColor = (color: string) => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'state', 'setColor', { color })

  const newState: State = {
    ...DrawState.State,
    Color: color,
  } as const

  DrawState.State = newState
}

// ####################################################################################################
// ~~~~~~ Set has alpha
// ####################################################################################################

const setHasAlpha = (hasAlpha: boolean) => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'state', 'setHasAlpha', { hasAlpha })

  const newState: State = {
    ...DrawState.State,
    HasAlpha: hasAlpha,
  } as const

  DrawState.State = newState
}

// ####################################################################################################
// ~~~~~~ Set stroke width
// ####################################################################################################

const setStrokeWidth = (strokeWidth: number) => {
  Config.Logs.DrawService &&
    UtilsLog.devLog('\nDrawService', 'state', 'setStrokeWidth', { strokeWidth })

  const newState: State = {
    ...DrawState.State,
    StrokeWidth: strokeWidth,
  } as const

  DrawState.State = newState
}

// ####################################################################################################
// ~~~~~~ Set erasing state
// ####################################################################################################

const setIsErasing = (isErasing: boolean) => {
  Config.Logs.DrawService &&
    UtilsLog.devLog('\nDrawService', 'state', 'setIsErasing', { isErasing })

  const newState: State = {
    ...DrawState.State,
    IsErasing: isErasing,
  } as const

  DrawState.State = newState
}

// ####################################################################################################
// ~~~~~~ Set command manager
// ####################################################################################################

const setCommandManager = (commandManager: CommandManager) => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'state', 'setCommandManager')

  const newState: State = {
    ...DrawState.State,
    commandManager,
  } as const

  DrawState.State = newState
}

// ####################################################################################################
// ~~~~~~ Reset
// ####################################################################################################

const reset = () => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'reset')

  DrawState.State = InitState
}

// ~~~~~~

export const DrawState = {
  State: InitState,

  setColor,
  setHasAlpha,
  setStrokeWidth,
  setIsErasing,

  setCommandManager,
  reset,
} // We need to mutate this object changing the state, don't use "as const"
