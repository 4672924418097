const prefix = 'mt-paint-bcast-stream-draw-update-props-epic'

// ~~~~~~ Mutators Types

export const EpicPaintBcastStreamDrawUpdatePropsMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicPaintBcastStreamDrawUpdatePropsMC = {
  ok: (
    props: Pick<
      DN.Models.PaintBcastStream.Draw.Model,
      'colorKey' | 'hasAlpha' | 'strokeWidth' | 'activePen' | 'isErasing'
    >,
  ) => ({
    type: EpicPaintBcastStreamDrawUpdatePropsMT.OK,
    payload: props,
  }),
} as const
