import { useDispatch } from 'react-redux'
import { FeedbackEndBcastFieldsMR } from '../../../../models/bcast/feedback/end-bcast/model'
import { Trans } from '../../../common/intl/trans'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Content } from '../../compound-dialog/content/component'
import { ClassName, STDialogEndBcastFeedbackEmojis } from './style'

// ~~~~~~ Constants

const FaceBadIcon = LazySVG('icons/face-bad')

const FaceOkIcon = LazySVG('icons/face-ok')

const FaceHappyIcon = LazySVG('icons/face-happy')

// ~~~~~~ Props

type Props = {
  onClose: () => void
}

// ~~~~~~ Component

export const DialogEndBcastFeedbackEmojis: React.FC<Props> = ({ onClose }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ Handlers

  function onClickFace(face: 'bad' | 'ok' | 'happy') {
    dispatch(FeedbackEndBcastFieldsMR.happiness.MC.change(face))
  }

  function onClickSkip() {
    onClose()
  }

  // ~~~~~~ Render

  return (
    <Content>
      <STDialogEndBcastFeedbackEmojis>
        {/* Title */}

        <div className={`${ClassName}--title`}>
          <Trans id="dialogs.end-bcast-feedback.emojis.Title" />
        </div>

        {/* Faces */}

        <div className={`${ClassName}--faces-container`}>
          <div className={`${ClassName}--faces-container--faces`}>
            {/* Bad */}

            <div
              className={`${ClassName}--faces-container--faces--face`}
              onClick={() => onClickFace('bad')}
            >
              <FaceBadIcon size={64} />
            </div>

            {/* Ok */}

            <div
              className={`${ClassName}--faces-container--faces--face`}
              onClick={() => onClickFace('ok')}
            >
              <FaceOkIcon size={64} />
            </div>

            {/* Happy */}

            <div
              className={`${ClassName}--faces-container--faces--face`}
              onClick={() => onClickFace('happy')}
            >
              <FaceHappyIcon size={64} />
            </div>
          </div>
        </div>

        {/* Skip */}

        <div className={`${ClassName}--skip`} onClick={onClickSkip}>
          <Trans id="dialogs.end-bcast-feedback.emojis.Action" />
        </div>

        {/* -- */}
      </STDialogEndBcastFeedbackEmojis>
    </Content>
  )
}
