import { EpicApiServerPubMainMC } from '../../api-stream-manager/server/pub-main/mutators'

const prefix = 'mt-bcast-comm-channel-init-epic'

// ~~~~~~ Mutators Types

export const EpicBcastCommChannelInitMT = {
  OK: `${prefix}-ok`,
  OK_WITH_SERVER_PUB: `${prefix}-ok-with-pub-server`,
  EVENT: `${prefix}-event`,
  ERROR: `${prefix}-error`,

  // Websocket events
  WS_OPEN: `${prefix}-ws-open`,
  WS_PING_TIMEOUT: `${prefix}-ws-ping-timeout`,
  WS_CLOSE: `${prefix}-ws-close`,
  WS_ERROR: `${prefix}-ws-error`,
  WS_MESSAGE: `${prefix}-ws-message`,
} as const

// ~~~~~~ Mutators Creators

export const EpicBcastCommChannelInitMC = {
  ok: (webSocket: WebSocket) => ({
    type: EpicBcastCommChannelInitMT.OK,
    payload: {
      webSocket,
    },
  }),

  okWithServerPub: (
    webSocket: WebSocket,
    serverPubData: ReturnType<typeof EpicApiServerPubMainMC.ok>['payload'],
  ) => ({
    type: EpicBcastCommChannelInitMT.OK_WITH_SERVER_PUB,
    payload: {
      webSocket,
      serverPubData,
    },
  }),

  wsOpen: () => ({
    type: EpicBcastCommChannelInitMT.WS_OPEN,
  }),

  wsPingTimeout: () => ({
    type: EpicBcastCommChannelInitMT.WS_PING_TIMEOUT,
  }),

  wsClose: () => ({
    type: EpicBcastCommChannelInitMT.WS_CLOSE,
  }),

  wsError: () => ({
    type: EpicBcastCommChannelInitMT.WS_ERROR,
  }),

  wsMessage: (data: DN.Services.CommChannel.Messages.Internal.WSMessage['payload']['data']) => ({
    type: EpicBcastCommChannelInitMT.WS_MESSAGE,
    payload: data,
  }),
} as const
