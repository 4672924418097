import { AppInitState } from '../../../../../models/app/model'
import { GoogleSlidesCreateFieldsMR } from '../../../../../models/bcast/google-presentations/model'
import { UtilsStore } from '@dn/utils'
import { ApiBcastGoogleSlidesAT } from '../../../../actions/api-bcast/google-slides/actions'
import { EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT } from '../../../../epics/api-bcast/google-slides/create-presentation-and-add-image-slide/mutators'
import { EpicApiBcastGoogleSlidesCreatePresentationMT } from '../../../../epics/api-bcast/google-slides/create-presentation/mutators'

import {
  EpicApiBcastProvidersUnlinkGoogleMC,
  EpicApiBcastProvidersUnlinkGoogleMT,
} from '../../../../epics/api-bcast/providers/unlink-google/mutators'

const initState = AppInitState.googleSlidesCreatePresentation

type State = typeof initState

const reducer: any = {
  ...GoogleSlidesCreateFieldsMR.title.Reducer,
}

// ####################################################################################################
// ~~~~~~ Unlink Google - OK
// ####################################################################################################

reducer[EpicApiBcastProvidersUnlinkGoogleMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastProvidersUnlinkGoogleMC.ok>,
): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Google Slide Create - START
// ####################################################################################################

reducer[ApiBcastGoogleSlidesAT.CREATE_PRESENTATION] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Google Slide Create - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationMT.OK] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Google Slide Create - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationMT.ERROR] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ Google Slide Create and Add image to slide - START
// ####################################################################################################

reducer[ApiBcastGoogleSlidesAT.CREATE_PRESENTATION_AND_ADD_IMAGE_SLIDE] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Google Slide Create and Add image to slide - OK
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.OK] = (
  state: State,
): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Google Slide Create and Add image to slide - ERROR
// ####################################################################################################

reducer[EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.ERROR] = (
  state: State,
): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ~~~~~~

export const googleSlidesCreatePresentationReducer = UtilsStore.dynReducer(initState, reducer)
