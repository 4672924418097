import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'started-header-top-right-time-left'

// ~~~~~~ Component

export const STStartedHeaderTopRightTimeLeft = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;

  ${Fonts.Scale.Body}

  color: ${theme(Colors.WOLF_100_05)};

  padding: ${Layout.Spacing[2]}px;

  .${ClassName}--clock {
    ${Fonts.Weight[400]}
    ${Fonts.MonoFamily}
  }
`
