import { merge, Observable, of, catchError, filter, map, mergeMap, takeUntil } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'

import { StoreState } from '../../../../models/app/model'
import { BroadcastAC, BroadcastAT } from '../../../actions/bcast/actions'

import { BroadcastService } from '../../../../services/broadcast'
import { EpicBcastStartSubMainMC } from './mutators'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'
import { cancelAll$$ } from '../../../../subjects/cancel-all/subject'
import { Config } from '../../../../config'

type Action = ReturnType<typeof BroadcastAC.startSubMain>

const connectionFail$ = of(
  BroadcastAC.exit('conn-fail'),
  CancelNowAC.cancelAll([CancelReasons.ConnectionClosed]),
)

export const bcastStartSubMainEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(BroadcastAT.START_SUB_MAIN),

    mergeMap(({ payload }) => {
      const broadcastId = payload.subConfig.broadcastId

      const username = BroadcastService.genUser(broadcastId, 'sub')

      const finalUsername = `${Config.App.red5proUsernamePrefix}-${username}`

      return BroadcastService.genSubscription$(
        finalUsername,
        finalUsername,
        payload.subConfig,
        payload.iceServers,
      ).pipe(
        mergeMap(({ red5Sub, events$, allEvents$ }) => {
          const startSub$ = BroadcastService.startSub$(red5Sub, broadcastId).pipe(
            mergeMap(([red5Sub, closedByConnFail$]) => {
              //

              const specialClose$ = closedByConnFail$.pipe(
                takeUntil(cancelAll$$),
                mergeMap(() => {
                  return of(
                    BroadcastAC.exit('conn-fail'),
                    CancelNowAC.cancelAll([CancelReasons.ConnectionClosed]),
                  )
                }),
              )

              return merge(
                of(EpicBcastStartSubMainMC.ok(red5Sub, broadcastId, username)),
                specialClose$,
              )
            }),

            catchError(() => {
              return connectionFail$
            }),
          )

          // All events

          const event$ = allEvents$.pipe(
            map((evt) =>
              EpicBcastStartSubMainMC.event({ type: evt.type, data: (evt as any).data }),
            ),
          )

          // Close

          const close$ = events$.pipe(
            filter((evt) => evt === 'closed'),
            filter(() => !state$.value.streamManagerSubMain.paused),
            mergeMap(() =>
              of(
                BroadcastAC.exit('session-end'),
                CancelNowAC.cancelAll([CancelReasons.SessionEnded]),
              ),
            ),
          )

          // Fails

          const fail$ = events$.pipe(
            filter((evt) => evt !== 'closed'),
            mergeMap((evt) => {
              switch (evt) {
                case 'user-limit':
                  return of(
                    EpicBcastStartSubMainMC.error([
                      { id: 'service.broadcast.sub.DisallowedError' },
                    ]),
                    BroadcastAC.exit('user-limit'),
                    CancelNowAC.cancelAll([CancelReasons.UserLimitReached]),
                  )

                case 'invalid-name':
                  return of(
                    BroadcastAC.exit('invalid-name'),
                    CancelNowAC.cancelAll([CancelReasons.InvalidName]),
                  )

                // case 'websocket-fail':
                //   return of(
                //     BroadcastAC.exit('websoket-fail'),
                //     CancelNowAC.cancel([CancelReasons.WebsocketFail]),
                //   )

                default:
                  return connectionFail$
              }
            }),
          )

          return merge(startSub$, close$, fail$, event$)
        }),
        catchError(() => connectionFail$),
      )
    }),
  )
