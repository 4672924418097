import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { StreamContextFieldsMR } from '../../../../models/bcast/stream-context/model'
import {
  EpicApiBcastStreamContextCreateMC,
  EpicApiBcastStreamContextCreateMT,
} from '../../../epics/api-bcast/stream-context/create/mutators'
import {
  EpicApiBcastStreamContextShowMC,
  EpicApiBcastStreamContextShowMT,
} from '../../../epics/api-bcast/stream-context/show/mutators'

const initState = AppInitState.streamContext

type State = typeof initState

const reducer: any = {
  ...StreamContextFieldsMR.__model__.Reducer,
  ...StreamContextFieldsMR.id.Reducer,
}

// ####################################################################################################
// ~~~~~~ Create stream context
// ####################################################################################################

reducer[EpicApiBcastStreamContextCreateMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastStreamContextCreateMC.ok>,
): State => {
  return {
    ...state,
    id: payload.id,
    bcastId: payload.bcastId,
    kind: 'pub',
  }
}

// ####################################################################################################
// ~~~~~~ Get stream context
// ####################################################################################################

reducer[EpicApiBcastStreamContextShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastStreamContextShowMC.ok>,
): State => {
  return {
    ...state,
    id: payload.streamContextId,
    bcastId: payload.bcastId,
    kind: 'sub',
  }
}

// ~~~~~~

// Contexts are updated before any event that require it
// so we maintain the state in case we want to use it value
// for close events

export const streamContextReducer = UtilsStore.dynReducer(initState, reducer, (state) => state)
