import { useDispatch } from 'react-redux'
import { Button } from '../../../../../components/common/button/component'
import { ModalsIds } from '../../../../../constants/modals'
import { ModalsMC } from '../../../../../store/actions-mutators/modals/mutators'
import { STStartedHeaderTopRightEndBroadcast } from './style'

// ~~~~~~ Component

export const StartedTopRightEndBroadcast = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ Handlers

  function onClick() {
    dispatch(ModalsMC.open(ModalsIds.ConfirmStopBroadcasting))
  }

  // ~~~~~~ Render

  return (
    <STStartedHeaderTopRightEndBroadcast>
      <Button
        $colorType="error"
        intlId="pages.started.top.End"
        $size="s"
        disabled={false}
        $active={true}
        $running={false}
        onClick={onClick}
      />
    </STStartedHeaderTopRightEndBroadcast>
  )
}
