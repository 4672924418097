import { ofType, StateObservable } from 'redux-observable'
import { map, Observable } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { DrawService } from '../../../../../services/draw'
import {
  PaintBCastStreamDrawAC,
  PaintBCastStreamDrawAT,
} from '../../../../actions/paint-bcast-stream/draw/actions'
import { EpicPaintBcastStreamDrawStopMC } from './mutators'

type Action = ReturnType<typeof PaintBCastStreamDrawAC.stop>

export const paintBCastStreamDrawStopEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(PaintBCastStreamDrawAT.STOP),
    map(() => {
      DrawService.stop()

      return EpicPaintBcastStreamDrawStopMC.ok()
    }),
  )
