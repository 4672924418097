const prefix = 'mt-share-main-stream-get-stream-by-extension-epic'

// ~~~~~~ Mutators Types

export const EpicShareMainStreamGetStreamByExtensionMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~

export const EpicShareMainStreamGetStreamByExtensionMC = {
  ok: (stream: MediaStream, videoTrack: MediaStreamTrack) => ({
    type: EpicShareMainStreamGetStreamByExtensionMT.OK,
    payload: {
      stream,
      videoTrack,
    },
  }),

  error: (errors: Infos[]) => ({
    error: true,
    type: EpicShareMainStreamGetStreamByExtensionMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
