import { UtilsLog } from '../../../../utils/logs'

export const guardIsAck = (
  message: unknown,
): message is DN.Services.CommChannel.Messages.AckModelBase => {
  if (message instanceof Blob) return false

  if (typeof message !== 'object' || !message) return false

  if (!('type' in message) || !message.type) return false

  const ackType: DN.Services.CommChannel.Messages.AckModelBase['type'] = 'ack'

  if (message.type !== ackType) return false

  if (
    !('payload' in message) ||
    typeof message.payload !== 'object' ||
    message.payload === null ||
    //
    !('ackData' in message.payload) ||
    typeof message.payload.ackData !== 'object' ||
    message.payload.ackData === null ||
    //
    !('id' in message.payload.ackData)
  ) {
    UtilsLog.devWarn('Malformed ACK Message', message)

    return false
  }

  return true
}
