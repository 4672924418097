import { Routes as AppRoutes } from './routes'

const Routes = {
  SuccessFree: {
    Path: '/sign-in/create-account/free/complete',
    Title: 'Free account created',
  },

  SuccessPaid: {
    Path: '/sign-in/create-account/paid/complete',
    Title: 'Paid account created',
  },

  SuccessUpgraded: {
    Path: '/upgrade-account/complete',
    Title: 'Upgrade account complete',
  },

  Join: {
    Path: '/join',
    Title: 'Join a Broadcast',
  },

  Start: {
    Path: '/start',
    Title: 'Create a Broadcast',
  },

  SignIn: {
    Path: '/sign-in',
    Title: 'Sign into Broadcast',
  },

  Started: {
    Path: '/broadcasting',
    Title: 'Broadcasting',
  },

  Joined: {
    Path: '/viewing',
    Title: 'Viewing a Broadcast',
  },
} as const

const getPathTitle = (locationPathname: string) => {
  switch (locationPathname) {
    case Routes.Join.Path:
      return Routes.Join

    case Routes.Start.Path:
      return Routes.Start

    case Routes.SignIn.Path:
      return Routes.SignIn

    default:
      return { Path: '', Title: '' }
  }
}

type RouteKey = keyof typeof Routes

const isGTMRoutePath = (path: string): path is RouteKey => {
  const data = Routes[path as keyof typeof Routes]

  return !!data
}

const DirectRoutes: { [key: string]: RouteKey } = {
  [AppRoutes.Join]: 'Join',
  [AppRoutes.Start]: 'Start',
  [AppRoutes.SignIn]: 'SignIn',
} as const

// ~~~~~~

export const RoutesGTM = {
  Routes,
  getPathTitle,
  isGTMRoutePath,
  DirectRoutes,
}
