import { useRef } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { UiSettingsEditFieldsMR } from '../../../../models/bcast/ui-settings/model'
import { UserSettingsEditFieldsMR } from '../../../../models/bcast/user-settings/model'
import { UtilsMobile } from '@dn/utils'
import { Trans } from '../../intl/trans'
import { ClassName, STUserSettingsTemplate } from './style'

// ~~~~~~ Constants

const isMobile = UtilsMobile.calcIsMobile()

// ~~~~~~ Types

type Props = {
  title: IntlMsgId
  subtitle?: IntlMsgId
  children: React.ReactNode
}

// ~~~~~~ Component

export const UserSettingsTemplate: React.FC<Props> = ({ title, subtitle, children }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const selfRef = useRef<HTMLDivElement>(null)

  const uiSettings = useSelector((state: StoreState) => state.uiSettings)

  const userSettings = useSelector((state: StoreState) => state.userSettings)

  // ~~~~~~ Computed

  const sectionSelected = uiSettings.sectionSelected

  // ~~~~~~ Handlers

  function onClickSection(section: DN.Models.UiSettings.Section) {
    if (sectionSelected === section) return

    if (section === 'general') {
      dispatch(UserSettingsEditFieldsMR.__model__.MC.setModel(userSettings))
    }

    dispatch(UiSettingsEditFieldsMR.section.MC.change(section))
  }

  // ~~~~~~ Render

  return (
    <STUserSettingsTemplate $isMobile={isMobile} ref={selfRef}>
      {/* Header */}

      <div className={`${ClassName}--header`}>
        {/* Title */}

        <div className={`${ClassName}--header--title`}>
          {isMobile ? (
            <>
              <span>
                <Trans id="common-components.user-settings.Title" />
              </span>

              <span> / </span>
            </>
          ) : undefined}

          <span>
            <Trans id={title} />
          </span>
        </div>

        {/* Subtitle */}

        {!isMobile && subtitle ? (
          <div className={`${ClassName}--header--subtitle`}>
            <Trans id={subtitle} />
          </div>
        ) : undefined}
      </div>

      {/* Mobile tabs */}

      {isMobile ? (
        <Scrollbars
          className={`${ClassName}--scroller-tabs`}
          universal
          autoHide={true}
          style={{
            height: 100,
          }}
        >
          <div className={`${ClassName}--scroller-tabs--content`}>
            {/* Account */}

            <span
              className={`${ClassName}--scroller-tabs--content--section ${
                sectionSelected === 'account' ? 'active' : ''
              }`}
              onClick={() => onClickSection('account')}
            >
              <Trans id="common-components.user-settings.sections.account.Title" />
            </span>

            {/* Providers */}

            <span
              className={`${ClassName}--scroller-tabs--content--section ${
                sectionSelected === 'providers' ? 'active' : ''
              }`}
              onClick={() => onClickSection('providers')}
            >
              <Trans id="common-components.user-settings.sections.providers.Title" />
            </span>

            {/* General */}

            <span
              className={`${ClassName}--scroller-tabs--content--section ${
                sectionSelected === 'general' ? 'active' : ''
              }`}
              onClick={() => onClickSection('general')}
            >
              <Trans id="common-components.user-settings.sections.general.Title" />
            </span>
          </div>
        </Scrollbars>
      ) : undefined}

      <Scrollbars
        className={`${ClassName}--scroller-content`}
        universal
        autoHide={false}
        style={{
          height: `calc(100vh - 30px)`,
        }}
        renderTrackVertical={(props) => <div {...props} className="scrollbar-vertical" />}
      >
        {/* Content (children) */}

        <div className={`${ClassName}--scroller-content--content`}>{children}</div>

        {/* - */}
      </Scrollbars>
    </STUserSettingsTemplate>
  )
}
