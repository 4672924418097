import { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes as Switch } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import * as Sentry from '@sentry/react'
import { HooksEvents } from '@dn/hooks'
import { UtilsMobile } from '@dn/utils'

import { Routes } from '../constants/routes/routes'
import { useCheckAuth } from '../hooks/auth/use-check-auth'
import { CancelNowAC, CancelReasons } from '../store/actions/cancel-now/actions'
import { Config } from '../config'
import { DebugActions } from '../debug/actions/component'
import { DebugLang } from '../debug/lang/component'
import { DebugPage } from '../pages/debug/component'
import { JoiningToBroadcastPage } from '../pages/2-joining/component'
import { RedirectionsNoSubscriptionPage } from '../pages/redirections/no-subscription/component'
import { RedirectionsLoginWithRedirectPage } from '../pages/redirections/login-with-redirect-page/component'
import { StartingABroadcastPage } from '../pages/2-starting/component'
import { useBroadcastEnded } from '../hooks/broadcast-ended/use-broadcast-ended'
import { useNoLicenseLogout } from '../hooks/auth/use-no-license-logout'
import { RedirectionsBuySuccessPage } from '../pages/redirections/buy-success/component'
import { NewVersionAvailable } from './common/new-version-available/component'
import { useVideoSubCancelIfTooManyProgress } from '../hooks/video/use-video-cancel-if-too-many-progress'
import { ExtensionStartBcastPage } from '../pages/extension/extension-start-bcast/component'
import { ExtensionCreateAccountPage } from '../pages/extension/extension-create-account/component'
import { ExtensionUserSettingsPage } from '../pages/extension/extension-user-settings/component'
import { UserSettingsPage } from '../pages/user-settings/component'
import { PrivacyPolicyPage } from '../pages/privacy-terms-cookies/privacy/component'
import { TermsPage } from '../pages/privacy-terms-cookies/terms/component'
import { CookiesPage } from '../pages/privacy-terms-cookies/cookies/component'
import { JoinPage } from '../pages/1-join/component'
import { StartPage } from '../pages/1-start/component'
import { SignInPage } from '../pages/1.5-sign-in/component'
import { RootPage } from '../pages/0-root/component'
import { AppDialogs } from './app-dialogs'
import { StartedPage } from '../pages/3-started/component'
import { LayoutPanelsResizeObserver } from './layouts/panels/resize-observer/component'
import { JoinedPage } from '../pages/3-joined/component'
import { RealHeightSetter } from './common/real-height-setter/component'
import { OnboardingButton } from './common/onboarding/button/component'
import { OnboardingPanel } from './common/onboarding/panel/component'
import { OnboardingTooltips } from './common/onboarding/tooltips/component'
import { StoreState } from '../models/app/model'
import { NavigatorOnlineStatus } from './common/navigator-online-status/component'
import { UserHasInteracted } from './common/user-has-interacted/component'
import { SendStoredDuration } from './common/send-stored-duration/component'

// ~~~~~~ Constants

const SentrySwitch = Config.ThirdParty.Sentry.DSN
  ? Sentry.withSentryReactRouterV6Routing(Switch)
  : Switch

const isMobileOrTablet = UtilsMobile.calcIsMobileOrTablet()

// ~~~~~ Component

export const App = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  useCheckAuth()

  useNoLicenseLogout()

  useBroadcastEnded()

  const beforeUnloadAndHide = useCallback(() => {
    dispatch(CancelNowAC.cancelAll([CancelReasons.BeforeUnload]))
  }, [dispatch])

  HooksEvents.BeforeUnloadAndHide.useHook(beforeUnloadAndHide)

  // ~~~~~~ State

  // - Store

  const userId = useSelector((state: StoreState) => state.currentUser.id)

  // - Refs

  const videoSubRef = useRef<HTMLVideoElement>(null)

  // ~~~~~~ Dependent hooks

  useVideoSubCancelIfTooManyProgress(videoSubRef.current)

  // ~~~~~~ Render

  return (
    <>
      {/* Connection */}

      <NavigatorOnlineStatus />

      {/* User interaction */}

      <UserHasInteracted />

      {/* Debug change lang */}

      <DebugLang />

      {/* Real height */}

      <RealHeightSetter />

      {/* New version */}

      <NewVersionAvailable />

      {/* Send stored duration (analytics) */}

      <SendStoredDuration />

      {/* QA Debug */}

      <DebugActions />

      {/* Dialogs */}

      <AppDialogs />

      {/* Onboarding */}

      {!isMobileOrTablet && userId ? (
        <>
          <OnboardingButton />
          <OnboardingPanel />
          <OnboardingTooltips />
        </>
      ) : undefined}

      {/* Toast messages */}

      <Toaster
        position="top-center"
        toastOptions={{
          className: 'toast',
          style: {
            borderRadius: 4,
            overflow: 'hidden',
            padding: 0,
            margin: 0,
          },
        }}
      />

      {/* Layou panel resize observer */}

      <LayoutPanelsResizeObserver />

      {/*
        red5pro sadly doesn't have an option to prevent it from tampering with the DOM
        We use use this video tags hidden to make the lib let us work
       */}

      <video style={{ display: 'none' }} id="main-stream-pub" />
      <video ref={videoSubRef} style={{ display: 'none' }} id="main-stream-sub" />
      <video style={{ display: 'none' }} id="cam-mic-stream-pub" />
      <video style={{ display: 'none' }} id="cam-mic-stream-sub" />

      {/* Routes */}

      <SentrySwitch>
        {/* Join or Start: Main page */}

        <Route path={Routes.Main} element={<RootPage />} />

        {/* Sign in */}

        <Route path={Routes.SignIn} element={<SignInPage />} />

        {/* Viewer */}

        {/* - Join */}

        <Route path={Routes.Join} element={<JoinPage />} />

        {/* - Joining to Broadcast */}

        <Route path={Routes.Joining} element={<JoiningToBroadcastPage />} />

        {/* - Joined: See a broadcast */}

        <Route path={Routes.Joined} element={<JoinedPage />} />

        {/* Broadcaster */}

        {/* - Start */}

        <Route path={Routes.Start} element={<StartPage />} />

        {/* - Starting a Broadcast */}

        <Route path={Routes.Starting} element={<StartingABroadcastPage />} />

        {/* - Started: Broadcast emision */}

        <Route path={Routes.Started} element={<StartedPage />} />

        {/* Extension */}

        {/* - Extension: Start broadcast from extension */}

        <Route path={Routes.ExtensionStartBcast} element={<ExtensionStartBcastPage />} />

        {/* - Extension: Create Account */}

        <Route path={Routes.ExtensionCreateAccount} element={<ExtensionCreateAccountPage />} />

        {/* - Extension: User settings */}

        <Route path={Routes.ExtensionUserSettings} element={<ExtensionUserSettingsPage />} />

        {/* User settings */}

        <Route path={Routes.UserSettings} element={<UserSettingsPage />} />

        {/* Privacy-Terms-Cookies */}

        {/* - Privacy */}

        <Route path={Routes.Privacy} element={<PrivacyPolicyPage />} />

        {/* - Terms */}

        <Route path={Routes.Terms} element={<TermsPage />} />

        {/* - Cookies */}

        <Route path={Routes.Cookies} element={<CookiesPage />} />

        {/* Redirects */}

        {/* - Buy success */}

        <Route path={Routes.BuySuccess} element={<RedirectionsBuySuccessPage />} />

        {/* - Redirect with login */}

        <Route path={Routes.RedirectWithLogin} element={<RedirectionsLoginWithRedirectPage />} />

        {/* - Logged but no subscription exists */}

        <Route path={Routes.NoSubscription} element={<RedirectionsNoSubscriptionPage />} />

        {/* - Set Debugger */}

        <Route path={Routes.Debug} element={<DebugPage />} />

        {/* - Unknown routes */}

        <Route path="*" element={<Navigate replace to={Routes.Main} />} />

        {/* - */}
      </SentrySwitch>
    </>
  )
}
