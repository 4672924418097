import { Observable } from 'rxjs'
import { Config } from '../../../../../config'

// ~~~~~~ Constants

const message: DN.Services.Extension.ToExtension.AppInfo = {
  type: 'app-info',
}

// ~~~~~~

export const sendMessageToExtensionAppInfo$ = () => {
  const obs$ = new Observable<DN.Services.Extension.FromExtension.AppInfo['payload']>(
    (observer) => {
      if (
        Config.Extension.Id === 'no-extension-id' ||
        !window.chrome ||
        !window.chrome.runtime ||
        !window.chrome.runtime.sendMessage
      ) {
        observer.error({ installed: false, extensionId: Config.Extension.Id })
        return
      }

      const callback = (res?: DN.Services.Extension.FromExtension.AppInfo) => {
        if (!res) {
          observer.error({ response: false })
          return
        }

        observer.next(res.payload)
        observer.complete()
      }

      chrome.runtime.sendMessage(Config.Extension.Id, message, callback)
    },
  )

  return obs$
}
