import { useCallback, useState } from 'react'
import { DevicePIPEnabled } from '../../../../../../../constants/misc'
import { UtilsFullscreen } from '@dn/utils'
import { JoinedTabletContentMainToolbarsMainButtonsCapturePaint } from './buttons/capture-paint/component'
import { JoinedTabletContentMainToolbarsMainButtonsCaptureShare } from './buttons/capture-share/component'
import { JoinedTabletMainToolbarsMainButtonsFullscreen } from './buttons/fullscreen/component'
import { JoinedTabletContentMainToolbarsMainButtonsPIP } from './buttons/pip/component'
import { JoinedTabletContentMainToolbarsMainButtonsZoom } from './buttons/zoom/component'
import { ClassName, STJoinedTabletContentMainToolbarsMain } from './style'

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const JoinedTabletContentMainToolbarsMain: React.FC<Props> = ({ video }) => {
  // ~~~~~~ State

  const [, setUpdate] = useState(performance.now())

  // ~~~~~~ Callbacks

  // We need this in order to sync pip and fullscreen icons
  // we can do it better using the store instead

  const onUpdate = useCallback(() => {
    setUpdate(performance.now())
  }, [])

  // ~~~~~~ Render

  return (
    <STJoinedTabletContentMainToolbarsMain>
      {/* Capture Share */}

      <div className={`${ClassName}--icon-btn`}>
        <JoinedTabletContentMainToolbarsMainButtonsCaptureShare video={video} />
      </div>

      {/* Capture Paint */}

      <div className={`${ClassName}--icon-btn`}>
        <JoinedTabletContentMainToolbarsMainButtonsCapturePaint video={video} />
      </div>

      {/* Zoom */}

      <div className={`${ClassName}--zoom`}>
        <JoinedTabletContentMainToolbarsMainButtonsZoom />
      </div>

      {/* PIP */}

      {DevicePIPEnabled ? (
        <div className={`${ClassName}--icon-btn`}>
          <JoinedTabletContentMainToolbarsMainButtonsPIP video={video} onUpdate={onUpdate} />
        </div>
      ) : undefined}

      {/* Full screen */}

      {UtilsFullscreen.fullscreenIsSupported ? (
        <div className={`${ClassName}--icon-btn`}>
          <JoinedTabletMainToolbarsMainButtonsFullscreen onUpdate={onUpdate} />
        </div>
      ) : undefined}

      {/* -- */}
    </STJoinedTabletContentMainToolbarsMain>
  )
}
