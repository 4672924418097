const prefix = 'at-paint-bcast-stream-draw'

// ~~~~~ Action types

export const PaintBCastStreamDrawAT = {
  START: `${prefix}-start`,
  UPDATE_PROPS: `${prefix}-update-props`,
  UPDATE_VIEW: `${prefix}-update-view`,

  CMD_UNDO: `${prefix}-cmd-undo`,
  CMD_REDO: `${prefix}-cmd-redo`,
  CMD_CLEAR: `${prefix}-cmd-clear`,

  STOP: `${prefix}-stop`,
} as const

// ~~~~~~ Action Creators

export const PaintBCastStreamDrawAC = {
  start: (canvas: HTMLCanvasElement, width: number, height: number) => ({
    type: PaintBCastStreamDrawAT.START,
    payload: {
      canvas,
      width,
      height,
    },
  }),

  updateProps: (patch: Partial<Omit<DN.Models.PaintBcastStream.Draw.Model, 'scope'>>) => ({
    type: PaintBCastStreamDrawAT.UPDATE_PROPS,
    payload: patch,
  }),

  updateView: (width: number, height: number) => ({
    type: PaintBCastStreamDrawAT.UPDATE_VIEW,
    payload: {
      width,
      height,
    },
  }),

  cmdUndo: () => ({
    type: PaintBCastStreamDrawAT.CMD_UNDO,
  }),

  cmdRedo: () => ({
    type: PaintBCastStreamDrawAT.CMD_REDO,
  }),

  cmdClear: () => ({
    type: PaintBCastStreamDrawAT.CMD_CLEAR,
  }),

  stop: () => ({
    type: PaintBCastStreamDrawAT.STOP,
  }),
} as const
