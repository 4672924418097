import { JoinedDesktopContentMain } from './main/component'
import { ClassName, STJoinedDesktopContent } from './style'

// ~~~~~~ Component

export const JoinedDesktopContent = () => {
  // ~~~~~~ Render

  return (
    <STJoinedDesktopContent>
      {/* Main content (video + buttons) */}

      <div className={`${ClassName}--main`}>
        <JoinedDesktopContentMain />
      </div>

      {/* - */}
    </STJoinedDesktopContent>
  )
}
