import { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { fromEvent } from 'rxjs'
import { ConstEventsPIP } from '@dn/constants'
import { DevicePIPEnabled } from '../../../../../../../../../constants/misc'
import { StoreState } from '../../../../../../../../../models/app/model'
import { UtilsFullscreen } from '@dn/utils'
import { useTrackStreamContext } from '../../../../../../../../../hooks/track-events/use-track-stream-context'
import { BcastTrackEvents } from '../../../../../../../../../services/track-events'

// ~~~~~~ Helpers

const isPipOn = () => !!document.pictureInPictureElement

// ~~~~~~ Hook

export const useJoinedBehaviourLogicContentMainToolbarsMainButtonsPIP = (
  video: HTMLVideoElement | null,
  onUpdate: () => void,
) => {
  // ~~~~~~ Hooks

  const streamContext = useTrackStreamContext()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const lang = useSelector((state: StoreState) => state.intl.lang)

  // - Refs

  const fullscreenRef = useRef<HTMLElement | null>(null)

  const tooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Specials

  fullscreenRef.current = document.getElementById('app')

  // ~~~~~~ Computed

  const tooltipTop = tooltipRef.current ? -tooltipRef.current.clientHeight - 8 : -34

  // ~~~~~~ Handlers

  function enterPIPMode() {
    if (!DevicePIPEnabled || !video || !video.requestPictureInPicture) return

    // First exit fullscreen

    UtilsFullscreen.exitFullScreenP().finally(() =>
      video.requestPictureInPicture().finally(() => {
        onUpdate()

        setTimeout(() => {
          setUpdate(performance.now())

          // Track

          BcastTrackEvents.calls.Pip.viewer(streamContext)
        }, 10)
      }),
    )
  }

  function exitPIPMode() {
    if (!isPipOn()) return

    document
      .exitPictureInPicture()
      .catch(() => undefined)
      .finally(() => {
        onUpdate()

        setTimeout(() => {
          setUpdate(performance.now())
        }, 10)
      })
  }

  function onClick() {
    isPipOn() ? exitPIPMode() : enterPIPMode()
  }

  // ~~~~~~ Effects

  // - Observe leave pip event (close button in external pip window)

  useEffect(() => {
    if (!video) return

    const sub = fromEvent(video, ConstEventsPIP.LeavePip).subscribe({
      next: () => {
        onUpdate()
        setUpdate(performance.now())
      },
    })

    return () => {
      sub.unsubscribe()
    }

    //
  }, [onUpdate, video])

  // ~~~~~~ Effects

  // - Force update on mount to calculate tooltip position

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // ~~~~~~

  return {
    tooltipRef,
    tooltipTop,
    lang,

    isPipOn,
    onClick,
  } as const
}
