import { getThemeValue } from '../utils/theme'
import { HexAlpha } from './colors/alpha'

const ShadowColor: ThemeColor = {
  light: '#999A9B',
  dark: '#0F1011',
}

export const Misc = {
  BoxShadow: (props: any) => `
    box-shadow: 0 4px 4px ${getThemeValue(props, ShadowColor)}${HexAlpha[24]};
  `,
}
