import { HooksRender } from '@dn/hooks'
import { useRef } from 'react'

// ~~~~~~ Hook

export const useJoinedBehaviourLogicLeftPanelSharePaintSlidesScrollbarHeight = (
  panelElementRef: React.RefObject<HTMLDivElement>,
  topElementRef: React.RefObject<HTMLDivElement>,
  exitElementRef: React.RefObject<HTMLDivElement>,

  panelMargin: number,
  Padding: number,
  ItemHeight: number,
) => {
  // ~~~~~~ Hooks

  const forceRerender = HooksRender.ForceRender.useHook()

  HooksRender.ForceRenderOnResizeWindow.useHook()

  HooksRender.ForceRenderOnResizeElement.useHook(topElementRef)

  // ~~~~~~ State

  // - Refs

  const titleRef = useRef<HTMLDivElement>(null)

  const slidesSelectInfoRef = useRef<HTMLDivElement>(null)

  const selectActionRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Computed

  // - Scrollbar height calc

  const panelElHeight = panelElementRef.current?.clientHeight

  const topElHeight = topElementRef.current?.clientHeight

  const exitElementHeight = exitElementRef.current?.clientHeight

  const titleHeight = titleRef.current?.clientHeight

  const slidesSelectInfoHeight = slidesSelectInfoRef.current?.clientHeight

  const selectHeight = selectActionRef.current?.clientHeight || 0

  const minScrollbarHeight = ItemHeight + ItemHeight / 2

  const scrollbarHeight =
    panelElHeight && topElHeight && exitElementHeight && titleHeight && slidesSelectInfoHeight
      ? panelElHeight -
        panelMargin -
        topElHeight -
        exitElementHeight -
        slidesSelectInfoHeight -
        titleHeight -
        selectHeight -
        Padding * 2
      : minScrollbarHeight

  return {
    refs: {
      titleRef,
      slidesSelectInfoRef,
      selectActionRef,
    },

    scrollbarHeight,

    recalculateScrollbarHeight: forceRerender,
  } as const
}
