import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { ClassName, STJoinedMobileToolbarsCapturePaintButtonsRedo } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/redo')

// ~~~~~~ Types

type Props = {
  onClick: () => void
}

// ~~~~~~ Component

export const JoinedMobileToolbarsCapturePaintButtonsRedo: React.FC<Props> = ({ onClick }) => {
  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsCapturePaintButtonsRedo onClick={onClick}>
      {/* Icon */}

      <Icon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsCapturePaintButtonsRedo>
  )
}
