import { css } from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const CommonClassName = 'onboarding-tooltips-common-style'

// ~~~~~~ CSS

export const STOnboardingTooltipsCommonStyle = css`
  position: absolute;
  z-index: 50;

  background-color: ${theme(Colors.EMMET_50_80)};
  padding: 12px;

  ${Layout.Border.Radius.A}

  color: ${theme(Colors.WOLF_00_10)};

  .${CommonClassName}--content {
    position: relative;

    .${CommonClassName}--content--title {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.BodyBig}

      margin-bottom: 12px;
    }

    .${CommonClassName}--content--text {
      ${Fonts.Weight[500]}
      ${Fonts.Scale.Body}

      margin-bottom: 12px;

      &.last {
        margin-bottom: 0;
      }

      a.${CommonClassName}--content--link {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.Body}

        color: ${theme(Colors.WOLF_00_10)};
      }
    }

    .${CommonClassName}--content--skip {
      display: flex;
      justify-content: flex-end;
    }

    /* Triangles */

    .${CommonClassName}--triangle {
      position: absolute;

      left: 50%;
      transform: translate(-50%, 0) rotate(45deg);

      bottom: -20px;

      background-color: ${theme(Colors.EMMET_50_80)};

      width: 16px;
      height: 16px;
    }

    .${CommonClassName}--left-triangle {
      position: absolute;

      top: 20%;
      left: -20px;
      transform: translate(0, -20%) rotate(45deg);

      background-color: ${theme(Colors.EMMET_50_80)};

      width: 16px;
      height: 16px;
    }
  }
`
