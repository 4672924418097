import styled from 'styled-components'
import { Transition } from '../../../../../../style/animations/css-animations'
import { Colors } from '../../../../../../style/theme/colors'
import { Fonts } from '../../../../../../style/theme/fonts'
import { Layout } from '../../../../../../style/theme/layout'
import { theme } from '../../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-float-panel-capture-share-slides'

export const Padding = 12

export const ItemHeight = 48

// ~~~~~~ Component

export const STJoinedMobileFloatPanelCaptureShareSlides = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;

  .${ClassName}--slides {
    flex: 1;

    ${Layout.Border.Radius.A}

    .${ClassName}--title-slider {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.BodyBig}

      color: ${theme(Colors.WOLF_95_05)};

      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .${ClassName}--closed-info {
      /* padding:
      Should be top because the component is not alwawys renderer.
      If we have padding-bottom in previous component, a not wanted space will be here
      when the component is not renderer
      */

      padding-top: ${Layout.Spacing[2]}px;

      ${Fonts.Weight[500]}
      ${Fonts.Scale.Body}
    }

    /* Already shared */

    .${ClassName}--already-shared {
      /* padding:
      Should be top because the component is not alwawys renderer.
      If we have padding-bottom in previous component, a not wanted space will be here
      when the component is not renderer
      */

      padding-top: ${Layout.Spacing[4]}px;

      .${ClassName}--already-shared--info {
        ${Fonts.Scale.Body}

        color: ${theme(Colors.WOLF_100_05)};
      }

      .${ClassName}--already-shared--selected {
        display: flex;
        align-items: center;

        height: ${ItemHeight}px;

        padding-bottom: ${Layout.Spacing[4]}px;

        .${ClassName}--already-shared--selected--icon {
          display: flex;

          svg {
            width: 16px;
            height: 16px;

            margin-right: ${Layout.Spacing[2]}px;

            path.svg-main {
              ${Transition.StrokeFaster};

              stroke: none;
              fill: ${theme(Colors.EMMET_50_40)};
            }
          }
        }

        .${ClassName}--already-shared--selected--name {
          color: ${theme(Colors.EMMET_50_40)};
        }
      }

      .${ClassName}--already-shared--action {
      }
    }

    /* Create slide */

    .${ClassName}--create {
      /* padding:
      Should be top because the component is not alwawys renderer.
      If we have padding-bottom in previous component, a not wanted space will be here
      when the component is not renderer
      */

      padding-top: ${Layout.Spacing[4]}px;

      .${ClassName}--create--info {
        ${Fonts.Scale.Body}

        color: ${theme(Colors.WOLF_80_20)};

        text-align: center;

        padding-bottom: ${Layout.Spacing[4]}px;
      }

      .${ClassName}--create--input {
        padding-bottom: ${Layout.Spacing[4]}px;
      }

      .${ClassName}--create--action {
        padding-bottom: ${Layout.Spacing[4]}px;
      }

      .${ClassName}--create--nav {
        cursor: pointer;

        ${Fonts.Scale.BodyBig}

        ${Transition.ColorFaster}

      color: ${theme(Colors.WOLF_100_05)};

        text-align: center;

        padding-bottom: ${Layout.Spacing[3]}px;

        &:hover {
          color: ${theme(Colors.WOLF_80_20)};
        }

        &:active {
          color: ${theme(Colors.WOLF_100_05)};
        }
      }
    }

    /* List */

    .${ClassName}--slides-select {
      .${ClassName}--slides-select--info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding-top: 16px;
        padding-bottom: 16px;

        .${ClassName}--slides-select--info--quantity {
          ${Fonts.Weight[500]}
          ${Fonts.Scale.Body}

        color: ${theme(Colors.WOLF_100_05)};

          display: flex;
          align-items: center;

          .${ClassName}--slides-select--info--quantity--data {
            margin-left: ${Layout.Spacing[1]}px;
            padding: 4px;
            border-radius: 50%;

            min-width: 20px;
            text-align: center;

            background-color: ${theme(Colors.WOLF_10_80)};
          }
        }

        .${ClassName}--slides-select--info--nav {
          cursor: pointer;

          ${Fonts.Weight[500]}
          ${Fonts.Scale.Body}

        ${Transition.ColorFaster}

        color: ${theme(Colors.WOLF_100_05)};

          &:hover {
            color: ${theme(Colors.WOLF_80_20)};
          }

          &:active {
            color: ${theme(Colors.WOLF_100_05)};
          }
        }
      }

      .${ClassName}--slides-select--list {
        padding-bottom: 24px;

        /* Empty */

        .${ClassName}--slides-select--list--empty {
          display: flex;
          flex-direction: column;
          align-items: center;

          .${ClassName}--slides-select--list--empty--icon {
            padding: 24px;

            svg {
              width: 76px;
              height: 76px;

              path.svg-main {
                fill: ${theme(Colors.WOLF_100_05)};
                stroke: none;
              }
            }
          }

          .${ClassName}--slides-select--list--empty--text {
            ${Fonts.Scale.Body}

            text-align: center;

            color: ${theme(Colors.WOLF_80_20)};
          }
        }

        /* NOT Empty */

        .${ClassName}--slides-select--list--item {
          cursor: pointer;

          ${Fonts.Weight[500]}
          ${Fonts.Scale.BodyBig}

          height: ${ItemHeight}px;

          ${Transition.ColorFaster};

          color: ${theme(Colors.WOLF_100_05)};

          /* margin-right: 12px; */

          border-bottom: solid 1px ${theme(Colors.WOLF_10_80)};

          display: flex;
          align-items: center;

          &.last {
            border-bottom: solid 1px transparent;
          }

          &:hover {
            color: ${theme(Colors.WOLF_80_20)};

            .${ClassName}--slides-select--list--item--icon {
              svg path.svg-main {
                fill: ${theme(Colors.WOLF_80_20)};
                stroke: none;
              }
            }
          }

          &:active {
            color: ${theme(Colors.WOLF_100_05)};

            .${ClassName}--slides-select--list--item--icon {
              svg path.svg-main {
                fill: ${theme(Colors.WOLF_100_05)};
                stroke: none;
              }
            }
          }

          &.selected {
            color: ${theme(Colors.EMMET_50_30)};

            .${ClassName}--slides-select--list--item--icon {
              svg path.svg-main {
                fill: ${theme(Colors.EMMET_50_30)};
                stroke: none;
              }
            }
          }

          .${ClassName}--slides-select--list--item--icon {
            display: flex;

            svg {
              width: 16px;
              height: 16px;

              margin-right: ${Layout.Spacing[2]}px;

              path {
                ${Transition.StrokeFaster};

                fill: ${theme(Colors.WOLF_100_05)};
                stroke: none;
              }
            }
          }
        }
      }
    }
  }

  .${ClassName}--close {
    padding-top: ${Layout.Spacing[2]}px;
  }
`
