import { DeprecatedCamMicPub } from '../../../../components/common/cam-mic/pub/deprecated/component'
import { ClassName, STStartedContentMain } from './style'
import { StartedContentMainButtonsLivePaint } from './toolbars/live-paint/component'
import { StartedContentMainLivePaint } from './live-paint/component'
import { StartedContentMainLoadingVideo } from './loading-video/component'
import { StartedContentMainPicOverVideo } from './pic-over-video/component'
import { StartedContentMainToolbarsMain } from './toolbars/main/component'
import { StartedContentMainVideoBadge } from './video-badge/component'
import { StartedContentMirrorEffectLayer } from './mirror-effect-layer/component'
import { useStartedContentMaintLogic } from './logic'
import { Config } from '../../../../config'
import { CamMicPub } from '../../../../components/common/cam-mic/pub/component'

// ~~~~~~ Component

export const StartedContentMain = () => {
  // ~~~~~~ Hooks

  const logic = useStartedContentMaintLogic()

  // ~~~~~~ Render

  return (
    <STStartedContentMain ref={logic.selfRef}>
      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Video wrapper  */}

        <div className={`${ClassName}--content--video-wrapper`} ref={logic.videoWrapperRef}>
          {/* Video Badge */}

          {logic.videoIsPlaying ? <StartedContentMainVideoBadge /> : undefined}

          {/* Camera and Microphone */}

          {Config.Features.BcastService2 ? (
            <CamMicPub
              className={`${ClassName}--content--video-wrapper--cam-mic ${
                logic.localCamStream ? '' : 'hidden'
              }`}
            />
          ) : (
            <DeprecatedCamMicPub
              className={`${ClassName}--content--video-wrapper--cam-mic ${
                logic.curCamMicStream ? '' : 'hidden'
              }`}
            />
          )}

          {/* Mirror effect */}

          <StartedContentMirrorEffectLayer showLayer={logic.showMirrorEffectLayer} />

          {/* Live paint */}

          {logic.livePaintEnabled ? <StartedContentMainLivePaint /> : undefined}

          {/* Pic over video when switching the source */}

          <StartedContentMainPicOverVideo
            videoRef={logic.videoRef}
            containerRef={logic.videoWrapperRef}
          />

          {/* Loading video... */}

          {!logic.videoIsPlaying ? <StartedContentMainLoadingVideo /> : undefined}

          {/* Video */}

          <video
            ref={logic.videoRef}
            className={`${ClassName}--content--video-wrapper--video`}
            autoPlay
            playsInline
            muted
            style={{
              zIndex: 0,
              top: 0,
              left: 0,
            }}
          />
        </div>

        {/* Tools */}

        <div className={`${ClassName}--content--tools`}>
          {/* Main */}

          {logic.videoIsPlaying && !logic.livePaintEnabled ? (
            <StartedContentMainToolbarsMain video={logic.videoRef.current} />
          ) : undefined}

          {/* Paint */}

          {logic.videoIsPlaying && logic.livePaintEnabled ? (
            <StartedContentMainButtonsLivePaint />
          ) : undefined}

          {/* -- */}
        </div>
      </div>
    </STStartedContentMain>
  )
}
