import { fromEvent } from 'rxjs'
import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'
import { actionEmitter } from '../dispatcher/extension-partial-service'

// ~~~~~ Types

type ExtensionMessage = {
  type: string
  payload: any
  meta: {
    from: 'content'
    msgId: string
    extensionId: string
  }
}

// ~~~~~ Guards

const messageIsExtensionMessage = (message: any): message is ExtensionMessage => {
  if (typeof message !== 'object') return false

  if (!('type' in message)) return false

  if (!('meta' in message) || typeof message.meta !== 'object') return false

  if (message.meta.extensionId !== Config.Extension.Id) return false

  if (message.meta.from !== 'content') return false

  if (typeof message.meta.msgId !== 'string') return false

  return true
}

// ~~~~~

export const startListening = () => {
  const sub = fromEvent<MessageEvent>(window, 'message').subscribe({
    next: (evt) => {
      if (!evt.source || evt.origin !== window.origin) return

      if (!evt.data || !(typeof evt.data === 'object')) return

      const message = evt.data

      if (!messageIsExtensionMessage(message)) return

      UtilsLog.devLog('[app]', 'onMessage', message)

      actionEmitter(evt.source, message)
    },
  })

  return sub
}
