import styled from 'styled-components'
import { MediaQuery } from '../../../style/constants/media-queries'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { getThemeValue } from '../../../style/utils/theme'

type STProps = {
  $forceTheme?: Theme
}

export const STFooter = styled.div<STProps>`
  ${Fonts.Scale.Body}

  color: ${(props) =>
    props.$forceTheme
      ? props.$forceTheme === 'light'
        ? getThemeValue(props, Colors.WOLF_30_30)
        : getThemeValue(props, Colors.WOLF_50_50)
      : getThemeValue(props, Colors.WOLF_30_50)};

  a {
    text-decoration: underline;

    color: ${(props) =>
      props.$forceTheme
        ? props.$forceTheme === 'light'
          ? getThemeValue(props, Colors.WOLF_30_30)
          : getThemeValue(props, Colors.WOLF_50_50)
        : getThemeValue(props, Colors.WOLF_30_50)};

    &.support {
      text-decoration: none;
    }
  }

  padding: 0 ${Layout.Spacing[9]}px;

  @media (max-width: ${MediaQuery.MD}) {
    text-align: center;
    padding: 0 ${Layout.Spacing[5]}px;
  }

  @media (max-width: ${MediaQuery.SM}) {
    ${Fonts.Scale.Label}

    padding: 0 ${Layout.Spacing[3]}px;
  }
`
