import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'

export const STDialogSafariChangeSource = styled.div`
  .line {
    ${Fonts.Scale.Body}
    text-align: center;

    color: ${theme(Colors.WOLF_80_20)};

    margin-bottom: ${Layout.Spacing[3]}px;
  }
`
