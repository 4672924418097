import { Trans } from '../../../../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsFullscreen } from '../../../../../../../behaviour-logic/content/main/toolbars/main/buttons/fullscreen/hook'
import { ClassName, STJoinedDesktopContentMainToolbarsMainButtonsFullscreen } from './style'

// ~~~~~~ Constants

const FullscreenIsOffIcon = LazySVG('icons/fullscreen')
const FullscreenIsOnIcon = LazySVG('icons/compress')

// ~~~~~~ Types

type Props = {
  onUpdate: () => void
}

// ~~~~~~ Component

export const JoinedDesktopMainToolbarsMainButtonsFullscreen: React.FC<Props> = ({ onUpdate }) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsFullscreen(onUpdate)

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainToolbarsMainButtonsFullscreen
      className="with-tooltip"
      onClick={logic.onClick}
    >
      {/* Icon */}

      {logic.isInFullScreen() ? (
        <FullscreenIsOnIcon className={`${ClassName}--icon-on`} size={24} />
      ) : (
        <FullscreenIsOffIcon className={`${ClassName}--icon-off`} size={24} />
      )}

      {/* Tooltip */}

      <span
        ref={logic.tooltipRef}
        className="tooltiptext"
        style={{
          top: logic.tooltipTop,
        }}
      >
        {logic.isInFullScreen() ? (
          <Trans id="pages.joined.main.toolbars.main.tooltips.fullscreen.Exit" />
        ) : (
          <Trans id="pages.joined.main.toolbars.main.tooltips.fullscreen.Enter" />
        )}
      </span>

      {/* -- */}
    </STJoinedDesktopContentMainToolbarsMainButtonsFullscreen>
  )
}
