import { Button, Menu, MenuItem, Wrapper } from 'react-aria-menubutton'
import { useDispatch } from 'react-redux'
import { UiOnboardingFieldsMR } from '../../../../models/bcast/ui-onboarding/model'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Trans } from '../../intl/trans'
import { Loader } from '../../loader/component'
import { useMenuLogic } from '../hook'
import { ClassName, STUserMenuDesktopTablet } from './style'

import ImageRocket from '../../../../imgs/webp/onboard-title-icon.webp'

// ~~~~~~ Constants

const IconSize = 24

const MenuIcon = LazySVG('icons/menu')

const UserIcon = LazySVG('icons/user')

const ManageAccountIcon = LazySVG('icons/gear')

const SupportIcon = LazySVG('icons/help')

const SettingsIcon = LazySVG('icons/settings')

const SignOutIcon = LazySVG('icons/sign-out-alt')

// ~~~~~~ Component

export const UserMenuDesktopTablet = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const {
    currentUserEmail,

    showOnboarding,
    resetOnboardingIsRunning,

    showMenuLoader,
    signOutWithoutModal,
    locationIsSettings,

    onSelection,
    opClickOnboarding,
  } = useMenuLogic()

  // ~~~~~~ Render

  return (
    <STUserMenuDesktopTablet data-testid="user-menu" $isOverBlack={locationIsSettings}>
      <Wrapper
        id="user-menu-desktop"
        onSelection={onSelection}
        onMenuToggle={({ isOpen }) => {
          if (!isOpen) return

          dispatch(UiOnboardingFieldsMR.panelState.MC.change('hidden'))
        }}
      >
        {/* Menu icon */}

        <Button data-testid="toggle-menu-act" tag="div">
          <div className={`${ClassName}--user-menu-btn`}>
            <div className={`${ClassName}--user-menu-btn--icon`}>
              {showMenuLoader ? <Loader size={IconSize} /> : <MenuIcon size={IconSize} />}
            </div>
          </div>
        </Button>

        {/* Menu */}

        <div className={`${ClassName}--dropdown`}>
          <Menu>
            <div className={`${ClassName}--dropdown--content`}>
              {/* Header */}

              <div className={`${ClassName}--dropdown--content--header`}>
                {/* Title */}

                <div className={`${ClassName}--dropdown--content--header--title`}>
                  <div className={`${ClassName}--dropdown--content--header--title--icon`}>
                    <UserIcon size={16} />
                  </div>

                  <div className={`${ClassName}--dropdown--content--header--title--text`}>
                    <span>&nbsp;</span>
                    <span>
                      <Trans id="user-menu.LoggedInAs" />
                    </span>
                  </div>
                </div>

                {/* Email */}

                <div
                  data-testid="email"
                  className={`${ClassName}--dropdown--content--header--email`}
                >
                  {currentUserEmail}
                </div>
              </div>

              {/* Reset onboarding */}

              {showOnboarding ? (
                <div
                  className={`${ClassName}--dropdown--content--onboarding`}
                  onClick={opClickOnboarding}
                >
                  {resetOnboardingIsRunning ? (
                    <div>
                      <Loader size={20} />
                    </div>
                  ) : (
                    <>
                      <Trans id="common-components.onboarding.Start" />

                      <img src={ImageRocket} alt="rocket" />
                    </>
                  )}
                </div>
              ) : undefined}

              {/* Items */}

              <div className={`${ClassName}--dropdown--content--items`}>
                {/* Manage Account */}

                <MenuItem
                  data-testid="go-to-manage-account-act"
                  className={`${ClassName}--dropdown--content--items--item`}
                  value="manage-account"
                >
                  <div className={`${ClassName}--dropdown--content--items--item--icon`}>
                    <ManageAccountIcon size={IconSize} />
                  </div>

                  <Trans id="user-menu.ManageAccount" />
                </MenuItem>

                {/* Support */}

                <MenuItem
                  data-testid="go-to-support-act"
                  className={`${ClassName}--dropdown--content--items--item`}
                  value="support"
                >
                  <div className={`${ClassName}--dropdown--content--items--item--icon`}>
                    <SupportIcon size={IconSize} />
                  </div>

                  <Trans id="user-menu.Support" />
                </MenuItem>

                {/* Settings */}

                <MenuItem
                  data-testid="go-to-settings-act"
                  className={`${ClassName}--dropdown--content--items--item ${
                    locationIsSettings ? 'active' : ''
                  }`}
                  value="settings"
                >
                  <div className={`${ClassName}--dropdown--content--items--item--icon`}>
                    <SettingsIcon size={IconSize} />
                  </div>

                  <Trans id="user-menu.Settings" />
                </MenuItem>

                {/* Logout */}

                <MenuItem
                  data-testid="sign-out-act"
                  className={`${ClassName}--dropdown--content--items--item`}
                  value={`sign-out-${signOutWithoutModal ? 'now' : 'stop-bcast'}`}
                >
                  <div className={`${ClassName}--dropdown--content--items--item--icon`}>
                    <SignOutIcon size={IconSize} />
                  </div>

                  <Trans id="user-menu.SignOut" />
                </MenuItem>
              </div>
            </div>
          </Menu>
        </div>

        {/* - */}
      </Wrapper>
    </STUserMenuDesktopTablet>
  )
}
