import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Loader } from '../../../components/common/loader/component'
import { ModalsIds } from '../../../constants/modals'
import { Routes } from '../../../constants/routes/routes'
import { ModalsMC } from '../../../store/actions-mutators/modals/mutators'
import { STNoSubscriptionPage } from './style'

// ~~~~~~ Component

export const RedirectionsNoSubscriptionPage: React.FC<{}> = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ~~~~~~ Effects

  // - Redirect

  useEffect(() => {
    navigate(Routes.SignIn, { replace: true })

    dispatch(ModalsMC.open(ModalsIds.YouCanBuyBroadcast))

    //
  }, [dispatch, navigate])

  // ~~~~~~ Render

  return (
    <STNoSubscriptionPage>
      <Loader size={100} />
    </STNoSubscriptionPage>
  )
}
