import { LazySVG } from '../../../../../../../components/svgs/lazy-svg/component'
import { Zoom } from '../../../../../../../constants/zoom'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsZoom } from '../../../../../behaviour-logic/content/main/toolbars/main/buttons/zoom/hook'
import { ClassName, STJoinedMobileToolbarsMainButtonsZoomIn } from './style'

// ~~~~~~ Constants

const ZoomInIcon = LazySVG('icons/zoom-in')

// ~~~~~~ Component

export const JoinedMobileToolbarsMainButtonsZoomIn = () => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsZoom(
    Zoom.StepMobile,
    Zoom.MaxMobile,
  )

  // ~~~~~~ Render

  return (
    <STJoinedMobileToolbarsMainButtonsZoomIn onClick={logic.onClickZoomIn}>
      {/* Icon */}

      <ZoomInIcon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedMobileToolbarsMainButtonsZoomIn>
  )
}
