export const BroadcastServiceConst = {
  // Subscriber Lifecycle Events
  // https://www.red5pro.com/docs/client/webrtc/subscriber/lifecycle-events/
  SubscriberEventNames: {
    Wildcard: '*',

    // ~~~~~~ Common Events

    // https://www.red5pro.com/docs/client/webrtc/subscriber/common-events/

    // When the subscriber has established a required remote connection, such as to a WebSocket server
    ConnectSuccess: 'Connect.Success',

    // When the subscriber has failed to establish a required remote connection for consuming a stream
    ConnectFailure: 'Connect.Failure',

    // When the subscriber has started a subscribing to a stream
    SubscribeStart: 'Subscribe.Start',

    // When the subscriber has failed to start subscribing to a stream
    SubscribeFail: 'Subscribe.Fail',

    // When the subscriber is cannot start subscribing to stream because a stream associated with the streamName is not available.
    SubscribeInvalidName: 'Subscribe.InvalidName',

    // When the subscriber has successfully closed an active subscription to a stream
    SubscribeStop: 'Subscribe.Stop',

    // When metadata is received on the client from the server
    SubscribeMetadata: 'Subscribe.Metadata',

    // When a status event of the subscriber has been receieved from the server
    SubscribeStatus: 'Subscribe.Status',

    // When a message is being sent by a subscribed-to publisher
    SubscribeSendInvoke: 'Subscribe.Send.Invoke',

    // Notification of when a live broadcast has stopped publishing.
    SubscribePlayUnpublish: 'Subscribe.Play.Unpublish',

    // Invoked when a close to the connection is detected
    SubscribeConnectionClosed: 'Subscribe.Connection.Closed',

    // Invoked when an orientation change is detected in metadata. Mobile (iOS and Android) broadcasts are sent with an orientation
    SubscribeOrientationChange: 'Subscribe.Orientation.Change',

    // Invoked when a change to volume is detected during playback. From 0 to 1
    SubscribeVolumeChange: 'Subscribe.Volume.Change',

    // Invoked when a change in playhead time is detected during playback. In seconds
    SubscribeTimeUpdate: 'Subscribe.Time.Update',

    // Invoked when a change in playback state has occured, such as when going from a Playback.PAUSED state to Playback.PLAYING state
    SubscribePlaybackChange: 'Subscribe.Playback.Change',

    // Invoked when a change in fullscreen state occurs during playback
    SubscribeFullScreenChange: 'Subscribe.FullScreen.Change',

    // Invoked when an attempt to autoplay on a media element throws a browser exception; typically due to browser security restrictions and their autoplay policies. (WebRTC and HLS, only)
    // See section on Autoplay Restrictions: https://www.red5pro.com/docs/client/webrtc/subscriber/other-information/

    SubscribeAutoplayFailure: 'Subscribe.Autoplay.Failure',

    // Invoked when an attempt to autoplay on a media element throws a browser exception and is muted based on the muteOnAutoplayRestriction config property; typically due to browser security restrictions and their autoplay policies. (WebRTC and HLS, only)
    // See section on Autoplay Restrictions: https://www.red5pro.com/docs/client/webrtc/subscriber/other-information/
    SubscribeAutoplayMuted: 'Subscribe.Autoplay.Muted	',

    // When a playback session is experiencing network congestion on the broadcast side
    SubscribePublisherNetworkCongestion: 'Subscribe.Publisher.NetworkCongestion',

    // When a playback session is recovering from network congestion on the broadcast side.
    SubscribePublisherNetworkRecovery: 'Subscribe.Publisher.NetworkRecovery',

    // NOTE: Not documented
    SubscribeVideoDimensionsChange: 'Subscribe.VideoDimensions.Change',

    MessageTransportChange: 'MessageTransport.Change',

    // ~~~~~~ WebRTC Subscriber Events

    // https://www.red5pro.com/docs/client/webrtc/subscriber/webrtc-subscriber-events/

    // When the negotation process has produced a valid PeerConnection
    WebRTCPeerConnectionAvailable: 'WebRTC.PeerConnection.Available',

    // When the subscriber requests to start an offer on the PeerConnection
    WebRTCOfferStart: 'WebRTC.Offer.Start',

    // When the subscriber has received a SessionDescription from a requested offer over the PeerConnection
    WebRTCOfferEnd: 'WebRTC.Offer.End',

    // When the subscriber requests to send an answer on the PeerConnection
    WebRTCAnswerStart: 'WebRTC.Answer.Start',

    // When the subscriber has received an answer (in form of a MediaStream) over the PeerConnection
    WebRTCAnswerEnd: 'WebRTC.Answer.End',

    // When the subscriber requests to send a candidate on the PeerConnection
    WebRTCCandidateStart: 'WebRTC.Candidate.Start',

    // When the subscriber has received a candidate over the PeerConnection
    WebRTCCandidateEnd: 'WebRTC.Candidate.End',

    // When the negotaiton process (a.k.a. trickle) has completed and the subscriber will attempt at consuming a stream
    WebRTCIceTrickleComplete: 'WebRTC.IceTrickle.Complete',

    // The underlying RTCDataChannel is available when signalingSocketOnly configuration is used
    WebRTCDataChannelAvailable: 'WebRTC.DataChannel.Available',

    // When the underlying RTCDataChannel is opened when signalingSocketOnly configuration is used
    WebRTCDataChannelOpen: 'WebRTC.DataChannel.Open',

    // When the underlying RTCDataChannel is closed when signalingSocketOnly configuration is used
    WebRTCDataChannelClose: 'WebRTC.DataChannel.Close',

    // When an error has occurred within the underlying RTCDataChannel when signalingSocketOnly configuration is used
    WebRTCDataChannelError: 'WebRTC.DataChannel.Error',

    // When a message has been delivered over the underlying RTCDataChannel when signalingSocketOnly configuration is used
    WebRTCDataChannelMessage: 'WebRTC.DataChannel.Message',

    // ~~~~~~ HLS Subscriber Events

    // https://www.red5pro.com/docs/client/webrtc/subscriber/hls-subscriber-events/
    // There are currently no HLS-specific events. Please refer to the common events
  },

  // Publisher Lifecycle Events
  // https://www.red5pro.com/docs/client/webrtc/publisher/lifecycle-events/
  PublisherEventNames: {
    Wildcard: '*',

    // ~~~~~~ Common Events
    // https://www.red5pro.com/docs/client/webrtc/publisher/common-events/

    // When the publisher has established a required remote connection, such as to a WebSocket or RTMP-based server
    ConnectSuccess: 'Connect.Success',

    // When the publisher has failed to establish a required remote connection for streaming
    ConnectFailure: 'Connect.Failure',

    // When the publisher has started a broadcast stream
    PublishStart: 'Publish.Start',

    // When the publisher has failed to start a broadcast stream
    PublishFail: 'Publish.Fail',

    // When the publisher is rejected from starting a broadcast stream because the streamName provided is already in use
    PublishInvalidName: 'Publish.InvalidName',

    // When the publisher has successfully closed an active broadcast stream
    UnpublishSuccess: 'Unpublish.Success',

    // When the publisher receives metadata from the server
    PublishMetadata: 'Publish.Metadata',

    // When a status event of the publisher has been receieved from the server
    PublishStatus: 'Publish.Status',

    // When the publisher stream has become available on the origin server to be consumed. This will follow the connection setup and Publish.Start event
    PublishAvailable: 'Publish.Available',

    // // When the current broadcast session is experiencing insufficient bandwidth conditions
    // PublishInsufficientBW: 'Publish.InsufficientBW',

    // // Then the current broadcast has updated information related to bandwidth condition recovery
    // PublishRecoveringBW: 'Publish.RecoveringBW',

    // // When the current broadcast session has sufficient bandwidth conditions from previously experiencing network issues
    // PublishSufficientBW: 'Publish.SufficientBW',

    // Invoked when a close to the connection is detected
    PublisherConnectionClosed: 'Publisher.Connection.Closed',

    // Notification when the Camera resolution has been set or change
    PublisherVideoDimensionChange: 'Publisher.Video.DimensionChange',

    // ~~~~~~ WebRTC Publisher Events

    // https://www.red5pro.com/docs/client/webrtc/publisher/webrtc-publisher-events/

    // When constraints have been accepted from the internal request to getUserMedia.
    // The data property of this event contains a requested object detailing the constraints used in the getUserMedia request
    // and an accepted object which is the current constraint settings for the media
    WebRTCMediaConstraintsAccepted: 'WebRTC.MediaConstraints.Accepted',

    // Then constraints have been rejected from the internal request to getUserMedia.
    // The data property of this event contains a constraints object detailing the constraints that were used
    // and rejected from getUserMedia
    WebRTCMediaConstraintsRejected: 'WebRTC.MediaConstraints.Rejected',

    // When the negotation process has returned a MediaStream object to use
    WebRTCMediaStreamAvailable: 'WebRTC.MediaStream.Available',

    // When the negotation process has produced a valid PeerConnection
    WebRTCPeerConnectionAvailable: 'WebRTC.PeerConnection.Available',

    // When the publisher requests to send an offer using a SessionDescription on the PeerConnection
    WebRTCOfferStart: 'WebRTC.Offer.Start',

    // When the publisher has received an answer from the SDP offer on the PeerConnection
    WebRTCIceTrickleComplete: 'WebRTC.IceTrickle.Complete',

    // the underlying RTCDataChannel is available when signalingSocketOnly configuration is used
    WebRTCDataChannelAvailable: 'WebRTC.DataChannel.Available',

    // When the underlying RTCDataChannel is opened when signalingSocketOnly configuration is used
    WebRTCDataChannelOpen: 'WebRTC.DataChannel.Open',

    // When the underlying RTCDataChannel is closed when signalingSocketOnly configuration is used
    WebRTCDataChannelClose: 'WebRTC.DataChannel.Close',

    // When an error has occurred within the underlying RTCDataChannel when signalingSocketOnly configuration is used
    WebRTCDataChannelError: 'WebRTC.DataChannel.Error',

    // When a message has been delivered over the underlying RTCDataChannel when signalingSocketOnly configuration is used
    WebRTCDataChannelMessage: 'WebRTC.DataChannel.Message',
  },

  // ~~~~~~

  OurSubscriberEvents: {
    Closed: 'closed',
    ClosedByConnFail: 'closed-by-conn-fail',
    Fail: 'fail',
    UserLimit: 'user-limit',
    InvalidName: 'invalid-name',
    WebSocketFail: 'websocket-fail',

    // When a playback session is experiencing network congestion on the broadcast side
    BandwidthInsufficient: 'bandwidth-insufficient',

    // When a playback session is recovering from network congestion on the broadcast side
    BandwidthRecovery: 'bandwidth-recovery',
  },

  OurPublisherEvents: {
    Closed: 'closed',

    // Error in peer connection
    ConnectionFailure: 'connection-failure',

    // Cannot publish (using a firewall)
    PublishFail: 'publish-fail',

    ClosedByConnFail: 'closed-by-conn-fail',

    // Cannot create datachannel
    WebRTCDataChannelError: 'data-channel-error',

    // When the current broadcast session is experiencing insufficient bandwidth conditions
    BandwidthInsufficient: 'bandwidth-insufficient',

    // Then the current broadcast has updated information related to bandwidth condition recovery
    BandwidthRecovering: 'bandwidth-recovering',

    // When the current broadcast session has sufficient bandwidth conditions from previously experiencing network issues
    BandwidthSufficient: 'bandwidth-sufficient',
  },

  ErrorCodes: {
    // Session publish disallowed for XXXYYY
    PublishDisalllowed: 'publish-disallowed',

    // (RTCPublisher) Error in stream publish: Session for XXXYYY was not found.
    PublishNotFound: 'publish-not-found',

    // Weirdo case with the stream an error merge
    PublishUnknown: 'publish-unknown',
  },
} as const
