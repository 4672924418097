import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { STCamMicSub } from './style'

import CameraOff from '../../../svgs/icons/camera-off/component'
import MicOff from '../../../svgs/icons/mic-off/component'
import { useCamMicSubSound } from '../../../../hooks/cam-mic/sub/use-cam-mic-sub-speakers-are-enabled'

// ~~~~~~ Constants

const CameraOffIcon = CameraOff
const MicOffIcon = MicOff

// ~~~~~~ Types

type Props = {
  className?: string
}

// ~~~~~~ Component

export const CamMicSub: React.FC<Props> = ({ className }) => {
  // ~~~~~~ Hooks

  const sound = useCamMicSubSound()

  // ~~~~~~ State

  const {
    remoteCamTrack,
    remoteMicTrack,

    isRemoteCamMuted,
    isRemoteMicMuted,
  } = useSelector((state: StoreState) => state.dnBcast)

  const videoRef = useRef<HTMLVideoElement>(null)

  // ~~~~~~ Computed

  const isRemoteWebCamEnabled = !!remoteCamTrack && !!remoteMicTrack

  // ~~~~~~ Effects

  // - Set video source

  useEffect(() => {
    const video = videoRef.current

    if (!remoteCamTrack || !remoteMicTrack || !video || video.srcObject) return

    video.srcObject = new MediaStream([remoteCamTrack, remoteMicTrack])

    //
  }, [remoteCamTrack, remoteMicTrack])

  // ~~~~~~ Render

  return (
    <STCamMicSub className={className}>
      {isRemoteWebCamEnabled ? (
        <div className="cam-mic-sub">
          {/* Camera muted */}

          <AnimatePresence>
            {isRemoteCamMuted ? (
              <motion.div
                className="cam-muted"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  type: 'easeIn',
                  duration: 0.5,
                }}
              >
                <CameraOffIcon />
              </motion.div>
            ) : undefined}
          </AnimatePresence>

          {/* Camera */}

          <video
            ref={videoRef}
            className="cam-mic-sub-video"
            muted={!sound.speakersAreEnabled}
            disablePictureInPicture
            playsInline
            autoPlay
          />

          {/*  */}
        </div>
      ) : undefined}

      {/* No mic */}

      <AnimatePresence>
        {remoteCamTrack && remoteMicTrack && isRemoteMicMuted ? (
          <motion.div
            className="mic-muted"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: 'easeIn',
              duration: 0.5,
            }}
          >
            <MicOffIcon />
          </motion.div>
        ) : undefined}
      </AnimatePresence>
    </STCamMicSub>
  )
}
