import { useDispatch, useSelector } from 'react-redux'
import { ModalsIds } from '../../../../../constants/modals'
import { StoreState } from '../../../../../models/app/model'
import { StorePlanFieldsMR } from '../../../../../models/store/plan/model'
import { ModalsMC } from '../../../../../store/actions-mutators/modals/mutators'
import { Button } from '../../../../common/button/component'
import { Trans } from '../../../../common/intl/trans'
import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { DialogsSubscriptionsPlansForDialogsLayout } from '../layout/component'
import { DialogsSubscriptionsPlansForDialogsLayoutPartsAction } from '../layout/parts/action/component'
import { DialogsSubscriptionsPlansForDialogsLayoutPartsContent } from '../layout/parts/content/component'
import { STDialogsSubscriptionsPlansForDialogsSharedStyleContent } from '../shared-style/style'
import { BcastTrackEvents } from '../../../../../services/track-events'

// ~~~~~~ Constants

const CircleArrowIcon = LazySVG('icons/circle-arrow')

const Logo = LazySVG('logos/broadcast-brand')

// ~~~~~~ Types

type Props = {
  onSelectPlan(): void
}

// ~~~~~~ Component

export const DialogsSubscriptionsPlansForDialogsFree: React.FC<Props> = ({ onSelectPlan }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const broadcastSub = useSelector((state: StoreState) => state.storeProducts)

  // ~~~~~~ Computed

  const broadcastFreePriceId = broadcastSub.products.free.id

  const isRunning = broadcastSub.uiStatus === 'running'

  const userIsLogged = !!currentUser.email

  // ~~~~~~ Handlers

  function onClick() {
    dispatch(StorePlanFieldsMR.kind.MC.change('free'))
    dispatch(StorePlanFieldsMR.priceId.MC.change(broadcastFreePriceId))

    onSelectPlan()

    dispatch(ModalsMC.open(ModalsIds.Step1EmailPass))

    // Track

    BcastTrackEvents.calls.BuyProcess.Free['1-select-plan-free']()
  }
  // ~~~~~~ Render

  return (
    <DialogsSubscriptionsPlansForDialogsLayout>
      {/* Content */}

      <DialogsSubscriptionsPlansForDialogsLayoutPartsContent>
        <STDialogsSubscriptionsPlansForDialogsSharedStyleContent $isFreePlan={true}>
          <div className="plans-for-dialogs-logo">
            <Logo size={23} />
          </div>

          <div className="plans-for-dialogs-title">
            <Trans id="plans.title.Free" />
          </div>

          <div className="plans-for-dialogs-subtitle">
            <Trans id="plans.subtitle.Common" />
          </div>

          <div className="plans-for-dialogs-content">
            {/* Up to 40 viewers */}

            <div className="line">
              <div className="icon">
                <CircleArrowIcon size={14} />
              </div>

              <Trans id="plans.lines.common.Viewers" />
            </div>

            {/* Free 14 premium days */}

            <div className="line">
              <div className="icon">
                <CircleArrowIcon size={14} />
              </div>

              <Trans id="plans.lines.free.UnlimitedDays" />
            </div>

            {/* 30-min daily */}

            <div className="line">
              <div className="icon">
                <CircleArrowIcon size={14} />
              </div>

              <Trans id="plans.lines.common.time-limit.Free" />
            </div>
          </div>

          <hr />
        </STDialogsSubscriptionsPlansForDialogsSharedStyleContent>
      </DialogsSubscriptionsPlansForDialogsLayoutPartsContent>

      {/* Action */}

      <DialogsSubscriptionsPlansForDialogsLayoutPartsAction>
        <Button
          $colorType="secondary"
          $width="100%"
          intlId={userIsLogged ? 'plans.actions.CurrentPlan' : 'plans.actions.Free'}
          disabled={userIsLogged || isRunning}
          $active={!userIsLogged && !isRunning}
          $running={isRunning}
          onClick={onClick}
        />
      </DialogsSubscriptionsPlansForDialogsLayoutPartsAction>
    </DialogsSubscriptionsPlansForDialogsLayout>
  )
}
