import { UtilsStore } from '@dn/utils'
import { Config } from '../../../config'

const initModel: DN.Models.UiMobileToolbar.Model = {
  openStatus: 'closed',
}

// ~~~~~~ Edit

const prefix = 'mt-ui-mobile-toolbar'

// ~~~~~~ Fields Mutators/Reducers

export const UiMobileToolbarFieldsMR = {
  openStatus: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'openStatus',
    initModel.openStatus,
    Config.Intl.InfosForInfer,
  ),
}

// ~~~~~~

export const UiMobileToolbar = {
  initModel,
}
