import { UtilsStore } from '@dn/utils'
import { Config } from '../../../../config'

const initModel: DN.Models.StreamManagerSub.ModelCamMic = {
  errors: [],

  id: '',

  username: '',

  paused: false,

  subscriber: undefined,

  lastEvent: 'Empty',

  iMutedAudio: false,
  iHaveTouchedAudioControl: false,

  bcasterMutedMic: false,
  bcasterMutedCam: false,
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-stream-amanager-sub-cam-mic-edit-field'

export const StreamManagerSubCamMicFieldsMR = {
  id: UtilsStore.genFieldMR(prefix, initModel, 'id', initModel.id, Config.Intl.InfosForInfer),

  iMutedAudio: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'iMutedAudio',
    initModel.iMutedAudio,
    Config.Intl.InfosForInfer,
  ),

  bcasterMutedAudio: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'bcasterMutedMic',
    initModel.bcasterMutedMic,
    Config.Intl.InfosForInfer,
  ),

  bcasterMutedVideo: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'bcasterMutedCam',
    initModel.bcasterMutedCam,
    Config.Intl.InfosForInfer,
  ),
}

// ~~~~~~

export const StreamManagerSubCamMic = {
  initModel,
}
