import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable, tap } from 'rxjs'

import { StoreState } from '../../../../models/app/model'
import { ToastUtil } from '../../../../components/common/toast/util'

import {
  EpicApiBcastGoogleClassRoomAnnounceCaptureMC,
  EpicApiBcastGoogleClassRoomAnnounceCaptureMT,
} from '../../api-bcast/google-classroom/announce-capture/mutators'
import {
  EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC,
  EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT,
} from '../../api-bcast/google-slides/create-presentation-and-add-image-slide/mutators'
import {
  EpicApiBcastGoogleSlidesAddImageSlideMC,
  EpicApiBcastGoogleSlidesAddImageSlideMT,
} from '../../api-bcast/google-slides/add-image-slide/mutators'
import {
  EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC,
  EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT,
} from '../../api-bcast/google-classroom/announce-bcast-link/mutators'

type Action =
  | ReturnType<typeof EpicApiBcastGoogleClassRoomAnnounceCaptureMC.ok>
  | ReturnType<typeof EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC.ok>
  | ReturnType<typeof EpicApiBcastGoogleSlidesAddImageSlideMC.ok>
  | ReturnType<typeof EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC.ok>

export const toastSuccessEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      EpicApiBcastGoogleClassRoomAnnounceCaptureMT.OK,
      EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.OK,
      EpicApiBcastGoogleSlidesAddImageSlideMT.OK,
      EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT.OK,
    ),

    tap(({ type, payload }) => {
      let id: IntlMsgId
      let values: any

      switch (type) {
        case EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT.OK:
          id = 'api.google-class-room.announce.link.Success'
          values = { value: payload.course.name }
          break

        case EpicApiBcastGoogleClassRoomAnnounceCaptureMT.OK:
          id = 'api.google-class-room.announce.image.Success'
          values = { value: payload.course.name }

          break

        case EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.OK:
        case EpicApiBcastGoogleSlidesAddImageSlideMT.OK:
          id = 'api.google-slides.add-slide.Success'
          values = { value: payload.title }

          break
      }

      if (!id) return

      ToastUtil.basic({
        kind: 'success',
        intlIds: [{ id, values }],
      })
    }),

    mergeMap(() => EMPTY),
  )
