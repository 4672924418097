import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'
import { DrawState } from '../@state/draw-partial-service'
import { DrawStop$$ } from '../subject/draw-partial-service'

export const stop = () => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'stop')

  DrawStop$$.next()
  DrawState.reset()
}
