const prefix = 'mt-share-cam-mic-stream-get-stream-epic'

// ~~~~~~ Mutators Types

export const EpicShareCamMicStreamGetStreamMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~ Mutators Creators

export type GetStreamData = {
  videoTrack?: MediaStreamTrack
  audioTrack?: MediaStreamTrack
}

export const EpicShareCamMicStreamGetStreamMC = {
  ok: (stream: MediaStream, streamData: GetStreamData) => ({
    type: EpicShareCamMicStreamGetStreamMT.OK,
    payload: {
      stream,
      streamData,
    },
  }),

  error: (errors: Infos[]) => ({
    error: true,
    type: EpicShareCamMicStreamGetStreamMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
