import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { FeedbackEndBcastFieldsMR } from '../../../../models/bcast/feedback/end-bcast/model'
import { FeedbackAC } from '../../../../store/actions/feedback/actions'
import { Button } from '../../../common/button/component'
import { CheckButton } from '../../../common/check-button/component'
import { IntlArea } from '../../../common/intl-area/component'
import { Trans } from '../../../common/intl/trans'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { ClassName, STDialogEndBcastFeedbackForm } from './style'

// ~~~~~~ Props

type Props = {
  onClose: () => void
}

// ~~~~~~ Component

export const DialogEndBcastFeedbackForm: React.FC<Props> = ({ onClose }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Store

  const feedback = useSelector((state: StoreState) => state.feedbackEndBcast)

  const numOfBcast = useSelector((store: StoreState) => store.userSettings.num_of_bcast)

  // ~~~~~~ Computed

  const isRunning = feedback.uiStatus === 'running'

  const feedbackWidget = numOfBcast === 1 ? 'first' : numOfBcast === 21 ? 'second' : ''

  // ~~~~~~ Handlers

  function onChangeComments(value: string) {
    dispatch(FeedbackEndBcastFieldsMR.comments.MC.change(value.slice(0, 4000)))
  }

  function onChangeGetInTouch() {
    dispatch(FeedbackEndBcastFieldsMR.getInTouch.MC.change(!feedback.get_in_touch))
  }

  function onClickClose() {
    // Always true
    if (!feedback.happiness) return

    dispatch(
      FeedbackAC.sendEndBcast(feedbackWidget, {
        hapiness: feedback.happiness,
        comments: '',
        get_in_touch: false,
      }),
    )

    onClose()

    dispatch(FeedbackEndBcastFieldsMR.__model__.MC.completeClear())
  }

  function onClickSubmit() {
    // Always true
    if (!feedback.happiness) return

    dispatch(
      FeedbackAC.sendEndBcast(feedbackWidget, {
        hapiness: feedback.happiness,
        comments: feedback.comments,
        get_in_touch: feedback.get_in_touch,
      }),
    )

    onClose()

    dispatch(FeedbackEndBcastFieldsMR.__model__.MC.completeClear())
  }

  // ~~~~~~ Render

  return (
    <>
      <Content>
        <STDialogEndBcastFeedbackForm>
          {/* Title */}

          <div className={`${ClassName}--title`}>
            <Trans id="dialogs.end-bcast-feedback.form.Title" />
          </div>

          {/* Subtitle */}

          <div className={`${ClassName}--subtitle`}>
            <Trans id="dialogs.end-bcast-feedback.form.Subtitle" />
          </div>

          {/* Comments */}

          <div className={`${ClassName}--feedback`}>
            <IntlArea
              $resize="none"
              $minHeight={120}
              value={feedback.comments}
              $disabled={isRunning}
              onChange={onChangeComments}
            />
          </div>

          {/* Check: Get in touch */}

          <div className={`${ClassName}--get-in-touch`}>
            <CheckButton
              intlId="dialogs.end-bcast-feedback.form.GetInTouch"
              checked={feedback.get_in_touch}
              disabled={isRunning}
              onClickCheck={onChangeGetInTouch}
            />
          </div>

          {/* -- */}
        </STDialogEndBcastFeedbackForm>
      </Content>

      <Actions>
        {/* Close */}

        <Button
          intlId="dialogs.end-bcast-feedback.form.actions.Close"
          $colorType="tertiary-ghost"
          $active={true}
          disabled={false}
          $running={false}
          onClick={onClickClose}
        />

        {/* Submit */}

        <Button
          intlId="dialogs.end-bcast-feedback.form.actions.Submit"
          $colorType="primary"
          $active={!isRunning}
          disabled={isRunning}
          $running={isRunning}
          onClick={onClickSubmit}
        />
      </Actions>
    </>
  )
}
