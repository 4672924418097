import { Config } from '../../../../config'
import { Trans } from '../../intl/trans'
import { UserSettingsTemplate } from '../0-template/component'
import { ClassName, STUserSettingsAccount } from './style'

// ~~~~~~ Component

export const UserSettingsAccount = () => {
  // ~~~~~~ Handlers

  function onClickGoToStore() {
    window.open(Config.Links.ManageAccount)
  }

  // ~~~~~~ Render

  return (
    <UserSettingsTemplate title="common-components.user-settings.sections.account.Title">
      <STUserSettingsAccount>
        <div className={`${ClassName}--notice`}>
          <div className={`${ClassName}--notice--info`}>
            <Trans id="common-components.user-settings.sections.account.logged.info" />
          </div>

          <div className={`${ClassName}--notice--info-action`} onClick={onClickGoToStore}>
            <Trans id="common-components.user-settings.sections.account.logged.info-action" />
          </div>
        </div>
      </STUserSettingsAccount>
    </UserSettingsTemplate>
  )
}
