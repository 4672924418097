import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { Colors } from '../../../../style/theme/colors'
import CamOff from '../../../svgs/icons/cam-off/component'
import CamOn from '../../../svgs/icons/cam-on/component'
import CameraOff from '../../../svgs/icons/camera-off/component'
import MicOff from '../../../svgs/icons/mic-off/component'
import MicOn from '../../../svgs/icons/mic-on/component'
import { LoaderBalls } from '../../loader-balls/component'
import { STSharedCamMic } from './style'
import { ServiceBcast } from '@dn/bcast'

// ~~~~~~ Constants

const CameraOffIcon = CameraOff

const CamOnIcon = CamOn
const CamOffIcon = CamOff

const MicOnIcon = MicOn
const MicOffIcon = MicOff

// ~~~~~~ Types

type Props = {
  className?: string
}

// ~~~~~~ Component

export const CamMicPub: React.FC<Props> = ({ className }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const dnBcast = useSelector((state: StoreState) => state.dnBcast)

  const videoRef = useRef<HTMLVideoElement>(null)

  // ~~~~~~ Computed

  const micTrack = dnBcast.localMicTrack || { enabled: false }

  const camTrack = dnBcast.localCamTrack || { enabled: false }

  const isConnecting = dnBcast.webcamUiState === 'running'

  const camIsRunning = dnBcast.localCamSilenceUiStatus === 'running'

  const camIsMuted = dnBcast.isLocalCamMuted

  const micIsRunning = dnBcast.localMicSilenceUiStatus === 'running'

  const micIsMuted = dnBcast.isLocalMicMuted

  // ~~~~~~ Handlers

  function onClickToggleCam() {
    if (!camTrack) return

    const action = dnBcast.isLocalCamMuted
      ? ServiceBcast.React.Actions.AC.camUnmute()
      : ServiceBcast.React.Actions.AC.camMute()

    dispatch(action)
  }

  function onClickToggleMic() {
    if (!micTrack) return

    const action = dnBcast.isLocalMicMuted
      ? ServiceBcast.React.Actions.AC.micUnmute()
      : ServiceBcast.React.Actions.AC.micMute()

    dispatch(action)
  }

  // ~~~~~~ Effects

  // - Set video src and start playing it

  useEffect(() => {
    const video = videoRef.current

    if (!dnBcast.localCamStream || !video) return

    video.srcObject = dnBcast.localCamStream

    video.play()

    //
  }, [dnBcast.localCamStream])

  // ~~~~~~ Render

  return (
    <STSharedCamMic className={`${className} ${dnBcast.localCamStream ? 'available' : ''}`}>
      {/* Camera */}

      <AnimatePresence>
        {dnBcast.localCamStream ? (
          <motion.div
            key="cam-mic-pub"
            className="cam-mic-pub"
            // Animation cycle
            initial="hidden"
            animate="visible"
            exit="toInfo"
            variants={{
              // Initial: hidden
              hidden: {
                opacity: 0,
                scale: 0,
              },
              // Animate: to visible
              visible: {
                transition: {
                  delay: 0.35,
                  type: 'spring',
                  duration: 0.7,
                },
                opacity: 1,
                scale: 1,
              },
              // Exit: to hidden
              toInfo: {
                transition: {
                  ease: 'easeInOut',
                  duration: 0.35,
                },

                opacity: 0,
              },
            }}
          >
            {/* Controls */}

            {!isConnecting ? (
              <div className={`cam-mic-pub-hover ${camIsMuted ? 'with-background' : ''}`}>
                <div className="cam-mic-pub-controls">
                  <div className="cam-mic-pub-controls-panel">
                    {/* Cam */}

                    <div
                      className={`cam-mic-pub-controls-video ${camIsRunning ? 'running' : ''} ${
                        !camTrack ? 'not-allowed' : ''
                      }`}
                      onClick={onClickToggleCam}
                    >
                      {camIsRunning ? <CamOnIcon /> : camIsMuted ? <CamOffIcon /> : <CamOnIcon />}
                    </div>

                    {/* Mic */}

                    <div
                      className={`cam-mic-pub-controls-audio ${micIsRunning ? 'running' : ''} ${
                        !micTrack ? 'not-allowed' : ''
                      }`}
                      onClick={onClickToggleMic}
                    >
                      {micIsRunning ? <MicOnIcon /> : micIsMuted ? <MicOffIcon /> : <MicOnIcon />}
                    </div>
                  </div>
                </div>
              </div>
            ) : undefined}

            {/* Camera muted (background) */}

            <AnimatePresence>
              {camIsMuted ? (
                <motion.div
                  className="cam-muted"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    type: 'easeIn',
                    duration: 0.5,
                  }}
                >
                  <CameraOffIcon />
                </motion.div>
              ) : undefined}
            </AnimatePresence>

            {/* Camera */}

            <video ref={videoRef} className="cam-mic-pub-video" muted disablePictureInPicture />

            {/*  */}
          </motion.div>
        ) : undefined}
      </AnimatePresence>

      {/* Connecting... (balls animation) */}

      <AnimatePresence>
        {isConnecting ? (
          <motion.div
            className="shared-cam-mic-loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LoaderBalls
              color={Colors.WOLF_00_00}
              loadingDot={{
                width: '8px',
                height: '8px',
              }}
              loadingContainer={{
                width: '50px',
                height: '100%',
              }}
              dotVariants={{
                initial: {
                  y: '-7px',
                },
                animate: {
                  y: '7px',
                },
              }}
            />
          </motion.div>
        ) : undefined}
      </AnimatePresence>
    </STSharedCamMic>
  )
}
