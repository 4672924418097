import { AppInitState } from '../../../../models/app/model'
import { StorePlanFieldsMR } from '../../../../models/store/plan/model'
import { UtilsStore } from '@dn/utils'
import { ApiStoreCheckoutAT } from '../../../actions/api-store/checkout/actions'
import {
  ApiCheckoutCreateSessionEpicMC,
  ApiCheckoutCreateSessionEpicMT,
} from '../../../epics/api-store/checkout/create-session/mutators'
import {
  ApiCheckoutSendEmailPassEpicMC,
  ApiCheckoutSendEmailPassEpicMT,
} from '../../../epics/api-store/checkout/send-email-pass/mutators'
import {
  ApiCheckoutValidateEmailCodeEpicMC,
  ApiCheckoutValidateEmailCodeEpicMT,
} from '../../../epics/api-store/checkout/validate-email-code/mutators'
import {
  ApiSubscriptionsCreateEpicMC,
  ApiSubscriptionsCreateEpicMT,
} from '../../../epics/api-store/subscription/create/mutators'
import { ApiStoreSubscriptionsAT } from '../../../actions/api-store/subscriptions/actions'
import {
  EpicApiSubscriptionsCheckPaymentMethodMC,
  EpicApiSubscriptionsCheckPaymentMethodMT,
} from '../../../epics/api-store/subscription/check-payment-method/mutators'
import {
  ApiSubscriptionsUpgradePlanEpicMC,
  ApiSubscriptionsUpgradePlanEpicMT,
} from '../../../epics/api-store/subscription/upgrade-plan/mutators'

// ~~~~~~

const initState = AppInitState.storePlan

type State = typeof initState

const reducer: any = {
  ...StorePlanFieldsMR.reset.Reducer,

  ...StorePlanFieldsMR.kind.Reducer,
  ...StorePlanFieldsMR.timeFrame.Reducer,
  ...StorePlanFieldsMR.email.Reducer,
  ...StorePlanFieldsMR.pass.Reducer,
  ...StorePlanFieldsMR.priceId.Reducer,
  ...StorePlanFieldsMR.code.Reducer,

  ...StorePlanFieldsMR.errors.Reducer,
}

// ####################################################################################################
// ~~~~~~ Checkout Send Email with Pass - START
// ####################################################################################################

reducer[ApiStoreCheckoutAT.SEND_EMAIL_PASS] = (state: State): State => {
  return {
    ...state,
    uiStatusEmailPass: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Send Email with Pass - OK
// ####################################################################################################

reducer[ApiCheckoutSendEmailPassEpicMT.OK] = (state: State): State => {
  return {
    ...state,
    uiStatusEmailPass: 'init',
    step: 2,
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Send Email with Pass - ERROR
// ####################################################################################################

reducer[ApiCheckoutSendEmailPassEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiCheckoutSendEmailPassEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatusEmailPass: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Validate Email Code - START
// ####################################################################################################

reducer[ApiStoreCheckoutAT.VALIDATE_EMAIL_CODE] = (state: State): State => {
  return {
    ...state,
    uiStatusVerificationCode: 'running',
    portal_code: '',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Validate Email Code - OK
// ####################################################################################################

reducer[ApiCheckoutValidateEmailCodeEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiCheckoutValidateEmailCodeEpicMC.ok>,
): State => {
  return {
    ...state,
    uiStatusVerificationCode: 'init',
    portal_code: payload.portal_code,
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Validate Email Code - ERROR
// ####################################################################################################

reducer[ApiCheckoutValidateEmailCodeEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiCheckoutValidateEmailCodeEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatusVerificationCode: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Subscribe to free plan - START
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.CREATE] = (state: State): State => {
  return {
    ...state,
    uiStatusSubscribing: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Subscription create (subscribe to free plan) - OK
// ####################################################################################################

reducer[ApiSubscriptionsCreateEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiSubscriptionsCreateEpicMC.ok>,
): State => {
  return {
    ...state,
    uiStatusSubscribing: 'completed',
  }
}

// ####################################################################################################
// ~~~~~~ Subscription create - ERROR
// ####################################################################################################

reducer[ApiSubscriptionsCreateEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiSubscriptionsCreateEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatusSubscribing: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Create Session (buy plan) - START
// ####################################################################################################

reducer[ApiStoreCheckoutAT.CREATE_SESSION] = (state: State): State => {
  return {
    ...state,
    uiStatusCheckout: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Create Session - OK
// ####################################################################################################

reducer[ApiCheckoutCreateSessionEpicMT.OK] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Checkout Create Session - ERROR
// ####################################################################################################

reducer[ApiCheckoutCreateSessionEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiCheckoutCreateSessionEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatusCheckout: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Check payment method (default card) - START
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.CHECK_PAYMENT_METHOD] = (state: State): State => {
  return {
    ...state,
    uiStatusCheckPaymentMethod: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Check payment method (default card) - OK
// ####################################################################################################

reducer[EpicApiSubscriptionsCheckPaymentMethodMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiSubscriptionsCheckPaymentMethodMC.ok>,
): State => {
  return {
    ...state,
    uiStatusCheckPaymentMethod: 'completed',
    card: payload,
  }
}

// ####################################################################################################
// ~~~~~~ Check payment method (default card) - ERROR
// ####################################################################################################

reducer[EpicApiSubscriptionsCheckPaymentMethodMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiCheckoutCreateSessionEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatusCheckPaymentMethod: 'completed',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Subscription upgrade - START
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.UPGRADE_PLAN] = (state: State): State => {
  return {
    ...state,
    uiStatusUpgradePlan: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Subscription upgrade - OK
// ####################################################################################################

reducer[ApiSubscriptionsUpgradePlanEpicMT.OK] = (state: State): State => {
  return {
    ...state,
    uiStatusUpgradePlan: 'completed',
  }
}

// ####################################################################################################
// ~~~~~~ Subscription upgrade - ERROR
// ####################################################################################################

reducer[ApiSubscriptionsUpgradePlanEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiSubscriptionsUpgradePlanEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatusUpgradePlan: 'init',
    errors: payload.errors,
  }
}

// ~~~~~~

export const storePlanReducer = UtilsStore.dynReducer(initState, reducer)
