import { UtilsStore } from '@dn/utils'

// ~~~~~~ Constants

const ErrorType: Infos = { id: 'wildcard' }

// ####################################################################################################
// ~~~~~~ Model
// ####################################################################################################

const initModel: DN.Models.LivePaint.Model = {
  enabled: false,
  genAltCanvas: undefined,
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-live-paint-edit'

export const LivePaintEditFieldsMR = {
  enabled: UtilsStore.genFieldMR(prefix, initModel, 'enabled', initModel.enabled, ErrorType),
  genAltCanvas: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'genAltCanvas',
    initModel.genAltCanvas,
    ErrorType,
  ),
}

// ####################################################################################################
// ~~~~~~ Video
// ####################################################################################################

const initVideoModel: DN.Models.LivePaint.Video.Model = {
  video: null,
  originalVideoW: 0,
  originalVideoH: 0,
}

const prefixVideo = 'live-paint-video-model'

export const LivePaintVideoMR = {
  video: UtilsStore.genBasicFieldMR(prefixVideo, initVideoModel, 'video', initVideoModel.video),

  originalVideoW: UtilsStore.genBasicFieldMR(
    prefixVideo,
    initVideoModel,
    'originalVideoW',
    initVideoModel.originalVideoW,
  ),

  originalVideoH: UtilsStore.genBasicFieldMR(
    prefixVideo,
    initVideoModel,
    'originalVideoH',
    initVideoModel.originalVideoH,
  ),
} as const

// ####################################################################################################
// ~~~~~~ Draw
// ####################################################################################################

const initDrawModel: DN.Models.LivePaint.Draw.Model = {
  canvas: null,
  scope: undefined,
  strokeWidth: 3,
  hasAlpha: false,
  colorKey: 'BLUE',
  activePen: 'pen',
  undos: 0,
  redos: 0,
  lastCmd: 'init',
  isDirty: false,
  isDownloaded: false,
  isErasing: false,
}

const prefixDraw = 'mt-live-paint-draw-model'

// ~~~~~~ Fields Mutators/Reducers

export const LivePaintDrawModelFieldsMR = {
  canvas: UtilsStore.genFieldMR(
    prefixDraw,
    initDrawModel,
    'canvas',
    initDrawModel.canvas,
    ErrorType,
  ),
  isDownloaded: UtilsStore.genFieldMR(
    prefixDraw,
    initDrawModel,
    'isDownloaded',
    initDrawModel.isDownloaded,
    ErrorType,
  ),
}

// ~~~~~~

export const LivePaintModel = {
  initModel,
  initVideoModel,
  initDrawModel,
}
