import { CamMicMicSoundNotMobileIsOffBar } from '../../../../../components/common/cam-mic/mic-sound/not-mobile/is-off-bar/component'
import { DeprecatedCamMicSub } from '../../../../../components/common/cam-mic/sub/deprecated/component'
import { JoinedDesktopContentMainCapturePaint } from './capture-paint/component'
import { JoinedDesktopContentMainLoadingVideo } from './loading-video/component'
import { ClassName, STJoinedDesktopContentMain } from './style'
import { JoinedDesktopContentMainToolbarsMain } from './toolbars/main/component'
import { JoinedDesktopContentMainToolbarsCapturePaint } from './toolbars/capture-paint/component'
import { CamMicMicSoundNotMobileButtonSwitcher } from '../../../../../components/common/cam-mic/mic-sound/not-mobile/button-switcher/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMain } from '../../../behaviour-logic/content/main/hook'
import { VideoPipStatusUpdater } from '../../../common/video-pip-status-updater/component'
import { CamMicSub } from '../../../../../components/common/cam-mic/sub/component'

// ~~~~~~ Constants

const CloseIcon = LazySVG('icons/times')

// ~~~~~~ Component

export const JoinedDesktopContentMain = () => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMain()

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMain ref={logic.selfRef}>
      {/* PIP control */}

      <VideoPipStatusUpdater video={logic.videoRef.current} />

      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Video wrapper */}

        <div className={`${ClassName}--content--video-wrapper`} ref={logic.videoWrapperRef}>
          {/* Sound */}

          {logic.videoIsPlaying &&
          (logic.curCamMicStream || (logic.remoteCamTrack && logic.remoteMicTrack)) ? (
            <>
              <CamMicMicSoundNotMobileIsOffBar className={`${ClassName}--content--sound`} />
              <CamMicMicSoundNotMobileButtonSwitcher className={`${ClassName}--content--sound`} />
            </>
          ) : undefined}

          {/* Camera */}

          {logic.videoIsPlaying ? (
            logic.isBcastV1 ? (
              <DeprecatedCamMicSub
                className={`${ClassName}--content--video-wrapper--cam-mic ${
                  logic.curCamMicStream ? '' : 'hidden'
                }`}
              />
            ) : (
              <CamMicSub
                className={`${ClassName}--content--video-wrapper--cam-mic ${
                  logic.remoteCamTrack && logic.remoteMicTrack ? '' : 'hidden'
                }`}
              />
            )
          ) : undefined}

          {/* Paint bcast stream */}

          {logic.paintBcastStreamEnabled ? <JoinedDesktopContentMainCapturePaint /> : undefined}

          {/* Loading video... */}

          {!logic.videoIsPlaying ? <JoinedDesktopContentMainLoadingVideo /> : undefined}

          {/* Video */}

          <video
            ref={logic.videoRef}
            className={`${ClassName}--content--video-wrapper--video ${
              logic.hasZoom ? 'with-zoom' : ''
            }`}
            autoPlay
            playsInline
            muted
            style={{
              zIndex: 0,
              top: 0,
              left: 0,

              position: logic.hasZoom ? 'absolute' : 'initial',

              // 100%, 120%, 140%...
              width: logic.zoomWidthAndHeight,
              height: logic.zoomWidthAndHeight,
            }}
          />

          {/* Mini video */}

          <div
            className={`${ClassName}--content--video-wrapper--mini-video-container ${
              logic.shouldShowMiniVideo ? '' : 'hidden'
            } ${logic.paintBcastStreamEnabled ? '' : 'hidden'}`}
          >
            <div
              className={`${ClassName}--content--video-wrapper--mini-video-container--close`}
              onClick={logic.onClickCloseMiniVideo}
            >
              <CloseIcon size={10} />
            </div>

            <video ref={logic.miniVideoRef} disablePictureInPicture autoPlay playsInline muted />
          </div>
        </div>

        {/* Tools */}

        <div ref={logic.toolsWrapperRef} className={`${ClassName}--content--tools`}>
          {/* Main */}

          {logic.videoIsPlaying && !logic.paintBcastStreamEnabled ? (
            <JoinedDesktopContentMainToolbarsMain video={logic.videoRef.current} />
          ) : undefined}

          {/* Paint */}

          {logic.videoIsPlaying && logic.paintBcastStreamEnabled ? (
            <JoinedDesktopContentMainToolbarsCapturePaint />
          ) : undefined}

          {/* -- */}
        </div>

        {/* -- */}
      </div>
    </STJoinedDesktopContentMain>
  )
}
