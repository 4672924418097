import { ServiceBcast } from '@dn/bcast'
import { UtilsStore } from '@dn/utils'
import { AppInitState } from '../../../models/app/model'
import {
  EpicApiBcastRoomPubMC,
  EpicApiBcastRoomPubMT,
} from '../../epics/api-bcast/room/pub/mutators'
import { Config } from '../../../config'
import {
  EpicShareMainStreamGetStreamMC,
  EpicShareMainStreamGetStreamMT,
} from '../../epics/share-main-stream/get-stream/mutators'
import {
  EpicApiBcastRoomSubMC,
  EpicApiBcastRoomSubMT,
} from '../../epics/api-bcast/room/sub/mutators'
import { EpicCancelNowAllMT, EpicCancelNowAllMC } from '../../epics/cancel-now/all/mutators'
import { ApiBcastRoomAC, ApiBcastRoomAT } from '../../actions/api-bcast/room/actions'
import {
  EpicShareMainStreamGetScreenToSwitchMC,
  EpicShareMainStreamGetScreenToSwitchMT,
} from '../../epics/share-main-stream/get-stream-to-switch/mutators'
import {
  EpicShareMainStreamGetStreamByExtensionToSwitchMC,
  EpicShareMainStreamGetStreamByExtensionToSwitchMT,
} from '../../epics/share-main-stream/get-stream-by-extension-to-switch/mutators'
import { UtilsBcast } from '../../../utils/bcast'

const initState = AppInitState.dnBcast

type State = typeof initState

const reducer = ServiceBcast.React.Reducer

const { getPublicBcastId } = UtilsBcast

// ####################################################################################################
// ~~~~~~ Cancel now all - DONE
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (
  state: State,
  { payload }: ReturnType<typeof EpicCancelNowAllMC.done>,
): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Get Stream - OK
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareMainStreamGetStreamMC.ok>,
): State => {
  return {
    ...state,

    localScreenStream: payload.stream,

    localScreenTrack: payload.videoTrack,
  }
}

// ####################################################################################################
// ~~~~~~ Get room for pub - START
// ####################################################################################################

reducer[ApiBcastRoomAT.FOR_PUB] = (
  state: State,
  { payload }: ReturnType<typeof ApiBcastRoomAC.forPub>,
): State => {
  return {
    ...state,

    getPubDataUiStatus: 'running',
    getPubDataErrors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Room for pub (api-call) - OK
// ####################################################################################################

reducer[EpicApiBcastRoomPubMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastRoomPubMC.ok>,
): State => {
  return {
    ...state,

    getPubDataUiStatus: 'init',

    token: payload.token,
    server: `${Config.Bcast.Protocol}${payload.server}`,
    bcastId: payload.id,
    publicBcastId: getPublicBcastId(payload.id),
    bcastUuid: payload.uuid,
  }
}

// ####################################################################################################
// ~~~~~~ Room for pub (api-call) - ERROR
// ####################################################################################################

reducer[EpicApiBcastRoomPubMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastRoomPubMC.error>,
): State => {
  return {
    ...initState,

    getPubDataUiStatus: 'init',
    getPubDataErrors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Get room for Sub - START
// ####################################################################################################

reducer[ApiBcastRoomAT.FOR_SUB] = (
  state: State,
  { payload }: ReturnType<typeof ApiBcastRoomAC.forSub>,
): State => {
  return {
    ...state,

    getSubDataUiStatus: 'running',
    getSubDataErrors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Room for sub (api-call) - OK
// ####################################################################################################

reducer[EpicApiBcastRoomSubMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastRoomSubMC.ok>,
): State => {
  return {
    ...state,

    getSubDataUiStatus: 'init',

    token: payload.token,
    server: `${Config.Bcast.Protocol}${payload.server}`,
    bcastId: payload.id,
    publicBcastId: getPublicBcastId(payload.id),
    bcastUuid: payload.uuid,
  }
}

// ####################################################################################################
// ~~~~~~ Room for sub (api-call) - ERROR
// ####################################################################################################

reducer[EpicApiBcastRoomSubMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastRoomSubMC.error>,
): State => {
  return {
    ...initState,

    getSubDataUiStatus: 'init',
    getSubDataErrors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Get screen to switch - OK
// ####################################################################################################

reducer[EpicShareMainStreamGetScreenToSwitchMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareMainStreamGetScreenToSwitchMC.ok>,
): State => {
  return {
    ...state,

    localScreenStream: payload.newStream,
    localScreenTrack: payload.newVideoTrack,
  }
}

// ####################################################################################################
// ~~~~~~ Get screen to switch - ERROR
// ####################################################################################################

reducer[EpicShareMainStreamGetScreenToSwitchMT.ERROR] = (state: State): State => {
  return state
}

// ####################################################################################################
// ~~~~~~ Extension choose source to switch - OK
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamByExtensionToSwitchMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareMainStreamGetStreamByExtensionToSwitchMC.ok>,
): State => {
  return {
    ...state,

    localScreenStream: payload.newStream,
    localScreenTrack: payload.newVideoTrack,
  }
}

// ####################################################################################################
// ~~~~~~ Extension choose source to switch - ERROR
// ####################################################################################################

reducer[EpicShareMainStreamGetStreamByExtensionToSwitchMT.ERROR] = (state: State): State => {
  return state
}

// ~~~~~~

export const dnBcastReducer = UtilsStore.dynReducer(initState, reducer)
