import qs from 'qs'

type Viewer = {
  meetId: string

  kind: 'viewer'
  username: string
  pass?: string
  env: string
}

type Bcaster = {
  meetId: string

  kind: 'bcaster'
  token: string
  pass?: string
  env: string
}

export type CommChannelCreateData = Bcaster | Viewer

export const commChannelCreate = (websocketUri: string, data: CommChannelCreateData) => {
  const queryParams = qs.stringify(
    data.kind === 'bcaster'
      ? {
          token: data.token,
          pass: data.pass,
          env: data.env,
        }
      : {
          username: data.username,
          pass: data.pass,
          env: data.env,
        },
  )

  return new WebSocket(`${websocketUri}/${data.meetId}/?${queryParams}`)
}
