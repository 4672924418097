import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'

export const STUserSettingsTopLeft = styled.div`
  cursor: pointer;

  ${Fonts.Scale.Body}
  ${Fonts.Weight[700]}

  color: ${theme(Colors.WOLF_05_05)};

  display: flex;
  align-items: center;

  svg {
    height: 16px;

    margin-right: ${Layout.Spacing[2]}px;

    path {
      fill: ${theme(Colors.WOLF_05_05)};
    }
  }
`
