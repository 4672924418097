export const Lisa = {
  90: '#69550f',
  80: '#896f14',
  70: '#b18f1a',
  60: '#e3b821',
  50: '#f9ca24',
  40: '#fad550',
  30: '#fbdb6c',
  20: '#fce79a',
  10: '#fdefbb',
  5: '#fefae9',
} as const

// ~~~~~~

const LISA_05_05: ThemeColor = {
  light: Lisa[5],
  dark: Lisa[5],
}

const LISA_05_50: ThemeColor = {
  light: Lisa[5],
  dark: Lisa[50],
}

const LISA_40_30: ThemeColor = {
  light: Lisa[40],
  dark: Lisa[30],
}

const LISA_50_20: ThemeColor = {
  light: Lisa[50],
  dark: Lisa[20],
}

const LISA_50_40: ThemeColor = {
  light: Lisa[50],
  dark: Lisa[40],
}

const LISA_50_50: ThemeColor = {
  light: Lisa[50],
  dark: Lisa[50],
}

const LISA_60_50: ThemeColor = {
  light: Lisa[60],
  dark: Lisa[50],
}

const LISA_60_60: ThemeColor = {
  light: Lisa[60],
  dark: Lisa[60],
}

// ~~~~~~

export const LisaColors = {
  LISA_05_05,
  LISA_05_50,
  LISA_40_30,
  LISA_50_20,
  LISA_50_40,
  LISA_50_50,
  LISA_60_50,
  LISA_60_60,
} as const
