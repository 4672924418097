import { HooksLifecycle } from '@dn/hooks'
import React, { forwardRef } from 'react'

import { LazySVG } from '../../svgs/lazy-svg/component'
import { Trans } from '../intl/trans'
import { Loader } from '../loader/component'

import { getColor, getIconSize, STButton, STProps } from './style'

// ~~~~~~ Constants

const LoaderSize = {
  xs: 18,
  s: 28,
  m: 32,
  l: 28,
  xl: 34,
}

// ~~~~~~ Types

type Icon = ReturnType<typeof LazySVG>

export type ButtonProps = STProps & {
  'data-testid'?: string

  children?: React.ReactNode

  intlId: IntlMsgId
  intlValues?: { [key: string]: string | number }
  disabled: boolean
  $running: boolean
  className?: string

  IconCenter?: Icon
  IconLeft?: Icon
  IconRight?: Icon

  onClick: (evt?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

// ~~~~~~ Component

export const Button = forwardRef<HTMLDivElement, ButtonProps>(
  (
    {
      'data-testid': dataTestId,
      children,
      intlId,
      intlValues,
      disabled,

      className,
      onClick,
      onMouseEnter,
      onMouseLeave,

      // Shared

      $running,

      // Style only

      $colorType,
      $active,
      $width,
      $height,
      $align,
      $padding,
      $forceNoMarginLeft,
      $size,
      $fontWeight,
      $fontColor,
      $borderRadius,

      IconCenter,
      IconLeft,
      IconRight,
    },
    divRef,
  ) => {
    // ~~~~~~ Hooks

    const isMounted = HooksLifecycle.IsMounted.useHook()

    // ~~~~~~ Computed

    const finalSize = $size || 'm'

    const loaderSize = LoaderSize[finalSize]

    const textColor = getColor($colorType, 'base').font

    // ~~~~~~ Handlers

    function onClickButton() {
      if ($running || disabled) return

      onClick()
    }

    // ~~~~~~ Render

    return (
      <STButton
        data-testid={dataTestId || 'button'}
        ref={divRef}
        className={className}
        $size={finalSize}
        $colorType={$colorType}
        $fontColor={$fontColor}
        $active={$active}
        $running={$running}
        $width={$width}
        $height={$height}
        $align={$align}
        $fontWeight={$fontWeight}
        $forceNoMarginLeft={$forceNoMarginLeft}
        $padding={$padding}
        $borderRadius={$borderRadius}
        onClick={onClickButton}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {$running ? (
          <Loader color={textColor} size={loaderSize} />
        ) : (
          <div className="icon-text">
            {/* Icon left */}

            {isMounted && IconLeft ? (
              <div className="button-icon">
                <IconLeft size={getIconSize({ $size: finalSize })} />
              </div>
            ) : undefined}

            {/* Text */}

            <div className="text">
              {isMounted && IconCenter ? (
                <div className="button-icon">
                  <IconCenter size={getIconSize({ $size: finalSize })} />
                </div>
              ) : undefined}

              <Trans id={intlId} values={intlValues} />
            </div>

            {/* Icon right */}

            {isMounted && IconRight ? (
              <div className="button-icon">
                <IconRight size={getIconSize({ $size: finalSize })} />
              </div>
            ) : undefined}
          </div>
        )}

        {/* Children */}

        {children}

        {/* - */}
      </STButton>
    )
  },
)
