import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Zoom } from '../../../../../../../../../constants/zoom'
import { StoreState } from '../../../../../../../../../models/app/model'
import { PaintBcastStreamVideoMR } from '../../../../../../../../../models/bcast/paint-bcast-stream/model'

// ~~~~~~ Types

type ZoomType = DN.Models.PaintBcastStream.Video.Model['zoom']

// ~~~~~~ Hook

export const useJoinedBehaviourLogicContentMainToolbarsMainButtonsZoom = (
  zoomStep: number,
  zoomMax: number,
) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const zoom = useSelector((state: StoreState) => state.paintBcastStreamVideo.zoom)

  // - Refs

  const tooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Computed

  const tooltipTop = tooltipRef.current ? -tooltipRef.current.clientHeight - 8 : -34

  const currentZoom = `${zoom}%`

  // ~~~~~~ Handlers

  function onClickZoomIn() {
    const zoomInValue = (zoom + zoomStep) as ZoomType

    if (zoomInValue > zoomMax) return

    dispatch(PaintBcastStreamVideoMR.zoom.MC.change(zoomInValue))
  }

  function onClickZoomOut() {
    const zoomOutValue = (zoom - zoomStep) as ZoomType

    if (zoomOutValue < Zoom.Min) return

    dispatch(PaintBcastStreamVideoMR.zoom.MC.change(zoomOutValue))
  }

  // ~~~~~~ Effects

  // - Force update on mount to calculate tooltip position

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // ~~~~~~

  return {
    tooltipRef,
    tooltipTop,
    currentZoom,

    onClickZoomOut,
    onClickZoomIn,
  } as const
}
