import styled from 'styled-components'
import { Transition } from '../../../../../../../../style/animations/css-animations'
import { theme } from '../../../../../../../../style/utils/theme'
import { CommonStyleSvgColors, STJoinedMobileToolbarsCapturePaintButtons } from '../../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-capture-paint-buttons-pens-highlighter'

// ~~~~~~ Component

export const STJoinedMobileToolbarsCapturePaintButtonsPensHighlighter = styled.div`
  ${STJoinedMobileToolbarsCapturePaintButtons}

  svg.${ClassName}--icon {
    path {
      ${Transition.FillStrokeFaster}

      fill: none;

      stroke: ${theme(CommonStyleSvgColors.color)};

      &#fill {
        fill: ${theme(CommonStyleSvgColors.color)};
      }
    }

    &.selected {
      path {
        stroke: ${theme(CommonStyleSvgColors.selected)};

        &#fill {
          fill: ${theme(CommonStyleSvgColors.selected)};
        }
      }
    }
  }
`
