import { useJoinedBehaviourLogicContentMainCapturePaint } from '../../../../behaviour-logic/content/main/capture-paint/hook'
import { ClassName, STJoinedDesktopContentMainCapturePaint } from './style'

// ~~~~~~ Component

export const JoinedDesktopContentMainCapturePaint = () => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainCapturePaint()

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainCapturePaint
      ref={logic.selfRef}
      $activePen={logic.activePen}
      $isErasing={logic.isErasing}
    >
      {/* Canvas wrapper */}
      {/* Canvas w/h equals to video 'frame' not video tag */}
      {/* Video tag uses all the space like div and centers the video frame */}

      <div ref={logic.canvasesWrapperRef} className={`${ClassName}--canvases-wrapper`}>
        {/* Paint canvas */}

        <canvas
          ref={logic.paintCanvasRef}
          className={`${ClassName}--canvases-wrapper--paint`}
          data-paper-hidpi="off"
        />

        {/* Screenshot canvas */}

        <canvas
          ref={logic.screenShotCanvasRef}
          className={`${ClassName}--canvases-wrapper--screenshot`}
        />

        {/* -- */}
      </div>
    </STJoinedDesktopContentMainCapturePaint>
  )
}
