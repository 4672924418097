const initModel: DN.Models.StreamManagerPub.ModelFront = {
  uiStatus: 'init',
  errors: [],
  id: '',
  publisher: undefined,
  lastEvent: 'Empty',
  isReady: false,
}

// ~~~~~~

export const StreamManagerPubModel = {
  initModel,
}
