import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import {
  EpicBcastStartPubCamMicMC,
  EpicBcastStartPubCamMicMT,
} from '../../../epics/bcast/start-pub-cam-mic/mutators'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'
import { EpicCancelNowCamMicMT } from '../../../epics/cancel-now/cam-mic/mutators'

const initState = AppInitState.streamManagerPubCamMic

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Pub cam-mic - OK
// ####################################################################################################

reducer[EpicBcastStartPubCamMicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartPubCamMicMC.ok>,
): State => {
  return {
    ...state,
    uiStatus: 'completed',
    publisher: payload.publisher,
  }
}

// ####################################################################################################
// ~~~~~~ Pub cam-mic - ERROR
// ####################################################################################################

reducer[EpicBcastStartPubCamMicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartPubCamMicMC.error>,
): State => {
  if (!payload.errors) return state

  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Last Event
// ####################################################################################################

reducer[EpicBcastStartPubCamMicMT.EVENT] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartPubCamMicMC.event>,
): State => {
  return {
    ...state,
    lastEvent: payload.type,
    isReady: payload.type === 'Publish.Available' ? true : state.isReady,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel now all - DONE
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Cancel now cam-mic - DONE
// ####################################################################################################

reducer[EpicCancelNowCamMicMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~

export const streamManagerPubCamMicReducer = UtilsStore.dynReducer(initState, reducer)
