import { motion } from 'framer-motion'
import styled from 'styled-components'
import { Transition } from '../../../../style/animations/css-animations'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { Misc } from '../../../../style/theme/misc'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'onboarding-panel'

// ~~~~~~ Component

export const STMotionOnboardingPanel = styled(motion.div)`
  ${Misc.BoxShadow}

  position: absolute;
  z-index: 50;

  bottom: 90px;
  right: 24px;

  color: ${theme(Colors.WOLF_100_05)};
  background-color: ${theme(Colors.WOLF_00_90)};

  padding: 12px 8px 16px 8px;

  ${Layout.Border.Radius.B}

  .${ClassName}--close {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:hover {
      svg path {
        fill: ${theme(Colors.WOLF_80_20)};
        stroke: none;
      }
    }

    svg {
      width: 13px;
      height: 13px;

      path {
        ${Transition.FillFaster};

        fill: ${theme(Colors.WOLF_100_05)};
        stroke: none;
      }
    }
  }

  .${ClassName}--content {
    padding-left: 16px;
    padding-right: 16px;

    min-width: 250px;

    .${ClassName}--content--title {
      ${Fonts.Scale.Subtitle}
      ${Fonts.Weight[700]}

      display: flex;
      align-items: center;

      margin-bottom: ${Layout.Spacing[1]}px;

      img {
        margin-left: ${Layout.Spacing[2]}px;
        height: 24px;
      }
    }

    .${ClassName}--content--subtitle {
      ${Fonts.Scale.Label}
      color: ${theme(Colors.WOLF_80_20)};

      margin-bottom: ${Layout.Spacing[3]}px;
    }

    .${ClassName}--content--percent {
      margin-bottom: ${Layout.Spacing[3]}px;
    }

    /* Done */

    .${ClassName}--content--done {
      max-width: 250px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      text-align: center;

      .${ClassName}--content--done--icon {
        img {
          width: 34px;
        }

        margin-bottom: ${Layout.Spacing[1]}px;
      }

      .${ClassName}--content--done--title {
        ${Fonts.Scale.Subtitle}
        ${Fonts.Weight[700]}

        margin-bottom: ${Layout.Spacing[2]}px;
      }

      .${ClassName}--content--done--info {
        ${Fonts.Scale.Label}
        color: ${theme(Colors.WOLF_80_20)};
      }
    }
  }
`
