import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars'
import { Button } from '../../../../../../components/common/button/component'
import { IntlInput } from '../../../../../../components/common/intl-input/component'
import { Trans } from '../../../../../../components/common/intl/trans'
import { SliderCheck } from '../../../../../../components/common/slider-check/component'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { UiLayoutViewerMobileFieldsMR } from '../../../../../../models/bcast/ui-layout-viewer-mobile/model'
import { useJoinedBehaviourLogicLeftPanelSharePaintSlidesLogic } from '../../../../behaviour-logic/left-panel/share-paint/slides/logic-hook'
import { useJoinedMobileFloatPanelCaptureShareSlidesSlidesScrollbarHeight } from './hooks/scrollbar-height'
import { ClassName, ItemHeight, STJoinedMobileFloatPanelCaptureShareSlides } from './style'

// ~~~~~~ Constants

const ItemIcon = LazySVG('icons/file')

// ~~~~~~ Component

export const JoinedMobileFloatPanelCaptureShareSlides = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Refs

  const slidesRef = useRef<HTMLDivElement>(null)

  const exitElementRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Dependent Hooks

  const slidesScroll = useJoinedMobileFloatPanelCaptureShareSlidesSlidesScrollbarHeight(
    slidesRef,
    ItemHeight,
  )

  const logic = useJoinedBehaviourLogicLeftPanelSharePaintSlidesLogic(
    slidesScroll.recalculateScrollbarHeight,
  )

  // ~~~~~~ Handlers

  function onClickReqClose() {
    dispatch(UiLayoutViewerMobileFieldsMR.floatPanelOpenState.MC.change('req-close'))
  }

  // ~~~~~~ Render

  return (
    <STJoinedMobileFloatPanelCaptureShareSlides>
      {/* Slides */}

      <div ref={slidesRef} className={`${ClassName}--slides`}>
        {/* Title and slider */}

        <div ref={slidesScroll.refs.titleRef} className={`${ClassName}--title-slider`}>
          <div className={`${ClassName}--title-slider--title`}>Google Slides</div>

          <div className={`${ClassName}--title-slider--title`}>
            <SliderCheck
              $colorType="secondary"
              checked={logic.userCanShareImage ? logic.shareIsOpen : false}
              onChange={logic.onChangeSlider}
            />
          </div>
        </div>

        {/* Closed info */}

        {!logic.shareIsOpen && !logic.userCanShareImage ? (
          <div className={`${ClassName}--closed-info`}>
            <Trans id="pages.joined.left-panel.share-paint.slides.Info" />
          </div>
        ) : undefined}

        {/* Already shared */}

        {logic.shareIsOpen && logic.alreadyShared ? (
          <div className={`${ClassName}--already-shared`}>
            {/* Info */}

            <div className={`${ClassName}--already-shared--info`}>
              <Trans id="pages.joined.left-panel.share-paint.slides.Current" />
            </div>

            {/* Selected slide */}

            <div className={`${ClassName}--already-shared--selected`}>
              {/* Icon */}

              <div className={`${ClassName}--already-shared--selected--icon`}>
                <ItemIcon size={24} />
              </div>

              {/* Slide */}

              <div className={`${ClassName}--already-shared--selected--name`}>
                {logic.lastSelectedGoogleSlideTitle}
              </div>
            </div>

            {/* Action: Share */}

            <div className={`${ClassName}--already-shared--action`}>
              <Button
                $colorType="primary"
                intlId="pages.joined.left-panel.share-paint.slides.actions.Select"
                $width="100%"
                disabled={logic.shareImageIsRunning}
                $running={logic.shareImageIsRunning}
                $active={!logic.shareImageIsRunning}
                onClick={logic.onShareImageInSelectedSlide}
              />
            </div>
          </div>
        ) : undefined}

        {/* Create new project */}

        {logic.shareIsOpen && !logic.alreadyShared && logic.shareView === 'create' ? (
          <div className={`${ClassName}--create`}>
            {/* Info: This can hide the "close" button */}

            {/* <div className={`${ClassName}--create--info`}>
              <Trans id="pages.joined.left-panel.share-paint.slides.InfoOpen" />
            </div> */}

            {/* Project name */}

            <IntlInput
              className={`${ClassName}--create--input`}
              value={logic.title}
              onChange={logic.onChangeTitle}
              errors={logic.title_err}
              $disabled={logic.shareImageIsRunning}
            />

            {/* Action */}

            <div ref={slidesScroll.refs.selectActionRef} className={`${ClassName}--create--action`}>
              <Button
                $colorType="primary"
                intlId="pages.joined.left-panel.share-paint.slides.actions.Create"
                $width="100%"
                disabled={logic.shareImageIsRunning || !logic.title}
                $running={logic.shareImageIsRunning}
                $active={!logic.shareImageIsRunning && !!logic.title}
                onClick={logic.onShareImageCreatingSlide}
              />
            </div>

            {/* Nav: Select existing */}

            <div
              className={`${ClassName}--create--nav`}
              onClick={() => logic.onClickNavigate('select')}
            >
              <Trans id="pages.joined.left-panel.share-paint.slides.actions.GoToSelect" />
            </div>
          </div>
        ) : undefined}

        {/* Select from project list */}

        {logic.shareIsOpen && !logic.alreadyShared && logic.shareView === 'select' ? (
          <div className={`${ClassName}--slides-select`}>
            {/* Available Info */}

            <div
              ref={slidesScroll.refs.slidesSelectInfoRef}
              className={`${ClassName}--slides-select--info`}
            >
              {/* Available slides */}

              {!logic.showEmptyList ? (
                <div className={`${ClassName}--slides-select--info--quantity`}>
                  <div>
                    <Trans id="pages.joined.left-panel.share-paint.slides.Available" />
                  </div>

                  <div className={`${ClassName}--slides-select--info--quantity--data`}>
                    {logic.slidesQuantity}
                  </div>
                </div>
              ) : (
                <div className={`${ClassName}--slides-select--info--quantity`} />
              )}

              {/* Nav: Create new */}

              <div
                onClick={() => logic.onClickNavigate('create')}
                className={`${ClassName}--slides-select--info--nav`}
              >
                <span>+</span>
                <span> </span>
                <Trans id="common.NewShe" />
              </div>
            </div>

            {/* List */}

            <Scrollbars
              universal
              autoHide={false}
              style={{ height: slidesScroll.scrollbarHeight }}
              renderTrackVertical={(props) => <div {...props} className="scrollbar-vertical" />}
            >
              <div className={`${ClassName}--slides-select--list`}>
                {/* - */}

                {/* Empty List */}

                {logic.showEmptyList ? (
                  <div className={`${ClassName}--slides-select--list--empty`}>
                    {/* Icon */}

                    <div className={`${ClassName}--slides-select--list--empty--icon`}>
                      <ItemIcon size={24} />
                    </div>

                    {/* Text */}

                    <div className={`${ClassName}--slides-select--list--empty--text`}>
                      <Trans id="pages.joined.left-panel.share-paint.slides.EmptyList" />
                    </div>
                  </div>
                ) : undefined}

                {/* List with data */}

                {!logic.showEmptyList &&
                  logic.slidesMetaList.list.map((project, idx) => (
                    <div
                      key={project.id}
                      //
                      className={`${ClassName}--slides-select--list--item ${
                        idx === logic.slidesMetaList.list.length - 1 ? 'last' : ''
                      } ${project === logic.selectedProject ? 'selected' : ''}`}
                      //
                      onClick={() => logic.onClickItem(project)}
                    >
                      {/* Icon */}

                      <div className={`${ClassName}--slides-select--list--item--icon`}>
                        <ItemIcon size={24} />
                      </div>

                      {/* Project (G.Slide) */}

                      <div className={`${ClassName}--slides-select--list--item--name`}>
                        {project.title}
                      </div>
                    </div>
                  ))}

                {/* - */}
              </div>
            </Scrollbars>

            {/* Action */}

            {!logic.showEmptyList ? (
              <div
                ref={slidesScroll.refs.selectActionRef}
                className={`${ClassName}--slides-select--action`}
              >
                <Button
                  $colorType="primary"
                  intlId="pages.joined.left-panel.share-paint.slides.actions.Select"
                  $width="100%"
                  disabled={logic.shareImageIsRunning || !logic.selectedProject.id}
                  $running={logic.shareImageIsRunning}
                  $active={!logic.shareImageIsRunning && !!logic.selectedProject.id}
                  onClick={logic.onShareImageInSelectedSlide}
                />
              </div>
            ) : undefined}
          </div>
        ) : undefined}

        {/* -- */}
      </div>

      {/* Close */}

      <div ref={exitElementRef} className={`${ClassName}--close`}>
        <Button
          $colorType="tertiary-ghost"
          intlId="common.Close"
          $width="100%"
          $active={true}
          $running={false}
          disabled={false}
          onClick={onClickReqClose}
        />
      </div>

      {/* -- */}
    </STJoinedMobileFloatPanelCaptureShareSlides>
  )
}
