const prefix = 'mt-bcast-exit-epic'

// ~~~~~~ Mutators Types

export const EpicBcastExitMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicBcastExitMC = {
  ok: (reason: DN.Models.Exit.ExitReason) => ({
    type: EpicBcastExitMT.OK,
    payload: {
      reason,
    },
  }),
} as const
