import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../../models/app/model'
import { DrawService } from '../../../../../../services/draw'
import {
  PaintBCastStreamDrawAC,
  PaintBCastStreamDrawAT,
} from '../../../../../actions/paint-bcast-stream/draw/actions'
import { EpicPaintBcastStreamDrawCommandMC } from '../mutators'

type Action = ReturnType<typeof PaintBCastStreamDrawAC.cmdUndo>

export const paintBcastStreamDrawCommandsUndoEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(PaintBCastStreamDrawAT.CMD_UNDO),

    mergeMap(() => {
      const scope = state$.value.paintBcastStreamDraw.scope

      if (!scope) return EMPTY

      const cmdName = DrawService.Commands.undo()

      const isDirty = DrawService.getSomeItemIsVisible(scope)

      return of(EpicPaintBcastStreamDrawCommandMC.undo(cmdName || 'undo', isDirty))
    }),
  )
