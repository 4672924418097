import { TablesCookiesKindB } from '../tables/cookies/kind-b/component'

// ~~~~~~ Types

type Props = {
  sectionNumber: number
}

// ~~~~~~ Props

export const Cookies: React.FC<Props> = ({ sectionNumber }) => {
  return (
    <>
      <h2>{sectionNumber}. Our Use of Cookies.</h2>

      {/* <p>
          {sectionNumber}.1 Our Services may place and access certain first party Cookies on your computer or
          device. First party Cookies are those placed directly by Us and are used only by Us. We
          use Cookies to facilitate and improve your experience of Our Services and to provide and
          improve Our products and services. We have carefully chosen these Cookies and have taken
          steps to ensure that your privacy and personal data is protected and respected at all
          times.
        </p>
      */}

      <p>
        {sectionNumber}.1 By using Our Services you may also receive certain third party Cookies on
        your computer or device. Third party Cookies are those placed by websites, services, and/or
        parties other than Us. Third party Cookies are used on Our Services for website analytics
        only.
        {/* For more details, please refer to section 6, above, and to section {sectionNumber}.6
        below. These Cookies are not integral to the functioning of Our Services and your use and
        experience of Our Services will not be impaired by refusing consent to them. */}
      </p>

      <p>
        {sectionNumber}.2 All Cookies used by and on Our Services are used in accordance with
        current Cookie Law.
      </p>

      {/*
      <p>
        {sectionNumber}.4 Before Cookies are placed on your computer or device, you will be shown a pop-up
        requesting your consent to set those Cookies. By giving your consent to the placing of
        Cookies you are enabling Us to provide the best possible experience and service to you.
        You may, if you wish, deny consent to the placing of Cookies; however certain features of
        Our Services may not function fully or as intended.
      </p>
      */}

      {/* <p>{sectionNumber}.5 The following first party Cookies may be placed on your computer or device:</p> */}

      {/* <TablesCookiesKindA
        cookies={[
          {
            name: 'SESS#',
            purpose: 'Preserves users states across page requests',
            necessary: true,
          },
        ]}
      /> */}

      {/* <p>and the following third-party Cookies may be placed on your computer or device:</p> */}

      <p>
        {sectionNumber}.3 The following third-party Cookies may be placed on your computer or
        device:
      </p>

      <TablesCookiesKindB
        cookies={[
          // Stripe

          {
            name: '__stripe_mid',
            provider: 'Stripe',
            purpose:
              'Used by the Stripe payment gateway, it stores an ID needed to make the payment.',
          },

          {
            name: '__stripe_sid',
            provider: 'Stripe',
            purpose:
              'Used by the Stripe payment gateway, it stores a session ID needed to make the payment.',
          },

          // Google

          {
            name: '__Host-GAPS, __Host-1PLSID, ACCOUNT_CHOOSER, __Host-3PLSID, LSID, SMSV, LSOLH, 1P_JAR, AEC, LSOLH, CONSENT, NID, G_ENABLED_IDPS',
            provider: 'Google',
            purpose: 'Used by google account',
          },
        ]}
      />

      {/*
      <p>
        {sectionNumber}.6 Our Services uses analytics services provided by Google Analytics, HubSpot, Zopim,
        Pubmatic and Casalemedia. Website analytics refers to a set of tools used to collect and
        analyse anonymous usage information, enabling Us to better understand how Our Services is
        used. This, in turn, enables Us to improve Our Services and the products and services
        offered through it. You do not have to allow Us to use these Cookies, however whilst Our
        use of them does not pose any risk to your privacy or your safe use of Our Services, it
        does enable Us to continually improve Our Services, making it a better and more useful
        experience for you.
      </p>
      */}

      {/*
      <p>
        {sectionNumber}.7 The analytics service(s) used by Our Services use(s) Cookies to gather the required
        information.
      </p>
      */}

      {/* <p>{sectionNumber}.8 The analytics service(s) used by Our Services use(s) the following Cookies:</p> */}

      {/* <TablesCookiesKindC cookies={[]} /> */}

      {/*
      <p>
        {sectionNumber}.9 In addition to the controls that We provide, you can choose to enable or disable
        Cookies in your internet browser. Most internet browsers also enable you to choose whether
        you wish to disable all cookies or only third-party Cookies. By default, most internet
        browsers accept Cookies, but this can be changed. For further details, please consult the
        help menu in your internet browser or the documentation that came with your device.
      </p>
      */}

      <p>
        {sectionNumber}.4 You can choose to enable or disable Cookies in your internet browser. Most
        internet browsers also enable you to choose whether you wish to disable all cookies or only
        third-party Cookies. By default, most internet browsers accept Cookies, but this can be
        changed. For further details, please consult the help menu in your internet browser or the
        documentation that came with your device.
      </p>

      <p>
        {sectionNumber}.5 You can choose to delete Cookies on your computer or device at any time,
        however you may lose any information that enables you to access Our Services more quickly
        and efficiently including, but not limited to, login and personalisation settings.
      </p>

      <p>
        {sectionNumber}.6 It is recommended that you keep your internet browser and operating system
        up-to-date and that you consult the help and guidance provided by the developer of your
        internet browser and manufacturer of your computer or device if you are unsure about
        adjusting your privacy settings.
      </p>
    </>
  )
}
