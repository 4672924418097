import { useState } from 'react'
import { useSelector } from 'react-redux'

import { UtilsHoc } from '@dn/utils'
import { StoreState } from '../models/app/model'
import { ModalRootPortal } from '../portals/modal-root'

// ~~~~~~ HOC

function createComponent<P extends object>(WrappedComponent: React.FC<P>, modalId: string) {
  const WithStackedModal: React.FC<P> = (props: P) => {
    // ~~~~~~ State

    const modals = useSelector((state: StoreState) => state.modals.list)

    const [modalClickedOut, setModalClickedOut] = useState(0)

    // ~~~~~~ Computed

    const isModalInModals = !!modals.filter((id: string) => id === modalId).length

    const modalIdx = isModalInModals
      ? modals.reduce(
          (acc: number | undefined, cur: string, idx: number) =>
            acc === undefined ? (cur === modalId ? idx : undefined) : acc,
          undefined,
        )
      : undefined

    const modalLastIdx = modals.length - 1

    const isActiveModal = modalIdx === modalLastIdx

    // ~~~~~~ Handlers

    function onModalPortalClickOut() {
      setModalClickedOut(performance.now())
    }

    function onModalDissapeared() {
      setModalClickedOut(0)
    }

    // ~~~~~~ Render

    return isModalInModals ? (
      <ModalRootPortal modalElementId={modalId} onModalPortalClickOut={onModalPortalClickOut}>
        {/* #modal-root{ .modal { ... } } in global-style */}
        <div style={{ zIndex: modalIdx }} className="modal">
          <WrappedComponent
            {...props}
            isActiveModal={isActiveModal}
            modalId={modalId}
            modalClickedOut={isActiveModal ? modalClickedOut : 0}
            onModalDissapeared={onModalDissapeared}
          />
        </div>
      </ModalRootPortal>
    ) : null
  }

  // ~~~~~~ Display name

  WithStackedModal.displayName = `WithStackedModal(${UtilsHoc.getDisplayName(WrappedComponent)})`

  // ~~~~~~ Component

  return WithStackedModal
}

// ~~~~~~ Exposed HOC

export const withStackedModal = <P extends object>(component: React.FC<P>, modalId: string) =>
  createComponent(component, modalId)
