import { Observable, map, mergeMap, takeUntil } from 'rxjs'

import { ofType, StateObservable } from 'redux-observable'

import { StoreState } from '../../../../../models/app/model'
import { apiStreamManagerServerGet$ } from '../../../../../services/api-stream-manager/server/get'
import {
  ApiStreamManagerServerAC,
  ApiStreamManagerServerAT,
} from '../../../../actions/api-stream-manager/server/actions'

import { ApiUtils } from '../../../utils'
import { EpicApiServerSubCamMicMC } from './mutators'
import { cancelAll$$ } from '../../../../../subjects/cancel-all/subject'
import { cancelCamMic$$ } from '../../../cancel-now/cam-mic/subject'

type Action = ReturnType<typeof ApiStreamManagerServerAC.getServerSubCamMic>

export const apiStreamManagerServerSubCamMicGetEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStreamManagerServerAT.GET_SERVER_SUB_CAM_MIC),
    mergeMap(() => {
      cancelCamMic$$.next()

      // Use main broadcast id to start sub to cam-mic
      const broadcastSubCamMicId = `${state$.value.streamManagerSubMain.id}-cam`

      // On "cancel all" we abort the response, server/pub reducer uses
      // the cancel action from cancel-now to change its running state
      return apiStreamManagerServerGet$(broadcastSubCamMicId, 'subscribe', 3).pipe(
        takeUntil(cancelAll$$),
        takeUntil(cancelCamMic$$),
      )
    }),
    map((resServer) =>
      ApiUtils.isSuccess(resServer)
        ? EpicApiServerSubCamMicMC.ok(resServer.response)
        : EpicApiServerSubCamMicMC.error(resServer.response),
    ),
  )
