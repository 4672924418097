import { Config } from '../../config'

// ~~~~~~ Constants

const ClientId = Config.ThirdParty.Google.Auth.ClientId
const ScopesBroadcaster = Config.ThirdParty.Google.Auth.Scope
const ScopesViewer = Config.ThirdParty.Google.Auth.ScopeViewer

// ####################################################################################################
// ~~~~~~ Grant access: Google authorization code flow. Uses Scopes broadcaster
// ####################################################################################################

type GrantAccessData = {
  onSuccess: (code: string) => void
  onCancel?: () => void
}

const grantAccess = ({ onSuccess, onCancel }: GrantAccessData) => {
  if (Config.isExtension) return

  const client = window.google.accounts.oauth2.initCodeClient({
    client_id: ClientId,
    scope: ScopesBroadcaster,
    ux_mode: 'popup',

    callback: ({ code }) => {
      // If user press the cancel button on the dialog then code will be undefined

      if (!code) {
        onCancel && onCancel()

        return
      }

      onSuccess(code)
    },
  })

  // This will trigger the callback (onSuccess) afater user log in with google
  client.requestCode()
}

// ####################################################################################################
// ~~~~~~ Login: Google implicit flow. Uses scopes viewer
// ####################################################################################################

type LoginResultData = {
  access_token: string
  expires_at: number
  expires_in: number
}

type LoginData = {
  interactive?: boolean
  onSuccess: (data: LoginResultData) => void
}

// ~~~~~~ App (bcast)

const appLogin = ({ onSuccess }: LoginData) => {
  const client = window.google.accounts.oauth2.initTokenClient({
    client_id: ClientId,
    scope: ScopesViewer,

    callback: (authResponse) => {
      onSuccess({
        access_token: authResponse.access_token,
        expires_at: Date.now() + authResponse.expires_in * 1000,
        expires_in: authResponse.expires_in,
      })
    },
  })

  // This will trigger the callback (onSuccess) afater user log in with google
  client.requestAccessToken()
}

// ~~~~~~ Extension

const neverAt = new Date('01-01-3000').getTime()

const extensionLogin = ({ interactive, onSuccess }: LoginData) => {
  if (!chrome || !chrome.identity) {
    // Debug

    onSuccess({
      access_token: Config.ThirdParty.Google.Auth.DevelToken,
      expires_at: neverAt,
      expires_in: neverAt,
    })

    return
  }

  const callback: any = (token: string, grantedScopes: string[]) => {
    onSuccess({
      access_token: token,
      expires_at: neverAt,
      expires_in: neverAt,
    })
  }

  chrome.identity.getAuthToken(
    { interactive: typeof interactive === 'boolean' ? interactive : true },
    callback,
  )
}

// ~~~~~ Login

const login = (loginData: LoginData) => {
  Config.isExtension ? extensionLogin(loginData) : appLogin(loginData)
}

// ~~~~~~

export const GoogleAuth = {
  grantAccess,
  login,
} as const
