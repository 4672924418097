import { HooksData } from '@dn/hooks'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Config } from '../../../config'
import { ModalsIds } from '../../../constants/modals'
import { StoreState } from '../../../models/app/model'
import { ModalsMC } from '../../../store/actions-mutators/modals/mutators'
import { ApiBcastUserSettingsAC } from '../../../store/actions/api-bcast/user-settings/actions'

// ~~~~~~ Component

export const FeedbackEndBcast = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Store

  const userId = useSelector((state: StoreState) => state.currentUser.id)

  const exit = useSelector((state: StoreState) => state.exit)

  const numOfBcast = useSelector((store: StoreState) => store.userSettings.num_of_bcast)

  const editUserSettingsUiStatus = useSelector(
    (store: StoreState) => store.userSettingsEdit.uiStatus,
  )

  // ~~~~~~ Computed

  const prevNumOfBcast = HooksData.PrevValue.useHook(numOfBcast)

  const isUpdatingSettings = editUserSettingsUiStatus === 'running'

  const shouldShowFeedback =
    userId && typeof prevNumOfBcast === 'number' && prevNumOfBcast + 1 === numOfBcast
      ? numOfBcast === 1 || numOfBcast === Config.App.Feedback.BcastEndTimesToShowAgain + 1
      : false

  const shouldExitOk = exit.reason === 'user-manual-exit'

  // ~~~~~~ Effects

  // - Increment num of bcast

  useEffect(() => {
    if (!userId || !shouldExitOk || !exit.shouldExit || isUpdatingSettings || shouldShowFeedback) {
      return
    }

    dispatch(ApiBcastUserSettingsAC.update(userId, { num_of_bcast: numOfBcast + 1 }))

    //
  }, [
    dispatch,
    exit.shouldExit,
    isUpdatingSettings,
    numOfBcast,
    shouldExitOk,
    shouldShowFeedback,
    userId,
  ])

  // - Show

  useEffect(() => {
    if (!userId || !shouldShowFeedback) return

    dispatch(ModalsMC.open(ModalsIds.EndBcastFeedback))

    //
  }, [dispatch, shouldShowFeedback, userId])

  // ~~~~~~ Render

  return null
}
