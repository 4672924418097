import { Config } from '../../../../config'
import { AppInitState } from '../../../../models/app/model'
import { UtilsLog } from '../../../../utils/logs'
import { UtilsStore } from '@dn/utils'
import { DebuggerMC, DebuggerMT } from '../../../actions-mutators/debugger/mutators'
import { ApiDebuggerAT } from '../../../actions/api-debugger/actions'
import { PaintBCastStreamDrawAT } from '../../../actions/paint-bcast-stream/draw/actions'
import { EpicApiDebuggerMT } from '../../../epics/api-bcast/debugger/mutators'
import { HocIntl } from '@dn/hocs'

const initState = AppInitState.debugger

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Debug enable
// ####################################################################################################

reducer[DebuggerMT.ENABLE] = (
  state: State,
  { payload }: ReturnType<typeof DebuggerMC.enable>,
): State => {
  return {
    ...state,
    enable: true,
    isQA: payload.kind === 'qa',
    sendError: '',
  }
}

// ####################################################################################################
// ~~~~~~ Debug disable
// ####################################################################################################

reducer[DebuggerMT.DISABLE] = (state: State): State => {
  return {
    ...state,
    enable: false,
    actions: [],
    sendError: '',
  }
}

// ####################################################################################################
// ~~~~~~ Send debug
// ####################################################################################################

reducer[ApiDebuggerAT.SEND] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    sendError: '',
  }
}

// ####################################################################################################
// ~~~~~~ Sended ok
// ####################################################################################################

reducer[EpicApiDebuggerMT.OK] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'completed',
  }
}

// ####################################################################################################
// ~~~~~~ Sended ko
// ####################################################################################################

reducer[EpicApiDebuggerMT.ERR] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
    sendError: 'Submission failed',
  }
}

// ~~~~~~

export const debuggerReducer = UtilsStore.dynReducer(
  initState,
  reducer,
  (state) => state,

  // All actions
  (state, action) => {
    Config.Logs.Actions && UtilsLog.devLog('action ~>', action)

    if (!state.enable) return state

    // If pass skip

    if (action.type === 'mt-plan-field-ch-pass') return state

    // If change lang, skip messages

    if (action.type === HocIntl.Mutators.MT.CHANGE_LANG) {
      return {
        ...state,
        actions: [
          ...state.actions,
          {
            type: action.type,
            payload: {
              lang: action.payload.lang,
            },
            meta: {
              pn: performance.now(),
            },
          },
        ],
      }
    }

    // If update-view (from drawings), skip messages

    if (action.type === PaintBCastStreamDrawAT.UPDATE_VIEW) return state

    return {
      ...state,
      actions: [
        ...state.actions,
        {
          ...action,
          meta: {
            ...(action.meta || {}),
            pn: performance.now(),
          },
        },
      ],
    }
  },
)
