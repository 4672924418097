import { Zoom } from '../../../constants/zoom'
import { UtilsStore } from '@dn/utils'

// ~~~~~~ Constants

const ErrorType: Infos = { id: 'wildcard' }

// ####################################################################################################
// ~~~~~~ Model
// ####################################################################################################

const initModel: DN.Models.PaintBcastStream.Model = {
  enabled: false,
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-paint-bcast-stream-edit'

export const PaintBcastStreamEditFieldsMR = {
  enabled: UtilsStore.genFieldMR(prefix, initModel, 'enabled', initModel.enabled, ErrorType),
}

// ####################################################################################################
// ~~~~~~ Video
// ####################################################################################################

const initVideoModel: DN.Models.PaintBcastStream.Video.Model = {
  video: null,

  originalVideoW: 0,
  originalVideoH: 0,

  zoom: Zoom.Min,
}

// ~~~~~~ Fields Mutators/Reducers

const prefixVideo = 'mt-paint-bcast-stream-video-model'

export const PaintBcastStreamVideoMR = {
  video: UtilsStore.genBasicFieldMR(prefixVideo, initVideoModel, 'video', initVideoModel.video),

  originalVideoW: UtilsStore.genBasicFieldMR(
    prefixVideo,
    initVideoModel,
    'originalVideoW',
    initVideoModel.originalVideoW,
  ),

  originalVideoH: UtilsStore.genBasicFieldMR(
    prefixVideo,
    initVideoModel,
    'originalVideoH',
    initVideoModel.originalVideoH,
  ),

  zoom: UtilsStore.genBasicFieldMR(prefixVideo, initVideoModel, 'zoom', initVideoModel.zoom),
} as const

// ####################################################################################################
// ~~~~~~ Pic
// ####################################################################################################

const initPicModel: DN.Models.PaintBcastStream.Pic.Model = {
  canvas: null,
}

// ~~~~~~ Fields Mutators/Reducers

const prefixPic = 'mt-paint-bcast-stream-pic-model'

export const PaintBcastStreamPicModelFieldsMR = {
  canvas: UtilsStore.genFieldMR(prefixPic, initPicModel, 'canvas', initPicModel.canvas, ErrorType),
}

// ####################################################################################################
// ~~~~~~ Draw
// ####################################################################################################

const initDrawModel: DN.Models.PaintBcastStream.Draw.Model = {
  canvas: null,
  scope: undefined,
  strokeWidth: 3,
  hasAlpha: false,
  colorKey: 'BLUE',
  activePen: 'pen',
  undos: 0,
  redos: 0,
  lastCmd: 'init',
  isDirty: false,
  isDownloaded: false,
  isErasing: false,
}

// ~~~~~~ Fields Mutators/Reducers

const prefixDraw = 'mt-paint-bcast-stream-draw-model'

export const PaintBcastStreamDrawModelFieldsMR = {
  canvas: UtilsStore.genFieldMR(
    prefixDraw,
    initDrawModel,
    'canvas',
    initDrawModel.canvas,
    ErrorType,
  ),

  isDownloaded: UtilsStore.genFieldMR(
    prefixDraw,
    initDrawModel,
    'isDownloaded',
    initDrawModel.isDownloaded,
    ErrorType,
  ),
}

// ~~~~~~

export const PaintBcastStreamModel = {
  initModel,
  initVideoModel,
  initPicModel,
  initDrawModel,
}
