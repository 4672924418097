import { map, mergeMap, Observable } from 'rxjs'
import { StateObservable, ofType } from 'redux-observable'
import { StoreState } from '../../../../../models/app/model'
import { apiBcastRoomVersion$ } from '../../../../../services/api-bcast/room/version'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastRoomVersionMC } from './mutators'
import { ApiBcastRoomAC, ApiBcastRoomAT } from '../../../../actions/api-bcast/room/actions'

type Action = ReturnType<typeof ApiBcastRoomAC.version>

export const apiBcastRoomVersionEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastRoomAT.VERSION),

    mergeMap(({ payload }) => {
      const bcastId = payload.bcastId

      return apiBcastRoomVersion$(bcastId)
    }),

    map((res) =>
      ApiUtils.isSuccess(res)
        ? EpicApiBcastRoomVersionMC.ok(res.response.version)
        : EpicApiBcastRoomVersionMC.error(res.response),
    ),
  )
