import { UtilsStore } from '@dn/utils'

// ~~~~~~ Contants

const timerId = setInterval(() => undefined, 1_000_000)

clearInterval(timerId)

const ErrorType: Infos = { id: 'wildcard' }

// ~~~~~~ Init

const initModel: DN.Models.StoreDuration.Model = {
  timerId,
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-store-duration-fields'

export const StoreDurationFieldsMR = {
  timerId: UtilsStore.genFieldMR(prefix, initModel, 'timerId', initModel.timerId, ErrorType),
}

// ~~~~~~

export const StoreDurationModel = {
  initModel,
}
