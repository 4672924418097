const prefix = 'mt-api-subscriptions-upgrade-plan'

// ~~~~~~ Mutators Types

export const ApiSubscriptionsUpgradePlanEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiSubscriptionsUpgradePlanEpicMC = {
  ok: (subscription: DN.Models.StoreSubscription.Api.UpgradePlan.ResParsed) => ({
    type: ApiSubscriptionsUpgradePlanEpicMT.OK,
    payload: {
      subscription,
    },
  }),

  error: (errors: DN.Models.StoreSubscription.Api.UpgradePlan.ResError) => ({
    type: ApiSubscriptionsUpgradePlanEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
