import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { CommChannelAC } from '../../../../store/actions/comm-channel/actions'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { DialogConfirmation } from '../component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmStopCamMicStream)

// ~~~~~~ Component

export const DialogConfirmStopCamMicStream = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const { publisher } = useSelector((state: StoreState) => state.streamManagerPubCamMic)

  // ~~~~~~ Computed

  // ~~~~~~ Handlers

  function onClickConfirm() {
    if (!publisher) return

    dispatch(CommChannelAC.sendBcasterCamMicStop())

    setDoClose(performance.now())
  }

  function onClickClose() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      Dialog={Dialog}
      title="dialogs.confirm.stop-cam-mic-stream.Title"
      contentText="dialogs.confirm.stop-cam-mic-stream.Content"
      okButtonIntl="dialogs.confirm.stop-cam-mic-stream.actions.Yes"
      isRunning={!publisher}
      doClose={doClose}
      onEndDisappear={() => undefined}
      onClickClose={onClickClose}
      onClickConfirm={onClickConfirm}
      okButtonColorType="error"
    />
  )
}
