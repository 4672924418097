import { Observable, of, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

const { ForPub } = ApiBcastRoutes.BcastRoom

// ####################################################################################################
// ~~~~~~ Bcast Room for PUB
// ####################################################################################################

type Res = {
  id: string
  uuid: string
  server: string // ip
  token: string
}

type ResParsed = DNApiResParsed<Res>

type ResError = DNApiError<Infos[]>

export const apiBcastRoomForPub$ = (token: string, bcastId: string, headers?: AppHeaders) => {
  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersWithAuthForRoom(token, headers),
    method: ForPub.Method,
    url: ForPub.getUrl(bcastId),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.server.get.Error', values: { value: res.status } }],
      })
    }),
  )
}
