import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Routes } from '../../../../constants/routes/routes'
import { StoreState } from '../../../../models/app/model'
import { UiLayoutPanelsFieldsMR } from '../../../../models/bcast/ui-layout-panels/model'
import { OnboardingTooltipsChangeSource } from './change-source/component'
import { OnboardingTooltipsLiveDraw } from './live-draw/component'
import { OnboardingTooltipsShare } from './share/component'

// ~~~~~~ Component

export const OnboardingTooltips = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const path = useLocation().pathname

  // ~~~~~~ State

  // - Store

  const userSettings = useSelector((state: StoreState) => state.userSettings)

  const showChangeSourceExtensionMenu = useSelector(
    (state: StoreState) => state.uiExtensionMenus.showChangeSource,
  )

  const videoIsPlaying = useSelector((state: StoreState) => state.sharedMainStream.videoIsPlaying)

  const livePaintEnabled = useSelector((state: StoreState) => state.livePaint.enabled)

  const leftPanelOpenState = useSelector(
    (state: StoreState) => state.uiLayoutPanels.leftPanelOpenState,
  )

  // ~~~~~~ Computed

  const isValidPath = path === Routes.Started

  const isEnable =
    videoIsPlaying &&
    isValidPath &&
    userSettings.isOnboardingRunning &&
    userSettings.onboarding_create_a_bcast &&
    !userSettings.onboarding_is_completed

  const showChangeSource = !showChangeSourceExtensionMenu && !userSettings.onboarding_change_source

  const showShare =
    !livePaintEnabled && userSettings.onboarding_change_source && !userSettings.onboarding_share

  const showLiveDraw = userSettings.onboarding_share && !userSettings.onboarding_live_draw

  const isLeftPanelOpen = leftPanelOpenState === 'open'

  // ~~~~~~ Effects

  // - Open left panel if is closed

  useEffect(() => {
    if (!showShare || isLeftPanelOpen) return

    dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('open'))

    //
  }, [dispatch, isLeftPanelOpen, showShare])

  // ~~~~~~ Render

  if (!isEnable) return null

  return (
    <>
      {/* 1 - Change Source */}

      <AnimatePresence>
        {showChangeSource ? <OnboardingTooltipsChangeSource /> : undefined}
      </AnimatePresence>

      {/* 2 - Share */}

      <AnimatePresence>
        {isLeftPanelOpen && showShare ? <OnboardingTooltipsShare /> : undefined}
      </AnimatePresence>

      {/* 3 - Live Draw */}

      <AnimatePresence>{showLiveDraw ? <OnboardingTooltipsLiveDraw /> : undefined}</AnimatePresence>
    </>
  )
}
