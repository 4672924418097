import { AppInitState } from '../../../../models/app/model'
import { ScreenshotFieldsMR } from '../../../../models/bcast/screenshot/model'
import { UtilsStore } from '@dn/utils'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'

// ~~~~~~

const initState = AppInitState.screenShot

type State = typeof initState

const reducer: any = {
  ...ScreenshotFieldsMR.canvasImageSource.Reducer,
}

// ####################################################################################################
// ~~~~~~ Cancel all
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~

export const screenShotReducer = UtilsStore.dynReducer(initState, reducer)
