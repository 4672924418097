const prefix = 'at-share-cam-mic-stream'

// ~~~~~ Action types

export const ShareCamMicStreamAT = {
  GET_STREAM: `${prefix}-get-stream`,
} as const

// ~~~~~~ Action Creators

type DeviceSelectData = {
  getCam: boolean
  getMic: boolean
  cam: MediaDeviceInfo | undefined
  camSize: { width: number; height: number }
  mic: MediaDeviceInfo | undefined
}

export const ShareCamMicStreamAC = {
  getStream: (data: DeviceSelectData) => ({
    type: ShareCamMicStreamAT.GET_STREAM,
    payload: {
      data,
    },
  }),
} as const
