import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../../models/app/model'
import { DrawService } from '../../../../../../services/draw'
import {
  PaintBCastStreamDrawAC,
  PaintBCastStreamDrawAT,
} from '../../../../../actions/paint-bcast-stream/draw/actions'
import { EpicPaintBcastStreamDrawCommandMC } from '../mutators'

type Action = ReturnType<typeof PaintBCastStreamDrawAC.cmdClear>

export const paintBcastStreamDrawCommandsClearEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(PaintBCastStreamDrawAT.CMD_CLEAR),

    mergeMap(() => {
      const { lastCmd } = state$.value.paintBcastStreamDraw

      if (lastCmd === 'clear') return EMPTY

      DrawService.Commands.clear()

      return of(EpicPaintBcastStreamDrawCommandMC.clear())
    }),
  )
