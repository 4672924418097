import { initializeReduxDispatcher } from '../dispatcher/extension-partial-service'
import { startListening } from '../start-listening/extension-partial-service'

let initialized = false

export const init = () => {
  if (initialized) return

  initialized = true

  initializeReduxDispatcher()

  startListening()
}
