import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { STProps, STPaintToolbarButtonsColor } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/color')

// ~~~~~~ Types

type Props = STProps & {
  onClick: () => void
}

// ~~~~~~ Component

export const PaintToolbarButtonsColor: React.FC<Props> = ({
  // Style

  $selected,
  $colorKey,
  $disabled,

  onClick,
}) => {
  // ~~~~~~ Render

  return (
    <STPaintToolbarButtonsColor
      $colorKey={$colorKey}
      $selected={$selected}
      $disabled={$disabled}
      onClick={onClick}
    >
      <Icon size={24} />
    </STPaintToolbarButtonsColor>
  )
}
