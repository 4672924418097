import { AppInitState } from '../../../../../models/app/model'
import { UiPipFullscreenFieldsMR } from '../../../../../models/bcast/ui-pip-fullscreen/model'
import { UtilsStore } from '@dn/utils'

const initState = AppInitState.uiPipFullscreen

// type State = typeof initState

const reducer: any = {
  ...UiPipFullscreenFieldsMR.updatedPn.Reducer,
}

// ~~~~~~

export const uiPipFullscreenReducer = UtilsStore.dynReducer(initState, reducer, (state) => {
  return state
})
