import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useCamMicSubSound } from '../../../../../../hooks/cam-mic/sub/use-cam-mic-sub-speakers-are-enabled'
import { StreamManagerSubCamMicFieldsMR } from '../../../../../../models/stream-manager/sub/cam-mic/model'
import { LazySVG } from '../../../../../svgs/lazy-svg/component'
import { Trans } from '../../../../intl/trans'
import { ClassName, STCamMicMicSoundNotMobileIsOffBar } from './style'

// ~~~~~~ Constants

const SpeakersOn = LazySVG('icons/speakers-on')

// ~~~~~~ Types

type Props = {
  className?: string
}

// ~~~~~~ Component

export const CamMicMicSoundNotMobileIsOffBar: React.FC<Props> = ({ className }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const sound = useCamMicSubSound()

  // ~~~~~~ State

  const [notShowMore, setNotShowMore] = useState(sound.isVirgin)

  // ~~~~~~ Handlers

  function onToggleSpeaker() {
    dispatch(StreamManagerSubCamMicFieldsMR.iMutedAudio.MC.change(sound.speakersAreEnabled))
  }

  // ~~~~~~ Render

  if (!notShowMore) return null

  return (
    <STCamMicMicSoundNotMobileIsOffBar className={className}>
      <AnimatePresence onExitComplete={() => setNotShowMore(true)}>
        {sound.isVirgin ? (
          <motion.div
            className={`${ClassName}--content`}
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              type: 'easeOut',
              duration: 0.5,
            }}
          >
            {/* Icon */}

            <div className={`${ClassName}--icon`}>
              <SpeakersOn size={20} />
            </div>

            {/* Sound state */}

            <div className={`${ClassName}--sound-state`}>
              <Trans id="common-components.cam-mic-mic-sound.NoSound" />
            </div>

            {/* Action */}

            <div className={`${ClassName}--action`} onClick={onToggleSpeaker}>
              <Trans id="common-components.cam-mic-mic-sound.TurnOn" />
            </div>

            {/* -- */}
          </motion.div>
        ) : undefined}
      </AnimatePresence>
    </STCamMicMicSoundNotMobileIsOffBar>
  )
}
