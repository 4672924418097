import { AppInitState } from '../../../../models/app/model'
import { LivePaintEditFieldsMR } from '../../../../models/bcast/live-paint/model'
import { UtilsStore } from '@dn/utils'
import { CancelNowAT } from '../../../actions/cancel-now/actions'

const initState = AppInitState.livePaint

type State = typeof initState

const reducer: any = {
  ...LivePaintEditFieldsMR.enabled.Reducer,
  ...LivePaintEditFieldsMR.genAltCanvas.Reducer,
}

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ~~~~~~

export const livePaintReducer = UtilsStore.dynReducer(initState, reducer)
