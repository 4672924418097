const prefix = 'mt-extension-user-data'

// ~~~~~~ Mutators Types

export const EpicExtensionUserDataMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicExtensionUserDataMC = {
  ok: (data: DN.Services.Extension.AppInfoPayload) => ({
    type: EpicExtensionUserDataMT.OK,
    payload: data,
  }),
} as const
