import { useState } from 'react'
import { ModalsIds } from '../../../../constants/modals'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { DialogConfirmation } from '../component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmSignInTimeout)

// ~~~~~~ Component

export const DialogConfirmSignInTimeout = () => {
  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  // ~~~~~~ Handlers

  function onClickOk() {
    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      Dialog={Dialog}
      predefIcon="error"
      okButtonColorType="error"
      title="dialogs.confirm.sign-in-time-out.Title"
      contentText="dialogs.confirm.sign-in-time-out.Content"
      okButtonIntl="common.OK"
      isRunning={false}
      doClose={doClose}
      onEndDisappear={() => undefined}
      onClickClose={() => undefined}
      onClickConfirm={onClickOk}
      hideCloseButton={true}
    />
  )
}
