import { JoinedMobileContentMain } from './main/component'
import { ClassName, STJoinedMobileContent } from './style'

// ~~~~~~ Component

export const JoinedMobileContent = () => {
  // ~~~~~~ Render

  return (
    <STJoinedMobileContent>
      {/* Main content (video + buttons) */}

      <div className={`${ClassName}--main`}>
        <JoinedMobileContentMain />
      </div>

      {/* - */}
    </STJoinedMobileContent>
  )
}
