import { ofType, StateObservable } from 'redux-observable'
import { map, Observable } from 'rxjs'
import { ColorToKey, ColorToKeyKey, PenColor } from '../../../../../constants/pen-color'
import { StoreState } from '../../../../../models/app/model'
import { DrawService } from '../../../../../services/draw'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../actions/live-paint/draw/actions'
import { EpicLivePaintDrawUpdatePropsMC } from './mutators'

type Action = ReturnType<typeof LivePaintDrawAC.updateProps>

export const livePaintUpdatePropsEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.UPDATE_PROPS),

    map(({ payload }) => {
      const curActivePen = state$.value.livePaintDraw.activePen

      const { strokeWidth, hasAlpha, colorKey, activePen, isErasing } = payload

      strokeWidth !== undefined && DrawService.Set.strokeWidth(strokeWidth)

      hasAlpha !== undefined && DrawService.Set.hasAlpha(hasAlpha)

      colorKey !== undefined && DrawService.Set.color(PenColor[colorKey].color)

      typeof isErasing !== 'undefined' && DrawService.Set.isErasing(isErasing)

      const drawState = DrawService.getState()

      return EpicLivePaintDrawUpdatePropsMC.ok({
        strokeWidth: drawState.StrokeWidth,
        hasAlpha: drawState.HasAlpha,
        colorKey: ColorToKey[drawState.Color as ColorToKeyKey],
        activePen: activePen || curActivePen,
        isErasing: drawState.IsErasing,
      })
    }),
  )
