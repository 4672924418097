import { AppInitState } from '../../../../models/app/model'
import { SharedCamMicModelFieldsMR } from '../../../../models/bcast/shared-cam-mic/model'
import { UtilsStore } from '@dn/utils'
import { BroadcastAT } from '../../../actions/bcast/actions'
import { CommChannelAT } from '../../../actions/comm-channel/actions'
import { ShareCamMicStreamAT } from '../../../actions/share-cam-mic-stream/actions'
import { EpicBcastCommChannelMessagesToServerBcasterCamDisableMT } from '../../../epics/comm-channel/messages/to-server/bcaster/cam/disable/mutators'
import { EpicBcastCommChannelMessagesToServerBcasterCamEnableMT } from '../../../epics/comm-channel/messages/to-server/bcaster/cam/enable/mutators'
import { EpicBcastCommChannelMessagesToServerBcasterMicDisableMT } from '../../../epics/comm-channel/messages/to-server/bcaster/mic/disable/mutators'
import { EpicBcastCommChannelMessagesToServerBcasterMicEnableMT } from '../../../epics/comm-channel/messages/to-server/bcaster/mic/enable/mutators'
import { EpicCommChannelOnPubAvailableSendCamMicStartEpicMT } from '../../../epics/comm-channel/on-pub-available-send-cam-mic-stream-start/mutators'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'
import { EpicCancelNowCamMicMT } from '../../../epics/cancel-now/cam-mic/mutators'
import {
  EpicShareCamMicStreamGetStreamMC,
  EpicShareCamMicStreamGetStreamMT,
} from '../../../epics/share-cam-mic-stream/get-stream/mutators'

// ~~~~~~

const initState = AppInitState.sharedCamMicStream

type State = typeof initState

const reducer: any = {
  ...SharedCamMicModelFieldsMR.errors.Reducer,
}

// ####################################################################################################
// ~~~~~~ Get cam and mic stream START
// ####################################################################################################

reducer[ShareCamMicStreamAT.GET_STREAM] = (state: State): State => {
  return {
    ...state,

    errors: [],

    getCamMicUiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Pub cam-mic - Start
// ####################################################################################################

reducer[BroadcastAT.START_PUB_CAM_MIC] = (state: State): State => {
  return {
    ...state,

    camMicUiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Bcaster cam-mic stream start - ACK
// ####################################################################################################

reducer[EpicCommChannelOnPubAvailableSendCamMicStartEpicMT.OK] = (state: State): State => {
  return {
    ...state,

    camMicUiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ Bcaster cam-mic stream start - Timeout
// ####################################################################################################

reducer[EpicCommChannelOnPubAvailableSendCamMicStartEpicMT.TIMEOUT] = (state: State): State => {
  return {
    ...state,

    camMicUiStatus: 'timeout',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Disable cam - START
// ####################################################################################################

reducer[CommChannelAT.SEND_BCASTER_CAM_DISABLE] = (state: State): State => {
  return {
    ...state,
    camUiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Disable cam - OK
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesToServerBcasterCamDisableMT.OK] = (state: State): State => {
  return {
    ...state,
    camUiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Disable cam - TIMEOUT
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesToServerBcasterCamDisableMT.TIMEOUT] = (
  state: State,
): State => {
  return {
    ...state,
    camUiStatus: 'timeout',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Enable cam - START
// ####################################################################################################

reducer[CommChannelAT.SEND_BCASTER_CAM_ENABLE] = (state: State): State => {
  return {
    ...state,
    camUiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Enable cam - OK
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesToServerBcasterCamEnableMT.OK] = (state: State): State => {
  return {
    ...state,
    camUiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Enable cam - TIMEOUT
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesToServerBcasterCamEnableMT.TIMEOUT] = (state: State): State => {
  return {
    ...state,
    camUiStatus: 'timeout',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Disable mic - START
// ####################################################################################################

reducer[CommChannelAT.SEND_BCASTER_MIC_DISABLE] = (state: State): State => {
  return {
    ...state,
    micUiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Disable mic - OK
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesToServerBcasterMicDisableMT.OK] = (state: State): State => {
  return {
    ...state,
    micUiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Disable mic - TIMEOUT
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesToServerBcasterMicDisableMT.TIMEOUT] = (
  state: State,
): State => {
  return {
    ...state,
    micUiStatus: 'timeout',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Enable mic - START
// ####################################################################################################

reducer[CommChannelAT.SEND_BCASTER_MIC_ENABLE] = (state: State): State => {
  return {
    ...state,
    micUiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Enable mic - OK
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesToServerBcasterMicEnableMT.OK] = (state: State): State => {
  return {
    ...state,
    micUiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ ACK: Enable mic - TIMEOUT
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesToServerBcasterMicEnableMT.TIMEOUT] = (state: State): State => {
  return {
    ...state,
    micUiStatus: 'timeout',
  }
}

// ####################################################################################################
// ~~~~~~ Get cam and mic stream OK
// ####################################################################################################

reducer[EpicShareCamMicStreamGetStreamMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareCamMicStreamGetStreamMC.ok>,
): State => {
  return {
    ...state,

    getCamMicUiStatus: 'completed',

    curCamMicStream: payload.stream,
  }
}

// ####################################################################################################
// ~~~~~~ Get cam and mic stream - ERROR
// ####################################################################################################

reducer[EpicShareCamMicStreamGetStreamMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicShareCamMicStreamGetStreamMC.error>,
): State => {
  return {
    ...state,

    errors: payload.errors,

    getCamMicUiStatus: 'completed',
  }
}

// ####################################################################################################
// ~~~~~~ Cancel all
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Cancel cam-mic
// ####################################################################################################

reducer[EpicCancelNowCamMicMT.DONE] = (state: State): State => {
  return {
    ...initState,
    errors: state.errors,
  }
}

// ~~~~~~

export const sharedCamMicStreamReducer = UtilsStore.dynReducer(initState, reducer)
