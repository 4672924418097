import styled from 'styled-components'

import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'

// ~~~~~~ Component

export const STDialogYouCanBuyBroadcast = styled.div`
  a,
  .open-dialog {
    ${Fonts.Weight[700]}

    text-decoration: none;
    cursor: pointer;

    color: ${theme(Colors.BRONX_30_40)};

    &:hover {
      color: ${theme(Colors.BRONX_30_40)};
    }

    &:active {
      color: ${theme(Colors.BRONX_30_40)};
    }
  }
`
