import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap } from 'rxjs'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreProductsList$ } from '../../../../../services/api-store/products/list'
import {
  ApiStoreProductsAC,
  ApiStoreProductsAT,
} from '../../../../actions/api-store/products/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiProductsListMC } from './mutators'

// ~~~~~ Types

type Action = ReturnType<typeof ApiStoreProductsAC.listProducts>

// ~~~~~~ Epic

export const apiStoreProductsListEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreProductsAT.LIST_PRODUCTS),
    mergeMap(({ payload }) =>
      apiStoreProductsList$(payload.productId).pipe(
        map((res) => ({
          productId: payload.productId,
          res,
        })),
      ),
    ),
    map(({ productId, res }) =>
      ApiUtils.isSuccess(res)
        ? EpicApiProductsListMC.okBroadcastProducts(
            res.response as DN.Models.StoreProducts.Api.List.ResParsedBroadcastProducts,
          )
        : EpicApiProductsListMC.error(productId, res.response),
    ),
  )
