import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../models/app/model'
import { BroadcastDurationMC } from '../../store/actions-mutators/broadcast-duration/mutators'
import { CancelReasons } from '../../store/actions/cancel-now/actions'
import { BcastTrackEvents } from '../../services/track-events'
import { useTrackUserContext } from '../track-events/use-track-user-context'
import { useTrackStreamContext } from '../track-events/use-track-stream-context'
import { LocalStorageStoreDuration } from '../../services/storage/local/store-duration'

// ~~~~~~ Hook

export const useBroadcastEnded = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  const streamContext = useTrackStreamContext()

  // ~~~~~~ State

  const duration = useSelector((state: StoreState) => state.bcastDuration)

  const currentUserEmail = useSelector((state: StoreState) => state.currentUser.email)

  const subscription = useSelector((state: StoreState) => state.subscription)

  const storeDurationTimerId = useSelector((state: StoreState) => state.storeDuration.timerId)

  // const exit = useSelector((state: StoreState) => state.exit)

  // ~~~~~~ Effects

  // - Send duration and track events

  useEffect(() => {
    if (duration.kind === 'none' || !duration.start || !duration.end) return

    // Duration

    const diff = Math.abs(duration.start.getTime() - duration.end.getTime())

    const mins = Math.floor(diff / 1000 / 60)

    duration.kind === 'pub'
      ? BcastTrackEvents.calls.Streams.PubMain.duration({ mins }, userContext, streamContext)
      : BcastTrackEvents.calls.Streams.SubMain.duration({ mins }, streamContext)

    // Rem store duration

    clearInterval(storeDurationTimerId)

    LocalStorageStoreDuration.clear()

    // End Reason

    switch (duration.endReason) {
      case CancelReasons.ScreenSharingCanceled:
        break

      case CancelReasons.ScreenSharingEnded:
        // ...
        break

      case CancelReasons.ScreenStreamHasNotAMediaTrack:
        // ...
        break

      case CancelReasons.NoScreenStreamFound:
        // Skip
        break

      case CancelReasons.NoStreamQualityFound:
        // Skip
        break

      case CancelReasons.UserStopped:
        // ...
        break

      case CancelReasons.SessionEnded:
        // ...
        break

      case CancelReasons.UserLimitReached:
        // ...
        break

      case CancelReasons.TimeLimitReached:
        // ...
        break

      case CancelReasons.BeforeUnload:
        // ...
        break

      case CancelReasons.ConnectionClosed:
        // ...
        break

      case CancelReasons.DeviceOffline:
        // ...
        break

      case CancelReasons.Unknown:
        // ...
        break

      case CancelReasons.InvalidName:
        // ...
        break

      case CancelReasons.PublishFail:
        // ...
        break
    }

    // Clear

    dispatch(BroadcastDurationMC.clear())

    //
  }, [
    currentUserEmail,
    dispatch,
    duration,
    storeDurationTimerId,
    streamContext,
    subscription,
    userContext,
  ])
}
