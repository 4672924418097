import { useDispatch, useSelector } from 'react-redux'
import { ExitEditingImageDialogActions } from '../../../../../../../components/dialogs/exit-editing-image/component'
import { ModalsIds } from '../../../../../../../constants/modals'
import { StoreState } from '../../../../../../../models/app/model'
import { UiLayoutPanelsFieldsMR } from '../../../../../../../models/bcast/ui-layout-panels/model'
import { UiLayoutViewerMobileFieldsMR } from '../../../../../../../models/bcast/ui-layout-viewer-mobile/model'
import { ModalsMC } from '../../../../../../../store/actions-mutators/modals/mutators'
import { useJoinedBehaviourLogicContentMainToolbarsCapturePaintDraw } from './draw-hook'

// ~~~~~~ Hook

export const useJoinedBehaviourLogicContentMainToolbarsCapturePaintLogic = () => {
  // ~~~~~~ Hooks

  const draw = useJoinedBehaviourLogicContentMainToolbarsCapturePaintDraw()

  const dispatch = useDispatch()

  // ~~~~~~ State

  const { googleAccessToken } = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Handlers

  function onClickSharePaint() {
    draw.genImageToShare()

    // GSlides slider open state

    if (!googleAccessToken) {
      dispatch(UiLayoutPanelsFieldsMR.shareImageToSlidesState.MC.change('closed'))
    }

    // Desktop/Tablet

    // - Open share paint panel

    dispatch(UiLayoutPanelsFieldsMR.leftPanelShowSharePaints.MC.change(true))

    dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('open'))

    // Mobile

    // - Open float panel

    dispatch(UiLayoutViewerMobileFieldsMR.floatPanelOpenState.MC.change('open'))
  }

  // - Show save before close dialog

  function onClickExitEditingPic() {
    if (!draw.isDirty || draw.isDownloaded) {
      draw.onClickExitEditingPic()

      return
    }

    dispatch(
      ModalsMC.open(ModalsIds.ExitEditingImage, (closeAction) => {
        switch (closeAction) {
          case ExitEditingImageDialogActions.NoDownload:
            draw.onClickExitEditingPic()

            break

          case ExitEditingImageDialogActions.Download:
            draw.onClickSave()
            break
        }
      }),
    )
  }

  // ~~~~~~

  return {
    draw,

    onClickSharePaint,
    onClickExitEditingPic,
  } as const
}
