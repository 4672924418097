import { EpicApiServerSubMainMC } from '../../../epics/api-stream-manager/server/sub-main/mutators'

const prefix = 'at-api-bcast-stream-context'

// ~~~~~~ Action Types

export const ApiBcastStreamContextAT = {
  SHOW: `${prefix}-show`,
  CREATE: `${prefix}-create`,
} as const

// ~~~~~~ Action Creators

type ShowData = ReturnType<typeof EpicApiServerSubMainMC.ok>['payload']

export const ApiBcastStreamContextAC = {
  show: (serverSubMainData: ShowData) => ({
    type: ApiBcastStreamContextAT.SHOW,
    payload: {
      serverSubMainData,
    },
  }),

  create: (broadcastId: string) => ({
    type: ApiBcastStreamContextAT.CREATE,
    payload: {
      broadcastId,
    },
  }),
} as const
