import { AppInitState } from '../../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { ApiBcastProvidersAT } from '../../../../actions/api-bcast/providers/actions'
import {
  EpicApiBcastCurrentUserShowMT,
  EpicApiBcastCurrentUserShowMC,
} from '../../../../epics/api-bcast/current-user/show/mutators'
import {
  EpicApiBcastProvidersLinkGoogleMC,
  EpicApiBcastProvidersLinkGoogleMT,
} from '../../../../epics/api-bcast/providers/link-google/mutators'
import {
  EpicApiBcastProvidersUnlinkGoogleMC,
  EpicApiBcastProvidersUnlinkGoogleMT,
} from '../../../../epics/api-bcast/providers/unlink-google/mutators'
import { EpicCancelNowAllMT } from '../../../../epics/cancel-now/all/mutators'
import { EpicResyncGoogleMC, EpicResyncGoogleMT } from '../../../../epics/resync/google/mutators'
import {
  EpicExtensionUserDataMC,
  EpicExtensionUserDataMT,
} from '../../../../epics/extension/user-data/mutators'

const initState = AppInitState.providerGoogle

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ App - Info
// ####################################################################################################

reducer[EpicExtensionUserDataMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicExtensionUserDataMC.ok>,
): State => {
  return {
    ...initState,
    ...payload.providers.google,
  }
}

// ####################################################################################################
// ~~~~~~ Current user Show - OK
// ####################################################################################################

reducer[EpicApiBcastCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastCurrentUserShowMC.ok>,
): State => {
  return {
    ...state,
    ...payload.providers.google,
  }
}

// ####################################################################################################
// ~~~~~~ Link Google - START
// ####################################################################################################

reducer[ApiBcastProvidersAT.LINK_GOOGLE] = (state: State): State => {
  return {
    ...state,
    errors: [],
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Link Google - OK
// ####################################################################################################

reducer[EpicApiBcastProvidersLinkGoogleMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastProvidersLinkGoogleMC.ok>,
): State => {
  return {
    ...state,
    ...payload.google,
    uiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ Link Google - ERROR
// ####################################################################################################

reducer[EpicApiBcastProvidersLinkGoogleMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastProvidersLinkGoogleMC.error>,
): State => {
  return {
    ...state,
    errors: payload.errors,
    uiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ Unlink Google - START
// ####################################################################################################

reducer[ApiBcastProvidersAT.UNLINK_GOOGLE] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Unlink Google - OK
// ####################################################################################################

reducer[EpicApiBcastProvidersUnlinkGoogleMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastProvidersUnlinkGoogleMC.ok>,
): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Unink Google - ERROR
// ####################################################################################################

reducer[EpicApiBcastProvidersUnlinkGoogleMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastProvidersUnlinkGoogleMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
  }
}

// ####################################################################################################
// ~~~~~~ Resync Google Scopes - OK
// ####################################################################################################

reducer[EpicResyncGoogleMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicResyncGoogleMC.ok>,
): State => {
  return {
    ...state,
    ...payload.google,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel all
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return {
    ...state,
    errors: [],
  }
}

// ~~~~~~

export const providerGoogleReducer = UtilsStore.dynReducer(initState, reducer)
