import { AppInitState } from '../../../../../models/app/model'
import { UserSettingsEditFieldsMR } from '../../../../../models/bcast/user-settings/model'
import { UtilsStore } from '@dn/utils'
import {
  EpicApiBcastCurrentUserShowMT,
  EpicApiBcastCurrentUserShowMC,
} from '../../../../epics/api-bcast/current-user/show/mutators'
import { EpicApiBcastUserSettingsDeleteMT } from '../../../../epics/api-bcast/user-settings/delete/mutators'
import {
  EpicApiBcastUserSettingsShowMT,
  EpicApiBcastUserSettingsShowMC,
} from '../../../../epics/api-bcast/user-settings/show/mutators'
import {
  EpicApiBcastUserSettingsUpdateMC,
  EpicApiBcastUserSettingsUpdateMT,
} from '../../../../epics/api-bcast/user-settings/update/mutators'
import {
  EpicExtensionUserDataMC,
  EpicExtensionUserDataMT,
} from '../../../../epics/extension/user-data/mutators'

const initState = AppInitState.userSettings

type State = typeof initState

const reducer: any = {
  ...UserSettingsEditFieldsMR.isOnboardingRunning.Reducer,
}

// ####################################################################################################
// ~~~~~~ Extension user data
// ####################################################################################################

reducer[EpicExtensionUserDataMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicExtensionUserDataMC.ok>,
): State => {
  return {
    ...initState,
    ...payload.userSettings,
  }
}

// ####################################################################################################
// ~~~~~~ Current user Show - OK
// ####################################################################################################

reducer[EpicApiBcastCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastCurrentUserShowMC.ok>,
): State => {
  return {
    ...initState,
    ...payload.userSettings,
  }
}

// ####################################################################################################
// ~~~~~~ Show settings - OK
// ####################################################################################################

reducer[EpicApiBcastUserSettingsShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastUserSettingsShowMC.ok>,
): State => {
  return {
    ...initState,
    ...payload,
  }
}

// ####################################################################################################
// ~~~~~~ Update settings - OK
// ####################################################################################################

reducer[EpicApiBcastUserSettingsUpdateMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastUserSettingsUpdateMC.ok>,
): State => {
  return {
    ...initState,
    ...payload,
  }
}

// ####################################################################################################
// ~~~~~~ Delete settings - OK
// ####################################################################################################

reducer[EpicApiBcastUserSettingsDeleteMT.OK] = (state: State): State => {
  return initState
}

// ~~~~~~

export const userSettingsReducer = UtilsStore.dynReducer(initState, reducer)
