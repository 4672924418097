const prefix = 'mt-api-bcast-user-settings-update-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastUserSettingsUpdateMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastUserSettingsUpdateMC = {
  ok: (data: DN.Models.UserSettings.Api.Update.ResParsed) => ({
    type: EpicApiBcastUserSettingsUpdateMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.UserSettings.Api.Update.ResError) => ({
    type: EpicApiBcastUserSettingsUpdateMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
