import { motion, Transition } from 'framer-motion'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../models/app/model'
import { HexAlpha } from '../../../services/draw/constants/draw-partial-service'
import { Colors } from '../../../style/theme/colors'

// ~~~~~ Constants

const LoadingDot = {
  display: 'block',
  width: '16px',
  height: '16px',
  backgroundColor: 'royalblue',
  borderRadius: '50%',
}

const LoadingContainer = {
  width: '96px',
  height: '66px',
  display: 'flex',
  justifyContent: 'space-around',
  // border: 'solid 1px blue',
}

const ContainerVariants = {
  initial: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  animate: {
    transition: {
      staggerChildren: 0.2,
    },
  },
}

const DotVariants = {
  initial: {
    y: '0',
  },
  animate: {
    y: '50px',
  },
}

const DotTransition: Transition = {
  duration: 0.7,
  repeat: Infinity,
  ease: 'easeInOut',
  repeatType: 'reverse',
}

// ~~~~~ Types

type Props = {
  color?: ThemeColor
  hexAlpha?: typeof HexAlpha
  loadingContainer?: Partial<typeof LoadingContainer>
  containerVariants?: Partial<typeof ContainerVariants>
  loadingDot?: Partial<typeof LoadingDot>
  dotVariants?: Partial<typeof DotVariants>
}

// ~~~~~ Component

export const LoaderBalls: React.FC<Props> = ({
  color,
  loadingContainer,
  containerVariants,
  loadingDot,
  dotVariants,
}) => {
  // ~~~~~ State

  const { mode: themeMode } = useSelector((state: StoreState) => state.theme)

  // ~~~~~ Computed

  const dotColor = color ? color[themeMode] : Colors.WOLF_100_05[themeMode]

  const finalLoadingContainer = {
    ...LoadingContainer,
    ...loadingContainer,
  }

  const finalContainerVariants = {
    ...ContainerVariants,
    ...containerVariants,
  }

  const finalLoadingDot = {
    ...LoadingDot,
    ...loadingDot,
    backgroundColor: dotColor,
  }

  const finalDotVariants = {
    ...DotVariants,
    ...dotVariants,
  }

  // ~~~~~ Render

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <motion.div
        style={finalLoadingContainer}
        variants={finalContainerVariants}
        initial="initial"
        animate="animate"
      >
        {/* Ball 1 */}

        <motion.span
          style={finalLoadingDot}
          variants={finalDotVariants}
          transition={DotTransition}
        />

        {/* Ball 2 */}

        <motion.span
          style={finalLoadingDot}
          variants={finalDotVariants}
          transition={DotTransition}
        />

        {/* Ball 3 */}

        <motion.span
          style={finalLoadingDot}
          variants={finalDotVariants}
          transition={DotTransition}
        />
      </motion.div>
    </div>
  )
}
