import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, map, mergeMap, Observable } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { apiStoreSubscriptionsCheckPaymentMethod$ } from '../../../../../services/api-store/subscriptions/check-payment-method'
import {
  ApiStoreSubscriptionsAC,
  ApiStoreSubscriptionsAT,
} from '../../../../actions/api-store/subscriptions/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiSubscriptionsCheckPaymentMethodMC } from './mutators'

type Action = ReturnType<typeof ApiStoreSubscriptionsAC.checkPaymentMethod>

export const apiStoreSubscriptionCheckPaymentMethodEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreSubscriptionsAT.CHECK_PAYMENT_METHOD),
    mergeMap(() => {
      const { currentUser, subscription } = state$.value

      if (!currentUser.token || !subscription.id) return EMPTY

      return apiStoreSubscriptionsCheckPaymentMethod$(currentUser.token, subscription.id)
    }),
    map((res) =>
      ApiUtils.isSuccess(res)
        ? EpicApiSubscriptionsCheckPaymentMethodMC.ok(res.response)
        : EpicApiSubscriptionsCheckPaymentMethodMC.error(res.status, res.response),
    ),
  )
