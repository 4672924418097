import { useSetRealSize } from '../../../hooks/misc/use-set-real-size'

// ~~~~~~ Component

export const RealHeightSetter = () => {
  // ~~~~~~ Hooks

  const { bottomRef } = useSetRealSize()

  // ~~~~~~ Render

  return (
    <div
      className="real-height"
      ref={bottomRef}
      style={{
        position: 'absolute',
        bottom: 0,
        right: 0,
        // width: 5,
        // height: 5,
        // backgroundColor: 'red',
      }}
    />
  )
}
