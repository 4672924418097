import { guardIsMessageFromServer } from '../is-from-server/comm-channel-partial-service'

export const guardFromServerIsBcastCurrentState = (
  message: unknown,
): message is DN.Services.CommChannel.Messages.FromServer.WithoutAck.BcastCurrentState.Req => {
  if (!guardIsMessageFromServer(message)) return false

  const msgType: DN.Services.CommChannel.Messages.FromServer.WithoutAck.BcastCurrentState.Req['type'] =
    'bcast-current-state'

  if (message.type !== msgType) return false

  if (
    !('bcasterCamTrackEnabled' in message.payload) ||
    typeof message.payload.bcasterCamTrackEnabled !== 'boolean'
  ) {
    return false
  }

  if (
    !('bcasterMicTrackEnabled' in message.payload) ||
    typeof message.payload.bcasterMicTrackEnabled !== 'boolean'
  ) {
    return false
  }

  if (
    !('bcasterCamMicStreamSharing' in message.payload) ||
    typeof message.payload.bcasterCamMicStreamSharing !== 'boolean'
  ) {
    return false
  }

  return true
}
