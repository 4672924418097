import { useState } from 'react'
import { useSelector } from 'react-redux'
import { UtilsMobile } from '@dn/utils'
import { HooksBounds } from '@dn/hooks'

import { DialogBreakPoint } from '../../../constants/breakpoints'
import { ModalsIds } from '../../../constants/modals'
import { StoreState } from '../../../models/app/model'
import { Button } from '../../common/button/component'
import { Actions } from '../compound-dialog/actions/component'
import { Content } from '../compound-dialog/content/component'
import { genAnimatedDialog } from '../compound-dialog/dialog/component'
import { Trans } from '../../common/intl/trans'
import { STDialogExitEditingImage } from './style'

// ~~~~~~ Constants

const ModalId = ModalsIds.ExitEditingImage

const Dialog = genAnimatedDialog(ModalId)

export const ExitEditingImageDialogActions = {
  Close: 'close',
  Download: 'download',
  NoDownload: 'no-download',
} as const

// ~~~~~~ Constants

const isMobile = UtilsMobile.calcIsMobile()

// ~~~~~~ Component

export const DialogExitEditingImage = () => {
  // ~~~~~~ Hooks

  const sizeData = HooksBounds.CheckPortrait.useHook()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const confirmModals = useSelector((state: StoreState) => state.modals.confirm)

  // ~~~~~~ Computed

  const onConfirm = confirmModals[ModalId]

  const useSmallButtons = isMobile && !sizeData.isPortrait

  // ~~~~~~ Handlers

  function onClickNoDownload() {
    onConfirm && onConfirm(ExitEditingImageDialogActions.NoDownload)

    setDoClose(performance.now())
  }

  function onClickDownloadH() {
    onConfirm && onConfirm(ExitEditingImageDialogActions.Download)
  }

  function onClickClose() {
    onConfirm && onConfirm(ExitEditingImageDialogActions.Close)

    setDoClose(performance.now())
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      modalSize={DialogBreakPoint.XS}
      dialogTitle="dialogs.exit-editing-image.Title"
      closeDialog={doClose}
    >
      {/* Content */}

      <Content>
        <STDialogExitEditingImage>
          <Trans id="dialogs.exit-editing-image.Content" />
        </STDialogExitEditingImage>
      </Content>

      {/* Actions */}

      <Actions>
        {/* Close */}

        <Button
          data-testid="close"
          intlId="common.Cancel"
          $colorType="secondary"
          $size={useSmallButtons ? 's' : 'm'}
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickClose}
        />

        {/* Extra space */}

        <div />

        {/* Don't Save */}

        <Button
          data-testid="save"
          intlId="common.DontSave"
          $colorType="secondary"
          $size={useSmallButtons ? 's' : 'm'}
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickNoDownload}
        />

        {/* Save */}

        <Button
          data-testid="continue"
          intlId="common.Save"
          $colorType="success"
          $size={useSmallButtons ? 's' : 'm'}
          disabled={false}
          $running={false}
          $active={true}
          onClick={onClickDownloadH}
        />
      </Actions>
    </Dialog>
  )
}
