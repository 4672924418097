import { Config } from '../../../../config'

const prefix = 'at-api-stream-manager-server'

const bcastIdPrefix = Config.Api.Bcast.IdPrefix

// ~~~~~~ Action Types

export const ApiStreamManagerServerAT = {
  GET_SERVER_PUB_MAIN: `${prefix}-get-server-pub-main`,
  GET_SERVER_PUB_CAM_MIC: `${prefix}-get-server-pub-cam-mic`,

  GET_SERVER_SUB_MAIN: `${prefix}-get-server-sub-main`,
  GET_SERVER_SUB_CAM_MIC: `${prefix}-get-server-sub-cam-mic`,
} as const

// ~~~~~~ Action Creators

export const ApiStreamManagerServerAC = {
  // Pub

  getServerPubMain: (broadcastId: string) => ({
    type: ApiStreamManagerServerAT.GET_SERVER_PUB_MAIN,
    payload: {
      broadcastId: `${bcastIdPrefix ? `${bcastIdPrefix}-` : ''}${broadcastId}`,
    },
  }),

  getServerPubCamMic: () => ({
    type: ApiStreamManagerServerAT.GET_SERVER_PUB_CAM_MIC,
  }),

  // Sub

  getServerSubMain: (broadcastId: string) => ({
    type: ApiStreamManagerServerAT.GET_SERVER_SUB_MAIN,
    payload: {
      broadcastId: `${bcastIdPrefix ? `${bcastIdPrefix}-` : ''}${broadcastId}`,
    },
  }),

  getServerSubCamMic: () => ({
    type: ApiStreamManagerServerAT.GET_SERVER_SUB_CAM_MIC,
  }),
} as const
