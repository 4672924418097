import { SessionStorageDebug } from '../../../services/storage/session/debug'

const storageDebugItem = SessionStorageDebug.get()

const initModel: DN.Models.Debugger.Model = {
  uiStatus: 'init',
  enable: ['on', 'qa'].includes(storageDebugItem as any),
  isQA: storageDebugItem === 'qa',
  actions: [],
  sendError: '',
}

// ~~~~~~

export const DebuggerModel = {
  initModel,
}
