import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { JoinedCommonLeftPanelSharePaint } from './share-paint/component'
import { Padding, STJoinedCommonLeftPanel } from './style'

// ~~~~~~ Component

export const JoinedCommonLeftPanel = () => {
  // ~~~~~~ State

  // - Store

  const layoutPanels = useSelector((state: StoreState) => state.uiLayoutPanels)

  // - Refs

  const selfRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Render

  return (
    <STJoinedCommonLeftPanel ref={selfRef}>
      {layoutPanels.leftPanelShowSharePaints ? (
        <JoinedCommonLeftPanelSharePaint panelRef={selfRef} panelMargin={Padding * 2} />
      ) : undefined}
    </STJoinedCommonLeftPanel>
  )
}
