import { Stripe, loadStripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'
import { Config } from '../config'
import { ModalsIds } from '../constants/modals'
import { useApiGetProducts } from '../hooks/api/use-api-get-prodcuts'
import { StoreState } from '../models/app/model'
import { DialogConfirmAllBlocked } from './dialogs/confirmation/all-blocked/component'
import { DialogConfirmConfirmGrantSystemPermissions } from './dialogs/confirmation/grant-system-permissions/component'
import { DialogConfirmShouldCheckAllGooglePermissionsToLink } from './dialogs/confirmation/should-check-all-google-permissions-to-link/component'
import { DialogConfirmSignInTimeout } from './dialogs/confirmation/sign-in-timeout/component'
import { DialogConfirmThanksForBuy } from './dialogs/confirmation/thanks-for-buy/component'
import { DialogConfirmConfirmUnblockGetScreen } from './dialogs/confirmation/unblock-get-screen/component'
import { DialogConfirmUserAlreadyExists } from './dialogs/confirmation/user-already-exists/component'
import { DialogConfirmUserLimitReached } from './dialogs/confirmation/user-limit-reached/component'
import { DialogConfirmViewerDisconnectedByConnFail } from './dialogs/confirmation/viewer-disconnected-by-fail/component'
import { DialogConfirmViewerDisconnectedByOffline } from './dialogs/confirmation/viewer-disconnected-by-offline/component'
import { DialogConfirmViewerDisconnectedByVideoLoad } from './dialogs/confirmation/viewer-disconnected-by-video-load/component'
import { DialogConfirmViewerDisconnectedByWebsocketFail } from './dialogs/confirmation/viewer-disconnected-by-websocket-fail/component'
import { DialogEndBcastFeedback } from './dialogs/end-bcast-feedback/component'
import { DialogSubscriptionsFreeAccountWithoutTime } from './dialogs/subscriptions/free-account-without-time/component'
import { DialogSubscriptionsLogguedStep1AddPaymentMethod } from './dialogs/subscriptions/logged-step-1-add-payment-method/component'
import { DialogsSubscriptionsLoggedStep2Buying } from './dialogs/subscriptions/logged-step-2-buying/component'
import { DialogCreateAnAccountFreeOrBuy } from './dialogs/subscriptions/plans/component'
import { DialogsSubscriptionsStep1EmailPass } from './dialogs/subscriptions/step-1-email-pass/component'
import { DialogsSubscriptionsStep2VerificationCode } from './dialogs/subscriptions/step-2-verification-code/component'
import { ViewHasEndedDialog } from './dialogs/view-has-ended/component'
import { DialogYouCanBuyBroadcast } from './dialogs/you-can-buy-broadcast/component'

// ~~~~~ Constants

let stripePromise: Promise<Stripe | null>

export const AppDialogs = () => {
  // ~~~~~~ State

  const modals = useSelector((state: StoreState) => state.modals.list)

  // ~~~~~~ Computed

  let showConfirmGrantSystemPermissions: true | undefined = undefined
  let showConfirmUnblockGetScreen: true | undefined = undefined
  let showConfirmAllBlocked: true | undefined = undefined
  let showYouCanBuyBroadcast: true | undefined = undefined
  let showViewHasEndedNormally: true | undefined = undefined
  let showConfirmUserLimitReached: true | undefined = undefined
  let showConfirmViewerDisconnectedByOffline: true | undefined = undefined
  let showConfirmViewerDisconnectedByConnFail: true | undefined = undefined
  let showConfirmViewerDisconnectedByWebsocketFail: true | undefined = undefined
  let showConfirmViewerDisconnectedByVideoLoad: true | undefined = undefined
  let showCreateAnAccountFreeOrBuy: true | undefined = undefined
  let showStep1EmailPass: true | undefined = undefined
  let showStep2VerificationCode: true | undefined = undefined
  let showConfirmThanksForBuy: true | undefined = undefined
  let showConfirmUserAlreadyExists: true | undefined = undefined
  let showLoggedStep1AddPaymentMethod: true | undefined = undefined
  let showLoggedStep2Buying: true | undefined = undefined
  let showFreeAccountWithoutTime: true | undefined = undefined
  let showConfirmShouldCheckAllGooglePermissionsToLink: true | undefined = undefined
  let showConfirmSignInTimeout: true | undefined = undefined
  let showEndBroadcastFeedback: true | undefined = undefined

  modals.forEach((modal) => {
    switch (modal) {
      case ModalsIds.ConfirmGrantSystemPermissions:
        showConfirmGrantSystemPermissions = true
        break

      case ModalsIds.ConfirmUnblockGetScreen:
        showConfirmUnblockGetScreen = true
        break

      case ModalsIds.ConfirmAllBlocked:
        showConfirmAllBlocked = true
        break

      case ModalsIds.YouCanBuyBroadcast:
        showYouCanBuyBroadcast = true
        break

      case ModalsIds.ViewHasEnded:
        showViewHasEndedNormally = true
        break

      case ModalsIds.ConfirmUserLimitReached:
        showConfirmUserLimitReached = true
        break

      case ModalsIds.ConfirmViewerDisconnectedByOffline:
        showConfirmViewerDisconnectedByOffline = true
        break

      case ModalsIds.ConfirmViewerDisconnectedByConnFail:
        showConfirmViewerDisconnectedByConnFail = true
        break

      case ModalsIds.ConfirmViewerDisconnectedByWebsocketFail:
        showConfirmViewerDisconnectedByWebsocketFail = true
        break

      case ModalsIds.ConfirmViewerDisconnectedByVideoLoad:
        showConfirmViewerDisconnectedByVideoLoad = true
        break

      case ModalsIds.CreateAnAccountFreeOrBuy:
        showCreateAnAccountFreeOrBuy = true
        break

      case ModalsIds.Step1EmailPass:
        showStep1EmailPass = true
        break

      case ModalsIds.Step2VerificationCode:
        showStep2VerificationCode = true
        break

      case ModalsIds.ConfirmThanksForBuy:
        showConfirmThanksForBuy = true
        break

      case ModalsIds.ConfirmUserAlreadyExists:
        showConfirmUserAlreadyExists = true
        break

      case ModalsIds.LoggedStep1AddPaymentMethod:
        stripePromise = stripePromise || loadStripe(Config.ThirdParty.Stripe.PublishableKey)

        showLoggedStep1AddPaymentMethod = true
        break

      case ModalsIds.LoggedStep2Buying:
        showLoggedStep2Buying = true
        break

      case ModalsIds.FreeAccountWithoutTime:
        showFreeAccountWithoutTime = true
        break

      case ModalsIds.ConfirmShouldCheckAllGooglePermissionsToLink:
        showConfirmShouldCheckAllGooglePermissionsToLink = true
        break

      case ModalsIds.ConfirmSignInTimeout:
        showConfirmSignInTimeout = true
        break

      case ModalsIds.EndBcastFeedback:
        showEndBroadcastFeedback = true
        break
    }
  })

  // ~~~~~~ Dependent Hooks

  useApiGetProducts(!!showCreateAnAccountFreeOrBuy)

  // ~~~~~~ Render

  return (
    <>
      {/* - System permissions */}

      {showConfirmGrantSystemPermissions && <DialogConfirmConfirmGrantSystemPermissions />}

      {/* - Browser permissions (not Safari) */}

      {showConfirmUnblockGetScreen && <DialogConfirmConfirmUnblockGetScreen />}

      {/* - All blocked */}

      {showConfirmAllBlocked && <DialogConfirmAllBlocked />}

      {/* - You can buy broadcast */}

      {showYouCanBuyBroadcast && <DialogYouCanBuyBroadcast />}

      {/* - View has ended normally */}

      {showViewHasEndedNormally && <ViewHasEndedDialog />}

      {/* - User limit reached */}

      {showConfirmUserLimitReached && <DialogConfirmUserLimitReached />}

      {/* - View has disconnected by offline */}

      {showConfirmViewerDisconnectedByOffline && <DialogConfirmViewerDisconnectedByOffline />}

      {/* - View has ended by fail or cannot join because conn fail */}

      {showConfirmViewerDisconnectedByConnFail && <DialogConfirmViewerDisconnectedByConnFail />}

      {/* - View has ended by websocket fail */}

      {showConfirmViewerDisconnectedByWebsocketFail && (
        <DialogConfirmViewerDisconnectedByWebsocketFail />
      )}

      {/* - View has ended because device cannot load the video */}

      {showConfirmViewerDisconnectedByVideoLoad && <DialogConfirmViewerDisconnectedByVideoLoad />}

      {/* - Create account Free or Premium */}

      {showCreateAnAccountFreeOrBuy && <DialogCreateAnAccountFreeOrBuy />}

      {/* - Step 1 email/pass */}

      {showStep1EmailPass && <DialogsSubscriptionsStep1EmailPass />}

      {/* - Step 2 verification code */}

      {showStep2VerificationCode && <DialogsSubscriptionsStep2VerificationCode />}

      {/* - Thanks for buy */}

      {showConfirmThanksForBuy && <DialogConfirmThanksForBuy />}

      {/* - User already exists */}

      {showConfirmUserAlreadyExists && <DialogConfirmUserAlreadyExists />}

      {/* - Logged step 1 Add payment method */}

      {showLoggedStep1AddPaymentMethod && (
        <DialogSubscriptionsLogguedStep1AddPaymentMethod stripe={stripePromise} />
      )}

      {/* - Loggued step 2 Buying */}

      {showLoggedStep2Buying && <DialogsSubscriptionsLoggedStep2Buying />}

      {/* - From extension, show no user with free account has not time remaining */}

      {showFreeAccountWithoutTime && <DialogSubscriptionsFreeAccountWithoutTime />}

      {/* - Link with google, should check all Google permissions to link the account */}

      {showConfirmShouldCheckAllGooglePermissionsToLink && (
        <DialogConfirmShouldCheckAllGooglePermissionsToLink />
      )}

      {/* - Sign In throws a timeout */}

      {showConfirmSignInTimeout && <DialogConfirmSignInTimeout />}

      {/* - Bcast feedback */}

      {showEndBroadcastFeedback && <DialogEndBcastFeedback />}
    </>
  )
}
