import styled from 'styled-components'
import { MediaQuery } from '../../../../style/constants/media-queries'

export const STJoinPagetopRight = styled.div`
  display: flex;
  align-items: center;

  .join-create-bcast {
    cursor: pointer;
  }

  .join-top-right-menu {
    margin-left: 16px; // Menu can be or not visible
  }

  .join-top-right-to-start-desktop {
    display: block;
  }

  .join-top-right-to-start-mobile {
    display: none;
  }

  /* Mobile */

  @media screen and (max-width: ${MediaQuery.SM}) {
    .join-top-right-to-start-desktop {
      display: none;
    }

    .join-top-right-to-start-mobile {
      display: block;
    }
  }
`
