import { UtilsFullscreen } from '@dn/utils'
import { HooksBounds } from '@dn/hooks'
import { DevicePIPEnabled } from '../../../../../../constants/misc'

// ~~~~~~ Constants

const showX = {
  x: 0,
  y: 0,
  opacity: 1,
} as const

const hideX = {
  x: -20,
  y: 0,
  opacity: 0,
} as const

const showY = {
  x: 0,
  y: 0,
  opacity: 1,
} as const

const hideY = {
  x: 0,
  y: 20,
  opacity: 0,
} as const

const SizeForSmaller = 56

// share, download, paint, zoom-in, zoom-out, pip, fullscreen, close
const Items = 8

const IconSize = 56

const RemPipSize = DevicePIPEnabled ? 0 : IconSize

const RemFullscreenSize = UtilsFullscreen.fullscreenIsSupported ? 0 : IconSize

const remSize = RemPipSize + RemFullscreenSize

const Size = Items * IconSize - remSize

// ~~~~~~ Hook

export const useJoinedMobileToolbarsMainHooksResizeAnimations = () => {
  // ~~~~~~ Hooks

  const sizeData = HooksBounds.CheckPortrait.useHook()

  // ~~~~~~ Computed

  const hidden = sizeData.isPortrait ? hideY : hideX

  const show = sizeData.isPortrait ? showY : showX

  const [width, height] = sizeData.isPortrait ? [SizeForSmaller, Size] : [Size, SizeForSmaller]

  const variantsContainer = {
    hidden: {
      width: sizeData.isPortrait ? width : 0,
      height: sizeData.isPortrait ? 0 : height,
    },

    show: {
      ...show,
      width,
      height,
      transition: {
        staggerChildren: 0.11,
        delayChildren: 0,
      },
    },
  }

  const variantsItem = {
    hidden,
    show,
  }

  // ~~~~~~

  return {
    sizeData,

    variantsContainer,
    variantsItem,
  }
}
