import styled from 'styled-components'
import { PenColor } from '../../../../../constants/pen-color'
import { Transition } from '../../../../../style/animations/css-animations'
import { Colors } from '../../../../../style/theme/colors'
import { getThemeValue, theme } from '../../../../../style/utils/theme'
import { PaintToolbarCommonColors, STPaintToolbarCommonStyle } from '../common-style'

// ~~~~~~ Helpers

const getColor = (colorKey: ColorKey) => PenColor[colorKey].color

// ~~~~~~ Types

type ColorKey = DN.Models.PaintBcastStream.Draw.ColorKey

export type STProps = {
  $colorKey: ColorKey
  $selected: boolean
  $disabled: boolean
}

// ~~~~~~ Component

export const STPaintToolbarButtonsColor = styled.div<STProps>`
  ${STPaintToolbarCommonStyle}

  padding: 0;

  ${Transition.BorderColorFaster}

  border: solid 1px
    ${(props) =>
    props.$selected ? getThemeValue(props, PaintToolbarCommonColors.selected) : 'transparent'};

  border-radius: 50%;

  svg rect {
    stroke: ${(props) =>
      props.theme.mode === 'light' && props.$colorKey === 'WHITE'
        ? getThemeValue(props, Colors.WOLF_20_20)
        : props.theme.mode === 'dark' && props.$colorKey === 'BLACK'
          ? getThemeValue(props, Colors.WOLF_80_80)
          : getColor(props.$colorKey)}; // svg border

    fill: ${({ $colorKey }) => getColor($colorKey)};
  }

  @media (hover: hover) {
    &:hover {
      border-color: ${theme(PaintToolbarCommonColors.hover)};
    }
  }
`
