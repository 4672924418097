import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { theme } from '../../../../../style/utils/theme'

export const STJoinedCommonHeaderTopLeft = styled.div`
  height: 16px;

  display: flex;

  svg {
    height: 16px;

    path {
      fill: ${theme(Colors.WOLF_100_05)};
      stroke: none;
    }
  }
`
