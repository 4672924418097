import { css } from 'styled-components'
import { Transition } from '../../../../../../style/animations/css-animations'
import { Colors } from '../../../../../../style/theme/colors'

// ~~~~~~ Constants

export const CommonStyleSvgColors = {
  color: Colors.WOLF_100_05,
  selected: Colors.EMMET_40_40,
}

export const CommonStyleSize = 56

// ~~~~~~ CSS

export const STJoinedMobileToolbarsCapturePaintButtons = css`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${CommonStyleSize}px;
  height: ${CommonStyleSize}px;

  /* Prevent icons to have less size inside scrollbar */

  min-width: ${CommonStyleSize}px;
  min-height: ${CommonStyleSize}px;

  svg {
    height: 24px;
    width: 24px;

    path {
      ${Transition.FillStrokeFaster}
    }
  }
`
