import { ofType, StateObservable } from 'redux-observable'
import { filter, map, Observable } from 'rxjs'
import { StoreState } from '../../../../models/app/model'
import {
  ExtensionDispatcherACT,
  ExtensionDispatcherAT,
} from '../../../../services/extension/actions/extension-partial-service'
import { ShareMainStreamAC } from '../../../actions/share-main-stream/actions'

type Action = ExtensionDispatcherACT['ChangeSource']

export const extensionMapToAppChangeSourceEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ExtensionDispatcherAT.CHANGE_SOURCE),
    filter(
      () =>
        state$.value.sharedMainStream.getScreenToSwitchUiStatus === 'init' ||
        state$.value.sharedMainStream.getScreenToSwitchUiStatus === 'completed',
    ),
    map(() => ShareMainStreamAC.getStreamToSwitch()),
  )
