import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { STStartedHeaderTopLeft } from './style'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-brand')

// ~~~~~~ Component

export const StartedHeaderTopLeft = () => {
  // ~~~~~~ Render

  return (
    <STStartedHeaderTopLeft>
      <Logo size={34} />
    </STStartedHeaderTopLeft>
  )
}
