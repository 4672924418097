import styled from 'styled-components'
import { STPaintToolbarCommonStyle } from '../common-style'

// ~~~~~~ Types

type STProps = {
  $disabled: boolean
}

// ~~~~~~ Component

export const STPaintToolbarButtonsDownload = styled.div<STProps>`
  ${STPaintToolbarCommonStyle}

  padding: 1px;

  height: 22px;
  width: 22px;
`
