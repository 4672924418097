import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { HexAlpha } from '../../../../style/theme/colors/alpha'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { getThemeValue, theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-settings-account'

// ~~~~~~ Component

export const STUserSettingsAccount = styled.div`
  .${ClassName}--notice {
    ${Layout.Border.Radius.B}

    padding: ${Layout.Spacing[4]}px;

    background-color: ${(props) =>
      props.theme.mode === 'light'
        ? getThemeValue(props, Colors.EMMET_05_05)
        : `${getThemeValue(props, Colors.EMMET_50_50)}${HexAlpha[20]}`};

    .${ClassName}--notice--info {
      ${Fonts.Weight[500]}
      ${Fonts.Scale.BodyBig}

      margin-bottom: 18px;
    }

    .${ClassName}--notice--info-action {
      cursor: pointer;

      ${Fonts.Weight[700]}
      ${Fonts.Scale.Body}

      display: inline-block;

      color: ${theme(Colors.EMMET_50_40)};

      border-bottom: solid 2px ${theme(Colors.EMMET_50_40)};
    }
  }
`
