import styled from 'styled-components'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { theme } from '../../../../style/utils/theme'

export const STStartPageTopRight = styled.div`
  display: flex;

  align-items: center;

  .start-top-right-free-info {
    margin-right: 16px;

    ${Fonts.Scale.Body};
    ${Fonts.Weight[500]};

    color: ${theme(Colors.EMMET_50_30)};

    .free-time-limit-desktop,
    .free-premium-days-desktop {
      display: block;
    }

    .free-time-limit-mobile,
    .free-premium-days-mobile {
      display: none;
    }
  }

  .start-top-right-upgrade {
    cursor: pointer;

    margin-right: 16px;

    ${Fonts.Scale.Body};
    ${Fonts.Weight[700]};

    color: ${theme(Colors.EMMET_50_30)};
  }

  .start-top-right-to-join {
    .start-top-right-to-join-desktop {
      display: block;
    }

    .start-top-right-to-join-mobile {
      display: none;
    }
  }

  .start-top-right-menu {
    margin-left: 16px; // Menu can be or not visible
  }

  /* Mobile */

  @media screen and (max-width: ${MediaQuery.SM}) {
    .start-top-right-free-info {
      .free-time-limit-desktop,
      .free-premium-days-desktop {
        display: none;
      }

      .free-time-limit-mobile,
      .free-premium-days-mobile {
        display: block;
      }
    }

    .start-top-right-to-join {
      .start-top-right-to-join-desktop {
        display: none;
      }

      .start-top-right-to-join-mobile {
        display: block;
      }
    }
  }
`
