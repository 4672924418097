import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useRef } from 'react'
import { fromEvent } from 'rxjs'
import { ConstEventsWindow } from '@dn/constants'
import { MenuExtensionSourceSelector } from '../../../../../../../../../components/common/menus/extension-source-selector/component'
import { CommonStyleSize } from '../../common-style'

// ~~~~~~ Types

type Props = {
  show: boolean
  showTitle?: true
  changeSourceBtn: HTMLDivElement | null

  onClickMenuItem: (kind: CaptureStreamKind) => void
  onClickOut: () => void
}

// ~~~~~~ Component

export const StartedContentMainToolbarsButtonsChangeSourceMenu: React.FC<Props> = ({
  show,
  showTitle,

  changeSourceBtn,

  onClickMenuItem,
  onClickOut,
}) => {
  // ~~~~~~ State

  const hiddenRef = useRef<HTMLDivElement>(null)

  const menuRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Effects

  // - Trigger click out on resize window

  useEffect(() => {
    const sub = fromEvent(window, ConstEventsWindow.Resize).subscribe({
      next: () => {
        menuRef.current && onClickOut()
      },
    })

    return () => {
      sub.unsubscribe()
    }
  }, [onClickOut])

  // ~~~~~~ Render

  return (
    <>
      {/* Hidden menu to get its width (diff langs == diff widths) */}

      <div style={{ position: 'absolute', top: -10000 }}>
        <MenuExtensionSourceSelector
          ref={hiddenRef}
          kind="Source"
          showTitle={showTitle}
          onClick={() => undefined}
          onClickOut={() => undefined}
        />
      </div>

      {/* The animated menu */}

      <AnimatePresence>
        {show && changeSourceBtn && hiddenRef.current ? (
          <motion.div
            initial={{
              opacity: 0,
              left: 0,
              y: -hiddenRef.current.clientHeight / 2 - CommonStyleSize / 2,
            }}
            animate={{
              opacity: 1,
              y: -hiddenRef.current.clientHeight / 2 - CommonStyleSize / 2 - 8,
            }}
            exit={{ opacity: 0 }}
            style={{
              position: 'absolute',
              zIndex: 10,
            }}
          >
            <MenuExtensionSourceSelector
              ref={menuRef}
              kind="Source"
              showTitle={showTitle}
              onClick={onClickMenuItem}
              onClickOut={onClickOut}
              // () => setShowExtensionSourceSelector(false)}
            />
          </motion.div>
        ) : undefined}
      </AnimatePresence>
    </>
  )
}
