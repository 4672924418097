// ####################################################################################################
// ~~~~~~ Gen user context
// ####################################################################################################

import { ServiceTrackEvents } from '@dn/track-events'

const genUserContext = (
  user: DN.Models.CurrentUser.ModelFront,
  sub: DN.Models.Subscription.ModelFront,
) => {
  return ServiceTrackEvents.Context.genBcastUser({
    version: '1_0_0',
    data: {
      id: user.id,
      sync_with_google: !!user.googleAccessToken,
      plan_type: sub.plan_type,
      quality: sub.quality,
      fps: sub.fps,
      users_capacity: sub.connections,
    },
  })
}

// ####################################################################################################
// ~~~~~~ Gen stream context
// ####################################################################################################

const genStreamContext = (
  id: string,
  kind: DN.Services.TrackEvents.Contexts.BcastStream.v1_0_0.Context['data']['kind'],
) => {
  return ServiceTrackEvents.Context.genBcastStream({
    version: '1_0_0',
    data: {
      id,
      kind,
    },
  })
}

// ~~~~~~

export const bcastTrackEventsHelpers = {
  genUserContext,
  genStreamContext,
} as const
