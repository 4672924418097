import { ofType, StateObservable } from 'redux-observable'
import { Observable, map } from 'rxjs'

import { StoreState } from '../../../../models/app/model'
import { ApiStreamManagerServerAC } from '../../../actions/api-stream-manager/server/actions'
import {
  EpicShareCamMicStreamGetStreamMC,
  EpicShareCamMicStreamGetStreamMT,
} from '../../share-cam-mic-stream/get-stream/mutators'

type Action = ReturnType<typeof EpicShareCamMicStreamGetStreamMC.ok>

export const chainingPubCamMicFromShareCamMicStreamGetStreamToGetServerPubCamMicEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicShareCamMicStreamGetStreamMT.OK),

    // Get server action

    map(() => ApiStreamManagerServerAC.getServerPubCamMic()),
  )
