import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars'
import { HooksData } from '@dn/hooks'

import { Button } from '../../../../../components/common/button/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { SliderCheck } from '../../../../../components/common/slider-check/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { useTrans } from '../../../../../hooks/use-trans'
import { StoreState } from '../../../../../models/app/model'
import { ApiBcastGoogleClassRoomAC } from '../../../../../store/actions/api-bcast/google-classroom/actions'
import { useClassroomScrollbarHeight } from './behaviour-logic/use-classroom-scrollbar-height'
import { ClassName, ItemHeight, Padding, STStartedLeftPanelMainClassroom } from './style'
import { UiLayoutPanelsFieldsMR } from '../../../../../models/bcast/ui-layout-panels/model'
import { BcastTrackEvents } from '../../../../../services/track-events'
import { useTrackUserContext } from '../../../../../hooks/track-events/use-track-user-context'
import { useTrackStreamContext } from '../../../../../hooks/track-events/use-track-stream-context'
import { useBcasterGConnect } from '../../../../../hooks/bcaster-g-connect/use-bcaster-g-connect'

// ~~~~~~ Constants

const ItemIcon = LazySVG('icons/book')

const NoCourse: Course = {
  id: '',
  name: '',
}

// ~~~~~~ Types

export type Course = {
  id: string
  name: string
}

type Props = {
  panelElementRef: React.RefObject<HTMLDivElement>
  panelMargin: number
  topElementRef: React.RefObject<HTMLDivElement>
}

// ~~~~~~ Component

export const StartedLeftPanelMainClassroom: React.FC<Props> = ({
  panelElementRef,
  panelMargin,
  topElementRef,
}) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const announceLinkText = useTrans('common.share.announce.Link')

  const classroomScroll = useClassroomScrollbarHeight(
    panelElementRef,
    topElementRef,
    panelMargin,
    Padding,
    ItemHeight,
  )

  const userContext = useTrackUserContext()

  const streamContext = useTrackStreamContext()

  const bcasterGConnect = useBcasterGConnect()

  // ~~~~~~ State

  // - Local

  const [selectedCourse, setSelectedCourse] = useState<Course>(NoCourse)

  const [linkSharedFeedback, setLinkSharedFeedback] = useState(false)

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const providerGoogle = useSelector((state: StoreState) => state.providerGoogle)

  const classRoomMetaList = useSelector((state: StoreState) => state.googleClassRoomMetaList)

  const classRoomAnnouncement = useSelector(
    (state: StoreState) => state.googleClassRoomAnnouncementBcastLink,
  )

  const broadcastId = useSelector(
    (state: StoreState) => state.streamManagerServerPubMain.broadcastId,
  )

  const layoutPanels = useSelector((state: StoreState) => state.uiLayoutPanels)

  // ~~~~~~ Computed

  const shareIsOpen = layoutPanels.shareBcasLinkToClassroomState === 'open'

  const userCanAnnounceLink = providerGoogle.classroom_announcements

  const classroomQuantity = classRoomMetaList.list.length

  const shareLinkIsRunning = classRoomAnnouncement.uiStatus === 'running'

  const alreadyShared = currentUser.lastSelectedGoogleClassRoom.id

  const showEmptyList =
    classRoomMetaList.list.length === 0 && classRoomMetaList.uiStatus !== 'running'

  // ~~~~~~ State dependent Hooks

  const prevUserCanAnnounceLink = HooksData.PrevValue.useHook(userCanAnnounceLink)

  const prevAnnouncementUiStatus = HooksData.PrevValue.useHook(classRoomAnnouncement.uiStatus)

  // ~~~~~~ Handlers

  function onChangeSlider() {
    if (!userCanAnnounceLink) {
      bcasterGConnect()

      return
    }

    // Model

    dispatch(
      UiLayoutPanelsFieldsMR.shareBcasLinkToClassroomState.MC.change(
        shareIsOpen ? 'closed' : 'open',
      ),
    )

    // Changed to close

    if (shareIsOpen) {
      setSelectedCourse(NoCourse)

      return
    }

    // Changed to open

    dispatch(ApiBcastGoogleClassRoomAC.list({ withIt: false }))

    // Required to recalculate scrollbar height on change to "checked"
    // as the internal size of the card changes.
    // We need the space used by "Available classrooms"

    setTimeout(() => classroomScroll.recalculateScrollbarHeight(), 0)
  }

  function onClickItem(course: Course) {
    selectedCourse === course ? setSelectedCourse(NoCourse) : setSelectedCourse(course)
  }

  function onShareLink() {
    const classroom = currentUser.lastSelectedGoogleClassRoom.id
      ? currentUser.lastSelectedGoogleClassRoom
      : selectedCourse

    if (shareLinkIsRunning || !classroom.id) return

    dispatch(
      ApiBcastGoogleClassRoomAC.announceBCastLink(
        /* WithGoogleAccessToken */ { withIt: false },
        classroom,
        announceLinkText,
        {
          kind: 'link',
          link: {
            url: `${window.origin}/${broadcastId}?gcroom=t`,
          },
        },
      ),
    )

    // Track

    BcastTrackEvents.calls.Google.Bcaster.shareCroomLink(userContext, streamContext)
  }

  // ~~~~~~ Effects

  // - On connect account, get classroom list

  useEffect(() => {
    if (prevUserCanAnnounceLink !== false || userCanAnnounceLink !== true) return

    dispatch(ApiBcastGoogleClassRoomAC.list(/* WithGoogleAccessToken */ { withIt: false }))

    //
  }, [dispatch, prevUserCanAnnounceLink, userCanAnnounceLink])

  // - On mount, if account is connected, get classroom list

  useEffect(() => {
    if (!userCanAnnounceLink) return

    dispatch(ApiBcastGoogleClassRoomAC.list({ withIt: false }))

    // We don't want update value side effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // - Observe announce to add feedback

  useEffect(() => {
    if (
      classRoomAnnouncement.uiStatus === prevAnnouncementUiStatus ||
      prevAnnouncementUiStatus !== 'running'
    ) {
      return
    }

    setSelectedCourse(NoCourse)

    setLinkSharedFeedback(true)

    //
  }, [classRoomAnnouncement.uiStatus, prevAnnouncementUiStatus])

  // - Return share button to its original state

  useEffect(() => {
    if (!linkSharedFeedback) return

    const timeout = setTimeout(() => setLinkSharedFeedback(false), 1200)

    return () => {
      clearTimeout(timeout)
    }
  })

  // ~~~~~~ Render

  return (
    <STStartedLeftPanelMainClassroom>
      {/* Title and slider */}

      <div ref={classroomScroll.refs.titleRef} className={`${ClassName}--title-slider`}>
        <div className={`${ClassName}--title-slider--title`}>Google Classroom</div>

        <div className={`${ClassName}--title-slider--title`}>
          <SliderCheck
            $colorType="secondary"
            checked={userCanAnnounceLink ? shareIsOpen : false}
            onChange={onChangeSlider}
          />
        </div>
      </div>

      {/* Closed info */}

      {!shareIsOpen && !userCanAnnounceLink ? (
        <div className={`${ClassName}--closed-info`}>
          <Trans id="pages.started.left-panel.common.classroom.Info" />
        </div>
      ) : undefined}

      {/* Already shared */}

      {shareIsOpen && alreadyShared ? (
        <div className={`${ClassName}--already-shared`}>
          {/* Info */}

          <div className={`${ClassName}--already-shared--info`}>
            <Trans id="pages.started.left-panel.common.classroom.Current" />
          </div>

          {/* Selected classroom */}

          <div className={`${ClassName}--already-shared--selected`}>
            {/* Icon */}

            <div className={`${ClassName}--already-shared--selected--icon`}>
              <ItemIcon size={24} />
            </div>

            {/* Classroom */}

            <div className={`${ClassName}--already-shared--selected--name`}>
              {currentUser.lastSelectedGoogleClassRoom.name}
            </div>
          </div>

          {/* Action */}

          <div className={`${ClassName}--already-shared--action`}>
            <Button
              $colorType="primary"
              intlId="pages.started.left-panel.common.classroom.ShareLink"
              $width="100%"
              disabled={shareLinkIsRunning}
              $running={shareLinkIsRunning}
              $active={!shareLinkIsRunning}
              onClick={onShareLink}
            />
          </div>
        </div>
      ) : undefined}

      {/* Classroom data */}

      {shareIsOpen && !alreadyShared ? (
        <div className={`${ClassName}--classroom-data`}>
          {/* Available classrooms */}

          {!showEmptyList ? (
            <div
              ref={classroomScroll.refs.classroomQttyRef}
              className={`${ClassName}--classroom-data--quantity`}
            >
              <div>
                <Trans id="pages.started.left-panel.common.classroom.Available" />
              </div>

              <div className={`${ClassName}--classroom-data--quantity--data`}>
                {classroomQuantity}
              </div>
            </div>
          ) : undefined}

          {/* List */}

          <Scrollbars
            universal
            autoHide={false}
            style={{ height: classroomScroll.scrollbarHeight }}
            renderTrackVertical={(props) => <div {...props} className="scrollbar-vertical" />}
          >
            <div className={`${ClassName}--classroom-data--list`}>
              {/* - */}

              {/* Empty List */}

              {showEmptyList ? (
                <div className={`${ClassName}--classroom-data--list--empty`}>
                  {/* Icon */}

                  <div className={`${ClassName}--classroom-data--list--empty--icon`}>
                    <ItemIcon size={24} />
                  </div>

                  {/* Text */}

                  <div className={`${ClassName}--classroom-data--list--empty--text`}>
                    <Trans id="pages.started.left-panel.common.classroom.EmptyList" />
                  </div>
                </div>
              ) : undefined}

              {/* List with data */}

              {!showEmptyList &&
                classRoomMetaList.list.map((classroom, idx) => (
                  <div
                    key={classroom.id}
                    //
                    className={`${ClassName}--classroom-data--list--item ${
                      idx === classRoomMetaList.list.length - 1 ? 'last' : ''
                    } ${classroom === selectedCourse ? 'selected' : ''}`}
                    //
                    onClick={() => onClickItem(classroom)}
                  >
                    {/* Icon */}

                    <div className={`${ClassName}--classroom-data--list--item--icon`}>
                      <ItemIcon size={24} />
                    </div>

                    {/* Classroom */}

                    <div className={`${ClassName}--classroom-data--list--item--name`}>
                      {classroom.name}
                    </div>
                  </div>
                ))}

              {/* - */}
            </div>
          </Scrollbars>

          {/* Action */}

          {!showEmptyList ? (
            <div
              ref={classroomScroll.refs.actionRef}
              className={`${ClassName}--classroom-data--action`}
            >
              <Button
                $colorType="primary"
                intlId="pages.started.left-panel.common.classroom.ShareLink"
                $width="100%"
                disabled={shareLinkIsRunning || !selectedCourse.id}
                $running={shareLinkIsRunning}
                $active={!shareLinkIsRunning && !!selectedCourse.id}
                onClick={onShareLink}
              />
            </div>
          ) : undefined}
        </div>
      ) : undefined}
    </STStartedLeftPanelMainClassroom>
  )
}
