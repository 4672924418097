import styled from 'styled-components'
import { Transition } from '../../../../../style/animations/css-animations'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'started-left-panel-main-bcast-online-info'

// ~~~~~~ Component

export const STStartedLeftPanelMainBcastOnlineInfo = styled.div`
  color: ${theme(Colors.WOLF_80_20)};

  /* Close */

  .${ClassName}--close-btn {
    display: flex;
    justify-content: flex-end;

    margin-bottom: ${Layout.Spacing[12]}px;

    svg {
      cursor: pointer;

      width: 32px;
      height: 32px;

      padding: 2px;

      path {
        ${Transition.FillFaster}

        fill: ${theme(Colors.WOLF_100_05)};
      }

      &:hover {
        path {
          fill: ${theme(Colors.WOLF_80_20)};
        }
      }

      &:active {
        path {
          fill: ${theme(Colors.WOLF_100_05)};
        }
      }
    }
  }

  /* Broadcast.online */

  .${ClassName}--line-bcast-title {
    ${Fonts.Weight[500]}
    ${Fonts.Scale.BodyBig}

    margin-bottom: ${Layout.Spacing[2]}px;
  }

  .${ClassName}--line-bcast-content {
    ${Fonts.Weight[700]}
    ${Fonts.Scale.Headline1}

    color: ${theme(Colors.WOLF_100_05)};

    margin-bottom: ${Layout.Spacing[3]}px;
  }

  /* ID */

  .${ClassName}--line-id-title {
    ${Fonts.Weight[500]}
    ${Fonts.Scale.BodyBig}

    margin-bottom: ${Layout.Spacing[2]}px;
  }

  .${ClassName}--line-id-content {
    cursor: pointer;

    ${Fonts.Weight[700]}
    ${Fonts.Scale.Headline1}

    letter-spacing: 4px;

    color: ${theme(Colors.WOLF_100_05)};

    margin-bottom: ${Layout.Spacing[4]}px;
  }

  /* Copy */

  .${ClassName}--line-copy {
    ${Fonts.Weight[500]}
    ${Fonts.Scale.Body}

    display: flex;
    align-items: center;

    padding-bottom: ${Layout.Spacing[5]}px;

    .${ClassName}--line-copy-icon {
      svg {
        display: flex;

        width: 20px;
        height: 20px;

        margin-right: ${Layout.Spacing[2]}px;

        path {
          fill: ${theme(Colors.WOLF_100_05)};
          stroke: none;
        }
      }
    }

    .${ClassName}--line-copy-text {
    }

    .${ClassName}--line-copy-copy {
      cursor: pointer;

      color: ${theme(Colors.EMMET_50_30)};

      ${Transition.ColorBorderAndBackgroundFaster}

      border-bottom: solid 1px ${theme(Colors.EMMET_50_30)};
      border-bottom-style: dashed;

      &:hover {
        color: ${theme(Colors.EMMET_40_40)};
        border-color: ${theme(Colors.EMMET_40_40)};
      }

      &:active {
        color: ${theme(Colors.EMMET_50_30)};
        border-color: ${theme(Colors.EMMET_50_30)};
      }
    }
  }

  /* Reduced panel */

  @media (max-width: ${MediaQuery.MD}) {
    /* Broadcast.online */

    .${ClassName}--line-bcast-title {
      ${Fonts.Weight[500]}
      ${Fonts.Scale.Body}
    }

    .${ClassName}--line-bcast-content {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.Title}
    }

    /* ID */

    .${ClassName}--line-id-title {
      ${Fonts.Weight[500]}
      ${Fonts.Scale.Body}
    }

    .${ClassName}--line-id-content {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.Title}
    }
  }
`
