import { UtilsClone, UtilsStore } from '@dn/utils'

// ~~~~~~ Constants

const ErrorType: Infos = { id: 'wildcard' }

const initModel: DN.Models.CurrentUser.ModelFront = {
  uiStatus: 'init',
  errors: [],

  id: '',
  email: '',
  token: '',

  isSessionChecked: false,

  lastSelectedGoogleClassRoom: {
    id: '',
    name: '',
  },

  lastSelectedGoogleSlide: {
    id: '',
    title: '',
  },

  googleAccessToken: '',
  googleAccessTokenExpiresAt: 0,
}

const prefix = 'mt-current-user-edit'

// ~~~~~~ Fields Mutators/Reducers

export const CurrentUserEditFieldsMR = {
  lastSelectedGoogleClassRoom: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'lastSelectedGoogleClassRoom',
    initModel.lastSelectedGoogleClassRoom,
    ErrorType,
  ),

  lastSelectedGoogleSlide: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'lastSelectedGoogleSlide',
    initModel.lastSelectedGoogleSlide,
    ErrorType,
  ),

  googleAccessToken: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'googleAccessToken',
    initModel.googleAccessToken,
    ErrorType,
  ),

  googleAccessTokenExpiresAt: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'googleAccessTokenExpiresAt',
    initModel.googleAccessTokenExpiresAt,
    ErrorType,
  ),

  // Survey

  // Onboarding
}

// ~~~~~~ Validations

// ...

// ~~~~~~

const fromBackToFront = (
  model: DN.Models.CurrentUser.ModelBack,
  token: string,
): DN.Models.CurrentUser.ModelFront => {
  //

  const modelClone = UtilsClone.clone(model) as Omit<typeof model, 'user_settings'> & {
    user_settings?: (typeof model)['user_settings']
  }

  delete modelClone.user_settings

  return {
    ...initModel,
    ...modelClone,
    token,
  }
}

// ~~~~~~

export const CurrentUserModel = {
  initModel,
  fromBackToFront,
}
