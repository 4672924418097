import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fromEvent } from 'rxjs'
import { ConstEventsPIP } from '@dn/constants'
import { UiPipFieldsMR } from '../../../../models/bcast/ui-pip/model'

// ~~~~~~ Helpers

const isPipOn = () => !!document.pictureInPictureElement

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const VideoPipStatusUpdater: React.FC<Props> = ({ video }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ Effects

  // - On mount update current pip state

  useEffect(() => {
    dispatch(UiPipFieldsMR.isActive.MC.change(isPipOn() ? 'open' : 'closed'))

    //
  }, [dispatch])

  // - Observe enter/leave pip events (close button in external pip window)

  useEffect(() => {
    if (!video) return

    const subEnter = fromEvent(video, ConstEventsPIP.EnterPip).subscribe({
      next: () => {
        dispatch(UiPipFieldsMR.isActive.MC.change('open'))
      },
    })

    const subLeave = fromEvent(video, ConstEventsPIP.LeavePip).subscribe({
      next: () => {
        dispatch(UiPipFieldsMR.isActive.MC.change('closed'))
      },
    })

    return () => {
      subEnter.unsubscribe()
      subLeave.unsubscribe()
    }

    //
  }, [dispatch, video])

  // ~~~~~~ Render

  return null
}
