import { map, Observable, tap } from 'rxjs'
import { StateObservable, ofType } from 'redux-observable'
import { UtilsObservables } from '@dn/utils'
import { ServiceWebRtc } from '@dn/webrtc'
import { StoreState } from '../../../../models/app/model'
import { BroadcastService } from '../../../../services/broadcast'
import { CancelNowAC, CancelNowAT, CancelReasons } from '../../../actions/cancel-now/actions'
import {
  EpicApiServerPubCamMicMC,
  EpicApiServerPubCamMicMT,
} from '../../api-stream-manager/server/pub-cam-mic/mutators'
import {
  EpicApiServerSubCamMicMC,
  EpicApiServerSubCamMicMT,
} from '../../api-stream-manager/server/sub-cam-mic/mutators'
import {
  EpicBcastStartPubCamMicMC,
  EpicBcastStartPubCamMicMT,
} from '../../bcast/start-pub-cam-mic/mutators'
import {
  EpicBcastStartSubCamMicMC,
  EpicBcastStartSubCamMicMT,
} from '../../bcast/start-sub-cam-mic/mutators'
import {
  EpicShareCamMicStreamGetStreamMC,
  EpicShareCamMicStreamGetStreamMT,
} from '../../share-cam-mic-stream/get-stream/mutators'
import { EpicCancelNowCamMicMC } from './mutators'
import { cancelCamMic$$ } from './subject'

type Action =
  | ReturnType<typeof CancelNowAC.cancelCamMic>
  | ReturnType<typeof EpicShareCamMicStreamGetStreamMC.error>
  | ReturnType<typeof EpicApiServerPubCamMicMC.error>
  | ReturnType<typeof EpicBcastStartPubCamMicMC.error>
  | ReturnType<typeof EpicApiServerSubCamMicMC.error>
  | ReturnType<typeof EpicBcastStartSubCamMicMC.error>

export const cancelNowCamMicEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      CancelNowAT.CANCEL_CAM_MIC,

      EpicShareCamMicStreamGetStreamMT.ERROR,

      EpicApiServerPubCamMicMT.ERROR,
      EpicBcastStartPubCamMicMT.ERROR,

      EpicApiServerSubCamMicMT.ERROR,
      EpicBcastStartSubCamMicMT.ERROR,
    ),
    tap(() => {
      cancelCamMic$$.next()

      const {
        sharedCamMicStream,

        streamManagerPubCamMic,
        streamManagerSubCamMic,
      } = state$.value

      // Stop cam mic

      const id = streamManagerPubCamMic.id || streamManagerSubCamMic.id

      // Streams

      const streams = [sharedCamMicStream.curCamMicStream]

      // Stopping streams
      // the stop service can be pending because the lib, if no internet connection
      // so we do not add the stop in stop$.subscribe({ next: () => ... })

      ServiceWebRtc.Media.Stream.stop$(streams).subscribe(
        UtilsObservables.tapLogObserver('Stop cam-mic streams'),
      )

      // Stop publisher or subscriber

      BroadcastService.stop$({
        camMicPub: streamManagerPubCamMic.publisher,
        camMicSub: streamManagerSubCamMic.subscriber,
        stop: { id },
      })
        .pipe(tap(UtilsObservables.tapLogObserver('Stop cam mic')))
        .subscribe()
    }),

    map(({ payload }) => {
      if (Array.isArray(payload)) {
        return EpicCancelNowCamMicMC.done([...payload])
      }

      const cancelReason = CancelReasons.Unknown
      // 'reason' in action.payload.data
      //   ? (action.payload.data.reason as CancelReasons)
      //   : CancelReasons.Unknown

      return EpicCancelNowCamMicMC.done([cancelReason])
    }),
  )
