import { UtilsStore } from '@dn/utils'
import { AppInitState } from '../../../../../models/app/model'
import { PaintBcastStreamPicModelFieldsMR } from '../../../../../models/bcast/paint-bcast-stream/model'
import { PaintBcastStreamPicMT } from '../../../../actions-mutators/paint-bcast-stream/pic/mutators'
import { CancelNowAT } from '../../../../actions/cancel-now/actions'

const initState = AppInitState.paintBcastStreamPic

type State = typeof initState

const reducer: any = {
  ...PaintBcastStreamPicModelFieldsMR.canvas.Reducer,
}

// ####################################################################################################
// ~~~~~~ Clear edit
// ####################################################################################################

reducer[PaintBcastStreamPicMT.CLEAR_EDIT] = (state: State): State => {
  return AppInitState.paintBcastStreamPic
}

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ~~~~~~

export const paintBcastStreamPicReducer = UtilsStore.dynReducer(initState, reducer)
