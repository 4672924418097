import { ofType, StateObservable } from 'redux-observable'
import { map, mergeMap, Observable, of, tap } from 'rxjs'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { apiBcastGoogleSlidesAddImageSlide$ } from '../../../../../services/api-bcast/google-slides/add-image-slide'
import { apiBcastGoogleSlidesCreatePresentation$ } from '../../../../../services/api-bcast/google-slides/create-presentation'
import { UtilsLog } from '../../../../../utils/logs'
import {
  ApiBcastGoogleSlidesAC,
  ApiBcastGoogleSlidesAT,
} from '../../../../actions/api-bcast/google-slides/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC } from './mutators'

type Action = ReturnType<typeof ApiBcastGoogleSlidesAC.createAndAddImageSlide>

export const apiBcastGoogleSlidesCreateAndAddImageToSlideEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastGoogleSlidesAT.CREATE_PRESENTATION_AND_ADD_IMAGE_SLIDE),

    // Api calls

    mergeMap(({ payload }) => {
      const widthGoogleAccessToken = payload.withGoogleAccessToken.withIt
        ? payload.withGoogleAccessToken
        : {
            ...payload.withGoogleAccessToken,
            token: state$.value.currentUser.token,
          }

      // Create presentation

      return apiBcastGoogleSlidesCreatePresentation$(
        widthGoogleAccessToken,
        payload.createData,
      ).pipe(
        mergeMap((createRes) => {
          // Error on create

          if (!ApiUtils.isSuccess(createRes)) return of(createRes)

          // Add image to created slide

          return apiBcastGoogleSlidesAddImageSlide$(
            widthGoogleAccessToken,
            createRes.response.id,
            payload.imageData,
          ).pipe(
            map((res) => {
              return ApiUtils.isSuccess(res)
                ? {
                    status: res.status,
                    response: createRes.response,
                  }
                : res
            }),
          )
        }),
      )
    }),

    // Extension

    tap((res) => {
      if (
        !Config.isExtension ||
        ApiUtils.isSuccess(res) ||
        !window.chrome ||
        !window.chrome.identity
      ) {
        return
      }

      window.chrome.identity.clearAllCachedAuthTokens(() =>
        UtilsLog.devLog('clear all cached auth tokens'),
      )
    }),

    // Mutator

    map((res) => {
      return ApiUtils.isSuccess(res)
        ? EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC.ok(res.response)
        : EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC.error(
            res.response,
            /* unsync */ res.status === 412,
          )
    }),
  )
