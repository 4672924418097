import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  BrowserIsSafari,
  BrowserVersionBase,
  DevicePIPEnabled,
} from '../../../../../../constants/misc'
import { UiPipFullscreenFieldsMR } from '../../../../../../models/bcast/ui-pip-fullscreen/model'
import { UtilsFullscreen } from '@dn/utils'
import { StartedContentMainToolbarsMainButtonsCamMic } from './buttons/cam-mic/component'
import { StartedContentMainToolbarsMainButtonsChangeSource } from './buttons/change-source/component'
import { StartedContentMainToolbarsMainButtonsFullscreen } from './buttons/fullscreen/component'
import { StartedContentMainToolbarsMainButtonsPIP } from './buttons/pip/component'
import { StartedContentMainToolbarsMainButtonsStartLivePaint } from './buttons/start-live-paint/component'
import { ClassName, STStartedContentMainToolbarsMain } from './style'
import { StoreState } from '../../../../../../models/app/model'
import { useSelector } from 'react-redux'

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const StartedContentMainToolbarsMain: React.FC<Props> = ({ video }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [, setUpdate] = useState(performance.now())

  const dnBcastWebcamUiState = useSelector((state: StoreState) => state.dnBcast.webcamUiState)

  // ~~~~~~ Computed

  const showFullScreenButton = BrowserIsSafari
    ? BrowserVersionBase > 16
    : UtilsFullscreen.fullscreenIsSupported

  const webcamIsRunning = dnBcastWebcamUiState === 'running'

  // ~~~~~~ Handlers

  // We need this in order to sync pip and fullscreen icons
  // we can do it better using the store instead

  function onUpdate() {
    setUpdate(performance.now())

    dispatch(UiPipFullscreenFieldsMR.updatedPn.MC.change(performance.now()))
  }

  // ~~~~~~ Render

  return (
    <STStartedContentMainToolbarsMain>
      {/* Change source */}

      <div className={`${ClassName}--text-btn`}>
        <StartedContentMainToolbarsMainButtonsChangeSource />
      </div>

      {/* Cam Mic */}

      <div className={`${ClassName}--icon-btn ${webcamIsRunning ? 'running' : ''}`}>
        <StartedContentMainToolbarsMainButtonsCamMic />
      </div>

      {/* Live Paint */}

      <div className={`${ClassName}--icon-btn`}>
        <StartedContentMainToolbarsMainButtonsStartLivePaint />
      </div>

      {/* PIP */}

      {DevicePIPEnabled ? (
        <div className={`${ClassName}--icon-btn`}>
          <StartedContentMainToolbarsMainButtonsPIP video={video} onUpdate={onUpdate} />
        </div>
      ) : undefined}

      {/* Full screen */}

      {showFullScreenButton ? (
        <div className={`${ClassName}--icon-btn`}>
          <StartedContentMainToolbarsMainButtonsFullscreen onUpdate={onUpdate} />
        </div>
      ) : undefined}

      {/* -- */}
    </STStartedContentMainToolbarsMain>
  )
}
