const prefix = 'at-api-debugger-epic'

// ~~~~~~ Mutators Types

export const EpicApiDebuggerMT = {
  OK: `${prefix}-ok`,
  ERR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiDebuggerMC = {
  ok: () => ({
    type: EpicApiDebuggerMT.OK,
  }),

  error: () => ({
    type: EpicApiDebuggerMT.ERR,
    error: true,
  }),
} as const
