import { AnimationProps } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../../../models/app/model'
import { UiMobileToolbarFieldsMR } from '../../../../../../../models/bcast/ui-mobile-toolbars/model'
import { JoinedMobileToolbarsCapturePaintButtonsPensHighlighter } from './highlighter/component'
import { JoinedMobileToolbarsCapturePaintButtonsPensMarker } from './marker/component'
import { JoinedMobileToolbarsCapturePaintButtonsPensPen } from './pen/component'
import { STMotionJoinedMoblieToolbarsCapturePaintButtonsPens } from './style'

// ~~~~~~ Types

type Props = {
  showAll: boolean

  variantsContainer: AnimationProps['variants']

  isPortrait: boolean

  onClickSelectPen: (newPen: DN.Models.PaintBcastStream.Draw.Pen) => void
  onClickShowPenSelector: () => void
}

// ~~~~~~ Component

export const JoinedMoblieToolbarsCapturePaintButtonsPens: React.FC<Props> = ({
  showAll,

  variantsContainer,

  isPortrait,

  onClickSelectPen,
  onClickShowPenSelector,
}) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const selectedPen = useSelector((state: StoreState) => state.paintBcastStreamDraw.activePen)

  const isErasing = useSelector((state: StoreState) => state.paintBcastStreamDraw.isErasing)

  // ~~~~~~ Handlers

  function onClickSelectPenH(pen: typeof selectedPen) {
    dispatch(UiMobileToolbarFieldsMR.openStatus.MC.change('painting-closed'))

    onClickSelectPen(pen)
  }

  function onClickCurrentPen() {
    isErasing ? onClickSelectPenH(selectedPen) : onClickShowPenSelector()
  }

  // ~~~~~~ Render

  // Collapsed: Current Pen

  if (!showAll) {
    return (
      <STMotionJoinedMoblieToolbarsCapturePaintButtonsPens>
        {/* -- */}

        {selectedPen === 'pen' ? (
          <JoinedMobileToolbarsCapturePaintButtonsPensPen onClick={onClickCurrentPen} />
        ) : selectedPen === 'marker' ? (
          <JoinedMobileToolbarsCapturePaintButtonsPensMarker onClick={onClickCurrentPen} />
        ) : selectedPen === 'highlighter' ? (
          <JoinedMobileToolbarsCapturePaintButtonsPensHighlighter onClick={onClickCurrentPen} />
        ) : undefined}

        {/* -- */}
      </STMotionJoinedMoblieToolbarsCapturePaintButtonsPens>
    )
  }

  // Pen selector

  return (
    <STMotionJoinedMoblieToolbarsCapturePaintButtonsPens
      variants={variantsContainer}
      initial="hidden"
      animate="show"
      style={{
        display: 'flex',
        flexDirection: isPortrait ? 'column' : 'row',
      }}
    >
      {/* Pen */}

      <JoinedMobileToolbarsCapturePaintButtonsPensPen onClick={() => onClickSelectPenH('pen')} />

      {/* Marker */}

      <JoinedMobileToolbarsCapturePaintButtonsPensMarker
        onClick={() => onClickSelectPenH('marker')}
      />

      {/* Highlighter */}

      <JoinedMobileToolbarsCapturePaintButtonsPensHighlighter
        onClick={() => onClickSelectPenH('highlighter')}
      />

      {/* -- */}
    </STMotionJoinedMoblieToolbarsCapturePaintButtonsPens>
  )
}
