import { ServiceTrackEvents } from '@dn/track-events'
import { Config } from '../../../config'
import { Routes } from '../../../constants/routes/routes'

export const bcastTrackEventsInit = (opts?: { withLogs: true }) => {
  if (opts?.withLogs) {
    ServiceTrackEvents.setLog()
  }

  ServiceTrackEvents.init(
    {
      namespace: `bcast-${Config.ThirdParty.Analytics.env}`,

      appId: 'app-web',

      postPath: Config.ThirdParty.Analytics.PostPath,

      trackerUrl: Config.ThirdParty.Analytics.URL,

      contexts: [],

      versions: {
        events: {
          Action: {
            version: '1_0_0',
          },
        },

        contexts: {
          AppInfo: {
            version: '1_0_0',

            data: {
              flavour: 'vanilla',
              version: Config.App.Version,
              revision: Config.App.Revision,
            },
          },

          OsInfo: {
            version: '1_0_0',
          },

          BrowserInfo: {
            version: '1_0_0',
          },
        },
      },
    },

    // Opts
    {
      sessionCreated: window.location.pathname.split('/')[1]
        ? window.location.pathname.split('/')[1] === Routes.BuySuccess.split('/')[1]
        : false,
    },
  )
}
