const prefix = 'mt-live-paint-draw-stop-epic'

// ~~~~~~ Mutators Types

export const EpicLivePaintDrawStopMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicLivePaintDrawStopMC = {
  ok: () => ({
    type: EpicLivePaintDrawStopMT.OK,
  }),
} as const
