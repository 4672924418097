import { getSomeItemIsVisible } from './get-some-item-is-visible/draw-partial-service'
import { init } from './init/draw-partial-service'
import { DrawPublicCommands } from './public-commands'
import { setCanvas } from './set-canvas/draw-partial-service'
import { startPainting$ } from './start-painting/draw-partial-service'
import { DrawState } from './@state/draw-partial-service'
import { stop } from './stop/draw-partial-service'
import { updateView } from './update-view/draw-partial-service'

// ~~~~~~

export const DrawService = {
  init,
  setCanvas,
  startPainting$,
  updateView,
  getSomeItemIsVisible,
  stop,

  getState: () => ({
    Color: DrawState.State.Color,
    HasAlpha: DrawState.State.HasAlpha,
    StrokeWidth: DrawState.State.StrokeWidth,
    IsErasing: DrawState.State.IsErasing,
  }),

  Set: {
    color: DrawState.setColor,
    hasAlpha: DrawState.setHasAlpha,
    strokeWidth: DrawState.setStrokeWidth,
    isErasing: DrawState.setIsErasing,
  },

  Commands: DrawPublicCommands,
} as const
