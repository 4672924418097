import { useEffect, useState } from 'react'
import { SessionStorageNewVersion } from '../../../services/storage/session/new-version'
import { UtilsString } from '@dn/utils'
import * as serviceWorker from '../../../service-worker-registration'

// ~~~~~~ Constants

const userAgent = navigator.userAgent

const BrowserIsFF = UtilsString.hasStr(userAgent, 'firefox')

const BrowserIsCypress = UtilsString.hasStr(userAgent, 'cypress')

const ForceSkipWaitingMsg = 'FORCE_SKIP_WAITING'
const SkipWaitingMsg = 'SKIP_WAITING'

let swIsRegistered = false
let activeSWExists = false

let controllerChangeListener: any = undefined
let messageListener: any = undefined

// ~~~~~~ Helpers

function unregisterSW() {
  serviceWorker.unregister()
}

// ~~~~~~ Hook

export const useNewVersionAvailable = () => {
  // ~~~~~~ State

  const [hideMessage, setHideMessage] = useState(SessionStorageNewVersion.get())

  const [newVersionAvailable, setNewVersionAvailable] = useState(false)

  const [updatingSW, setUpdatingSW] = useState(false)

  // ~~~~~~ Handlers

  // Config Service Worker

  function configureSW() {
    if (swIsRegistered || !navigator.serviceWorker) return

    swIsRegistered = true

    // Reload the browser if new service worker is activated
    controllerChangeListener &&
      navigator.serviceWorker.removeEventListener('controllerchange', controllerChangeListener)

    controllerChangeListener = navigator.serviceWorker.addEventListener('controllerchange', () => {
      // Prevent reload the browser if is the first swervice worker installation
      if (!activeSWExists) {
        activeSWExists = true
        return
      }

      window.location.reload()
    })

    serviceWorker.register({
      onRegister: (registrationSW) => {
        activeSWExists = !!registrationSW.active

        // If browser is firefox, enable ping-pong to force Skip Waiting in all tabs/windows

        messageListener && navigator.serviceWorker.removeEventListener('message', messageListener)

        messageListener = BrowserIsFF
          ? navigator.serviceWorker.addEventListener('message', (evt) => {
              if (evt.data.msg !== ForceSkipWaitingMsg) return

              setNewVersionAvailable(true)
              setUpdatingSW(true)

              navigator.serviceWorker.ready.then((swRegistration) => {
                if (swRegistration.waiting) {
                  // We don't need to clear this timeout
                  setTimeout(() => {
                    swRegistration.waiting &&
                      swRegistration.waiting.postMessage({
                        type: SkipWaitingMsg,
                      })
                  }, 500)
                } else {
                  // We don't need to clear this timeout
                  setTimeout(() => {
                    window.location.reload()
                  }, 500)
                }
              })
            })
          : undefined
      },

      onReadyForUpdate: () => {
        navigator.serviceWorker.ready.then((swRegistration) => {
          const waiting = !!swRegistration.waiting

          setNewVersionAvailable(waiting)
        })
      },
    })
  }

  // Update Service Worker

  function updateSW() {
    if (updatingSW || !navigator.serviceWorker) return

    setUpdatingSW(true)

    navigator.serviceWorker.ready.then((swRegitration) => {
      swRegitration.waiting && swRegitration.waiting.postMessage({ type: SkipWaitingMsg })
    })
  }

  // Dismiss message

  function dismiss() {
    setHideMessage(true)
    SessionStorageNewVersion.set()
  }

  // ~~~~~~ Effects

  // - Unregister service worker if we are in e2e tests

  useEffect(() => {
    BrowserIsCypress ? unregisterSW() : configureSW()

    return () => {
      // Remove controller change listener
      controllerChangeListener &&
        navigator.serviceWorker.removeEventListener('controllerchange', controllerChangeListener)

      // Remove message listener
      messageListener && navigator.serviceWorker.removeEventListener('message', messageListener)
    }
  }, [])

  // ~~~~~~

  return {
    newVersionAvailable: newVersionAvailable && !hideMessage,
    updatingSW,
    updateSW,
    dismiss,
  } as const
}
