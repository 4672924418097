import { ofType, StateObservable } from 'redux-observable'
import { Observable, filter, map } from 'rxjs'
import { UtilsString } from '@dn/utils'
import { StoreState } from '../../../../../models/app/model'
import {
  EpicShareMainStreamGetStreamMC,
  EpicShareMainStreamGetStreamMT,
} from '../../../share-main-stream/get-stream/mutators'
import { ApiBcastStreamContextAC } from '../../../../actions/api-bcast/stream-context/actions'
import { Config } from '../../../../../config'
import { ConstBcast } from '../../../../../constants/bcast'

type Action = ReturnType<typeof EpicShareMainStreamGetStreamMC.ok>

export const deprecatedChainingPubMainFromGetStreamToGetStreamContextId$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicShareMainStreamGetStreamMT.OK),

    filter(
      () =>
        !Config.Features.BcastService2 ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v1'),
    ),

    map(() => {
      // Gen broadcast ID

      const broadcastId = UtilsString.genRandomStr(ConstBcast.IdLength, {
        symbols: false,
        numbers: true,
        letters: false,
      })

      // Create stream context

      return ApiBcastStreamContextAC.create(broadcastId)
    }),
  )
