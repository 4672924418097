import styled from 'styled-components'
import { MediaQuery } from '../../../style/constants/media-queries'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'

// ~~~~~~ Top Left

export const STLayoutTextTopLeft = styled.div`
  display: flex;
  align-items: center;

  .layout-text-top-left-back {
    cursor: pointer;

    svg {
      height: 20px;

      path {
        fill: ${theme(Colors.WOLF_05_05)};
      }
    }
  }
`

// ~~~~~~ Main

export const STLayoutText = styled.div`
  padding-top: 48px;

  background-color: ${theme(Colors.WOLF_00_00)};

  max-height: calc(100vh - 184px);
  overflow-y: scroll;

  .layout-text-header-and-content {
    margin-left: 120px;
    margin-right: 120px;

    .layout-text-content {
      user-select: text;

      color: ${theme(Colors.WOLF_80_80)};
      ${Fonts.Weight[500]}

      h1 {
        ${Fonts.Scale.Headline1}
        ${Fonts.Weight[700]}

        color: ${theme(Colors.WOLF_100_100)};
      }

      h2 {
        ${Fonts.Scale.Title}
        ${Fonts.Weight[700]}

        color: ${theme(Colors.WOLF_80_80)};

        margin-top: 40px;
        margin-bottom: 24px;
      }

      p {
        ${Fonts.Scale.Body}
        ${Fonts.Weight[500]}

        margin-bottom: 24px;
      }

      table {
        margin-bottom: 24px;
      }

      a {
        text-decoration: none;
        color: ${theme(Colors.EMMET_40_40)};

        &:visited {
          color: ${theme(Colors.EMMET_40_40)};
        }

        &:active {
          color: ${theme(Colors.EMMET_40_40)};
        }
      }

      ol li {
        list-style: decimal;
        list-style-position: inside;
      }

      .indent-1 {
        margin-left: 24px;
      }

      .p-like {
        ${Fonts.Scale.Body}
        ${Fonts.Weight[500]}

        margin-bottom: 24px;
      }
    }
  }

  @media (max-width: ${MediaQuery.SM}) {
    .layout-text-header-and-content {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
`
