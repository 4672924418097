import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'
import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { STPaintToolbarButtonsMarker } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/tool-marker')

// ~~~~~~ Types

type Props = {
  actionKind: 'paint-bcast-stream' | 'live-paint'

  onClick: () => void
}

// ~~~~~~ Component

export const PaintToolbarButtonsMarker: React.FC<Props> = ({ actionKind, onClick }) => {
  // ~~~~~~ State

  const { activePen, isErasing } = useSelector((state: StoreState) => {
    switch (actionKind) {
      case 'paint-bcast-stream':
        return state.paintBcastStreamDraw

      case 'live-paint':
        return state.livePaintDraw
    }
  })

  // ~~~~~~ Computed

  const selected = !isErasing && activePen === 'marker'

  // ~~~~~~ Render

  return (
    <STPaintToolbarButtonsMarker $selected={selected} $disabled={false} onClick={onClick}>
      <Icon size={24} />
    </STPaintToolbarButtonsMarker>
  )
}
