import { UtilsStore } from '@dn/utils'

const initModel: DN.Models.UiPipFullscreen.Model = {
  updatedPn: 0,
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-ui-pip-fullscreen-model'

export const UiPipFullscreenFieldsMR = {
  updatedPn: UtilsStore.genBasicFieldMR(prefix, initModel, 'updatedPn', initModel.updatedPn),
}

// ~~~~~~

export const UiPipFullscreenModel = {
  initModel,
} as const
