const prefix = 'at-live-paint-draw'

// ~~~~~ Action types

export const LivePaintDrawAT = {
  START: `${prefix}-start`,
  UPDATE_PROPS: `${prefix}-update-props`,
  UPDATE_VIEW: `${prefix}-update-view`,

  CMD_UNDO: `${prefix}-cmd-undo`,
  CMD_REDO: `${prefix}-cmd-redo`,
  CMD_CLEAR: `${prefix}-cmd-clear`,

  STOP: `${prefix}-stop`,
} as const

// ~~~~~~ Action Creators

export const LivePaintDrawAC = {
  start: (canvas: HTMLCanvasElement, width: number, height: number) => ({
    type: LivePaintDrawAT.START,
    payload: {
      canvas,
      width,
      height,
    },
  }),

  updateProps: (patch: Partial<Omit<DN.Models.LivePaint.Draw.Model, 'scope'>>) => ({
    type: LivePaintDrawAT.UPDATE_PROPS,
    payload: patch,
  }),

  updateView: (width: number, height: number) => ({
    type: LivePaintDrawAT.UPDATE_VIEW,
    payload: {
      width,
      height,
    },
  }),

  cmdUndo: () => ({
    type: LivePaintDrawAT.CMD_UNDO,
  }),

  cmdRedo: () => ({
    type: LivePaintDrawAT.CMD_REDO,
  }),

  cmdClear: () => ({
    type: LivePaintDrawAT.CMD_CLEAR,
  }),

  stop: () => ({
    type: LivePaintDrawAT.STOP,
  }),
} as const
