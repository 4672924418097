import { ofType, StateObservable } from 'redux-observable'
import { Observable, filter, map } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { ApiStreamManagerServerAC } from '../../../../actions/api-stream-manager/server/actions'
import {
  EpicApiBcastStreamContextCreateMC,
  EpicApiBcastStreamContextCreateMT,
} from '../../../api-bcast/stream-context/create/mutators'
import { Config } from '../../../../../config'

type Action = ReturnType<typeof EpicApiBcastStreamContextCreateMC.ok>

export const deprecatedChainingPubMainFromCreateStreamContextIdToGetServerPubMain$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiBcastStreamContextCreateMT.OK),

    filter(
      () =>
        !Config.Features.BcastService2 ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v1'),
    ),

    map(({ payload }) => ApiStreamManagerServerAC.getServerPubMain(payload.bcastId)),
  )
