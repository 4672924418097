import { AppInitState } from '../../../../../models/app/model'
import { LivePaintDrawModelFieldsMR } from '../../../../../models/bcast/live-paint/model'
import { UtilsStore } from '@dn/utils'
import { CancelNowAT } from '../../../../actions/cancel-now/actions'
import {
  EpicLivePaintDrawCommandMT,
  EpicLivePaintDrawCommandMC,
} from '../../../../epics/live-paint/draw/commands/mutators'
import {
  EpicLivePaintDrawStartMT,
  EpicLivePaintDrawStartMC,
} from '../../../../epics/live-paint/draw/start/mutators'
import { EpicLivePaintDrawStopMT } from '../../../../epics/live-paint/draw/stop/mutators'
import {
  EpicLivePaintDrawUpdatePropsMT,
  EpicLivePaintDrawUpdatePropsMC,
} from '../../../../epics/live-paint/draw/update-props/mutators'

const initState = AppInitState.livePaintDraw

type State = typeof initState

const reducer: any = {
  ...LivePaintDrawModelFieldsMR.isDownloaded.Reducer,
}

// ####################################################################################################
// ~~~~~~ Start Ok
// ####################################################################################################

reducer[EpicLivePaintDrawStartMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicLivePaintDrawStartMC.ok>,
): State => {
  return {
    ...state,
    scope: payload.scope,
    canvas: payload.canvas,
  }
}

// ####################################################################################################
// ~~~~~~ Update props
// ####################################################################################################

reducer[EpicLivePaintDrawUpdatePropsMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicLivePaintDrawUpdatePropsMC.ok>,
): State => {
  return {
    ...state,
    ...payload,
  }
}

// ####################################################################################################
// ~~~~~~ Command Add Path
// ####################################################################################################

reducer[EpicLivePaintDrawCommandMT.ADD_PATH] = (state: State): State => {
  return {
    ...state,
    undos: state.undos + 1,
    redos: 0,
    lastCmd: 'addPath',
    isDirty: true,
    isDownloaded: false,
  }
}

// ####################################################################################################
// ~~~~~~ Command Rem Path
// ####################################################################################################

reducer[EpicLivePaintDrawCommandMT.REM_PATH] = (
  state: State,
  { payload }: ReturnType<typeof EpicLivePaintDrawCommandMC.remPath>,
): State => {
  return {
    ...state,
    undos: state.undos + 1,
    redos: 0,
    lastCmd: 'remPath',
    isDirty: payload.isDirty,
  }
}

// ####################################################################################################
// ~~~~~~ Commmand Clear
// ####################################################################################################

reducer[EpicLivePaintDrawCommandMT.CLEAR] = (state: State): State => {
  return {
    ...state,
    undos: state.undos + 1,
    redos: 0,
    lastCmd: 'clear',
    isDirty: false,
  }
}

// ####################################################################################################
// ~~~~~~ Command Undo
// ####################################################################################################

reducer[EpicLivePaintDrawCommandMT.UNDO] = (
  state: State,
  { payload }: ReturnType<typeof EpicLivePaintDrawCommandMC.undo>,
): State => {
  const newUndos = state.undos - 1

  const finalUndos = newUndos < 0 ? 0 : newUndos

  return {
    ...state,
    undos: finalUndos,
    redos: newUndos < 0 ? state.redos : state.redos + 1,
    lastCmd: payload.command,
    isDirty: payload.isDirty,
  }
}

// ####################################################################################################
// ~~~~~~ Command Redo
// ####################################################################################################

reducer[EpicLivePaintDrawCommandMT.REDO] = (
  state: State,
  { payload }: ReturnType<typeof EpicLivePaintDrawCommandMC.redo>,
): State => {
  const newRedos = state.redos - 1

  return {
    ...state,
    undos: state.undos + 1,
    redos: newRedos < 0 ? 0 : newRedos,
    lastCmd: payload.command,
    isDirty: payload.isDirty,
  }
}

// ####################################################################################################
// ~~~~~~ Stop Ok
// ####################################################################################################

reducer[EpicLivePaintDrawStopMT.OK] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ~~~~~~

export const livePaintDrawReducer = UtilsStore.dynReducer(initState, reducer)
