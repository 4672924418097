import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'layout-viewer-mobile'

export const LayoutViewerMobileSizes = {
  HeaderHeight: 72,
  ContentPaddingBottom: 12,
  ContentPaddingSides: 12,
}

const { HeaderHeight, ContentPaddingSides, ContentPaddingBottom } = LayoutViewerMobileSizes

// ~~~~~~ Component

export const STLayoutViewerMobile = styled.div`
  box-sizing: border-box;
  height: 100%;

  max-width: ${({ theme }) => `${theme.pageWidth ? `${theme.pageWidth}px` : '100vw'}`};
  max-height: ${({ theme }) => `${theme.pageHeight ? `${theme.pageHeight}px` : '100vh'}`};

  overflow: hidden;

  background-color: ${theme(Colors.WOLF_05_100)};

  display: flex;

  /* Float Panel */

  .${ClassName}--float-panel {
    position: absolute;

    z-index: 30;

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    overflow: hidden;

    &.hidden {
      display: none;
    }

    .${ClassName}--float-panel--inner {
      width: 100%;
      height: 100%;

      background-color: ${theme(Colors.WOLF_00_100)};
    }
  }

  /* Sound */

  .${ClassName}--sound {
    position: absolute;
    z-index: 20;

    top: ${HeaderHeight + 8}px;

    &.no-header {
      top: 8px;
    }

    &.hidden {
      display: none;
    }

    .${ClassName}--sound--inner {
      width: 100%;
    }
  }

  /* Toolbar */

  .${ClassName}--toolbar {
    .${ClassName}--toolbar--inner {
      position: absolute;
      z-index: 20;

      bottom: ${Layout.Spacing[4]}px;
      right: ${Layout.Spacing[4]}px;
    }
  }

  /* Header + Content */

  .${ClassName}--middle {
    padding-left: ${ContentPaddingSides}px;
    padding-right: ${ContentPaddingSides}px;

    flex: 1;

    display: flex;
    flex-direction: column;

    width: auto;
    overflow: hidden;

    /* Header */

    .${ClassName}--middle--header {
      &.hidden {
        display: none;
      }

      .${ClassName}--middle--header--inner {
        width: 100%;
        height: ${HeaderHeight}px;

        display: flex;
        align-items: center;
      }
    }

    /* Content */

    .${ClassName}--middle--content {
      padding-bottom: ${ContentPaddingBottom}px;
      flex: 1;

      .${ClassName}--middle--content--inner {
        width: 100%;
        height: 100%;

        max-height: ${({ theme }) =>
          theme.pageHeight
            ? `calc(${theme.pageHeight}px - ${HeaderHeight + ContentPaddingBottom}px)`
            : `calc(100vh - ${HeaderHeight + ContentPaddingBottom}px)`};

        &.no-header {
          max-height: ${({ theme }) =>
            theme.pageHeight
              ? `${theme.pageHeight - ContentPaddingBottom}px`
              : `calc(100vh - ${ContentPaddingBottom}px)`};
        }
      }
    }
  }

  /* Webcam */

  .${ClassName}--webcam {
    &.hidden {
      display: none;
    }

    .${ClassName}--webcam--inner {
      position: absolute;
      z-index: 20;
      left: 0;
      bottom: 0;
    }
  }
`
