import { StateObservable, ofType } from 'redux-observable'
import { EMPTY, filter, mergeMap, Observable } from 'rxjs'
import { Track } from 'livekit-client'
import { ServiceBcast } from '@dn/bcast'
import { StoreState } from '../../../../../models/app/model'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../actions/live-paint/draw/actions'
import { EpicLivePaintReplaceTrackOnStopDrawMC } from './mutators'
import { Config } from '../../../../../config'

type Action = ReturnType<typeof LivePaintDrawAC.stop>

export const livePaintReplaceTrackOnDrawStopEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.STOP),

    filter(
      () =>
        (Config.Features.BcastService2 && !Config.Features.BcastCheckVersion) ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v2'),
    ),

    mergeMap(() => {
      const room = state$.value.dnBcast.room

      if (!ServiceBcast.Guards.isRoom(room)) return EMPTY

      const screenLocalTrackPub = room.localParticipant.getTrackPublication(
        Track.Source.ScreenShare,
      )

      if (!screenLocalTrackPub) return EMPTY

      const localVideoTrack = screenLocalTrackPub.videoTrack

      if (!localVideoTrack) return EMPTY

      const localScreenTrack = state$.value.dnBcast.localScreenTrack

      if (!localScreenTrack) return EMPTY

      const obs$ = new Observable((observer) => {
        localVideoTrack.replaceTrack(localScreenTrack).then(() => {
          const { canvasStreamStop } = state$.value.sharedMainStream

          // --|
          if (!canvasStreamStop) return observer.complete()

          canvasStreamStop()

          // --))-|
          observer.next(EpicLivePaintReplaceTrackOnStopDrawMC.ok())
          observer.complete()
        })
      })

      return obs$
    }),
  )
