const initModel: DN.Models.Modals.Model = {
  list: [],
  confirm: {},
  values: {},
}

// ~~~~~~

export const ModalsModel = {
  initModel,
}
