import { useDispatch } from 'react-redux'
import { UtilsStore } from '@dn/utils'
import { UtilsValidate } from '../../utils/validations'

// ~~~~~~ Types

type GenFieldMR = ReturnType<typeof UtilsStore.genFieldMR<any, any, Infos>>

// ~~~~~~ Hook

export const useValidator = (
  validators: DN.Utils.Validations.Validator<IntlMsgId>[],
  fieldMR: GenFieldMR,
) => {
  const dispatch = useDispatch()

  function validator(value: string, ...others: any[]) {
    const errors = UtilsValidate.validate([value, ...others], validators)

    dispatch(errors.length ? fieldMR.MC.setInvalid(errors) : fieldMR.MC.setValid())

    return errors
  }

  return validator
}
