import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { CancelNowAT } from '../../../actions/cancel-now/actions'
import { StoreDurationFieldsMR } from '../../../../models/bcast/store-duration/model'

const initState = AppInitState.storeDuration

type State = typeof initState

const reducer: any = {
  ...StoreDurationFieldsMR.timerId.Reducer,
}

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (state: State): State => {
  clearInterval(state.timerId)

  return initState
}

// ~~~~~~

export const storeDurationReducer = UtilsStore.dynReducer(initState, reducer)
