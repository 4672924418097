import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { getThemeValue, theme } from '../../../../../style/utils/theme'

// ~~~~~~ Types

type STProps = {
  $isFreePlan: boolean
}

// ~~~~~~ Component

export const STDialogsSubscriptionsPlansForDialogsSharedStyleContent = styled.div<STProps>`
  text-align: left;

  height: 100%;
  width: 100%;

  .plans-for-dialogs-logo {
    width: 154px;

    svg path {
      fill: ${(props) =>
        getThemeValue(props, props.$isFreePlan ? Colors.WOLF_100_05 : Colors.WOLF_05_100)};
    }
  }

  .plans-for-dialogs-title {
    ${Fonts.Scale.Title}
    ${Fonts.Weight[700]}

    color: ${(props) =>
      getThemeValue(props, props.$isFreePlan ? Colors.WOLF_100_05 : Colors.BRONX_50_50)};

    margin-bottom: ${Layout.Spacing[4]}px;
  }

  .plans-for-dialogs-subtitle {
    ${Fonts.Scale.Body}

    margin-bottom: ${Layout.Spacing[2]}px;
  }

  .plans-for-dialogs-content {
    .line {
      ${Fonts.Scale.Body}

      padding-bottom: ${Layout.Spacing[2]}px;

      display: flex;

      ${({ $isFreePlan }) => ($isFreePlan ? Fonts.Weight[500] : Fonts.Weight[700])};

      .icon {
        padding: 2px;
        margin-right: 8px;

        width: 16px;
        height: 16px;

        svg path {
          fill: ${(props) =>
            getThemeValue(props, props.$isFreePlan ? Colors.WOLF_100_05 : Colors.WOLF_05_100)};
        }
      }
    }
  }

  hr {
    border: none;

    margin: ${Layout.Spacing[4]}px 0 ${Layout.Spacing[4]}px 0;
  }

  .plans-for-dialogs-payment {
    .line {
      display: flex;
      align-items: center;

      &.switch {
        margin-bottom: ${Layout.Spacing[2]}px;

        .switch {
          margin-left: ${Layout.Spacing[2]}px;
          margin-right: ${Layout.Spacing[2]}px;
        }

        .pay-yearly {
          display: flex;
          align-items: center;

          .pay-save {
            ${Fonts.Scale.Label}
            ${Fonts.Weight[700]}

            color: ${theme(Colors.WOLF_100_05)};
            background-color: ${theme(Colors.WOLF_05_100)};

            ${Layout.Border.Radius.B}
            margin-left: ${Layout.Spacing[2]}px;

            padding: ${Layout.Spacing[1]}px ${Layout.Spacing[2]}px ${Layout.Spacing[1]}px
              ${Layout.Spacing[2]}px;
          }
        }
      }

      &.price {
        .price {
          ${Fonts.Scale.Title}

          ${Fonts.Weight[700]}
        }

        .price-separator {
          ${Fonts.Scale.Title}

          padding-right: 4px;
          padding-left: 4px;
        }

        .price-time {
          ${Fonts.Scale.BodyBig}

          align-self: flex-end;
        }
      }
    }
  }
`
