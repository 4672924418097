import styled from 'styled-components'
import { MediaQuery } from '../../../style/constants/media-queries'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-settings'

// ~~~~~~ Component

export const STUserSettings = styled.div`
  display: flex;
  flex-direction: column;

  padding: 70px 180px;

  width: calc(100% - ${180 * 2}px);
  height: calc(100% - ${70 * 2}px);

  .${ClassName}--header {
    margin-bottom: ${Layout.Spacing[9]}px;

    color: ${theme(Colors.WOLF_100_05)};

    .${ClassName}--header--title {
      ${Fonts.Scale.Title};
      ${Fonts.Weight[700]};
    }
  }

  .${ClassName}--panel {
    display: flex;

    height: 100%;

    .${ClassName}--pannel--left {
      padding-right: ${Layout.Spacing[5]}px;

      border-right: solid 1px ${theme(Colors.WOLF_10_90)};

      width: 220px;

      .${ClassName}--pannel--left--section {
        cursor: pointer;

        display: flex;
        align-items: center;

        padding: ${Layout.Spacing[2]}px;
        padding-right: 60px;

        ${Layout.Border.Radius.A}

        &.active {
          cursor: default;

          ${Fonts.Weight[500]}

          color: ${theme(Colors.WOLF_00_100)};

          background-color: ${theme(Colors.EMMET_50_40)};

          .${ClassName}--pannel--left--section--icon {
            &.fill {
              svg path {
                fill: ${theme(Colors.WOLF_00_100)};
                stroke: none;
              }
            }

            &.stroke {
              svg path {
                fill: none;
                stroke: ${theme(Colors.WOLF_00_100)};
              }
            }
          }
        }

        .${ClassName}--pannel--left--section--icon {
          display: flex;
          align-items: center;

          margin-right: ${Layout.Spacing[2]}px;

          svg {
            width: 20px;
            height: 20px;
          }

          &.fill {
            svg path {
              fill: ${theme(Colors.WOLF_100_05)};
            }
          }

          &.stroke {
            svg path {
              stroke: ${theme(Colors.WOLF_100_05)};
            }
          }
        }
      }
    }

    .${ClassName}--panel--right {
      flex: 1;
      max-width: 600px;
    }
  }

  @media (max-width: ${MediaQuery.MD}) {
    padding: 35px 24px;

    width: calc(100% - ${24 * 2}px);
    height: calc(100% - ${35 * 2}px);
  }

  @media (max-width: ${MediaQuery.SM}) {
    padding-left: ${Layout.Spacing[3]}px;
    padding-right: ${Layout.Spacing[3]}px;

    width: calc(100% - ${Layout.Spacing[3] * 2}px);
    height: 100%;
  }
`
