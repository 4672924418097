import { AppInitState } from '../../../../models/app/model'
import { RootModelFieldsMR } from '../../../../models/bcast/root/model'
import { UtilsStore } from '@dn/utils'
import { HooksEvents } from '@dn/hooks'
import { RootMT } from '../../../actions-mutators/root/mutators'
import {
  EpicBcastCommChannelMessagesFromServerMC,
  EpicBcastCommChannelMessagesFromServerMT,
} from '../../../epics/comm-channel/messages/from-server/mutators'
import {
  EpicApiBcastRoomVersionMC,
  EpicApiBcastRoomVersionMT,
} from '../../../epics/api-bcast/room/version/mutators'
import { ExitMT } from '../../../actions-mutators/exit/mutators'
import { ApiBcastRoomAC, ApiBcastRoomAT } from '../../../actions/api-bcast/room/actions'

const initState = AppInitState.root

type State = typeof initState

const reducer: any = {
  ...RootModelFieldsMR.prevPath.Reducer,
  ...RootModelFieldsMR.redirectPath.Reducer,
  ...RootModelFieldsMR.userKind.Reducer,
}

// ####################################################################################################
// ~~~~~~ Get Room Version
// ####################################################################################################

reducer[ApiBcastRoomAT.VERSION] = (
  state: State,
  { payload }: ReturnType<typeof ApiBcastRoomAC.version>,
): State => {
  return {
    ...state,
    uiRoomVersion: 'running',
    uiRoomVersionErrors: [],
    bcastId: payload.bcastId,
    version: 'unknown',
  }
}

reducer[EpicApiBcastRoomVersionMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastRoomVersionMC.ok>,
): State => {
  return {
    ...state,
    uiRoomVersion: 'completed',
    version: payload.roomKind,
  }
}

reducer[EpicApiBcastRoomVersionMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastRoomVersionMC.error>,
): State => {
  return {
    ...state,
    bcastId: '',
    uiRoomVersion: 'completed',
    uiRoomVersionErrors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Reset Exit
// ####################################################################################################

reducer[ExitMT.RESET_EXIT] = (state: State): State => {
  return {
    ...state,
    uiRoomVersion: 'init',
    version: 'unknown',
    bcastId: '',
    uiRoomVersionErrors: [],
  }
}

// On log out there is a redirecction and the store is cleared

// ####################################################################################################
// ~~~~~~ Side menu
// ####################################################################################################

reducer[RootMT.SHOW_SIDE_MENU] = (state: State): State => {
  return {
    ...state,
    showSideMenu: true,
  }
}

reducer[RootMT.HIDE_SIDE_MENU] = (state: State): State => {
  return {
    ...state,
    showSideMenu: false,
  }
}

// ####################################################################################################
// ~~~~~~ Connection status
// ####################################################################################################

reducer[HooksEvents.NavigatorOnlineStatus.Mutators.MT.ONLINE] = (state: State): State => {
  if (state.connectionStatus === 'online') return state

  return {
    ...state,
    connectionStatus: 'online',
  }
}

reducer[HooksEvents.NavigatorOnlineStatus.Mutators.MT.OFFLINE] = (state: State): State => {
  if (state.connectionStatus === 'offline') return state

  return {
    ...state,
    connectionStatus: 'offline',
  }
}

// ####################################################################################################
// ~~~~~~ User has interacted
// ####################################################################################################

reducer[HooksEvents.WindowClick.Mutators.MT.CLICK] = (state: State): State => {
  return {
    ...state,
    userHasInteracted: performance.now(),
  }
}

// ####################################################################################################
// ~~~~~~ On receive current bcaseter state about cam-mic from server (websocket)
// ####################################################################################################

reducer[EpicBcastCommChannelMessagesFromServerMT.BCAST_CURRENT_STATE] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastCommChannelMessagesFromServerMC.bcastCurrentState>,
): State => {
  if (!payload.sharingCamMic) return state

  // Only save the first time as we do with "userHasInteracted"

  if (state.viewerCamHasStarted) return state

  return {
    ...state,

    viewerCamHasStarted: performance.now(),
  }
}

// ~~~~~~

export const rootReducer = UtilsStore.dynReducer(initState, reducer, (state) => {
  return state
})
