const prefix = 'mt-api-products-list-epic'

// ~~~~~~ Mutators Types

export const EpicApiProductsListMT = {
  OK: `${prefix}-ok-list`,
  OK_BROADCAST_SUB: `${prefix}-ok-broadcast-products`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiProductsListMC = {
  okList: (
    productId: DN.Models.StoreProducts.ProductId,
    products: DN.Models.StoreProducts.Api.List.ResParsedList,
  ) => ({
    type: EpicApiProductsListMT.OK,
    payload: {
      productId,
      products,
    },
  }),

  okBroadcastProducts: (products: DN.Models.StoreProducts.Api.List.ResParsedBroadcastProducts) => ({
    type: EpicApiProductsListMT.OK_BROADCAST_SUB,
    payload: {
      products,
    },
  }),

  error: (productId: DN.Models.StoreProducts.ProductId, errors: Infos[]) => ({
    type: EpicApiProductsListMT.ERROR,
    error: true,
    payload: {
      productId,
      errors,
    },
  }),
} as const
