export const Sito = {
  90: '#2a5915',
  80: '#36751c',
  70: '#469724',
  60: '#5ac12e',
  50: '#63d432',
  40: '#82dd5b',
  30: '#96e276',
  20: '#b7eba1',
  10: '#cff2bf',
  5: '#effbeb',
} as const

// ~~~~~~

const SITO_05_05: ThemeColor = {
  light: Sito[5],
  dark: Sito[5],
}

const SITO_20_20: ThemeColor = {
  light: Sito[20],
  dark: Sito[20],
}

const SITO_30_30: ThemeColor = {
  light: Sito[30],
  dark: Sito[30],
}

const SITO_40_30: ThemeColor = {
  light: Sito[40],
  dark: Sito[30],
}

const SITO_50_20: ThemeColor = {
  light: Sito[50],
  dark: Sito[20],
}

const SITO_50_40: ThemeColor = {
  light: Sito[50],
  dark: Sito[40],
}

const SITO_50_50: ThemeColor = {
  light: Sito[50],
  dark: Sito[50],
}

const SITO_60_50: ThemeColor = {
  light: Sito[60],
  dark: Sito[50],
}

const SITO_60_60: ThemeColor = {
  light: Sito[60],
  dark: Sito[60],
}

const SITO_70_70: ThemeColor = {
  light: Sito[70],
  dark: Sito[70],
}

// ~~~~~~

export const SitoColors = {
  SITO_05_05,
  SITO_20_20,
  SITO_30_30,
  SITO_40_30,
  SITO_50_20,
  SITO_50_40,
  SITO_50_50,
  SITO_60_50,
  SITO_60_60,
  SITO_70_70,
} as const
