import React from 'react'
import { useViewerMobileContext } from '../../hooks/use-viewer-mobile-context'

// ~~~~~~ Component

export const LayoutViewerMobileFloatPanel: React.FC<any> = ({ children }) => {
  const { kind } = useViewerMobileContext()

  return <>{kind === 'float-panel' ? children : null}</>
}
