import { UserMenu } from '../../../components/common/user-menu/component'
import { STUserSettingsTopRight } from './style'

// ~~~~~~ Component

export const UserSettingsTopRight = () => {
  // ~~~~~~ Render

  return (
    <STUserSettingsTopRight>
      {/* Menu */}

      <div className="user-menu">
        <UserMenu />
      </div>
    </STUserSettingsTopRight>
  )
}
