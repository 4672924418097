import { AppInitState } from '../../../../../models/app/model'
import { LivePaintVideoMR } from '../../../../../models/bcast/live-paint/model'
import { UtilsStore } from '@dn/utils'
import { CancelNowAT } from '../../../../actions/cancel-now/actions'

const initState = AppInitState.livePaintVideo

type State = typeof initState

const reducer: any = {
  ...LivePaintVideoMR.video.Reducer,
  ...LivePaintVideoMR.originalVideoW.Reducer,
  ...LivePaintVideoMR.originalVideoH.Reducer,
}

// ####################################################################################################
// ~~~~~~ Get stream END
// ####################################################################################################

// reducer[EpicPaintCaptureStreamGetStreamMT.END] = (_state: State): State => {
//   return initState
// }

// ####################################################################################################
// ~~~~~~ Get stream by extension END
// ####################################################################################################

// reducer[EpicPaintCaptureStreamGetStreamByExtensionMT.END] = (_state: State): State => {
//   return initState
// }

// ####################################################################################################
// ~~~~~~ Cancel now - START
// ####################################################################################################

reducer[CancelNowAT.CANCEL_ALL] = (): State => {
  return initState
}

// ~~~~~~

export const LivePaintVideoReducer = UtilsStore.dynReducer(initState, reducer)
