import { ofType, StateObservable } from 'redux-observable'
import { map, Observable } from 'rxjs'
import { StoreState } from '../../../../models/app/model'

import { BcastTrackEvents } from '../../../../services/track-events'
import { LocalStorageStoreDuration } from '../../../../services/storage/local/store-duration'
import { StoreDurationFieldsMR } from '../../../../models/bcast/store-duration/model'
import {
  EpicBcastStartSubMainMC,
  EpicBcastStartSubMainMT,
} from '../../bcast/start-sub-main/mutators'

// ~~~~~~ Constants

const ONE_MIN = 60_000

// ~~~~~~ Types

type Action = ReturnType<typeof EpicBcastStartSubMainMC.ok>

// ~~~~~~ Epic

export const chainingStoreDurationFromStartSubMain$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicBcastStartSubMainMT.OK),

    map(() => {
      const state = state$.value

      const streamContextData = state.streamContext

      const streamContext = BcastTrackEvents.helpers.genStreamContext(
        streamContextData.id,
        streamContextData.kind,
      )

      const storeDuration = state.storeDuration

      clearInterval(storeDuration.timerId)

      let mins = 0

      const intervalId = setInterval(() => {
        mins += 1

        LocalStorageStoreDuration.set({
          kind: 'sub',
          streamContext,
          mins,
        })
      }, ONE_MIN)

      // Action

      return StoreDurationFieldsMR.timerId.MC.change(intervalId)
    }),
  )
