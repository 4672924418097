import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'

// Constants

export const ClassName = 'join-content'

// ~~~~~~ Component

export const STJoinContent = styled.div`
  padding-left: ${Layout.Spacing[3]}px;
  padding-right: ${Layout.Spacing[3]}px;

  width: calc(100% - ${Layout.Spacing[3] * 2}px);
  height: 100%;

  color: ${theme(Colors.WOLF_100_05)};

  display: flex;
  justify-content: center;
  align-items: center;

  .${ClassName}--content {
    width: 400px;

    .${ClassName}--content--bcast {
      margin-bottom: 40px;

      svg {
        height: 20px;
        path {
          fill: ${theme(Colors.WOLF_100_05)};
        }
      }
    }

    .${ClassName}--content--errors {
      margin-bottom: 16px;
    }

    .${ClassName}--content--info-join {
      ${Fonts.Scale.Title}
      margin-bottom: 16px;

      max-width: 434px;
    }

    .${ClassName}--content--action {
      margin-bottom: 32px;
    }

    .${ClassName}--content--info-extra {
      text-align: center;

      .${ClassName}--content--info-extra--link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`
