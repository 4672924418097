import { Observable, of, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { SubscriptionModel } from '../../../models/bcast/subscription/model'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

const { Show } = ApiBcastRoutes.Subscriptions

// ####################################################################################################
// ~~~~~~ Subscription Show
// ####################################################################################################

type Res = DN.Models.Subscription.Api.Show.Res
type ResParsed = DNApiResParsed<DN.Models.Subscription.Api.Show.ResParsed>
type ResError = DNApiError<DN.Models.Subscription.Api.Show.ResError>

export const apiBcastSubscriptionShow$ = (token: string, headers?: AppHeaders) => {
  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersWithAuth(token),
    method: Show.Method,
    url: Show.getUrl(),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: {
          subscription: SubscriptionModel.fromBackToFront(res.response.subscription),
        },
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.current-user.subscription.Error' }],
      })
    }),
  )
}
