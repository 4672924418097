const prefix = 'mt-devices'

// ~~~~~~ Mutators Types

export const DevicesMT = {
  ADD_STREAM: `${prefix}-add-stream`,
  REM_STREAM: `${prefix}-rem-stream`,

  UPDATE_MODEL: `${prefix}-update-model`,

  CLEAR: `${prefix}-clear`,
} as const

// ~~~~~~ Mutators Creators

export const DevicesMC = {
  updateModel: (patch: Partial<DN.Models.Devices.Model>) => ({
    type: DevicesMT.UPDATE_MODEL,
    payload: {
      patch,
    },
  }),

  addStream: (stream: MediaStream, camDeviceId: string, micDeviceId: string) => ({
    type: DevicesMT.ADD_STREAM,
    payload: {
      stream,
      camDeviceId,
      micDeviceId,
    },
  }),

  remStream: (stream: MediaStream) => ({
    type: DevicesMT.REM_STREAM,
    payload: {
      stream,
    },
  }),

  clear: () => ({
    type: DevicesMT.CLEAR,
  }),
} as const
