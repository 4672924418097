const prefix = 'mt-subscription'

// ~~~~~~ Mutators Types

export const SubscriptionMT = {
  SET_TIME_REMAINING: `${prefix}-set-time-remaining`,
  SET_DAYS_LEFT: `${prefix}-set-days-left`,
} as const

// ~~~~~~ Mutators Creators

export const SubscriptionMC = {
  // TODO(Juanjo): This action could be removed when we delete usePreviewCoundown

  setTimeRemaining: (minutes: number, seconds = 0) => ({
    type: SubscriptionMT.SET_TIME_REMAINING,
    payload: {
      minutes,
      seconds,
    },
  }),

  setDaysLeft: (daysLeft: number) => ({
    type: SubscriptionMT.SET_DAYS_LEFT,
    payload: {
      daysLeft,
    },
  }),
} as const
