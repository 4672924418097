import styled from 'styled-components'

// ~~~~~~ Component

export const STJoinPage = styled.div`
  width: 100%;
  height: 100%;

  min-height: 467px;
`
