const prefix = 'mt-api-bcast-stream-context-create'

// ~~~~~~ Mutators Types

export const EpicApiBcastStreamContextCreateMT = {
  OK: `${prefix}-ok`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastStreamContextCreateMC = {
  ok: (data: DN.Models.StreamContext.Api.Create.ResParsed) => ({
    type: EpicApiBcastStreamContextCreateMT.OK,
    payload: data,
  }),
} as const
