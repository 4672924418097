import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../../../../../../../models/app/model'
import {
  PaintBcastStreamPicModelFieldsMR,
  PaintBcastStreamEditFieldsMR,
} from '../../../../../../../../../models/bcast/paint-bcast-stream/model'
import { UiLayoutPanelsFieldsMR } from '../../../../../../../../../models/bcast/ui-layout-panels/model'
import { BcastTrackEvents } from '../../../../../../../../../services/track-events'
import { useTrackStreamContext } from '../../../../../../../../../hooks/track-events/use-track-stream-context'

// ~~~~~~ Hook

export const useJoinedBehaviourLogicContentMainToolbarsMainButtonsCapturePaint = (
  video: HTMLVideoElement | null,
) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const streamContext = useTrackStreamContext()

  // ~~~~~~ State

  // - Local

  const [, setUpdate] = useState(0)

  // - Store

  const { originalVideoW, originalVideoH } = useSelector(
    (state: StoreState) => state.paintBcastStreamVideo,
  )

  // - Refs

  const tooltipRef = useRef<HTMLSpanElement>(null)

  // ~~~~~~ Computed

  const tooltipTop = tooltipRef.current ? -tooltipRef.current.clientHeight - 8 : -34

  // ~~~~~~ Handlers

  function takeScreenshot() {
    if (!video) return

    const canvas = document.createElement('canvas')

    canvas.width = originalVideoW
    canvas.height = originalVideoH

    const context2d = canvas.getContext('2d')

    if (!context2d) return

    context2d.imageSmoothingEnabled = false

    context2d.drawImage(video, 0, 0, originalVideoW, originalVideoH)

    dispatch(PaintBcastStreamPicModelFieldsMR.canvas.MC.change(canvas))

    return true
  }

  function onClickCapturePaint() {
    // Take screenshot

    if (!takeScreenshot()) return

    // Close share paint left panel

    dispatch(UiLayoutPanelsFieldsMR.leftPanelOpenState.MC.change('closed'))

    dispatch(UiLayoutPanelsFieldsMR.leftPanelShowSharePaints.MC.change(false))

    // Start painting

    dispatch(PaintBcastStreamEditFieldsMR.enabled.MC.change(true))

    // Track

    BcastTrackEvents.calls.PaintStart.viewer(streamContext)
  }

  // ~~~~~~ Effects

  // - Force update on mount to calculate tooltip position

  useEffect(() => {
    setUpdate(performance.now())
  }, [])

  // ~~~~~~

  return {
    tooltipRef,
    tooltipTop,

    onClickCapturePaint,
  } as const
}
