import { LazySVG } from '../../../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsFullscreen } from '../../../../../../../behaviour-logic/content/main/toolbars/main/buttons/fullscreen/hook'
import { ClassName, STJoinedTabletContentMainToolbarsMainButtonsFullscreen } from './style'

// ~~~~~~ Constants

const FullscreenIsOffIcon = LazySVG('icons/fullscreen')
const FullscreenIsOnIcon = LazySVG('icons/compress')

// ~~~~~~ Types

type Props = {
  onUpdate: () => void
}

// ~~~~~~ Component

export const JoinedTabletMainToolbarsMainButtonsFullscreen: React.FC<Props> = ({ onUpdate }) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsFullscreen(onUpdate)

  // ~~~~~~ Render

  return (
    <STJoinedTabletContentMainToolbarsMainButtonsFullscreen onClick={logic.onClick}>
      {/* Icon */}

      {logic.isInFullScreen() ? (
        <FullscreenIsOnIcon className={`${ClassName}--icon-on`} size={24} />
      ) : (
        <FullscreenIsOffIcon className={`${ClassName}--icon-off`} size={24} />
      )}

      {/* -- */}
    </STJoinedTabletContentMainToolbarsMainButtonsFullscreen>
  )
}
