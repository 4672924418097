import styled from 'styled-components'
import { Colors } from '../../style/theme/colors'
import { Fonts } from '../../style/theme/fonts'
import { Layout } from '../../style/theme/layout'
import { theme } from '../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'starting'

// ~~~~~~ Component

export const STStartingABroadcastPage = styled.div`
  background-color: ${theme(Colors.WOLF_00_100)};

  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .${ClassName}--center {
    height: 200px;
    max-width: 400px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .${ClassName}--center--logo {
      display: flex;
      justify-content: center;

      margin-bottom: ${Layout.Spacing[3]}px;

      svg {
        width: 64px;
      }
    }

    .${ClassName}--center--creating {
      ${Fonts.Scale.Title}
      color: ${theme(Colors.WOLF_100_05)};

      margin-bottom: ${Layout.Spacing[3]}px;
    }

    .${ClassName}--center--events {
      height: 20px;

      ${Fonts.Scale.Body};
      color: ${theme(Colors.WOLF_100_05)};
    }

    .${ClassName}--center--no-last-event {
      ${Fonts.Scale.Body};
      color: ${theme(Colors.WOLF_100_05)};

      margin-top: ${Layout.Spacing[3]}px;
    }
  }
`
