import { of, Observable, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { StoreProductsModel } from '../../../models/store/products/model'
import { ApiStoreRoutes } from '../constants/routes'
import { StoreHeaderUtils } from '../utils/headers'

const { Products } = ApiStoreRoutes

// ####################################################################################################
// ~~~~~~ List Products
// ####################################################################################################

type Res = DN.Models.StoreProducts.Api.List.Res

type Parsed =
  | DN.Models.StoreProducts.Api.List.ResParsedList
  | DN.Models.StoreProducts.Api.List.ResParsedBroadcastProducts

type ResParsed = DNApiResParsed<Parsed>
type ResError = DNApiError<DN.Models.StoreProducts.Api.List.ResError>

export const apiStoreProductsList$ = (
  productId: DN.Models.StoreProducts.ProductId,
  headers?: AppHeaders,
) => {
  return ajax<Res>({
    ...StoreHeaderUtils.genHeaders(headers),
    method: Products.List.Method,
    url: Products.List.getUrl(productId),
  }).pipe(
    map((res): ResParsed => {
      const broadcastProducts = res.response.reduce((acc, cur) => {
        // Free
        if (cur.order === 1) {
          return {
            ...acc,
            free: cur,
          }
        }

        // Premium
        if (cur.interval === 'month') {
          return {
            ...acc,
            premium: {
              ...acc.premium,
              month: cur,
            },
          }
        }

        if (cur.interval === 'year') {
          return {
            ...acc,
            premium: {
              ...acc.premium,
              year: cur,
            },
          }
        }

        return acc
      }, StoreProductsModel.initBcastModel.products)

      return {
        status: res.status,
        response: broadcastProducts,
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.products.Error' }],
      })
    }),
  )
}
