const Scale = {
  Headline1: 'font-size: 40px;line-height: 48px;',
  Headline2: 'font-size: 32px;line-height: 40px;',
  Title: 'font-size: 24px;line-height: 32px;',
  Subtitle: 'font-size: 18px;line-height: 24px;',
  BodyBig: 'font-size: 16px;line-height: 24px;',
  Body: 'font-size: 14px;line-height: 20px;',
  Label: 'font-size: 12px;line-height: 18px;',
  Helper: 'font-size: 10px;line-height: 16px;',
} as const

const Weight = {
  // 100
  '100': 'font-weight: 100;',
  // 300
  '300': 'font-weight: 300;',
  // 400 - Regular
  '400': 'font-weight: 400;',
  // 500
  '500': 'font-weight: 500;',
  // 700
  '700': 'font-weight: 700;',
  // 900
  '900': 'font-weight: 900;',
} as const

const Family = 'font-family: Gilroy, Arial, sans-serif;'
const MonoFamily = 'font-family: Roboto, monospace;'

// ~~~~~~

export const Fonts = {
  Scale,
  Weight,
  Family,
  MonoFamily,
} as const
