const initMetaListModel: DN.Models.GoogleClassRoom.MetaListModelFront = {
  uiStatus: 'init',
  errors: [],
  list: [],
  searchTerm: '',
  page: 0,
  perPage: 10,
  sortBy: 'name',
  sortDir: 'ASC',
}

const initAnnouncementModel: DN.Models.GoogleClassRoom.AnnouncementModelFront = {
  uiStatus: 'init',
  errors: [],
}

// ~~~~~~

export const GoogleClassRoomModel = {
  initMetaListModel,
  initAnnouncementModel,
} as const
