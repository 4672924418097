import { forwardRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { filter, fromEvent } from 'rxjs'
import { ConstEventsWindow } from '@dn/constants'
import { StoreState } from '../../../../models/app/model'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Trans } from '../../intl/trans'
import { STMenuExtensionSourceSelector, STProps } from './style'

// ~~~~~~ Constants

// Icons

const Icons = {
  light: {
    tab: LazySVG('icons/capture-tab-light'),
    app: LazySVG('icons/capture-app-light'),
    desktop: LazySVG('icons/capture-desktop-light'),
  },
  dark: {
    tab: LazySVG('icons/capture-tab-dark'),
    app: LazySVG('icons/capture-app-dark'),
    desktop: LazySVG('icons/capture-desktop-dark'),
  },
}

type Texts = {
  Title: IntlMsgId
  Tab: IntlMsgId
  App: IntlMsgId
  Desktop: IntlMsgId
}

type TextOpts = {
  Source: Texts
  Capture: Texts
}

const Text: TextOpts = {
  Source: {
    Title: 'extension.source-selector.Title',
    Tab: 'extension.source-selector.Tab',
    App: 'extension.source-selector.App',
    Desktop: 'extension.source-selector.Desktop',
  },
  Capture: {
    Title: 'extension.capture-selector.Title',
    Tab: 'extension.capture-selector.Tab',
    App: 'extension.capture-selector.App',
    Desktop: 'extension.capture-selector.Desktop',
  },
}

// ~~~~~~ Types

type Props = STProps & {
  className?: string
  showTitle?: true
  kind: 'Source' | 'Capture'

  onClick: (kind: CaptureStreamKind) => void
  onClickOut: () => void
}

// ~~~~~~ Component

export const MenuExtensionSourceSelector = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      showTitle,
      kind,

      onClick,
      onClickOut,

      // Style
      $forceDarkTheme: forceDarkTheme,
    },
    divRef,
  ) => {
    // ~~~~~~ State

    const { mode: themeMode } = useSelector((state: StoreState) => state.theme)

    // ~~~~~~ Computed

    const { tab: Tab, app: App, desktop: Desktop } = Icons[themeMode]

    const texts = Text[kind]

    // ~~~~~~ Effects

    // - Control click out

    useEffect(() => {
      const sub = fromEvent<MouseEvent>(window, ConstEventsWindow.Click)
        .pipe(
          filter((evt) => {
            const self = (divRef as any).current as HTMLDivElement | null

            if (!self || !evt.target) return false

            if (evt.target === self || self.contains(evt.target as any)) return false

            return true
          }),
        )
        .subscribe({
          next: () => {
            onClickOut()
          },
        })

      return () => {
        sub.unsubscribe()
      }
    }, [divRef, onClickOut])

    // ~~~~~~ Render

    return (
      <STMenuExtensionSourceSelector
        className={className}
        ref={divRef}
        $forceDarkTheme={forceDarkTheme}
      >
        {/* Title */}

        {showTitle ? (
          <div className="popup-menu-title">
            <Trans id={texts.Title} />
          </div>
        ) : undefined}

        {/* Tab */}

        <div className="popup-menu-line" onClick={() => onClick('tab')}>
          <div className="popup-menu-icon">
            <Tab size={24} />
          </div>

          <div className="popup-menu-text">
            <Trans id={texts.Tab} />
          </div>
        </div>

        {/* App */}

        <div className="popup-menu-line" onClick={() => onClick('app')}>
          <div className="popup-menu-icon">
            <App size={24} />
          </div>

          <div className="popup-menu-text">
            <Trans id={texts.App} />
          </div>
        </div>

        {/* Desktop */}

        <div className="popup-menu-line last" onClick={() => onClick('desktop')}>
          <div className="popup-menu-icon">
            <Desktop size={24} />
          </div>

          <div className="popup-menu-text">
            <Trans id={texts.Desktop} />
          </div>
        </div>
      </STMenuExtensionSourceSelector>
    )
  },
)
