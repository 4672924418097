import { STStartedHeader } from './style'
import { StartedHeaderTopLeft } from './top-left/component'
import { StartedHeaderTopRight } from './top-right/component'

// ~~~~~~ Component

export const StartedHeader = () => {
  // ~~~~~~ Render

  return (
    <STStartedHeader>
      {/* Left */}

      <div>
        <StartedHeaderTopLeft />
      </div>

      {/* Right */}

      <div>
        <StartedHeaderTopRight />
      </div>

      {/* - */}
    </STStartedHeader>
  )
}
