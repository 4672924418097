import { MediaQueryNumbers } from '../../../style/constants/media-queries'
import { UtilsStore } from '@dn/utils'
import { Config } from '../../../config'

const initModel: DN.Models.UiLayoutPanels.Model = {
  leftPanelOpenState: 'open',
  savedNotPaintingLeftPanelOpenState: 'open',
  savedPaintingLeftPanelOpenState: 'open',

  leftPanelDisplayValue: window.innerWidth <= MediaQueryNumbers.MD ? 'middle' : 'full',

  leftPanelShowSharePaints: false,

  // Sliders

  shareBcasLinkToClassroomState: 'closed',

  shareImageToClassroomState: 'closed',

  shareImageToSlidesState: 'closed',

  onLinkWithGoogleShouldOpen: 'slides',
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-ui-layout-panels'

export const UiLayoutPanelsFieldsMR = {
  leftPanelOpenState: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'leftPanelOpenState',
    initModel.leftPanelOpenState,
    Config.Intl.InfosForInfer,
  ),

  savedNotPaintingLeftPanelOpenState: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'savedNotPaintingLeftPanelOpenState',
    initModel.savedNotPaintingLeftPanelOpenState,
    Config.Intl.InfosForInfer,
  ),

  savedPaintingLeftPanelOpenState: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'savedPaintingLeftPanelOpenState',
    initModel.savedPaintingLeftPanelOpenState,
    Config.Intl.InfosForInfer,
  ),

  leftPanelDisplayValue: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'leftPanelDisplayValue',
    initModel.leftPanelDisplayValue,
    Config.Intl.InfosForInfer,
  ),

  leftPanelShowSharePaints: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'leftPanelShowSharePaints',
    initModel.leftPanelShowSharePaints,
    Config.Intl.InfosForInfer,
  ),

  shareBcasLinkToClassroomState: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'shareBcasLinkToClassroomState',
    initModel.shareBcasLinkToClassroomState,
    Config.Intl.InfosForInfer,
  ),

  shareImageToClassroomState: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'shareImageToClassroomState',
    initModel.shareImageToClassroomState,
    Config.Intl.InfosForInfer,
  ),

  shareImageToSlidesState: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'shareImageToSlidesState',
    initModel.shareImageToSlidesState,
    Config.Intl.InfosForInfer,
  ),

  onLinkWithGoogleShouldOpen: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'onLinkWithGoogleShouldOpen',
    initModel.onLinkWithGoogleShouldOpen,
    Config.Intl.InfosForInfer,
  ),
}

// ~~~~~~

export const LayoutPanelsModel = {
  initModel,
}
