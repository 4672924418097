import styled from 'styled-components'
import { Transition } from '../../../../style/animations/css-animations'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Colors } from '../../../../style/theme/colors'
import { HexAlpha } from '../../../../style/theme/colors/alpha'
import { Layout } from '../../../../style/theme/layout'
import { getThemeValue, theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'started-left-panel-share-paint'

const ImageWidth = 372
const ImageHeight = 232

const ImageWidthMiddle = 328
const ImageHeightMiddle = 204

// ~~~~~~ Component

export const STStartedLeftPanelSharePaint = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;

  .${ClassName}--content {
    .${ClassName}--content--screenshot {
      padding-bottom: ${Layout.Spacing[2]}px;

      .${ClassName}--content--image {
        position: relative;

        width: ${ImageWidth}px;
        height: ${ImageHeight}px;

        display: flex;
        justify-content: center;

        img {
          display: block;

          max-width: ${ImageWidth}px;
          max-height: ${ImageHeight}px;

          width: auto;
          height: auto;
          display: block;
        }

        .${ClassName}--content--image--actions {
          position: absolute;

          bottom: ${Layout.Spacing[2]}px;
          right: ${Layout.Spacing[2]}px;

          display: flex;

          .${ClassName}--content--image--actions--refresh {
            cursor: pointer;

            width: 40px;
            height: 40px;

            margin-right: ${Layout.Spacing[2]}px;

            border-radius: 2px;

            ${Transition.BackgroundColorFaster}

            background-color: ${(props) =>
              `${getThemeValue(props, Colors.WOLF_100_100)}${HexAlpha[40]}`};

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: ${(props) =>
                `${getThemeValue(props, Colors.WOLF_100_100)}${HexAlpha[50]}`};
            }

            &:active {
              background-color: ${(props) =>
                `${getThemeValue(props, Colors.WOLF_100_100)}${HexAlpha[40]}`};
            }

            svg {
              width: 20px;
              height: 20px;

              path {
                stroke: none;
                fill: ${theme(Colors.WOLF_00_05)};
              }
            }
          }

          .${ClassName}--content--image--actions--download {
            cursor: pointer;

            width: 40px;
            height: 40px;

            border-radius: 2px;

            ${Transition.BackgroundColorFaster}

            background-color: ${theme(Colors.BRONX_40_30)};

            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              width: 20px;
              height: 20px;

              path {
                stroke: none;
                fill: ${theme(Colors.WOLF_100_100)};
              }
            }

            &:hover {
              background-color: ${theme(Colors.BRONX_50_40)};
            }

            &:active {
              background-color: ${theme(Colors.BRONX_60_50)};
            }
          }
        }
      }
    }

    .${ClassName}--content--slides-card {
      padding-bottom: ${Layout.Spacing[2]}px;
    }

    .${ClassName}--content--classroom-card {
    }
  }

  .${ClassName}--exit {
    padding-top: ${Layout.Spacing[2]}px;
    width: 100%;
  }

  /* Reduced panel */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--content {
      .${ClassName}--content--screenshot {
        .${ClassName}--content--image {
          width: ${ImageWidthMiddle}px;
          height: ${ImageHeightMiddle}px;

          img {
            max-width: ${ImageWidthMiddle}px;
            max-height: ${ImageHeightMiddle}px;
          }
        }
      }
    }
  }
`
