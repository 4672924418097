import { Trans } from '../../../../../components/common/intl/trans'
import { LoaderBalls } from '../../../../../components/common/loader-balls/component'
import { ClassName, STStartedContentMainLoadingVideo } from './style'

// ~~~~~~ Component

export const StartedContentMainLoadingVideo = () => {
  // ~~~~~~ Render

  return (
    <STStartedContentMainLoadingVideo>
      <LoaderBalls />

      <div className={`${ClassName}--text`}>
        <Trans id="pages.joined.content.LoadingVideo" />
      </div>
    </STStartedContentMainLoadingVideo>
  )
}
