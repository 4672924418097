import styled from 'styled-components'
import { getThemeValue } from '../../../../../style/utils/theme'
import { PaintToolbarCommonColors, STPaintToolbarCommonStyle } from '../common-style'

// ~~~~~~ Types

type STProps = {
  $selected: boolean
  $disabled: boolean
}

// ~~~~~~ Component

export const STPaintToolbarButtonsPen = styled.div<STProps>`
  ${STPaintToolbarCommonStyle}

  svg path {
    fill: none;
    stroke: ${(props) =>
      props.$selected
        ? getThemeValue(props, PaintToolbarCommonColors.selected)
        : getThemeValue(props, PaintToolbarCommonColors.color)};
  }

  @media (hover: hover) {
    &:hover {
      svg path {
        fill: none;
        stroke: ${(props) => getThemeValue(props, PaintToolbarCommonColors.hover)};
      }
    }
  }
`
