const prefix = 'mt-bcast-start-sub-cam-mic-epic'

// ~~~~~~ Mutators Types

export const EpicBcastStartSubCamMicMT = {
  OK: `${prefix}-ok`,
  EVENT: `${prefix}-event`,
  ERROR: `${prefix}-error`,

  BANDWITH_STATE_INSUFFICIENT: `${prefix}-bandwidth-state-insufficient`,
  BANDWITH_STATE_RECOVERING: `${prefix}-bandwidth-state-recovering`,
} as const

// ~~~~~~ Mutators Types

export const EpicBcastStartSubCamMicMC = {
  ok: (sub: R5P.Subscription, broadcastId: string, username: string) => ({
    type: EpicBcastStartSubCamMicMT.OK,
    payload: {
      sub,
      broadcastId,
      username,
    },
  }),

  event: ({ type, data }: { type: string; data?: any }) => ({
    type: EpicBcastStartSubCamMicMT.EVENT,
    payload: {
      type: type as DN.Models.StreamManagerSub.LastEvent,
      data,
    },
  }),

  error: (errors: Infos[]) => ({
    type: EpicBcastStartSubCamMicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),

  bandwidthStateInsufficient: () => ({
    type: EpicBcastStartSubCamMicMT.BANDWITH_STATE_INSUFFICIENT,
  }),

  bandwidthStateRecovering: () => ({
    type: EpicBcastStartSubCamMicMT.BANDWITH_STATE_RECOVERING,
  }),
} as const
