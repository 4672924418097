import styled from 'styled-components'
import { Transition } from '../../../style/animations/css-animations'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'

// ~~~~~~ Helpers

const getFocussedBorderColor = (hasErrors: boolean) => {
  return hasErrors ? Colors.BROOKLYN_40_30 : Colors.WOLF_100_05
}

// ~~~~~~ Props

export type STProps = {
  $minHeight?: number
  $resize?: 'none' | 'both' | 'horizontal' | 'vertical'
  $disabled: boolean
  $hasErrors: boolean
}

export const STIntlArea = styled.div<STProps>`
  width: 100%;

  .label {
    ${Fonts.Scale.Body};
    ${Fonts.Weight[700]};

    padding-bottom: 12px;
    color: ${theme(Colors.WOLF_100_05)};
  }

  textarea {
    ${Fonts.Scale.BodyBig};
    ${Fonts.Weight[500]};

    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'default')};

    ${({ $minHeight }) => ($minHeight ? `min-height: ${$minHeight}px;` : '')}
    height: 41px;

    width: calc(100% - 36px);
    padding: 13px 16px 15px 16px;

    ${Layout.Border.Radius.A}

    resize: ${({ $resize }) => ($resize ? $resize : 'both')};

    opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

    /* Colors */

    background-color: transparent;

    color: ${theme(Colors.WOLF_50_30)};

    /* Border */

    border: solid ${Layout.Border.Size.Number.A}px;

    border-color: ${theme(Colors.WOLF_50_30)};

    ${Transition.BorderColorFaster};

    &:hover {
      border-color: ${theme(Colors.WOLF_40_40)};
    }

    &:focus {
      border-color: ${({ $hasErrors }) => theme(getFocussedBorderColor($hasErrors))};
    }

    &::placeholder {
      color: ${theme(Colors.WOLF_40_50)};
    }
  }
`
