const prefix = 'at-share-main-stream'

export const ShareMainStreamAT = {
  GET_STREAM: `${prefix}-get-stream`,
  GET_STREAM_TO_SWITCH: `${prefix}-get-stream-to-switch`,

  GET_STREAM_BY_EXTENSION: `${prefix}-get-stream-by-extension`,
  GET_STREAM_BY_EXTENSION_TO_SWITCH: `${prefix}-get-stream-by-extension-to-switch`,
} as const

export const ShareMainStreamAC = {
  getStream: () => ({
    type: ShareMainStreamAT.GET_STREAM,
  }),

  getStreamToSwitch: () => ({
    type: ShareMainStreamAT.GET_STREAM_TO_SWITCH,
  }),

  getStreamByExtension: (kind: CaptureStreamKind) => ({
    type: ShareMainStreamAT.GET_STREAM_BY_EXTENSION,
    payload: {
      kind,
    },
  }),

  getStreamByExtensionToSwitch: (kind: CaptureStreamKind) => ({
    type: ShareMainStreamAT.GET_STREAM_BY_EXTENSION_TO_SWITCH,
    payload: {
      kind,
    },
  }),
} as const
