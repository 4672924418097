import { Observable } from 'rxjs'
import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'
import { BroadcastServiceConst } from '../constants/broadcast-partial-service'
import { genTakeUntilStop$ } from '../subject/stop/take-until/broadcast-partial-service'

export const startPub$ = (pub: R5P.Publisher, id: string) => {
  Config.Logs.BroadcastService &&
    UtilsLog.devLog('BCastService', 'startPub$', '\n- id\n', id, '\n- publisher\n', pub)

  const obs$ = new Observable((observer) => {
    pub
      .publish()
      .then(() => {
        observer.next()
        observer.complete()
      })
      .catch((err) => {
        UtilsLog.devLog('BCastService', 'startPub$', '\n- ERROR\n', err)

        if (typeof err !== 'string') {
          observer.error(BroadcastServiceConst.ErrorCodes.PublishUnknown)
          return
        }

        let errorCode = undefined

        switch (true) {
          // Session publish disallowed for 502572
          case !!~err.search('Session publish disallowed for'):
            //
            errorCode = BroadcastServiceConst.ErrorCodes.PublishDisalllowed

            break

          // (RTCPublisher) Error in stream publish: Session for 944715 was not found.
          case !!~err.search('Error in stream publish') && !!~err.search('was not found'):
            //
            errorCode = BroadcastServiceConst.ErrorCodes.PublishNotFound

            break
        }

        observer.error(errorCode)
      })
  })

  return obs$.pipe(genTakeUntilStop$(id))
}
