import { UtilsStore } from '@dn/utils'

const initModel: DN.Models.ImageToShare.Model = {
  file: '',
  name: '',

  reqUpdate: false,
}

// ~~~~~~ Edit

const prefix = 'mt-image-to-share'

// ~~~~~~ Fields Mutators/Reducers

export const ImageToShareFieldsMR = {
  __model__: UtilsStore.genModelMR(prefix, initModel),

  file: UtilsStore.genBasicFieldMR(prefix, initModel, 'file', initModel.file),

  name: UtilsStore.genBasicFieldMR(prefix, initModel, 'name', initModel.name),

  reqUpdate: UtilsStore.genBasicFieldMR(prefix, initModel, 'reqUpdate', initModel.reqUpdate),
}

// ~~~~~~

export const ImageToShareModel = {
  initModel,
} as const
