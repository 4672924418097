import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, tap } from 'rxjs'
import { CountdownService } from '@dn/service-countdown'
import { StoreState } from '../../../../../models/app/model'
import { apiStoreSubscriptionsUpgradePlan$ } from '../../../../../services/api-store/subscriptions/upgrade-plan'
import { ExtensionService } from '../../../../../services/extension'
import {
  ApiStoreSubscriptionsAC,
  ApiStoreSubscriptionsAT,
} from '../../../../actions/api-store/subscriptions/actions'
import { ApiUtils } from '../../../utils'
import { ApiSubscriptionsUpgradePlanEpicMC } from './mutators'

type Action = ReturnType<typeof ApiStoreSubscriptionsAC.upgradePlan>

export const apiStoreSubscritionsUpgradePlanEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreSubscriptionsAT.UPGRADE_PLAN),

    mergeMap(({ payload }) =>
      apiStoreSubscriptionsUpgradePlan$(
        state$.value.currentUser.token,
        state$.value.subscription.id,
        payload,
      ),
    ),

    // Extension

    tap((res) => {
      if (!ApiUtils.isSuccess(res)) return

      ExtensionService.SendMessage.ToExtension.updateInfo()
    }),

    // Countdown

    tap((res) => {
      if (!ApiUtils.isSuccess(res)) return

      CountdownService.Stop.all()
    }),

    map((res) =>
      ApiUtils.isSuccess(res)
        ? ApiSubscriptionsUpgradePlanEpicMC.ok(res.response)
        : ApiSubscriptionsUpgradePlanEpicMC.error(res.response),
    ),
  )
