const prefix = 'mt-server-sub-cam-mic-epic'

// ~~~~~~ Mutators Types

export const EpicApiServerSubCamMicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiServerSubCamMicMC = {
  ok: (server: DN.Models.StreamManagerServer.Api.Get.ResParsed) => ({
    type: EpicApiServerSubCamMicMT.OK,
    payload: {
      server,
    },
  }),

  error: (errors: DN.Models.StreamManagerServer.Api.Get.ResError) => ({
    type: EpicApiServerSubCamMicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
