import { useSelector } from 'react-redux'
import { StoreState } from '../../models/app/model'
import { BcastTrackEvents } from '../../services/track-events'
import { useMemo } from 'react'

export const useTrackScreenStreamContext = (kind: 'pub' | 'sub') => {
  // ~~~~~~ State

  const screenContextId = useSelector((state: StoreState) => state.dnBcast.bcastUuid)

  // ~~~~~~ Computed

  const streamContext = useMemo(() => {
    return BcastTrackEvents.helpers.genStreamContext(screenContextId, kind)
  }, [kind, screenContextId])

  // ~~~~~~

  return streamContext
}
