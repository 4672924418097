import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DialogBreakPoint } from '../../../../constants/breakpoints'
import { ModalsIds } from '../../../../constants/modals'
import { useValidator } from '../../../../hooks/validators/use-validators'
import { StoreState } from '../../../../models/app/model'
import { StorePlanFieldsMR, StorePlanModel } from '../../../../models/store/plan/model'
import { ModalsMC } from '../../../../store/actions-mutators/modals/mutators'
import { ApiStoreCheckoutAC } from '../../../../store/actions/api-store/checkout/actions'
import { Button } from '../../../common/button/component'
import { InfoBox } from '../../../common/infos/box/component'
import { IntlInput } from '../../../common/intl-input/component'
import { Trans } from '../../../common/intl/trans'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Actions } from '../../compound-dialog/actions/component'
import { Content } from '../../compound-dialog/content/component'
import { genAnimatedDialog } from '../../compound-dialog/dialog/component'
import { STDialogsSubscriptionsCreateAnAccount } from './style'
import { BcastTrackEvents } from '../../../../services/track-events'

// ~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.Step1EmailPass)

const EmailIcon = LazySVG('icons/mail')
const PassIcon = LazySVG('icons/lock')

const ErrorUserExists: IntlMsgId = 'api.checkout.send-email-pass.Error412'

// ~~~~~ Component

export const DialogsSubscriptionsStep1EmailPass = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const validateEmail = useValidator(StorePlanModel.validations.Email, StorePlanFieldsMR.email)

  const validatePass = useValidator(StorePlanModel.validations.Pass, StorePlanFieldsMR.pass)

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const [isDirty, setIsDirty] = useState(false)

  const { kind, errors, email, email_err, pass, pass_err, uiStatusEmailPass, step } = useSelector(
    (state: StoreState) => state.storePlan,
  )

  // ~~~~~~ Computed

  const isRunning = uiStatusEmailPass === 'running'

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())

    dispatch(StorePlanFieldsMR.reset.MC.reset())

    dispatch(ApiStoreCheckoutAC.cancel())
  }

  function onChangeEmail(value: string) {
    isDirty && validateEmail(value)

    dispatch(StorePlanFieldsMR.email.MC.change(value))
  }

  function onChangePass(value: string) {
    isDirty && validatePass(value)

    dispatch(StorePlanFieldsMR.pass.MC.change(value))
  }

  function submit() {
    setIsDirty(true)

    // Validate

    const errors = [validateEmail(email).length, validatePass(pass).length]

    if (errors.find(Boolean)) return

    // Clean dirty

    setIsDirty(false)

    // Submit

    dispatch(
      ApiStoreCheckoutAC.sendEmailPass({
        email: email,
        password: pass,
      }),
    )

    // Track

    kind === 'free'
      ? BcastTrackEvents.calls.BuyProcess.Free['2-create-account']()
      : BcastTrackEvents.calls.BuyProcess.Premium['2-create-account']()
  }

  // ~~~~~~ Effects

  // - Check step to go to the next modal

  useEffect(() => {
    if (doClose > 0 || step !== 2) return

    dispatch(ModalsMC.open(ModalsIds.Step2VerificationCode))

    setDoClose(performance.now())

    //
  }, [dispatch, doClose, step])

  // - Check error to move to end if is an exisiting user

  useEffect(() => {
    if (doClose > 0 || !errors.some((error) => error.id === ErrorUserExists)) return

    dispatch(StorePlanFieldsMR.pass.MC.clear())

    dispatch(ModalsMC.open(ModalsIds.ConfirmUserAlreadyExists))

    setDoClose(performance.now())

    //
  }, [errors, dispatch, doClose])

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      modalSize={DialogBreakPoint.S}
      closeOnClickOut={false}
      dialogTitle="dialogs.email-pass.Title"
      dialogTitleAlign="left"
      dialogSubtitle="common.StepOf"
      dialogSubtitleValues={{ step: 1, total: kind === 'free' ? 2 : 3 }}
      closeDialog={doClose}
    >
      <Content>
        <STDialogsSubscriptionsCreateAnAccount>
          <div className="line">
            <Trans id="dialogs.email-pass.Subtitle" />
          </div>

          {errors && errors.length ? (
            <InfoBox withIcon={true} $boxType="ERROR" infos={errors} />
          ) : undefined}

          <form onSubmit={submit}>
            {/* Email */}

            <div className="line">
              <IntlInput
                Icon={EmailIcon}
                placeholder="dialogs.email-pass.form.email.Placeholder"
                $disabled={isRunning}
                value={email}
                errors={email_err}
                onChange={onChangeEmail}
                showErrorsInBox
              />
            </div>

            {/* Pass */}

            <div className="line last">
              <IntlInput
                Icon={PassIcon}
                $iconWidth={16}
                placeholder="dialogs.email-pass.form.pass.Placeholder"
                type="password"
                $disabled={isRunning}
                value={pass}
                errors={pass_err}
                onChange={onChangePass}
                showErrorsInBox
              />
            </div>
          </form>
        </STDialogsSubscriptionsCreateAnAccount>
      </Content>

      <Actions>
        {/* Close */}

        <Button
          data-testid="negative-act"
          intlId="common.Cancel"
          $colorType="secondary"
          disabled={false}
          $active={true}
          $running={false}
          onClick={onClickClose}
        />

        {/* Continue */}

        <Button
          data-testid="positive-act"
          intlId="common.Continue"
          $colorType="primary"
          disabled={isRunning}
          $active={!isRunning}
          $running={isRunning}
          onClick={submit}
        />
      </Actions>
    </Dialog>
  )
}
