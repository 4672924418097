import { useJoinedBehaviourLogicContentMain } from '../../../behaviour-logic/content/main/hook'
import { JoinedTabletContentMainCapturePaint } from '../../../tablet/content/main/capture-paint/component'
import { JoinedTabletContentMainLoadingVideo } from '../../../tablet/content/main/loading-video/component'
import { ClassName, STJoinedMobileContentMain } from './style'

// ~~~~~~ Component

export const JoinedMobileContentMain = () => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMain()

  // ~~~~~~ Render

  return (
    <STJoinedMobileContentMain ref={logic.selfRef}>
      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Video wrapper */}

        <div className={`${ClassName}--content--video-wrapper`} ref={logic.videoWrapperRef}>
          {/* Paint bcast stream */}

          {logic.paintBcastStreamEnabled ? <JoinedTabletContentMainCapturePaint /> : undefined}

          {/* Loading video... */}

          {!logic.videoIsPlaying ? <JoinedTabletContentMainLoadingVideo /> : undefined}

          {/* Video */}

          <video
            ref={logic.videoRef}
            className={`${ClassName}--content--video-wrapper--video ${
              logic.hasZoom ? 'with-zoom' : ''
            }`}
            autoPlay
            playsInline
            muted
            style={{
              zIndex: 0,
              top: 0,
              left: 0,

              position: logic.hasZoom ? 'absolute' : 'initial',

              // 100%, 120%, 140%...
              width: logic.zoomWidthAndHeight,
              height: logic.zoomWidthAndHeight,
            }}
          />
        </div>

        {/* -- */}
      </div>

      {/* -- */}
    </STJoinedMobileContentMain>
  )
}
