import React from 'react'
import { LayoutPanelsContext } from '../context/component'

// ~~~~~~ Hook

export const useLayoutPanelsContext = () => {
  const context = React.useContext(LayoutPanelsContext)

  if (!context) {
    throw new Error(`Layout cupV2 components cannot be rendered outside the LayoutCup component`)
  }

  return context
}
