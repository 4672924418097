import styled from 'styled-components'
import { STMenuCommonStyle, STProps as STCommonProps } from '../common-style/style'

// ~~~~~ Types

export type STProps = STCommonProps

// ~~~~~ Component

export const STMenuExtensionSourceSelector = styled.div<STProps>`
  ${STMenuCommonStyle}
`
