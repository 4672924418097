const prefix = 'mt-api-bcast-current-user-show-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastCurrentUserShowMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastCurrentUserShowMC = {
  ok: (data: DN.Models.CurrentUser.Api.Show.ResParsed) => ({
    type: EpicApiBcastCurrentUserShowMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.CurrentUser.Api.Show.ResError) => ({
    type: EpicApiBcastCurrentUserShowMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
