import { BronxColors } from './colors/bronx'
import { BrooklynColors } from './colors/brooklyn'
import { SitoColors } from './colors/sito'
import { LisaColors } from './colors/lisa'
import { WolfColors } from './colors/wolf'
import { EmmetColors } from './colors/emmet'

export const Colors = {
  ...BronxColors,
  ...WolfColors,
  ...BrooklynColors,
  ...SitoColors,
  ...LisaColors,
  ...EmmetColors,
} as const
