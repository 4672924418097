const prefix = 'mt-subscriptions-get-time-epic'

export const EpicApiSubscriptionsGetTimeMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

export const EpicApiSubscriptionsGetTimeMC = {
  ok: (data: DN.Models.Subscription.Api.GetTime.ResParsed) => ({
    type: EpicApiSubscriptionsGetTimeMT.OK,
    payload: data,
  }),

  error: (status: number, errors: DN.Models.Subscription.Api.GetTime.ResError) => ({
    type: EpicApiSubscriptionsGetTimeMT.ERROR,
    error: true,
    payload: {
      status,
      errors,
    },
  }),
} as const
