import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, takeUntil } from 'rxjs'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreCheckoutValidateEmailCode$ } from '../../../../../services/api-store/checkout/validate-email-code'

import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'

import { ApiUtils } from '../../../utils'
import { ApiCheckout$$ } from '../subject/subject'
import { ApiCheckoutValidateEmailCodeEpicMC } from './mutators'

type Action = ReturnType<typeof ApiStoreCheckoutAC.validateEmailCode>

export const apiStoreCheckoutValidateEmailCodeEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.VALIDATE_EMAIL_CODE),

    mergeMap(({ payload }) =>
      apiStoreCheckoutValidateEmailCode$(payload).pipe(takeUntil(ApiCheckout$$)),
    ),

    map((res) =>
      ApiUtils.isSuccess(res)
        ? ApiCheckoutValidateEmailCodeEpicMC.ok(res.response)
        : ApiCheckoutValidateEmailCodeEpicMC.error(res.response),
    ),
  )
