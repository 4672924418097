import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { useCamMicSubSound } from '../../../../../../hooks/cam-mic/sub/use-cam-mic-sub-speakers-are-enabled'
import { StreamManagerSubCamMicFieldsMR } from '../../../../../../models/stream-manager/sub/cam-mic/model'
import { LazySVG } from '../../../../../svgs/lazy-svg/component'
import { ClassName, STCamMicMicSoundMobileButtonSwitcher } from './style'

// ~~~~~~ Constants

const SpeakersOn = LazySVG('icons/speakers-on')
const SpeakersOff = LazySVG('icons/speakers-off')

// ~~~~~~ Types

type Props = {
  className?: string
}

// ~~~~~~ Component

export const CamMicMicSoundMobileButtonSwitcher: React.FC<Props> = ({ className }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const sound = useCamMicSubSound()

  // ~~~~~~ Handlers

  function onToggleSpeaker() {
    dispatch(StreamManagerSubCamMicFieldsMR.iMutedAudio.MC.change(sound.speakersAreEnabled))
  }

  // ~~~~~~ Render

  if (sound.isVirgin) return null

  return (
    <STCamMicMicSoundMobileButtonSwitcher className={className}>
      <AnimatePresence>
        {!sound.isVirgin ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              type: 'easeIn',
              duration: 0.5,
            }}
            className={`${ClassName}--content`}
            onClick={onToggleSpeaker}
          >
            {sound.speakersAreEnabled ? <SpeakersOn size={20} /> : <SpeakersOff size={20} />}
          </motion.div>
        ) : undefined}
      </AnimatePresence>
    </STCamMicMicSoundMobileButtonSwitcher>
  )
}
