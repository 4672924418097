import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'dialog-subscription-verification-code'

// ~~~~~~ Component

export const STDialogsSubscriptionsVerificationCode = styled.div`
  .${ClassName}--line {
    text-align: left;

    margin-bottom: ${Layout.Spacing[3]}px;

    svg path {
      fill: ${theme(Colors.WOLF_100_05)};
    }
  }

  .${ClassName}--info-box {
    margin-bottom: ${Layout.Spacing[3]}px;
  }
`
