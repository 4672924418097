const MainSpace = {
  Up768: '16px',
  Down768: '8px',
}

const Spacing = {
  1: 4,
  2: 8,
  3: 16,
  4: 24,
  5: 32,
  6: 40,
  7: 48,
  8: 56,
  9: 64,
  10: 72,
  11: 80,
  12: 94,
} as const

const Border = {
  Size: {
    A: 'border-width: 1px;',
    B: 'border-width: 2px;',
    C: 'border-width: 4px;',
    D: 'border-width: 8px;',

    Number: {
      A: 1,
      B: 2,
      C: 4,
      D: 8,
    },
  },
  Radius: {
    A: 'border-radius: 4px;',
    B: 'border-radius: 8px;',
    C: 'border-radius: 12px;',
    D: 'border-radius: 16px;',
    E: 'border-radius: 80px;',
  },
} as const

const Widths = {
  Mobile: '420px',
}

const Breakpoints = {
  XSmall: {
    Value: '320px',
  },
  Small: {
    Value: '576px',
  },
  Medium: {
    Value: '768px',
  },
  Large: {
    Value: '992px',
  },
  XLarge: {
    Value: '1200px',
  },
  XXLarge: {
    Value: '1440px',
  },
}

export const Layout = {
  MainSpace,
  Widths,
  Spacing,
  Border,
  Breakpoints,
} as const
