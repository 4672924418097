import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, filter, mergeMap, Observable, of } from 'rxjs'
import { Config } from '../../../../../../config'
import { StoreState } from '../../../../../../models/app/model'
import { UtilsCanvasToStream } from '@dn/utils'
import { LivePaintDrawAC, LivePaintDrawAT } from '../../../../../actions/live-paint/draw/actions'
import { EpicLivePaintReplaceTrackOnStartDrawMC } from '../mutators'

type Action = ReturnType<typeof LivePaintDrawAC.start>

export const deprecatedLivePaintReplaceTrackOnDrawStartEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(LivePaintDrawAT.START),

    filter(
      () =>
        !Config.Features.BcastService2 ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v1'),
    ),

    mergeMap(({ payload }) => {
      const state = state$.value
      const canvas = payload.canvas

      if (!state.livePaintVideo.video) return EMPTY

      return of(
        UtilsCanvasToStream.startWithVideoPlaying$(
          state.livePaintVideo.video,
          state.subscription.fps,
          canvas,
        ),
      )
    }),

    mergeMap(({ track, stop }) => {
      if (Config.isExtension) return EMPTY

      const state = state$.value

      const publisher = state.streamManagerPubMain.publisher

      if (!publisher) return EMPTY

      const { video } = state.livePaintVideo

      if (!video) return EMPTY

      const peerConn = publisher.getPeerConnection()

      const { curScreenVideoTrack } = state.sharedMainStream

      if (!curScreenVideoTrack) return EMPTY

      const transceiver = peerConn.getTransceivers()[0]

      const obs$ = new Observable((observer) => {
        transceiver.sender.replaceTrack(track).then(() => {
          //

          // --))-|
          observer.next(EpicLivePaintReplaceTrackOnStartDrawMC.ok(track, stop))
          observer.complete()
        })
      })

      return obs$
    }),
  )
