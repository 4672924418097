import styled from 'styled-components'
import { Colors } from '../../style/theme/colors'
import { Fonts } from '../../style/theme/fonts'
import { Layout } from '../../style/theme/layout'
import { theme } from '../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'page-sign-in'

// ~~~~~~ Main

export const STSignInPage = styled.div`
  height: 100%;
`

// ~~~~~~ Top Left

export const STSignInTopLeft = styled.div`
  display: flex;
  align-items: center;

  .${ClassName}--sign-in-top-left-back {
    cursor: pointer;

    svg {
      height: 20px;

      path {
        fill: ${theme(Colors.WOLF_100_05)};
      }
    }
  }
`

// ~~~~~~ Content

export const STSignInContent = styled.div`
  padding-left: ${Layout.Spacing[3]}px;
  padding-right: ${Layout.Spacing[3]}px;

  width: calc(100% - ${Layout.Spacing[3] * 2}px);
  height: 100%;

  color: ${theme(Colors.WOLF_100_05)};

  display: flex;
  justify-content: center;
  align-items: center;

  .${ClassName}--sign-in-content {
    .${ClassName}--sign-in-content--sign-in-bcast {
      margin-bottom: 40px;

      svg {
        height: 20px;
        path {
          fill: ${theme(Colors.WOLF_100_05)};
        }
      }
    }

    .${ClassName}--sign-in-content--sign-in-info {
      ${Fonts.Scale.Title}
      margin-bottom: 16px;

      max-width: 434px;
    }

    .${ClassName}--sign-in-content--device-not-supported {
      margin-bottom: 16px;
    }

    .${ClassName}--sign-in-content--sign-in-action {
      margin-bottom: 8px;
    }
  }
`
