import { catchError, map, Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../../constants/routes'
import { BcastHeaderUtils } from '../../utils/headers'

const { GetGoogle } = ApiBcastRoutes.Providers

// ####################################################################################################
// ~~~~~~ Get Google scopes
// ####################################################################################################

type Res = DN.Models.Provider.Api.GetGoogle.Res
type ResParsed = DNApiResParsed<DN.Models.Provider.Api.GetGoogle.ResParsed>
type ResError = DNApiError<DN.Models.Provider.Api.GetGoogle.ResError>

export const apiBcastProvidersGoogleGet$ = (
  token: string,
  userId: string,
  headers?: AppHeaders,
) => {
  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersWithAuth(token, headers),
    method: GetGoogle.Method,
    url: GetGoogle.getUrl(userId),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.providers.get-google.Error' }],
      })
    }),
  )
}
