import { ofType, StateObservable } from 'redux-observable'
import { Observable, map, mergeMap, tap } from 'rxjs'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreSubscriptionsCreate$ } from '../../../../../services/api-store/subscriptions/create'
import {
  ApiStoreSubscriptionsAC,
  ApiStoreSubscriptionsAT,
} from '../../../../actions/api-store/subscriptions/actions'
import { ApiUtils } from '../../../utils'
import { ApiSubscriptionsCreateEpicMC } from './mutators'
import { BcastTrackEvents } from '../../../../../services/track-events'

type Action = ReturnType<typeof ApiStoreSubscriptionsAC.create>

export const apiStoreSubscritionsCreateEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreSubscriptionsAT.CREATE),

    mergeMap(({ payload }) => apiStoreSubscriptionsCreate$(payload)),

    // Track

    tap((res) => {
      if (!ApiUtils.isSuccess(res)) return

      // Track

      BcastTrackEvents.calls.BuyProcess.Free['4-create-success']()
    }),

    map((res) =>
      ApiUtils.isSuccess(res)
        ? ApiSubscriptionsCreateEpicMC.ok(res.response)
        : ApiSubscriptionsCreateEpicMC.error(res.response),
    ),
  )
