import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, map, mergeMap, Observable } from 'rxjs'
import { StoreState } from '../../../../models/app/model'
import { apiBcastProvidersGoogleGet$ } from '../../../../services/api-bcast/providers/google/get'
import {
  EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC,
  EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT,
} from '../../api-bcast/google-classroom/announce-bcast-link/mutators'
import {
  EpicApiBcastGoogleClassRoomAnnounceCaptureMC,
  EpicApiBcastGoogleClassRoomAnnounceCaptureMT,
} from '../../api-bcast/google-classroom/announce-capture/mutators'
import {
  EpicApiBcastGoogleClassRoomListMC,
  EpicApiBcastGoogleClassRoomListMT,
} from '../../api-bcast/google-classroom/list/mutators'
import {
  EpicApiBcastGoogleSlidesAddImageSlideMC,
  EpicApiBcastGoogleSlidesAddImageSlideMT,
} from '../../api-bcast/google-slides/add-image-slide/mutators'
import {
  EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC,
  EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT,
} from '../../api-bcast/google-slides/create-presentation-and-add-image-slide/mutators'
import {
  EpicApiBcastGoogleSlidesCreatePresentationMC,
  EpicApiBcastGoogleSlidesCreatePresentationMT,
} from '../../api-bcast/google-slides/create-presentation/mutators'
import {
  EpicApiBcastGoogleSlidesListPresentationsMC,
  EpicApiBcastGoogleSlidesListPresentationsMT,
} from '../../api-bcast/google-slides/list-presentations/mutators'
import { ApiUtils } from '../../utils'
import { EpicResyncGoogleMC } from './mutators'

type Action =
  // Classroom
  | ReturnType<typeof EpicApiBcastGoogleClassRoomAnnounceBcastLinkMC.error>
  | ReturnType<typeof EpicApiBcastGoogleClassRoomAnnounceCaptureMC.error>
  | ReturnType<typeof EpicApiBcastGoogleClassRoomListMC.error>
  // Slides
  | ReturnType<typeof EpicApiBcastGoogleSlidesAddImageSlideMC.error>
  | ReturnType<typeof EpicApiBcastGoogleSlidesCreatePresentationMC.error>
  | ReturnType<typeof EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMC.error>
  | ReturnType<typeof EpicApiBcastGoogleSlidesListPresentationsMC.error>

export const resyncGoogleEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      // Classroom
      EpicApiBcastGoogleClassRoomAnnounceBcastLinkMT.ERROR,
      EpicApiBcastGoogleClassRoomAnnounceCaptureMT.ERROR,
      EpicApiBcastGoogleClassRoomListMT.ERROR,
      // Slides
      EpicApiBcastGoogleSlidesAddImageSlideMT.ERROR,
      EpicApiBcastGoogleSlidesCreatePresentationMT.ERROR,
      EpicApiBcastGoogleSlidesCreatePresentationAndAddImageSlideMT.ERROR,
      EpicApiBcastGoogleSlidesListPresentationsMT.ERROR,
    ),
    mergeMap(({ payload }) => {
      if (!payload.unsync) return EMPTY

      const { token, id } = state$.value.currentUser

      return apiBcastProvidersGoogleGet$(token, id)
    }),
    map((res) => {
      if (!ApiUtils.isSuccess(res)) return EMPTY

      return EpicResyncGoogleMC.ok(res.response)
    }),
  )
