import { useSelector } from 'react-redux'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../../models/app/model'
import { STStartedHeaderTopRightViewers } from './style'
import { Config } from '../../../../../config'

// ~~~~~~ Constants

const UsersIcon = LazySVG('icons/users-group')

// ~~~~~~ Component

export const StartedHeaderTopRightViewers = () => {
  // ~~~~~~ State

  const viewersQtty = useSelector((state: StoreState) =>
    Config.Features.BcastService2 ? state.dnBcast.participantQtty : state.viewers.quantity,
  )

  // ~~~~~~ Render

  return (
    <STStartedHeaderTopRightViewers>
      {/* Icon */}

      <div className="started-top-right-viewers-icon">
        <UsersIcon size={20} />
      </div>

      {/* Quantity */}

      <div className="started-top-right-viewers-quantity">{viewersQtty}</div>

      {/* - */}
    </STStartedHeaderTopRightViewers>
  )
}
