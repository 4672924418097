import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { DialogBreakPoint } from '../../../constants/breakpoints'
import { ModalsIds } from '../../../constants/modals'
import { Button } from '../../common/button/component'
import { Actions } from '../compound-dialog/actions/component'
import { Content } from '../compound-dialog/content/component'
import { genAnimatedDialog } from '../compound-dialog/dialog/component'
import { Trans } from '../../common/intl/trans'
import { StoreState } from '../../../models/app/model'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ViewHasEnded)

// ~~~~~~ Component

export const ViewHasEndedDialog = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ State

  const [closeDialog, setCloseDialog] = useState(0)

  const { redirectPath } = useSelector((state: StoreState) => state.root)

  // ~~~~~~ Handlers

  function onClickOk() {
    setCloseDialog(performance.now())
  }

  function onWillBeClosed() {
    navigate(redirectPath, { replace: true })
  }

  // ~~~~~~ Render

  return (
    <Dialog
      stackedBtns={true}
      predefIcon="info"
      modalSize={DialogBreakPoint.XS}
      closeOnClickOut={true}
      dialogTitle="dialogs.show-ended.Title"
      closeDialog={closeDialog}
      onWillBeClosed={onWillBeClosed}
    >
      {/* Content */}

      <Content>
        <Trans id="dialogs.show-ended.Content" />
      </Content>

      {/* Actions */}

      <Actions>
        <Button
          intlId="common.OK"
          $colorType="primary"
          $running={false}
          disabled={false}
          $active={true}
          onClick={onClickOk}
        />
      </Actions>
    </Dialog>
  )
}
