import { catchError, map, Observable, of, timeout } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiStunTurnRoutes } from '../../constants/routes'
import { StunTurnHeaderUtils } from '../../utils/headers'

type Res = DN.Models.StunTurnCredentials.Api.Get.Res
type ResParsed = DNApiResParsed<DN.Models.StunTurnCredentials.Api.Get.ResParsed>
// type ResError = DNApiError<DN.Models.StunTurnCredentials.Api.Get.ResError>

const { Get } = ApiStunTurnRoutes.Credentials

// @param token: subscription.token

export const apiStunTurnGetCredentials$ = () => {
  return ajax<Res>({
    ...StunTurnHeaderUtils.genGetHeaders(),
    method: Get.Method,
    url: Get.getUrl(),
  }).pipe(
    timeout(Get.Timeout),

    map((res): ResParsed => {
      return {
        status: res.status,

        response: {
          ...res.response,

          // response.ttl is time-to-live in seconds
          // Expiration = ttl in milliseconds + current date

          expiration: res.response.ttl * 1000 + Date.now(),
        },
      }
    }),

    catchError((_res: DNApiRes<void>): Observable<ResParsed> => {
      return of({
        status: 200,

        response: {
          username: '',
          password: '',
          ttl: 0,
          uris: Get.DefaultStunTurnServers,

          expiration: Date.now(),
        },
      })
    }),
  )
}
