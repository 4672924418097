import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import {
  EpicBcastCommChannelInitMC,
  EpicBcastCommChannelInitMT,
} from '../../../epics/comm-channel/init/mutators'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'

const initState = AppInitState.commChannel

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Comm channel init (init from viewer)
// ####################################################################################################

reducer[EpicBcastCommChannelInitMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastCommChannelInitMC.ok>,
): State => {
  return {
    ...state,
    webSocket: payload.webSocket,
  }
}

// ####################################################################################################
// ~~~~~~ Comm channel init with server pub data (init from bcaster)
// ####################################################################################################

reducer[EpicBcastCommChannelInitMT.OK_WITH_SERVER_PUB] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastCommChannelInitMC.okWithServerPub>,
): State => {
  return {
    ...state,
    webSocket: payload.webSocket,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel now all - DONE
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return initState
}

// ~~~~~~

export const commChannelReducer = UtilsStore.dynReducer(initState, reducer)
