import { UserSettings } from '../../components/common/user-settings/component'
import { DialogGoogleSignIn } from '../../components/dialogs/google/sign-in/component'
import { LayoutTopContent } from '../../components/layouts/top-content/component'
import { LayoutTopContentContent } from '../../components/layouts/top-content/parts/content/component'
import { LayoutTopContentTopLeft } from '../../components/layouts/top-content/parts/top/left/component'
import { LayoutTopContentTopRight } from '../../components/layouts/top-content/parts/top/right/component'
import { UserSettingsTopLeft } from './top-left/component'
import { UserSettingsTopRight } from './top-right/component'

// ~~~~~~ Component

export const UserSettingsPage = () => {
  // ~~~~~~ Render

  return (
    <>
      {/* Dialogs */}

      {/* - Dialog: Link with google (not checkning modal list to avoid repaint) */}

      <DialogGoogleSignIn />

      {/* Layout */}

      <LayoutTopContent data-testid="user-settings-page" $noTopBottomMarginContent>
        {/* - Top Left */}

        <LayoutTopContentTopLeft>
          <UserSettingsTopLeft />
        </LayoutTopContentTopLeft>

        {/* - Top Right */}

        <LayoutTopContentTopRight>
          <UserSettingsTopRight />
        </LayoutTopContentTopRight>

        {/* Content */}

        <LayoutTopContentContent>
          <UserSettings />
        </LayoutTopContentContent>
      </LayoutTopContent>
    </>
  )
}
