import { useSelector } from 'react-redux'
import { DialogExitEditingImage } from '../../../components/dialogs/exit-editing-image/component'
import { DialogGoogleSignIn } from '../../../components/dialogs/google/sign-in/component'
import { ModalsIds } from '../../../constants/modals'
import { StoreState } from '../../../models/app/model'

// ~~~~~~ Component

export const JoinedDialogs = () => {
  // ~~~~~~ State

  const modals = useSelector((state: StoreState) => state.modals.list)

  // ~~~~~~ Computed

  const showGoogleSignIn = modals.includes(ModalsIds.GoogleSignIn)

  const showExitEditingImage = modals.includes(ModalsIds.ExitEditingImage)

  // ~~~~~~ Render

  return (
    <>
      {/* - Dialog: Exit editing image */}

      {showExitEditingImage ? <DialogExitEditingImage /> : undefined}

      {/* Dialog: Google sign-in */}

      {showGoogleSignIn ? <DialogGoogleSignIn /> : undefined}
    </>
  )
}
