const prefix = 'mt-api-bcast-user-settings-delete-epic'

// ~~~~~~ Mutators Types

export const EpicApiBcastUserSettingsDeleteMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiBcastUserSettingsDeleteMC = {
  ok: (data: DN.Models.UserSettings.Api.Delete.ResParsed) => ({
    type: EpicApiBcastUserSettingsDeleteMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.UserSettings.Api.Delete.ResError) => ({
    type: EpicApiBcastUserSettingsDeleteMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
