import { HooksEvents } from '@dn/hooks'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../models/app/model'
import { BroadcastAC } from '../../../store/actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../store/actions/cancel-now/actions'

// ~~~~~~ Component

export const NavigatorOnlineStatus = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  HooksEvents.NavigatorOnlineStatus.useHook()

  // ~~~~~~ State

  const publisher = useSelector((state: StoreState) => state.streamManagerPubMain.publisher)

  const subscriber = useSelector((state: StoreState) => state.streamManagerSubMain.subscriber)

  const connectionStatus = useSelector((state: StoreState) => state.root.connectionStatus)

  // ~~~~~~ Effects

  // - On go offline, set exit if pub or sub and cancel all

  useEffect(() => {
    if (connectionStatus === 'online') return

    // Flow is controlled in pages see broadcast component
    // to show the dialog and then redirect to main page

    if (publisher || subscriber) {
      dispatch(BroadcastAC.exit('network-offline'))
    }

    dispatch(CancelNowAC.cancelAll([CancelReasons.DeviceOffline]))

    //
  }, [connectionStatus, dispatch, publisher, subscriber])

  // ~~~~~~ Render

  return null
}
