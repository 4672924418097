import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'onboarding-panel-done'

// ~~~~~~ Component

export const STOnboardingPanelDone = styled.div`
  ${Fonts.Scale.BodyBig}
  ${Fonts.Weight[300]}

  color: ${theme(Colors.WOLF_50_50)};

  display: flex;
  align-items: center;

  margin-bottom: ${Layout.Spacing[3]}px;

  .${ClassName}--icon {
    margin-right: ${Layout.Spacing[2]}px;

    svg {
      width: 16px;
      height: 16px;

      display: flex;
      align-items: center;

      path {
        fill: none;
        stroke: ${theme(Colors.WOLF_50_50)};
      }
    }
  }
`
