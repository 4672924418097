import styled from 'styled-components'
import { MediaQuery } from '../../../style/constants/media-queries'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'

// ~~~~~~ Props

export type STProps = {
  $noTopBottomMarginContent?: true
}

// ~~~~~~ Component

export const STLayoutTopContent = styled.div<STProps>`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;

  background-color: ${theme(Colors.WOLF_00_100)};

  .bottom-ref {
    position: absolute;
    bottom: 0;
  }

  .layout-top-content-top,
  .layout-top-content-content {
    width: 100%;
  }

  .layout-top-content-top {
    background-color: ${theme(Colors.WOLF_100_100)};

    z-index: 1;
    height: 68px;
    min-height: 68px;

    padding: 0px ${Layout.Spacing[5]}px 0px ${Layout.Spacing[5]}px;
    width: calc(100% - ${Layout.Spacing[5] * 2}px);

    ${Fonts.Scale.Body}

    display: flex;
    justify-content: center;

    .layout-top-content-top-content {
      width: 100%;
      /* max-width: ${MediaQuery.LG}; */

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .layout-top-content-content {
    position: relative;
    z-index: 0;

    flex: 1;

    width: 100%;

    margin-top: ${({ $noTopBottomMarginContent }) => ($noTopBottomMarginContent ? 0 : 8)}px;
    margin-bottom: ${({ $noTopBottomMarginContent }) => ($noTopBottomMarginContent ? 0 : 8)}px;

    height: ${({ $noTopBottomMarginContent }) =>
      $noTopBottomMarginContent
        ? 'calc(100% - 68px)'
        : `calc(100% - ${16 + 68}px)`}; // 16 => 8 (margin) x 2 | 68 => top
  }

  /* Mobile|Tablet: not show top and bottom */

  @media only screen and (hover: none) and (pointer: coarse) {
    .layout-top-content-content {
      margin-top: 0;
      margin-bottom: 0;

      height: calc(100% - 68px);
    }
  }

  /* Tablet */

  @media (max-width: ${MediaQuery.MD}) {
    .layout-top-content-top {
      padding: 0px 24px 0px 24px;
      width: calc(100% - 48px); // 48 => 24 (padding) x 2
    }
  }

  /* Portrait mobile: SM */

  @media screen and (orientation: portrait) and (max-width: ${MediaQuery.SM}) {
    .layout-top-content-content {
      margin: 0;
      border: 0;
      width: 100%;
    }
  }

  /* Landscape mobile: SM */

  @media screen and (orientation: landscape) and (max-height: ${MediaQuery.SM}) {
    .layout-top-content-top {
      display: none;
    }
  }
`
