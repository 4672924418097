import React from 'react'
import { useLayoutPanelsContext } from '../../hooks/use-layout-panels-context'

// ~~~~~~ Component

export const LayoutPanelsHeader: React.FC<any> = ({ children }) => {
  const { kind } = useLayoutPanelsContext()

  return <>{kind === 'header' ? children : null}</>
}
