import { JoinedTabletContentMain } from './main/component'
import { ClassName, STJoinedTabletContent } from './style'

// ~~~~~~ Component

export const JoinedTabletContent = () => {
  // ~~~~~~ Render

  return (
    <STJoinedTabletContent>
      {/* Main content (video + buttons) */}

      <div className={`${ClassName}--main`}>
        <JoinedTabletContentMain />
      </div>

      {/* - */}
    </STJoinedTabletContent>
  )
}
