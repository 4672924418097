export const Brooklyn = {
  90: '#632020',
  80: '#812a29',
  70: '#a73735',
  60: '#d64644',
  50: '#eb4d4b',
  40: '#ef716f',
  30: '#f28886',
  20: '#f6adac',
  10: '#f9c8c7',
  5: '#fdeded',
} as const

// ~~~~~~

const BROOKLYN_05_05: ThemeColor = {
  light: Brooklyn[5],
  dark: Brooklyn[5],
}

const BROOKLYN_05_50: ThemeColor = {
  light: Brooklyn[5],
  dark: Brooklyn[50],
}

const BROOKLYN_20_20: ThemeColor = {
  light: Brooklyn[20],
  dark: Brooklyn[20],
}

const BROOKLYN_30_30: ThemeColor = {
  light: Brooklyn[30],
  dark: Brooklyn[30],
}

const BROOKLYN_40_30: ThemeColor = {
  light: Brooklyn[40],
  dark: Brooklyn[30],
}

const BROOKLYN_40_40: ThemeColor = {
  light: Brooklyn[40],
  dark: Brooklyn[40],
}

const BROOKLYN_50_20: ThemeColor = {
  light: Brooklyn[50],
  dark: Brooklyn[20],
}

const BROOKLYN_50_30: ThemeColor = {
  light: Brooklyn[50],
  dark: Brooklyn[30],
}

const BROOKLYN_50_40: ThemeColor = {
  light: Brooklyn[50],
  dark: Brooklyn[40],
}

const BROOKLYN_60_50: ThemeColor = {
  light: Brooklyn[60],
  dark: Brooklyn[50],
}

const BROOKLYN_60_60: ThemeColor = {
  light: Brooklyn[60],
  dark: Brooklyn[60],
}

// ~~~~~~

export const BrooklynColors = {
  BROOKLYN_05_05,
  BROOKLYN_05_50,
  BROOKLYN_20_20,
  BROOKLYN_30_30,
  BROOKLYN_40_30,
  BROOKLYN_40_40,
  BROOKLYN_50_20,
  BROOKLYN_50_30,
  BROOKLYN_50_40,
  BROOKLYN_60_50,
  BROOKLYN_60_60,
} as const
