import { sendMessageWithAckBcasterCamMicStreamStart$ } from './bcaster-cam-mic-stream-start/comm-channel-partial-service'
import { sendMessageWithAckBcasterCamMicStreamStop$ } from './bcaster-cam-mic-stream-stop/comm-channel-partial-service'
import { sendMessageWithAckBcasterCamTrackDisable$ } from './bcaster-cam-track/disable/comm-channel-partial-service'
import { sendMessageWithAckBcasterCamTrackEnable$ } from './bcaster-cam-track/enable/comm-channel-partial-service'
import { sendMessageWithAckBcasterMicTrackDisable$ } from './bcaster-mic-track/disable/comm-channel-partial-service'
import { sendMessageWithAckBcasterMicTrackEnable$ } from './bcaster-mic-track/enable/comm-channel-partial-service'

// ~~~~~~

export const WithAck = {
  BcasterCamMicStreamStart$: sendMessageWithAckBcasterCamMicStreamStart$,
  BcasterCamMicStreamStop$: sendMessageWithAckBcasterCamMicStreamStop$,

  BcasterCamTrack: {
    Enable$: sendMessageWithAckBcasterCamTrackEnable$,
    Disable$: sendMessageWithAckBcasterCamTrackDisable$,
  },

  BcasterMicTrack: {
    Enable$: sendMessageWithAckBcasterMicTrackEnable$,
    Disable$: sendMessageWithAckBcasterMicTrackDisable$,
  },
}
