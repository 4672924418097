import React from 'react'
import { useLayoutCupContext } from '../../hooks/use-layout-cup-context'

// ~~~~~~ Component

export const LayoutCupContent: React.FC<any> = ({ children }) => {
  const { kind } = useLayoutCupContext()

  return <>{kind === 'content' ? children : null}</>
}
