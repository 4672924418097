import styled from 'styled-components'
import { Transition } from '../../../../../../../../style/animations/css-animations'
import { Colors } from '../../../../../../../../style/theme/colors'
import { theme } from '../../../../../../../../style/utils/theme'
import {
  CommonStyleSvgColors,
  STStartedContentMainToolbarsButtonsCommonStyle,
} from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'started-content-main-toolbars-main-buttons-cam-mic'

// ~~~~~~ Component

export const STStartedContentMainToolbarsMainButtonsCamMic = styled.div`
  ${STStartedContentMainToolbarsButtonsCommonStyle}

  svg {
    height: 28px;
    width: 28px;
  }

  svg.${ClassName}--cam-start {
    path {
      fill: none;
      stroke: ${theme(CommonStyleSvgColors.color)};
    }
  }

  svg.${ClassName}--cam-feedback {
    path {
      ${Transition.FillFaster}

      fill: ${theme(CommonStyleSvgColors.color)};
      stroke: none;
    }

    circle {
      ${Transition.FillFaster}

      fill: ${theme(Colors.SITO_50_50)};
      stroke: none;
    }

    &.running {
      circle {
        fill: ${theme(Colors.LISA_50_50)};
        stroke: none;
      }
    }
  }
`
