import { AppInitState } from '../../../../models/app/model'
import { UtilsStore } from '@dn/utils'
import { SubscriptionMT, SubscriptionMC } from '../../../actions-mutators/subscription/mutators'
import {
  EpicApiBcastCurrentUserShowMT,
  EpicApiBcastCurrentUserShowMC,
} from '../../../epics/api-bcast/current-user/show/mutators'
import {
  EpicApiBcastSubscriptionShowMT,
  EpicApiBcastSubscriptionShowMC,
} from '../../../epics/api-bcast/subscription/show/mutators'
import {
  EpicApiSubscriptionsGetTimeMT,
  EpicApiSubscriptionsGetTimeMC,
} from '../../../epics/api-store/subscription/get-time/mutators'
import {
  ApiSubscriptionsUpgradePlanEpicMT,
  ApiSubscriptionsUpgradePlanEpicMC,
} from '../../../epics/api-store/subscription/upgrade-plan/mutators'
import {
  EpicExtensionUserDataMC,
  EpicExtensionUserDataMT,
} from '../../../epics/extension/user-data/mutators'

const initState = AppInitState.subscription

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Extension user data
// ####################################################################################################

reducer[EpicExtensionUserDataMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicExtensionUserDataMC.ok>,
): State => {
  return {
    ...initState,
    ...payload.subscription,
  }
}

// ####################################################################################################
// ~~~~~~ Current user Show - OK
// ####################################################################################################

reducer[EpicApiBcastCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastCurrentUserShowMC.ok>,
): State => {
  return {
    ...initState,
    ...payload.subscription,
  }
}

// ####################################################################################################
// ~~~~~~ Store Subscription upgrade - OK
// ####################################################################################################

reducer[ApiSubscriptionsUpgradePlanEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiSubscriptionsUpgradePlanEpicMC.ok>,
): State => {
  return {
    ...initState,
    ...payload.subscription,
  }
}

// ####################################################################################################
// ~~~~~~ Get Bcast Subscription - OK
// ####################################################################################################

reducer[EpicApiBcastSubscriptionShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiBcastSubscriptionShowMC.ok>,
): State => {
  return {
    ...state,
    ...payload.subscription,
    time_limit: payload.subscription.time_limit ?? undefined,
    time_remaining: payload.subscription.time_remaining ?? undefined,
  }
}

// ####################################################################################################
// ~~~~~~ Get Bcast Subscription - ERROR
// ####################################################################################################

// ???

// ####################################################################################################
// ~~~~~~ Set days left
// ####################################################################################################

reducer[SubscriptionMT.SET_DAYS_LEFT] = (
  state: State,
  { payload }: ReturnType<typeof SubscriptionMC.setDaysLeft>,
): State => {
  return {
    ...state,
    daysLeft: payload.daysLeft,
  }
}

// TODO(Juanjo): It could be removed when we delete usePreviewCoundown because it is the only
// place where we call this mutator

// ####################################################################################################
// ~~~~~~ Set time
// ####################################################################################################

reducer[SubscriptionMT.SET_TIME_REMAINING] = (
  state: State,
  { payload }: ReturnType<typeof SubscriptionMC.setTimeRemaining>,
): State => {
  return {
    ...state,
    time_remaining: payload.minutes,
  }
}

// ####################################################################################################
// ~~~~~~ Get Time - OK
// ####################################################################################################

reducer[EpicApiSubscriptionsGetTimeMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiSubscriptionsGetTimeMC.ok>,
): State => {
  return {
    ...state,
    time_remaining: payload.remaining,
  }
}

// ####################################################################################################
// ~~~~~~ Get Time - ERROR 412 (user has not time_limit, maybe updated plan, remove)
// ####################################################################################################

reducer[EpicApiSubscriptionsGetTimeMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiSubscriptionsGetTimeMC.error>,
): State => {
  if (payload.status !== 412) return state

  return {
    ...state,
    time_limit: undefined,
    time_remaining: undefined,
  }
}

// ~~~~~~

export const subscriptionReducer = UtilsStore.dynReducer(initState, reducer)
