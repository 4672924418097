import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromEvent, take, takeUntil, filter, scan, takeWhile } from 'rxjs'
import { ConstEventsVideo } from '@dn/constants'
import { StoreState } from '../../models/app/model'
import { BroadcastAC } from '../../store/actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../store/actions/cancel-now/actions'
import { UtilsLog } from '../../utils/logs'

const MaxProgressEvents = 30

export const useVideoSubCancelIfTooManyProgress = (video: HTMLVideoElement | null) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const subscriber = useSelector((state: StoreState) => state.streamManagerSubMain.subscriber)

  // ~~~~~~ Effects

  useEffect(() => {
    if (!video || !subscriber) return

    const progress$ = fromEvent(video, ConstEventsVideo.Progress)

    const loaded$ = fromEvent(video, ConstEventsVideo.LoadedData)

    function exit() {
      dispatch(BroadcastAC.exit('video-too-many-progress'))

      dispatch(CancelNowAC.cancelAll([CancelReasons.VideoTooManyProgress]))
    }

    const sub = progress$
      .pipe(
        takeUntil(loaded$),
        takeWhile(() => !!subscriber),
        filter(() => !!video.srcObject && !!subscriber),
        scan((acc, _cur) => acc + 1, 0),
        filter((value) => value === MaxProgressEvents),
        take(1),
      )
      .subscribe({
        next: exit,
        complete: () => {
          UtilsLog.devLog('useVideoCancelIfTooManyProgress', 'Complete')
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [dispatch, subscriber, video])
}
