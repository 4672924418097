import { UtilsStore } from '@dn/utils'
import { Config } from '../../../config'

const initModel: DN.Models.ScreenShot.Model = {
  canvasImageSource: document.createElement('canvas') as HTMLCanvasElement,
}

// ~~~~~~ Fields Mutators/Reducers Add Image To Slide

const prefix = 'mt-screenshot-edit'

export const ScreenshotFieldsMR = {
  canvasImageSource: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'canvasImageSource',
    initModel.canvasImageSource,
    Config.Intl.InfosForInfer,
  ),
}

// ~~~~~~

export const ScreenShotModel = {
  initModel,
}
