import styled from 'styled-components'
import { PenColor } from '../../../../../../../../constants/pen-color'
import { Transition } from '../../../../../../../../style/animations/css-animations'
import { Colors } from '../../../../../../../../style/theme/colors'
import { getThemeValue, theme } from '../../../../../../../../style/utils/theme'
import { CommonStyleSvgColors, STJoinedMobileToolbarsCapturePaintButtons } from '../../common-style'

// ~~~~~~ Constants

export const ClassName = 'joined-mobile-toolbars-capture-paints-colors-color'

// ~~~~~~ Helpers

const getColor = (colorKey: ColorKey) => PenColor[colorKey].color

// ~~~~~~ Types

type ColorKey = DN.Models.PaintBcastStream.Draw.ColorKey

export type STProps = {
  $colorKey: ColorKey
}

// ~~~~~~ Component

export const STJoinedMobileToolbarsCapturePaintColorsColor = styled.div<STProps>`
  ${STJoinedMobileToolbarsCapturePaintButtons}

  padding: 0;

  ${Transition.BorderColorFaster}

  svg {
    border: solid 1px transparent;

    border-radius: 50%;

    &.selected {
      border-color: ${theme(CommonStyleSvgColors.selected)};
    }

    rect {
      stroke: ${(props) =>
        props.theme.mode === 'light' && props.$colorKey === 'WHITE'
          ? getThemeValue(props, Colors.WOLF_20_20)
          : props.theme.mode === 'dark' && props.$colorKey === 'BLACK'
            ? getThemeValue(props, Colors.WOLF_80_80)
            : getColor(props.$colorKey)}; // svg border

      fill: ${({ $colorKey }) => getColor($colorKey)};
    }
  }
`
