import {
  EMPTY,
  fromEvent,
  Observable,
  of,
  catchError,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
} from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'
import { ConstWebRtc } from '@dn/constants'
import { ServiceWebRtc } from '@dn/webrtc'
import { StoreState } from '../../../../../models/app/model'
import { ExtensionService } from '../../../../../services/extension'
import { UtilsLog } from '../../../../../utils/logs'
import { cancelAll$$ } from '../../../../../subjects/cancel-all/subject'
import { ApiBcastUserSettingsAC } from '../../../../actions/api-bcast/user-settings/actions'
import { CancelNowAC, CancelReasons } from '../../../../actions/cancel-now/actions'
import { ShareMainStreamAC, ShareMainStreamAT } from '../../../../actions/share-main-stream/actions'
import { ShareMainStream$$ } from '../../subject'
import { EpicShareMainStreamGetScreenToSwitchMC } from '../mutators'
import { BcastTrackEvents } from '../../../../../services/track-events'
import { Config } from '../../../../../config'

type Action = ReturnType<typeof ShareMainStreamAC.getStreamToSwitch>

export const deprecatedShareMainStreamGetStreamToSwitchEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ShareMainStreamAT.GET_STREAM_TO_SWITCH),

    filter(
      () =>
        !Config.Features.BcastService2 ||
        (Config.Features.BcastService2 &&
          Config.Features.BcastCheckVersion &&
          state$.value.root.version === 'v1'),
    ),

    tap(() => {
      ExtensionService.SendMessage.ToExtension.setMeAsActive()
      ExtensionService.SendMessage.ToExtension.chooseSourceStateChanged('choosing')
    }),

    mergeMap(() => {
      const subscription = state$.value.subscription

      const extraConstraints: MediaTrackConstraints = {
        frameRate: {
          // A double-precision floating-point number specifying a value the property would ideally have,
          // but which can be considered optional if necessary to find a match.
          ideal: subscription.fps,

          // A double-precision floating-point number specifying a specific,
          // required, value the property must have to be considered acceptable
          // exact is equivalent to min == max
          // Currently not supported, see:
          // https://chromium.googlesource.com/chromium/src/+/refs/heads/main/third_party/blink/renderer/modules/mediastream/user_media_request.cc#391
          // exact: subscription.fps,

          max: subscription.fps,
        },
      }

      // Quality

      return ServiceWebRtc.Media.Screen.getStream$(subscription.quality, extraConstraints).pipe(
        takeUntil(cancelAll$$),
        catchError(() => of(EpicShareMainStreamGetScreenToSwitchMC.error([]))),
      )
    }),

    // TODO(Cuenca): Extract to function, code shared with extension-choose-source-to-switch
    // ecept the mutator calls

    mergeMap((data) => {
      if ('error' in data) return of(data)

      const { curScreenVideoTrack, canvasStreamStop } = state$.value.sharedMainStream

      const { isOnboardingRunning, onboarding_create_a_bcast, onboarding_change_source } =
        state$.value.userSettings

      const currentUserId = state$.value.currentUser.id

      const newStream = data

      const newVideoTrack = newStream.getVideoTracks()[0]

      const publisher = state$.value.streamManagerPubMain.publisher

      if (!publisher) return EMPTY

      const peerConn = publisher.getPeerConnection()

      const transceiver = peerConn.getTransceivers()[0]

      const obs$ = new Observable((observer) => {
        //

        // -))
        observer.next(EpicShareMainStreamGetScreenToSwitchMC.start())

        // 250 ms of delay. Prevent popping when image appears to replace the video
        setTimeout(() => {
          // Bitrate experiments

          transceiver.sender
            .replaceTrack(newVideoTrack)
            .then(() => {
              // Onboarding change source

              if (isOnboardingRunning && onboarding_create_a_bcast && !onboarding_change_source) {
                observer.next(
                  ApiBcastUserSettingsAC.update(currentUserId, {
                    onboarding_change_source: true,
                  }),
                )

                // Track

                const userContext = BcastTrackEvents.helpers.genUserContext(
                  state$.value.currentUser,
                  state$.value.subscription,
                )

                BcastTrackEvents.calls.Onboarding['2-onboarding-change-source'](userContext)
              }

              // Bitrate experiments
              // PeerConnUtils.createOfferAndSetLocalDescription$(peerConn, videoBitrate).subscribe()

              //

              // $-)) Stop listening old track (get-screen and get-screen-for-change)
              ShareMainStream$$.next({ type: 'replace-track' })

              // Free old tracks
              canvasStreamStop && canvasStreamStop()
              curScreenVideoTrack?.stop()

              // If stream track ended (maybe user click on 'stop sharing' button)
              // dispatch cancel all, that also sends a disconnect message

              fromEvent(newVideoTrack, ConstWebRtc.Media.Track.Events.Ended)
                .pipe(
                  take(1),

                  takeUntil(cancelAll$$),
                  takeUntil(ShareMainStream$$.pipe(filter((msg) => msg.type === 'replace-track'))),

                  map(() => {
                    return CancelNowAC.cancelAll([CancelReasons.ScreenSharingEnded])
                  }),

                  catchError(() => {
                    return of(CancelNowAC.cancelAll([CancelReasons.ScreenStreamHasNotAMediaTrack]))
                  }),
                )
                .subscribe({
                  next: (cancelNowAction) => {
                    // -))-|
                    observer.next(cancelNowAction)
                    observer.complete()
                  },
                  complete: () => observer.complete(),
                })

              // -)) Emit new stream and new videoTrack
              observer.next(EpicShareMainStreamGetScreenToSwitchMC.ok(newStream, newVideoTrack))

              //
            })
            .catch((err) => {
              UtilsLog.devLog('GetScreenForChange$', 'replace-track-failed')
              // -X
              observer.error()
            })
        }, 250)
      })

      return obs$.pipe(catchError(() => of(EpicShareMainStreamGetScreenToSwitchMC.error([]))))
    }),

    tap(() => {
      ExtensionService.SendMessage.ToExtension.chooseSourceStateChanged('not-choosing')
    }),
  )
