import { AppInitState } from '../../../../models/app/model'
import { StreamManagerSubMainFieldsMR } from '../../../../models/stream-manager/sub/main/model'
import { UtilsStore } from '@dn/utils'
import {
  EpicBcastStartSubMainMC,
  EpicBcastStartSubMainMT,
} from '../../../epics/bcast/start-sub-main/mutators'

import { ExitMT } from '../../../actions-mutators/exit/mutators'
import { EpicCancelNowAllMT } from '../../../epics/cancel-now/all/mutators'

const initState = AppInitState.streamManagerSubMain

type State = typeof initState

const reducer: any = {
  ...StreamManagerSubMainFieldsMR.id.Reducer,
}

// ####################################################################################################
// ~~~~~~ Subscription Event: Ok
// ####################################################################################################

reducer[EpicBcastStartSubMainMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartSubMainMC.ok>,
): State => {
  return {
    ...state,
    subscriber: payload.sub,
    username: payload.username,
    id: payload.broadcastId,
  }
}

// ####################################################################################################
// ~~~~~~ Subscription Event: error
// ####################################################################################################

reducer[EpicBcastStartSubMainMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartSubMainMC.error>,
): State => {
  if (!payload.errors) return state

  return {
    ...state,
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Last Event
// ####################################################################################################

reducer[EpicBcastStartSubMainMT.EVENT] = (
  state: State,
  { payload }: ReturnType<typeof EpicBcastStartSubMainMC.event>,
): State => {
  return {
    ...state,
    lastEvent: payload.type,
    isReady: payload.type === 'Subscribe.VideoDimensions.Change' ? true : state.isReady,
  }
}

// ####################################################################################################
// ~~~~~~ Reset exit, remove errors
// ####################################################################################################

reducer[ExitMT.RESET_EXIT] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Cancel now all - DONE
// ####################################################################################################

reducer[EpicCancelNowAllMT.DONE] = (state: State): State => {
  return {
    ...initState,
    id: state.id,
  }
}

// ~~~~~~

export const streamManagerSubMainReducer = UtilsStore.dynReducer(initState, reducer)
