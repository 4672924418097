import { ofType, StateObservable } from 'redux-observable'
import { mergeMap, Observable, of } from 'rxjs'
import { StoreState } from '../../../../../models/app/model'
import { BroadcastAC } from '../../../../actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../../actions/cancel-now/actions'
import {
  EpicBcastCommChannelMessagesFromServerMC,
  EpicBcastCommChannelMessagesFromServerMT,
} from '../../messages/from-server/mutators'

type Action = ReturnType<typeof EpicBcastCommChannelMessagesFromServerMC.predefMsgCloseNowNoViewers>

export const onPredefMsgConfirmCloseNowByNoViewers$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicBcastCommChannelMessagesFromServerMT.PREFED_MSG_CLOSE_NOW_NO_VIEWERS),

    mergeMap(() =>
      of(
        BroadcastAC.exit('inactivity'),

        CancelNowAC.cancelAll([CancelReasons.Inactivity]),
      ),
    ),
  )
