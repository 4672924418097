import React from 'react'
import { useLayoutCupContext } from '../../../hooks/use-layout-cup-context'

// ~~~~~~ Component

export const LayoutCupTopRight: React.FC<any> = ({ children }) => {
  const { kind } = useLayoutCupContext()

  return <>{kind === 'top-right' ? children : null}</>
}
