import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-settings-template'

// ~~~~~~ Types

type STProps = {
  $isMobile: boolean
}

// ~~~~~~ Component

export const STUserSettingsTemplate = styled.div<STProps>`
  display: flex;
  flex-direction: column;

  padding-left: ${Layout.Spacing[5]}px;

  height: calc(100vh - ${({ $isMobile }) => ($isMobile ? 0 : 252)}px);

  overflow: hidden;

  .${ClassName}--header {
    margin-bottom: ${Layout.Spacing[6]}px;

    .${ClassName}--header--title {
      ${Fonts.Scale.Subtitle}
      ${Fonts.Weight[700]}
    }

    .${ClassName}--header--subtitle {
      ${Fonts.Scale.Body}
      ${Fonts.Weight[500]}

      color: ${theme(Colors.WOLF_80_20)};
    }
  }

  .${ClassName}--scroller-tabs {
    .${ClassName}--scroller-tabs--content {
      display: flex;

      .${ClassName}--scroller-tabs--content--section {
        cursor: pointer;
        margin-right: ${Layout.Spacing[3]}px;

        &.active {
          color: ${theme(Colors.EMMET_40_50)};

          border-bottom: solid 1px ${theme(Colors.EMMET_40_50)};
        }
      }
    }
  }

  .${ClassName}--scroller-content {
    padding-bottom: 20px;
  }
`
