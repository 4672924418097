import { Observable, of, catchError, map } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiBcastRoutes } from '../constants/routes'
import { BcastHeaderUtils } from '../utils/headers'

// ~~~~~~

const { Version } = ApiBcastRoutes.BcastRoom

// ####################################################################################################
// ~~~~~~ Bcast Room version (v1: red5pro, v2: livekit)
// ####################################################################################################

type Res = {
  id: string
  version: 'v1' | 'v2'
}

type ResParsed = DNApiResParsed<Res>
type ResError = DNApiError<Infos[]>

export const apiBcastRoomVersion$ = (bcastId: string) => {
  return ajax<Res>({
    ...BcastHeaderUtils.genHeadersForRoom(),
    method: Version.Method,
    url: Version.getUrl(bcastId),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),

    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.server.get.Error', values: { value: res.status } }],
      })
    }),
  )
}
