import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { STPaintToolbarButtonsUndo, STProps } from './style'

// ~~~~~~ Constants

const Icon = LazySVG('icons/undo')

// ~~~~~~ Types

type Props = STProps & {
  onClick: () => void
}

// ~~~~~~ Component

export const PaintToolbarButtonsUndo: React.FC<Props> = ({ $disabled, onClick }) => {
  // ~~~~~~ Render

  return (
    <STPaintToolbarButtonsUndo onClick={onClick} $disabled={$disabled}>
      <Icon size={24} />
    </STPaintToolbarButtonsUndo>
  )
}
