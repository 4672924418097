import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'dialog-end-bcast-feedback-form'

// ~~~~~~ Component

export const STDialogEndBcastFeedbackForm = styled.div`
  .${ClassName}--title {
    ${Fonts.Scale.Title}
    ${Fonts.Weight[300]}

    color: ${theme(Colors.WOLF_100_05)};

    margin-bottom: ${Layout.Spacing[2]}px;
  }

  .${ClassName}--subtitle {
    ${Fonts.Scale.Body}
    ${Fonts.Weight[500]}

    margin-bottom: ${Layout.Spacing[3]}px;
  }

  .${ClassName}--feedback {
    margin-bottom: ${Layout.Spacing[3]}px;
  }

  .${ClassName}--get-in-touch {
  }
`
