import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fromEvent } from 'rxjs'
import { ConstEventsWindow } from '@dn/constants'
import { HooksData } from '@dn/hooks'
import { UtilsMobile } from '@dn/utils'

import { RegexRoutes, Routes } from '../../constants/routes/routes'
import { StoreState } from '../../models/app/model'
import { HocTheme } from '@dn/hocs'

// ~~~~~~ Constants

const isMobile = UtilsMobile.calcIsMobile()

const RealHeightRoutes = [
  Routes.SignIn,
  Routes.Join,
  // Routes.Joining <= require regex
  // Routes.Joined <= require regex
  Routes.Start,
  Routes.Starting,
  Routes.Started,
]

// ~~~~~~ Hook

export const useSetRealSize = () => {
  // ~~~~~~ Hooks

  const path = useLocation().pathname

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [sizeData, setSizeData] = useState({
    isPortrait: window.innerHeight > window.innerWidth,
    width: window.innerWidth,
    height: window.innerHeight,
  })

  // - Store

  const { pageWidth, pageHeight } = useSelector((state: StoreState) => state.theme)

  const uiPipFullscreenUpdatedPn = useSelector(
    (state: StoreState) => state.uiPipFullscreen.updatedPn,
  )

  // - Refs

  const bottomRef = useRef<HTMLDivElement>(null)

  // ~~~~~~ Dependent hooks

  const prevUiPipFullscreenUpdatedPn = HooksData.PrevValue.useHook(uiPipFullscreenUpdatedPn)

  // ~~~~~~ Computed

  const isMobileLandscapeJoin = isMobile && !sizeData.isPortrait && path === Routes.Join

  const isValidRoute =
    RealHeightRoutes.includes(path as any) ||
    RegexRoutes.joining.exec(path) ||
    RegexRoutes.joined.exec(path)

  // ~~~~~~ Effects

  // - Update real height

  useEffect(() => {
    if (!bottomRef.current) return

    const newWidth = bottomRef.current.offsetLeft

    const newHeight = bottomRef.current.offsetTop

    if (isMobileLandscapeJoin && (pageWidth !== undefined || pageHeight !== undefined)) {
      dispatch(HocTheme.Mutators.MC.chSize(undefined, undefined))
    }

    if (
      !isMobileLandscapeJoin &&
      !isValidRoute &&
      (pageWidth !== undefined || pageHeight !== undefined)
    ) {
      dispatch(HocTheme.Mutators.MC.chSize(undefined, undefined))
    }

    if (
      !isMobileLandscapeJoin &&
      isValidRoute &&
      (pageWidth !== newWidth || pageHeight !== newHeight)
    ) {
      dispatch(HocTheme.Mutators.MC.chSize(newWidth, newHeight))
    }

    const sub = fromEvent(window, ConstEventsWindow.Resize).subscribe({
      next: () => {
        requestAnimationFrame(() => {
          if (!bottomRef.current) return

          const newWidth = bottomRef.current.offsetLeft

          const newHeight = bottomRef.current.offsetTop

          if (isMobileLandscapeJoin && (pageWidth !== undefined || pageHeight !== undefined)) {
            dispatch(HocTheme.Mutators.MC.chSize(undefined, undefined))
          }

          if (
            !isMobileLandscapeJoin &&
            !isValidRoute &&
            (pageWidth !== undefined || pageHeight !== undefined)
          ) {
            dispatch(HocTheme.Mutators.MC.chSize(undefined, undefined))
          }

          if (
            !isMobileLandscapeJoin &&
            isValidRoute &&
            (pageWidth !== newWidth || pageHeight !== newHeight)
          ) {
            dispatch(HocTheme.Mutators.MC.chSize(newWidth, newHeight))
          }
        })
      },
    })

    return () => {
      sub.unsubscribe()
    }
  }, [dispatch, isMobileLandscapeJoin, isValidRoute, pageHeight, pageWidth])

  // - Update mobile disposition (portrait/landscape)
  //   on change orientation (resize)

  useEffect(() => {
    if (!isMobile) return

    const sub = fromEvent(window, ConstEventsWindow.Resize).subscribe({
      next: () => {
        setTimeout(() => {
          if (window.innerWidth === sizeData.width && window.innerHeight === sizeData.height) {
            return
          }

          setTimeout(() => {
            setSizeData({
              isPortrait: window.innerHeight > window.innerWidth,
              width: window.innerWidth,
              height: window.innerHeight,
            })
          }, 10)
        }, 0)
      },
    })

    return () => {
      sub.unsubscribe()
    }
  }, [sizeData.height, sizeData.width])

  // - On pip-fullscreen change update real height

  useEffect(() => {
    if (
      !prevUiPipFullscreenUpdatedPn ||
      prevUiPipFullscreenUpdatedPn === uiPipFullscreenUpdatedPn
    ) {
      return
    }

    requestAnimationFrame(() => {
      if (!bottomRef.current) return

      const newWidth = bottomRef.current.offsetLeft

      const newHeight = bottomRef.current.offsetTop

      if (isMobileLandscapeJoin && (pageWidth !== undefined || pageHeight !== undefined)) {
        dispatch(HocTheme.Mutators.MC.chSize(undefined, undefined))
      }

      if (
        !isMobileLandscapeJoin &&
        !isValidRoute &&
        (pageWidth !== undefined || pageHeight !== undefined)
      ) {
        dispatch(HocTheme.Mutators.MC.chSize(undefined, undefined))
      }

      if (
        !isMobileLandscapeJoin &&
        isValidRoute &&
        (pageWidth !== newWidth || pageHeight !== newHeight)
      ) {
        dispatch(HocTheme.Mutators.MC.chSize(newWidth, newHeight))
      }
    })

    //
  }, [
    dispatch,
    isMobileLandscapeJoin,
    isValidRoute,
    pageHeight,
    pageWidth,
    prevUiPipFullscreenUpdatedPn,
    uiPipFullscreenUpdatedPn,
  ])

  // ~~~~~~

  return { bottomRef, realHeight: pageHeight }
}
