import { useEffect, useState } from 'react'
import { take } from 'rxjs'
import { ExtensionService } from '../../services/extension'
import { UtilsLog } from '../../utils/logs'

// ~~~~~~ Hook

export const useExtensionGetCurrentUser = (
  onSuccess: (data: DN.Services.Extension.AppInfoPayload | undefined) => void,
  onError: () => void,
) => {
  // ~~~~~~ State

  const [isMounted, setIsMounted] = useState(false)

  // ~~~~~~ Effects

  // - On mount call the extension to get user info

  useEffect(() => {
    if (isMounted) return

    setIsMounted(true)

    ExtensionService.SendMessage.ToExtension.appInfo$()
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          onSuccess(data)
        },
        error: (err) => {
          UtilsLog.devLog('Extension get current user EROR', err)
          onError()
        },
      })

    //
  }, [isMounted, onError, onSuccess])
}
