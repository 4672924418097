import { UtilsStore } from '@dn/utils'

// ~~~~~~ Constants

const ErrorType: Infos = { id: 'wildcard' }

const initModel: DN.Models.BcastCountdown.Model = {
  timeRemainig: {
    minutes: 0,
    seconds: 0,
  },
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-bcast-countdown-edit'

export const BcastCountdownMR = {
  timeRemaining: UtilsStore.genFieldMR(
    prefix,
    initModel,
    'timeRemainig',
    initModel.timeRemainig,
    ErrorType,
  ),
}

// ~~~~~~

export const BcastCountdownModel = {
  initModel,
}
