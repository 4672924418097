import { UnknownAction } from 'redux'
import { ofType, StateObservable } from 'redux-observable'
import { mergeMap, Observable, of } from 'rxjs'
import { HocTheme } from '@dn/hocs'
import { UtilsLang, UtilsOS } from '@dn/utils'

import { ToastUtil } from '../../../../../components/common/toast/util'
import { StoreState } from '../../../../../models/app/model'
import { UiOnboardingFieldsMR } from '../../../../../models/bcast/ui-onboarding/model'
import { apiBcastUserSettingsUpdate$ } from '../../../../../services/api-bcast/user-settings/update'
import {
  ApiBcastUserSettingsAC,
  ApiBcastUserSettingsAT,
} from '../../../../actions/api-bcast/user-settings/actions'
import { IntlAC } from '../../../../actions/intl/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiBcastUserSettingsUpdateMC } from './mutators'
import { Config } from '../../../../../config'

// ~~~~~~ Constants

const { DefaultLocale, SupportedLocales } = Config.Intl

// ~~~~~~ Types

type Action = ReturnType<typeof ApiBcastUserSettingsAC.update>

// ~~~~~~ Epic

export const apiBcastUserSettingsUpdateEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiBcastUserSettingsAT.UPDATE),

    mergeMap(({ payload }) =>
      apiBcastUserSettingsUpdate$(state$.value.currentUser.token, payload.userId, payload.patch, {
        isOnboardingRunning: state$.value.userSettings.isOnboardingRunning,
      }),
    ),

    mergeMap((res) => {
      if (!ApiUtils.isSuccess(res)) {
        return of(EpicApiBcastUserSettingsUpdateMC.error(res.response))
      }

      // Update

      const actions: UnknownAction[] = [EpicApiBcastUserSettingsUpdateMC.ok(res.response)]

      // Lang

      const langAction =
        !res.response.lang || res.response.lang === 'auto'
          ? IntlAC.changeLang(UtilsLang.getAppLang(SupportedLocales, DefaultLocale))
          : IntlAC.changeLang(res.response.lang)

      actions.push(langAction)

      // Theme

      const themeAction =
        !res.response.theme || res.response.theme === 'auto'
          ? HocTheme.Mutators.MC.changeTheme(UtilsOS.getTheme())
          : HocTheme.Mutators.MC.changeTheme(res.response.theme)

      actions.push(themeAction)

      // Toast if re-enabled

      if (
        state$.value.userSettings.onboarding_is_completed &&
        !res.response.onboarding_is_completed
      ) {
        ToastUtil.basic({
          kind: 'success',
          intlIds: [{ id: 'common-components.onboarding.Reset' }],
        })

        actions.push(UiOnboardingFieldsMR.buttonState.MC.change('button-only'))
      }

      // All actions

      return of(...actions)
    }),
  )
