import { UtilsStore } from '@dn/utils'

const NoBounds: DN.Models.UiOnboarding.Bounds = {
  x: 0,
  y: 0,

  width: 0,
  height: 0,

  top: 0,
  right: 0,

  bottom: 0,
  left: 0,
}

const initModel: DN.Models.UiOnboarding.Model = {
  buttonState: 'hidden',
  panelState: 'hidden',
  tooltipState: 'none',

  onboardingChangeSourceBounds: NoBounds,
  onboardingShareBounds: NoBounds,
  onboardingLiveDrawBounds: NoBounds,
}

// ~~~~~~ Edit

const prefix = 'mt-ui-onboarding-edit'

// ~~~~~~ Fields Mutators/Reducers

export const UiOnboardingFieldsMR = {
  buttonState: UtilsStore.genBasicFieldMR(prefix, initModel, 'buttonState', initModel.buttonState),
  panelState: UtilsStore.genBasicFieldMR(prefix, initModel, 'panelState', initModel.panelState),
  tooltipState: UtilsStore.genBasicFieldMR(
    prefix,
    initModel,
    'tooltipState',
    initModel.tooltipState,
  ),

  onboardingChangeSourceBounds: UtilsStore.genBasicFieldMR(
    prefix,
    initModel,
    'onboardingChangeSourceBounds',
    initModel.onboardingChangeSourceBounds,
  ),

  onboardingShareBounds: UtilsStore.genBasicFieldMR(
    prefix,
    initModel,
    'onboardingShareBounds',
    initModel.onboardingShareBounds,
  ),

  onboardingLiveDrawBounds: UtilsStore.genBasicFieldMR(
    prefix,
    initModel,
    'onboardingLiveDrawBounds',
    initModel.onboardingLiveDrawBounds,
  ),
}

// ~~~~~~

export const UiOnboardingModel = {
  initModel,
} as const
