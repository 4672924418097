const prefix = 'mt-share-main-stream-get-stream-by-extension-to-switch-api'

// ~~~~~~ Mutators Types

export const EpicShareMainStreamGetStreamByExtensionToSwitchMT = {
  WAITING_STREAM: `${prefix}-waiting-stream`,
  START: `${prefix}-start`,
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-error`,
} as const

// ~~~~~~

export const EpicShareMainStreamGetStreamByExtensionToSwitchMC = {
  waitingStream: () => ({
    type: EpicShareMainStreamGetStreamByExtensionToSwitchMT.WAITING_STREAM,
  }),

  start: () => ({
    type: EpicShareMainStreamGetStreamByExtensionToSwitchMT.START,
  }),

  ok: (newStream: MediaStream, newVideoTrack: MediaStreamTrack) => ({
    type: EpicShareMainStreamGetStreamByExtensionToSwitchMT.OK,
    payload: {
      newStream,
      newVideoTrack,
    },
  }),

  error: (errors: Infos[]) => ({
    error: true,
    type: EpicShareMainStreamGetStreamByExtensionToSwitchMT.ERROR,
    payload: {
      errors,
    },
  }),
} as const
