import { Observable, map } from 'rxjs'
import { ofType, StateObservable } from 'redux-observable'

import { StoreState } from '../../../../models/app/model'
import { BroadcastAC, BroadcastAT } from '../../../actions/bcast/actions'
import { EpicBcastExitMC } from './mutators'

type Action = ReturnType<typeof BroadcastAC.exit>

export const bcastExitEpic$ = (action$: Observable<Action>, state$: StateObservable<StoreState>) =>
  action$.pipe(
    ofType(BroadcastAT.EXIT),
    map(({ payload }) => EpicBcastExitMC.ok(payload.reason)),
  )
