// ~~~~~~ Constants

export const prefix = 'at-from-extension'

// ~~~~~~ Helpers

const genType = (from: string) => `${prefix}-#-${from}`

// ~~~~~~ Types

type Action<T, M> = {
  type: string
  payload: T
  meta: M & {
    source: MessageEventSource
  }
}

// ~~~~~~ Action Types

export const ExtensionDispatcherAT = {
  STOP_BROADCAST: genType('stop-bcast-now'),
  CHANGE_SOURCE: genType('change-source'),
}

// ~~~~~~ Action Creator Types

export type ExtensionDispatcherACT = {
  StopBcast: Action<undefined, { msgId: string }>
  ChangeSource: Action<undefined, undefined>
}
