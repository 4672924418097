import styled from 'styled-components'
import { STPaintToolbarCommonStyle } from '../common-style'

// ~~~~~~ Types

export type STProps = {
  $disabled: boolean
}

// ~~~~~~ Component

export const STPaintToolbarButtonsRedo = styled.div<STProps>`
  ${STPaintToolbarCommonStyle}
`
