import { Raster, Size } from 'paper/dist/paper-core'
import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'

type Data = {
  scope: paper.PaperScope
  canvas: HTMLCanvasElement
  width: number
  height: number
}

export const setCanvas = ({ scope, canvas, width, height }: Data) => {
  Config.Logs.DrawService && UtilsLog.devLog('\nDrawService', 'setCanvas', { width, height })

  canvas.width = width
  canvas.height = height

  scope.setup(canvas)

  scope.view.viewSize = new Size(width, height)

  const raster = new Raster(canvas)

  raster.position = scope.view.center

  scope.project.activeLayer.fitBounds(scope.view.bounds)
}
