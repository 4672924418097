import { StartedContentMain } from './main/component'
import { ClassName, STStartedContent } from './style'

// ~~~~~~ Component

export const StartedContent = () => {
  // ~~~~~~ Render

  return (
    <STStartedContent>
      {/* Main content (video + buttons) */}

      <div className={`${ClassName}--main`}>
        <StartedContentMain />
      </div>

      {/* - */}
    </STStartedContent>
  )
}
