import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import { Colors } from './theme/colors'
import { Fonts } from './theme/fonts'
import { theme } from './utils/theme'
import { HexAlpha } from './theme/colors/alpha'
import { Layout } from './theme/layout'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  /* Do not put fonts in global style or a bad flicker effect will appear */

  body {
    width: 100vw;

    height: ${({ theme }) => (theme.pageHeight ? `${theme.pageHeight}px` : '100vh')};

    max-height: -webkit-fill-available;

    overflow: ${({ theme }) => (theme.pageHeight ? 'hidden' : 'auto')};

    display: flex;

    padding: 0;
    margin: 0;

    color: ${theme(Colors.WOLF_100_05)};
    background-color: ${theme(Colors.WOLF_00_100)};

    ${Fonts.Family}
    ${Fonts.Scale.BodyBig}
    ${Fonts.Weight[500]}

    /* Fix Safari ios bug: with a modal present you can scroll
    overflow: hidden;*/

    user-select: none;

    #app {
      width: 100vw;
      height: ${({ theme }) => (theme.pageHeight ? `${theme.pageHeight}px` : '100vh')};
    }

    /* Main content */

    #root {
      width: 100%;
      height: 100%;
    }

    /* Modal Portal */

    #modal-root {
      position: relative;
      z-index: 1000;

      .modal {
        background-color: ${theme(Colors.WOLF_90_90)}${HexAlpha[90]};
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  /* Reset */

  :focus { outline: 0; }

  h1, h2, h3, h4, h5, h6, p, span, form {
    margin: 0;
    padding: 0;
  }

  textarea {
    margin: 0;
    border-radius: 0;
  }

  input {
    padding: 0;
    border-radius: 0;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
  }

  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  /* Edge password eye */

  ::-ms-reveal {
    display: none;
  }

  /* Video controls */

  video::-webkit-media-controls-enclosure {
    display: none !important;
  }

  ::-moz-full-screen .my-video {
    width: 100%;
    height: 100%;
  }

  .my-video::-moz-full-screen {
    width: 100%;
    height: 100%;
  }

  .my-video::-webkit-full-screen {
    width: 100%;
    height: 100%;
  }

  .scrollbar-vertical  {
    position: absolute;
    width: 6px;
    right: 2px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;

    & > div {
      background-color: ${theme(Colors.WOLF_10_90)} !important;
    }
  }

  /* https://googlechrome.github.io/samples/picture-in-picture/css-pseudo-class */

  .my-video:picture-in-picture {
    /* css here not working */
  }

  /* Toast */

  .toast {
    [role="status"] {
      margin: 0;
    }
  }

  /* Tooltips */

  .with-tooltip {
    .tooltiptext {
      visibility: hidden;

      ${Fonts.Scale.Label}

      background-color: ${theme(Colors.WOLF_90_90)};
      color: ${theme(Colors.WOLF_05_05)};

      text-align: center;
      white-space: nowrap;
      padding: ${Layout.Spacing[1]}px ${Layout.Spacing[2]}px;
      border-radius: 6px;

      /* Position the tooltip text */
      position: absolute;
      z-index: 1;
      bottom: 44px;

      /* Fade in tooltip */
      opacity: 0;

      transition: opacity 0.3s;
      transition-delay: 0.5s;
    }

    &.viewer .tooltiptext {
      bottom: 40px;
    }

    @media(hover: hover) {
      &:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
    }
  }
`
