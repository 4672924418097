import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'
import { Colors } from '../../../../../style/theme/colors'
import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { Trans } from '../../../intl/trans'
import { Loader } from '../../../loader/component'
import { ClassName, STUserSettingsProvidersGoogle } from './style'
import { useBcasterGConnect } from '../../../../../hooks/bcaster-g-connect/use-bcaster-g-connect'
import { useBcasterGDisconnect } from '../../../../../hooks/bcaster-g-connect/use-bcaster-g-disconnect'

// ~~~~~~ Constants

const IconGoogle = LazySVG('logos/google')

// ~~~~~~ Computed

export const UserSettingsProvidersGoogle = () => {
  // ~~~~~~ Hooks

  const bcasterGConnect = useBcasterGConnect()

  const bcasterGDisconnect = useBcasterGDisconnect()

  // ~~~~~~ State

  const { drive, slides, classroom_list, classroom_announcements, uiStatus } = useSelector(
    (state: StoreState) => state.providerGoogle,
  )

  // ~~~~~~ Computed

  const hasNoneLinked = !drive && !slides && !classroom_list && !classroom_announcements

  const hasSomeGoogleLinked = drive || slides || classroom_list || classroom_announcements

  const isProviderGoogleRunning = uiStatus === 'running'

  const showLink = !isProviderGoogleRunning && hasNoneLinked

  const showUnlink = !isProviderGoogleRunning && hasSomeGoogleLinked

  // ~~~~~~ Handlers

  function onClickLink() {
    if (isProviderGoogleRunning) return

    bcasterGConnect()
  }

  function onClickUnlink() {
    if (isProviderGoogleRunning) return

    bcasterGDisconnect()
  }

  // ~~~~~~ Render

  return (
    <STUserSettingsProvidersGoogle>
      {/* icon */}

      <div className={`${ClassName}--icon`}>
        <IconGoogle size={20} />
      </div>

      <div className={`${ClassName}--content`}>
        {/* Header */}

        <div className={`${ClassName}--content--title`}>Google</div>

        <div className={`${ClassName}--content--subtitle`}>
          <Trans id="common-components.user-settings.sections.providers.Google.Subtitle" />
        </div>

        {/* Actions */}

        <div className={`${ClassName}--actions`}>
          {/* Running */}

          {isProviderGoogleRunning ? <Loader size={24} color={Colors.EMMET_50_50} /> : undefined}

          {/* Link */}

          {showLink ? (
            <div className={`${ClassName}--actions--link`} onClick={onClickLink}>
              <Trans id="common-components.user-settings.sections.providers.Google.action.Link" />
            </div>
          ) : undefined}

          {/* Unlink */}

          {showUnlink ? (
            <div className={`${ClassName}--actions--unlink`} onClick={onClickUnlink}>
              <Trans id="common-components.user-settings.sections.providers.Google.action.Unlink" />
            </div>
          ) : undefined}
        </div>
      </div>
    </STUserSettingsProvidersGoogle>
  )
}
