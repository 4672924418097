import { Trans } from '../../../../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsPIP } from '../../../../../../../behaviour-logic/content/main/toolbars/main/buttons/pip/hook'
import { ClassName, STJoinedDesktopContentMainToolbarsMainButtonsPIP } from './style'

// ~~~~~~ Constants

const PipIsOffIcon = LazySVG('icons/pip-inactive')
const PipIsOnIcon = LazySVG('icons/pip-active')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
  onUpdate: () => void
}

// ~~~~~~ Component

export const JoinedDesktopContentMainToolbarsMainButtonsPIP: React.FC<Props> = ({
  video,
  onUpdate,
}) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsPIP(video, onUpdate)

  // ~~~~~~ Render

  return (
    <STJoinedDesktopContentMainToolbarsMainButtonsPIP
      className={`with-tooltip ${logic.lang === 'es' || logic.isPipOn() ? 'small' : ''}`}
      onClick={logic.onClick}
    >
      {/* Icon */}

      {logic.isPipOn() ? (
        <PipIsOnIcon className={`${ClassName}--icon-on`} size={24} />
      ) : (
        <PipIsOffIcon className={`${ClassName}--icon-off`} size={24} />
      )}

      {/* Tooltip */}

      <span
        ref={logic.tooltipRef}
        className="tooltiptext"
        style={{
          top: logic.tooltipTop,
        }}
      >
        {logic.isPipOn() ? (
          <Trans id="pages.joined.main.toolbars.main.tooltips.pip.Exit" />
        ) : (
          <Trans id="pages.joined.main.toolbars.main.tooltips.pip.Enter" />
        )}
      </span>

      {/* -- */}
    </STJoinedDesktopContentMainToolbarsMainButtonsPIP>
  )
}
