import { ofType, StateObservable } from 'redux-observable'
import { mergeMap, Observable, of, tap } from 'rxjs'
import { Config } from '../../../../config'
import { StoreState } from '../../../../models/app/model'
import { ExtensionService } from '../../../../services/extension'
import {
  ExtensionDispatcherACT,
  ExtensionDispatcherAT,
} from '../../../../services/extension/actions/extension-partial-service'
import { BroadcastAC } from '../../../actions/bcast/actions'
import { CancelNowAC, CancelReasons } from '../../../actions/cancel-now/actions'

type Action = ExtensionDispatcherACT['StopBcast']

export const extensionMapToAppStopBcastEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ExtensionDispatcherAT.STOP_BROADCAST),
    tap(({ meta }) => {
      // Send stopped to extnsion after emit the actions in mergeMap

      !Config.isExtension &&
        setTimeout(
          () => ExtensionService.SendMessage.ToContent.bcastStopped(meta.source, meta.msgId),
          10,
        )
    }),
    mergeMap(() =>
      of(BroadcastAC.exit('user-manual-exit'), CancelNowAC.cancelAll([CancelReasons.UserStopped])),
    ),
  )
