import { LazySVG } from '../../../../../../../../../components/svgs/lazy-svg/component'
import { useJoinedBehaviourLogicContentMainToolbarsMainButtonsCapturePaint } from '../../../../../../../behaviour-logic/content/main/toolbars/main/buttons/capture-paint/hook'
import { ClassName, STJoinedTabletContentMainToolbarsMainButtonsCapturePaint } from './style'

// ~~~~~~ Constants

const PaintIcon = LazySVG('icons/tool-pen')

// ~~~~~~ Types

type Props = {
  video: HTMLVideoElement | null
}

// ~~~~~~ Component

export const JoinedTabletContentMainToolbarsMainButtonsCapturePaint: React.FC<Props> = ({
  video,
}) => {
  // ~~~~~~ Hooks

  const logic = useJoinedBehaviourLogicContentMainToolbarsMainButtonsCapturePaint(video)

  // ~~~~~~ Render

  return (
    <STJoinedTabletContentMainToolbarsMainButtonsCapturePaint onClick={logic.onClickCapturePaint}>
      {/* Icon */}

      <PaintIcon className={`${ClassName}--icon`} size={24} />

      {/* -- */}
    </STJoinedTabletContentMainToolbarsMainButtonsCapturePaint>
  )
}
