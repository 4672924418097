import { useDispatch, useSelector } from 'react-redux'

import { ModalsIds } from '../../../../../constants/modals'
import { StoreState } from '../../../../../models/app/model'
import { StorePlanFieldsMR } from '../../../../../models/store/plan/model'
import { ModalsMC } from '../../../../../store/actions-mutators/modals/mutators'
import { ApiStoreSubscriptionsAC } from '../../../../../store/actions/api-store/subscriptions/actions'
import { Button } from '../../../../common/button/component'
import { Trans } from '../../../../common/intl/trans'
import { Loader } from '../../../../common/loader/component'
import { SliderCheck } from '../../../../common/slider-check/component'
import { LazySVG } from '../../../../svgs/lazy-svg/component'
import { DialogsSubscriptionsPlansForDialogsLayout } from '../layout/component'
import { DialogsSubscriptionsPlansForDialogsLayoutPartsAction } from '../layout/parts/action/component'
import { DialogsSubscriptionsPlansForDialogsLayoutPartsContent } from '../layout/parts/content/component'
import { STDialogsSubscriptionsPlansForDialogsSharedStyleContent } from '../shared-style/style'
import { BcastTrackEvents } from '../../../../../services/track-events'
import { useTrackUserContext } from '../../../../../hooks/track-events/use-track-user-context'

// ~~~~~~ Constants

const CircleArrowIcon = LazySVG('icons/circle-arrow')

const Logo = LazySVG('logos/broadcast-brand')

// ~~~~~~ Types

type Props = {
  onSelectPlan(): void
}

// ~~~~~~ Component

export const DialogsSubscriptionsPlansForDialogsPremium: React.FC<Props> = ({ onSelectPlan }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const userContext = useTrackUserContext()

  // ~~~~~~ State

  const plan = useSelector((state: StoreState) => state.storePlan)

  const broadcastSub = useSelector((state: StoreState) => state.storeProducts)

  const currentUserEmail = useSelector((state: StoreState) => state.currentUser.email)

  // ~~~~~~ Computed

  const isRunning = broadcastSub.uiStatus === 'running'

  const broadcastPremium = broadcastSub.products.premium

  const isYearly = plan.timeFrame === 'yearly'

  const broadcastPriceId = isYearly ? broadcastPremium.year.id : broadcastPremium.month.id

  const currentUserIsLogged = !!currentUserEmail

  const premiumMonthPrice = broadcastPremium.month.price

  const premiumYearPrice = broadcastPremium.year.price

  // ~~~~~~ Handlers

  function onClick() {
    dispatch(StorePlanFieldsMR.kind.MC.change('premium'))
    dispatch(StorePlanFieldsMR.priceId.MC.change(broadcastPriceId))

    // New user: buy

    if (!currentUserIsLogged) {
      dispatch(ModalsMC.open(ModalsIds.Step1EmailPass))
      onSelectPlan()

      // Track

      BcastTrackEvents.calls.BuyProcess.Premium['1-select-plan-premium']()

      return
    }

    // Existent user: upgrade

    // Check default Card

    dispatch(ApiStoreSubscriptionsAC.checkPaymentMethod())

    // -> No (Error404) => Add Card => yes => check card => yes
    // -> Yes => Buy => yes => Thanx

    // Track

    BcastTrackEvents.calls.BuyProcess.Upgrade['2-confirm-plan'](userContext)
  }

  function onChangePayTime() {
    dispatch(StorePlanFieldsMR.timeFrame.MC.change(isYearly ? 'monthly' : 'yearly'))
  }

  // ~~~~~~ Render

  return (
    <DialogsSubscriptionsPlansForDialogsLayout>
      {/* Content */}

      <DialogsSubscriptionsPlansForDialogsLayoutPartsContent>
        <STDialogsSubscriptionsPlansForDialogsSharedStyleContent $isFreePlan={false}>
          <div className="plans-for-dialogs-logo">
            <Logo size={23} />
          </div>

          <div className="plans-for-dialogs-title">Premium</div>

          <div className="plans-for-dialogs-subtitle">
            <div>
              <Trans id="plans.subtitle.Common" />
            </div>
          </div>

          <div className="plans-for-dialogs-content">
            <div className="line">
              <div className="icon">
                <CircleArrowIcon size={14} />
              </div>

              <Trans id="plans.lines.common.Viewers" />
            </div>

            <div className="line">
              <div className="icon">
                <CircleArrowIcon size={14} />
              </div>

              <Trans id="plans.lines.common.time-limit.Pay" />
            </div>
          </div>

          <hr />

          {isRunning ? (
            <Loader size={20} />
          ) : (
            <div className="plans-for-dialogs-payment">
              <div className="line switch">
                <Trans id="plans.lines.buy.PayMonthly" />

                <div className="switch">
                  <SliderCheck
                    $small
                    checked={isYearly}
                    $colorType="buy-plan"
                    onChange={onChangePayTime}
                  />
                </div>

                <div className="pay-yearly">
                  <Trans id="plans.lines.buy.pay-yearly.Name" />

                  <div className="pay-save">
                    <Trans id="plans.lines.buy.pay-yearly.Save" />
                  </div>
                </div>
              </div>

              <div className="line price">
                <span className="price">${isYearly ? premiumYearPrice : premiumMonthPrice}</span>
                <span className="price-separator"> / </span>
                <span className="price-time">
                  {isYearly ? <Trans id="common.Year" /> : <Trans id="common.Month" />}
                </span>
              </div>
            </div>
          )}
        </STDialogsSubscriptionsPlansForDialogsSharedStyleContent>
      </DialogsSubscriptionsPlansForDialogsLayoutPartsContent>

      {/* Action */}

      <DialogsSubscriptionsPlansForDialogsLayoutPartsAction>
        <Button
          $colorType="buy-plan"
          $width="100%"
          intlId="plans.actions.Pay"
          disabled={isRunning || plan.uiStatusCheckPaymentMethod === 'running'}
          $active={!isRunning && plan.uiStatusCheckPaymentMethod !== 'running'}
          $running={isRunning || plan.uiStatusCheckPaymentMethod === 'running'}
          onClick={onClick}
        />
      </DialogsSubscriptionsPlansForDialogsLayoutPartsAction>
    </DialogsSubscriptionsPlansForDialogsLayout>
  )
}
