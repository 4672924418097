import React from 'react'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Trans } from '../../intl/trans'
import { STInfoBox, STProps } from './style'

const InfoIcon = LazySVG('icons/info')

const WarningIcon = LazySVG('icons/warning')

const ErrorIcon = LazySVG('icons/circle-error')

type Icon = ReturnType<typeof LazySVG>

// ~~~~~~ Types

type InfoExtra = Infos & {
  underline?: true
  color?: ThemeColor
  onClick?: () => void
}

type Props = STProps & {
  infos: Array<InfoExtra | 'newline' | 'space' | { style: React.CSSProperties }>
  className?: string
  withIcon: boolean
  Icon?: Icon
  usingNewLines?: true
}

// ~~~~~~ Component

export const InfoBox: React.FC<Props> = ({
  infos,
  className,
  $boxType: boxType,
  withIcon,
  Icon,
  $usingNewLines: usingNewLines,
  $alignCenter: alignCenter,
  $padding: padding,
  $borderRadius: borderRadius,
  $iconColor: iconColor,
  $iconAlignSelf: iconAlignSelf,
  $iconMarginTop: iconMarginTop,
  $fontScale: fontScale,
}) => {
  if (!infos.length) return null

  return (
    <STInfoBox
      data-testid="info-box"
      $padding={padding}
      className={className}
      $boxType={boxType}
      $alignCenter={alignCenter}
      $borderRadius={borderRadius}
      $iconColor={iconColor}
      $iconAlignSelf={iconAlignSelf}
      $iconMarginTop={iconMarginTop}
      $fontScale={fontScale}
      $usingNewLines={usingNewLines}
    >
      {/* Icon */}

      {withIcon ? (
        <div className="info-box-icon-container">
          <div className="info-box-icon">
            {Icon ? (
              <Icon size={16} />
            ) : boxType === 'ERROR' ? (
              <ErrorIcon size={16} />
            ) : boxType === 'WARNING' ? (
              <WarningIcon size={16} />
            ) : boxType === 'INFO' ? (
              <InfoIcon size={16} />
            ) : undefined}
          </div>
        </div>
      ) : undefined}

      {/* Texts */}

      <div>
        {infos.map((info, idx) =>
          info === 'space' ? (
            <span key={idx}> </span>
          ) : info === 'newline' ? (
            <br key={idx} />
          ) : 'style' in info ? (
            <div key={idx} style={info.style} />
          ) : usingNewLines ? (
            <div
              className={`${info.underline ? 'underlined' : ''} ${
                info.onClick ? 'has-action' : ''
              }`}
              style={{ color: info.color ? info.color.dark : 'inherit' }}
              onClick={info.onClick}
              key={idx}
            >
              <Trans id={info.id} values={info.values} />
            </div>
          ) : (
            <span
              className={`${info.underline ? 'underlined' : ''} ${
                info.onClick ? 'has-action' : ''
              }`}
              style={{ color: info.color ? info.color.dark : 'inherit' }}
              onClick={info.onClick}
              key={idx}
            >
              <Trans id={info.id} values={info.values} />
            </span>
          ),
        )}
      </div>
    </STInfoBox>
  )
}
