import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars'
import { HooksData } from '@dn/hooks'

import { Button } from '../../../../../components/common/button/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { SliderCheck } from '../../../../../components/common/slider-check/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { ModalsIds } from '../../../../../constants/modals'
import { useTrans } from '../../../../../hooks/use-trans'
import { StoreState } from '../../../../../models/app/model'
import { ModalsMC } from '../../../../../store/actions-mutators/modals/mutators'
import { ApiBcastGoogleClassRoomAC } from '../../../../../store/actions/api-bcast/google-classroom/actions'
import { GoogleAuth } from '../../../../../third-parties/google-api/library'
import { UiLayoutPanelsFieldsMR } from '../../../../../models/bcast/ui-layout-panels/model'
import { ClassName, ItemHeight, Padding, STStartedLeftPanelSharePaintClassroom } from './style'
import { useClassroomScrollbarHeight } from './behaviour-logic/use-classroom-scrollbar-height'
import { Config } from '../../../../../config'
import { CurrentUserEditFieldsMR } from '../../../../../models/bcast/current-user/model'
import { useTrackStreamContext } from '../../../../../hooks/track-events/use-track-stream-context'
import { useTrackUserContext } from '../../../../../hooks/track-events/use-track-user-context'
import { BcastTrackEvents } from '../../../../../services/track-events'
import { useBcasterGConnect } from '../../../../../hooks/bcaster-g-connect/use-bcaster-g-connect'

// ~~~~~~ Constants

const ItemIcon = LazySVG('icons/book')

const NoCourse: Course = {
  id: '',
  name: '',
}

// ~~~~~~ Types

export type Course = {
  id: string
  name: string
}

type Props = {
  panelElementRef: React.RefObject<HTMLDivElement>
  panelMargin: number

  topElementRef: React.RefObject<HTMLDivElement>

  slidesElementRef: React.RefObject<HTMLDivElement>

  exitElementRef: React.RefObject<HTMLDivElement>
}

// ~~~~~~ Component

export const StartedLeftPanelSharePaintClassroom: React.FC<Props> = ({
  panelElementRef,
  panelMargin,
  topElementRef,
  slidesElementRef,
  exitElementRef,
}) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const announceImageText = useTrans('common.share.announce.Image')

  const classroomScroll = useClassroomScrollbarHeight(
    panelElementRef,
    topElementRef,
    slidesElementRef,
    exitElementRef,
    panelMargin,
    Padding,
    ItemHeight,
  )

  const userContext = useTrackUserContext()

  const streamContext = useTrackStreamContext()

  const bcasterGConnect = useBcasterGConnect()

  // ~~~~~~ State

  // - Local

  const [selectedCourse, setSelectedCourse] = useState<Course>(NoCourse)

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const providerGoogle = useSelector((state: StoreState) => state.providerGoogle)

  const classRoomMetaList = useSelector((state: StoreState) => state.googleClassRoomMetaList)

  const classRoomAnnouncement = useSelector(
    (state: StoreState) => state.googleClassRoomAnnouncementCapture,
  )

  const imageToShare = useSelector((state: StoreState) => state.imageToShare)

  const layoutPanels = useSelector((state: StoreState) => state.uiLayoutPanels)

  // ~~~~~~ Computed

  const shareIsOpen = layoutPanels.shareImageToClassroomState === 'open'

  const userCanAnnounceLink =
    providerGoogle.classroom_announcements ||
    (Config.isExtension && !!currentUser.googleAccessToken)

  const classroomQuantity = classRoomMetaList.list.length

  const shareImageIsRunning = classRoomAnnouncement.uiStatus === 'running'

  const alreadyShared = !!currentUser.lastSelectedGoogleClassRoom.id

  const showEmptyList =
    classRoomMetaList.list.length === 0 && classRoomMetaList.uiStatus !== 'running'

  const withGoogleAccessToken = useMemo(
    () =>
      Config.isExtension && !currentUser.id
        ? ({ withIt: true, accessToken: currentUser.googleAccessToken } as const)
        : ({ withIt: false } as const),
    [currentUser.googleAccessToken, currentUser.id],
  )

  // ~~~~~~ State dependent Hooks

  const prevUserCanAnnounceLink = HooksData.PrevValue.useHook(userCanAnnounceLink)

  // ~~~~~~ Handlers

  function onChangeSlider() {
    if (!userCanAnnounceLink) {
      dispatch(UiLayoutPanelsFieldsMR.onLinkWithGoogleShouldOpen.MC.change('classroom'))

      if (Config.isExtension) {
        currentUser.id
          ? dispatch(ModalsMC.open(ModalsIds.LinkGoogleInBcast))
          : dispatch(
              ModalsMC.open(ModalsIds.GoogleSignIn, () => {
                GoogleAuth.login({
                  onSuccess: ({ access_token, expires_at }) => {
                    // Update info

                    dispatch(CurrentUserEditFieldsMR.googleAccessToken.MC.change(access_token))
                    dispatch(
                      CurrentUserEditFieldsMR.googleAccessTokenExpiresAt.MC.change(expires_at),
                    )

                    // Open GSlides panel

                    dispatch(UiLayoutPanelsFieldsMR.shareImageToClassroomState.MC.change('open'))
                  },
                })
              }),
            )

        return
      }

      bcasterGConnect()

      return
    }

    // Model

    dispatch(
      UiLayoutPanelsFieldsMR.shareImageToClassroomState.MC.change(shareIsOpen ? 'closed' : 'open'),
    )

    // Changed to close

    if (shareIsOpen) {
      setSelectedCourse(NoCourse)

      return
    }

    // Changed to open

    dispatch(ApiBcastGoogleClassRoomAC.list(withGoogleAccessToken))

    // Required to recalculate scrollbar height on change to "checked"
    // as the internal size of the card changes.
    // We need the space used by "Available classrooms"

    setTimeout(() => classroomScroll.recalculateScrollbarHeight(), 0)
  }

  function onClickItem(course: Course) {
    selectedCourse === course ? setSelectedCourse(NoCourse) : setSelectedCourse(course)
  }

  function onShareImage() {
    const classroom = currentUser.lastSelectedGoogleClassRoom.id
      ? currentUser.lastSelectedGoogleClassRoom
      : selectedCourse

    if (shareImageIsRunning || !classroom.id) return

    dispatch(
      ApiBcastGoogleClassRoomAC.announceCapture(
        withGoogleAccessToken,

        classroom,

        announceImageText,
        {
          kind: 'image',
          image: {
            name: imageToShare.name,
            content: imageToShare.file,
          },
        },
      ),
    )

    // Track

    BcastTrackEvents.calls.Google.Bcaster.shareCroomImage(userContext, streamContext)
  }

  // ~~~~~~ Effects

  // - On connect account, get classroom list

  useEffect(() => {
    if (prevUserCanAnnounceLink !== false || userCanAnnounceLink !== true) return

    dispatch(ApiBcastGoogleClassRoomAC.list(withGoogleAccessToken))

    //
  }, [dispatch, prevUserCanAnnounceLink, userCanAnnounceLink, withGoogleAccessToken])

  // - On mount, if account is connected, get classroom list

  useEffect(() => {
    if (!userCanAnnounceLink) return

    dispatch(ApiBcastGoogleClassRoomAC.list(withGoogleAccessToken))

    // We don't want update value side effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // ~~~~~~ Render

  return (
    <STStartedLeftPanelSharePaintClassroom>
      {/* Title and slider */}

      <div ref={classroomScroll.refs.titleRef} className={`${ClassName}--title-slider`}>
        <div className={`${ClassName}--title-slider--title`}>Google Classroom</div>

        <div className={`${ClassName}--title-slider--title`}>
          <SliderCheck
            $colorType="secondary"
            checked={userCanAnnounceLink ? shareIsOpen : false}
            onChange={onChangeSlider}
          />
        </div>
      </div>

      {/* Closed info */}

      {!shareIsOpen && !userCanAnnounceLink ? (
        <div className={`${ClassName}--closed-info`}>
          <Trans id="pages.started.left-panel.common.classroom.Info" />
        </div>
      ) : undefined}

      {/* Already shared */}

      {shareIsOpen && alreadyShared ? (
        <div className={`${ClassName}--already-shared`}>
          {/* Info */}

          <div className={`${ClassName}--already-shared--info`}>
            <Trans id="pages.started.left-panel.common.classroom.Current" />
          </div>

          {/* Selected classroom */}

          <div className={`${ClassName}--already-shared--selected`}>
            {/* Icon */}

            <div className={`${ClassName}--already-shared--selected--icon`}>
              <ItemIcon size={24} />
            </div>

            {/* Classroom */}

            <div className={`${ClassName}--already-shared--selected--name`}>
              {currentUser.lastSelectedGoogleClassRoom.name}
            </div>
          </div>

          {/* Action */}

          <div className={`${ClassName}--already-shared--action`}>
            <Button
              $colorType="primary"
              intlId="common.Upload"
              $width="100%"
              disabled={shareImageIsRunning}
              $running={shareImageIsRunning}
              $active={!shareImageIsRunning}
              onClick={onShareImage}
            />
          </div>
        </div>
      ) : undefined}

      {/* Classroom data */}

      {shareIsOpen && !alreadyShared ? (
        <div className={`${ClassName}--classroom-data`}>
          {/* Available classrooms */}

          {!showEmptyList ? (
            <div
              ref={classroomScroll.refs.classroomQttyRef}
              className={`${ClassName}--classroom-data--quantity`}
            >
              <div>
                <Trans id="pages.started.left-panel.common.classroom.Available" />
              </div>

              <div className={`${ClassName}--classroom-data--quantity--data`}>
                {classroomQuantity}
              </div>
            </div>
          ) : undefined}

          {/* List */}

          <Scrollbars
            universal
            autoHide={false}
            style={{ height: classroomScroll.scrollbarHeight }}
            renderTrackVertical={(props) => <div {...props} className="scrollbar-vertical" />}
          >
            <div className={`${ClassName}--classroom-data--list`}>
              {/* - */}

              {/* Empty List */}

              {showEmptyList ? (
                <div className={`${ClassName}--classroom-data--list--empty`}>
                  {/* Icon */}

                  <div className={`${ClassName}--classroom-data--list--empty--icon`}>
                    <ItemIcon size={24} />
                  </div>

                  {/* Text */}

                  <div className={`${ClassName}--classroom-data--list--empty--text`}>
                    <Trans id="pages.started.left-panel.common.classroom.EmptyList" />
                  </div>
                </div>
              ) : undefined}

              {/* List with data */}

              {!showEmptyList &&
                classRoomMetaList.list.map((classroom, idx) => (
                  <div
                    key={classroom.id}
                    //
                    className={`${ClassName}--classroom-data--list--item ${
                      idx === classRoomMetaList.list.length - 1 ? 'last' : ''
                    } ${classroom === selectedCourse ? 'selected' : ''}`}
                    //
                    onClick={() => onClickItem(classroom)}
                  >
                    {/* Icon */}

                    <div className={`${ClassName}--classroom-data--list--item--icon`}>
                      <ItemIcon size={24} />
                    </div>

                    {/* Classroom */}

                    <div className={`${ClassName}--classroom-data--list--item--name`}>
                      {classroom.name}
                    </div>
                  </div>
                ))}

              {/* - */}
            </div>
          </Scrollbars>

          {/* Action */}

          {!showEmptyList ? (
            <div
              ref={classroomScroll.refs.actionRef}
              className={`${ClassName}--classroom-data--action`}
            >
              <Button
                $colorType="primary"
                intlId="common.Upload"
                $width="100%"
                disabled={shareImageIsRunning || !selectedCourse.id}
                $running={shareImageIsRunning}
                $active={!shareImageIsRunning && !!selectedCourse.id}
                onClick={onShareImage}
              />
            </div>
          ) : undefined}
        </div>
      ) : undefined}

      {/* - */}
    </STStartedLeftPanelSharePaintClassroom>
  )
}
