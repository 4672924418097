import styled from 'styled-components'
import { Transition } from '../../../../../style/animations/css-animations'
import { Colors } from '../../../../../style/theme/colors'
import { HexAlpha } from '../../../../../style/theme/colors/alpha'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'joined-tablet-content-main'

// ~~~~~~ Component

export const STJoinedTabletContentMain = styled.div`
  touch-action: none;

  width: 100%;
  height: 100%;

  background-color: ${theme(Colors.WOLF_05_100)};

  display: flex;
  justify-content: center;

  position: relative;

  overflow: hidden;

  transition:
    width 0.2s,
    height 0.2s;

  /* Content */

  .${ClassName}--content {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${Layout.Border.Radius.A}

    overflow: hidden;

    width: 100%;
    height: 100%;

    /* Sound */

    .${ClassName}--content--sound {
      position: absolute;
      top: 16px;

      z-index: 11;
    }

    /* Video Wrapper */

    .${ClassName}--content--video-wrapper {
      position: relative;

      width: 100%;

      ${Layout.Border.Radius.A}

      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: center;

      /* Cam Mic */

      .${ClassName}--content--video-wrapper--cam-mic {
        position: absolute;

        // LivePaint z-index is 10
        z-index: 11;

        left: ${Layout.Spacing[2]}px;

        // Component padding
        bottom: ${Layout.Spacing[2]}px;

        &.hidden {
          display: none;
        }
      }

      /* Video */

      .${ClassName}--content--video-wrapper--video {
        z-index: 4;
        width: 100%;
        height: 100%;

        &.with-zoom {
          cursor: grab;

          &:active {
            cursor: grabbing;
          }
        }
      }

      /* Mini video */

      .${ClassName}--content--video-wrapper--mini-video-container {
        padding: ${Layout.Spacing[2]}px;
        border-radius: 2px;

        position: absolute;
        bottom: 16px;
        right: 16px;
        width: 231px;
        height: 142px;
        z-index: 10;

        background-color: ${theme(Colors.WOLF_100_100)}${HexAlpha[80]};

        &.hidden {
          display: none;
        }

        video {
          width: 100%;
          height: 100%;
        }

        .${ClassName}--content--video-wrapper--mini-video-container--close {
          cursor: pointer;

          z-index: 1;

          position: absolute;
          top: ${Layout.Spacing[1]}px;
          right: ${Layout.Spacing[1]}px;

          background-color: ${theme(Colors.WOLF_100_100)};
          border-radius: 2px;

          padding: 2px;

          height: 20px;
          width: 20px;

          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 16px;
            width: 16px;

            path {
              ${Transition.FillFaster}

              fill: ${theme(Colors.WOLF_40_40)};
            }
          }

          &:hover {
            svg path {
              fill: ${theme(Colors.WOLF_05_05)};
            }
          }

          &:active {
            svg path {
              fill: ${theme(Colors.WOLF_40_40)};
            }
          }
        }
      }
    }

    /* Tools */

    .${ClassName}--content--tools {
      z-index: 20;

      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
`
